import React, {
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { useAppInfo } from 'AppState';
import { navLinkCategories } from 'nav/menu';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Paper,
  Typography,
} from '@mui/material';

export default function HoverMenu({navMenuSelected,handleMouseLeave,handleSidebar}) {
    const {dispatchSidebar,stateSidebar} = useAppInfo()
    const {sidebarLeftExpanded} = stateSidebar

    const location = useLocation();
    const pageName = location.pathname.split('/')[1];
    const subPage = location.pathname.split('/')[2];
    const [currentSubPage, setCurrentSubPage] = useState();
  
    useEffect(() => {
      const currentPage = navLinkCategories.find((page) => page.link === pageName);
      const currentSubPage = currentPage?.menu?.find((sub) => sub.link === subPage);
      setCurrentSubPage(currentSubPage);
    }, [pageName, subPage]);

    const [paperPosition, setPaperPosition] = useState({ top: 0, left: 0 });
    const [positionSet, setPositionSet] = useState(false); // New state to delay rendering

    useEffect(() => {
        if (navMenuSelected) {
          const hoveredLink = document.getElementById("leftSidebarMain");
          if (hoveredLink) {
            const { top, left, height, width } = hoveredLink.getBoundingClientRect();
            const paperWidth = 200; // Assuming Paper width is 200px
            const paperHeight = 400; // Assuming Paper height is 200px
      
            let newLeft = left + width; // Position to the right of the link with a 10px margin
            let newTop = top;
      
            // Adjust horizontally if the Paper goes beyond the right edge
            if (newLeft + paperWidth > window.innerWidth) {
              newLeft = left - paperWidth - 0; // Position to the left of the link
            }
      
            // Adjust vertically if the Paper goes below the bottom edge
            if (newTop + paperHeight > window.innerHeight) {
              newTop = Math.max(0, window.innerHeight - paperHeight - 10); // Position at the maximum top that fits within the viewport
            }
      
            setPaperPosition({ top: newTop, left: newLeft });
            setPositionSet(true)
          }
          else{
            setPaperPosition({ top: 0, left: 0 });
            setPositionSet(true)
          }
        }
      }, []);



  
  return (
    <>
     {(positionSet &&navMenuSelected && navMenuSelected?.menu) && (
              createPortal(
                  <Paper
                    elevation={0}
                    onMouseLeave={handleMouseLeave}
                    onPointerLeave={handleMouseLeave}
                    style={{
                      position: 'absolute',
                      // top: `${paperPosition.top}px`,
                      top:0,
                    //   left:0,
                      left: paperPosition.left ?`${paperPosition.left}px` : "0px",
                      zIndex: 100000000,
                      height: '100vh',
                      background: 'var(--bgWoodSmoke)',
                      borderRadius:0,
                      borderLeft:"3px solid #252627",
                      boxShadow: "5px 0 5px -3px rgba(0, 0, 0, 0.3)",
                      boxSizing: 'border-box',
                      minWidth: '250px',
                      width: 'auto',
                      transition: 'ease-in-out',
                      overflow:"auto",
                      overflowY:"auto",
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.25rem',
                        padding: '0.5rem',
                        minWidth: '200px',
                      }}
                    >

                    <div style={{display:"flex"
                    ,gap:"0.25rem",alignItems:"center",color:"#fafafa",padding:sidebarLeftExpanded && "0.5rem 0"}}>
                    {navMenuSelected?.icon}
                      <Typography variant="h6" sx={{ padding: '0.5rem',color:"#fafafa"}}>
                        {navMenuSelected?.name} 
                        
                      </Typography>
                    </div>
                      
                      {navMenuSelected?.menu.map((obj) => (
                        <Button
                          key={obj.name}
                          sx={{
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            display: 'flex',
                            gap: '1rem',
                            fontSize: '0.9rem',
                            fontWeight:obj.link === subPage && "bold",
                            background:obj.link === subPage && '#fafafa',
                            color:
                              obj.name === currentSubPage?.name
                                ? 'var(--selectBlue)'
                                : '#fafafa',

                                '&:hover': {background:'#F8F8F8',color:obj.link !== subPage && 'black'}
                          }}
                          onClick={() =>
                            handleSidebar(
                              `${navMenuSelected?.link}/${obj?.link}`
                            )
                          }
                        >
                          {/* {obj?.icon} */}
                          {obj.name}
                        </Button>
                      ))}
                    </div>
                  </Paper>,
                document.body
              )
            )}
    </>
  )
}
