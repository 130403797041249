import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";

const TooltipPortal = ({ tooltip, style }) => {
  if (!tooltip.visible) return null;

  const combinedStyle = {
    position: "absolute",
    left: tooltip.x + 10, // offset to avoid cursor overlap
    top: tooltip.y + 10,
    pointerEvents: "none",
    background: "rgba(0,0,0,0.7)",
    color: "#fff",
    padding: "5px",
    borderRadius: "3px",
    whiteSpace: "nowrap",
    zIndex: 1000,
    ...style,
  };

  return ReactDOM.createPortal(
    <div style={combinedStyle}>{tooltip.content}</div>,
    document.body
  );
};

const LiquidChart = ({
  color = "blue",
  data = [],
  animationType = "both", // 'both', 'area', 'line', or 'none'
  showPointer = false,    // Toggles the pointer/text entirely
  tooltipEnabled = false, // Enables or disables tooltip feature
  tooltipStyle = {},      // Custom styles for the tooltip
}) => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 1000,
    height: 0,
    margin: { top: 10, right: 10, bottom: 0, left: 0 },
  });

  const [pathData, setPathData] = useState({ line: "", area: "" });
  const [endPoint, setEndPoint] = useState({ x: 0, y: 0 });

  // Tooltip state
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    content: "",
  });

  const isValidNumber = (val) =>
    val !== null && val !== undefined && !Number.isNaN(val);

  useEffect(() => {
    const observeTarget = containerRef.current;
    if (!observeTarget) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setDimensions((prev) => ({
          ...prev,
          width: width || prev.width,
          height: height || prev.height,
        }));
      }
    });

    resizeObserver.observe(observeTarget);
    return () => resizeObserver.unobserve(observeTarget);
  }, []);

  useEffect(() => {
    const { width, height, margin } = dimensions;
    if (width === 0 || height === 0) return;
    if (!data || data.length === 0) return;

    const validValues = data.filter(isValidNumber);
    if (validValues.length === 0) {
      setPathData({ line: "", area: "" });
      setEndPoint({ x: 0, y: 0 });
      return;
    }

    const xScale = d3
      .scaleLinear()
      .domain([0, data.length - 1])
      .range([margin.left, width - margin.right]);

    // Adjust yScale domain to accommodate negative values
    const yScale = d3
      .scaleLinear()
      .domain([
        Math.min(0, d3.min(validValues)),
        Math.max(0, d3.max(validValues))
      ])
      .range([height - margin.bottom, margin.top]);

    const lineGenerator = d3
      .line()
      .defined((d) => isValidNumber(d))
      .x((d, i) => xScale(i))
      .y((d) => yScale(d))
      .curve(d3.curveMonotoneX);

    // Set area baseline at y(0) to correctly handle negatives
    const areaGenerator = d3
      .area()
      .defined((d) => isValidNumber(d))
      .x((d, i) => xScale(i))
      .y0(yScale(0))
      .y1((d) => yScale(d))
      .curve(d3.curveMonotoneX);

    setPathData({
      line: lineGenerator(data),
      area: areaGenerator(data),
    });

    let lastValidIndex = -1;
    for (let i = data.length - 1; i >= 0; i--) {
      if (isValidNumber(data[i])) {
        lastValidIndex = i;
        break;
      }
    }

    if (lastValidIndex === -1) {
      setEndPoint({ x: 0, y: 0 });
    } else {
      setEndPoint({
        x: xScale(lastValidIndex),
        y: yScale(data[lastValidIndex]),
      });
    }
  }, [data, dimensions]);

  const handleMouseEnter = () => {
    setTooltip(prev => ({ ...prev, visible: true }));
  };

  const handleMouseMove = (e) => {
    if (!data || data.length === 0) return;

    const { width, height, margin } = dimensions;
    const containerRect = containerRef.current.getBoundingClientRect();
    const xPosition = e.clientX - containerRect.left;

    const validValues = data.filter(isValidNumber);
    if (validValues.length === 0) return;

    const xScale = d3
      .scaleLinear()
      .domain([0, data.length - 1])
      .range([margin.left, width - margin.right]);

    // Adjust yScale domain to accommodate negative values in tooltip calculations
    const yScale = d3
      .scaleLinear()
      .domain([
        Math.min(0, d3.min(validValues)),
        Math.max(0, d3.max(validValues))
      ])
      .range([height - margin.bottom, margin.top]);

    const relativeX = xPosition;
    let approxIndex = Math.round(xScale.invert(relativeX));
    approxIndex = Math.max(0, Math.min(data.length - 1, approxIndex));

    // Find nearest valid data point
    while (approxIndex > 0 && !isValidNumber(data[approxIndex])) {
      approxIndex--;
    }
    while (approxIndex < data.length - 1 && !isValidNumber(data[approxIndex])) {
      approxIndex++;
    }

    if (!isValidNumber(data[approxIndex])) return;

    const newX = xScale(approxIndex) + containerRect.left;
    const newY = yScale(data[approxIndex]) + containerRect.top;

    setTooltip({
      visible: true,
      x: newX,
      y: newY,
      content: data[approxIndex].toFixed(2),
    });
  };

  const handleMouseLeave = () => {
    setTooltip(prev => ({ ...prev, visible: false }));
  };

  return (
    <div ref={containerRef} style={{ width: "100%", overflow: "hidden"}}>
      <svg
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        preserveAspectRatio="xMinYMin meet"
        style={{ width: "100%", height: "100%" }}
        {...(tooltipEnabled
          ? {
              onMouseEnter: handleMouseEnter,
              onMouseMove: handleMouseMove,
              onMouseLeave: handleMouseLeave,
            }
          : {})}
      >
        <defs>
          <linearGradient id="liquidGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={color} stopOpacity="0.8" />
            <stop offset="100%" stopColor={color} stopOpacity="0" />
          </linearGradient>
        </defs>

        <path
          d={pathData.area}
          fill="url(#liquidGradient)"
          style={{
            transition:
              animationType === "both" || animationType === "area"
                ? "d 2s ease-out"
                : "none",
          }}
        />
        <path
          d={pathData.line}
          fill="none"
          stroke={color}
          strokeWidth="1"
          style={{
            strokeDasharray:
              animationType === "both" || animationType === "line"
                ? "1400"
                : "none",
            strokeDashoffset:
              animationType === "both" || animationType === "line"
                ? "1400"
                : "none",
            animation:
              animationType === "both" || animationType === "line"
                ? "dashAnimation 3s linear forwards"
                : "none",
          }}
        />
        {showPointer && (
          <g>
            <circle
              cx={endPoint.x}
              cy={endPoint.y}
              r="6"
              fill={color}
            />
          </g>
        )}
        <style>
          {`
            @keyframes dashAnimation {
              from { stroke-dashoffset: 1000; }
              to { stroke-dashoffset: 0; }
            }
            .recording-dot {
              animation: pulse 1s infinite;
            }
            @keyframes pulse {
              0% { r: 6; opacity: 0.9; }
              50% { r: 8; opacity: 1; }
              100% { r: 6; opacity: 0.9; }
            }
          `}
        </style>
      </svg>
      <TooltipPortal tooltip={tooltip} style={tooltipStyle} />
    </div>
  );
};

export default LiquidChart;
