import { Skeleton } from '@mui/material'
import React from 'react'


export default function LoadingSkeletonPage({children}) {
  return (
    <Skeleton variant='rectangle' width={'100%'} height={"100%"}>
      {children}
    </Skeleton>
    
  )
}
