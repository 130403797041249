import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EventIcon from '@mui/icons-material/Event';
import { Button } from '@mui/material';
import ThreePIcon from '@mui/icons-material/ThreeP';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Modal from 'components/Modal/Modal';
import AISummaryBox from 'chatGPT/AISummaryBox';
import { useAppInfo } from 'AppState';

const commonButtonStyles = {
  borderRadius: "25px",
  boxShadow:"none",
  width: "max-content",
  // minHeight: "50px",
  minWidth: "200px",
  textTransform: "none", // Prevent uppercase text transformation
  fontWeight: 600,
  padding:"0.5rem",
  transition: "background-color 0.3s",
};

export default function CategoryActions() {
  return <div>CategoryActions</div>;
}

export function ActionAnalytics({ url }) {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<AnalyticsIcon sx={{ color: "#42a5f5", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => url && navigate(url)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#e3f2fd", // Light blue background
        color: "#0d47a1",            // Darker blue text
        '&:hover': {
          backgroundColor: "#bbdefb",
        },
      }}
    >
      Analytics
    </Button>
  );
}

export function ActionFinancialProducts({ url="/FinancialProducts" }) {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<ShoppingCartIcon sx={{ color: "#42a5f5", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => url && navigate(url)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#e3f2fd", // Light blue background
        color: "#0d47a1",            // Darker blue text
        '&:hover': {
          backgroundColor: "#bbdefb",
        },
      }}
    >
      Financial Products
    </Button>
  );
}

export function ActionProfessionalAdvice({ url='/AskProfessional' }) {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<ThreePIcon  sx={{ color:"#1b5e20", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => url && navigate(url)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#e8f5e9", // Light green background
        color: "#1b5e20",           // Darker green text
        '&:hover': {
          backgroundColor: "#c8e6c9",
        },
      }}
    >
      Professional Advice
    </Button>
  );
}


export function ActionInsights({ url,info}) {
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  return (
    <>
    <Button
      startIcon={<AutoAwesomeIcon sx={{ color: "#66bb6a", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => setModal(true)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#e8f5e9", // Light green background
        color: "#1b5e20",           // Darker green text
        '&:hover': {
          backgroundColor: "#c8e6c9",
        },
      }}
    >
      Insights
    </Button>
    {modal && <InsightModal handleClose={() => setModal(false)} info={info}/>}
    </>
  );
}


export function ActionTodos({ url }) {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<EventIcon sx={{ color: "#8e24aa", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => url && navigate(url)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#f3e5f5", // Light purple background
        color: "#4a148c",           // Darker purple text
        '&:hover': {
          backgroundColor: "#e1bee7",
        },
      }}
    >
     AI Todos
    </Button>
  );
}




export function InsightModal({handleClose,info}){
  const {stateMyFinancials,statePortfolio,stateApp,stateAssistant,dispatchAssistant} = useAppInfo()
  
  const {username} = stateApp
  const {aiSummaryStore} = stateAssistant
  const {portfolioLoaded,portfolio,portfolioResults} = statePortfolio
  const {liabilityIndividualDetails,
    liabilityStats, financialsUpdated,currency,summaryStats,assets,liabilities,incomeMonthly,expenseMonthly,financialHealthIssues,
    financialWellnessScore,goalsPeriodic,financialView,financialsLoaded,demoProfile,
    goalAnalytics,financials} = stateMyFinancials
  const [aiMessage,setAIMessage] = useState()

    useEffect(()=>{
      let user = financialView === "personal" ? username : demoProfile
      let userMessage = user ? `You are addressing to ${user}` : `You are addressing to a demo profile ${demoProfile} for ${username} account on Autonomy Finance.`
     
      const content = [
        {info:"liabilities", content: JSON.stringify({liabilityIndividualDetails,liabilityStats}), 
        message:"Give me key insights on my Liabilities and Debt in and highlight important points. Tell me how am I placed in paying this debt."},
        {info:"goals",content: JSON.stringify({goalAnalytics}),message:"Give me key insights on my goals and highlight important points. Tell me how am I doing in reaching them."},
        {info:"assets",content: JSON.stringify({assets,portfolioResults}),message:"Give me key insights on my assets and highlight important points. Tell me how are they doing, what is the allocation, etc."},
        {info:"income",content: JSON.stringify({income:incomeMonthly}),message:"Give me key insights on my income and highlight important points. Tell me how I am doing? Am I spending too much? How am I using my income?"},
        {info:"expenses",content: JSON.stringify({expenses:expenseMonthly}),message:"Give me key insights on my expenses and highlight important points. Tell me whether am I spending too much, where can I save, and if any chances of improvement."},
      ]
      const contentSelected = content.filter(obj=>obj.info === info)
      const message = `${contentSelected[0]?.message} Use the following information: ${contentSelected[0]?.content}`
      if (financialsLoaded && financialsUpdated){
        const portfolioResultAll = portfolioResults?.portfolioOverviewAll
        let financialSituation = {currency,summaryStats,financialHealthIssues,
              financialWellnessScore,goalsPeriodic,}
        if (portfolioResultAll){
          financialSituation = {...financialSituation,...portfolioResultAll}
        }
  
        setAIMessage(`${message}. And consider the financial situation only to assist the main information: ${JSON.stringify(financialSituation)}`)
      }
    },[financialsLoaded,financialsUpdated,financialView,demoProfile,info])

    // console.log("aiMessage",aiMessage)

  return(
    <>
    <Modal onClose={handleClose}>
      <AISummaryBox usePromptStandard={false} message={aiMessage}/>
    </Modal>
    </>
  )
}
