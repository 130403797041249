// src/components/emailTemplates/EmailTemplate.jsx

const EmailTemplate = ({
  title = 'Financial Updates',
  companyName = 'Autonomy Finance',
  userName,
  message = "Your Money. Your Choices. Your Financial Autonomy",
  link = 'https://AutonomyFinance.com',
  linkText = 'Visit Autonomy Finance',
  contactEmail = 'info@autonomyfinance.ai',
  sidebarLinks = [
    { text: 'Home', url: 'https://AutonomyFinance.com/Home' },
    { text: 'Features', url: 'https://AutonomyFinance.com/Features' },
    { text: 'About', url: 'https://AutonomyFinance.com/About' },
  ],
  buttonText = 'Get Started',
  buttonLink = 'https://AutonomyFinance.com/get-started',
  imageUrl = 'https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/email%2FUntitled%20design%20(2).png?alt=media&token=3de30bdb-c15e-4bcd-b571-d52048db03ab',
}) => {
  const header = EmailHeader({ title, companyName });
  const body = EmailBody({
    userName,
    message,
    link,
    linkText,
    buttonText,
    buttonLink,
    imageUrl,
  });
  const footer = EmailFooter({ companyName, contactEmail,imageUrl});
  const sidebar = sidebarLinks ? EmailSidebar({ links: sidebarLinks }) : '';

  return `
    <html>
      <body>
        ${header}
        <div style="display: flex;">
          <div style="flex: 1;">
            ${body}
          </div>
        </div>
        ${footer}
      </body>
    </html>
  `;
};

export default EmailTemplate;



const EmailBody = ({ 
  userName, 
  message, 
  link, 
  linkText, 
  buttonText, 
  buttonLink, 
  imageUrl 
}) => {
  return (
    `<div style="padding: 20px; font-family: Arial, sans-serif; color: #333;">
      <h2>Hello, ${userName ? `${userName}!` : ""}</h2>
      <p>${message}</p>
      
      <p>
        <a href="${link}" style="color: #1a73e8; text-decoration: none;">${linkText}</a>
      </p>
      ${buttonText && buttonLink ? `
        <a href="${buttonLink}" style="
          display: inline-block;
          padding: 10px 20px;
          background-color: #1a73e8;
          color: white;
          text-decoration: none;
          border-radius: 5px;
        ">${buttonText}</a>
      ` : ''}
    </div>`
  );
};



const EmailHeader = ({ title, companyName }) => {
  return (
    `<div style="background-color:rgb(10, 75, 12); padding: 20px; text-align: center; color: white;">
      <h2>${title}</h2>
      <p>${companyName}</p>
    </div>`
  );
};


const EmailFooter = ({ companyName, contactEmail,imageUrl}) => {
  return (
    `<div style="background-color: #f1f1f1; padding: 10px; text-align: center; font-size: 12px; color: #555;">  
    <p>Best Regards,<br>${companyName} Team</p>
      <p>Contact us at <a href="mailto:${contactEmail}" style="color: #1a73e8;">${contactEmail}</a></p>
      ${imageUrl ? `<img src="${imageUrl}" alt="Image" style="max-width: 100%; height: 500px; object-fit: contain;"/>` : ''}
    </div>`
  );
};



const EmailSidebar = ({ links }) => {
  const linksHtml = links.map(link => `<li><a href="${link.url}" style="color: #1a73e8;">${link.text}</a></li>`).join('');

  return `
    <div style="width: 200px; float: left; padding: 20px; background-color: #f9f9f9;">
      <h3>Resources</h3>
      <ul style="list-style-type: none; padding: 0;">
        ${linksHtml}
      </ul>
    </div>
  `;
};

