import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import useRealTimePriceMultipleAccounts
  from 'data/eod/useRealTimePriceMultipleAccounts';

import getPerformanceDetails from '../utils/getPortfolioDetails';

export default function usePortfolioMultipleSummary() {
    const {statePortfolio, dispatchPortfolio } = useAppInfo()
    const {portfolio,portfolioLoaded} = statePortfolio

    // console.log(portfolio)
    const tickerCodes = useMemo(() => getTickerCodesFromPortfolios(portfolio), [portfolio]);
    const { prices, loading } = useRealTimePriceMultipleAccounts(tickerCodes);

    const [portfolioResults, setPortfolioResults] = useState({});
    const [totalSummary, setTotalSummary] = useState({
        "Investment Cost": 0,
        NAV: 0,
        PLpercent: 0,
        ProfitLoss: 0,
        "Realized P/L": 0
    });

    useEffect(() => {
        if (!loading && portfolioLoaded) {
            const results = {};
            const totalOverview = {};
            if (Object.keys(portfolio).length > 0) {
            Object.entries(portfolio).forEach(([accountName, portfolioSel]) => {
                const tickerToCodeMap = portfolioSel.reduce((map, obj) => {
                    map[obj["TICKER"]] = obj["CODE"];
                    return map;
                }, {});
                if (portfolioSel.length>0 && tickerCodes[accountName].length>0 && prices[accountName] 
                    && tickerToCodeMap){
                const { holdingDetails, portfolioOverview, portfolioTransactionTable } = getPerformanceDetails({
                    portfolioSel,
                    tickerCode: tickerCodes[accountName],
                    tickerCodeDict: tickerToCodeMap,
                    prices: prices[accountName]
                });

                // Aggregate portfolioOverview into totalOverview dynamically
                Object.entries(portfolioOverview).forEach(([key, value]) => {
                    if (key === "PLpercent") return; // Skip PLpercent, we'll calculate it later
                    totalOverview[key] = (totalOverview[key] || 0) + parseFloat(value);
                });

                results[accountName] = {
                    portfolioTableAll: holdingDetails,
                    portfolioOverviewAll: portfolioOverview,
                    portfolioTransactionTableAll: portfolioTransactionTable
                };
            }
            });

            // Calculate PLpercent for the total overview
            if (totalOverview["Investment Cost"] !== 0) {
                totalOverview.PLpercent = ((Number(totalOverview["Profit/Loss"]) / Number(totalOverview["Investment Cost"])) * 100);
            } else {
                totalOverview.PLpercent = 0;
            }
            
            }

            if (Object.keys(results).length > 0) {
                dispatchPortfolio({"portfolioResults":results,"portfolioTotal":totalOverview});
                setPortfolioResults(results);
                setTotalSummary(totalOverview);
            }
            else{
                dispatchPortfolio({"portfolioResults":{},"portfolioTotal":undefined});
                setPortfolioResults({});
                setTotalSummary({});
            }
            
          
        }
       
    }, [portfolio, prices, tickerCodes, loading]);

    return { portfolioResults, totalSummary };
}

function getTickerCodesFromPortfolios(portfolio) {
    const tickerCodes = {};
    if (portfolio) {
        Object.entries(portfolio).forEach(([accountName, portfolioSel]) => {
            tickerCodes[accountName] = portfolioSel.map(obj => obj["CODE"]);
        });
    }
    return tickerCodes;
}