import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { IconButton } from '@mui/material';
import styles from "./Modal.module.css";

export default function ModalOnboarding({ targetRef = "nav-links", children, closeIcon = true, onClose }) {
  const modalRef = useRef(null);
  const [targetRect, setTargetRect] = useState(null);
  const [childStyle, setChildStyle] = useState({});
  const [chosenPosition, setChosenPosition] = useState('bottom'); // Default

  useEffect(() => {
    const target = document.getElementById(targetRef);
    if (target) {
      const rect = target.getBoundingClientRect();
      setTargetRect({
        height: `${rect.height}px`,
        width: `${rect.width}px`,
        top: `${rect.top}px`,
        left: `${rect.left}px`
      });

      // Calculate child style dynamically based on available space
      const {style, position} = calculateDynamicChildPosition(rect);
      setChildStyle(style);
      setChosenPosition(position);
    }
  }, [targetRef]);

  return createPortal(
    <div 
      onClick={(e) => e.stopPropagation()}
      ref={modalRef} 
      style={{ 
        position: 'fixed', width: '100vw', height: '100vh', 
        left: 0, top: 0, backgroundColor: 'rgba(0,0,0,0.10)',
        zIndex: 1000
      }}
    >
      <>
        {/* The highlight rectangle around the target */}
        <div 
          className={styles.borderAnim}
          style={{ ...targetRect, position: 'absolute', zIndex: 10000000, boxSizing: "border-box"}} 
        />

        {/* The tooltip container */}
        <div 
          className={`${styles.tooltipContainer} ${styles['arrow-' + chosenPosition]}`}
          style={{ 
            position: 'absolute',
            zIndex: 10000000,
            ...childStyle
          }}
        >
          {closeIcon && (
            <div style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}>
              <IconButton sx={{ color: "white" }} onClick={onClose}>
                <CloseSharpIcon />
              </IconButton>
            </div>
          )}
          <div style={{ display: "flex", gap: "1rem", padding: "0.5rem" }}>
            <div style={{ flex: 1 }}>
              {children}
            </div>
          </div>
        </div>
      </>
    </div>,
    document.body
  );
};


function calculateDynamicChildPosition(rect) {
  // Adjust dimensions as needed
  const childWidth = 300; 
  const childHeight = 200; 
  const margin = 20;  // Smaller margin to place the tooltip closer to the target
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  const { height, width, top, left } = rect;

  // Define potential positions
  const positions = [
    {
      position: 'bottom',
      space: viewportHeight - top - height - childHeight - margin,
      style: {
        top: `${top + height + margin}px`,
        left: `${Math.max(margin, Math.min(left + (width / 2) - (childWidth / 2), viewportWidth - childWidth - margin))}px`
      }
    },
    {
      position: 'top',
      space: top - childHeight - margin,
      style: {
        top: `${top - childHeight - margin}px`,
        left: `${Math.max(margin, Math.min(left + (width / 2) - (childWidth / 2), viewportWidth - childWidth - margin))}px`
      }
    },
    {
      position: 'right',
      space: viewportWidth - left - width - childWidth - margin,
      style: {
        top: `${Math.max(margin, Math.min(top + (height / 2) - (childHeight / 2), viewportHeight - childHeight - margin))}px`,
        left: `${left + width + margin}px`
      }
    },
    {
      position: 'left',
      space: left - childWidth - margin,
      style: {
        top: `${Math.max(margin, Math.min(top + (height / 2) - (childHeight / 2), viewportHeight - childHeight - margin))}px`,
        left: `${left - childWidth - margin}px`
      }
    }
  ];

  // Sort positions by available space
  positions.sort((a, b) => b.space - a.space);

  for (let pos of positions) {
    if (pos.space > 0 && fitsWithinViewport(pos.style, childWidth, childHeight, viewportWidth, viewportHeight, margin)) {
      return {style: pos.style, position: pos.position};
    }
  }

  const adjusted = adjustToFitViewport(positions[0].style, childWidth, childHeight, viewportWidth, viewportHeight, margin);
  return { style: adjusted, position: positions[0].position };
}

function fitsWithinViewport(style, childWidth, childHeight, viewportWidth, viewportHeight, margin) {
  const calcLeft = parseFloat(style.left);
  const calcTop = parseFloat(style.top);

  return (
    calcLeft >= margin &&
    calcLeft + childWidth <= viewportWidth - margin &&
    calcTop >= margin &&
    calcTop + childHeight <= viewportHeight - margin
  );
}

function adjustToFitViewport(style, childWidth, childHeight, viewportWidth, viewportHeight, margin) {
  let adjustedStyle = { ...style };
  const calcLeft = parseFloat(style.left);
  const calcTop = parseFloat(style.top);

  adjustedStyle.left = `${Math.max(margin, Math.min(calcLeft, viewportWidth - childWidth - margin))}px`;
  adjustedStyle.top = `${Math.max(margin, Math.min(calcTop, viewportHeight - childHeight - margin))}px`;

  return adjustedStyle;
}
