export const firebaseMedia = {
    "professional_Brandon":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/professional%2FProfessional02.jpg?alt=media&token=c5ecb7d0-c820-457a-bfbd-b57150565382",
    "professional_Maria":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/professional%2FProfessional7.jpg?alt=media&token=e54fdf55-955e-486d-bed5-2797f3801a66",
    "professional_Diana":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/professional%2FProfessional3.webp?alt=media&token=c2287046-47ae-4607-b965-c4069008b516",
    "professional_Steve":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/professional%2FProfessional01.webp?alt=media&token=c0b7ce02-fde4-47e4-9f7a-a361ef7a089b",
    "professional_Chase":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/professional%2FChase.svg?alt=media&token=a2356e2f-c9f4-4f48-a3eb-e21387ca609e",
    "professional_Rocket":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/professional%2FMortgage.png?alt=media&token=11238d9b-cb3a-4d21-8827-7c8dae16c7d5",
    "professional_Fidelity":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/professional%2FFidelity-Logo.png?alt=media&token=36baa745-b7b5-444c-b73d-f51957bf043e",
    "professional_Metlife":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/professional%2FMetLife.png?alt=media&token=3ebef972-1838-49bd-bdaf-e513413726a1",
    video_amibeatingmarket:"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/videos%2FAmIbeatingMarket.mp4?alt=media&token=85b336ee-c549-40c6-b94d-87c40cd6dc18",
    video_canibuyhouse:"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/videos%2FCanIBuyaHouse.mp4?alt=media&token=1bfa24db-8387-4628-87f5-1079c326f5a2",
    video_portfoliofit:"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/videos%2FOpportunityPortfolio.mp4?alt=media&token=bbb80045-2a31-44ab-81a8-42d406e350d2",
    video_howfinancially:"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/videos%2FHowAmIdoing_.mp4?alt=media&token=dce37109-2817-4eab-b933-15c165f48270",
    "report_market":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/report%2FMarketAI.webp?alt=media&token=9efb9b6b-4891-40c2-ba75-3ebf21a922d4",
    "profile_milind":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/team%2FMilindProfilePic.jpg?alt=media&token=a6781018-8330-4dd5-b2d6-ba9c267e2a05",
    "profile_yash":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/team%2Fprofile_yash.jpg?alt=media&token=aad5101d-da60-4193-b3b9-38ffe0bb3342",
    "profile_louis":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/team%2FLouisGagnon.gif?alt=media&token=0b35d9a5-a7a3-4caa-b3ec-78f73941369c",
    "social_financialPlanning":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/social%2FFinancialPlanningFeed.webp?alt=media&token=5e8138b0-0516-4054-81a8-eacf6c71ef50",
    "social_crypto":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/social%2FCryptoFeed.webp?alt=media&token=6da98a41-5976-4db3-ae05-87f75385803a",
    "social_stocks":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/social%2FStockSocialFeed.webp?alt=media&token=5233599f-43fe-404d-bec7-035b03741d72",
    "landing_goldCoins":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/landing%2F%E2%80%94Pngtree%E2%80%94gold%20coin%20number%20money%20dollar_5324212.png?alt=media&token=2938d049-f42e-479f-b00c-72fcd094905a",
    "landing_about":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/landing%2F3a73ad4817e9230e67e6933101e05384.png?alt=media&token=89d01db9-bd6f-4707-984c-a09d3b5f4cf3",
    "demoprofile_alex":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/demoProfile%2Fcfca6b67ad1faac49e491de3f2a5ed83.png?alt=media&token=c4e644aa-e85b-45fb-80a5-1b10faaf2ba3",
    "demoprofile_vera":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/demoProfile%2Ff818cbd752948685e17e4aede61a1d3c.png?alt=media&token=24ce9d92-3193-48b3-af88",
    "demoprofile_timtara":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/demoProfile%2Fkisspng-login-manager-computer-icons-stx-process-equipment-manager-5ac4b0cf9ceb93.6916016915228397596428.png?alt=media&token=4541abfb-f646-407e-8dd7-c86d5e1da782",
    "demoprofile_gabriel":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/demoProfile%2Fkisspng-makeover-cosmetics-face-hairstyle-cheek-5af7a72f0d34f4.6719145915261796310541.png?alt=media&token=04777ffd-a0a8-47d6-a9b1-a8a26e7d6cb5",
    "demoprofile_harper":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/demoProfile%2F5edd132023982aef4541685fd554d8b5.png?alt=media&token=832a98e1-baa8-4860-a325-7e7f360862a4",
    "demoprofile_stephan":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/demoProfile%2Fkisspng-computer-icons-clip-art-portable-network-graphics-islamic-boarding-school-5c5f372537a662.046809281549743909228.png?alt=media&token=8797acc3-4055-46a3-a403-79b3f243b8c6",
    "icon_bond":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/icons%2Fbonds.png?alt=media&token=5686e272-b683-474a-b13a-90aaad65cda7",
    "icon_stocks":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/icons%2Fstocks.png?alt=media&token=4ef60591-bc4b-48df-af70-16d43a03703a",
    "icon_crypto":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/icons%2Fcryptocurrency.png?alt=media&token=4230d172-7f8d-4e13-84bc-f0b13806684b",
    "icon_etf":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/icons%2FetfIcon.png?alt=media&token=27908865-baf9-4d25-8975-001e8c3cc0c5",
    "icon_forex":"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/icons%2Fforex.png?alt=media&token=dc01ed76-4f81-45c2-a564-2503007864f7",
    video_backgroundSpotlight:"https://firebasestorage.googleapis.com/v0/b/autonomy-finance.firebasestorage.app/o/videos%2FBokeh.mp4?alt=media&token=f8d77e10-f1bd-47b9-8e1c-9fb80522e791",
}