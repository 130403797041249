import React from "react";
import { FinancialProductInfo } from "./info";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useNavigate } from "react-router-dom";
export const categories = [
  {category:"Banking",products: ["SavingsAccount", "CreditCard"],icon:<AccountBalanceIcon/>},
  // {category:"Investing",products: ["Stocks", "MutualFunds"],icon:<AccountBalanceWalletIcon/>},
  {category:"Loans",products: ["PersonalLoans", "Mortgages", "StudentLoans"],icon:<AccountBalanceWalletIcon/>},
  {category:"Insurance",products: ["CarInsurance", "LifeInsurance", "HomeInsurance"],icon:<AccountBalanceWalletIcon/>},
];


export default function FinancialProductsChatUI() {
  const navigate = useNavigate()
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"2rem",alignItems:"center",padding:"1rem"}}>
      {categories.map((item,index) => 
      <div key={index} style={{display:"flex",gap:"1rem",flexWrap:"wrap"}}>
        <div style={{background:"#fafafa",borderRadius:"10px"
          ,width:"300px",minHeight:"200px",padding:"1rem",display:"flex",alignItems:"center"
          ,justifyContent:"center",boxSizing:"border-box",gap:"1rem"}}>
        {item.icon} <h4 style={{fontWeight:"bold"}}>{item.category}</h4>
        </div>
        <div style={{display:"flex",flexDirection:"column",gap:"1rem",maxWidth:"400px",minWidth:"400px",cursor:"pointer"}}>
        {FinancialProductInfo.filter(obj=>item.products.includes(obj.productName)).map((product,index) =>
        <div onClick={()=>navigate(`/FinancialProducts/${product.productName}`)} className="card-hover" key={index} style={{display:"flex",padding:"1rem",background:"#fafafa",borderRadius:"10px",width:"300px",alignItems:"center",boxSizing:"border-box"}}>
          <h4 style={{flex:1}}>{product.heading}</h4>
         <KeyboardArrowRightIcon sx={{color:"gray"}}/>
          </div>)}
          </div>
      </div>)}
      </div>
  )
}
