import React from 'react';

import { useAppInfo } from 'AppState';
import { formatNumber } from 'utils/utilGeneral';

import { Typography } from '@mui/material';

import TemplateAction from './TemplateAction';
import { calculateTotal } from './utils';

export default function SpendingMore() {
    const { stateMyFinancials } = useAppInfo();
    const { currency, summaryStats } = stateMyFinancials;

    const spendingRate =
        summaryStats.totalExpense?.m > 0 && summaryStats.totalIncome?.m > 0
            ? (summaryStats.totalExpense?.m / (summaryStats.totalIncome?.m || 1)) * 100
            : undefined;

    const isHighSpendingRate = spendingRate && spendingRate >= 95;

    return (
        <TemplateAction
            actionHeading={"Here are some steps to bring your spending under control and create room for savings:"}
            actions={[
                { label: "Set a Monthly Budget", link: "/MyFinancials/Budget" },
                { label: "Automate Savings", link: "/MyFinancials/AutomatedSavings" },
            ]}
        >
            <Typography variant="body1" gutterBottom>
                Your current spending rate is{" "}
                <strong>{spendingRate ? `${formatNumber(spendingRate)}%` : "N/A"}</strong>.
                {isHighSpendingRate
                    ? " This is above the recommended limit of 95%, meaning you may not have enough left for savings or unexpected expenses."
                    : " Great job keeping your spending rate below 95%! A lower spending rate helps you save more for future goals."}
            </Typography>

        </TemplateAction>
    );
}




export function CashCoverage() {
    const { stateMyFinancials } = useAppInfo();
    const { assets, summaryStats, currency } = stateMyFinancials;

    const totalCash = calculateTotal(assets, ["Cash"]);
    const monthlyExpense = summaryStats.totalExpense?.m || 1; // Avoid division by 0
    const cashCoverage = totalCash / monthlyExpense;

    return (
        <TemplateAction
            actionHeading={"Here are some steps to Improve Your Cash Coverage:"}
            actions={[
                { label: "Automate Monthly Contributions" },
                { label: "Build a Cash Reserve" },
            ]}
        >
            {totalCash === 0 ? (
                <Typography variant="body1" gutterBottom>
                    You currently have no cash in your account. This puts you at risk of not being able to cover essential expenses in emergencies. Start building a cash reserve to ensure you’re prepared for unexpected situations.
                </Typography>
            ) : cashCoverage < 1 ? (
                <Typography variant="body1" gutterBottom>
                    Your current cash balance is <strong>{`${currency?.symbol}${formatNumber(totalCash)}`}</strong>, covering only <strong>{`${formatNumber(cashCoverage * 100)}%`}</strong> of your monthly expenses. It’s recommended to have at least 3–6 months of cash coverage for emergencies. Consider building your reserve over time.
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Great job! Your current cash balance of <strong>{`${currency?.symbol}${formatNumber(totalCash)}`}</strong> provides a coverage of <strong>{`${formatNumber(cashCoverage * 100)}%`}</strong>, which is sufficient to cover your monthly expenses. Keep maintaining this level of preparedness.
                </Typography>
            )}
        </TemplateAction>
    );
}


export function MinMonthlyCash() {
    const { stateMyFinancials } = useAppInfo();
    const { summaryStats, currency } = stateMyFinancials;

    const totalIncome = summaryStats.totalIncome?.m || 0; // Monthly income
    const monthlyCashflow = summaryStats.totalNetCashflow?.m || 0; // Monthly net cash flow
    const hasPositiveCashflow = monthlyCashflow > 0;
    const cashflowPercentage = totalIncome > 0 ? (monthlyCashflow / totalIncome) * 100 : undefined;

    return (
        <TemplateAction
            actionHeading={
                hasPositiveCashflow
                    ? "Here are some steps to keep your cashflow positive:"
                    : "Here are some steps to improve your monthly cashflow:"
            }
            actions={[
                { label: "Automate Monthly Savings" },
                { label: "Budget Expenses" },
            ]}
        >
            {totalIncome > 0 ? (
                <Typography variant="body1" gutterBottom>
                    Your monthly income is <strong>{`${currency?.symbol}${formatNumber(totalIncome)}`}</strong>. 
                    Your current cash flow is <strong>{`${currency?.symbol}${formatNumber(monthlyCashflow)}`}</strong>, which is <strong>{cashflowPercentage ? `${formatNumber(cashflowPercentage)}%` : "N/A"}</strong> of your income.
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Your monthly cash flow is <strong>{`${currency?.symbol}${formatNumber(monthlyCashflow)}`}</strong>, but income details are not available. To ensure financial stability, focus on maintaining a positive cash flow.
                </Typography>
            )}

            {hasPositiveCashflow ? (
                <Typography variant="body1" gutterBottom>
                    Great job maintaining a positive cash flow! Aim to save at least 10–20% of your income to grow your financial reserves. Automate your savings and stay on budget to ensure steady progress.
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Your expenses currently exceed your income. To improve your cash flow, consider reducing discretionary expenses and prioritizing essential costs. Ideally, aim to save 10–20% of your income as cash flow each month.
                </Typography>
            )}
        </TemplateAction>
    );
}


export function IncomeStability() {
    const { stateMyFinancials } = useAppInfo();
    const { summaryStats, currency } = stateMyFinancials;

    const totalIncome = summaryStats.totalIncome?.m || 0; // Monthly income
    const monthlyCashflow = summaryStats.totalNetCashflow?.m || 0; // Monthly net cash flow
    const hasStableIncome = totalIncome > 0;

    return (
        <TemplateAction
            actionHeading={
                hasStableIncome
                    ? "Here are some steps to diversify and strengthen your income:"
                    : "Here are some ways to establish a stable periodic income:"
            }
            actions={[
                { label: "Start a Side Hustle" },
                { label: "Invest for Passive Income" },
            ]}
        >
            {hasStableIncome ? (
                <Typography variant="body1" gutterBottom>
                    Your current monthly income is <strong>{`${currency?.symbol}${formatNumber(totalIncome)}`}</strong>. 
                    While this provides stability, diversifying your income sources can help protect against job loss or unexpected expenses. Consider building additional income streams to strengthen your financial resilience.
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    You currently don’t have a steady periodic income. Establishing a reliable income source is essential for covering expenses and achieving financial goals. Here are some strategies to get started:
                </Typography>
            )}

            <Typography variant="body1" gutterBottom>
                Consider these strategies to improve your income stability:
            </Typography>
            <ul>
                <li><strong>Start a Side Hustle:</strong> Use your skills or hobbies to generate extra income through freelancing, consulting, or selling products.</li>
                <li><strong>Invest for Passive Income:</strong> Explore investments like dividend-paying stocks, REITs (Real Estate Investment Trusts), or peer-to-peer lending to earn a steady income.</li>
                <li><strong>Rent Out Assets:</strong> If you own a property, vehicle, or equipment, consider renting it out to generate periodic income.</li>
                <li><strong>Upskill or Retrain:</strong> Invest in education or training to qualify for higher-paying jobs or freelance opportunities.</li>
                <li><strong>Offer Services Locally:</strong> Part-time jobs, tutoring, or gig-based platforms can help generate income in your area.</li>
            </ul>
        </TemplateAction>
    );
}
