import useFindDefaultPortfolio
  from 'data/user/portfolio/useFindDefaultPortfolio';
import useFindDummyPortoflio from 'data/user/portfolio/useFindDummyPortfolio';
import useFindPortfolio from 'data/user/portfolio/useFindPortfolio';

import usePortfolioMultipleSummary
  from '../Portfolio/hooks/usePortfolioMultipleSummary';
import usePortfolioSummaryDetails
  from '../Portfolio/hooks/usePortfolioSummaryDetails';
import usePortfolioTrendDetails
  from '../Portfolio/hooks/usePortfolioTrendDetails';

export default function useSetPortfolio() {
    
    useFindDefaultPortfolio()
    useFindDummyPortoflio()
    useFindPortfolio()
    usePortfolioSummaryDetails()
    usePortfolioTrendDetails()
    usePortfolioMultipleSummary() 
 
}

export function UpdateAssetWithPortfolio({portfolioResults,assets}) {

    if (portfolioResults) {
        // Create a new array to update assets, start with the current assets
        let assetUpdate = [...assets.filter(asset => asset.custody !== "Autonomy")];
       
        const today = new Date();
        Object.entries(portfolioResults).forEach(([accountNameSel, portfolioDetails]) => {
          const nav = Number(portfolioDetails?.portfolioOverviewAll?.NAV || 0);
          const existingAssetIndex = assetUpdate.findIndex(obj => obj.id === accountNameSel);
    
          if (existingAssetIndex !== -1) {
            // Update existing asset immutably
            assetUpdate[existingAssetIndex] = {
              ...assetUpdate[existingAssetIndex],
              amount: nav,
              date: today,
              edit: false,
              custody:"Autonomy"
            };
          } else {
            // Add new portfolio asset
            assetUpdate  = [...assetUpdate,{
              id: accountNameSel,
              type: "Investment Portfolio",
              assetType: "Investment - Liquid",
              name: accountNameSel,
              amount: nav,
              date: today,
              currentAllocation: 0,
              targetAllocation: 0,
              differenceAllocation: 0,
              return: 10,
              volatility: 10,
              forecastPeriod: 30,
              edit: false,
              custody:"Autonomy"
            }];
          }
        });
    
        // Dispatch the updated assets list only once after all modifications
        return assetUpdate
      }

      return []
}