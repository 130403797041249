

import { openaiAPI } from 'appState/initialStateAssistant';
import React, { useEffect, useState } from 'react'

export default function useAssistantSummarize({message}){
    const [aiSummary, setAISummary]= useState({loading:true,answer:""});
    useEffect(() => {
      async function handleAISummary() {
        setAISummary({ loading: true, answer: "" });
    
        let finalAnswer = "";
        
        for await (const chunk of fetchAISummarize({ message })) {
            finalAnswer += chunk; // Accumulate the response
            setAISummary({ loading: true, answer: finalAnswer }); // Update UI progressively
        }
    
        setAISummary({ loading: false, answer: finalAnswer }); // Finalize
    }


          if (message) {
            handleAISummary()
          }
        }, [message])

    return aiSummary
}

export async function* fetchAISummarize({ messageType = "text", message = "Summarise this image" }) {
  const response = await openaiAPI.chat.completions.create({
      model: "gpt-4o-mini",
      stream: true,
      messages: [
          {
              role: "developer",
              content: [{ type: "text", text: "Give answers in a clear, concise, and easy-to-understand format." }],
          },
          {
              role: "user",
              content: [{ type: "text", text: message }],
          },
      ],
  });

  for await (const chunk of response) {
      const answer = chunk.choices[0]?.delta?.content;
      if (answer) yield answer;  // Yield each streamed answer
  }
}


// async function handleAISummary() {
//   // Set to loading
//   setAISummary({ loading: true, answer: "" })

//   // Fetch AI summary
//   const { answer } = await fetchAISummarize({
//     message: message,
//   })

//   // Update the state with the result
//   setAISummary({ loading: false, answer })
// }

// export async function fetchAISummarize({messageType="text",message="Summarise this image",stream=true}) {
//     let loading = true
//     let messageDeveloper = ""
//     const response = await openaiAPI.chat.completions.create({
//         model: "gpt-4o-mini",
//         stream:stream,
//         messages: [
//           {
//             role: "developer",
//             content: [
//               { type: "text", text:"Give answers in a clear, concise, and easy-to-understand format." },
//             ],
//           },
//           {
//             role: "user",
//             content: [
//               { type: "text", text: message },
//             ],
//           },
//         ],
//       });
//       loading = false
//       let answer
//       for await (const chunk of response) {
//         // return {loading,answer};
//         answer = chunk.choices[0].delta.content
//         yield answer
//       }
//       // let answer = response.choices[0]?.message?.content 
      
// }