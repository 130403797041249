import { useAppInfo } from 'AppState';
import React, { useEffect, useState } from 'react';

const ConnectEmail = () => {
  const [status, setStatus] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const {stateApp} = useAppInfo()
  const {userId} = stateApp

  const handleConnect = (provider) => {
    // Pass the userId in the query params
    window.location.href = `http://localhost:8000/oauth/initiate/${provider}/?userId=${userId}`;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');

    if (status === 'success') {
      setStatus('Connected successfully!');
      setIsConnected(true);
    } 
    else{
      setStatus('Authentication failed. Please try again.');
    }
  }, [userId]);

  return (
    <div>
      <h2>Connect Your Email Account</h2>
      {isConnected ? (
        <p>{status}</p>
      ) : (
        <>
          <p>Connect your email to automate tasks.</p>
          <button onClick={() => handleConnect('google')}>Connect with Google</button>
        </>
      )}
    </div>
  );
};

export default ConnectEmail;
