import React, {
  useMemo,
  useState,
} from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
  Avatar,
  Button,
  Chip,
  TextField,
} from '@mui/material';

import FilterModal from '../components/FilterModal';
import PageHeader from '../components/PageHeader';
import styles from './Pulse.module.css';
import PulseAction from './PulseAction';
import { pulseData } from './pulseData';

export default function Pulse() {
    const [expandedClients, setExpandedClients] = useState({});
    const [selectedMarketEventTypes, setSelectedMarketEventTypes] = useState([]);
    const [activeFiltersCount, setActiveFiltersCount] = useState(0);
    
    // Update initial date range to last 15 days to next 15 days
    const getDefaultDateRange = () => {
        const now = new Date();
        const startDate = new Date(now);
        startDate.setDate(now.getDate() - 5);
        const endDate = new Date(now);
        endDate.setDate(now.getDate() + 30);
        return ['Custom', [startDate, endDate]];
    };

    const [dateRange, setDateRange] = useState(getDefaultDateRange());
    const [customDateRange, setCustomDateRange] = useState(getDefaultDateRange()[1]);
    const [expandedActions, setExpandedActions] = useState({});
    const [actionPopoverAnchor, setActionPopoverAnchor] = useState(null);
    const [displayMode, setDisplayMode] = useState('expand');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [dateAnchorEl, setDateAnchorEl] = useState(null);
    const [filters, setFilters] = useState({
        marketEventType: null,
        dateRange: ['Next 30 Days', [0, 1]],
        customDateRange: [new Date(), addMonths(new Date(), 1)]
    });
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedImpact, setSelectedImpact] = useState([]);

    const toggleClientList = (itemId) => {
        setExpandedClients(prev => ({
            ...prev,
            [itemId]: !prev[itemId]
        }));
    };

    const toggleActionList = (itemId) => {
        setExpandedActions(prev => ({
            ...prev,
            [itemId]: !prev[itemId]
        }));
    };


    const handleDateRangeClick = (event) => {
        setDateAnchorEl(event.currentTarget);
    };

    const handleDateRangeClose = () => {
        setDateAnchorEl(null);
    };

    const handleDateRangeSelect = (range) => {
        const now = new Date();
        const [start, end] = QUICK_DATE_RANGES[range];
        let startDate, endDate;
        
        if (range === 'Beyond 6M') {
            startDate = addMonths(now, start);
            endDate = addMonths(now, end);
        } else if (start <= 0 && end === 0) {
            // Past range (e.g., Past 1M)
            startDate = addMonths(now, start);
            endDate = now;
        } else if (start === 0) {
            // Future range (e.g., Next 1M)
            startDate = now;
            endDate = addMonths(now, end);
        }

        setDateRange([range, [startDate, endDate]]);
        setCustomDateRange([startDate, endDate]);
        handleApplyFilters();
    };

    const handleCustomDateChange = (newRange) => {
        if (newRange[0] && newRange[1]) {
            setCustomDateRange(newRange);
            setDateRange(['Custom', newRange]);
        }
    };

    const toggleActionDisplay = (itemId, event) => {
        if (displayMode === 'popover') {
            setActionPopoverAnchor(actionPopoverAnchor ? null : event.currentTarget);
        } else {
            setExpandedActions(prev => ({
                ...prev,
                [itemId]: !prev[itemId]
            }));
        }
    };

    // Add function to close action dropdown
    const handleCloseActionDropdown = (itemId) => {
        setExpandedActions(prev => ({
            ...prev,
            [itemId]: false
        }));
    };

    // Calculate event counts based only on date range
    const dateFilteredPulseData = useMemo(() => {
        return pulseData.filter(item => {
            const itemDate = new Date(item.timestamp);
            if (dateRange[0] === 'Custom' && customDateRange[0] && customDateRange[1]) {
                return itemDate >= customDateRange[0] && itemDate <= customDateRange[1];
            } else if (dateRange[1]) {
                const [startDate, endDate] = dateRange[1];
                return itemDate >= startDate && itemDate <= endDate;
            }
            return true;
        });
    }, [dateRange, customDateRange]);

    // Filter and sort pulses
    const filteredPulseData = useMemo(() => {
        const now = new Date();
        
        return pulseData
            .filter(item => {
                // Filter by search term
                if (searchTerm) {
                    const searchLower = searchTerm.toLowerCase();
                    const hasMatchingClient = item.affectedClients.some(client => 
                        client.name.toLowerCase().includes(searchLower) ||
                        client.portfolio.toLowerCase().includes(searchLower)
                    );
                    const matchesAction = (item.actions || []).some(action =>
                        action.description.toLowerCase().includes(searchLower)
                    );
                    const matchesTitle = item.title.toLowerCase().includes(searchLower);
                    const matchesDetails = item.details.toLowerCase().includes(searchLower);
                    if (!hasMatchingClient && !matchesAction && !matchesTitle && !matchesDetails) {
                        return false;
                    }
                }

                // Filter by market event types
                if (selectedMarketEventTypes.length > 0) {
                    if (!selectedMarketEventTypes.includes(item.type)) return false;
                }

                // Filter by impact level
                if (selectedImpact.length > 0) {
                    if (!selectedImpact.includes(item.impact)) return false;
                }

                // Filter by date range
                const itemDate = new Date(item.timestamp);
                if (dateRange[0] === 'Custom' && customDateRange[0] && customDateRange[1]) {
                    return itemDate >= customDateRange[0] && itemDate <= customDateRange[1];
                } else if (dateRange[1]) {
                    const [startDate, endDate] = dateRange[1];
                    return itemDate >= startDate && itemDate <= endDate;
                }

                return true;
            })
            .sort((a, b) => {
                const dateA = new Date(a.timestamp);
                const dateB = new Date(b.timestamp);
                
                // Check if dates are current (today)
                const isCurrentA = dateA.toDateString() === now.toDateString();
                const isCurrentB = dateB.toDateString() === now.toDateString();
                
                // Sort current items first
                if (isCurrentA && !isCurrentB) return -1;
                if (!isCurrentA && isCurrentB) return 1;
                
                // For non-current items, sort by date (newest first)
                return dateB - dateA;
            });
    }, [searchTerm, selectedMarketEventTypes, dateRange, customDateRange, selectedImpact]);

    // Calculate affected clients count
    const affectedClientsCount = useMemo(() => {
        return new Set(filteredPulseData.flatMap(item => 
            item.affectedClients.map(client => client.id)
        )).size;
    }, [filteredPulseData]);

    const getImpactColor = (impact) => {
        switch (impact.toLowerCase()) {
            case 'high':
                return styles.highImpact;
            case 'medium':
                return styles.mediumImpact;
            case 'low':
                return styles.lowImpact;
            default:
                return '';
        }
    };

    const getIcon = (type) => {
        switch (type) {
            case 'Market Change':
                return <TrendingUpIcon />;
            case 'Industry News':
                return <NewspaperIcon />;
            case 'Regulatory':
                return <AccountBalanceIcon />;
            case 'Mortgage Update':
                return <HomeIcon />;
            case 'Insurance Alert':
                return <SecurityIcon />;
            case 'Client Life Event':
                return <EventIcon />;
            case 'Compliance Alert':
                return <GavelIcon />;
            case 'Economy':
                return <ShowChartIcon />;
            default:
                return null;
        }
    };

    const handleApplyFilters = () => {
        const newFilters = {
            marketEventTypes: selectedMarketEventTypes,
            dateRange: dateRange,
            customDateRange: customDateRange,
            impact: selectedImpact
        };
        setFilters(newFilters);
        
        // Calculate active filters count - only count non-empty filters
        const count = [
            selectedMarketEventTypes.length > 0,
            dateRange[0] !== 'Next 1M' && dateRange[0] !== 'Custom',
            selectedImpact.length > 0
        ].filter(Boolean).length;
        
        setActiveFiltersCount(count);
        setFilterModalOpen(false);
    };

    const handleMarketEventTypeSelect = (eventType) => {
        setSelectedMarketEventTypes(prev => {
            const newTypes = prev.includes(eventType) 
                ? prev.filter(type => type !== eventType)
                : [...prev, eventType];
            
            // Calculate active filters count immediately after selection
            const count = [
                newTypes.length > 0,
                dateRange[0] !== 'Next 1M' && dateRange[0] !== 'Custom',
                selectedImpact.length > 0
            ].filter(Boolean).length;
            
            setActiveFiltersCount(count);
            return newTypes;
        });
    };

    const handleResetFilters = () => {
        setSelectedMarketEventTypes([]);
        const defaultRange = getDefaultDateRange();
        setDateRange(defaultRange);
        setCustomDateRange(defaultRange[1]);
        setSelectedImpact([]);
        setActiveFiltersCount(0);
    };

    return (
        <div className={styles.container}>
            <PageHeader
                title="Pulse"
                subtitle={`Clients affected by market and news events`}
                searchPlaceholder="Search clients, portfolios, or actions..."
                searchValue={searchTerm}
                onSearchChange={setSearchTerm}
                onFilterClick={() => setFilterModalOpen(true)}
                activeFiltersCount={activeFiltersCount}
            />

            <div className={styles.marketFilters}>
                {MARKET_EVENT_TYPES.map(eventType => {
                    const eventCount = dateFilteredPulseData.filter(item => item.type === eventType).length;
                    return (
                        <Button
                            key={eventType}
                            sx={{
                                backgroundColor: selectedMarketEventTypes.includes(eventType) ? "#1976d2" : "white",
                                color: selectedMarketEventTypes.includes(eventType) ? "white" : "gray",
                            }}
                            variant={selectedMarketEventTypes.includes(eventType) ? "contained" : "outlined"}
                            size="small"
                            onClick={() => handleMarketEventTypeSelect(eventType)}
                            className={styles.filterButton}
                            endIcon={
                                <Chip 
                                    label={eventCount} 
                                    size="small"
                                    sx={{
                                        color: selectedMarketEventTypes.includes(eventType) ? "white" : "black",
                                        backgroundColor:"#fafafa"
                                    }}
                                    className={styles.countBadge}
                                />
                            }
                        >
                            {eventType}
                        </Button>
                    );
                })}
            </div>

            <div className={styles.impactFilters}>
                {IMPACT_LEVELS.map(impact => {
                    const impactCount = dateFilteredPulseData.filter(item => item.impact === impact).length;
                    return (
                        <Button
                            key={impact}
                            size="small"
                            onClick={() => {
                                setSelectedImpact(prev => {
                                    const newImpact = prev.includes(impact) 
                                        ? prev.filter(i => i !== impact)
                                        : [...prev, impact];
                                    
                                    // Calculate active filters count immediately after selection
                                    const count = [
                                        selectedMarketEventTypes.length > 0,
                                        dateRange[0] !== 'Next 1M' && dateRange[0] !== 'Custom',
                                        newImpact.length > 0
                                    ].filter(Boolean).length;
                                    
                                    setActiveFiltersCount(count);
                                    return newImpact;
                                });
                            }}
                            className={`${styles.filterButton} 
                            ${selectedImpact.includes(impact) ? styles[`${impact.toLowerCase()}ImpactSelected`] : styles[`${impact.toLowerCase()}Impact`]}`}
                            endIcon={
                                <Chip 
                                    label={impactCount} 
                                    size="small"
                                    className={`${styles.countBadge} ${selectedImpact.includes(impact) ? styles.countBadgeSelected :  styles[`${impact.toLowerCase()}Badge`] }`}
                                />
                            }
                        >
                            {impact} Impact
                        </Button>
                    );
                })}
            </div>

            <div className={styles.affectedClientsSummary}>
                <PersonIcon sx={{ fontSize: 18, color: 'black' }} />
                <span>{affectedClientsCount} clients affected</span>
            </div>

            <FilterModal
                open={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                onSearchChange={setSearchTerm}
                searchTerm={searchTerm}
                title="Filter Pulse"
                onApply={handleApplyFilters}
                onReset={handleResetFilters}
            >
                <div className={styles.filterContent}>
                    {/* Search in Modal */}
                    {/* <div className={styles.filterSection}>
                        <h3>Search</h3>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Search in pulse..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div> */}

                    {/* Date Range in Modal */}
                    <div className={styles.filterSection}>
                        <h3>Date Range</h3>
                        <div className={styles.filterChips}>
                            {Object.entries(QUICK_DATE_RANGES).map(([label]) => (
                                <Chip
                                    key={label}
                                    label={label}
                                    onClick={() => handleDateRangeSelect(label)}
                                    color={dateRange[0] === label ? "primary" : "default"}
                                    className={styles.filterChip}
                                />
                            ))}
                        </div>
                        <div className={styles.customDateRange}>
                            <TextField
                                type="date"
                                size="small"
                                value={formatDate(customDateRange[0])}
                                onChange={(e) => handleCustomDateChange([new Date(e.target.value), customDateRange[1]])}
                                className={styles.dateInput}
                            />
                            <span className={styles.dateSeparator}>to</span>
                            <TextField
                                type="date"
                                size="small"
                                value={formatDate(customDateRange[1])}
                                onChange={(e) => handleCustomDateChange([customDateRange[0], new Date(e.target.value)])}
                                className={styles.dateInput}
                            />
                        </div>
                    </div>

                    {/* Market Event Types */}
                    <div className={styles.filterSection}>
                        <h3>Market & News Events</h3>
                        <div className={styles.filterChips}>
                            {MARKET_EVENT_TYPES.map(type => (
                                <Chip
                                    key={type}
                                    label={type}
                                    onClick={() => handleMarketEventTypeSelect(type)}
                                    onDelete={selectedMarketEventTypes.includes(type) ? () => handleMarketEventTypeSelect(type) : undefined}
                                    color={selectedMarketEventTypes.includes(type) ? "primary" : "default"}
                                    className={styles.filterChip}
                                    deleteIcon={<CloseIcon />}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Impact filter section */}
                    <div className={styles.filterSection}>
                        <h3>Impact Level</h3>
                        <div className={styles.filterChips}>
                            {IMPACT_LEVELS.map(impact => (
                                <Chip
                                    key={impact}
                                    label={impact}
                                    onClick={() => setSelectedImpact(prev => 
                                        prev.includes(impact) 
                                            ? prev.filter(i => i !== impact)
                                            : [...prev, impact]
                                    )}
                                    color={selectedImpact.includes(impact) ? "primary" : "default"}
                                    className={styles.filterChip}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </FilterModal>

            <div className={styles.pulseGridRows}>
                {filteredPulseData.sort((a,b)=>new Date(a.timestamp) - new Date(b.timestamp)).map((item) => (
                    <div key={item.id} 
                    style={{borderLeft: item?.impact === "High" ? 
                        "4px solid #FF0000 " : item?.impact === "Medium" ? "4px solid #FFA500" : "4px solid #008000"}}
                    className={styles.pulseCard}
                    >
                          
                            
                        <div style={{width:"100%",display:"flex",gap:"1rem"}}>
                             
                        <div className={styles.pulseContentRow}>
                        <div 
                            style={{width:"100%",display:"flex",flexDirection:"column",gap:"1rem"}}>
                            <div className={styles.dateText}>
                                {formatTimestamp(item.timestamp)}
                            </div>
                            <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                           
                            <div className={styles.categoryText}>
                                {getIcon(item.type)}
                                {item.type}
                            </div>
                            <div className={styles.impactSection}>
                                <span className={`${styles.impact} ${getImpactColor(item.impact)}`}>
                                    {item.impact} Impact
                                    
                                </span>
                            </div>
                           
                            </div>
                          
                           
                            </div>
                        
                            <h3 className={styles.titleRow}>{item.title}</h3>
                            <p className={styles.descriptionRow}>{item.details}</p>

                        

                            <div className={styles.affectedClientsSection}>
                                <div className={styles.affectedClientsHeader}>
                                    <div className={styles.affectedClientsTitle}>
                                        <PersonIcon sx={{ fontSize: 18, color: '#64748B' }} />
                                        Affected Clients
                                    </div>
                                    <span className={styles.affectedClientsCount}>
                                        {item.affectedClients.length} {item.affectedClients.length === 1 ? 'client' : 'clients'}
                                    </span>
                                </div>
                                <div className={styles.clientsGrid}>
                                    {(expandedClients[item.id] 
                                        ? item.affectedClients 
                                        : item.affectedClients.slice(0, 3)).map((client) => (
                                        <div key={client.id} className={styles.clientCard}>
                                            <Avatar className={styles.clientAvatar}>{client.name[0]}</Avatar>
                                            <div className={styles.clientInfo}>
                                                <div className={styles.clientName}>{client.name}</div>
                                                <div style={{ fontSize: '0.75rem', color: '#64748B' }}>
                                                    Last contact: <span style={{ fontSize: '0.75rem', color: '#64748B' }}>{formatLastCommunication(client.lastCommunication)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {item.affectedClients.length > 3 && !expandedClients[item.id] && (
                                    <button 
                                        className={styles.showMoreButton}
                                        onClick={() => toggleClientList(item.id)}
                                    >
                                        <PersonIcon sx={{ fontSize: 16 }} />
                                        Show {item.affectedClients.length - 3} more clients
                                    </button>
                                )}
                                {expandedClients[item.id] && item.affectedClients.length > 3 && (
                                    <button 
                                        className={styles.showMoreButton}
                                        onClick={() => toggleClientList(item.id)}
                                    >
                                        <PersonIcon sx={{ fontSize: 16 }} />
                                        Show less
                                    </button>
                                )}
                            </div>
                        </div>
                            <PulseAction item={item}/>
                    </div>
                    </div>
                ))}
            </div>

        </div>
    );
} 


// Date utility functions
const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const addMonths = (date, months) => {
    const d = new Date(date);
    d.setMonth(d.getMonth() + months);
    return d;
};

const subMonths = (date, months) => {
    const d = new Date(date);
    d.setMonth(d.getMonth() - months);
    return d;
};

const isWithinInterval = (date, { start, end }) => {
    const d = new Date(date);
    return d >= start && d <= end;
};

const QUICK_DATE_RANGES = {
    'Next 1M': [0, 1],
    'Next 3M': [0, 3],
    'Next 6M': [0, 6],
    'Past 1M': [-1, 0],
    'Past 3M': [-3, 0],
    'Past 6M': [-6, 0],
    'Beyond 6M': [-24, -6]  // Show everything older than 6 months
};

const MARKET_EVENT_TYPES = [
    'Market Change',
    'Regulatory',
    'Economy',
    'Mortgage Update',
    'Insurance Alert'
];

// Add formatTimestamp function
const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
};

// Update formatLastCommunication function
const formatLastCommunication = (timestamp) => {
    if (!timestamp) return 'No recent contact';
    try {
        const date = new Date(timestamp);
        if (isNaN(date.getTime())) return 'No recent contact';
        
        return date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    } catch (error) {
        return 'No recent contact';
    }
};

const IMPACT_LEVELS = ['High', 'Medium', 'Low'];