import React from 'react';

import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InsightsIcon from '@mui/icons-material/Insights';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import StreamIcon from '@mui/icons-material/Stream';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Typography,
} from '@mui/material';

import HomeFooterBig from './HomeFooterBig';
import QuestionsHome from './QuestionsHome';

export default function WhyUs() {
  
  const whyUsMain = [{
    title:"We protect Your Data - giving you full control",
    description:"We safeguard your financial data, giving you full authority over how it’s shared.",
    navigate:"your-data",},
  ]

  return (
    <div style={{overflow:"hidden",width:"100%",boxSizing:"border-box",position:"relative"
        ,display:"flex",flexDirection:"column",gap:"1rem"}}>
        <div style={{padding:"4rem",display:"flex",flexDirection:"column",gap:"1rem"}}>    
        <div style={{display:"flex",flexDirection:"column",gap:"1rem",alignItems:"center"}}>
        <h1 style={{fontSize:"4rem",color:"black",maxWidth:"70vw"}}>Why Us?</h1>
        <h2 style={{maxWidth:"70vw",fontWeight:"normal"}}>We empower you to achieve financial autonomy with personalized insights and guidance. Your privacy, security, and success are our priorities—not managing your assets or promoting more trades. Your Money. Your Choices. Your Financial Autonomy.</h2>
        </div>
        <WhyUsSection/>
        </div>
       
        <WhyUsPrivacy/>
        <UsVsOthers/>
        <WhyUsEmpower/>
      
        <QuestionsHome/>
        <HomeFooterBig/>
    </div>
  )
}


const scoreData = [
  {
    icon: <FavoriteIcon style={{ fontSize: "8rem", color: "#66BB6A" }} />,
    title: "Personalized & Real-Time Financial Management",
    points: [
      "Tailored solutions for your unique goals.",
      "Dynamic updates as your life and markets evolve.",
    ],
  },
  {
    icon: <InsightsIcon style={{ fontSize: "8rem", color: "#42A5F5" }} />,
    title: "Information Focused & Unbiased",
    points: [
      "Empowering your decisions, not pushing trades.",
      "Clear insights tailored to your financial context.",
    ],
  },
  {
    icon: <SecurityIcon style={{ fontSize: "8rem", color: "#FFA726" }} />,
    title: "Secured",
    points: [
      "Robust encryption to protect your data.",
      "Transparent pricing and no hidden fees.",
    ],
  },
  {
    icon: <SettingsSuggestIcon style={{ fontSize: "8rem", color: "#607D8B" }} />,
    title: "Control",
    points: [
      "You decide who has access to your data.",
      "You have complete control over your privacy.",
    ],
  },
  {
    icon: <StreamIcon style={{ fontSize: "8rem", color: "#AB47BC" }} />,
    title: "Holistic & Integrated",
    points: [
      "Connecting investments, goals, and risks seamlessly.",
      "A comprehensive approach to manage your finances.",
    ],
  },
  {
    icon: <AccessibilityIcon style={{ fontSize: "8rem", color: "#FF7043" }} />,
    title: "Accessible & User-Friendly",
    points: [
      "Simple design for all expertise levels.",
      "Intuitive features accessible from any device.",
    ],
  },
  {
    icon: <VisibilityIcon style={{ fontSize: "8rem", color: "#29B6F6" }} />,
    title: "Transparent & Trustworthy",
    points: [
      "Full transparency in data and pricing.",
      "Clear guidance with no hidden surprises.",
    ],
  },
];



function WhyUsPrivacy(){

  return(<>
   <div id="whyus-privacy"  style={{display:"flex",flexDirection:"column",gap:"1rem"
    ,alignItems:"center",marginTop:"4rem"}}>
       <h1 style={{fontSize:"3rem",color:"black",maxWidth:"70vw",fontWeight:"600"}}>We Protect Your Privacy</h1>
       <h2 style={{maxWidth:"70vw",fontWeight:"normal"}}> 
       We prioritize safeguarding your financial data, ensuring you stay in full control of how it’s shared. 
        </h2>

      
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "2rem",
          flexWrap: "wrap",
          position:"relative",width:"100%",paddingTop:"2rem"
        }}
      >

        {scoreData.filter(obj=>["Secured","Control","Transparent & Trustworthy"].includes(obj.title)).map(({ icon, title, points }, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              padding: "20px",
            width: "350px",
              minWidth:"300px",
              color:"rgba(0, 0, 0, 0.5)",
              // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              position:"relative",
            }}
          >

            <div style={{display:"flex",
              flexDirection:"column",gap:"1rem",zIndex:10,position:"relative",alignItems:"center"}}>
            <div style={{ marginBottom: "20px" }}>{icon}</div>
            <h3 style={{ fontSize: "1.5rem",color:"black"}}>{title}</h3>
            <div>
            {points.map((point, idx) => (
                <h4 key={idx} style={{ marginBottom: "10px",textAlign:"left",}}>
                  {point}
                </h4>
              ))}
              </div>
              </div>
          </div>
        ))}
      </div>
      </div>
        
  </>)
}

function WhyUsEmpower(){

  return(<>
   <div id="whyus-empower" style={{display:"flex",flexDirection:"column"
    ,gap:"1rem",alignItems:"center",margin:"4rem 0",minHeight:"80vh"}}>
       <h1 style={{fontSize:"3rem",color:"black",maxWidth:"70vw",fontWeight:"600"}}>Our Mission - Empowering You!</h1>
       <h2 style={{maxWidth:"70vw",fontWeight:"normal"}}> We are not here to grow our asset under management or encourage more trading. <br/>
           We provide you with high-quality insights and guidance, empowering you to take stock of your finances.
       
        </h2>

      
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "2rem",
          flexWrap: "wrap",
          position:"relative",width:"100%",paddingTop:"2rem"
        }}
      >

        {scoreData.filter(obj=>!["Control","Secured","Transparent & Trustworthy"].includes(obj.title)).map(({ icon, title, points }, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              padding: "20px",
            width: "350px",
              minWidth:"300px",
              color:"rgba(0, 0, 0, 0.5)",
              // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              position:"relative",
            }}
          >

            <div style={{display:"flex",
              flexDirection:"column",gap:"1rem",zIndex:10,position:"relative",alignItems:"center"}}>
            <div style={{ marginBottom: "20px" }}>{icon}</div>
            <h3 style={{ fontSize: "1.5rem",color:"black"}}>{title}</h3>
            <div>
            {points.map((point, idx) => (
                <h4 key={idx} style={{ marginBottom: "10px",textAlign:"left",}}>
                  {point}
                </h4>
              ))}
              </div>
              </div>
          </div>
        ))}
      </div>
      </div>
        
  </>)
}


function UsVsOthers () {
  const traditionalFinanceVsUs = [
    {
      category: "Goals",
      traditional: "Goals are set, but you’re left to figure out how to reach them.",
      us: "We spot extra cash and automatically route it to your goals."
    },
    {
      category: "Mortgage",
      traditional: "You only see a list of payments with no guidance on better deals.",
      us: "We find lower interest rates than your current mortgage and streamline refinancing."
    },
    {
      category: "Insurance",
      traditional: "You know your current coverage, but not if you’re underinsured.",
      us: "We identify coverage gaps and handle the application process for you."
    },
    {
      category: "Portfolio",
      traditional: "Raw data on your assets without proactive rebalancing tools.",
      us: "We automatically rebalance your portfolio to keep you on track toward your goals."
    },
    {
      category: "Investing",
      traditional: "You see market updates, but must figure out your own trades.",
      us: "We analyze opportunities (like a strong S&P 500 buy) and automate trading for you."
    }
  ];

  // Inline styles for demonstration purposes
  // For production, consider using CSS modules, styled-components, or a CSS preprocessor.
  const styles = {
    container: {
      // margin: '0 auto',
      // maxWidth: '1200px',
      padding: '20px',display:"flex",flexDirection:"column",gap:"1rem",alignItems:"center",marginTop:"4rem",minHeight:"80vh"
    },

    cardsContainer: {
      display: 'flex',
      flexDirection:"column",
      gap: '20px',
      // flexWrap: 'wrap',
      alignItems: 'center',
      // justifyContent: 'center'
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '2rem',
      // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      maxWidth:"800px",
      // borderBottom:"1px solid #ddd"
    },
    cardTitle: {
      fontSize: '1.7rem',
      margin: '0 0 10px',
      color: 'black',
      fontWeight:"normal",
      borderBottom:"1px solid #ddd",
      padding:"1rem 0",
    },
    cardContent: {
      display: 'flex',
      gap: '1rem'
    },
    traditionalSection: {
      // backgroundColor: '#fff',
      padding: '1rem 0',
      borderRadius: '6px',
      maxWidth:"300px",
      boxSizing:"border-box",
      // boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.05)'
    },
    ourApproachSection: {
      backgroundColor: '#e6f7e9',
      padding: '1rem',
      flex:1,
      boxSizing:"border-box",
      borderRadius: '6px',
      boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.05)'
    },
    subTitle: {
      margin: '0 0 5px',
      fontSize: '1.2rem',
      fontWeight: 'bold',color:"gray"
    },
    subTitleTraditional: {
      margin: '0 0 5px',
      fontSize: '1.2rem',
      fontWeight: 'normal',color:"gray"
    },
    text: {
      margin: 0,
      fontSize: '1.2rem',
      lineHeight: '1.4',
      fontWeight:"normal"
    },
    footer: {
      textAlign: 'center'
    },
    ctaButton: {
      backgroundColor: '#007BFF',
      border: 'none',
      borderRadius: '4px',
      padding: '15px 30px',
      fontSize: '1rem',
      color: '#fff',
      cursor: 'pointer'
    }
  };
  
 
    return (
      <div id="whyus-automate" style={styles.container}>
      <h1 style={{fontSize:"3rem",color:"black",maxWidth:"70vw",fontWeight:"600"}}>We Automate Your Financial Workflows</h1> 
      <h2 style={{maxWidth:"70vw",fontWeight:"normal"}}>
       
       Stop just tracking your finances—<strong>start automating</strong> them.
         While others show you a snapshot of where you stand, we go beyond
         simple tracking. Our platform delivers high-quality insights and
         automates your financial workflows, so you can focus on living—not
         planning. 
       </h2>
  
        {/* Comparison Cards */}
        <section style={styles.cardsContainer}>
          {traditionalFinanceVsUs.map((item) => (
            <div key={item.category} style={styles.card}>
              <h2 style={styles.cardTitle}>{item.category}</h2>
              <div style={styles.cardContent}>
                <div style={styles.traditionalSection}>
                  <h3 style={styles.subTitleTraditional}>Traditional</h3>
                  <p style={{fontSize:"1.2rem",fontWeight:"normal",margin:0}}>{item.traditional}</p>
                </div>
                <div style={styles.ourApproachSection}>
                  
                  <h3 style={styles.subTitle}>Our Approach</h3>
                  <p style={styles.text}>{item.us}</p>
                </div>
              </div>
            </div>
          ))}
        </section>
  
       
      </div>
    );

  
  
}



const whyUsMain = [
  {
    title: "We Protect Your Privacy",
    description:
      "We prioritize safeguarding your financial data, ensuring you stay in full control of how it’s shared.",
    navigate: "whyus-privacy",
    icon: <SecurityIcon style={{ color: "#1976d2",height:"80px"}} />,
  },
  {
    title: "We Automate Your Financial Workflows",
    description:
      "We go beyond monitoring — automating your financial workflows so that you can focus on living — not planning.",
    navigate: "whyus-automate",
    icon: <AutoAwesomeIcon fontSize="large" style={{ color: "#388e3c" }} />,
  },
  {
    title: "Our Mission - Empowering You!",
    description:
      "Gain the tools and insights you need to confidently take charge of your financial future.",
    navigate: "whyus-empower",
    icon: <InsightsIcon fontSize="large" style={{ color: "#f57c00" }} />,
  },
];

const WhyUsSection = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const offset = 100; // Adjust this value to set the margin at the top
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = sectionPosition - offset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        alignItems: 'center',
        padding: '2rem',
      }}
    >
      {whyUsMain.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            background: '#f9f9f9',
            padding: '2rem',
            borderRadius: '12px',
            cursor: 'pointer',
            transition: 'box-shadow 0.3s ease',
            width: '600px',
            boxSizing: 'border-box',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.1)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          {item.icon}
          <Box>
            <Typography
              variant="h5"
              component="h3"
              style={{
                margin: '0 0 0.5rem 0',
                fontSize: '1.5rem',
                color: '#333',
                fontWeight: '600',
              }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: '1rem',
                margin: '0.5rem 0',
                color: '#555',
              }}
            >
              {item.description}
            </Typography>
            <a
              onClick={(e) => {e.preventDefault();scrollToSection(item.navigate)}}
              smooth={true}
              duration={500}
              style={{
                textDecoration: 'none',
                color: '#1976d2',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              Learn More
            </a>
          </Box>
        </div>
      ))}
    </div>
  );
};