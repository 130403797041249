import React, { useState } from 'react';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import ConnectWithoutContactIcon
  from '@mui/icons-material/ConnectWithoutContact';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WorkIcon from '@mui/icons-material/Work';
import {
  Box,
  Chip,
  Typography,
} from '@mui/material';

import TabRound from '../../components/Tabs/TabRound';
import FilterModal from '../components/FilterModal';
import PageHeader from '../components/PageHeader';
import {
  dummyInsights,
  getProspectMetricsData,
} from '../data/dummyData';
import {
  ADVISOR_SERVICES,
  prospectData,
} from './prospectData';
import styles from './Prospects.module.css';

// Define prospect categories
const PROSPECT_CATEGORIES = [
    'Top Prospects',
    'In Your Region',
    'High Net Worth',
    'Business Owners',
    'Recently Added'
];

// Define filter options based on data
const REGIONS = ['West Coast', 'East Coast', 'Midwest', 'South', 'West'];
const RISK_PROFILES = ['Conservative', 'Moderate', 'Moderate-Conservative', 'Moderate-Aggressive', 'Aggressive'];
const NET_WORTH_RANGES = [
    { label: '$2M - $4M', min: 2000000, max: 4000000 },
    { label: '$4M - $6M', min: 4000000, max: 6000000 },
    { label: '$6M - $8M', min: 6000000, max: 8000000 },
    { label: '$8M+', min: 8000000, max: Infinity }
];

function MetricCard({ title, value, subtitle, icon }) {
  return (
    <Box className={styles.metricCard}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <Box sx={{ color: 'primary.light', opacity: 0.9 }}>
          {icon}
        </Box>
        <Box>
          <Typography variant="h6" className={styles.metricValue}>
            {value}
          </Typography>
          <Typography variant="body2" className={styles.metricTitle}>
            {title}
          </Typography>
          <Typography variant="caption" className={styles.metricSubtitle}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default function Prospects() {
    const [searchTerm, setSearchTerm] = useState('');
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [filters, setFilters] = useState({
        region: [],
        netWorthRange: [],
        riskProfile: [],
        advisorServices: []
    });

    const prospectMetricsData = getProspectMetricsData(dummyInsights.prospectMetrics);

    // Calculate active filters count
    const activeFiltersCount = Object.values(filters).reduce((count, filterArray) => 
        count + filterArray.length, 0);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
    };

    const filteredProspects = prospectData.filter(prospect => {
        // Filter by selected tab category
        if (!prospect.categories.includes(PROSPECT_CATEGORIES[selectedTab])) {
            return false;
        }

        // Filter by search term
        if (searchTerm) {
            const searchLower = searchTerm.toLowerCase();
            return (
                prospect.name.toLowerCase().includes(searchLower) ||
                prospect.location.toLowerCase().includes(searchLower) ||
                prospect.occupation.toLowerCase().includes(searchLower) ||
                prospect.company.toLowerCase().includes(searchLower) ||
                prospect.investmentInterests.some(interest => 
                    interest.toLowerCase().includes(searchLower)
                ) ||
                prospect?.advisorServices?.some(service =>
                    service.toLowerCase().includes(searchLower)
                )
            );
        }

        // Filter by region if selected
        if (filters.region.length > 0 && !filters.region.includes(prospect.region)) {
            return false;
        }

        // Filter by risk profile if selected
        if (filters.riskProfile.length > 0 && !filters.riskProfile.includes(prospect.riskProfile)) {
            return false;
        }

        // Filter by advisor services if selected
        if (filters.advisorServices.length > 0 && !prospect.advisorServices.some(service => 
            filters.advisorServices.includes(service))) {
            return false;
        }

        // Filter by net worth range if selected
        if (filters.netWorthRange.length > 0) {
            const matchesRange = filters.netWorthRange.some(range => {
                const selectedRange = NET_WORTH_RANGES.find(r => r.label === range);
                return prospect.netWorth >= selectedRange.min && prospect.netWorth < selectedRange.max;
            });
            if (!matchesRange) return false;
        }

        return true;
    });

    const handleApplyFilters = () => {
        setFilterModalOpen(false);
    };

    const handleTabChange = (newValue) => {
        setSelectedTab(newValue);
    };

    const toggleFilter = (type, value) => {
        setFilters(prev => ({
            ...prev,
            [type]: prev[type].includes(value)
                ? prev[type].filter(item => item !== value)
                : [...prev[type], value]
        }));
    };

    return (
        <div className={styles.container}>
            <PageHeader
                title="Prospects"
                subtitle="Potential clients in your target market"
                searchPlaceholder="Search by name, company, location, or interests..."
                searchValue={searchTerm}
                onSearchChange={setSearchTerm}
                onFilterClick={() => setFilterModalOpen(true)}
                activeFiltersCount={activeFiltersCount}
            />

            <Box className={styles.metricsContainer}>
                <MetricCard
                    title="Total Prospects"
                    value={prospectMetricsData[0].value}
                    subtitle={prospectMetricsData[0].subtitle}
                    icon={<PersonIcon />}
                />
                <MetricCard
                    title="High Net Worth"
                    value={prospectMetricsData[1].value}
                    subtitle={prospectMetricsData[1].subtitle}
                    icon={<AccountBalanceWalletIcon />}
                />
                <MetricCard
                    title="Potential AUM"
                    value={prospectMetricsData[2].value}
                    subtitle={prospectMetricsData[2].subtitle}
                    icon={<AssessmentIcon />}
                />
                <MetricCard
                    title="Top Prospects"
                    value={prospectMetricsData[3].value}
                    subtitle={prospectMetricsData[3].subtitle}
                    icon={<BarChartIcon />}
                />
            </Box>

            <div style={{ marginBottom: "1rem" }}>
                <TabRound 
                    options={PROSPECT_CATEGORIES}
                    value={selectedTab}
                    onChange={handleTabChange}
                />
            </div>

            <div className={styles.prospectsRows}>
                {filteredProspects.map((prospect) => (
                    <div key={prospect.id} className={styles.prospectRow}>
                        <div className={styles.prospectMainInfo}>
                            <div className={styles.nameSection}>
                                <PersonIcon className={styles.personIcon} />
                                <div>
                                    <h2 className={styles.name}>{prospect.name}</h2>
                                    <span className={styles.age}>{prospect.age} years old</span>
                                </div>
                            </div>
                            <div className={styles.actionButtons}>
                            <button className={`${styles.actionButton} ${styles.viewButton}`}>
                                <VisibilityIcon className={styles.buttonIcon} />
                                View Profile
                            </button>
                            <button className={`${styles.actionButton} ${styles.connectButton}`}>
                                <ConnectWithoutContactIcon className={styles.buttonIcon} />
                                Connect
                            </button>
                        </div>
                            {/* <div className={styles.riskBadge}>
                                {prospect.riskProfile}
                            </div> */}
                        </div>

                        <div className={styles.detailsRow}>
                            <div className={styles.detail}>
                                <AccountBalanceWalletIcon />
                                <div>
                                    <span className={styles.detailLabel}>Net Worth</span>
                                    <span className={styles.detailValue}>{formatCurrency(prospect.netWorth)}</span>
                                </div>
                            </div>
                            <div className={styles.detail}>
                                <TrendingUpIcon />
                                <div>
                                    <span className={styles.detailLabel}>Potential AUM</span>
                                    <span className={styles.detailValue}>{formatCurrency(prospect.potentialAUM)}</span>
                                </div>
                            </div>
                            <div className={styles.detail}>
                                <LocationOnIcon />
                                <div>
                                    <span className={styles.detailLabel}>Location</span>
                                    <span className={styles.detailValue}>{prospect.location}</span>
                                </div>
                            </div>
                            <div className={styles.detail}>
                                <WorkIcon />
                                <div>
                                    <span className={styles.detailLabel}>Occupation</span>
                                    <span className={styles.detailValue}>{prospect.occupation}</span>
                                </div>
                            </div>
                            <div className={styles.detail}>
                                <FamilyRestroomIcon />
                                <div>
                                    <span className={styles.detailLabel}>Family Status</span>
                                    <span className={styles.detailValue}>{prospect.familyStatus}</span>
                                </div>
                            </div>
                        </div>

                        <div className={styles.interestsSection}>
                            <span className={styles.interestsLabel}>Investment Interests</span>
                            <div className={styles.interestTags}>
                                {prospect.investmentInterests.map((interest, index) => (
                                    <span key={index} className={styles.interestTag}>
                                        {interest}
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div className={styles.interestsSection}>
                            <span className={styles.interestsLabel}>
                                <SupportAgentIcon className={styles.serviceIcon} />
                                Seeking Advisory Services
                            </span>
                            <div className={styles.interestTags}>
                                {prospect.advisorServices.map((service, index) => (
                                    <span key={index} className={styles.interestTag}>
                                        {service}
                                    </span>
                                ))}
                            </div>
                        </div>

                       
                    </div>
                ))}
            </div>

            <FilterModal
                open={filterModalOpen}
                onSearchChange={setSearchTerm}
                searchTerm={searchTerm}
                searchPlaceholder="Search prospects..."
                onClose={() => setFilterModalOpen(false)}
                title="Filter Prospects"
                onApply={handleApplyFilters}
                onReset={() => setFilters({
                    region: [],
                    netWorthRange: [],
                    riskProfile: [],
                    advisorServices: []
                })}
            >
                <div className={styles.filterContent}>

                
                    {/* Region Filter */}
                    <div className={styles.filterSection}>
                        <Typography variant="subtitle1" className={styles.filterTitle}>
                            Region
                        </Typography>
                        <div className={styles.filterChips}>
                            {REGIONS.map(region => (
                                <Chip
                                    key={region}
                                    label={region}
                                    onClick={() => toggleFilter('region', region)}
                                    color={filters.region.includes(region) ? "primary" : "default"}
                                    className={`${styles.filterChip} ${filters.region.includes(region) ? styles.selectedChip : ''}`}
                                />
                            ))}
                        </div>
                    </div>


                    {/* Net Worth Range Filter */}
                    <div className={styles.filterSection}>
                        <Typography variant="subtitle1" className={styles.filterTitle}>
                            Net Worth Range
                        </Typography>
                        <div className={styles.filterChips}>
                            {NET_WORTH_RANGES.map(range => (
                                <Chip
                                    key={range.label}
                                    label={range.label}
                                    onClick={() => toggleFilter('netWorthRange', range.label)}
                                    color={filters.netWorthRange.includes(range.label) ? "primary" : "default"}
                                    className={`${styles.filterChip} ${filters.netWorthRange.includes(range.label) ? styles.selectedChip : ''}`}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Risk Profile Filter */}
                    <div className={styles.filterSection}>
                        <Typography variant="subtitle1" className={styles.filterTitle}>
                            Risk Profile
                        </Typography>
                        <div className={styles.filterChips}>
                            {RISK_PROFILES.map(profile => (
                                <Chip
                                    key={profile}
                                    label={profile}
                                    onClick={() => toggleFilter('riskProfile', profile)}
                                    color={filters.riskProfile.includes(profile) ? "primary" : "default"}
                                    className={`${styles.filterChip} ${filters.riskProfile.includes(profile) ? styles.selectedChip : ''}`}
                                />
                            ))}
                        </div>
                    </div>


                    {/* Advisor Services Filter */}
                    <div className={styles.filterSection}>
                        <Typography variant="subtitle1" className={styles.filterTitle}>
                            Advisory Services Needed
                        </Typography>
                        <div className={styles.filterChips}>
                            {ADVISOR_SERVICES.map(service => (
                                <Chip
                                    key={service}
                                    label={service}
                                    onClick={() => toggleFilter('advisorServices', service)}
                                    color={filters.advisorServices.includes(service) ? "primary" : "default"}
                                    className={`${styles.filterChip} ${filters.advisorServices.includes(service) ? styles.selectedChip : ''}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </FilterModal>
        </div>
    );
} 