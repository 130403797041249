import React, { useState, useEffect } from 'react';
import { useAppInfo } from 'AppState';
import { Typography, Button } from '@mui/material';
import TooltipInfo from 'components/Modal/TooltipInfo';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CircleScore from 'components/Charts/CircleScore';
import PopoverButton from 'components/Modal/PopoverButton';
import TextWithBorders from 'components/Text/TextWithBorders';
import { navLinkDict } from 'nav/navLinks';
import Modal from 'components/Modal/Modal';
import InvestmentPreferenceModal from './InvestmentPreference/InvestmentPreferenceModal';
import RiskToleranceModal from './RiskManagement/RiskToleranceModal';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import HomeInsightNotificationBox from 'home/HomeInsightNotificationBox';
export default function ProfileCompletion({displayType="button"}) {
  const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
  const [hover, setHover] = useState(false);
  const navigate = useNavigate()
  const {profileCompletion,financialsLoaded} = stateMyFinancials
  const totalItems = Object.keys(profileCompletion).length;
  const [completedItems, setCompletedItems] = useState(0);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [notCompleted, setNotCompleted] = useState([]);
  const [loaded,setLoaded] = useState(false)
  const [modal, setModal] = useState(false);

  
 
  useEffect(() => {
    if (profileCompletion){ 

      const completedItems = Object.keys(profileCompletion).filter(key => profileCompletion[key]).length;
      const completionPercentage = Math.round((completedItems / totalItems) * 100);
    
    
      const completed = [];
      const notCompleted = [];
    
      Object.entries(profileCompletion).forEach(([key, value]) => {
        if (Object.keys(itemsLink).includes(key)){
        if (value) {
          completed.push(key);
        } else {
          notCompleted.push(key);
        }}
      });
      setCompletedItems(completedItems);
      setCompletionPercentage(completionPercentage);
      setCompleted(completed);
      setNotCompleted(notCompleted);
      setTimeout(() => setLoaded(true), 1000);
    }

  },[ profileCompletion])

  
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };
  const [selectedItem, setSelectedItem] = useState(null)
  
  function handleNavigate(item){
      const component = item?.component
      if (!component){
      navigate(`${item?.link}`)}
      else{
        setSelectedItem(item)
      }
  }
  const strokeWidth = 15; // Fixed stroke width
  const viewBoxSize = 120; // ViewBox size which SVG uses to calculate relative positions


  function Completion (){
    return (  
    <>            
    <div style={{ display:"flex",flexDirection:"column",gap:"0.5rem",overflow:"auto"}}>
      <strong>Profile Completion: {`${Math.round(completionPercentage)}%`}</strong>
      <strong>Complete the following:</strong>
      {notCompleted.length>0 && notCompleted.map(obj=><Button 
       onClick={()=>handleNavigate(itemsLink[obj])}
      key={obj} style={{display:"flex",alignItems:"center",gap:"0.4rem",textTransform:"none"
      ,textAlign:"left",width:"100%"}}>
          <CheckCircleOutlineIcon fontSize='small' sx={{color:'gray'}}/>
          <Typography fontSize={"small"} onClick={()=>handleNavigate(itemsLink[obj])} variant="subtitle1" component="div">{itemsLink[obj]?.name}</Typography>
          <ArrowOutwardIcon fontSize='small' sx={{color:'gray',marginLeft:"auto"}}  onClick={()=>handleNavigate(itemsLink[obj])}/>
      </Button>)}
     
     
      {completed.length>0 && 
      <>
       <strong>Completed:</strong>
      {completed.map(obj=><Button 
       onClick={()=>handleNavigate(itemsLink[obj])}
      key={obj} style={{display:"flex",alignItems:"center",gap:"0.4rem",textTransform:"none"
      ,textAlign:"left",width:"100%"}}>
          <CheckCircleIcon fontSize='small' sx={{color:'green'}}/>
          <Typography sx={{color:"black"}} fontSize={"small"} variant="subtitle1" component="div">{itemsLink[obj]?.completedName}</Typography>
          <ArrowOutwardIcon  fontSize='small' sx={{color:'gray',marginLeft:"auto"}} />
      </Button>)}</>}
      
    </div>
    <ItemModals info={selectedItem} onClose={()=>setSelectedItem(null)}/>
    </>)
  }

  
  if (!financialsLoaded || !loaded || completionPercentage === 100){
    return null
  }


  return (
    <>
    {(completionPercentage < 100 && loaded && financialsLoaded) &&
    <>
    {displayType !=="button" && 
    <>
      <HomeInsightNotificationBox
      onClick={()=>setModal(true)}
      className='card-hover'
      style={{display:"flex",gap:"1rem"
        ,alignItems:"center",cursor:"pointer",padding:"0.5rem",borderRadius:"5px",width:"100%",boxSizing:"border-box",position:"relative"}}>
      <CircleScore 
        width='60px'
        height='60px'
        color={"blue"}
        title='completion'
        score={completedItems} 
        totalScore={totalItems}/>
             
        <h4 style={{fontWeight:"bold"}}>Complete your Profile</h4>
        
       {/* <div style={{flex:1,display:"flex",flexDirection:"column"}}>
        <PopoverButton showLabel title='Complete your Profile'>
          <Completion/>
        </PopoverButton>
        </div> */}
        
      </HomeInsightNotificationBox>
      {modal && <Modal sx={{maxWidth:"500px"}} height='auto' onClose={() => setModal(false)}>
          <Completion/>
          </Modal>}
      </>}
    {displayType==="button" && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", 
    alignItems: "center",position:"relative"}}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    >
      <Button sx={{
      minWidth:"0",marginLeft:"auto",padding:0
      ,flexDirection:"column",textTransform:"none",color:"blue"}}>
         <CircleScore 
        width='25px'
        title=''
        fontSizeScore='2em'
        height='25px'
        color={"blue"}
        score={completedItems} 
        totalScore={totalItems}/>

      <span style={{ fontSize: "0.8rem" }}>Profile Completion</span>
      </Button>
     
      {hover && (
        <TooltipInfo 
        width='300px'
        sx={{zIndex:"10000",position:"absolute", top:"100%" }}>
          <div style={{padding:"10px"}}>
             <Completion/></div>
        </TooltipInfo>
      )}
    </div>}
    </>}
    </>
  );
}

function ItemModals({onClose,info}){
  const [open,setOpen]=useState(false)
  useEffect(()=>{
    if (info){
    setOpen(true)}
  },[info])
  
  const content = {
    "Set Investment Preferences":
  <InvestmentPreferenceModal open={open} onClose={()=>{setOpen(false);onClose && onClose(false)}} />,
    "Assess Risk Tolerance":<RiskToleranceModal open={open} onClose={()=>{setOpen(false);onClose && onClose(false)}} />,}

  return (
    <>{open && content[info?.name]}</>
  )
}


const itemsLink = {assets:{link:"/MyFinancials/Assets",name:"Add Assets",completedName:"Assets Added"},
liabilities:{link:"/MyFinancials/Liabilities",name:"Liabilities",completedName:"Liabilities Added"},
portfolio:{link:navLinkDict?.Portfolio?.path,name:"Create New Investment Portfolio",completedName:"Investment Portfolio Created"}
,incomeMonthly:{link:"/MyFinancials/Cashflow",name:"Add Income",completedName:"Income Added"},
expenseMonthly:{link:"/MyFinancialsCashflow",name:"Add Expense",completedName:"Expense Added"},
goalsPeriodic:{link:"/Goals",name:"Add Goals",completedName:"Goals Added"},
netCashAllocate:{link:"/MyFinancials/Cashflow",name:"Allocate Cashflow",completedName:"Cashflow Allocated"},
investmentPreference:{link:"/MyFinancials/InvestmentPreferences",component:true,name:"Set Investment Preferences",completedName:"Investment Preference Set"}
,riskTolerance:{link:"/MyFinancials/RiskManagement",component:true,name:"Assess Risk Tolerance",completedName:"Risk Tolerance Assessed"}
}