import { extrapolateCashflow } from "myFinancials/Utils/utils";

/**
 * Calculates the required return and asset base to retire based on expenses, goals, inflation, and expected return.
 * @param {Object} params
 * @param {number} params.currentAge - Current age of the individual.
 * @param {number} params.retirementAge - Desired retirement age.
 * @param {number} params.deathAge - Expected age at death.
 * @param {Array} params.assets - Array of asset objects with { amount, return, date }.
 * @param {Array} params.expenses - Array of expense objects with { amount, date }.
 * @param {Array} params.goals - Array of goal objects with { amount, date }.
 * @param {number} params.inflationRate - Annual inflation rate (in percentage).
 * @param {number} params.expectedReturn - Expected annual return on assets (in percentage).
 * @param {number} params.horizonYears - Projection period in years.
 * @param {number} params.sustainableReturn - A sustainable return rate (in percentage).
 * @returns {Object} - Contains schedules and summary metrics.
 */
export function calculateRetirementAmountActual({
    currentAge = 40,
    retirementAge = 65,
    deathAge = 100,
    assets,
    expenses,
    goals,
    inflationRate = 2, // default 2%
    expectedReturn = 10, // default 10%
    horizonYears = 30,
    sustainableReturn = 5, // default 5%
}) {
    const years = deathAge - retirementAge;
    const inflation = inflationRate / 100;
    const expectedR = expectedReturn / 100;
    const sustainableR = sustainableReturn / 100;
    let baseYear = new Date().getFullYear() + retirementAge - currentAge;

    // Extrapolate Expenses and Goals
    const expenseDist = extrapolateCashflow({
        fields: expenses,
        useYears: true,
        years: deathAge - currentAge + 1,
    });

    const goalDist = extrapolateCashflow({
        fields: goals,
    });

    // Initialize Schedules
    const schedule = [];
    let assetBaseForReturn = 0;
    let assetBaseForWithdrawal = 0;
    let requiredReturnSustainableYear = null;
    console.log("BASE YEAR", baseYear)
    for (let year = 0; year <= years; year++) {
        let currentYear = baseYear + year;
        
        const age = retirementAge + year;
       

        // Sum expenses and goals for the current year
        const currentExpenses = sumForAge(expenseDist, currentYear);
        const currentGoals = sumForAge(goalDist, currentYear);
        const totalNeeds = currentExpenses + currentGoals;
        let {totalAssets: aggregatedAssets,averageReturn:expectedR} = aggregateAssets(assets, year)

        // Calculate Required Return
        // simple calculation
        // let requiredReturn = totalNeeds > 0 ? totalNeeds / aggregatedAssets : 0;
        let result = findDiscountRate(totalNeeds,0.02,years - year + 1,aggregatedAssets)
        let requiredReturn = result?.discountRate || totalNeeds/aggregatedAssets
        // Check if required return is sustainable
        if (!requiredReturnSustainableYear && requiredReturn <= sustainableR) {
            requiredReturnSustainableYear = year;
        }

        // Calculate Assets Required for Withdrawals
        // Present Value of future needs

        let pv = calculateAssetRequired(totalNeeds,inflation,expectedR, years - year + 1); 
        // for (let t = 1; t <= years - year; t++) {
        //     const futureNeed = sumForAge(expenseDist, currentYear + year + t) + sumForAge(goalDist, currentYear + year + t);
        //     pv += futureNeed / Math.pow(1 + expectedR, t);
        // }
        const assetsForWithdrawal = pv;

        // Calculate Assets Required for Returns
        // Present Value needed to grow to current totalNeeds
        // Calculate required assets A = C₀ * (1 + g)^n / r
        const assetsForReturn = totalNeeds / (expectedR);

        // Update the maximum required assets
        assetBaseForWithdrawal = Math.max(assetBaseForWithdrawal, assetsForWithdrawal);
        assetBaseForReturn = Math.max(assetBaseForReturn, assetsForReturn);

        // Populate the schedule
        schedule.push({
            age,
            currentYear,
            futureExpenses: currentExpenses.toFixed(2),
            futureGoals: currentGoals.toFixed(2),
            totalNeeds: totalNeeds.toFixed(2),
            assets: aggregatedAssets.toFixed(2),
            assetReturn:  (aggregatedAssets * (expectedR)).toFixed(2),
            requiredReturn: (requiredReturn * 100).toFixed(2) + '%',
            assetsRequiredForWithdrawal: assetBaseForWithdrawal.toFixed(2),
            assetsRequiredForReturn: assetBaseForReturn.toFixed(2),
        });
    }

    // Summary Metrics
    const summary = {
        averageExpectedReturn: expectedReturn.toFixed(2) + '%',
        sustainableReturnYear: requiredReturnSustainableYear !== null ? (currentAge + requiredReturnSustainableYear) : 'Not achievable within horizon',
    };

    return { schedule, summary };
}

/**
 * Aggregates all assets into a single principal and calculates the total assets based on returns.
 * @param {Array} assets - Array of asset objects with { amount, return, date }.
 * @param {number} yearsPassed - Number of years passed since current year.
 * @returns {Object} - Contains totalAssets and averageReturn.
 */
function aggregateAssets(assets, yearsPassed) {
    let totalAmount = 0;
    let weightedReturnSum = 0;
    let totalAmountNoChange = 0;

    assets.forEach((asset) => {
        totalAmountNoChange += asset.amount;
        const assetGrowth = asset.return / 100;
        const grownAmount = asset.amount * Math.pow(1 + assetGrowth, yearsPassed);
        totalAmount += grownAmount;
        weightedReturnSum += asset.amount * assetGrowth;
    });

    const averageReturn = totalAmountNoChange === 0 ? 0 : weightedReturnSum / totalAmountNoChange;
    return { totalAssets: totalAmount, averageReturn };
}

/**
 * Converts age to calendar year based on base year.
 * @param {number} baseYear - The base year corresponding to currentAge.
 * @param {number} age - The age to convert.
 * @returns {number} - The corresponding calendar year.
 */
function ageToCalendarYear(baseYear, age) {
    return baseYear + age;
}

/**
 * Sums all distribution amounts that fall into the calendar year matching the person's age.
 * @param {Array} distribution - Array of distribution objects with { amount, date }.
 * @param {number} baseYear - The base year corresponding to currentAge.
 * @param {number} age - The age to sum for.
 * @returns {number} - The total amount for that age.
 */
function sumForAge(distribution, targetYear) {
    return distribution
        .filter((item) => new Date(item.date).getFullYear() === targetYear)
        .reduce((acc, curr) => acc + curr.amount, 0);
}

function calculateAssetRequired(amount,inflation, expReturn, years) {
    const growthRate = inflation
    const discountRate = expReturn; 

    if (discountRate === growthRate) {
        // Avoid division by zero; handle as a special case where g = r
        return amount * years / (1 + discountRate);
    }

    const factor = Math.pow((1 + growthRate) / (1 + discountRate), years);
    const presentValue = amount * (1 - factor) / (discountRate - growthRate);
    return presentValue;
}


function findDiscountRate(amount, growthRate = 0.02, targetYear, presentValue, tolerance = 1e-6, maxIterations = 3000) {
    // Define the result object structure
    const result = {
        success: false,
        discountRate: null,
        message: ''
    };

    // Input Validation
    if (targetYear <= 0) {
        result.message = "Error: targetYear must be greater than 0.";
        return result;
    }
    if (presentValue <= 0) {
        result.message = "Error: presentValue must be greater than 0.";
        return result;
    }
    if (growthRate < 0) {
        result.message = "Error: growthRate cannot be negative.";
        return result;
    }

    // Define the Present Value of a Growing Annuity formula
    const calculatePV = (r) => {
        if (Math.abs(r - growthRate) < 1e-12) { // Handle floating-point precision
            return amount * targetYear / (1 + growthRate);
        }
        return amount * (1 - Math.pow((1 + growthRate) / (1 + r), targetYear)) / (r - growthRate);
    };

    // Initialize the lower and upper bounds for r
    let lower = 0.025; // Slightly above growth rate to avoid division by zero
    let upper = 2.0; // 100% as an initial upper guess

    // Calculate PV at the lower and upper bounds
    let pvLower = calculatePV(lower);
    let pvUpper = calculatePV(upper);

    // Ensure that the presentValue lies between pvLower and pvUpper
    if (!(presentValue < pvLower && presentValue > pvUpper)) {
        // console.log(presentValue,pvLower,pvUpper,lower,upper,targetYear)
        result.message = "Warning: Cannot find a discount rate within the initial bounds. Please adjust the bounds or check the input values.";
        return result;
    }

    let iteration = 0;
    let r = 0;
    let pv = 0;

    // Bisection Method
    while (iteration < maxIterations) {
        // Calculate the midpoint
        r = (lower + upper) / 2;
        pv = calculatePV(r);

        // Check if the calculated PV is within the desired tolerance
        if (Math.abs(pv - presentValue) < tolerance) {
            result.success = true;
            result.discountRate = r;
            result.message = "Success: Discount rate found.";
            return result;
        }

        // Decide which subinterval to keep
        if (pv > presentValue) {
            // Need a higher discount rate to reduce PV
            lower = r;
        } else {
            // Need a lower discount rate to increase PV
            upper = r;
        }

        iteration++;
    }

    // If the function hasn't returned by now, it didn't converge
    result.message = "Warning: Failed to converge to a solution within the maximum number of iterations.";
    return result;
}
