import { Button } from '@mui/material'
import { useAppInfo } from 'AppState'
import { assetTypes, defaultFieldBlank } from 'appState/initialStateMyFinancials'
import AddFinancialsModal from 'myFinancials/Utils/AddFinancialsModal'
import FieldFormGen from 'myFinancials/FormFields/FieldFormGen'
import React, { useState } from 'react'

export default function AddAsset() {
const {stateMyFinancials} = useAppInfo()
const {assets} = stateMyFinancials
  const [addAssets,setAddAssets] = useState(false)

  return (
    <>
    <Button sx={{width:"max-content"}} variant='contained' onClick={()=>setAddAssets(true)}>+ Asset</Button>
    {addAssets && <AddFinancialsModal title="Assets" onClose={()=>setAddAssets(false)}>
    <FieldFormGen 
    onSubmit={()=>setAddAssets(false)}
    // forecastPeriod={true} 
    date={true} 
    // volatility={true} 
    // returnExpected={true} 
    allocation={false} 
    defaultField={defaultFieldBlank.Asset}
    storeName={"assets"} data={assets} showType={true} 
    typeOptions={assetTypes}/>
    </AddFinancialsModal>}
    </>
  )
}

export function AssetTableForm(){
  const {stateMyFinancials} = useAppInfo()
  const {assets} = stateMyFinancials
 
  return (<FieldFormGen 
    // forecastPeriod={true} 
    date={true} 
    // volatility={true} 
    // returnExpected={true} 
    allocation={false} 
    defaultField={defaultFieldBlank.Asset}
    storeName={"assets"} data={assets} showType={true} 
    typeOptions={assetTypes}/>)
}