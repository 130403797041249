import { Box, Button, Typography } from "@mui/material";
import { formatNumber } from "utils/utilGeneral";
import { useAppInfo } from "AppState";
import { calculateTotal } from "./utils";
import SavingsIcon from "@mui/icons-material/Savings";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TemplateAction from "./TemplateAction";
import React from "react";


export function GoalPlanning({ successType = "Before Withdrawal" }) {
    const { stateMyFinancials } = useAppInfo();
    const { summaryStats, currency, goalAnalytics } = stateMyFinancials;
    const { goalSuccessRateAfterWithdrawal, goalSuccessRateBeforeWithdrawal, perGoalDetails } = goalAnalytics;

    
    const goalSuccess =
        successType === "Before Withdrawal"
            ? goalSuccessRateBeforeWithdrawal
            : goalSuccessRateAfterWithdrawal;

    const goalsBelowTarget = perGoalDetails.filter((goal) =>
        successType === "Before Withdrawal"
            ? goal?.successRateBeforeWithdrawal < 80
            : goal?.successRateAfterWithdrawal < 80
    );

    return (
        <TemplateAction
            actionHeading={"Here are some ways to boost your Goal Success Rate:"}
            actions={[
                { label: "Prioritize your goals", link: "/MyFinancials/Goals" },
                { label: "Set up a Goal Savings/Investment Account" },
            ]}
        >
            <Typography variant="body1" gutterBottom>
                Your current goal success rate is <strong>{formatNumber(goalSuccess)}%</strong>. 
                This is below the ideal 80%, meaning some of your goals may not be achieved.
            </Typography>

            {goalsBelowTarget.length > 0 && (
                <Typography variant="body1" gutterBottom>
                    The following goals need attention:
                    <ul>
                        {goalsBelowTarget.map((goal, index) => (
                            <li key={index}>
                                {`${goal?.type} - ${goal?.name} (${formatNumber(
                                    successType === "Before Withdrawal"
                                        ? goal?.successRateBeforeWithdrawal
                                        : goal?.successRateAfterWithdrawal
                                )}%)`}
                            </li>
                        ))}
                    </ul>
                </Typography>
            )}

            {goalSuccess >= 60 ? (
                <Typography variant="body1" gutterBottom>
                    You're on track but can make small adjustments to ensure all your goals are met.
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Focus on prioritizing your goals and managing your finances to improve your success rate.
                </Typography>
            )}
        </TemplateAction>
    );
}
