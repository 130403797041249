import React, { useEffect, useState } from 'react';
import styles from './RetirementEarliest.module.css';
// Ensure these packages are installed:
// npm install highcharts highcharts-react-official
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAppInfo } from 'AppState';
import { extrapolateCashflow } from 'myFinancials/Utils/utils';
import CollapseToggler from 'components/Buttons/CollapseToggler';
import { Button } from '@mui/material';
import AISummaryBox from 'chatGPT/AISummaryBox';
import NumberInput from 'components/Inputs/NumberInput';
import { calculateRetirementData } from './calculation';


export default function RetirementEarliest({currentAgeDefault=40, retirementAgeDefault=45, deathAgeDefault=90, earliestRetirementDefault}) {
    const { stateMyFinancials } = useAppInfo();
    const { assets, cashflowDistribution, expenseMonthly,goalsPeriodic} = stateMyFinancials;
    const { goalDist} = cashflowDistribution;
  
    // User inputs
    const [currentAge, setCurrentAge] = useState(40);
    const [retirementAge, setRetirementAge] = useState(40);
    const [deathAge, setDeathAge] = useState(90);
    const [earliestRetirement, setEarliestRetirement] = useState({
      return: "",
      withdrawal: "",});
  
    // Extrapolate expenseDist from expenseMonthly

    useEffect(()=>{
      if (currentAgeDefault) setCurrentAge(currentAgeDefault)
      if (retirementAgeDefault) setRetirementAge(retirementAgeDefault)
      if (deathAgeDefault) setDeathAge(deathAgeDefault)
    },[currentAgeDefault,retirementAgeDefault,deathAgeDefault])
  
    // Results after submission
    const [results, setResults] = useState([]);

    // Handler to run calculations
    const handleSubmit = (e) => {
      e.preventDefault();
      if (retirementAge < currentAge) {
        alert('Retirement Age must be greater than or equal to Current Age.');
        return;
      }
      if (deathAge < retirementAge) {
        alert('Death Age must be greater than or equal to Retirement Age.');
        return;
      }
      const {results, earliestRetirementReturn, earliestRetirementWithdrawal} = calculateRetirementData({
        currentAge,
        retirementAge,
        deathAge,
        assets,
        expenses: expenseMonthly,
        goals:goalsPeriodic,
      });
      setResults(results);
      setEarliestRetirement({return:earliestRetirementReturn,withdrawal:earliestRetirementWithdrawal});
    };
  
    return (
      <div className={styles.container}>
        {/* <h1>Retirement Earliest</h1> */}
  
      {/* Inputs for current age, retirement age, death age */}
      <form className={styles.inputs} onSubmit={handleSubmit}>
      <NumberInput 
      sx={{width:"150px"}}
       variant='outlined'
            max={deathAge - 1}
            min={10}
            label='Current Age'
            value={currentAge}
            onChange={(v) => setCurrentAge(Number(v))}
            />
              <NumberInput 
              variant='outlined'
               sx={{width:"150px"}}
               max={Number(deathAge) - 1}
               min={Number(currentAge) + 1}
            label='Retirement Age'
            value={retirementAge}
            onChange={(v) => setRetirementAge(Number(v))}
            />
  
          <NumberInput 
           variant='outlined'
           sx={{width:"150px"}}
            max={120}
            min={NumberInput(retirementAge) + 1}
            label='Death Age'
            value={deathAge}
            onChange={(v) => setDeathAge(Number(v))}
            />
          <Button variant='contained' type="submit" className={styles.submitButton}>
            Submit
          </Button>
        </form>
  
        {/* Explanation */}
        <Explanation/>
  
        {/* Show chart & table only if we have results */}
        {results.length > 0 && (
          <>
          <AISummaryBox
          usePromptStandard={false}
          message={`Use this data to communicate about the earliest retirement from just considering asset returns and considering asset withdrawals in concise and engaging way in max 200 words.: 
              ${JSON.stringify({earliestRetirement,results})}. Inflation and compounding returns are considered and included in the calcualtions.`}
          />
          {/* <div>
          <h3>Earliest Retirement from just using Asset Returns: {earliestRetirement?.return}</h3> 
          <h3>Earliest Retirement from withdrawing Assets: {earliestRetirement?.withdrawal}</h3> 
          </div>   */}
            <ProbabilityChart data={results} />
            <RetirementTable data={results} />
          </>
        )}
      </div>
    );
  }
  

/** 
 * 2) SUBCOMPONENTS (CHART + TABLE) 
 * --------------------------------------------------------------------------------
 */

function Explanation(){
  return( <div className={styles.explanation}>
    {/* <h2>Calculation Explanation</h2> */}
    <p>
      This tool helps you determine the earliest year you can retire based on your current assets,
      expected returns, yearly expenses, and goals. It evaluates two scenarios:
    </p>
    <ul>
      <li>
        <strong>Returns Only:</strong> Relying solely on the returns generated by your assets
        without withdrawing from the principal.
      </li>
      <li>
        <strong>Withdrawal:</strong> Using returns first and withdrawing from the principal when
        returns are insufficient to cover expenses and goals.
      </li>
    </ul>
    <p>
      The probabilities indicate the likelihood of sustaining your expenses and goals throughout
      your retirement period under each scenario.
    </p>
  </div>)
}
/**
 * ProbabilityChart
 * Renders probabilities as lines for each calendar year.
 */
function ProbabilityChart({ data }) {
  // data is an array of { startAge, startYear, probReturn, probWithdrawal, ... }

  const categories = data.map((d) => d.startYear.toString());
  const retireTimestamp = new Date(`${2040}-01-01`).getTime();

  const options = {
    title: { text: 'Probability of Sustaining Expenses/Goals' },
    xAxis: {
      categories: categories,
      title: { text: 'Retirement Start Year' },
      plotBands: [
        {
          // highlight retirement year
          color: "#d4eed1",
          from: retireTimestamp,
          to: retireTimestamp + 365 * 24 * 3600 * 1000, // highlight 1 year
          label: {
            text: "Retirement Year",
            style: { color: "green", fontWeight: "bold" },
          },
        },
      ],
    },
    yAxis: {
      min: 0,
      max: 100,
      title: { text: 'Probability (%)' },
    },
    series: [
      {
        name: 'Returns Only',
        data: data.map((d) => parseFloat(d.probReturn)),
        type: 'line',
      },
      {
        name: 'Withdrawal',
        data: data.map((d) => parseFloat(d.probWithdrawal)),
        type: 'line',
      },
    ],
   
    tooltip: {
      shared: true,
      valueSuffix: '%',
    },
    credits: { enabled: false },
  };

  return (
    <div className={styles.chartContainer}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

/**
 * CashflowAreaChart
 * Renders a detailed cashflow area chart for a selected year.
 */
function CashflowAreaChart({ simulationDetails, startYear, probReturn, probWithdrawal }) {
  if (!simulationDetails || simulationDetails.length === 0) return null;

  const categories = simulationDetails.map((d) => d.year.toString());

  const expensesGoals = simulationDetails.map((d) => d.expenses + d.goals);
  const assetReturn = simulationDetails.map((d) => parseFloat(d.assetReturn));
  const assetWithdrawal = simulationDetails.map((d) => parseFloat(d.assetWithdrawal));
  const assetAmount = simulationDetails.map((d) => parseFloat(d.assetAmount));

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: `Cashflow Details Starting in ${startYear}`,
    },
    xAxis: {
      categories: categories,
      title: { text: 'Year' },
    },
    yAxis: {
      title: { text: 'Amount ($)' },
    },
    tooltip: {
      shared: true,
      valuePrefix: '$',
    },
    plotOptions: {
      area: {
        stacking: 'normal',
      },
    },
    series: [
      {
        name: 'Expenses + Goals',
        data: expensesGoals,
        color: '#FF6384',
      },
      {
        name: 'Asset Return',
        data: assetReturn,
        color: '#36A2EB',
      },
      {
        name: 'Asset Withdrawal',
        data: assetWithdrawal,
        color: '#FFCE56',
      },
      {
        name: 'Asset Amount',
        data: assetAmount,
        color: '#4BC0C0',
        type: 'line',
        marker: {
          enabled: true,
        },
      },
    ],
    credits: { enabled: false },
  };

  return (
    <div className={styles.cashflowChartContainer}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className={styles.chartExplanation}>
        <p>
          <strong>Probability Explanation:</strong> 
          <br />
          <strong>Returns Only:</strong> You have a {probReturn}% probability of sustaining your expenses and goals
          solely through asset returns from the retirement start year.
          <br />
          <strong>Withdrawal:</strong> You have a {probWithdrawal}% probability of sustaining your expenses and goals
          by utilizing both asset returns and withdrawals from the principal.
        </p>
        <p>
          This chart shows how your assets are utilized over the years. The <strong>Asset Amount</strong> line indicates the remaining assets each year after accounting for returns and withdrawals.
        </p>
      </div>
    </div>
  );
}



/**
 * RetirementTable
 * Displays the retirement data in a table. Clicking a row expands a detailed chart.
 */
function RetirementTable({ data }) {
    const [expandedRows, setExpandedRows] = useState([]);
  
    const toggleRow = (startYear) => {
      if (expandedRows.includes(startYear)) {
        setExpandedRows(expandedRows.filter((year) => year !== startYear));
      } else {
        setExpandedRows([...expandedRows, startYear]);
      }
    };
  
    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Year - Retirement Age</th>
            <th>Expenses + Goals</th>
            <th>Asset Return ($)</th>
            <th>Asset Withdrawal ($)</th>
            <th>Asset Amount ($)</th>
            <th>Number of Years Covered by Returns</th>
            <th>Number of Years Covered by Assets</th>
            <th>Prob. Returns Only</th>
            <th>Prob. Withdrawal</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <React.Fragment key={row.startYear}>
              <tr onClick={() => toggleRow(row.startYear)} className={styles.tableRow}>
                <td style={{whiteSpace:"nowrap"}}><CollapseToggler isExpanded={expandedRows.includes(row.startYear)}/> {row.startYear} - {row.startAge}</td>
                <td>${row.expensesAndGoals.toLocaleString()}</td>
                {/* <td>${row.goals.toLocaleString()}</td> */}
                <td>${parseFloat(row.assetReturn).toLocaleString()}</td>
                <td>${parseFloat(row.assetWithdrawal).toLocaleString()}</td>
                <td>${parseFloat(row.assetAmount).toLocaleString()}</td>
                <td>{row.coveredReturn}</td>
                <td>{row.coveredWithdrawal} </td>
                <td>
                   {row.probReturn}%
                </td>
                <td>
                  {row.probWithdrawal}%
                </td>
              </tr>
              {expandedRows.includes(row.startYear) && (
                <tr className={styles.expandedRow}>
                  <td colSpan="9">
                    <CashflowAreaChart
                      simulationDetails={row.simulationDetails}
                      startYear={row.startYear}
                      probReturn={row.probReturn}
                      probWithdrawal={row.probWithdrawal}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  }
  