import React from 'react'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from '@mui/material';
export default function CollapseToggler({onClick,size="small",isExpanded=false}) {
  return (
    <IconButton size={size} onClick={(e)=>onClick && onClick(e)}>
    <KeyboardArrowDownIcon
      style={{
        transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
        transition: "0.3s",
      }}
    />
  </IconButton>
  )
}

