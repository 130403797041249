import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown';
import useAssistantSummarize from './useAssistantSummarize';
import LogoLoader from 'logo/LogoLoader';
import { useAppInfo } from 'AppState';

export default function useAISummarizeFinancials({message,usePromptStandard=true,submitAnswer=false}) {
  const [aiMessage,setAIMessage] = useState()
  const {stateMyFinancials,statePortfolio,stateApp,stateAssistant,dispatchAssistant} = useAppInfo()
  const {username} = stateApp
  const {aiSummaryStore} = stateAssistant
  const {portfolioLoaded,portfolio,portfolioResults} = statePortfolio
  const {financialsUpdated,currency,summaryStats,assets,liabilities,incomeMonthly,expenseMonthly,financialHealthIssues,
    financialWellnessScore,goalsPeriodic,financialView,financialsLoaded,demoProfile,financials} = stateMyFinancials

//   const aiSummaryId = financialView === "personal" ? `${id}_${username}` : `${id}_demo_${demoProfile}`
  const aiSummary = useAssistantSummarize({message:aiMessage});
  const {loading,answer } = aiSummary;
  const [answerSaved,setAnswerSaved] = useState()

//   useEffect(()=>{
//     if (id){
//       let aiSummaryStoreSave = aiSummaryStore?.filter(obj=>obj.aiSummaryId === aiSummaryId)
//       let answerSave =  aiSummaryStoreSave[0]?.answer
//       setAnswerSaved(answerSave)
//     }
//     else {
//       setAnswerSaved(false)
//     }
//   },[id])
 
  useEffect(()=>{
    if (!submitAnswer) return

    let portfolioCheck = false
    if (portfolioLoaded && portfolio && typeof portfolio === "object" && Object.keys(portfolio).length > 0 && portfolioResults){
      portfolioCheck = true
    }
    if (
      portfolioLoaded && portfolio && typeof portfolio === "object" && Object.keys(portfolio).length === 0
    ){
      portfolioCheck = true}
    
      let user = financialView === "personal" ? username : demoProfile
      let userMessage = user ? `You are addressing to ${user}` : `You are addressing to a demo profile ${demoProfile} for ${username} account on Autonomy Finance.`

    if (!usePromptStandard){
      setAIMessage(`${userMessage}. ${message}`)
    }
    
    
    if (usePromptStandard &&financialsLoaded && message && !answerSaved && financialsUpdated && portfolioCheck){
      const portfolioResultAll = portfolioResults?.portfolioOverviewAll
      let financialSituation = {currency,summaryStats,assets,liabilities,incomeMonthly,expenseMonthly,financialHealthIssues,
            financialWellnessScore,goalsPeriodic,}
      if (portfolioResultAll){
        financialSituation = {...financialSituation,...portfolioResultAll}
      }

      setAIMessage(` 
        ${message}. 
        Consider the financial situation to address the ${user}. Financial Situation: ${JSON.stringify(financialSituation)}`)
    }
  },[submitAnswer,financialsLoaded,financialsUpdated,answerSaved,message
    ,financialView,portfolioLoaded,portfolio,portfolioResults,demoProfile])


  return {aiSummary}
}
