import React from 'react';

import TimeSeriesChart from 'components/Charts/TimeSeriesChart';
import NoInfo from 'components/NoInfo';

import { useAppInfo } from 'AppState';
import usePriceChartEod from 'data/eod/usePriceChartEod';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';

export default function BondPrice() {
    const {stateTicker} = useAppInfo()
    const { priceInfo, loadingPriceInfo } = usePriceChartEod({
        tickerSel: stateTicker.tickerBondCode,
      });
  return (
    <>
      {priceInfo.length>0 && !loadingPriceInfo && 
      <TimeSeriesChart 
      gradient={true}
                grid={false}
                chartType="area"
      seriesName="adjusted_close" 
      data={getSpecificArrayObjKeys(priceInfo,["date","adjusted_close"])}/>}
      {priceInfo.length===0 && !loadingPriceInfo && 
     <NoInfo/>
      }
    </>
  )
}
