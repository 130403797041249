import React from 'react';

import {
  Box,
  Paper,
  Typography,
} from '@mui/material';

import NomyMetrics from './NomyMetrics';
import NomyPrompts from './NomyPrompts';
import NomyTodos from './NomyTodos';

const NomyHighlightsCard = ({ metrics }) => {
  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 3,
        bgcolor: '#ffffff',
        borderRadius: '16px',
        height: '100%',
        border: '1px solid #f0f0f0',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 8px 24px rgba(0,0,0,0.05)',
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 500,
            color: 'text.primary',
            letterSpacing: '0.3px',
            fontSize: '1rem'
          }}
        >
          Quinn (AI Assistant)
        </Typography>
      </Box>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <NomyMetrics taskStats={metrics.taskStats} />
        <NomyTodos/>
        <NomyPrompts/>
      </Box>
    </Paper>
  );
};

export default NomyHighlightsCard; 