// HomeNewLandingPage.jsx
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';

import styles from './HomeNewLandingPage.module.css'; // Import the CSS module

export const HomeNewLandingPageTyped = () => {
  // State to track the current step in the typewriter sequence
  const {stateHome,dispatchHome} = useAppInfo()
  const [currentStep, setCurrentStep] = useState(0);

  // const endOfContentRef = useRef(null);

  const handleHeadingComplete = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    if (currentStep === 8){
      dispatchHome({pageTyped:true})
    }
    
  };

  // useEffect(() => {
  //   if (endOfContentRef.current) {
  //     endOfContentRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [currentStep]);



  return (
    <>

    <div className={styles.wrapper}>
      

      <div className={styles.container}>
    
      {/* Heading with Typewriter */}
      {currentStep >= 0 && (
        <h1 style={{zIndex:1}} className={styles.title}>
          <Typewriter
            text="Imagine a world where your money is on autopilot."
            onComplete={handleHeadingComplete}
            speed={50}
            delay={0}
          />
        </h1>
      )}

      

      {/* The rest of the content fades in once showContent is true */}
      <div className={styles.fadeIn}>
        <section className={styles.section}>
          {currentStep >= 1 && (
            <h4 className={styles.subheading}>
              <Typewriter
                text="AI agents optimize your financial life in real-time, seamlessly collaborating with your data, advisor, and the market."
                onComplete={handleHeadingComplete}
                speed={40}
                delay={0}
              />
            </h4>
          )}

          

          {/* List of features */}
          {currentStep >= 2 && (
            <div className={styles.list}>
              {[
                "Refinancing mortgage",
                "Portfolio-fit trades",
                "Tax loss harvesting",
                "Automated savings",
              ].map((item, index) => {
                const stepForItem = 2 + index;
                return (
                  <div key={index} className={`${styles.listItem}`}>
                    {currentStep >= stepForItem && (
                      <>
                        <DoneIcon sx={{ color: "green" }} />
                        <h4 className={styles.subheading}>
                          <Typewriter
                            text={item}
                            onComplete={handleHeadingComplete}
                            speed={40}
                            delay={0}
                          />
                        </h4>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {/* Highlight statement */}
          {currentStep >= 6 && (
            <h4 className={styles.highlight}>
              <Typewriter
                text="Every financial task — predicted and handled autonomously. Relax, your financial future is secure."
                onComplete={handleHeadingComplete}
                speed={40}
                delay={0}
              />
            </h4>
          )}
        </section>

        {/* Intro Section */}
        {currentStep >= 7 && (
          <h4 className={styles.description}>
            <Typewriter
              text="Welcome to the era of self-driving money - where you own your data. You keep your banks, trading apps, advisors, and accountants. Everything stays the same — yet we’ve completely reimagined your finances for the AI era."
              onComplete={handleHeadingComplete}
              speed={40}
              delay={0}
            />
          </h4>
        )}

        {/* Description Section */}
        {currentStep >= 8 && (
          <h2 className={styles.intro}>
            <Typewriter
              text="We are Autonomy Finance. We are coming soon!"
              onComplete={handleHeadingComplete}
              speed={40}
              delay={0}
            />
          </h2>
        )}
         

        {/* Buttons */}
        {currentStep >= 9 && (
          <section className={styles.buttonContainer}>
            <ActionButtons/>
          </section>
        )}
      </div>
      {/* <div ref={endOfContentRef} /> */}
      </div>
    </div>
    </>
  );
};




const HomeNewLandingPage = () => {
  // State to track the current step in the typewriter sequence

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
      {/* <Logo type='long' color='black' width='150px' height='40px'/>  */}
      {/* <h1>Autonomy Finance</h1> */}
      {/* Heading with Typewriter */}
      <h1 className={styles.title}>
        Imagine a world where your money is on autopilot.
        </h1>

      {/* The rest of the content fades in once showContent is true */}
      <div>
        <section className={styles.section}>
        <h4 className={styles.subheading}>
             AI agents optimize your financial life in real-time, seamlessly collaborating with your data, advisor, and the market.
            </h4>

          {/* List of features */}

            <div className={styles.list}>
              {[
                "Refinancing mortgage",
                "Portfolio-fit trades",
                "Tax loss harvesting",
                "Automated savings",
              ].map((item, index) => {
                return (
                  <div key={index} className={`${styles.listItem}`}>
                    <>
                        <DoneIcon sx={{ color: "green" }} />
                        <h4 className={styles.subheading}>
                          {item}
                        </h4>
                      </>
                  </div>
                );
              })}
            </div>
        

          {/* Highlight statement */}
          <h4 className={styles.highlight}>
            Every financial task — predicted and handled autonomously. Relax, your financial future is secure.
            </h4>
        </section>

        {/* Intro Section */}
        <h4 className={styles.description}>
           Welcome to the era of self-driving money - where you own your data. You keep your banks, trading apps, advisors, and accountants. Everything stays the same — yet we’ve completely reimagined your finances for the AI era.
          </h4>

        {/* Description Section */}
        <h2 className={styles.intro}>
        We are Autonomy Finance. We are coming soon!
          </h2>

        {/* Buttons */}
        <section className={styles.buttonContainer}>
           <ActionButtons/>
          </section>
      </div>
      {/* <div ref={endOfContentRef} /> */}
      </div>
    </div>
  );
};


export default HomeNewLandingPage;


function ActionButtons(){
    const { dispatchApp, dispatchHome } = useAppInfo()
    
    const navigate = useNavigate()
    const location = useLocation()
    
  function handleRequestDemo() {
    dispatchHome({ "contactUs": true })
  }
  function handleLogin(login = true) {
    dispatchApp({ "loginModal": login })
  }

  return (
    <>
     <Button onClick={handleLogin} className={styles.button}>Login</Button>
     <Button onClick={handleRequestDemo} className={styles.button}>Request Demo</Button>
    </>
  )
}

const Typewriter = ({delay=0, text, speed = 50, style, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const indexRef = useRef(0);
  const rafIdRef = useRef(null);
  const startTimeRef = useRef(null);

  useEffect(() => {
    if (!text) return;
    
    setDisplayedText('');
    indexRef.current = 0;
    startTimeRef.current = null;
    setIsTyping(false);
    const typeCharacter = (timestamp) => {
      if (!startTimeRef.current) {
        startTimeRef.current = timestamp;
      }

      const elapsed = timestamp - startTimeRef.current;

      // Check if it's time to display the next character
      if (elapsed >= speed * indexRef.current) {
        setDisplayedText(text.slice(0, indexRef.current + 1));
        setIsTyping(true);
        indexRef.current += 1;
      }

      if (indexRef.current < text.length) {
        rafIdRef.current = requestAnimationFrame(typeCharacter);
      } else {
        if (onComplete) onComplete();
        cancelAnimationFrame(rafIdRef.current);
        rafIdRef.current = null;
        setIsTyping(false);
      }
    };
    setTimeout(() => {
      rafIdRef.current = requestAnimationFrame(typeCharacter);

    }, 500);
    
    // Cleanup on unmount or when text changes
    return () => {
      if (rafIdRef.current) {
        cancelAnimationFrame(rafIdRef.current);
      }
    };
  }, [text, speed]);

  return (
    <span style={style} className={styles.typewriterText}>
      {displayedText}
      {isTyping && <span className={styles.cursor}></span>}
    </span>
  );
};

