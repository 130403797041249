import { useEffect } from 'react';

import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';
import axios from 'axios';

const useFindChatAI = () => {
  const { dispatchAssistant,stateApp} = useAppInfo()
  const userId = stateApp.userId
  const userRole = stateApp?.onboarding?.userRole

  const findChatAI = async () => {
    try {
      if (userId) {
        const response =  await axios.post(`${BASE_URL}/findChatAI/`, {
          userId,
          table:userRole === "advisor" ? "ChatAIAdvisor" : "ChatAI"
        });
        // Process the response data as needed
       const data = response.data;
        
       if (data){
        dispatchAssistant({chatHistory:data})
       }
       else{
        dispatchAssistant({chatHistory:[]})
       }
        
      }
    } catch (err) {
      // Handle the error
    }
  };

  useEffect(() => {
    if (userId) {
      findChatAI();
    }
  }, [userId,userRole]);

  return null; // Since this hook is used for side effects, you don't need to return anything
};

export default useFindChatAI;

