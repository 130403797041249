import React, { useEffect, useState } from 'react';
import { auth,createUserWithEmailAndPassword, signInWithEmailAndPassword, googleProvider, signInWithPopup, db } from '../firebaseConfig/firebaseConfig';
import { onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { useAppInfo } from 'AppState';
import {getDoc, setDoc, doc } from 'firebase/firestore';
import { Alert, Button, FormControl, Input, InputLabel, TextField } from '@mui/material';
import Logo from '../logo/Logo';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import {useNavigate } from 'react-router-dom';
// import { auth, } from './firebase-config';

// Reference to the document you want to update



function AuthForm() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showForgotPassword,setShowForgotPassword] = useState(false)

  const register = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      alert("Verification email sent. Please check your inbox.");
      await signOut(auth);
      
    } catch (error) {
      setError('Failed to create an account. ' + error.message.split("Firebase:")[1]);
      handleForgotPassword()
      console.error(error);
    }
  };

  const login = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user
      const userDocRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(userDocRef);
      const userChatsDocRef = doc(db, "userChats", user?.uid);
      const docUserChats = await getDoc(userChatsDocRef);
      if (!docSnap.exists() && user.emailVerified) {
        // If the user document does not exist, create it
        await setDoc(doc(db, "users", user?.uid), {
          uid: user?.uid,
          displayName: user?.displayName,
          email: user?.email,
          photoURL: user?.photoURL,
        });
        setShowForgotPassword(false)
      }
      if (!docUserChats.exists() && user.emailVerified){
        // Also create an empty document for user chats
        await setDoc(doc(db, "userChats", user.uid), {});
      }
      navigate("/")
    } catch (error) {
      setError('Failed to sign in. ' + error.message.split("Firebase:")[1]);
      setShowForgotPassword(true)

      console.error(error);
    }
  };

  const signInWithGoogle = async (e) => {
    e.preventDefault();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // console.log('Logged in with Google:', user);
       // Update Firestore with user details
       const userDocRef = doc(db, "users", user.uid);
       const docSnap = await getDoc(userDocRef);

       const userChatsDocRef = doc(db, "userChats", user.uid);
       const docUserChats = await getDoc(userChatsDocRef);
       if (!docSnap.exists()) {
        // console.log("User Db created")
         // If the user document does not exist, create it
         await setDoc(doc(db, "users", user.uid), {
           uid: user.uid,
           displayName: user.displayName,
           email: user.email,
           photoURL: user.photoURL,
         });
       }
       if (!docUserChats.exists()){
         // Also create an empty document for user chats
         await setDoc(doc(db, "userChats", user.uid), {});
       }
       navigate("/")
    } catch (error) {
      setError('Failed to sign in with Google. ' + error.message.split("Firebase:")[1]);
      console.error(error);
    }
  };


  const logout = async () => {
    try {
      await signOut(auth);
      // console.log('Signed out successfully');
    } catch (error) {
      setError('Failed to sign out. ' + error.message.split("Firebase:")[1]);
      console.error(error);
    }
  };


  
  const [showPassword, setShowPassword] = useState(false);
  const handlePassword = (event) => {
    setError('');
    const pwd = event.target.value;
    setPassword(pwd)}
  const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

  async function handleForgotPassword(){
  
    try{
      await sendPasswordResetEmail(auth, email);
      alert("Password Reset link sent. Please check your inbox.");
    }

  catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    setError(error.message.split("Firebase:")[1])
    // ..
  };
  }

  const [token,setToken] = useState()
  const [tokenSubmit,setTokenSubmit] = useState(false)
  const [tokenEnterCount,setTokenEnterCount] = useState(0)

  useEffect(()=>{
    if (tokenEnterCount >= 5){
      alert("Too many attempts")
    }
  },[tokenEnterCount])

  return (
    <div style={{display:"flex",flexDirection:"column",alignItems:"center",width:"100%",gap:"0.4rem",height:"auto",overflow:"auto"}}>
      {/* <Logo height='40px' width='200px' type='long'/> */}
      <h1>Welcome to Autonomy! </h1>
      <h4 style={{color:"gray",textAlign:"center",marginBottom:"2rem"}}>Access is invitation-only at this time. We will be live by Q2 2025. Stay tuned!</h4>
      {(token !== "ts2024!" && !tokenSubmit && tokenEnterCount >= 5) && <Alert severity='warning'>Too many attempts. Try again later.</Alert>}
      {(token !== "ts2024!" && !tokenSubmit && tokenEnterCount < 5) && 
      <form id="authForm" onSubmit={(e)=>{e.preventDefault();setTokenEnterCount((token)=>token + 1); 
      token === "ts@2024!" ? setTokenSubmit(true) : alert("Invalid Token")}} 
      style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"center",gap:"1rem"}}>
      <TextField required sx={{width:"100%"}} variant='standard' type="text" value={token} 
        onChange={(e) => setToken(e.target.value)} placeholder="Token" />
      <Button variant='contained' type='submit'>Submit Token</Button>
      </form>}


      
      {(token === "ts@2024!" && tokenSubmit) && 
      <>
      
      
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {showForgotPassword && <button style={{color:"red"}} onClick={handleForgotPassword}>Forgot Password?</button>}
      <form id="forgotPassword" style={{width:"100%",display:"flex",justifyContent:"center",flex:1}}>
        <div style={{display:"flex",flexDirection:"column",gap:"1rem"
        ,width:"80%",justifyContent:"center",alignItems:"center",boxSizing:"border-box"}}>
        <TextField sx={{width:"100%"}} variant='standard' type="email" value={email} 
        onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        
        <FormControl variant="standard" sx={{width:"100%",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
          {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
           */}
          <Input
            required
            id="outlined-adornment-password"
            onChange={handlePassword}
            value={password}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            placeholder="Password"
          />
        </FormControl>
        
        <div style={{display:"flex",gap:"0.5rem",marginTop:"2rem",marginBottom:"1rem"}}>
        <Button variant='outlined' onClick={register}>Register</Button>
        <Button variant='contained' onClick={login}>Login</Button>
        </div>
        
        <button className='btn' onClick={signInWithGoogle}>
                            <img style={{height:"25px",width:"25px"}} src="/GoogleIcon.png"/>Login with Google
        </button>
        
        </div>
      </form>
      
      </>}
    </div>
  );
}

export default AuthForm;


// const unsubscribe = onAuthStateChanged(auth, async (user) => {
//   if (user) {
//     if (user?.emailVerified) {
//       // User is logged in and email is verified
//       unsubscribe(); // Unsubscribe from further state changes

//       // Proceed with login
//       await signInWithEmailAndPassword(auth, email, password);
      
//       // Create user document in Firestore
//       await setDoc(doc(db, "users", user.uid), {
//         uid: user.uid,
//         displayName: "", // Consider prompting the user for this, or leave blank
//         email: user.email,
//         photoURL: "", // Consider how you want to handle the photoURL for email/password registration
//       });

//       // Create empty user chats on Firestore
//       await setDoc(doc(db, "userChats", user.uid), {});
//     }}}) 