import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import TabLine from 'components/Tabs/TabLine';

import {
  Explore,
  KeyboardArrowRight,
} from '@mui/icons-material';
import TryIcon from '@mui/icons-material/Try';
import {
  Button,
  Typography,
} from '@mui/material';

import MessageTape from './MessageTape';
import useHandleUserEvents from './useHandleUserEvents';

export default function ExploreQuestions() {
    // Manage which subcategory questions are expanded
    const {dispatchAssistant,stateApp} = useAppInfo()
    const {onboarding} = stateApp
    const userRole = onboarding?.userRole
    const {handleSend} = useHandleUserEvents()
    const [expanded, setExpanded] = useState({});
    const [activeTab, setActiveTab] = useState(0);
  
    // Refs for each category to scroll to
    const categoryRefs = useRef([]);
    const observerRef = useRef();
  
    // Toggle the expansion of a specific subcategory
    const handleToggle = (category, subCategory) => {
      const key = `${category}-${subCategory}`;
      setExpanded((prev) => ({
        ...prev,
        [key]: !prev[key]
      }));
    };
  
    // Handle tab change
    const handleTabChange = (newValue) => {
      setActiveTab(newValue);
      categoryRefs.current[newValue].scrollIntoView({top:-150, behavior: 'smooth' });
    };
  
    // Use Intersection Observer to detect which category is in view
    useEffect(() => {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = categoryRefs.current.indexOf(entry.target);
              setActiveTab(index);
            }
          });
        },
        { rootMargin: '-50px 0px 50px 0px', threshold: 0.90}
      );
  
      // Observe each category div
      categoryRefs.current.forEach((ref) => {
        if (ref) observerRef.current.observe(ref);
      });
  
      // Cleanup on unmount
      return () => {
        if (observerRef.current) observerRef.current.disconnect();
      };
    }, []);

    const questionsData = userRole === "advisor" ? exploreQuestionsAdvisors : exploreQuestionsConsumer
  
    return (
      <div style={{ width: '100%', boxSizing: 'border-box'
      ,height:"100%",overflow:"auto"}}>
        {/* Tabs for categories */}
        
        <h2>Explore Questions</h2>
        <h4 style={{fontWeight:"normal",color:"gray"}}>These are just a few sample questions 
            you can ask Quinn to get you started...</h4>
        <TabLine
        tabList={questionsData.map((obj) => (obj.category))}
          tabIndex={activeTab}
          handleTabChange={handleTabChange}
          tabsSx={{paddingTop:"1rem",position:"sticky",
            top:0,backgroundColor:"white",zIndex:1}}
        >
         
        </TabLine
        >
  
        {questionsData.map((category, index) => (
          <div
            key={index} 
            ref={(el) => (categoryRefs.current[index] = el)}   
            style={{ marginBottom: 20, padding: '1rem 0' }}
          >
  
            <h4 style={{fontWeight:"600"
              ,color:"black",padding:"1rem 0"}}>{category?.category}</h4>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
              {category.subCategory.map((sub, idx) => (
                <div
                  key={idx}
                  style={{
                    padding: '1rem',
                    maxWidth: '400px',
                    minWidth: '300px',
                    // border:"1px solid #e0e0e0",
                   backgroundColor:"#fafafa",
                    borderRadius: '5px'
                  }}
                >
                  <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                  <TryIcon sx={{color:"gray"}} fontSize='small'/>
                  <Typography variant="h6" style={{ marginBottom: 5,color:"gray", fontSize: '1rem',fontWeight:"bold"}}>
                    {sub.title}
                  </Typography>
                  </div>
                  <div>
                    {/* Show only the first 3 questions unless expanded */}
                    {(expanded[`${category.category}-${sub.title}`]
                      ? sub.questions
                      : sub.questions.slice(0, 3)
                    ).map((question, qIdx) => (
                      <Button
                        onClick={()=>{dispatchAssistant({exploreQuestions:false,chatGptMessage:question});handleSend(question);}}
                        key={qIdx}
                        variant="text"
                        fullWidth
                        // variant="outlined"
                        // sx={{color:"black","&:hover":{color:"primary.main"}}}
                        style={{ fontSize: '0.9rem', textAlign: 'left', justifyContent: 'flex-start'}}
                      >
                        <KeyboardArrowRight style={{fontSize:"0.8rem",marginRight:"0.5rem"}}/>
                        {question}
                      </Button>
                    ))}
                  </div>
                  {sub.questions.length > 3 && (
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      onClick={() => handleToggle(category.category, sub.title)}
                    >
                      {expanded[`${category.category}-${sub.title}`] ? 'Show Less' : 'More'}
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <MessageTape/>
      </div>
    );
  }


export function ExploreQuestionButton({variant="text",sx={},icon=true,text="Explore Questions"}){
  const { handleMessage, handleSend,handleExploreQuestions} = useHandleUserEvents();
 
  return (
    <Button 
    variant='text'
    sx={{...sx}}
    startIcon={icon && <Explore fontSize='small'/>}  onClick={()=>handleExploreQuestions()}>
      {text}</Button>
  )
}


  const exploreQuestionsConsumer = [
    {
      category: "Personal Finance",
      subCategory: [
          {
              title: "Financial Wellness",
              questions: [
                "How am I doing financially?",
                "How can I improve my financials?",
                "How can I grow my Networth?",
              ]
            },
            {
              title: "My Situation",
              questions: [
                "What is my Networth",
                "What is my Asset Allocation?",
                "How much do I owe?",
                "How is my portfolio doing?",
                "How much am I burning per month?",
              ]
            },
        {
          title: "Budgeting",
          questions: [
            "How much am I spending per month?",
            "How do I create a budget that works for me?",
            "What are the best strategies to manage monthly expenses?",
            "How do I track my spending effectively?",
            "What should I do if my expenses exceed my income?",
            "How can I adjust my budget to accommodate unexpected expenses?"
          ]
        },
        {
          title: "Saving Strategies",
          questions: [
            "How much am I saving each month?",
            "How much should I save each month?",
            "What are effective ways to build an emergency fund?",
            "What percentage of my income should I allocate towards savings?",
            "Is it better to save or invest extra cash?",
            "How can I save for multiple goals at the same time?"
          ]
        },
      ]
    },
    {
      category: "Life Scenarios",
      subCategory: [
          {
              title: "House Purchase",
              questions: [
                "Can I afford a House with my current financials?",
                "Give me a plan to purchase a house",
                "What are the things I should consider when buying a House?",
              ]
            },
            {
              title: "Child Planning",
              questions: [
                "I am planning to have a kid - can you give me a financial plan?",
                "What are the costs asociated with having a child?",
              ]
            },
        
        {
          title: "Retirement Planning",
          questions: [
            "Give me a retire plan?",
            "Can I retire in next 5 years?",
            "How much should I have in my bank to retire?",
          ]
        },
        {
          title: "Other Scenarios",
          questions: [
            "I want a car and travel annually. Give me a plan.",
            "I am currently renting but planning on buying a house. What is the extra cost?",
          ]
        },
      ]
    },
    { category: "Portfolio Management",
      subCategory: [
      {title: "Portfolio Health",
      questions: [
        "How is my Portfolio Doing?",
        "Am I beating the market?",
        "What are my top holdings?",
        "How should I rebalance my portfolio?",
        "What percentage should I allocate to stocks vs. bonds?",
        "How can I include alternative assets in my portfolio?",
      ]},
      {title: "Enhance Portfolio",
          questions: [
            "Find me opportunities that align my Portfolio Strategy",
            "How should I rebalance my portfolio?",
            "How can I include alternative assets in my portfolio?",
          ]},
      {title: "Risk Management",
          questions: [
            "What is my Risk Tolerance?",
            "What is the best asset allocation for my risk profile?",
            "What should I do if I want to reduce my exposure to market risk?",
            "What percentage should I allocate to stocks vs. bonds?",
  
          ]}]
    },
    {
      category: "Opportunities",
      subCategory: [
        {
          title: "Long Term Investing",
          questions: [
            "Find me opportunities that align with my Portfolio",
            "Show me some undervalued Stocks",
          ]
        },
        {
          title: "Trading",
          questions: [
            "Give me top trending Stocks in the Technology Sector",
            "Give me trending Cryptos",
          ]
        },
        {
          title: "Industry Specific",
          questions: [
            "Top IT stocks",
            "Top Healthcare stocks",
            "Top Energy stocks",
            "Top Consumer Staple stocks",
            "Top Auto stocks",
          ]
        },
      ]
    },
    {
      category: "Market & Economy",
      subCategory: [
        {
          title: "Market Trends",
          questions: [
            "What are the current trends in the stock market?",
            "What is the outlook for the stock market?",
            "What are the biggest risks facing the markets right now?",
          ]
        },
        {
          title: "Market News",
          questions: [
            "Latest Stock News",
            "Latest Crypto News",
            "Latest Forex News",
          ]
        },
        {
          title: "Economy",
          questions: [
            "How is the Economy Doing?",
            "What is the outlook for the Economy?",
            "How do interest rates affect market performance?",
            "How does inflation impact different asset classes?",
          ]
        }
      ]
    },
    {
      category: "Stock Analysis",
      subCategory: [
        {
          title: "Fundamental Analysis",
          questions: [
            "AAPL vs MSFT vs GOOG vs NVDA vs META",
            "Give me NVDA - Discounted Cash Flow Valuation",
            "How does NVDA compare with its Industry?",
            "TSLA latest financials?",
          ]
        },
        {
          title: "Technical Analysis",
          questions: [
            "META price comparison with its peers",
            "NVDA technical indicators",
            "NVDA correlation with its peers",
          ]
        },
        {
          title: "Earning Call Higlights",
          questions: [
            "Give me the earning call highlights for TSLA",
            "Key issues NVDA is having according to its recent earning calls",
            "What is the outlook for AAPL from its recent earning calls?",
            "What are the biggest risks WMT is facing according to its recent earning calls?",
          ]
        }
      ]
    },
    {
      category: "Reports",
      subCategory: [
        {
          title: "My Situation",
          questions: [
            "Give me report on My Financials",
            "Give me report on My Portfolio",
          ]
        },
        {
          title: "Market & Economy",
          questions: [
            "Give me report on the Market",
            "Give me report on the Economy",
          ]
        }
      ]
    }
  
  ];
  
export const exploreQuestionsAdvisors = [
  {
    category: "Client Engagement & Communication",
    subCategory: [
      {
        title: "Client Outreach",
        questions: [
          "Which clients haven't been contacted in the last 3 months?",
          "Which clients have upcoming review meetings?",
          "Which clients have significant portfolio changes that need discussion?",
          "Which clients have upcoming life events that need attention?",
          "Which clients have the highest AUM but lowest engagement?",
          "Which clients have pending action items that need follow-up?"
        ]
      },
      {
        title: "Client Segmentation",
        questions: [
          "Show me my top 10 clients by AUM",
          "Which clients are in the highest risk category?",
          "Which clients are approaching retirement age?",
          "Which clients have multiple active scenarios?",
          "Which clients have the highest financial wellness score?",
          "Which clients have the lowest goal success rate?"
        ]
      },
      {
        title: "Client Life Events",
        questions: [
          "Which clients have upcoming birthdays or anniversaries?",
          "Which clients have children approaching college age?",
          "Which clients are approaching retirement age?",
          "Which clients have recently changed jobs or careers?",
          "Which clients have recently married or divorced?",
          "Which clients have recently moved or relocated?"
        ]
      }
    ]
  },
  {
    category: "Portfolio & Investment Management",
    subCategory: [
      {
        title: "Portfolio Analysis",
        questions: [
          "Which clients need portfolio rebalancing?",
          "Which clients have high concentration in specific sectors?",
          "Which clients have underperforming portfolios?",
          "Which clients have the highest risk-adjusted returns?",
          "Which clients have significant cash positions?",
          "Which clients have tax-loss harvesting opportunities?"
        ]
      },
      {
        title: "Risk Management",
        questions: [
          "Which clients have risk scores above their target?",
          "Which clients need risk tolerance reassessment?",
          "Which clients have high exposure to volatile sectors?",
          "Which clients need insurance coverage review?",
          "Which clients have estate planning gaps?",
          "Which clients need emergency fund review?"
        ]
      },
      {
        title: "Investment Opportunities",
        questions: [
          "Which clients have cash for new investments?",
          "Which clients are suitable for alternative investments?",
          "Which clients have tax-advantaged investment opportunities?",
          "Which clients could benefit from sector rotation?",
          "Which clients have ESG investment preferences?",
          "Which clients have international exposure gaps?"
        ]
      }
    ]
  },
  {
    category: "Prospect Management",
    subCategory: [
      {
        title: "Prospect Analysis",
        questions: [
          "Show me my top prospects by potential AUM",
          "Which prospects are in my target market?",
          "Which prospects have similar profiles to my best clients?",
          "Which prospects have urgent financial planning needs?",
          "Which prospects have high net worth but no advisor?",
          "Which prospects are in industries I specialize in?"
        ]
      },
      {
        title: "Prospect Engagement",
        questions: [
          "Which prospects have been contacted recently?",
          "Which prospects have shown interest in specific services?",
          "Which prospects have upcoming review meetings?",
          "Which prospects need follow-up communication?",
          "Which prospects have specific service needs I can address?",
          "Which prospects have been referred by existing clients?"
        ]
      },
      {
        title: "Prospect Conversion",
        questions: [
          "Which prospects are most likely to convert?",
          "Which prospects have the highest potential AUM?",
          "Which prospects have urgent financial planning needs?",
          "Which prospects have been in the pipeline the longest?",
          "Which prospects have specific pain points I can address?",
          "Which prospects have the best fit with my services?"
        ]
      }
    ]
  },
  {
    category: "Financial Planning & Scenarios",
    subCategory: [
      {
        title: "Scenario Analysis",
        questions: [
          "Which clients need retirement planning updates?",
          "Which clients have estate planning gaps?",
          "Which clients need tax planning strategies?",
          "Which clients have college planning needs?",
          "Which clients need business succession planning?",
          "Which clients have insurance coverage gaps?"
        ]
      },
      {
        title: "Goal Tracking",
        questions: [
          "Which clients are behind on their financial goals?",
          "Which clients have achieved their primary goals?",
          "Which clients need goal reassessment?",
          "Which clients have conflicting financial goals?",
          "Which clients have new goals that need planning?",
          "Which clients have the highest goal success rate?"
        ]
      },
      {
        title: "Life Event Planning",
        questions: [
          "Which clients are planning to buy a home?",
          "Which clients are planning to start a business?",
          "Which clients are planning to retire early?",
          "Which clients are planning to relocate?",
          "Which clients are planning to have children?",
          "Which clients are planning to sell their business?"
        ]
      }
    ]
  },
  {
    category: "Business Development",
    subCategory: [
      {
        title: "Client Growth",
        questions: [
          "Which clients have the highest growth potential?",
          "Which clients could benefit from additional services?",
          "Which clients have referral potential?",
          "Which clients have the highest lifetime value?",
          "Which clients have the most complex needs?",
          "Which clients have the highest satisfaction scores?"
        ]
      },
      {
        title: "Service Optimization",
        questions: [
          "Which services are most in demand?",
          "Which clients need specialized services?",
          "Which services have the highest client satisfaction?",
          "Which services have the highest revenue potential?",
          "Which services need improvement?",
          "Which services are underutilized?"
        ]
      },
      {
        title: "Market Analysis",
        questions: [
          "Which market segments have the highest growth potential?",
          "Which industries have the most potential clients?",
          "Which geographic areas have the most opportunities?",
          "Which services are trending in my market?",
          "Which competitors are most active in my market?",
          "Which market segments are underserved?"
        ]
      }
    ]
  },
  {
    category: "Compliance & Risk",
    subCategory: [
      {
        title: "Compliance Monitoring",
        questions: [
          "Which clients need document updates?",
          "Which clients have compliance gaps?",
          "Which clients need policy reviews?",
          "Which clients have regulatory requirements?",
          "Which clients need disclosure updates?",
          "Which clients have pending compliance actions?"
        ]
      },
      {
        title: "Risk Assessment",
        questions: [
          "Which clients have high-risk profiles?",
          "Which clients need risk reassessment?",
          "Which clients have unusual activity patterns?",
          "Which clients have compliance violations?",
          "Which clients have documentation gaps?",
          "Which clients need enhanced due diligence?"
        ]
      },
      {
        title: "Document Management",
        questions: [
          "Which clients have expired documents?",
          "Which clients need document reviews?",
          "Which clients have missing documentation?",
          "Which clients need policy updates?",
          "Which clients have pending signatures?",
          "Which clients need document renewals?"
        ]
      }
    ]
  },
  {
    category: "Reporting & Analytics",
    subCategory: [
      {
        title: "Performance Reporting",
        questions: [
          "Generate a performance summary for my top clients",
          "Show me clients with significant portfolio changes",
          "Which clients have exceeded their goals?",
          "Which clients need performance reviews?",
          "Generate a sector allocation report",
          "Show me clients with tax-loss harvesting opportunities"
        ]
      },
      {
        title: "Business Analytics",
        questions: [
          "Show me my client growth trends",
          "Which services are most profitable?",
          "Which clients have the highest revenue?",
          "Show me my client retention rates",
          "Which market segments are growing fastest?",
          "Show me my client acquisition costs"
        ]
      },
      {
        title: "Client Insights",
        questions: [
          "Generate insights on my client base",
          "Show me client satisfaction trends",
          "Which clients need attention?",
          "Show me client engagement patterns",
          "Which clients have the most complex needs?",
          "Generate a client health score report"
        ]
      }
    ]
  }
];
  