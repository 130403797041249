import { useAppInfo } from 'AppState';
import { formatNumber } from 'utils/utilGeneral';

import { Typography } from '@mui/material';

import TemplateAction from './TemplateAction';

export function NoSavings() {
    const { stateMyFinancials } = useAppInfo();
    const { summaryStats, currency } = stateMyFinancials;
    const { totalIncome, totalExpense, totalNetCashflow } = summaryStats;

    const getMessage = () => {
        const netCashflowRatio = totalNetCashflow?.m / totalExpense?.m;
        const cashflowAmount = `${currency?.symbol} ${formatNumber(totalNetCashflow?.m)}`;

        if (netCashflowRatio > 0.2) {
            return (
                <>
                    You currently have enough cash to meet your monthly expenses and will have net cash of <strong>{cashflowAmount}</strong>. <br />
                    That's great! However, you are not currently saving, and savings are crucial for building long-term financial security.
                </>
            );
        }
        if (totalNetCashflow?.m < 0) {
            return (
                <>
                    You currently don't have enough cash to save. <br />
                    At the end of the month, you are projected to have <strong>{cashflowAmount}</strong>. Consider cutting expenses or increasing your income to start saving.
                </>
            );
        }
        if (netCashflowRatio < 0.05 && netCashflowRatio > 0) {
            return (
                <>
                    You are just barely covering your monthly expenses. <br />
                    You need to focus on budgeting and finding ways to save for emergencies and future goals.
                </>
            );
        }
        return null;
    };

    const message = getMessage();

    return (
        <TemplateAction actionHeading={"Here are some ways to build your savings account:"}
        actions={[{label:totalNetCashflow?.m > 0.05?"Automate transfers to a savings account":"Budget and start saving"}]}
        >
            {message && (
                <Typography variant="body1" gutterBottom>
                    {message}
                </Typography>
            )}

        </TemplateAction>
    );
}


export function GrowingAssets() {
    const { stateMyFinancials } = useAppInfo();
    const {
        summaryStats,
        currency,
        riskTolerance,
        investmentPreferenceRiskTolerance,
        goalAnalytics,
        goalsPeriodic,
    } = stateMyFinancials;

    const hasTakenRiskTest = riskTolerance?.test;
    const selectedAssets = investmentPreferenceRiskTolerance.filter(
        (asset) => asset.selected && asset.targetAllocation > 0
    );
    const goalTerm = goalAnalytics?.goalsTimeHorizon || 0; // Years to meet goals
    const goalsExist = goalsPeriodic?.length > 0;

    const actionDetailsWithGoals = [
        `Automate monthly contributions to savings or investment accounts.`,
        goalsExist && `To meet your goals, aim for an investment time horizon of at least ${goalTerm} years.`,
        selectedAssets.length > 0 &&
            `Focus on maintaining the allocation in your selected assets to achieve your desired balance: ${selectedAssets
                .map((asset) => `${asset?.type} (${asset?.targetAllocation}%)`)
                .join(", ")}.`,
    ].filter(Boolean);

    const riskToleranceDetails = [
        "Take a risk tolerance test to align your investments with your comfort level.",
        "Define your short-term and long-term financial goals to prioritize investments.",
    ];

    return (
        <TemplateAction
            actionHeading={"Here are some steps to Grow Your Assets Strategically:"}
            actions={[
                !hasTakenRiskTest && {
                    label: "Take a Risk Tolerance Test",
                },
                selectedAssets.length === 0 && {
                    label: "Set Investment Preferences",
                },
                { label: "Automate Monthly Contributions", link: "/MyFinancials/Assets" },
            ].filter(Boolean)}
        >
            <Typography variant="body1" gutterBottom>
                Growing your assets requires consistent effort and strategic planning. By aligning your investments with your goals and risk tolerance, you can achieve a balanced and sustainable portfolio.
            </Typography>

            {hasTakenRiskTest ? (
                <>
                    <Typography variant="body1" gutterBottom>
                        With your risk tolerance assessed, here’s how to grow your assets:
                    </Typography>
                    <ul>
                        {!selectedAssets.length > 0 && (
                            <li>
                                <Typography>Set your investment preferences to begin building a diversified portfolio.</Typography>
                            </li>
                        )}
                        {actionDetailsWithGoals.map((detail, index) => (
                            <li key={index}>
                                <Typography>{detail}</Typography>
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <>
                    <Typography variant="body1" gutterBottom>
                        Not sure where to start? Take a risk tolerance test to understand your investment style and identify the right allocation strategy:
                    </Typography>
                    <ul>
                        {riskToleranceDetails.map((detail, index) => (
                            <li key={index}>
                                <Typography>{detail}</Typography>
                            </li>
                        ))}
                    </ul>
                </>
            )}

        </TemplateAction>
    );
}

export function DiversifiedAssets() {
    const { stateMyFinancials } = useAppInfo();
    const { assets, investmentPreferenceRiskTolerance, riskTolerance } = stateMyFinancials;

    const hasTakenRiskTest = riskTolerance?.test;

    // Calculate the number of unique asset classes in current assets
    const uniqueAssetClasses = [...new Set(assets.map((asset) => asset.type))];
    const diversificationLevel = uniqueAssetClasses.length;

    // Filter selected asset preferences with non-zero target allocations
    const selectedAssets = investmentPreferenceRiskTolerance.filter(
        (pref) => pref.selected && pref.targetAllocation > 0
    );

    // Check if diversification meets the best practice threshold
    const isDiversified = diversificationLevel >= 3;

    // Actionable insights based on diversification status and preferences
    const actionableSteps = [
        "Start small by reallocating a portion of your cash or savings into new asset classes",
        selectedAssets.length > 0 &&
            `Your current investment preferences include: ${selectedAssets
                .map((asset) => `${asset.type} (${asset.targetAllocation}%)`)
                .join(", ")}.`,
        "Reallocate funds to achieve a more balanced distribution across asset classes.",
    ].filter(Boolean);

    return (
        <TemplateAction
            actionHeading={"Here are some steps to Diversify Your Portfolio Strategically:"}
            actions={[
                !hasTakenRiskTest
                        && 
                {
                    label:  "Take a Risk Tolerance Test",
                },
                selectedAssets.length === 0 && {
                    label: "Set Investment Preferences",
                },
                { label: "Automate Asset Allocation", link: "/MyFinancials/Assets" },
            ]}
        >
            <Typography variant="body1" gutterBottom>
                Diversification is key to reducing risk in your portfolio. By spreading your investments across multiple asset classes, you can achieve a better balance between risk and return.
            </Typography>

            {isDiversified ? (
                <Typography variant="body1" gutterBottom>
                    Your portfolio is diversified across <strong>{diversificationLevel}</strong> asset classes. Great job maintaining a balanced portfolio! Continue to monitor your allocation to ensure it aligns with your risk tolerance and financial goals.
                </Typography>
            ) : (
                <>
                    <Typography variant="body1" gutterBottom>
                        Your portfolio currently includes <strong>{diversificationLevel}</strong> asset classes. To meet the best practice of diversifying across at least 3 asset classes, consider these steps:
                    </Typography>
                    <ul>
                        {actionableSteps.map((step, index) => (
                            <li key={index}>
                                <Typography>{step}</Typography>
                            </li>
                        ))}
                    </ul>
                </>
            )}

        </TemplateAction>
    );
}
