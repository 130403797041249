import React, {
  useMemo,
  useState,
} from 'react';

import CircleScore from 'components/Charts/CircleScore';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import TabRound from '../../components/Tabs/TabRound';
import FilterModal from '../components/FilterModal';
import PageHeader from '../components/PageHeader';
import {
  dummyInsights,
  getProspectMetricsData,
} from '../data/dummyData';
import styles from './Clients.module.css';
import {
  clientsData,
  formatCurrency,
  formatDate,
} from './clientsData';

// Mock client metrics data
const clientMetrics = {
  totalClients: 45,
  activeClients: 38,
  newClientsThisMonth: 3,
  clientRetentionRate: 95
};

function MetricCard({ title, value, subtitle, icon }) {
  return (
    <Box className={styles.metricCard}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <Box sx={{ color: 'primary.light', opacity: 0.9 }}>
          {icon}
        </Box>
        <Box>
          <Typography variant="h6" className={styles.metricValue}>
            {value}
          </Typography>
          <Typography variant="body2" className={styles.metricTitle}>
            {title}
          </Typography>
          <Typography variant="caption" className={styles.metricSubtitle}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function TabPanel({ children, value, index }) {
  return (
    <div hidden={value !== index} style={{ padding: '1rem 0' }}>
      {value === index && children}
    </div>
  );
}

function getInitials(name) {
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase();
}

export default function Clients() {
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    portfolioType: [],
    riskScore: [],
    status: [],
    aumRange: [0, 5000000],
    growthRange: [0, 20],
    wellnessScore: [0, 100],
    goalSuccessRate: [0, 100],
  });

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const handleFilterOpen = () => setFilterOpen(true);
  const handleFilterClose = () => setFilterOpen(false);

  const handleFilterReset = () => {
    setFilters({
      portfolioType: [],
      riskScore: [],
      status: [],
      aumRange: [0, 5000000],
      growthRange: [0, 20],
      wellnessScore: [0, 100],
      goalSuccessRate: [0, 100],
    });
  };

  const handleFilterApply = () => {
    // Apply filters logic here
    handleFilterClose();
  };

  const filteredClients = useMemo(() => {
    return clientsData.filter(client => {
      // Text search filter
      const matchesSearch = 
        client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.occupation.toLowerCase().includes(searchTerm.toLowerCase());

      // Portfolio Type filter
      const matchesPortfolioType = 
        filters.portfolioType.length === 0 || 
        filters.portfolioType.includes(client.portfolioType);

      // Risk Score filter
      const matchesRiskScore = 
        filters.riskScore.length === 0 || 
        filters.riskScore.includes(client.riskScore);

      // Status filter
      const matchesStatus = 
        filters.status.length === 0 || 
        filters.status.includes(client.status);

      // AUM Range filter
      const matchesAUM = 
        client.aum >= filters.aumRange[0] && 
        client.aum <= filters.aumRange[1];

      // Growth Range filter
      const matchesGrowth = 
        client.growth >= filters.growthRange[0] && 
        client.growth <= filters.growthRange[1];

      // Financial Wellness Score filter
      const matchesWellness = 
        client.financialWellnessScore >= filters.wellnessScore[0] && 
        client.financialWellnessScore <= filters.wellnessScore[1];

      // Goal Success Rate filter
      const matchesGoalSuccess = 
        client.goalSuccessRate >= filters.goalSuccessRate[0] && 
        client.goalSuccessRate <= filters.goalSuccessRate[1];

      return matchesSearch && 
             matchesPortfolioType && 
             matchesRiskScore && 
             matchesStatus && 
             matchesAUM && 
             matchesGrowth && 
             matchesWellness && 
             matchesGoalSuccess;
    });
  }, [searchTerm, filters]);

  const topClients = [...clientsData]
    .sort((a, b) => b.aum - a.aum)
    .slice(0, 10);

  const totalAUM = clientsData.reduce((sum, client) => sum + client.aum, 0);
  const averageGrowth = clientsData.reduce((sum, client) => sum + client.growth, 0) / clientsData.length;
  const activeScenarios = clientsData.reduce((sum, client) => sum + client.activeScenarios, 0);

  const activeFiltersCount = Object.values(filters).filter(Boolean).length;

  const portfolioTypes = ['Conservative', 'Moderate', 'Aggressive'];
  const riskScores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const statuses = ['Active', 'Inactive'];

  const prospectMetricsData = getProspectMetricsData(dummyInsights.prospectMetrics);

  return (
    <div className={styles.container}>
      <PageHeader
        title="Clients"
        subtitle="Manage and monitor your client relationships"
        searchPlaceholder="Search clients..."
        searchValue={searchTerm}
        onSearchChange={setSearchTerm}
        onFilterClick={handleFilterOpen}
        activeFiltersCount={activeFiltersCount}
      />

      <Box className={styles.metricsContainer}>
        <MetricCard
          title="Total Clients"
          value={clientMetrics.totalClients}
          subtitle="Active client relationships"
          icon={<PersonIcon />}
        />
        <MetricCard
          title="Active Clients"
          value={clientMetrics.activeClients}
          subtitle="Engaged in last 30 days"
          icon={<GroupIcon />}
        />
        <MetricCard
          title="New Clients"
          value={clientMetrics.newClientsThisMonth}
          subtitle="Added this month"
          icon={<PersonAddIcon />}
        />
        <MetricCard
          title="Retention Rate"
          value={`${clientMetrics.clientRetentionRate}%`}
          subtitle="Client retention rate"
          icon={<AutoGraphIcon />}
        />
      </Box>

      <FilterModal
        open={filterOpen}
        onClose={handleFilterClose}  
        onSearchChange={setSearchTerm}
        searchTerm={searchTerm}
        title="Filter Clients"
        onApply={handleFilterApply}
        onReset={handleFilterReset}
      >
        <div className={styles.filterContent}>
          {/* Portfolio Type Filter */}
          <div className={styles.filterSection}>
            <Typography className={styles.filterSectionTitle}>
              Portfolio Type
            </Typography>
            <div className={styles.filterOptions}>
              {portfolioTypes.map((type) => (
                <Chip
                  key={type}
                  label={type}
                  className={`${styles.filterChip} ${
                    filters.portfolioType.includes(type) ? styles.selected : ''
                  }`}
                  onClick={() => {
                    setFilters(prev => ({
                      ...prev,
                      portfolioType: prev.portfolioType.includes(type)
                        ? prev.portfolioType.filter(t => t !== type)
                        : [...prev.portfolioType, type]
                    }));
                  }}
                />
              ))}
            </div>
          </div>

          {/* Risk Score Filter */}
          <div className={styles.filterSection}>
            <Typography className={styles.filterSectionTitle}>
              Risk Score
            </Typography>
            <div className={styles.filterOptions}>
              {riskScores.map((score) => (
                <Chip
                  key={score}
                  label={score}
                  className={`${styles.filterChip} ${
                    filters.riskScore.includes(score) ? styles.selected : ''
                  }`}
                  onClick={() => {
                    setFilters(prev => ({
                      ...prev,
                      riskScore: prev.riskScore.includes(score)
                        ? prev.riskScore.filter(s => s !== score)
                        : [...prev.riskScore, score]
                    }));
                  }}
                />
              ))}
            </div>
          </div>

          {/* Status Filter */}
          <div className={styles.filterSection}>
            <Typography className={styles.filterSectionTitle}>
              Status
            </Typography>
            <div className={styles.filterOptions}>
              {statuses.map((status) => (
                <Chip
                  key={status}
                  label={status}
                  className={`${styles.filterChip} ${
                    filters.status.includes(status) ? styles.selected : ''
                  }`}
                  onClick={() => {
                    setFilters(prev => ({
                      ...prev,
                      status: prev.status.includes(status)
                        ? prev.status.filter(s => s !== status)
                        : [...prev.status, status]
                    }));
                  }}
                />
              ))}
            </div>
          </div>

          {/* AUM Range Filter */}
          <div className={styles.filterSection}>
            <Typography className={styles.filterSectionTitle}>
              Assets Under Management
            </Typography>
            <div className={styles.rangeSlider}>
              <Slider
                value={filters.aumRange}
                onChange={(_, newValue) => {
                  setFilters(prev => ({ ...prev, aumRange: newValue }));
                }}
                valueLabelDisplay="auto"
                min={0}
                max={5000000}
                step={100000}
                marks={[
                  { value: 0, label: '$0' },
                  { value: 2500000, label: '$2.5M' },
                  { value: 5000000, label: '$5M' },
                ]}
                sx={{
                  '& .MuiSlider-thumb': {
                    height: 16,
                    width: 16,
                    backgroundColor: '#fff',
                    border: '2px solid #3B82F6',
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                      boxShadow: 'inherit',
                    },
                    '&:before': {
                      display: 'none',
                    },
                  },
                  '& .MuiSlider-track': {
                    height: 4,
                    backgroundColor: '#E5E7EB',
                  },
                  '& .MuiSlider-rail': {
                    height: 4,
                    backgroundColor: '#F3F4F6',
                  },
                  '& .MuiSlider-mark': {
                    backgroundColor: '#E5E7EB',
                    height: 4,
                    width: 4,
                    '&.MuiSlider-markActive': {
                      backgroundColor: '#fff',
                    },
                  },
                  '& .MuiSlider-markLabel': {
                    fontSize: '0.75rem',
                    color: '#6B7280',
                  },
                  '& .MuiSlider-valueLabel': {
                    backgroundColor: '#3B82F6',
                    color: '#fff',
                    fontSize: '0.75rem',
                    padding: '4px 8px',
                    borderRadius: '4px',
                  },
                }}
              />
              <div className={styles.rangeValues}>
                <span>{formatCurrency(filters.aumRange[0])}</span>
                <span>{formatCurrency(filters.aumRange[1])}</span>
              </div>
            </div>
          </div>

          {/* Growth Range Filter */}
          <div className={styles.filterSection}>
            <Typography className={styles.filterSectionTitle}>
              Growth Rate
            </Typography>
            <div className={styles.rangeSlider}>
              <Slider
                value={filters.growthRange}
                onChange={(_, newValue) => {
                  setFilters(prev => ({ ...prev, growthRange: newValue }));
                }}
                valueLabelDisplay="auto"
                min={0}
                max={20}
                step={0.5}
                marks={[
                  { value: 0, label: '0%' },
                  { value: 10, label: '10%' },
                  { value: 20, label: '20%' },
                ]}
                sx={{
                  '& .MuiSlider-thumb': {
                    height: 16,
                    width: 16,
                    backgroundColor: '#fff',
                    border: '2px solid #3B82F6',
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                      boxShadow: 'inherit',
                    },
                    '&:before': {
                      display: 'none',
                    },
                  },
                  '& .MuiSlider-track': {
                    height: 4,
                    backgroundColor: '#E5E7EB',
                  },
                  '& .MuiSlider-rail': {
                    height: 4,
                    backgroundColor: '#F3F4F6',
                  },
                  '& .MuiSlider-mark': {
                    backgroundColor: '#E5E7EB',
                    height: 4,
                    width: 4,
                    '&.MuiSlider-markActive': {
                      backgroundColor: '#fff',
                    },
                  },
                  '& .MuiSlider-markLabel': {
                    fontSize: '0.75rem',
                    color: '#6B7280',
                  },
                  '& .MuiSlider-valueLabel': {
                    backgroundColor: '#3B82F6',
                    color: '#fff',
                    fontSize: '0.75rem',
                    padding: '4px 8px',
                    borderRadius: '4px',
                  },
                }}
              />
              <div className={styles.rangeValues}>
                <span>{filters.growthRange[0]}%</span>
                <span>{filters.growthRange[1]}%</span>
              </div>
            </div>
          </div>

          {/* Financial Wellness Score Filter */}
          <div className={styles.filterSection}>
            <Typography className={styles.filterSectionTitle}>
              Financial Wellness Score
            </Typography>
            <div className={styles.rangeSlider}>
              <Slider
                value={filters.wellnessScore}
                onChange={(_, newValue) => {
                  setFilters(prev => ({ ...prev, wellnessScore: newValue }));
                }}
                valueLabelDisplay="auto"
                min={0}
                max={100}
                step={5}
                marks={[
                  { value: 0, label: '0' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' },
                ]}
                sx={{
                  '& .MuiSlider-thumb': {
                    height: 16,
                    width: 16,
                    backgroundColor: '#fff',
                    border: '2px solid #3B82F6',
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                      boxShadow: 'inherit',
                    },
                    '&:before': {
                      display: 'none',
                    },
                  },
                  '& .MuiSlider-track': {
                    height: 4,
                    backgroundColor: '#E5E7EB',
                  },
                  '& .MuiSlider-rail': {
                    height: 4,
                    backgroundColor: '#F3F4F6',
                  },
                  '& .MuiSlider-mark': {
                    backgroundColor: '#E5E7EB',
                    height: 4,
                    width: 4,
                    '&.MuiSlider-markActive': {
                      backgroundColor: '#fff',
                    },
                  },
                  '& .MuiSlider-markLabel': {
                    fontSize: '0.75rem',
                    color: '#6B7280',
                  },
                  '& .MuiSlider-valueLabel': {
                    backgroundColor: '#3B82F6',
                    color: '#fff',
                    fontSize: '0.75rem',
                    padding: '4px 8px',
                    borderRadius: '4px',
                  },
                }}
              />
              <div className={styles.rangeValues}>
                <span>{filters.wellnessScore[0]}</span>
                <span>{filters.wellnessScore[1]}</span>
              </div>
            </div>
          </div>

          {/* Goal Success Rate Filter */}
          <div className={styles.filterSection}>
            <Typography className={styles.filterSectionTitle}>
              Goal Success Rate
            </Typography>
            <div className={styles.rangeSlider}>
              <Slider
                value={filters.goalSuccessRate}
                onChange={(_, newValue) => {
                  setFilters(prev => ({ ...prev, goalSuccessRate: newValue }));
                }}
                valueLabelDisplay="auto"
                min={0}
                max={100}
                step={5}
                marks={[
                  { value: 0, label: '0%' },
                  { value: 50, label: '50%' },
                  { value: 100, label: '100%' },
                ]}
                sx={{
                  '& .MuiSlider-thumb': {
                    height: 16,
                    width: 16,
                    backgroundColor: '#fff',
                    border: '2px solid #3B82F6',
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                      boxShadow: 'inherit',
                    },
                    '&:before': {
                      display: 'none',
                    },
                  },
                  '& .MuiSlider-track': {
                    height: 4,
                    backgroundColor: '#E5E7EB',
                  },
                  '& .MuiSlider-rail': {
                    height: 4,
                    backgroundColor: '#F3F4F6',
                  },
                  '& .MuiSlider-mark': {
                    backgroundColor: '#E5E7EB',
                    height: 4,
                    width: 4,
                    '&.MuiSlider-markActive': {
                      backgroundColor: '#fff',
                    },
                  },
                  '& .MuiSlider-markLabel': {
                    fontSize: '0.75rem',
                    color: '#6B7280',
                  },
                  '& .MuiSlider-valueLabel': {
                    backgroundColor: '#3B82F6',
                    color: '#fff',
                    fontSize: '0.75rem',
                    padding: '4px 8px',
                    borderRadius: '4px',
                  },
                }}
              />
              <div className={styles.rangeValues}>
                <span>{filters.goalSuccessRate[0]}%</span>
                <span>{filters.goalSuccessRate[1]}%</span>
              </div>
            </div>
          </div>
        </div>
      </FilterModal>

      <Box>
        <TabRound 
          options={["Overview", "Top Clients", "Portfolio & Risk Management", "Financial Wellness"]}
          value={tabValue}
          onChange={handleTabChange}
        />
      </Box>

      <TabPanel value={tabValue} index={0}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="150">Client Name</TableCell>
                <TableCell width="120">Location</TableCell>
                <TableCell width="100">AUM</TableCell>
                <TableCell width="100">Growth</TableCell>
                <TableCell width="120">Last Contact</TableCell>
                <TableCell width="120">Next Review</TableCell>
                <TableCell width="180">To-dos</TableCell>
                <TableCell width="100">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients.map((client) => (
                <TableRow key={client.id} className={styles.tableRow}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Avatar 
                        sx={{ 
                          width: 40,
                          height: 40,
                          fontSize: '1rem',
                          fontWeight: 500
                        }}
                      >
                        {getInitials(client.name)}
                      </Avatar>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {client.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{client.location}</TableCell>
                  <TableCell>{formatCurrency(client.aum)}</TableCell>
                  <TableCell>
                    <div className={styles.growthCell}>
                      {client.growth >= 10 ? (
                        <TrendingUpIcon className={styles.trendingUp} />
                      ) : (
                        <TrendingDownIcon className={styles.trendingDown} />
                      )}
                      {client.growth}%
                    </div>
                  </TableCell>
                  <TableCell>{formatDate(client.lastContact)}</TableCell>
                  <TableCell>{formatDate(client.nextReview)}</TableCell>
                  <TableCell>
                    <div className={styles.todoChips}>
                      {client.todos?.map((todo, idx) => (
                        <Chip 
                          key={idx}
                          label={todo}
                          size="small"
                          className={styles.todoChip}
                          variant="outlined"
                        />
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={client.status}
                      className={styles[client.status.toLowerCase()]}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="150">Client Name</TableCell>
                <TableCell width="100">AUM</TableCell>
                <TableCell width="100">Growth</TableCell>
                <TableCell width="200">Investment Goals</TableCell>
                <TableCell width="120">Active Scenarios</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topClients.map((client) => (
                <TableRow key={client.id} className={styles.tableRow}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Avatar 
                        sx={{ 
                          width: 40,
                          height: 40,
                          fontSize: '1rem',
                          fontWeight: 500
                        }}
                      >
                        {getInitials(client.name)}
                      </Avatar>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {client.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{formatCurrency(client.aum)}</TableCell>
                  <TableCell>
                    <div className={styles.growthCell}>
                      {client.growth >= 10 ? (
                        <TrendingUpIcon className={styles.trendingUp} />
                      ) : (
                        <TrendingDownIcon className={styles.trendingDown} />
                      )}
                      {client.growth}%
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={styles.goalChips}>
                      {client.investmentGoals.map((goal, idx) => (
                        <Chip 
                          key={idx}
                          label={goal}
                          size="small"
                          className={styles.goalChip}
                          variant="outlined"
                        />
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>{client.activeScenarios}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="150">Client Name</TableCell>
                <TableCell width="120">Portfolio Type</TableCell>
                <TableCell width="100">AUM</TableCell>
                <TableCell width="100">Growth</TableCell>
                <TableCell width="120">Risk Score</TableCell>
                <TableCell width="80">Age</TableCell>
                <TableCell width="150">Occupation</TableCell>
                <TableCell width="200">Investment Goals</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients.map((client) => (
                <TableRow key={client.id} className={styles.tableRow}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Avatar 
                        sx={{ 
                          width: 40,
                          height: 40,
                          fontSize: '1rem',
                          fontWeight: 500
                        }}
                      >
                        {getInitials(client.name)}
                      </Avatar>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {client.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={client.portfolioType}
                      className={styles[client.portfolioType.toLowerCase()]}
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{formatCurrency(client.aum)}</TableCell>
                  <TableCell>
                    <div className={styles.growthCell}>
                      {client.growth >= 10 ? (
                        <TrendingUpIcon className={styles.trendingUp} />
                      ) : (
                        <TrendingDownIcon className={styles.trendingDown} />
                      )}
                      {client.growth}%
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={styles.riskScore}>
                      <CircleScore 
                        width='40px'
                        height='40px'
                        fontSizeScore="1.4em"
                        title=''
                        score={client.riskScore} 
                        totalScore={10}/>
                      
                 
                    </div>
                  </TableCell>
                  <TableCell>{client.age}</TableCell>
                  <TableCell>{client.occupation}</TableCell>
                  <TableCell>
                    <div className={styles.goalChips}>
                      {client.investmentGoals.map((goal, idx) => (
                        <Chip 
                          key={idx}
                          label={goal}
                          size="small"
                          className={styles.goalChip}
                          variant="outlined"
                        />
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="150">Client Name</TableCell>
                <TableCell width="150">Financial Wellness</TableCell>
                <TableCell width="150">Goal Success Rate</TableCell>
                <TableCell width="200">Goals</TableCell>
                <TableCell width="180">To-dos</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients.map((client) => (
                <TableRow key={client.id} className={styles.tableRow}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Avatar 
                        sx={{ 
                          width: 40,
                          height: 40,
                          fontSize: '1rem',
                          fontWeight: 500
                        }}
                      >
                        {getInitials(client.name)}
                      </Avatar>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {client.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <div className={styles.wellnessScore}>
                      <CircleScore 
                        width='40px'
                        height='40px'
                        color={"blue"}
                        title=''
                        fontSizeScore="1.4rem"
                        score={client.financialWellnessScore} 
                        totalScore={100}/>
                      
                      {/* <span>{client.financialWellnessScore}%</span> */}
                    </div>
                  </TableCell>
                
                  <TableCell>
                    <div className={styles.successRate}>
                    <CircleScore 
        width='40px'
        height='40px'
         fontSizeScore="1.4rem"
        title=''
        score={client.goalSuccessRate} 
        totalScore={100}/>
                      {/* <span>{client.goalSuccessRate}%</span> */}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={styles.goalChips}>
                      {client.investmentGoals.map((goal, idx) => (
                        <Chip 
                          key={idx}
                          label={goal}
                          size="small"
                          className={styles.goalChip}
                          variant="outlined"
                        />
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={styles.todoChips}>
                      {client.todos?.map((todo, idx) => (
                        <Chip 
                          key={idx}
                          label={todo}
                          size="small"
                          className={styles.todoChip}
                          variant="outlined"
                        />
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </div>
  );
} 