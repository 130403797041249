import { useAppInfo } from 'AppState'
import FinancialVaultTableUpload from 'financialVault/Upload/FinancialVaultTable'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useHandleVaultUser from 'financialVault/useHandleVaultUser'
import FinancialVaultUpload from 'financialVault/Upload/FinancialVaultUpload'

export default function FinancialVaultUploadTab () {
    const {stateMyFinancials} = useAppInfo()
    const {vaultData} = stateMyFinancials

    const {handleChange, handleDelete} = useHandleVaultUser()
    const [uploadItems,setUploadedItems] = useState(vaultData)
    const location = useLocation()
    const subPage = location.pathname.split('/')[2];
    const subPageName = decodeURIComponent(subPage)

    
    useEffect(()=>{
        if (subPage){
          setUploadedItems(vaultData.filter(item => item.category === subPageName))
        }
        else{
          setUploadedItems(vaultData)}
      },[vaultData,subPage])

    return ( <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
        <div style={{height:"100px",width:"100%"}}>
        <FinancialVaultUpload/>
        
        </div>
        {uploadItems.length > 0 &&
        <FinancialVaultTableUpload mediaItems={uploadItems} 
        handleChange={handleChange} 
          handleDelete={handleDelete}
          />}
        </div>)
}
