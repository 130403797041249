import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import DetailTradeTickerButton from 'analytics/ticker/DetailTradeTickerButton';
import useHandleTickerChange
  from 'analytics/ticker/hooks/useHandleTickerChange';
import { useAppInfo } from 'AppState';
import LogoTicker from 'components/LogoTicker';
import TabGeneral from 'components/Tabs/TabGeneral';
import {
  assetCode,
  dfin,
} from 'content/constants';
import useSearchTicker from 'data/ticker/useSearchTicker';
import { PeerAddTableButton } from 'peerList/PeerAddButton';
import { WatchListAddTableButton } from 'watchlist/WatchListAddButton';

import LinearProgress from '@mui/material/LinearProgress';

import styles from './SearchMain.module.css';

export default function SearchTicker({ searchTerm, addType = "watchlist" }) {
  const { dispatchTicker, stateTicker, dispatchSearch, stateSearch } = useAppInfo();
  const handleTickerChange = useHandleTickerChange();
  const tickerInfo = stateTicker.tickerInfo;
  const { searchTickerHistory } = stateSearch;

  const [tabIndex, setTabIndex] = useState(0);
  const [focusedRowIndex, setFocusedRowIndex] = useState(-1); // Focused row for keyboard navigation
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1); // Hovered row for mouse hover

  const tableContainerRef = useRef(null); // Ref for the table container
  const rowRefs = useRef([]); // Ref for each row to manage scroll

  const colAdd = addType === "watchlist" ? "Add to Watchlist" : "Add to Peers";
  const tableColumns = ["Ticker", "Name", "Exchange", colAdd];
  const tableRows = ["TICKER", "NAME", "EXCHANGE"];
  const tickerType = Object.values(assetCode);
  const tabList = ["All", ...tickerType];
  const tabContent = []; // You can add tab content here as per your requirement.

  const [loading, setLoading] = useState(false);
  const searchInfo = useSearchTicker(searchTerm);

  const filteredList = useMemo(() => {
    const tickerT = tabList[tabIndex];
    let filteredResults = ["All", assetCode?.Stocks].includes(tickerT)
      ? tickerInfo.filter((obj) => obj["COUNTRY"] === "USA")
      : tickerInfo;
    const searchTickerSet = new Set(searchTickerHistory.map((item) => item[dfin.ticker]));

    let topResults;
    if (searchTerm !== "" && searchTerm && searchInfo.searchedTicker) {
      filteredResults = [...searchInfo.searchedTicker];
      topResults = [
        ...filteredResults
          .filter((item) => !searchTickerSet.has(item[dfin.ticker]))
          .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap]),
        ...searchTickerHistory,
      ];
    } else {
      topResults = [
        ...searchTickerHistory,
        ...filteredResults
          .filter((item) => !searchTickerSet.has(item[dfin.ticker]))
          .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap]),
      ];
    }

    if (tickerT === "All") {
      return topResults.slice(0, 200);
    } else {
      const filteredByTab = topResults.filter((item) => item["TYPE"] === tickerT);
      return filteredByTab.slice(0, 200);
    }
  }, [searchTickerHistory, searchTerm, searchInfo.searchedTicker, tabIndex, tickerInfo]);

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  // Handle keyboard events
  const handleKeyDown = (e) => {
    if (filteredList.length > 0) {
      if (e.key === "ArrowDown") {
        setFocusedRowIndex((prevIndex) =>
          (prevIndex + 1) % filteredList.length
        );
      } else if (e.key === "ArrowUp") {
        setFocusedRowIndex((prevIndex) =>
          (prevIndex - 1 + filteredList.length) % filteredList.length
        );
      } else if (e.key === "Enter" && focusedRowIndex >= 0) {
        // Handle ticker change on Enter key press
        handleTickerChange({ticker:filteredList[focusedRowIndex][dfin.tickerCode]});
      }
    }
  };

  // Scroll to the focused row when it changes
  useEffect(() => {
   setFocusedRowIndex(-1)
  }, []);

  useEffect(() => {
    if (focusedRowIndex >= 0 && rowRefs.current[focusedRowIndex]) {
      rowRefs.current[focusedRowIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [focusedRowIndex]);

  useEffect(() => {
    // Add keyboard event listener
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, focusedRowIndex]);

  return (
    <>
      <div style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
        <TabGeneral
          tabList={tabList}
          tabContent={tabContent}
          tabIndex={tabIndex}
          handleTabChange={handleTabChange}
        />
      </div>
      {filteredList.length > 0 && (
        <div
          style={{
            height: "90%",
            maxHeight: "90%",
            overflow: "auto",
            marginTop: "1rem",
            width: "100%",
          }}
          ref={tableContainerRef} // Ref for the table container
        >
          <table className={styles.table}>
            <thead>
              <tr>
                {tableColumns.map((colHeader) => (
                  <th key={colHeader}>{colHeader}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <LinearProgress />
              ) : (
                filteredList.map((obj, index) => (
                  <tr
                    key={index}
                    ref={(el) => (rowRefs.current[index] = el)} // Assign row ref
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      backgroundColor:
                        focusedRowIndex === index || hoveredRowIndex === index
                          ? "#f0f0f0"
                          : "transparent", // Highlight focused or hovered row
                    }}
                    onClick={(e) => handleTickerChange({ticker:obj[dfin.tickerCode]})}
                    onMouseEnter={() => setHoveredRowIndex(index)} // Set hovered row
                    onMouseLeave={() => setHoveredRowIndex(-1)} // Reset hovered row on leave
                  >
                    {tableRows.map((row, rowIndex) => (
                      <td key={rowIndex}>
                        {row !== dfin.ticker ? (
                          obj[row]
                        ) : (
                          <div className={styles.logoTick}>
                            <LogoTicker tickerSel={obj[dfin.ticker]} />
                            {obj[row]}
                          </div>
                        )}
                      </td>
                    ))}
                    <td>
                      {addType === "watchlist" ? (
                        <WatchListAddTableButton ticker={obj[dfin.ticker]} />
                      ) : (
                        <PeerAddTableButton ticker={obj[dfin.ticker]} />
                      )}

                      {/* Show DetailTradeTickerButton on hover */}
                      {(hoveredRowIndex === index || focusedRowIndex === index) && (
                        <DetailTradeTickerButton ticker={obj[dfin.ticker]} />
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}


// import React, { useEffect, useMemo, useState } from "react";
// import { useAppInfo } from "AppState";
// import TabGeneral from "components/Tabs/TabGeneral";
// import { assetCode, dfin } from "content/constants";
// import LinearProgress from "@mui/material/LinearProgress";
// import styles from "./SearchMain.module.css";
// import useSearchTicker from "data/ticker/useSearchTicker";
// import useHandleTickerChange from "analytics/ticker/hooks/useHandleTickerChange";
// import { WatchListAddTableButton } from "watchlist/WatchListAddButton";
// import { PeerAddTableButton } from "peerList/PeerAddButton";
// import DetailTradeTickerButton from "analytics/ticker/DetailTradeTickerButton";
// import LogoTicker from "components/LogoTicker";

// export default function SearchTicker({ searchTerm, addType = "watchlist"}) {
//   const {dispatchTicker,stateTicker,dispatchSearch,stateSearch} = useAppInfo();
//   const handleTickerChange = useHandleTickerChange()
//   const tickerInfo = stateTicker.tickerInfo;
//   const {searchTickerHistory } = stateSearch;

//   const [tabIndex, setTabIndex] = useState(0);
//   // const [filteredList, setFilteredList] = useState([]);

//   const colAdd = addType === "watchlist" ? "Add to Watchlist" : "Add to Peers";
//   const tableColumns = ["Ticker", "Name", "Exchange", colAdd];
//   const tableRows = ["TICKER", "NAME", "EXCHANGE"];
//   const tickerType = Object.values(assetCode)
//   // tickerInfo && new Set(stateTicker.tickerInfo.map(obj => obj["TYPE"]))
//   const tabList = ["All", ...tickerType];
//   const tabContent = []; // You can add tab content here as per your requirement.


//   function handleTabChange(index) {
//     setTabIndex(index);
//   }


//   const [loading, setLoading] = useState(false);
//   const searchInfo = useSearchTicker(searchTerm);

  
//   const filteredList = useMemo(() => {
//     const tickerT = tabList[tabIndex];
    
//     // Filter and sort the full list
//     let filteredResults = ["All", assetCode?.Stocks].includes(tickerT) ? tickerInfo.filter((obj) => obj["COUNTRY"] === "USA"): tickerInfo;
//     const searchTickerSet = new Set(searchTickerHistory.map(item => item[dfin.ticker]));
    
//     let topResults;

//     if (searchTerm !== "" && searchTerm && searchInfo.searchedTicker) {
//       filteredResults = [...searchInfo.searchedTicker]
//       topResults = [...filteredResults.filter(item => !searchTickerSet.has(item[dfin.ticker])).sort(
//         (a, b) => b[dfin.marketCap] - a[dfin.marketCap]),...searchTickerHistory,
//         ];
//     }
//     else {
//       topResults = [...searchTickerHistory,...filteredResults.filter(item => !searchTickerSet.has(item[dfin.ticker])).sort(
//         (a, b) => b[dfin.marketCap] - a[dfin.marketCap]),
//         ];
//     }
  
//     // Apply tab filtering

//     if (tickerT === "All") {
//       return topResults.slice(0, 200);
//     } else {
//       const filteredByTab = topResults.filter(
//         (item) => item["TYPE"] === tickerT
//       );
//       return filteredByTab.slice(0, 200);
//     }
//   }, [searchTickerHistory,searchTerm, searchInfo.searchedTicker, tabIndex, tickerInfo]);

  
//   const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);

//   function handleRowMouseEnter(index) {
//     setHoveredRowIndex(index);
//   }
 
//   return (
//     <>
//       <>
//         <div style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
//           <TabGeneral
//             tabList={tabList}
//             tabContent={tabContent}
//             tabIndex={tabIndex}
//             handleTabChange={handleTabChange}
//           />
//         </div>
//         {filteredList.length > 0 && (
//           <div
//             style={{
//               height: "90%",
//               maxHeight: "90%",
//               overflow: "auto",
//               marginTop: "1rem",
//               width: "100%",
//             }}
//           >
//             <table className={styles.table}>
//               <thead>
//                 <tr>
//                   {tableColumns.map((colHeader) => (
//                     <th key={colHeader}>{colHeader}</th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {loading ? (
//                   <LinearProgress />
//                 ) : (
//                   filteredList.map((obj, index) => (
//                     <tr
//                       style={{ position: "relative", cursor: "pointer" }}
//                       onMouseEnter={() => handleRowMouseEnter(index)}
//                       onMouseLeave={() => handleRowMouseEnter(-1)}
//                       key={index}
//                       onClick={(e) =>
//                         handleTickerChange(e, obj[dfin.tickerCode])
//                       }
//                     >
//                       {tableRows.map((row, rowIndex) => (
//                         <td key={rowIndex}>
//                           {row !== dfin.ticker ? (
//                             obj[row]
//                           ) : (
//                             <div className={styles.logoTick}>
//                               <LogoTicker tickerSel={obj[dfin.ticker]}/>
//                               {obj[row]}
//                             </div>
//                           )}
//                         </td>
//                       ))}
//                       <td>
//                       {addType === "watchlist" ? 
//                       <WatchListAddTableButton 
//                       ticker={obj[dfin.ticker]}/>:
//                       <PeerAddTableButton ticker={obj[dfin.ticker]}/>}
                     
//                         {hoveredRowIndex === index && (
//                           <DetailTradeTickerButton ticker={obj[dfin.ticker]}/>
//                         )}
//                       </td>
//                     </tr>
//                   ))
//                 )}
//               </tbody>
//             </table>
//           </div>
//         )}
//       </>
//     </>
//   );
// }

function levenshteinDistance(a, b) {
  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      const cost = a[j - 1] === b[i - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // Deletion
        matrix[i][j - 1] + 1, // Insertion
        matrix[i - 1][j - 1] + cost // Substitution
      );
    }
  }

  return matrix[b.length][a.length];
}
