import React from 'react';

import { useAppInfo } from 'AppState';
import {
  navLinkAdvisor,
  navLinkCategories,
} from 'nav/menu';
import { useLocation } from 'react-router-dom';

import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';

import styles from './PageHeader.module.css';

export default function PageHeader({ 
  title, 
  subtitle, 
  searchPlaceholder = 'Search...', 
  searchValue, 
  onSearchChange,
  onFilterClick,
  activeFiltersCount = 0
}) 

{
const {stateApp} = useAppInfo()
const {userRole} = stateApp?.onboarding
  const location = useLocation();
const pageName = location.pathname.split('/')[1];
const subPage = location.pathname.split('/')[2];
const baseNavLinks = userRole === 'advisor' ? navLinkAdvisor : navLinkCategories
const currentPage =  baseNavLinks.find((page) => page.link === pageName);


  return (
    <div className={styles.header}>
      <div className={styles.titleSection}>
      <Button
      startIcon={currentPage?.icon}
      style={{display:"flex",alignItems:"center",padding:"0 0.5rem"}}>
    <h3 style={{color:"var(--selectBlue)"}}>
      {currentPage?.name}</h3>
    {/* {currentPage?.menu && 
    <ExpandMoreIcon/>} */}
      
      </Button>
        {/* <h3 className={styles.title}>{title}</h3> */}
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      <div className={styles.searchSection}>
        <div className={styles.searchBar}>
          <SearchIcon className={styles.searchIcon} />
          <input
            type="text"
            placeholder={searchPlaceholder}
            className={styles.searchInput}
            value={searchValue}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>
        <button 
          className={`${styles.filterButton} ${activeFiltersCount > 0 ? styles.hasFilters : ''}`}
          onClick={onFilterClick}
        >
          <FilterListIcon /> Filter
          {activeFiltersCount > 0 && (
            <span className={styles.filterBadge}>{activeFiltersCount}</span>
          )}
        </button>
      </div>
    </div>
  );
} 