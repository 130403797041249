import React, { useMemo } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { LinearProgress } from '@mui/material';
import { RecessionData } from 'content/contentEconomy';

require("highcharts/modules/accessibility")(Highcharts);

const TimeSeriesChart = ({
  seriesName,
  data,
  chartType = "area",
  title = "",
  zoneRGseries = false,
  rgValueMin = 0,
  recession = false,
  navigator = false,
  datePicker = true,
  color,
  animation = false,
  disableButtons = false,
  /** NEW PROPS **/
  grid = true,          // Show or hide grid lines; default: true
  dynamicColor = false, // If true => color is green if last > first, else red; default: false
  gradient = false,     // If true => area fill is a gradient from top color to white; default: false
}) => {
  

  const firstValue = data[0][seriesName];
  const lastValue = data[data.length - 1][seriesName];

  // Decide final color: either the one passed in or dynamic (green/red)
  const finalColor = useMemo(() => {
    if (!dynamicColor) return color || 'green'; // fallback if color not provided
    return (lastValue > firstValue) ? 'green' : 'red';
  }, [color, dynamicColor, firstValue, lastValue]);

  // Build the series array
  const seriesData = useMemo(
    () => [{
      name: seriesName,
      data: data.map((entry) => [
        new Date(entry.Date || entry.date).getTime(),
        entry[seriesName],
      ]),
      color: finalColor,
      animation: animation,
      // If gradient is true and chartType is 'area', apply a gradient fill
      fillColor: (gradient && chartType === 'area')
        ? {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 }, // top to bottom
            stops: [
              [0, finalColor], // top color
              [1, '#ffffff'],  // bottom white
            ],
          }
        : undefined,
    }],
    [data, seriesName, finalColor, animation, gradient, chartType]
  );

  // Highcharts options
  const options = useMemo(
    () => ({
      chart: {
        zoomType: 'x',
        type: chartType,
        animation: false,
      },
      title: {
        text: title,
      },
      xAxis: {
        type: 'datetime',
        endOnTick: true,
        lineColor: '#ddd',
        lineWidth: 2,  
        showLastLabel: true,
        minRange: 1,
        tickColor: '#ddd', // Green tick color
        tickWidth: 1,         // Width of tick marks
        tickLength: 10,   
        labels: {
          style: {
            color: 'gray', // Sets the label color to green
            fontSize: '12px', // Optional: Adjust font size
            // fontWeight: 'bold', // Optional: Set font weight
          },},
        // Hide/show the grid lines
        // gridLineWidth: grid ? 1 : 0,
        plotBands:
          recession &&
          RecessionData.map((recessionItem, index) => ({
            from: new Date(recessionItem.from).getTime(),
            to: new Date(recessionItem.to || recessionItem.from).getTime(),
            color: index % 2 === 0 ? 'rgba(255, 0, 0, 0.1)' : 'rgba(255, 0, 0, 0.05)',
            label: {
              text: recessionItem.label,
              rotation: 90,
              verticalAlign: "bottom",
              y: -50
            },
          })),
      },
      yAxis: {
        title: {
          text: 'Values',
        },
        // Hide/show the grid lines
        gridLineWidth: grid ? 1 : 0,
        plotLines: zoneRGseries
          ? [
              {
                value: rgValueMin,
                color: 'black',
                dashStyle: 'dot',
                width: 2,
                zIndex: 5,
                label: {
                  text: 'Investment Cost',
                  style: {
                    fontSize: '1rem',
                    align: 'right',
                    x: -20,
                    backgroundColor: '#ddd'
                  }
                }
              },
            ]
          : [],
      },
      series: seriesData,
      plotOptions: {
        series: {
          lineWidth: 1,    
          marker: { enabled: false },
          zones:
            zoneRGseries && [
              { value: 0, color: 'red' },
              { color: 'green' },
            ],
        },
      },
      rangeSelector: {
        allButtonsEnabled: true,
        inputEnabled: datePicker,
        selected: 5,
        animation: false,
        buttons: [
          { type: 'month', count: 1, text: '1m' },
          { type: 'month', count: 3, text: '3m' },
          { type: 'month', count: 6, text: '6m' },
          { type: 'ytd', text: 'YTD' },
          { type: 'year', count: 1, text: '1y' },
          { type: 'year', count: 5, text: '5y' },
          { type: 'year', count: 10, text: '10y' },
          { type: 'all', text: 'All' },
        ],
        buttonTheme: {
          style: {
            display: disableButtons ? 'none' : 'inline-block',
          },
        },
      },
      credits: { enabled: false },
      navigator: { enabled: navigator },
      scrollbar: { enabled: false },
      stockTools: {
        gui: {
          enabled: false
        }
      },
      accessibility: { enabled: false },
    }),
    [
      chartType,
      title,
      grid,
      recession,
      zoneRGseries,
      rgValueMin,
      datePicker,
      navigator,
      seriesData,
      disableButtons,
    ]
  );

    // Safeguard if data is empty or missing
    if (!data || data.length === 0) {
      return <div>No data available</div>;
    }
  

  return (
    <div style={{ flex: 1, height: '100%', width: "100%" }}>
      {(!options) && <LinearProgress />}
      {options && (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType="stockChart"
          containerProps={{ style: { height: '100%', width: "100%" } }}
        />
      )}
    </div>
  );
};

export default TimeSeriesChart;



// Highcharts.setOptions({
//   plotOptions: {
//     series: {
//       animation: false,
//     },
//   },
//   lang: { rangeSelectorZoom :"" } 
// });
