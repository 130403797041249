import React from 'react'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@mui/material';

export default function HomeInsightNotificationBox({showArrow=true,children,navigateUrl,hover=true,onClick}) {
    const navigate = useNavigate()
    const [mouseEnter,setMouseEnter] = React.useState(false)

    function handleClick(e){
      e.stopPropagation()
      onClick && onClick()
      if (navigateUrl) {
        navigate(navigateUrl)
      }
    }
  return (
    <div
    onPointerEnter={()=>setMouseEnter(true)}
    onPointerLeave={()=>setMouseEnter(false)}
    className={hover && 'card-hover'}
    onClick={(e)=>handleClick(e)}
    style={{display:"flex",gap:"1rem"
      ,alignItems:"center",padding:"0.5rem",borderRadius:"5px",width:"100%"
      ,boxSizing:"border-box",position:"relative",cursor:"pointer"}}>
        <div style={{flex:1,display:"flex",gap:"1rem",alignItems:"center"}}>
        {children}
        </div>
      
        <Button
        style={{
        padding:"0.5rem",
        borderRadius:0,
        minWidth:0,
        fontWeight:"bold",opacity:showArrow && mouseEnter ? 1 : 0}}  
       >
         <KeyboardArrowRightIcon sx={{color:"gray"}} fontSize='small'/>
        </Button>
    </div>
  )
}
