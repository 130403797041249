import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import InfoBox from 'components/Layout/InfoBox';
import TabLine from 'components/Tabs/TabLine';
import {
  Link,
  useParams,
} from 'react-router-dom';

import {
  Email,
  Facebook,
  Instagram,
  Language,
  LinkedIn,
  LocationOn,
  Phone,
  Star,
  VerifiedUser,
  WhatsApp,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import {
  financeProfessionalFormDetails,
} from './FinanceProfessionalFormFields';
import { professionalDetailsArray } from './info';
import MediaCarousel from './MediaCarousel';
import Reviews from './Reviews/Reviews';

const StickyProfileSummary = ({ info, tabIndex, handleTabChange }) => (
  <Box 
    sx={{ 
      position: 'sticky', 
      top: 0, 
      zIndex: 1, 
      bgcolor: 'white', 
      py: 1.5,
      backdropFilter: 'blur(8px)',
      backgroundColor: 'rgba(255, 255, 255, 0.9)'
    }}
  >
    <Box sx={{ mx: 'auto', px: 2 }}>
      <div style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}>
        <Avatar 
          src={info.profileImage} 
          alt={info.name} 
          sx={{ 
            width: '30px', 
            height: '30px',
            '& img': {
              objectFit: 'cover',
            }
          }} 
        />
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.25 }}>{info.name}</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 0.25 }}>
            {info.role} | {info.organization}
          </Typography>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <VerifiedUser sx={{ color: 'success.main', fontSize: '1rem' }} />
              <Typography variant="caption" color="success.main">Verified</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <Star sx={{ color: '#f1c40f', fontSize: '1rem' }} />
              <Typography variant="caption" color="text.secondary">
                {`${info?.reviewsStats?.averageRating} (${info?.reviewsStats?.totalReviews})`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  </Box>
);

const FinanceProfessionalBanner = ({details=financeProfessionalFormDetails}) => {
  const {idProfessional} = useParams()
  const [tabIndex, setTabIndex] = useState(0);
  const [showStickyProfile, setShowStickyProfile] = useState(false);
  const [info,setInfo] = useState(financeProfessionalFormDetails)

  useEffect(() => {
    if (idProfessional) {
      const infoProfessional = professionalDetailsArray.find((item) => Number(item.userId) === Number(idProfessional));
      if (infoProfessional){
        setInfo(infoProfessional)}
    }
  },[idProfessional])

  const scrollToSection = (index) => {
    setTabIndex(index);
    const sectionIds = ["about", "coreExpertise", "services", "certifications", "qualifications","reviews"];
    const sectionId = sectionIds[index] || "reviews";
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  };

  const profileSection = useRef(null);
  const [isProfileVisible, setProfileVisible] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setProfileVisible(entry.isIntersecting);
      },
      { rootMargin: '-50px 0px 0px 0px' }
    );

    if (profileSection.current) {
      observer.observe(profileSection.current);
    }

    return () => {
      if (profileSection.current) {
        observer.unobserve(profileSection.current);
      }
    };
  }, []);

  return (
    <Box sx={{ 
      maxWidth: '1000px', 
      mx: 'auto', 
      px: 2,
      py: 3
    }}>
      {!isProfileVisible && (
        <StickyProfileSummary info={info} tabIndex={tabIndex} handleTabChange={scrollToSection} />
      )}

      <Box ref={profileSection} id="profileSection" sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '2rem' }}>
          <Avatar
            src={info.profileImage}
            alt={info.name}
            sx={{
              width: 80,
              height: 80,
              flexShrink: 0,
              '& img': {
                objectFit: 'cover',
                width: '100%',
                height: '100%'
              }
            }}
          />
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 500, 
                mb: 0.5,
                letterSpacing: '-0.5px'
              }}
            >
              {info.name}
            </Typography>
            <Typography 
              variant="subtitle1" 
              color="text.secondary" 
              sx={{ 
                mb: 1,
                fontWeight: 400
              }}
            >
              {info.role} | {info.organization}
            </Typography>
            <Box sx={{ display: "flex", gap: "1.5rem" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <VerifiedUser sx={{ color: 'success.main', fontSize: '1.1rem' }} />
                <Typography variant="body2" color="success.main">Verified</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Star sx={{ fontSize: '1.1rem', color: '#f1c40f' }} />
                <Typography variant="body2" color="text.secondary">
                  {`${info?.reviewsStats?.averageRating} (${info?.reviewsStats?.totalReviews})`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ 
        position: 'sticky', 
        top: { xs: 0, md: 60 }, 
        zIndex: 1, 
        bgcolor: 'background.paper',
        py: 1,
        mb: 3
      }}>
        <TabLine
          sx={{ 
            overflow: "auto",
            '& .MuiTabs-indicator': {
              height: 2,
              borderRadius: 2
            }
          }}
          tabIndex={tabIndex}
          handleTabChange={scrollToSection}
          tabList={["About", "Core Expertise", "Services", "Certifications", "Qualifications", "FAQ's", "Reviews"]}
        />
      </Box>

      <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
        <Box id="about" sx={{ mb: 4 }}>
          <Typography 
            variant="body1" 
            color="text.secondary" 
            sx={{ 
              lineHeight: 1.7,
            }}
          >
            {info.descriptionLong}
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <InfoBox sx={{ height: "400px", borderRadius: 1, overflow: 'hidden' }}>
            <MediaCarousel mediaFiles={info?.marketingMedia}/>
          </InfoBox>
        </Box>

        <Box id="coreExpertise" sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>Core Expertise</Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
            {info.coreExpertise.map((expertise, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{ 
                  bgcolor: 'grey.100',
                  color: 'text.primary',
                  px: 2,
                  py: 0.75,
                  borderRadius: 1,
                  transition: 'all 0.2s',
                  '&:hover': {
                    bgcolor: 'grey.200'
                  }
                }}
              >
                {expertise}
              </Typography>
            ))}
          </Stack>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box id="services" sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>Services Offered</Typography>
          <List sx={{ '& .MuiListItem-root': { px: 0 } }}>
            {info.services.map((service, index) => (
              <ListItem key={index}>
                <ListItemText 
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
                      {service.title}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      {service.details}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box id="certifications" sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>Certifications</Typography>
          <List sx={{ '& .MuiListItem-root': { px: 0 } }}>
            {info.certifications.map((cert, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {cert.issuingAuthority}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      Issued: {cert.issuedDate} | Expires: {cert.expiryDate}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box id="qualifications" sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>Qualifications</Typography>
          <List sx={{ '& .MuiListItem-root': { px: 0 } }}>
            {info.qualifications.map((qualification, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {qualification.degree}, {qualification.school}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      Graduated on {qualification.issuedDate}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box id="reviews">   
          <Reviews />
        </Box>
      </Box>
    </Box>
  );
};

export default FinanceProfessionalBanner;

function ContactInfo({ info }) {
  return (
    <Box sx={{ 
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 1,
      p: 2
    }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>Contact Information</Typography>
      <List sx={{ '& .MuiListItem-root': { px: 0 } }}>
        <ListItem>
          <ListItemText 
            primary={info.Address} 
            secondary={<LocationOn fontSize="small" sx={{ mr: 1 }} />} 
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={info.Email} 
            secondary={<Email fontSize="small" sx={{ mr: 1 }} />} 
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={info.PhoneNumber} 
            secondary={<Phone fontSize="small" sx={{ mr: 1 }} />} 
          />
        </ListItem>
        <ListItem>
          <Link href={info.Website} target="_blank" style={{ textDecoration: 'none' }}>
            <Typography color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
              <Language fontSize="small" sx={{ mr: 1 }} />
              Visit Website
            </Typography>
          </Link>
        </ListItem>
      </List>
      <Box sx={{ mt: 2 }}>
        <IconButton size="small" component="a" href={info.SocialMedia.linkedin} target="_blank"><LinkedIn /></IconButton>
        <IconButton size="small" component="a" href={info.SocialMedia.facebook} target="_blank"><Facebook /></IconButton>
        <IconButton size="small" component="a" href={info.SocialMedia.instagram} target="_blank"><Instagram /></IconButton>
        <IconButton size="small" component="a" href={info.SocialMedia.whatsapp} target="_blank"><WhatsApp /></IconButton>
      </Box>
    </Box>
  );
}

    