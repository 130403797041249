import React from 'react';

import { useAppInfo } from 'AppState';
import ChatContent from 'chatGPT/ChatContent';
import Modal from 'components/Modal/Modal';

import ExploreAssitants from './ExploreAssistants';
import MessageBarT from './MessageBarT';

export default function TSAssistantMainLayout() {

    const {stateApp,stateAssistant,dispatchAssistant} = useAppInfo()
    const {chats,chatHistory,chatWindow,exploreQuestions,exploreAssistants,assistantSelected} = stateAssistant
  
  return (
    <div style={{height:"100%",width:"100%",boxSizing:"border-box",display:"flex"}}> 

        {/* <ChatAIMenuLeftSidebar/> */}

        <div style={{flex:1,padding:"0",overflow:"auto",display:"flex",flexDirection:"column"}}>
        <div style={{flex:1,padding:"1rem"}}>
        {/* {(!chatWindow && exploreQuestions) && <ExploreQuestions/>}
         */}
        {chatWindow && <ChatContent/>}
        </div>
        <div style={{
         position:"sticky",display:"flex"
         ,alignItems:"center",bottom:0,width:"100%"
         ,justifyContent:"center",padding:"0.5rem 0 2rem 0",background:"transparent",zIndex:100000,boxSizing:"border-box"}}>
           <div style={{
             position:"absolute",
             bottom:"0px",
             right:"0px",
             backdropFilter:"blur(10px)",
             height:"80px",
             width:"100%",
             background: "linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,1) 100%)",
             pointerEvents: "none"
           }}/>

         <MessageBarT/>
         
       </div>

       </div>

       {(exploreAssistants) && 
       <Modal width="80vw" height="80vh" 
       sx={{borderRadius:"5px"}} onClose={()=>dispatchAssistant({exploreAssistants:false})}> 
       <div style={{height:"100%",width:"100%",overflow:"auto"}}>

       <ExploreAssitants/>
       </div>
       </Modal>
       }

        </div>
  )
}
