import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { useAppInfo } from "AppState"; // your imaginary custom hook
import SelectBox from "components/Inputs/SelectBox";
import Modal from "components/Modal/Modal";
import { Button, IconButton } from "@mui/material";
import { Cancel, Delete, Edit } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AISummaryBox from "chatGPT/AISummaryBox";
import TextInput from "components/Inputs/TextInput";
import NumberInput from "components/Inputs/NumberInput";
import { formatNumber, generateUniqueId } from "utils/utilGeneral";
import { fetchAISummarize } from "chatGPT/useAssistantSummarize";
import ReactMarkdown from 'react-markdown';
import CollapseToggler from "components/Buttons/CollapseToggler";
// ========== DEFAULT BRACKETS ==========
// Changed to array-of-objects with (category, amount, inflation).
const DEFAULT_BRACKETS = [
  {
    bracketId: "b1",
    rangeStart: 0,
    rangeEnd: 6,
    categories: [
      { category: "food", amount: 1000, inflation:2  },
      { category: "childcare", amount: 3000, inflation:2  },
      { category: "clothing", amount: 500, inflation:2  },
      { category: "toys", amount: 200, inflation:2  },
    ],
  },
  {
    bracketId: "b2",
    rangeStart: 6,
    rangeEnd: 12,
    categories: [
      { category: "food", amount: 1500, inflation:2  },
      { category: "clothing", amount: 600, inflation:2  },
      { category: "childcare", amount: 1500, inflation:2  },
      { category: "education", amount: 4000, inflation:2  },
      { category: "toys", amount: 700, inflation:2  },
    ],
  },
  {
    bracketId: "b3",
    rangeStart: 12,
    rangeEnd: 20,
    categories: [
      { category: "food", amount: 2000, inflation:2  },
      { category: "clothing", amount: 700, inflation:2  },
      { category: "education", amount: 7000, inflation:2  },
    ],
  },
];

/**
 * Utility that sums bracket categories for a given age if age
 * is within [rangeStart, rangeEnd). Now also applies each
 * category's inflation based on the difference from the current year.
 */
function getChildExpensesForAge(child, age) {
  if (!child.ageBrackets || !Array.isArray(child.ageBrackets)) {
    return {};
  }

  let totalCategories = {};
  const year = child.birthYear + age; // Which calendar year?

  child.ageBrackets.forEach((bracket) => {
    const { rangeStart, rangeEnd, categories } = bracket;
    if (age >= rangeStart && age < rangeEnd) {
      // Merge categories
      categories.forEach(({ category, amount, inflation }) => {
        // Each category can have its own inflation
        const yearDiff = year - new Date().getFullYear();
        const infRate = inflation/100 || 0; // fallback if no inflation set
        const inflationFactor = Math.pow(1 + infRate, yearDiff);

        const inflatedAmt = amount * inflationFactor;
        totalCategories[category] =
          (totalCategories[category] || 0) + inflatedAmt;
      });
    }
  });

  return totalCategories;
}

/**
 * MAIN COMPONENT
 */
export default function ScenarioChildExpense() {
  const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
  const { cashflowDistribution, financialScenarios, financialsLoaded } = stateMyFinancials;
  const {
    netCashflowDist,
    netCashflowBeforeAssetReturnDist,
    netCashflowAssetWidthdrawalDist,
  } = cashflowDistribution;

  // -----------------------------------------
  // FORM + STATE
  // -----------------------------------------
  const [formData, setFormData] = useState({
    funding: "Net Cashflow Before Asset Return", // or "Net Cashflow After Asset Return", etc
  });

  // A toggle for stacked vs grouped
  const [stacked, setStacked] = useState(true);

  // Start with one dummy child
  const [childrenData, setChildrenData] = useState([]);

  useEffect(() => {
    if (!financialsLoaded) {
      return;
    }
    if (financialScenarios?.childExpense) {
      setChildrenData(financialScenarios.childExpense);
    } else {
      setChildrenData([
        {
          id: generateUniqueId(),
          name: "Future Child",
          birthYear: new Date().getFullYear() + 1,
          ageBrackets: JSON.parse(JSON.stringify(DEFAULT_BRACKETS)), // clone
        },
      ]);
    }
  }, [financialScenarios, financialsLoaded]);

  // For the Add/Edit modal
  const [modalOpen, setModalOpen] = useState(false);
  const [editingChild, setEditingChild] = useState(null);

  // Chart & table data
  const [chartSeries, setChartSeries] = useState([]);
  const [probabilitySeries, setProbabilitySeries] = useState([]);
  const [tableRows, setTableRows] = useState([]);

 
  // For "expanded" rows in the table
  const [expandedYears, setExpandedYears] = useState({});

  const toggleYearExpand = (year) => {
    setExpandedYears((prev) => ({
      ...prev,
      [year]: !prev[year],
    }));
  };

  // -----------------------------------------
  // CRUD HANDLERS
  // -----------------------------------------
  const handleAddChildClick = () => {
    setEditingChild(null);
    setModalOpen(true);
  };

  const handleEditChild = (child) => {
    setEditingChild(child);
    setModalOpen(true);
  };

  const handleRemoveChild = (childId) => {
    setChildrenData((prev) => prev.filter((c) => c.id !== childId));
  };

  const handleModalSave = (child) => {
    if (child.id) {
      // editing existing
      setChildrenData((prev) => {
        let childUpdate = prev.map((c) => (c.id === child.id ? child : c));
        dispatchMyFinancials({
          financialScenarios: { ...financialScenarios, childExpense: childUpdate },
        });
        return childUpdate;
      });
    } else {
      // adding new
      const newId = generateUniqueId();
      setChildrenData((prev) => {
        let childUpdate = [...prev, { ...child, id: newId }];
        dispatchMyFinancials({
          financialScenarios: { ...financialScenarios, childExpense: childUpdate },
        });
        return childUpdate;
      });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setEditingChild(null);
  };

  // -----------------------------------------
  // EXPENSE + CHART CALC
  // -----------------------------------------
  
  useEffect(() => {
    recalcExpenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenData, formData]);

  const recalcExpenses = () => {
    // 1. Decide which funding distribution we use
    let funding;
    let fundingTillAge = 20 
    fundingTillAge = getMaxAgeFromChildren(childrenData)
    switch (formData.funding) {
      case "Net Cashflow Before Asset Return":
        funding = netCashflowBeforeAssetReturnDist;
        break;
      case "Net Cashflow After Asset Return":
        funding = netCashflowDist;
        break;
      case "Net Cashflow Asset Withdrawal":
        funding = netCashflowAssetWidthdrawalDist;
        break;
      default:
        funding = [];
    }
  
    // 2. Build a data structure:
    //    year -> { [categoryName]: totalAmount }
    const expensesByYearCategory = {};
    let minYear = Infinity;
    let maxYear = -Infinity;
  
    childrenData.forEach((child) => {
      const startYear = child.birthYear;
      const endYear = startYear + fundingTillAge - 1; // or whichever range you need
  
      for (let year = startYear; year <= endYear; year++) {
        const age = year - startYear;
        const childExpensesObj = getChildExpensesForAge(child, age);
  
        if (!expensesByYearCategory[year]) {
          expensesByYearCategory[year] = {};
        }
  
        // Sum up expenses by category for this year
        for (const [cat, amt] of Object.entries(childExpensesObj)) {
          expensesByYearCategory[year][cat] =
            (expensesByYearCategory[year][cat] || 0) + amt;
        }
  
        // Track global min/max year
        if (year < minYear) minYear = year;
        if (year > maxYear) maxYear = year;
      }
    });
  
    // 3. Gather all categories (unique)
    const allCategoriesSet = new Set();
    Object.values(expensesByYearCategory).forEach((catObj) => {
      Object.keys(catObj).forEach((c) => allCategoriesSet.add(c));
    });
    const allCategories = Array.from(allCategoriesSet).sort();
  
    // Helper to get the total funding for a specific year
    function getFundForYear(year, fundingArray) {
      return fundingArray
        .filter((f) => new Date(f.date).getFullYear() === year)
        .reduce((sum, f) => sum + (f.amount || 0), 0);
    }
  
    // Prepare data structures for the final chart & table
    const seriesDataMap = {};
    allCategories.forEach((cat) => {
      seriesDataMap[cat] = []; // will hold the stacked column data
    });
  
    const probabilityPoints = []; // will hold the line data (probability)
    const tableRows = [];         // will hold rows for the data table
  
    // 4. Single pass over the year range:
    const yearRange = [];
    for (let y = minYear; y <= maxYear; y++) {
      yearRange.push(y);
    }
  
    yearRange.forEach((year) => {
      const catObj = expensesByYearCategory[year] || {};
      const totalExpense = Object.values(catObj).reduce((sum, val) => sum + val, 0);
  
      // Get funding for this year
      const fundForYear = getFundForYear(year, funding);
  
      // Probability
      const probability =
        totalExpense > 0 ? Math.min((fundForYear / totalExpense) * 100, 100) : 100;
  
      // Fill in the category-based series data
      allCategories.forEach((cat) => {
        seriesDataMap[cat].push({
          x: Date.UTC(year, 0, 1),
          y: catObj[cat] || 0,
          year,
        });
      });
  
      // Fill in the probability line data
      probabilityPoints.push([Date.UTC(year, 0, 1), probability]);
  
      // Build table row (including sub-categories)
      const subCategories = Object.entries(catObj).map(([cat, amt]) => ({
        category: cat,
        amount: amt,
      }));
  
      tableRows.push({
        year,
        fundForYear,
        totalExpense,
        probability: `${probability.toFixed(2)}%`,
        subCategories,
      });
    });
  
    // 5. Construct the Highcharts series array
    const newChartSeries = allCategories.map((cat) => ({
      name: cat,
      type: "column",
      data: seriesDataMap[cat],
      stack: stacked ? "childrenExpenses" : undefined,
      dataLabels: { enabled: false },
    }));
  
    const newProbabilitySeries = [
      {
        name: "Funding Probability",
        type: "line",
        yAxis: 1,
        data: probabilityPoints,
        marker: { enabled: true },
      },
    ];
  
    // 6. Update the state
    setChartSeries(newChartSeries);
    setProbabilitySeries(newProbabilitySeries);
    setTableRows(tableRows);
  };
  

  // -----------------------------------------
  // CHART CONFIG
  // -----------------------------------------
  const chartOptions = {
    title: { text: "Children Expenses & Probability" },
    xAxis: {
      type: "datetime",
    },
    yAxis: [
      {
        title: { text: "Expense" },
        // Remove stack labels
        stackLabels: { enabled: false },
      },
      {
        title: { text: "Probability (%)" },
        opposite: true,
        max: 100,
      },
    ],
    credits: { enabled: false },
    legend: { enabled: true },
    plotOptions: {
      column: {
        // If stacked is true, stacking: "normal". If not, no stacking.
        stacking: stacked ? "normal" : undefined,
        groupPadding: 0.1,
      },
    },
    series: [...chartSeries, ...probabilitySeries],
  };

  return (
    <div style={{ padding: "1rem 2rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
      {/* Funding Source Selector */}
      <div style={{display: "flex", gap: "1rem", flexWrap: "wrap",width:"100%",boxSizing:"border-box"}}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
            height: "max-content",
            width: "300px",
            background: "#fafafa",
            borderRadius: "10px", 
            padding:"1rem"
          }}
        >
          <SelectBox
            variant="outlined"
            size="medium"
            label="Funding Source"
            options={[
              "Net Cashflow Before Asset Return",
              "Net Cashflow After Asset Return",
              "Net Cashflow Asset Withdrawal",
            ]}
            value={formData.funding}
            onChange={(e, v) => setFormData((prev) => ({ ...prev, funding: v }))}
          />
          <ChildList
            childrenData={childrenData}
            onEdit={handleEditChild}
            onRemove={handleRemoveChild}
          />
          {/* Add Child Button */}
          <Button variant="contained" onClick={handleAddChildClick} style={{ marginTop: "1rem" }}>
            Add Child
          </Button>
        </div>
        <AISummary childrenData={childrenData} tableRows={tableRows}/>
      </div>
  {/* Summary */}
  
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem", flex: "1" }}>
        {/* Chart */}
        {chartSeries.length > 0 && (
          <>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </>
        )}

        {/* Detailed Table */}
        <h3 style={{ marginTop: "2rem" }}>Detailed Yearly Expenses</h3>
        <ExpenseBreakdownTable
          tableRows={tableRows}
          expandedYears={expandedYears}
          onToggleExpand={toggleYearExpand}
        />

        {/* Modal for Add/Edit Child */}
        {modalOpen && (
          <ChildModal
            isOpen={modalOpen}
            onClose={handleModalClose}
            onSave={handleModalSave}
            initialData={editingChild || {}}
          />
        )}
      </div>
    </div>
  );
}

// -----------------------------------------
// Child List
// -----------------------------------------
function ChildList({ childrenData, onEdit, onRemove }) {
  if (!childrenData?.length) return <p>No children added yet.</p>;

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1rem" }}>
      <h4 style={{ fontWeight: "bold",borderBottom:"1px solid #ddd",padding:"0.5rem"}}>Children</h4>
      {childrenData.map((child) => (
        <div
          key={child.id}
          style={{
            borderBottom: "1px solid #ddd",
            padding: "1rem",
            alignItems: "center",
            display: "flex",
            gap: "1rem",
          }}
        >
          <h4>{child.name}</h4>
          <h4>{child.birthYear}</h4>
          <div style={{ marginLeft: "auto" }}>
            <IconButton onClick={() => onEdit(child)}>
              <Edit fontSize="small" />
            </IconButton>
            <IconButton onClick={() => onRemove(child.id)}>
              <Delete fontSize="small" />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
}

// -----------------------------------------
// Yearly Expense Breakdown Table
//   - Expandable sub-rows
// -----------------------------------------
function ExpenseBreakdownTable({ tableRows, expandedYears, onToggleExpand }) {
  if (!tableRows.length) {
    return <p>No data to display.</p>;
  }

  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        marginTop: "1rem",
      }}
    >
      <thead>
        <tr style={{ background: "#f0f0f0" }}>
          <th style={{ padding: "8px" }}>Year</th>
          <th style={{ padding: "8px" }}>Funds</th>
          <th style={{ padding: "8px" }}>Expense</th>
          <th style={{ padding: "8px" }}>Probability</th>
        </tr>
      </thead>
      <tbody>
        {tableRows.map((row, idx) => {
          const isExpanded = expandedYears[row.year] || false;
          return (
            <React.Fragment key={idx}>
              {/* Main Row */}
              <tr
                onClick={() => onToggleExpand(row.year)}
                style={{
                  backgroundColor: "#fff",
                  borderBottom: isExpanded ? "none" : "1px solid #ccc",
                  cursor: "pointer",
                }}
              >
                <td style={{ padding: "8px" }}>
                   <CollapseToggler isExpanded={isExpanded}/>
                                          
                  {row.year}
                </td>
                <td style={{ padding: "8px" }}>${formatNumber(row.fundForYear)}</td>
                <td style={{ padding: "8px" }}>${formatNumber(row.totalExpense)}</td>
                <td style={{ padding: "8px" }}>{row.probability}</td>
              </tr>

              {/* Sub-categories row (visible if expanded) */}
              {isExpanded && (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      padding: "8px",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "6px",
                              textAlign: "left",
                            }}
                          >
                            Category
                          </th>
                          <th
                            style={{
                              padding: "6px",
                              textAlign: "left",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.subCategories.map((sub, sIdx) => (
                          <tr key={sIdx}>
                            <td
                              style={{
                                padding: "6px",
                              }}
                            >
                              {sub.category}
                            </td>
                            <td
                              style={{
                                padding: "6px",
                              }}
                            >
                              ${sub.amount.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

function AISummary({tableRows,childrenData}) {
    const [latestExpense, setLatestExpense] = useState(0);
    const [latestProbability, setLatestProbability] = useState(0);
    const [aiSummary, setAISummary] = useState();

    useEffect(()=>{
    // For summary, look at the last year row
    if (tableRows.length > 0) {
        const lastRow = tableRows[0];
        console.log(tableRows);
        setLatestExpense(lastRow.totalExpense || 0);
        const parseProb = parseFloat(lastRow.probability.replace("%", ""));
        setLatestProbability(parseProb || 0);
        setAISummary(`Tell about the total expenses, probablity, and how to meet this expense. 
            Give an understanding of how having a child will incur this 
            new expenses - and how well he is prepared to meet this expense. 
            ${JSON.stringify({"childrenDetails":childrenData,"childExpense":tableRows})}`)
    } else {
        setLatestExpense(0);
        setLatestProbability(0);
    }
    },[tableRows])

   
    
       
    return (
        <AISummaryBox 
        message={aiSummary} 
        sx={{ flex: "1" }}>
        {/* <p>
          Immediate Total Expense: <strong>${latestExpense.toFixed(2)}</strong>
        </p>
        <p>
          Probability of current coverage: <strong>{latestProbability.toFixed(2)}%</strong>
        </p> */}
      </AISummaryBox>
    )
}

// -----------------------------------------
// Child Modal (Add/Edit) - with Dynamic Age Brackets
// -----------------------------------------
function ChildModal({ isOpen, onClose, onSave, initialData }) {
  // We'll store bracket objects like:
  // {
  //   bracketId,
  //   rangeStart,
  //   rangeEnd,
  //   categories: [
  //     { category: "food", amount: 1000, inflation:2  },
  //     ...
  //   ]
  // }

  const [childData, setChildData] = useState(() => ({
    id: null,
    name: "",
    birthYear: new Date().getFullYear(),
    ageBrackets: JSON.parse(JSON.stringify(DEFAULT_BRACKETS)),
  }));

  useEffect(() => {
    if (!isOpen) return;

    if (initialData && initialData.id) {
      // editing
      setChildData({
        id: initialData.id,
        name: initialData.name || "",
        birthYear: initialData.birthYear || new Date().getFullYear(),
        ageBrackets: initialData.ageBrackets
          ? JSON.parse(JSON.stringify(initialData.ageBrackets))
          : JSON.parse(JSON.stringify(DEFAULT_BRACKETS)),
      });
    } else {
      // adding new
      setChildData({
        id: null,
        name: "",
        birthYear: new Date().getFullYear(),
        ageBrackets: JSON.parse(JSON.stringify(DEFAULT_BRACKETS)),
      });
    }
  }, [isOpen, initialData]);

  const handleChange = (field, value) => {
    setChildData((prev) => ({ ...prev, [field]: value }));
  };

  // Bracket-level changes
  const handleBracketChange = (bracketId, field, value) => {
    setChildData((prev) => {
      const updated = prev.ageBrackets.map((b) =>
        b.bracketId === bracketId ? { ...b, [field]: value } : b
      );
      return { ...prev, ageBrackets: updated };
    });
  };

  // Categories in a bracket (array-based)
  const handleCategoryFieldChange = (bracketId, idx, field, value) => {
    setChildData((prev) => {
      const updated = prev.ageBrackets.map((b) => {
        if (b.bracketId !== bracketId) return b;

        // Clone categories, update one row
        const newCatArr = b.categories.map((catObj, catIdx) => {
          if (catIdx !== idx) return catObj;
          return { ...catObj, [field]: value };
        });
        return { ...b, categories: newCatArr };
      });
      return { ...prev, ageBrackets: updated };
    });
  };

  const handleAddCategory = (bracketId) => {
    const newCat = prompt("Enter new category name:");
    if (!newCat) return;
    setChildData((prev) => {
      const updated = prev.ageBrackets.map((b) => {
        if (b.bracketId === bracketId) {
          return {
            ...b,
            categories: [
              ...b.categories,
              {
                category: newCat,
                amount: 0,
                inflation: 0,
              },
            ],
          };
        }
        return b;
      });
      return { ...prev, ageBrackets: updated };
    });
  };

  const handleRemoveCategory = (bracketId, catName) => {
    const confirmRemove = window.confirm(
      `Remove category "${catName}" from this bracket?`
    );
    if (!confirmRemove) return;
    setChildData((prev) => {
      const updated = prev.ageBrackets.map((b) => {
        if (b.bracketId === bracketId) {
          // Filter out this category
          const filtered = b.categories.filter((c) => c.category !== catName);
          return { ...b, categories: filtered };
        }
        return b;
      });
      return { ...prev, ageBrackets: updated };
    });
  };

  const handleAddBracket = () => {
    const newId = `b${Date.now()}`;
    setChildData((prev) => ({
      ...prev,
      ageBrackets: [
        ...prev.ageBrackets,
        {
          bracketId: newId,
          rangeStart: 20,
          rangeEnd: 25,
          categories: [
            {
              category: "newCategory",
              amount: 100,
              inflation: 0,
            },
          ],
        },
      ],
    }));
  };

  const handleRemoveBracket = (bracketId) => {
    const confirmRemove = window.confirm(
      "Are you sure you want to remove this bracket?"
    );
    if (!confirmRemove) return;
    setChildData((prev) => ({
      ...prev,
      ageBrackets: prev.ageBrackets.filter((b) => b.bracketId !== bracketId),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(childData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal onClose={onClose}>
      <div
        style={{
          padding: "1rem",
          width: "100%",
          boxSizing: "border-box",
          height: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <h3>{childData.id ? "Edit Child" : "Add Child"}</h3>
        <form
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          onSubmit={handleSubmit}
        >
          <TextInput
            variant="outlined"
            sx={{ maxWidth: "250px" }}
            label="Name"
            value={childData.name}
            onChange={(v) => handleChange("name", v)}
            required
          />
          <NumberInput
            sx={{ maxWidth: "250px" }}
            variant="outlined"
            label="Birth Year"
            type="number"
            min={new Date().getFullYear() - 1}
            max={new Date().getFullYear() + 50}
            value={childData.birthYear}
            onChange={(v) => handleChange("birthYear", parseInt(v, 10))}
            required
          />

          <div>
            <h4 style={{ fontWeight: "bold" }}>Age Brackets</h4>
            <h4>
              Children's expenses change as they age. Define the age ranges for
              each bracket and the associated expenses (with individual
              inflation).
            </h4>
          </div>
          {childData.ageBrackets.map((bracket) => (
            <div
              key={bracket.bracketId}
              style={{ background: "#fafafa", padding: "1rem" }}
            >
              <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                <span>Age Bracket:</span>
                <TextInput
                  label="Start"
                  type="number"
                  sx={{ fontSize: "0.8rem", maxWidth: "50px" }}
                  value={bracket.rangeStart}
                  onChange={(v) =>
                    handleBracketChange(bracket.bracketId, "rangeStart", parseInt(v, 10))
                  }
                />
                <TextInput
                  label="End"
                  sx={{ fontSize: "0.8rem", maxWidth: "50px" }}
                  type="number"
                  value={bracket.rangeEnd}
                  onChange={(v) =>
                    handleBracketChange(bracket.bracketId, "rangeEnd", parseInt(v, 10))
                  }
                />
                <Button
                  variant="outlined"
                  onClick={() => handleRemoveBracket(bracket.bracketId)}
                  style={{ marginLeft: "auto", fontSize: "0.8rem", minWidth: 0 }}
                >
                  Remove Age
                </Button>
              </div>

              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "0.5rem",
                }}
              >
                <thead>
                  <tr style={{ background: "#f7f7f7" }}>
                    <th style={{ padding: "6px" }}>Category</th>
                    <th style={{ padding: "6px" }}>Annual Amount</th>
                    <th style={{ padding: "6px" }}>Annual Inflation</th>
                    <th style={{ padding: "6px" }}>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {bracket.categories.map((catObj, idx) => (
                    <tr key={catObj.category + idx}>
                      <td style={{ padding: "6px" }}>{catObj.category}</td>
                      <td style={{ padding: "6px" }}>
                        <TextInput
                          type="number"
                          sx={{ width: "80px" }}
                          value={catObj.amount}
                          onChange={(v) =>
                            handleCategoryFieldChange(
                              bracket.bracketId,
                              idx,
                              "amount",
                              parseFloat(v) || 0
                            )
                          }
                        />
                      </td>
                      <td style={{ padding: "6px" }}>
                        <TextInput
                          type="number"
                          sx={{ width: "80px" }}
                          value={catObj.inflation}
                          step="0.01"
                          onChange={(v) =>
                            handleCategoryFieldChange(
                              bracket.bracketId,
                              idx,
                              "inflation",
                              parseFloat(v) || 0
                            )
                          }
                        />
                      </td>
                      <td
                        style={{
                          padding: "6px",
                          textAlign: "center",
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            handleRemoveCategory(bracket.bracketId, catObj.category)
                          }
                        >
                          <Cancel />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button onClick={() => handleAddCategory(bracket.bracketId)}>
                Add Category
              </Button>
            </div>
          ))}

          <div>
            <Button variant="highlight" onClick={handleAddBracket}>
              + Add Another Bracket
            </Button>
          </div>

          <div style={{ marginTop: "1rem", textAlign: "center" }}>
            <Button variant="outlined" onClick={onClose} style={{ marginRight: "10px" }}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              {childData.id ? "Save" : "Add"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}


function getMaxAgeFromChildren(children) {
  if (!children || children.length === 0) {
    return 0; // Return 0 if the array is empty or undefined
  }

  // Get the max rangeEnd from all children's ageBrackets
  return Math.max(
    ...children.flatMap(child => child.ageBrackets.map(bracket => bracket.rangeEnd))
  );
}
