import React from 'react';

import Modal from 'components/Modal/Modal';

import FinancialVault from './FinancialVault';

export default function FinancialVaultModal({open,onClose}) {
  return (
    <>
    {open &&
    <Modal onClose={onClose} sx={{width:"80%",height:"80%"}}>
        <FinancialVault/>
        </Modal>}
        </>
  )
}
