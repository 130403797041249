import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';

export default function SocialMedia({color="black"}) {
  return (
    <div style={{display:"flex",gap:"2rem",alignItems:"center"}}>
    <a
      href="https://twitter.com/AutonomyFinance"
      target='blank'
      style={{
        fontSize: "2rem",
        color:color,
        display:"flex",
        gap:"1rem"
      }}
    >
       <XIcon style={{fontSize:"3rem"}} />
       
    </a>
    <a
      href="https://www.instagram.com/autonomyfinance/"
      target='blank'
      style={{
        fontSize: "2rem",
        color:color,
        display:"flex",
        gap:"1rem"
      }}
    >
      <InstagramIcon style={{fontSize:"3rem"}} />
    </a>
    <a
     href="https://www.instagram.com/autonomyfinance/"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
       <YouTubeIcon style={{color:color,fontSize:"4rem"}}  />
       
    </a>
    </div>
  )
}
