import React, { useState } from 'react'
import GoalsProjectionEdit from './GoalsProjectionEdit'
import GoalRealizeSteps from './GoalRealizeSteps'
import GoalsOverview from './GoalsOverview'
import NoFinancialData from 'myFinancials/Template/NoFinancialData'
import { useAppInfo } from 'AppState'
import GoalSuccessFailureScenario from './GoalSuccessFailureScenario'
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating'
import GoalsSuccessRate from './GoalsSuccessRate'
import LeftSidebarPageMenu from 'sidebar/LeftSidebar/LeftSidebarPageMenu'
import { Paper } from '@mui/material'
import { menuGoals } from 'nav/menu'

export default function GoalPage() {
  const {stateMyFinancials} = useAppInfo()
  const {goalsPeriodic,financialsLoaded,financialView} = stateMyFinancials
  const [menuSel,setMenuSel] = useState("Overview")
  const content = {
    "Overview":<GoalsOverview displayType={"main"}/>,
    "Steps to realize Goals":<GoalRealizeSteps/>,
    "Projections":<GoalsProjectionEdit/>,
    "SuccessFailure":<GoalSuccessFailureScenario/>
  }
  return (
    <div style={{width:"100%",position:"relative",height:"100%",minHeight:"50vh"}}>
    
 
    <div className="pageContentWrapper">
    {/* <GoalsSuccessRate/> */}
        {/* <div style={{display:"flex",maxWidth:"max-content"}}>
        <Toggle handleToggle={(v)=>dispatchMyFinancials({"financialView":!v ? "personal":"demo"})} toggleMode={financialView !== "personal"} toggleValues={["Personal","Demo"]}/>
        </div>  */}
          <div className="containerLeftMenuAndContent">
            <LeftSidebarPageMenu
                name={`Information`}
                bigMenu={false}
                menuOptions={menuGoals}
                menuSel={menuSel}
                handleMenuSel={(v)=>setMenuSel(v)}
              />
            <Paper
              elevation={1}
              className="containerMainContent"
              sx={{position:"relative"}}
            > 
            <NoFinancialData financialType="goals"/>
            {financialsLoaded && goalsPeriodic.length > 0 && 
            <>
            {financialView === "demo" && <div style={{zIndex:100,position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}> 
              <h1 style={{fontSize:"2rem",fontWeight:"bold",color:"rgba(0,0,0,0.2)"}}>Demo Account</h1>
              </div>}
              {content[menuSel]}
              </>}
            </Paper>
          </div>
        </div>

    </div>
  )
}
