import { navLinkDict } from "nav/navLinks";
import { getStartEndDate } from "./useCheckCreateTodos";
import { DiversifiedAssets, GrowingAssets, NoSavings } from "./TodoHealthActions/AssetManagent";
import { BuildEmergencyFund, LifeInsuranceCoverage } from "./TodoHealthActions/RiskAndCrisisManagement";
import { CreditCardDebt, HighDebtToIncome, LiabilityCoverage } from "./TodoHealthActions/DebtManagement";
import { GoalPlanning } from "./TodoHealthActions/GoalPlanning";
import SpendingMore, { CashCoverage, IncomeStability, MinMonthlyCash } from "./TodoHealthActions/CashflowManagement";

const calculateTotal = (items, types) => 
    items.reduce((total, item) => types.includes(item.type) ? total + item.amount : total, 0);


export const todoHealthCheck = [
    {
        id: "check-savings",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Asset",
        name: "Savings",
        description: "Create a savings plan to build financial reserves for contingencies and retirement.",
        actions: [
            {
                label: "Start Saving",
                component: <NoSavings/>,
                description: "Create a savings plan to build financial reserves for contingencies and retirement.",
                link: {pathname:"/MyFinancials/Assets",label:"Check Savings"},
                details: [
                    "Start with a saving target of 5% of monthly income.",
                    "Automate transfers to a savings account."
                ],
                questions:[""],
                // financialProduct:"Savings Account",
                professional:"Financial Advisor"
            },
        ],
        period: "Monthly",
        dateStart: getStartEndDate("month", 1),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 3,
    },
    {
        id: "growing-savings",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Asset",
        name: "Growing Assets",
        description: "Invest in assets that grow over time like portfolios, real estate, or other investments.",
        actions: [
            {
                label: "Invest Now",
                component: <GrowingAssets/>,
                description: "Invest in assets that grow over time like portfolios, real estate, or other investments.",
                link: "/MyFinancials/Assets",
                details: [
                    "Review current asset allocation.",
                    "Identify areas for growth investment.",
                    "Diversify investments for balanced returns."
                ],
                // financialProduct:"Savings Account",
                professional:"Financial Advisor"
            },
        ],
        period: "Quarterly",
        dateStart: new Date(),
        dateStart: getStartEndDate("month", 2),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 2,
    },
    {
        id: "diversified-savings",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Asset",
        name: "Diversified Assets",
        description: "Ensure assets are distributed across at least three classes for stability.",
        actions: [
            {
                label: "Diversify Now",
                component: <DiversifiedAssets/>,
                link: "/MyFinancials/Assets",
                details: [
                    "Assess current asset classes.",
                    "Add investments in different asset categories.",
                    "Monitor diversification quarterly."
                ],
                 // financialProduct:"Savings Account",
                professional:"Financial Advisor"
            },
        ],
        period: "Quarterly",
        dateStart: getStartEndDate("month", 2),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 1,
    },
    {
        id: "credit-card-debt",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Debt",
        name: "Credit Card Debt",
        description: "Eliminate credit card debt to reduce financial burden.",
        actions: [
            {
                label: "Pay Debt",
                component: <CreditCardDebt/>,
                link: "/MyFinancials/Liabilities",
                description: "Eliminate credit card debt to reduce financial burden.",
                details: [
                    "Review current credit card balances.",
                    "Plan payments to clear debt monthly.",
                    "Avoid unnecessary credit card expenses."
                ],
                 // financialProduct:"Savings Account",
                professional:"Financial Advisor"
            },
        ],
        period: "Monthly",
        dateStart: new Date(),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 3,
    },
    {
        id: "debt-income-ratio",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Debt",
        name: "Debt-to-Income Ratio",
        description: "Keep the debt-to-income ratio below 30%.",
        actions: [
            {
                label: "Manage Debt",
                component: <HighDebtToIncome/>,
                link: "/MyFinancials/Liabilities",
                description: "Keep the debt-to-income ratio below 30%.",
                details: [
                    "Calculate current debt-to-income ratio.",
                    "Identify ways to reduce monthly debt obligations.",
                    "Ensure income exceeds liabilities by at least 70%."
                ],
                 // financialProduct:"Savings Account",
                professional:"Financial Advisor"
            },
        ],
        period: "Semi-Annually",
        dateStart: getStartEndDate("quarter", 1),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
        priority: 2,
    },
    {
        id: "liability-coverage",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Debt",
        name: "Liability Coverage",
        description: "Ensure liabilities are covered by assets. Maintain a liability coverage ratio greater than 1.5.",
        actions: [
            {
                label: "Review Coverage",
                component: <LiabilityCoverage/>,
                link: "/MyFinancials/Liabilities",
                description: "Ensure liabilities are covered by assets. Maintain a liability coverage ratio greater than 1.5.",
                details: [
                    "Analyze asset-to-liability ratio.",
                    "Reduce liabilities or increase assets to meet the target ratio.",
                    "Monitor coverage ratio quarterly."
                ],
            },
        ],
        period: "Quarterly",
        dateStart: getStartEndDate("month", 2),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 2,
    },
    {
        id: "spending-rate-monthly",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Cashflow",
        name: "Spending Rate Monthly",
        description: "Keep your spending rate under 95% of income.",
        actions: [
            {
                label: "Track Spending",
                component: <SpendingMore/>,
                link: "/MyFinancials/Cashflow",
                description: "Keep your spending rate under 95% of income.",
                details: [
                    "Review monthly income and expenses.",
                    "Identify and reduce unnecessary expenditures.",
                    "Ensure savings allocation meets at least 5% of income."
                ],
            },
        ],
        period: "Monthly",
        dateStart: getStartEndDate("week", 0),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 3,
    },
    {
        id: "cash-coverage",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Cashflow",
        name: "Cash Coverage",
        description: "Maintain sufficient cash to cover monthly expenses.",
        actions: [
            {
                label: "Plan Coverage",
                component: <CashCoverage/>,
                link: "/MyFinancials/Assets",
                description: "Maintain sufficient cash to cover monthly expenses.",
                details: [
                    "Calculate monthly expense coverage ratio.",
                    "Ensure cash reserves meet or exceed monthly expenses.",
                    "Review coverage ratio quarterly."
                ],
            },
        ],
        period: "Quarterly",
        dateStart: getStartEndDate("month", 2),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 3,
    },
    {
        id: "cashflow-monthly",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Cashflow",
        name: "Positive Monthly Net Cashflow",
        description: "Ensure positive cashflow by balancing income and expenses.",
        actions: [
            {
                label: "Optimize Cashflow",
                component: <MinMonthlyCash/>,
                link: "/MyFinancials/Cashflow",
                description: "Ensure positive cashflow by balancing income and expenses.",
                details: [
                    "Analyze cash inflow and outflow patterns.",
                    "Identify areas to increase income or reduce expenses.",
                    "Monitor cashflow status monthly."
                ],
            },
        ],
        period: "Monthly",
        dateStart: getStartEndDate("week", 2),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 3,
    },
    {
        id: "minimum-net-cashflow",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Cashflow",
        name: "Maintain Minimum Monthly Net Cashflow",
        description: "Ensure positive cashflow by balancing income and expenses.",
        actions: [
            {
                label: "Optimize Cashflow",
                component: <MinMonthlyCash/>,
                link: "/MyFinancials/Cashflow",
                description: "Ensure positive cashflow by balancing income and expenses.",
                details: [
                    "Analyze cash inflow and outflow patterns.",
                    "Identify areas to increase income or reduce expenses.",
                    "Monitor cashflow status monthly."
                ],
            },
        ],
        period: "Monthly",
        dateStart: getStartEndDate("week", 2),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 2,
    },
    {
        id: "income-stability",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Cashflow",
        name: "Maintain a Periodic Source of Income",
        description: "Ensure Periodic Source of Income.",
        actions: [
            {
                label: "Periodic Income",
                component:<IncomeStability/>,
                link: "/MyFinancials/Cashflow",
                description: "Ensure Periodic Source of Income.",
                details: [
                    "Analyze cash inflow and outflow patterns.",
                    "Identify areas to increase income or reduce expenses.",
                    "Monitor cashflow status monthly."
                ],
            },
        ],
        period: "Monthly",
        dateStart: getStartEndDate("week", 2),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 2,
    },
    {
        id: "goal success rate before withdrawal",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Goal Planning",
        name: "Goal Success Rate Before Withdrawal",
        description: "Achieve a goal success rate of > 80% before withdrawing funds.",
        actions: [
            {
                label: "Review Goals",
                component: <GoalPlanning/>,
                link: navLinkDict?.Goals?.path,
                description: "Achieve a goal success rate of > 80% before withdrawing funds.",
                details: [
                    "Assess current goal success rates.",
                    "Plan financial goals realistically.",
                    "Ensure resources align with the desired success rate."
                ],
            },
        ],
        period: "Quarterly",
        dateStart: getStartEndDate("month", 2),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 2,
    },
    {
        id: "goal success rate after withdrawal",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Goal Planning",
        name: "Goal Success Rate After Withdrawal",
        description: "Achieve a goal success rate of > 80% before withdrawing funds.",
        actions: [
            {
                label: "Review Goals",
                component: <GoalPlanning successType="After Withdrawal"/>,
                link: navLinkDict?.Goals?.path,
                description: "Achieve a goal success rate of > 80% before withdrawing funds.",
                details: [
                    "Assess current goal success rates.",
                    "Plan financial goals realistically.",
                    "Ensure resources align with the desired success rate."
                ],
            },
        ],
        period: "Quarterly",
        dateStart: getStartEndDate("month", 2),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 2,
    },
    {
        id: "life-insurance-coverage",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Risk & Crisis Management",
        name: "Life Insurance Coverage",
        description: "Ensure adequate life insurance coverage to protect dependents.",
        actions: [
            {
                label: "Get Insured",
                component: <LifeInsuranceCoverage/>,
                link: "/MyFinancials/Assets",
                description: "Ensure adequate life insurance coverage to protect dependents.",
                details: [
                    "Set up life insurance policies.",
                    "Calculate the required coverage amount.",
                    "Update or add policies to meet income protection needs."
                ],
                questions:["What are the benefits of Life Insurance?","Best Life Insurance Providers"],
                financialProduct:"Life Insurance",
                professional:"Insurance Advisor",
            },
        ],
        period: "Quarterly",
        dateStart: getStartEndDate("month", 1),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        priority: 1,
    },
    {
        id: "emergency-fund",
        owner: "takestock",
        edit: false,
        delete: false,
        category: "Risk & Crisis Management",
        name: "Emergency Fund",
        description: "Build an emergency fund to cover 6-9 months of expenses.",
        actions: [
            {
                label: "Build Emergency Fund",
                component: <BuildEmergencyFund/>,
                link: "/MyFinancials/Cashflow",
                description: "Build an emergency fund to cover 6-9 months of expenses.",
                details: [
                    "Your emergency fund should be set up to cover 6-9 months of expenses.",
                    "Set up a separate emergency account.",
                    "Automate monthly contributions to the fund."
                ],

            },
        ],
        period: "Monthly",
        dateStart: getStartEndDate("month", 1),
        dateExpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
        priority: 3,
    },
];



export function getTodoHealthCheck (id) {
  return todoHealthCheck.find((todo) => todo.id === id);
}

function getStartDate(){
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    return startOfYear
}