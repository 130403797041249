export const financeCategories = [
  {
    category: "Financial Wellness",
    scenarios: [
      {
        name: "Income Growth & Pay Negotiation",
        hasCustomModel: false,
        relatedClients: 5
      },
      {
        name: "Expense Management - Budgeting",
        hasCustomModel: true,
        relatedClients: 8
      },
      {
        name: "Credit Building & Score Management",
        hasCustomModel: false,
        relatedClients: 4
      },
      {
        name: "Financial Habits & Behavior",
        hasCustomModel: true,
        relatedClients: 6
      },
      {
        name: "Financial Education & Literacy",
        hasCustomModel: false,
        relatedClients: 3
      },
      {
        name: "Pay off Debt vs Save",
        hasCustomModel: true,
        relatedClients: 7
      },
      {
        name: "Overall Goal Planning",
        hasCustomModel: true,
        relatedClients: 12
      },
      {
        name: "Side Hustles & Additional Income Streams",
        hasCustomModel: false,
        relatedClients: 4
      }
    ]
  },
  {
    category: "Managing Liabilities",
    scenarios: [
      {
        name: "Student Loan Payoff",
        hasCustomModel: true,
        relatedClients: 6
      },
      {
        name: "Debt Consolidation Strategy",
        hasCustomModel: true,
        relatedClients: 8
      },
      {
        name: "Mortgage Refinancing",
        hasCustomModel: true,
        relatedClients: 10
      },
      {
        name: "Other Loan Refinancing (Auto, Personal Loans)",
        hasCustomModel: false,
        relatedClients: 4
      },
      {
        name: "Credit Card",
        hasCustomModel: true,
        relatedClients: 7
      }
    ]
  },
  {
    category: "Family & Children",
    scenarios: [
      {
        name: "Child Planning / Family Formation",
        hasCustomModel: true,
        relatedClients: 5
      },
      {
        name: "Child Education Fund / College Savings",
        hasCustomModel: true,
        relatedClients: 9
      },
      {
        name: "Wedding / Major Life Event Planning",
        hasCustomModel: false,
        relatedClients: 3
      },
      {
        name: "Parental Support & Elder Care Planning",
        hasCustomModel: true,
        relatedClients: 6
      }
    ]
  },
  {
    category: "Home & Large Purchase",
    scenarios: [
      {
        name: "Large Purchase (Car, Boat, etc.)",
        hasCustomModel: false,
        relatedClients: 4
      },
      {
        name: "Rent vs Buy Motor Vehicle",
        hasCustomModel: true,
        relatedClients: 5
      },
      {
        name: "Rent vs. Buy Home Analysis",
        hasCustomModel: true,
        relatedClients: 8
      },
      {
        name: "Buying a Home",
        hasCustomModel: true,
        relatedClients: 12
      },
      {
        name: "Major Home Renovation",
        hasCustomModel: false,
        relatedClients: 6
      },
      {
        name: "Second/Vacation Home Purchase",
        hasCustomModel: true,
        relatedClients: 4
      },
      {
        name: "Upgrading/Downsizing Home",
        hasCustomModel: true,
        relatedClients: 7
      },
      {
        name: "Home Energy Efficiency Investments",
        hasCustomModel: false,
        relatedClients: 3
      }
    ]
  },
  {
    category: "Retirement & Health",
    scenarios: [
      {
        name: "Healthcare & Medical Costs Planning",
        hasCustomModel: true,
        relatedClients: 8
      },
      {
        name: "Early Retirement (FIRE) Planning",
        hasCustomModel: true,
        relatedClients: 6
      },
      {
        name: "Retirement Asset Accumulation Targets",
        hasCustomModel: true,
        relatedClients: 15
      },
      {
        name: "Investment Withdrawal Strategies",
        hasCustomModel: true,
        relatedClients: 9
      },
      {
        name: "Standard Retirement Planning",
        hasCustomModel: true,
        relatedClients: 20
      },
      {
        name: "Long-Term Care Insurance Planning",
        hasCustomModel: false,
        relatedClients: 7
      },
      {
        name: "Social Security Optimization",
        hasCustomModel: true,
        relatedClients: 12
      }
    ]
  },
  {
    category: "Making Wealth – Asset Management",
    scenarios: [
      {
        name: "Investment Portfolio Growth Strategies",
        hasCustomModel: true,
        relatedClients: 18
      },
      {
        name: "Investment vs. Income Generation Analysis",
        hasCustomModel: true,
        relatedClients: 14
      },
      {
        name: "Portfolio Simulation & Stress Testing",
        hasCustomModel: true,
        relatedClients: 16
      },
      {
        name: "Alternative Investments & Diversification",
        hasCustomModel: true,
        relatedClients: 10
      }
    ]
  },
  {
    category: "Other / Lifestyle & Career",
    scenarios: [
      {
        name: "Major Vacation / Travel Fund",
        hasCustomModel: false,
        relatedClients: 5
      },
      {
        name: "Starting a Business",
        hasCustomModel: true,
        relatedClients: 7
      },
      {
        name: "Relocation / Living Abroad",
        hasCustomModel: false,
        relatedClients: 4
      },
      {
        name: "Career Shift / Sabbatical Planning",
        hasCustomModel: true,
        relatedClients: 6
      },
      {
        name: "Inheritance / Estate Planning",
        hasCustomModel: true,
        relatedClients: 8
      },
      {
        name: "Philanthropy & Charitable Giving",
        hasCustomModel: true,
        relatedClients: 9
      },
      {
        name: "Continuing Education & Career Development",
        hasCustomModel: false,
        relatedClients: 5
      }
    ]
  },
  {
    category: "Insurance & Risk Management",
    scenarios: [
      {
        name: "Life Insurance Planning",
        hasCustomModel: true,
        relatedClients: 15
      },
      {
        name: "Health Insurance",
        hasCustomModel: true,
        relatedClients: 12
      },
      {
        name: "Disability Insurance",
        hasCustomModel: true,
        relatedClients: 8
      },
      {
        name: "Home & Auto Insurance Review",
        hasCustomModel: false,
        relatedClients: 6
      },
      {
        name: "Insurance Overview",
        hasCustomModel: true,
        relatedClients: 20
      }
    ]
  },
  {
    category: "Tax Planning & Optimization",
    scenarios: [
      {
        name: "Year-End Tax Strategies",
        hasCustomModel: true,
        relatedClients: 25
      },
      {
        name: "Tax Liability",
        hasCustomModel: true,
        relatedClients: 18
      },
      {
        name: "Tax Management",
        hasCustomModel: true,
        relatedClients: 22
      },
      {
        name: "Investment Tax Optimization",
        hasCustomModel: true,
        relatedClients: 16
      },
      {
        name: "Estate Tax Planning & Wealth Transfer",
        hasCustomModel: true,
        relatedClients: 12
      }
    ]
  },
  {
    category: "Emergency & Contingency Planning",
    scenarios: [
      {
        name: "Emergency Fund Planning",
        hasCustomModel: true,
        relatedClients: 28
      },
      {
        name: "Job Loss / Income Protection Strategies",
        hasCustomModel: true,
        relatedClients: 15
      },
      {
        name: "Climate Change Impact on Financials",
        hasCustomModel: false,
        relatedClients: 8
      },
      {
        name: "Industry Specific Impact on Financials",
        hasCustomModel: false,
        relatedClients: 10
      }
    ]
  }
]; 