import React, { useState } from 'react';

import Modal from 'components/Modal/Modal';
import useFinancialProducts from 'data/financialProducts/useFinancialProducts';

import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import FinancialProductInfoListTemplate from './FinancialInfoTemplate';
import { FinancialProductInfo } from './info';
import { productIcons } from './ProductIcons';

export default function FinancialProductsModal({product="Savings Account"}) {
    const [modal,setModal] = useState(false)
    const financialProductSel = FinancialProductInfo.filter(obj=>obj.heading===product)[0]
    const productType = financialProductSel?.productName
    const {data} = useFinancialProducts({productType:productType});
   
  return (
    <>
     <Button
      onClick={()=>setModal(true)}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        background: "#ffffff",
        boxShadow: "0 2px 8px rgba(0,0,0,0.04)",
        borderRadius: "12px",
        padding: "1.25rem",
        minWidth: "240px",
        border: "1px solid #f0f0f0",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          background: "#ffffff",
          boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
          transform: "translateY(-2px)",
          borderColor: "#e0e0e0"
        }
      }}
    >   
      <Box sx={{ 
        display: "flex", 
        gap: "0.75rem", 
        alignItems: "center",
        width: "100%"
      }}>
        <Box sx={{ 
          color: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          {productIcons[product]}
        </Box>
        <Typography 
          sx={{ 
            fontSize: "1rem",
            fontWeight: 500,
            color: "text.primary"
          }}
        >
          {product}
        </Typography>
      </Box>
      <ImageList data={data}/>
    </Button>
    {modal && 
    <Modal onClose={()=>setModal(false)} width='90%' height='80%' sx={{borderRadius:"5px"}}>
        <div style={{height:"100%",width:"100%",overflow:"auto",position:"relative"}}>
        {data && <FinancialProductInfoListTemplate 
       productGeneralInfo={financialProductSel} data={data}/>}
       </div>
    </Modal>}
    </>
  )
}

const ImageList = ({ data }) => {
  const maxImages = 3;
  if (!data || data.length === 0) return null;
  return (
    <Box sx={{ 
      display: "flex", 
      alignItems: "center", 
      position: "relative",
      width: "100%"
    }}>
      {data.slice(0, maxImages).map((obj, index) => (
        <Box
          key={index}
          sx={{
            width: "50px",
            height: "30px",
            borderRadius: "6px",
            overflow: "hidden",
            position: "relative",
            zIndex: maxImages - index,
            marginLeft: index > 0 ? "-8px" : "0",
            border: "2px solid #ffffff",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "translateY(-2px)"
            }
          }}
        >
          <img
            src={obj?.["Image URL"]}
            alt="image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain"
            }}
          />
        </Box>
      ))}
      {data.length > maxImages && (
        <Box
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#f5f5f5",
            color: "#666",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: 500,
            fontSize: "0.75rem",
            marginLeft: "-8px",
            zIndex: 10,
            border: "2px solid #ffffff",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
          }}
        >
          +{data.length - maxImages}
        </Box>
      )}
    </Box>
  );
};
