import './CompanyProfile.css';

import React from 'react';

import { useAppInfo } from 'AppState';
import TimeSeriesChart from 'components/Charts/TimeSeriesChart';
import LoadingSkeletonLine from 'components/Loading/LoadingSkeletonLine';
import TableGen from 'components/Table/TableGen';
import { dfin } from 'content/constants';
import useCompanyNews from 'data/company/useCompanyNews';
import usePriceChartEod from 'data/eod/usePriceChartEod';
import { useNavigate } from 'react-router-dom';
import {
  formatNumber,
  getSpecificArrayObjKeys,
  numberDecimal,
} from 'utils/utilGeneral';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Button } from '@mui/material';

import CompanyComments from './CompanyComments';
import CompanyESGScoreBar from './CompanyESGScoreBar';
import CompanyFinancialScore from './CompanyFinancialScore';
import CompanyTechnicalScore from './CompanyTechnicalScore';
import CompanyValueScore from './CompanyValueScore';

export default function CompanyProfile() {
  const { stateTicker, stateStock,dispatchIndustry,dispatchTicker,dispatchMarket} = useAppInfo();
  
  const navigate = useNavigate()
  const {tickerStock} = stateTicker
  const {coInfo} = stateStock;
  const { priceInfo, loadingPriceInfo } = usePriceChartEod({
    tickerSel: stateTicker.tickerStockCode,
  });

  const { newsCo, newCoLoading } = useCompanyNews(stateStock.companySelected);


  const primaryInfo = [
    { name: "Ticker", value: coInfo?.[dfin.ticker] },
    { name: "Name", value: coInfo?.[dfin.coName] },
    { name: "Website", value: coInfo?.[dfin.coWebsite] },
    { name: "Exchange", value: coInfo?.[dfin.exchange] },
    { name: "Country", value: coInfo?.[dfin.country] },
    { name: "Sector", value: coInfo?.[dfin.gicSector] },
    { name: "Industry", value: coInfo?.[dfin.gicIndustry] },
    { name: "Market Cap", value: formatNumber(coInfo?.[dfin.marketCap]) },
    { name: "Market Scale", value: coInfo?.[dfin.mScale] },
    { name: "PE", value: numberDecimal(coInfo?.[dfin.pe]) },
    { name: "Dividend Yield", value: `${numberDecimal(coInfo?.[dfin.dividendYield])}%` },
    { name: "Beta", value: numberDecimal(coInfo?.[dfin.beta]) },
    { name: "Net Profit Margin", value: `${numberDecimal(coInfo?.[dfin.netProfitMargin]) * 100}%` },
    { name: "Net Profit", value: formatNumber(coInfo?.[dfin.netIncome]) },
    { name: "Ebitda", value: formatNumber(coInfo?.[dfin.ebitda]) },
    { name: "ROIC", value: `${numberDecimal(numberDecimal(coInfo?.[dfin.roic])) * 100}%` },
    // Add more metrics here as needed
];
  function handleLinkInternal(n, v) {
    let industrySel = coInfo?.[dfin.gicIndustry];
    let sectorSel = coInfo?.[dfin.gicSector];
    let indSecMode = false
    if (n === "Industry") {
      indSecMode =true
    }
    dispatchIndustry({
      industrySel: [industrySel],
      sectorSel: [sectorSel],
      menuSel: "Performance",
      countrySel: [coInfo?.[dfin.country]],
      indSecMode: indSecMode
    });
    navigate("/Analytics/Industry");
  }
  function handleNews() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "News & Events",
      subMenuSel: "News"
    });
  }
  
  function handleStatsIndustry() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "Stats",
      subMenuSel: "vsIndustry"
    });
  }
  
  function handleStatsPeers() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "Stats",
      subMenuSel: "vs Peers"
    });
  }
  
  function handleChart() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "Charts",
      subMenuSel: "Techncial Chart"
    });
  }
  
  function handleAssetClass() {
    navigate("/Analytics/Market");
    dispatchMarket({ assetClassSel: "Stocks" });
  }
  
  function handlePeers() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "Peers",
      subMenuSel: "Peer Profile"
    });
  }

  return (

      
      <div
        style={{
          flex: 1,
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "0.5rem",
          boxSizing: "border-box",
          flexWrap:"wrap",padding:"0.25rem"
        }}
      >
        <div
          style={{
            borderRadius: "5px",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <>
          <div style={{display:"flex",flexDirection:"column",width:"100%",minHeight:"300px"
          ,height:"300px",maxHeight:"300px"
          ,boxSizing:"border-box",borderRadius:"5px",position:"relative"}}>
          <div style={{flex:1,width:"100%"}}>
          {!priceInfo.length > 0 && loadingPriceInfo && <LoadingSkeletonLine variant="rectangle" width="100%" height="100%"/>}
          {priceInfo.length > 0 && !loadingPriceInfo && (
              <TimeSeriesChart
                datePicker={false}
                gradient={true}
                grid={false}
                chartType="area"
                seriesName="adjusted_close"
                data={getSpecificArrayObjKeys(priceInfo, [
                  "date",
                  "adjusted_close",
                ])}
              />
            )}
          </div>
          <div style={{float:"right",marginLeft:"auto",position:"absolute",right:0}}><Button sx={{minWidth:"0"}} onClick={handleChart}><FullscreenIcon/></Button></div>
          </div>
          <>
          
        </>
       
        </>

        
        </div>
        <div style={{width:"100%",display:"flex",gap:"0.5rem",boxSizing:"border-box"}}>
        <div style={{ width: "50%", position: "relative",backgroundColor:"#fafafa",borderRadius:"5px",padding:"0.5rem"}}>
        
        <TableGen 
        sx={{backgroundColor:"#fafafa"}}
        tHead={false} tableLayout="auto" data={primaryInfo} pagination={false}/>
        
      </div>
             <div
          style={{
            minWidth:"40%",
            flex:1,
            flexDirection: "column",
            display: "flex",
            gap: "0.25rem",
            boxSizing: "border-box",
          }}
        >

<div
          style={{
            display: "flex",
            flexDirection:"column",
            maxWidth: "100%",
            gap: "0.5rem",
            boxSizing: "border-box",
          }}
        >
          <CompanyComments tickerList={[tickerStock]}/>
          <CompanyFinancialScore coInfo={coInfo}/>
          <CompanyTechnicalScore coInfo={coInfo}/>
          <CompanyValueScore coInfo={coInfo}/>
          <CompanyESGScoreBar coInfo={coInfo}/>
        </div>
 
      <div style={{display:"flex",flexDirection:"column"
      ,alignItems:"flex-start",width:"100%",
      borderRadius: "5px",
      padding: "0.25rem",
      backgroundColor: "#fafafa",
      // border: "2px solid #F8F8F8",
      boxSizing:"border-box",}}>
         {/* <Button sx={{width:"100%"}} onClick={handleStatsIndustry}>Stats vs Industry <ArrowOutwardIcon style={{ marginLeft: 'auto' }}/></Button>
<Button sx={{width:"100%"}} onClick={handleStatsPeers}>Stats vs Peers <ArrowOutwardIcon style={{ marginLeft: 'auto' }}/></Button> */}
<Button sx={{width:"100%"}} onClick={handlePeers}>{coInfo?.[dfin.ticker]} Peers <ArrowOutwardIcon fontSize="small" style={{ marginLeft: 'auto' }}/></Button>
<Button sx={{width:"100%"}} onClick={() => handleLinkInternal("Industry")}>{coInfo?.[dfin.ticker]} Industry <ArrowOutwardIcon fontSize="small" style={{ marginLeft: 'auto' }}/></Button>
<Button sx={{width:"100%"}} onClick={() => handleLinkInternal("Sector")}>{coInfo?.[dfin.ticker]} Sector <ArrowOutwardIcon fontSize="small" style={{ marginLeft: 'auto' }}/></Button>
<Button sx={{width:"100%"}} onClick={handleAssetClass}>{coInfo?.[dfin.ticker]} Asset Class: Stocks <ArrowOutwardIcon fontSize="small" style={{ marginLeft: 'auto' }}/></Button>
        </div>
 
          <div
          style={{
            width: "100%",
            borderRadius: "5px",
            padding: "0.25rem",
            backgroundColor: "#fafafa",
            boxSizing:"border-box",
           flex:1,
           overflow:"auto"
          }}
        >
          
            <Button onClick={handleNews} sx={{width:"100%"}}>
            News/Events
            <ArrowOutwardIcon  style={{ marginLeft: 'auto' }}/></Button>
          {!newCoLoading &&
            newsCo.map((obj) => (
              <div key={obj?.content.title} style={{ padding: "0.5rem" }}>
                <a
                  style={{ textDecoration: "none" }}
                  key={obj?.content.title}
                  href={obj?.content?.canonicalUrl?.url || obj?.content?.clickThroughUrl?.url}
                  target="blank"
                >
                  {obj?.content?.title}
                </a>
              </div>
            ))}
        </div>
        </div>
        </div>


        
      </div>
  );
}
