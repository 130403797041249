
import React, { useEffect, useRef, useState } from 'react'
import HomeFooterBig from 'home/HomeFooterBig'
import Features from 'home/product/Features'


export default function Product() {

return (

  <div style={{width: "100%", 
  background: "white",display:"flex",
  flexDirection:"column",justifyContent:"center",minHeight:"100vh",boxSizing:"border-box"}}>
    <Features/>
    <HomeFooterBig/>
    </div>
)

}