export const ADVISOR_SERVICES = [
    'Retirement Planning',
    'Estate Planning',
    'Tax Planning',
    'Investment Management',
    'Wealth Transfer',
    'Business Succession Planning',
    'Insurance Planning',
    'Education Planning'
];

export const prospectData = [
    {
        id: 1,
        name: 'Michael Anderson',
        occupation: 'Tech Executive',
        company: 'Silicon Innovations',
        netWorth: 2500000,
        location: 'San Francisco Bay Area',
        region: 'West Coast',
        investmentInterests: ['Technology', 'Sustainable Energy'],
        advisorServices: ['Investment Management', 'Tax Planning', 'Estate Planning'],
        familyStatus: 'Married, 2 children',
        age: 42,
        potentialAUM: 1800000,
        riskProfile: 'Moderate-Aggressive',
        categories: ['Top Prospects', 'In Your Region']
    },
    {
        id: 2,
        name: 'Elena Rodriguez',
        occupation: 'Medical Specialist',
        company: 'Stanford Medical Center',
        netWorth: 3200000,
        location: 'Palo Alto, CA',
        region: 'West Coast',
        investmentInterests: ['Healthcare', 'Real Estate'],
        advisorServices: ['Retirement Planning', 'Investment Management', 'Insurance Planning'],
        familyStatus: 'Single',
        age: 38,
        potentialAUM: 2400000,
        riskProfile: 'Moderate',
        categories: ['High Net Worth', 'In Your Region']
    },
    {
        id: 3,
        name: 'James Wilson',
        occupation: 'Business Owner',
        company: 'Wilson Manufacturing',
        netWorth: 5800000,
        location: 'Chicago, IL',
        region: 'Midwest',
        investmentInterests: ['Industrial', 'Commercial Real Estate'],
        advisorServices: ['Business Succession Planning', 'Estate Planning', 'Tax Planning'],
        familyStatus: 'Married, 3 children',
        age: 51,
        potentialAUM: 4200000,
        riskProfile: 'Conservative',
        categories: ['Top Prospects', 'Business Owners', 'High Net Worth']
    },
    {
        id: 4,
        name: 'Sarah Chen',
        occupation: 'Legal Partner',
        company: 'Global Law Associates',
        netWorth: 4100000,
        location: 'New York, NY',
        region: 'East Coast',
        investmentInterests: ['Finance', 'International Markets'],
        advisorServices: ['Tax Planning', 'Estate Planning', 'Wealth Transfer'],
        familyStatus: 'Married, 1 child',
        age: 45,
        potentialAUM: 3000000,
        riskProfile: 'Moderate',
        categories: ['High Net Worth', 'Business Owners']
    },
    {
        id: 5,
        name: 'Robert Taylor',
        occupation: 'Serial Entrepreneur',
        company: 'Multiple Ventures',
        netWorth: 7500000,
        location: 'Austin, TX',
        region: 'South',
        investmentInterests: ['Startups', 'Crypto', 'Tech'],
        advisorServices: ['Investment Management', 'Tax Planning', 'Business Succession Planning'],
        familyStatus: 'Divorced',
        age: 47,
        potentialAUM: 5500000,
        riskProfile: 'Aggressive',
        categories: ['Top Prospects', 'Business Owners', 'High Net Worth']
    },
    {
        id: 6,
        name: 'Jennifer Lee',
        occupation: 'Tech Founder',
        company: 'InnovateTech Solutions',
        netWorth: 6200000,
        location: 'Seattle, WA',
        region: 'West Coast',
        investmentInterests: ['AI', 'Tech Startups', 'Sustainable Energy'],
        advisorServices: ['Investment Management', 'Business Succession Planning', 'Tax Planning'],
        familyStatus: 'Single',
        age: 35,
        potentialAUM: 4800000,
        riskProfile: 'Aggressive',
        categories: ['Recently Added', 'Business Owners', 'High Net Worth']
    },
    {
        id: 7,
        name: 'David Martinez',
        occupation: 'Real Estate Developer',
        company: 'Martinez Properties',
        netWorth: 4800000,
        location: 'Miami, FL',
        region: 'South',
        investmentInterests: ['Real Estate', 'Commercial Development'],
        advisorServices: ['Investment Management', 'Estate Planning', 'Tax Planning'],
        familyStatus: 'Married',
        age: 49,
        potentialAUM: 3500000,
        riskProfile: 'Moderate',
        categories: ['Business Owners', 'In Your Region']
    },
    {
        id: 8,
        name: 'Lisa Thompson',
        occupation: 'Healthcare Executive',
        company: 'MedTech Innovations',
        netWorth: 3800000,
        location: 'Boston, MA',
        region: 'East Coast',
        investmentInterests: ['Healthcare', 'Biotech', 'Innovation'],
        advisorServices: ['Retirement Planning', 'Insurance Planning', 'Investment Management'],
        familyStatus: 'Married, 2 children',
        age: 44,
        potentialAUM: 2800000,
        riskProfile: 'Moderate-Conservative',
        categories: ['Recently Added', 'High Net Worth']
    },
    {
        id: 9,
        name: 'Kevin O\'Brien',
        occupation: 'Investment Banker',
        company: 'Global Finance Corp',
        netWorth: 8500000,
        location: 'New York, NY',
        region: 'East Coast',
        investmentInterests: ['Finance', 'Global Markets', 'Tech'],
        advisorServices: ['Investment Management', 'Wealth Transfer', 'Estate Planning'],
        familyStatus: 'Married, 1 child',
        age: 41,
        potentialAUM: 6500000,
        riskProfile: 'Aggressive',
        categories: ['Top Prospects', 'High Net Worth']
    },
    {
        id: 10,
        name: 'Maria Garcia',
        occupation: 'Restaurant Chain Owner',
        company: 'Garcia Restaurant Group',
        netWorth: 3100000,
        location: 'Houston, TX',
        region: 'South',
        investmentInterests: ['Food Industry', 'Real Estate', 'Franchising'],
        advisorServices: ['Business Succession Planning', 'Estate Planning', 'Education Planning'],
        familyStatus: 'Married, 3 children',
        age: 46,
        potentialAUM: 2200000,
        riskProfile: 'Conservative',
        categories: ['Business Owners', 'In Your Region']
    },
    {
        id: 11,
        name: 'Thomas Wright',
        occupation: 'Software Company CEO',
        company: 'Wright Software Solutions',
        netWorth: 5500000,
        location: 'Portland, OR',
        region: 'West Coast',
        investmentInterests: ['Technology', 'Startups', 'Cybersecurity'],
        advisorServices: ['Investment Management', 'Tax Planning', 'Business Succession Planning'],
        familyStatus: 'Single',
        age: 39,
        potentialAUM: 4100000,
        riskProfile: 'Aggressive',
        categories: ['Recently Added', 'Business Owners', 'High Net Worth']
    },
    {
        id: 12,
        name: 'Emily Chang',
        occupation: 'Biotech Founder',
        company: 'BioInnovate Labs',
        netWorth: 4200000,
        location: 'San Diego, CA',
        region: 'West Coast',
        investmentInterests: ['Biotech', 'Healthcare', 'Research'],
        advisorServices: ['Investment Management', 'Insurance Planning', 'Tax Planning'],
        familyStatus: 'Married',
        age: 37,
        potentialAUM: 3100000,
        riskProfile: 'Moderate',
        categories: ['Top Prospects', 'Business Owners']
    },
    {
        id: 13,
        name: 'Richard Foster',
        occupation: 'Manufacturing Executive',
        company: 'Foster Industries',
        netWorth: 6800000,
        location: 'Detroit, MI',
        region: 'Midwest',
        investmentInterests: ['Manufacturing', 'Automation', 'Real Estate'],
        advisorServices: ['Estate Planning', 'Business Succession Planning', 'Retirement Planning'],
        familyStatus: 'Married, 2 children',
        age: 52,
        potentialAUM: 5100000,
        riskProfile: 'Conservative',
        categories: ['High Net Worth', 'Business Owners']
    },
    {
        id: 14,
        name: 'Amanda White',
        occupation: 'E-commerce Founder',
        company: 'White Retail Group',
        netWorth: 3600000,
        location: 'Denver, CO',
        region: 'West',
        investmentInterests: ['E-commerce', 'Digital Marketing', 'Tech'],
        advisorServices: ['Investment Management', 'Tax Planning', 'Business Succession Planning'],
        familyStatus: 'Single',
        age: 36,
        potentialAUM: 2700000,
        riskProfile: 'Moderate-Aggressive',
        categories: ['Recently Added', 'Business Owners']
    },
    {
        id: 15,
        name: 'Daniel Kim',
        occupation: 'Venture Capitalist',
        company: 'Kim Ventures',
        netWorth: 9200000,
        location: 'San Francisco, CA',
        region: 'West Coast',
        investmentInterests: ['Startups', 'Tech', 'Innovation'],
        advisorServices: ['Investment Management', 'Wealth Transfer', 'Tax Planning'],
        familyStatus: 'Married, 1 child',
        age: 43,
        potentialAUM: 7000000,
        riskProfile: 'Aggressive',
        categories: ['Top Prospects', 'High Net Worth', 'In Your Region']
    },
    {
        id: 16,
        name: 'Rachel Green',
        occupation: 'Fashion Brand Owner',
        company: 'Green Fashion House',
        netWorth: 2800000,
        location: 'Los Angeles, CA',
        region: 'West Coast',
        investmentInterests: ['Fashion', 'Retail', 'E-commerce'],
        advisorServices: ['Business Succession Planning', 'Investment Management', 'Insurance Planning'],
        familyStatus: 'Single',
        age: 34,
        potentialAUM: 2000000,
        riskProfile: 'Moderate',
        categories: ['Recently Added', 'Business Owners', 'In Your Region']
    },
    {
        id: 17,
        name: 'Mark Johnson',
        occupation: 'Construction Company Owner',
        company: 'Johnson Construction',
        netWorth: 5100000,
        location: 'Phoenix, AZ',
        region: 'West',
        investmentInterests: ['Real Estate', 'Construction', 'Development'],
        advisorServices: ['Estate Planning', 'Business Succession Planning', 'Tax Planning'],
        familyStatus: 'Married, 3 children',
        age: 48,
        potentialAUM: 3800000,
        riskProfile: 'Conservative',
        categories: ['Business Owners', 'High Net Worth']
    },
    {
        id: 18,
        name: 'Sophie Turner',
        occupation: 'Marketing Agency CEO',
        company: 'Turner Digital',
        netWorth: 3300000,
        location: 'Chicago, IL',
        region: 'Midwest',
        investmentInterests: ['Digital Marketing', 'Tech', 'Media'],
        advisorServices: ['Business Succession Planning', 'Investment Management', 'Tax Planning'],
        familyStatus: 'Married',
        age: 40,
        potentialAUM: 2400000,
        riskProfile: 'Moderate-Aggressive',
        categories: ['Recently Added', 'Business Owners', 'In Your Region']
    },
    {
        id: 19,
        name: 'William Chen',
        occupation: 'Tech Investor',
        company: 'Chen Capital',
        netWorth: 7800000,
        location: 'Seattle, WA',
        region: 'West Coast',
        investmentInterests: ['Technology', 'AI', 'Startups'],
        advisorServices: ['Investment Management', 'Wealth Transfer', 'Tax Planning'],
        familyStatus: 'Married, 1 child',
        age: 45,
        potentialAUM: 5900000,
        riskProfile: 'Aggressive',
        categories: ['Top Prospects', 'High Net Worth', 'In Your Region']
    },
    {
        id: 20,
        name: 'Isabella Martinez',
        occupation: 'Healthcare Tech Founder',
        company: 'HealthTech Solutions',
        netWorth: 4500000,
        location: 'Austin, TX',
        region: 'South',
        investmentInterests: ['Healthcare', 'Technology', 'Innovation'],
        advisorServices: ['Investment Management', 'Business Succession Planning', 'Insurance Planning'],
        familyStatus: 'Single',
        age: 36,
        potentialAUM: 3400000,
        riskProfile: 'Moderate',
        categories: ['Recently Added', 'Business Owners', 'High Net Worth']
    }
]; 