import { useAppInfo } from 'AppState';
import { openaiAPI } from 'appState/initialStateAssistant';
import { clientsData } from 'b2b/Clients/clientsData';
import { prospectData } from 'b2b/Prospects/prospectData';
import { pulseData } from 'b2b/Pulse/pulseData';
import { parse } from 'partial-json';

export default function useTopQuestionGenerator() {
    const {stateAssistant,dispatchAssistant,stateMyFinancials,stateApp} = useAppInfo()
    const {userRole} = stateApp.onboarding

    function GetMyFinancials() {
        const {currency,summaryStats,assets,liabilities,incomeMonthly,expenseMonthly,financialHealthIssues,
            financialWellnessScore,goalsPeriodic,financialView,financialsUpdated} = stateMyFinancials
        const info = {currency,summaryStats,goalsPeriodic,assets,liabilities,
          incomeMonthly,expenseMonthly,financialHealthIssues,
          financialWellnessScore,}
        return info
      }

      function GetAdvisorBusinessDetails(){
        const info = {pulseData,clientsData,prospectData}
        return  JSON.stringify(info)
      }

      async function* fetchTopQuestions(retryCount = 3) {
        const info = GetMyFinancials();
        const jsonInfo = userRole === "consumer" ? JSON.stringify(info) : GetAdvisorBusinessDetails();
        const messageRefined = "Give me top 4 questions and use real numbers in the questions from the financial data provided to you! " + jsonInfo;
        const systemInstructions = userRole === "consumer" ? systemInstructionsConsumer : systemInstructionsAdvisor;
        let attempt = 0;
        let finalResponse = "";
      
        while (attempt < retryCount) {
          try {
            finalResponse = "";
            // Start the API call
            const completion = await openaiAPI.chat.completions.create({
              messages: [
                { role: "system", content: systemInstructions },
                { role: "user", content: messageRefined }
              ],
              model: "gpt-4o",
              response_format: responseFormat,
              stream: true,
            });
      
            // Process each chunk of the streaming response
            for await (const chunk of completion) {
              const content = chunk.choices[0].delta.content;
              if (content) {
                finalResponse += content;
                // Parse the accumulated response to extract questions
                const topQuestions = parse(finalResponse)?.questions;
                // Yield an object with the current response and an empty error message
                yield { response: topQuestions || "", error: "" };
              }
            }
      
           
            
            // Successfully received a response; break out of the retry loop.
            break;
          } catch (error) {
            attempt++;
            
            // If we've reached the maximum number of attempts, yield an error object.
            if (attempt >= retryCount) {
              yield { response: "", error: "Failed to fetch top questions after multiple attempts." };
              return;
            }
            // Wait before trying again (you can adjust the delay or implement exponential backoff if needed)
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
      }
      

    return {fetchTopQuestions}
    
}

const tools = {
    "name": "get_users_financials",
    "description": "Call this to know about the users financials.",
    "strict": false,
    "parameters": {
      "type": "object",
      "properties": {},
      "required": []
    }
  }

const systemInstructionsConsumer = `You will provide the top questions to the user must ask to improve or grow their financials based on the data provided to you in get_users_financials functions. Use real numbers in the questions you provide - like amount of expense, income, assets, liabilities, investments, etc..`
const systemInstructionsAdvisor = `You will provide the top questions to the user who is a financial advisor. You will provide questions that will help the user (financial advisor) to grow their business (get more clients, more assets under management, more revenue, etc). Give questions with real names of clients, prospects and real events and real numbers. And add a list of names and say xyz more clients affected.`

const responseFormat = { "type": "json_schema","json_schema":{
    "name": "top_questions",
    "strict": false,
    "schema": {
      "type": "object",
      "properties": {
        "questions": {
          "type": "array",
          "description": "A list of top questions that users must ask.",
          "items": {
            "type": "object",
            "properties": {
              "emoji": {
                "type": "string",
                "description": "Provide a relavant emoji"
              },
              "question": {
                "type": "string",
                "description": "The actual question to be asked by the user. It should include real numbers from the data. And maximum 20 words."
              },
              "category": {
                "type": "string",
                "description": "The category or topic of the question."
              },
              "importance": {
                "type": "string",
                "description": "The priority level of the question (e.g., high, medium, low)."
              }
            },
            "required": [
              "question",
              "emoji",
              "category",
              "importance"
            ],
            "additionalProperties": false
          }
        }
      },
      "required": [
        "questions"
      ],
      "additionalProperties": false
    }
  }}
