import React, { useCallback, useEffect, useState } from 'react'
import { Button, IconButton } from '@mui/material';
import TextInput from 'components/Inputs/TextInput';
import { debounce, set } from 'lodash';
import useAddFinancialVault from 'data/user/financialVault/useAddFinancialVault';
import { useAppInfo } from 'AppState';
import { generateUniqueId } from 'utils/utilGeneral';
import { Delete } from '@mui/icons-material';


export function FinancialVaultNomineeDetails(){
    const {stateMyFinancials,dispatchMyFinancials,stateApp} = useAppInfo();
    const {vaultNominee,vaultLoaded,financialView,demoProfile} = stateMyFinancials
    const { addFinancialVault, error } = useAddFinancialVault()
    const [identityDetails,setIdentityDetails] = useState([])
    const [change,setChange] = useState(false)

    useEffect(()=>{
      if (financialView === "personal") {
      
        if (vaultNominee.length > 0 && vaultLoaded) {
            setIdentityDetails(vaultNominee)
        }
        if (vaultLoaded && vaultNominee.length === 0) {
            // console.log("Being Added")
            const id = generateUniqueId()
            setIdentityDetails([{id,name:"",surname:"",email:"",mobile:""}])
            addFinancialVault({dataName:"vaultNominee",data:[{id,name:"",surname:"",email:"",mobile:""}]});
        }
      }
      else {
        setIdentityDetails([{name:`${demoProfile} father`,demoProfile,email:`father@demo.com`,mobile:"+1 (123) 456-7890"}])
      }
    },[vaultLoaded])

    
   
    const debouncedAddFinancialVault = useCallback(
        debounce((payload) => {
          addFinancialVault(payload);
        }, 1000), // Adjust debounce delay as needed
        []
      );
      useEffect(() => {
        if (Object.keys(change).length > 0) {

          debouncedAddFinancialVault(change);
  
          return () => {
            setChange({});
          };
        }
      }, [change, debouncedAddFinancialVault]);

      const handleChange = (fieldName, value,index,id) => {
       
        setIdentityDetails((prevItems) =>{
         let updateIdentity = prevItems.map((item,i) =>
            i === index ? { ...item, [fieldName]: value } : item
          )
          dispatchMyFinancials({vaultNominee:updateIdentity})
          setChange({dataId:id,dataName:"vaultNominee",dataKey:fieldName,data:updateIdentity})
          return updateIdentity
         
        }
        );
      };

      function handleDelete(id){
        setIdentityDetails((prevItems) =>{
          let updateIdentity = prevItems.filter((item,i) =>item?.id !== id)
          
          if (updateIdentity.length === 0){
            updateIdentity = [{id,name:"",surname:"",email:"",mobile:""}]
           }
            dispatchMyFinancials({vaultNominee:updateIdentity})
           setChange({dataId:id,dataName:"vaultNominee",data:updateIdentity})
          
           return updateIdentity
          
         }
         );
      }

    return (
        <div style={{display:"flex",flexDirection:"column",gap:"3rem",width:"100%"}}>
        <div>
        <h3>Nominee</h3>
        <h4>Adding a nominee ensures your financial assets are smoothly transferred to your chosen beneficiary in case of unforeseen events.</h4>
        </div>
        <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",width:"100%"}}>
        {identityDetails.map((identity,index)=>
        <React.Fragment key={index}>
        <TextInput disabled={financialView !== "personal"}  sx={{width:"250px"}}  label='Name' placeholder={"Nominee Legal Name"} 
        value={identity?.name || ""}
        onChange={(value)=>handleChange("name",value,index,identity?.id)}/>
         <TextInput disabled={financialView !== "personal"} sx={{width:"250px"}}  label='Surname' placeholder={"Nominee Legal Surname"} 
        value={identity?.surname || ""}
        onChange={(value)=>handleChange("surname",value,index,identity?.id)}/>
        
        
         <TextInput disabled={financialView !== "personal"} sx={{width:"250px"}} label='Email' placeholder={"Email"}
         type='email' 
        value={identity?.email || ""}
        onChange={(value)=>handleChange("email",value,index,identity?.id)}/>
      
     
        <TextInput disabled={financialView !== "personal"} required={false} label='Mobile' sx={{width:"250px"}} placeholder={"Mobile Number"}
         type='phone' 
        value={identity?.mobile || ""}
        onChange={(value)=>handleChange("mobile",value,index,identity?.id)}/>
        {identityDetails.length > 1 && 
        <Delete onClick={()=>handleDelete(identity?.id)}/>}
        </React.Fragment>)}
        </div>
        
        <Button disabled={financialView !== "personal"} variant='contained' sx={{width:"max-content"}} 
        onClick={()=>setIdentityDetails([...identityDetails,{id:generateUniqueId(),name:"",surname:"",email:"",mobile:""}])}>+ Add Nominee</Button>
       
        </div>
    )
}
