import { useAppInfo } from 'AppState';
import useAddFinancialVault from 'data/user/financialVault/useAddFinancialVault';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import { deleteMediaFile } from "components/Upload/processMedia";
import { useLocation } from 'react-router-dom';

const today = new Date()

export default function useHandleVaultUser() {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {vaultData} = stateMyFinancials
    const { addFinancialVault, error } = useAddFinancialVault()

    const handleDeleteSuccess = () => {
        // onUploadSuccess && onUploadSuccess({url,fileType:file.type});
      }

    const debouncedAddFinancialVault = useCallback(
        debounce((payload) => {
          addFinancialVault(payload);
        }, 1000), // Adjust debounce delay as needed
        []
      );
    
    
      // Clean up the debounce function on unmount
      useEffect(() => {
        return () => {
          debouncedAddFinancialVault.cancel();
        };
      }, [debouncedAddFinancialVault]);
    
    const handleChange = (fieldName, value,id) => {
        let updatedMediaItems;
        const vaultDataUpdate = vaultData.map(mediaItem => mediaItem.id === id ? {...mediaItem,[fieldName]:value} : mediaItem);
        // setMediaItems((prevItems) =>{
        //   updatedMediaItems = prevItems.map((item,i) =>
        //     i === index ? { ...item, [fieldName]: value } : item
        //   )
        //   dispatchMyFinancials({vaultData:updatedMediaItems})
        //   return updatedMediaItems
        // }
        // );

        dispatchMyFinancials({vaultData:vaultDataUpdate})
        
        debouncedAddFinancialVault({
          dataId: id,
          dataKey: fieldName,
          updateType: "update_by_key",
          data: value,
        });
      };

      function handleDelete(item) {
        const mediaItemUpdate = vaultData.filter(mediaItem => mediaItem.id !== item.id);
        // setMediaItems(mediaItemUpdate);
        dispatchMyFinancials({vaultData:mediaItemUpdate})
        deleteMediaFile({fileUrl:item.uploadedUrl,previewUrl:item.previewUrl,onSuccess:handleDeleteSuccess})
        addFinancialVault({data:mediaItemUpdate});
        // handleDeleteFile && handleDeleteFile(item.uploadedUrl)
      }
 
    return  {handleChange,handleDelete}
}


export function useHandleVaultUserUpload ({category,subCategory}) {
  const location = useLocation()
  const subPage = location.pathname.split('/')[2];
  const subPageName = decodeURIComponent(subPage)
  const {dispatchMyFinancials,stateMyFinancials}  = useAppInfo()
  const {vaultData,vaultLoaded} = stateMyFinancials
  const [mediaItems, setMediaItems] = useState([]);
  const [uploadedMediaItems, setUploadedMediaItems] = useState([]);

  const { addFinancialVault, error } = useAddFinancialVault()


  useEffect(() => {
      if (vaultData.length>0 && vaultLoaded) {
        setMediaItems(vaultData)
      }
    },[vaultLoaded])

  
  
  const handleFileData = (file) => {
      // Add new media item for each selected file with initial preview URL and type
   
      // if (!subPage) {
      // setTabIndex(1)}
      setMediaItems(prevItems => {
        let updatedMediaItems = [...prevItems,
        { 
          id: file?.id || file.name, // Unique identifier
          category: category,
          subCategory: subCategory,
          date:today,
          name: file.name,
          url: file?.url, 
          fileType: file.type,
        }
      ]; 
      dispatchMyFinancials({'vaultData':updatedMediaItems})
      return updatedMediaItems;});
      setUploadedMediaItems(prevItems => [
        ...prevItems,
        { 
          id: file?.id || file.name, // Unique identifier
          category: category,
          subCategory: subCategory,
          date:today,
          name: file.name,
          url: file?.url, 
          fileType: file.type,
        }
      ])
    };
  
    const handleProgress = (file) => {
      const progress = file.percent;
      setMediaItems(prevItems => {
        let updatedMediaItems = prevItems.map(item =>
          item.id === file.name ? { ...item, progress } : item
        ); 
        dispatchMyFinancials({'vaultData':updatedMediaItems})
        return updatedMediaItems;}
      );
  
      setUploadedMediaItems(prevItems =>
        prevItems.map(item =>
          item.id === file.name ? { ...item, progress } : item
        )
      );
    };
  
    const debouncedAddFinancialVault = useCallback(
      debounce((payload) => {
        addFinancialVault(payload);
      }, 1000), // Adjust debounce delay as needed
      []
    );
  
  
    // Clean up the debounce function on unmount
    useEffect(() => {
      return () => {
        debouncedAddFinancialVault.cancel();
      };
    }, [debouncedAddFinancialVault]);
  
    const handleUploadSuccess = (file) => {
      const url = file?.url;
    
      // Use a temporary variable to store updated items
      let updatedMediaItems;
      
      setMediaItems(prevItems => {
        updatedMediaItems = prevItems.map(item =>
          item.id === file?.name ? { ...item, url: url, progress: 100 } : item
        );
        if (updatedMediaItems && updatedMediaItems.length > 0) {
          addFinancialVault({data:updatedMediaItems});}
        return updatedMediaItems; // Return updated items to set the state
      })
      // console.log(updatedMediaItems,file)
      // if (updatedMediaItems && updatedMediaItems.length > 0) {
      // console.log("Add Vault",updatedMediaItems);
      // addFinancialVault({data:updatedMediaItems});}
      // Safely use updatedMediaItems here
    };
    const handleDeleteSuccess = () => {
      // onUploadSuccess && onUploadSuccess({url,fileType:file.type});
    }

    function handleDelete(item) {
      const mediaItemUpdate = mediaItems.filter(mediaItem => mediaItem.id !== item.id);
      setMediaItems(mediaItemUpdate);
      
      deleteMediaFile({fileUrl:item.uploadedUrl,previewUrl:item.previewUrl,onSuccess:handleDeleteSuccess})
      addFinancialVault({data:mediaItemUpdate});
      // handleDeleteFile && handleDeleteFile(item.uploadedUrl)
    }

    
const handleChange = (fieldName, value,index,id) => {
  let updatedMediaItems;
  setMediaItems((prevItems) =>{
    updatedMediaItems = prevItems.map((item,i) =>
      i === index ? { ...item, [fieldName]: value } : item
    )
    dispatchMyFinancials({vaultData:updatedMediaItems})
    return updatedMediaItems
  }
  );
  
  // console.log("Updated Media Items",updatedMediaItems)
  setUploadedMediaItems((prevItems) =>prevItems.map((item,i) =>
        i === index ? { ...item, [fieldName]: value } : item
      )
    );

  debouncedAddFinancialVault({
    dataId: id,
    dataKey: fieldName,
    updateType: "update_by_key",
    data: value,
  });
};
  
}