import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FinancialVaultIdentity from './FinancialVaultIdentity';
import FinancialVaultTaxInsurance from './FinancialVaultTaxInsurance';

import { FinancialVaultAssets, FinancialVaultExpenses, FinancialVaultIncome, FinancialVaultLiabilities } from './FinancialVaultFinancials';

export default function FinancialVaultCategories() {

  const info = {
    "Identity & Personal Docs":<FinancialVaultIdentity/>,
    "Assets":<FinancialVaultAssets/>,
    "Liabilities":<FinancialVaultLiabilities/>,
    "Incomes":<FinancialVaultIncome/>,
    "Expenses":<FinancialVaultExpenses/>,
    "Taxes, Estate & Insurance":<FinancialVaultTaxInsurance/>,
    "Charity & Business Interest":<FinancialVaultTaxInsurance/>,
    "Others":<FinancialVaultTaxInsurance/>,
  }
      const location = useLocation()
      const navigate = useNavigate()
      const subPage = location.pathname.split('/')[2];
      const subPageName = decodeURIComponent(subPage)

      
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"0.5rem"}}>
        <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
       <IconButton onClick={() => navigate("/MyVault")}><ArrowBackIosIcon/></IconButton>
       <h3>{subPageName}</h3>
 
         </div>
        {info[subPageName]}
        </div>
  )
}
