import { useEffect } from 'react';

import { useAppInfo } from 'AppState';

import useGoalDetails from '../Goals/useGoalDetails';
import useLiabilityAnalytics from '../Liability/useLiabilityAnalytics';
import {
  TotalIncomeExpense,
  TotalNetWorth,
  TotalSummaryDist,
} from '../Utils/Totals';
import {
  calculateAmortizationSchedule,
  calculateMonthlyNetCashFlow,
  calculateNetCashFlow,
  extrapolateCashflow,
} from '../Utils/utils';
import useCheckFinancialHealthNew from './useCheckFinancialHealthNew';
import { UpdateAssetWithPortfolio } from './useSetPortfolio';

export default function useMyFinancialsDetails () {
const {statePortfolio,stateMyFinancials,dispatchMyFinancials} = useAppInfo()
const {portfolioResults,portfolio,portfolioLoaded} = statePortfolio
const {assets,liabilities,incomeMonthly,expenseMonthly,summaryStats, 
  financialView,financials,demoProfile,assetUpdatedWithPortfolio,financialsUpdated} = stateMyFinancials


useEffect(()=>{
 
    if (!assetUpdatedWithPortfolio){
      return
    }
    // console.log(assetUpdatedWithPortfolio,"Asset Updated",assets)
    const incomeDist =  [...extrapolateCashflow({fields:incomeMonthly})]
    const expenseDist = [...extrapolateCashflow({fields:expenseMonthly})]
    const liabilityDist = liabilities.flatMap(obj => {
      const {amortizationSchedule}= calculateAmortizationSchedule(obj);
      return amortizationSchedule || [];
    });

    const assetDist = extrapolateCashflow({fields:assets,growthKey:"return",showVolatility:false})
    const assetReturnDist = extrapolateCashflow({fields:assets,growthKey:"return",amountType:"growth",showVolatility:false})
    const cashBalance = extrapolateCashflow({fields:assets.filter(asset=>asset?.type==="Cash")})
    const beginningCashBalanace = assets.filter(asset=>asset?.type==="Cash")[0]?.amount || 0
    const totalCashInflowDist = [...incomeDist,...assetReturnDist,...cashBalance]
    const totalCashInflowWithoutCashDist = [...incomeDist,...assetReturnDist]
    const totalCashOutflowDist = [...expenseDist,...liabilityDist]
    const networthDist = calculateMonthlyNetCashFlow(assetDist,liabilityDist,"Networth")
    const netCashflowDist = calculateNetCashFlow(totalCashInflowDist, totalCashOutflowDist);
    const netCashflowDistWithoutBeginningCash = calculateNetCashFlow(totalCashInflowWithoutCashDist, totalCashOutflowDist);
    const netCashflowBeforeAssetReturnDist = calculateNetCashFlow([...incomeDist,...cashBalance],totalCashOutflowDist)
    const netCashflowAssetWidthdrawalDist = calculateNetCashFlow([...incomeDist,...assetDist], totalCashOutflowDist);
    const totalAssets = assets.reduce((total, field) => {return total + (Number(field.amount) || 0);}, 0);
    const totalLiabilities = liabilities.reduce((total, field) => {return total + (field.amount || 0);}, 0);
    const netWorth = totalAssets - totalLiabilities;
    const totalAssetTypeAmounts = totalAmountCategory(assets,"type")
    const totalAssetName = totalAmountCategory(assets,"name")
    const totalLiabilityTypeAmounts = totalAmountCategory(liabilities)
    const totalLiabilityName = totalAmountCategory(liabilities,"name")
    const totalGroupedAssets = groupByType(assets)
    const totalGroupedLiabilities = groupByType(liabilities)
    const {totalIncome,totalExpense} = TotalIncomeExpense(incomeDist,expenseDist)
    const endingCashBalance = TotalSummaryDist(netCashflowDistWithoutBeginningCash,beginningCashBalanace)
    const totalNetCashflow = TotalSummaryDist(netCashflowDistWithoutBeginningCash)
    const totalNetCashflowBeforeReturns = TotalSummaryDist(netCashflowBeforeAssetReturnDist)
    const totalAssetReturnDist = TotalSummaryDist(assetReturnDist)
    
    const {totalAssetDist,totalLiabilityDist,totalNetworthDist} = TotalNetWorth({assetDist,liabilityDist})
    
    const cashflowDistribution = {
    incomeDist,
    expenseDist,
    netCashflowDist,
    netCashflowBeforeAssetReturnDist,
    netCashflowAssetWidthdrawalDist,
    assetDist,
    assetReturnDist,
    liabilityDist,
    networthDist,
    totalCashInflowDist,
    totalCashOutflowDist}
    

    const summary = {
      totalAssets,
      totalLiabilities
      ,netWorth:netWorth,
      totalIncome,totalExpense,totalNetCashflow,endingCashBalance,
      totalAssetTypes:totalAssetTypeAmounts,totalLiabilityTypes:totalLiabilityTypeAmounts,totalGroupedLiabilities
      ,totalAssetDist,totalAssetName,totalGroupedAssets
      ,totalLiabilityName,totalLiabilityDist,totalNetworthDist
      ,totalAssetReturnDist,totalNetCashflowBeforeReturns}
   
    if (assetUpdatedWithPortfolio) {
      dispatchMyFinancials({"summaryStats":summary,
        "cashflowDistribution":cashflowDistribution,financialsUpdated:true})  
    }

  
    
  },[assets,liabilities,incomeMonthly,expenseMonthly,assetUpdatedWithPortfolio])

useGoalDetails()

useLiabilityAnalytics()
  
useCheckFinancialHealthNew()

useEffect(() => {
  // console.log("PortfolioResults",portfolioResults)
  if (portfolioLoaded && portfolioResults) {
    const assetUpdate = UpdateAssetWithPortfolio({portfolioResults,assets})
    dispatchMyFinancials({ assets: assetUpdate,assetUpdatedWithPortfolio:true });
  }
  if (portfolioLoaded && Object.keys(portfolio).length === 0) {
    dispatchMyFinancials({ assetUpdatedWithPortfolio:true});
  }
}, [portfolioResults,financials,financialView,demoProfile,portfolio,portfolioLoaded]);

}



function totalAmountCategory(fields,key="type"){
  return fields.reduce((acc, item) => {
    acc[item[key]] = (Number(acc[item[key]]) || 0) + Number(item.amount);
    return acc;
  }, {});
}

function groupByType(assets) {
  const grouped = assets.reduce((acc, asset) => {
    if (!acc[asset.type]) {
      acc[asset.type] = {
        type: asset.type,
        totalAmount: 0,
        items: []
      };
    }
    acc[asset.type].items.push({ name: asset.name, amount: asset.amount });
    acc[asset.type].totalAmount += asset.amount;
    return acc;
  }, {});

  // Convert the object of grouped assets into an array
  return Object.values(grouped);
}
