import { menuIcons } from 'nav/menuIcons';

// Helper function to get a date relative to today
const getRelativeDate = (daysOffset, hours = 9) => {
  const date = new Date();
  date.setDate(date.getDate() + daysOffset);
  date.setHours(hours, 0, 0, 0); // Set to 9 AM by default
  return date.toISOString();
};

export const dummyInsights = {
  clientMetrics: {
    totalClients: 24,
    activeClients: 24,
    newClientsThisMonth: 0,
    clientRetentionRate: 100
  },
  prospectMetrics: {
    totalProspects: 20,
    highNetWorthProspects: 12, // prospects with netWorth > 5M
    potentialAUM: 85000000, // sum of potentialAUM from all prospects
    topProspects: 8 // prospects with potentialAUM > 5M
  },
  portfolioMetrics: {
    totalAUM: 65000000,
    averageClientPortfolio: 2708333,
    topPerformingPortfolios: 8,
    riskAdjustedReturn: 12.5
  },
  aiMetrics: {
    taskStats: {
      pending: {
        count: 12,
        details: "8 high priority, 4 medium priority"
      },
      inProcess: {
        count: 8,
        details: "5 client reviews, 3 portfolio updates"
      },
      completed: {
        count: 156,
        details: "Last 30 days"
      },
      errors: {
        count: 2,
        details: "1 data sync, 1 notification"
      }
    },
    todos: [
      // Today's Tasks
      {
        id: 1,
        event: "Portfolio Rebalancing Needed",
        client: "Emma Thompson",
        date: getRelativeDate(0, 10), // Today at 10 AM
        description: "Market volatility detected - recommended rebalancing for conservative portfolio",
        actions: ["Review portfolio", "Schedule meeting"],
        priority: "High",
        avatar: "ET"
      },
      {
        id: 2,
        event: "Tax Optimization Opportunity",
        client: "Michael Anderson",
        date: getRelativeDate(0, 14), // Today at 2 PM
        description: "New tax law changes affecting high-net-worth portfolio",
        actions: ["Update docs", "Notify client"],
        priority: "High",
        avatar: "MA"
      },
      {
        id: 3,
        event: "Retirement Planning Review",
        client: "Robert Taylor",
        date: getRelativeDate(0, 15), // Today at 3 PM
        description: "Annual retirement portfolio review for government official",
        actions: ["Review strategy", "Update projections"],
        priority: "Medium",
        avatar: "RT"
      },
      {
        id: 4,
        event: "Stock Option Review",
        client: "Benjamin Kim",
        date: getRelativeDate(0, 16), // Today at 4 PM
        description: "Review stock options for fintech founder",
        actions: ["Review options", "Update plan"],
        priority: "High",
        avatar: "BK"
      },
      // Tomorrow's Tasks
      {
        id: 5,
        event: "Estate Planning Update",
        client: "Nathan Wright",
        date: getRelativeDate(1, 9), // Tomorrow at 9 AM
        description: "Review and update estate planning documents for pharmaceutical executive",
        actions: ["Review docs", "Schedule meeting"],
        priority: "High",
        avatar: "NW"
      },
      {
        id: 6,
        event: "Property Investment Review",
        client: "Thomas Martinez",
        date: getRelativeDate(1, 11), // Tomorrow at 11 AM
        description: "Review real estate portfolio for developer",
        actions: ["Review properties", "Update strategy"],
        priority: "Medium",
        avatar: "TM"
      },
      {
        id: 7,
        event: "IPO Planning",
        client: "Alexandra Foster",
        date: getRelativeDate(1, 14), // Tomorrow at 2 PM
        description: "IPO preparation for biotech executive",
        actions: ["Review plan", "Update documents"],
        priority: "High",
        avatar: "AF"
      },
      // Day After Tasks
      {
        id: 8,
        event: "Business Succession Planning",
        client: "Victoria Patel",
        date: getRelativeDate(2, 10), // Day After at 10 AM
        description: "Business succession strategy review for manufacturing executive",
        actions: ["Review plan", "Update documents"],
        priority: "High",
        avatar: "VP"
      },
      {
        id: 9,
        event: "Healthcare Sector Review",
        client: "Olivia Martinez",
        date: getRelativeDate(2, 13), // Day After at 1 PM
        description: "Review healthcare sector exposure for healthcare executive",
        actions: ["Review exposure", "Update strategy"],
        priority: "Medium",
        avatar: "OM"
      },
      {
        id: 10,
        event: "Tech Sector Volatility Review",
        client: "David Kim",
        date: getRelativeDate(2, 15), // Day After at 3 PM
        description: "Review tech sector exposure for software engineer",
        actions: ["Analyze allocation", "Rebalance if needed"],
        priority: "Medium",
        avatar: "DK"
      }
    ]
  }
};

export const promptSuggestions = [
  // Client-Specific Actions
  {
    prompt: "Review Emma Thompson's portfolio rebalancing needs - conservative portfolio showing volatility",
    category: "Client Actions",
    icon: "👤"
  },
  {
    prompt: "Schedule estate planning review for Michael Anderson - new tax legislation affecting high-net-worth clients",
    category: "Client Actions",
    icon: "📋"
  },
  {
    prompt: "Prepare retirement planning review for Robert Taylor - government official portfolio review due",
    category: "Client Actions",
    icon: "🎯"
  },
  {
    prompt: "Review Benjamin Kim's stock options - fintech founder options review needed",
    category: "Client Actions",
    icon: "📈"
  },
  {
    prompt: "Update estate planning for Nathan Wright - pharmaceutical executive documents need review",
    category: "Client Actions",
    icon: "📄"
  },
  
  // Market Impact Analysis
  {
    prompt: "Analyze tech sector volatility impact on David Kim and James Wilson's portfolios",
    category: "Market Analysis",
    icon: "📊"
  },
  {
    prompt: "Review fixed income exposure for conservative portfolios after Fed rate decision",
    category: "Market Analysis",
    icon: "🏦"
  },
  {
    prompt: "Assess real estate market impact on Thomas Martinez and Sophie Chen's property investments",
    category: "Market Analysis",
    icon: "🏠"
  },
  {
    prompt: "Evaluate healthcare sector opportunities for Olivia Martinez and Sarah Chen",
    category: "Market Analysis",
    icon: "🏥"
  },
  {
    prompt: "Review ESG compliance requirements for Sophie Chen and Victoria Patel",
    category: "Market Analysis",
    icon: "🌱"
  },

  // Portfolio Management
  {
    prompt: "Generate portfolio rebalancing recommendations for clients with high tech exposure",
    category: "Portfolio",
    icon: "⚖️"
  },
  {
    prompt: "Review retirement portfolio allocations for clients aged 55+ (Michael Anderson, Robert Taylor, Nathan Wright)",
    category: "Portfolio",
    icon: "🎯"
  },
  {
    prompt: "Analyze fixed income holdings for conservative portfolios after recent rate changes",
    category: "Portfolio",
    icon: "📉"
  },
  {
    prompt: "Review real estate exposure across client portfolios",
    category: "Portfolio",
    icon: "🏠"
  },
  {
    prompt: "Generate sector concentration analysis for all client portfolios",
    category: "Portfolio",
    icon: "📊"
  },

  // Risk Assessment
  {
    prompt: "Identify clients with high concentration in tech stocks (David Kim, James Wilson, Benjamin Kim)",
    category: "Risk",
    icon: "⚠️"
  },
  {
    prompt: "Review estate planning risk for high-net-worth clients after tax law changes",
    category: "Risk",
    icon: "📋"
  },
  {
    prompt: "Analyze retirement planning risk for clients approaching retirement age",
    category: "Risk",
    icon: "🎯"
  },
  {
    prompt: "Assess real estate market risk for property-heavy portfolios",
    category: "Risk",
    icon: "🏠"
  },
  {
    prompt: "Review ESG compliance risk for affected client portfolios",
    category: "Risk",
    icon: "🌱"
  },

  // Client Life Events
  {
    prompt: "Prepare retirement planning review for Michael Anderson and Robert Taylor",
    category: "Life Events",
    icon: "👥"
  },
  {
    prompt: "Review business succession planning for Victoria Patel",
    category: "Life Events",
    icon: "🏢"
  },
  {
    prompt: "Update estate planning documents for high-net-worth clients",
    category: "Life Events",
    icon: "📄"
  },
  {
    prompt: "Review student loan impact on Alexandra Foster and Benjamin Kim's financial planning",
    category: "Life Events",
    icon: "🎓"
  },
  {
    prompt: "Prepare healthcare sector review for Olivia Martinez and Sarah Chen",
    category: "Life Events",
    icon: "🏥"
  }
];

export const getClientMetricsData = (clientMetrics) => [
  {
    title: "Total Clients",
    value: clientMetrics.totalClients,
    subtitle: "Active client relationships",
    icon: menuIcons?.Clients
  },
  {
    title: "Active Clients",
    value: clientMetrics.activeClients,
    subtitle: "Engaged in last 30 days",
    icon: menuIcons?.Profile
  },
  {
    title: "New Clients",
    value: clientMetrics.newClientsThisMonth,
    subtitle: "Added this month",
    icon: menuIcons?.AddUser
  },
  {
    title: "Retention Rate",
    value: `${clientMetrics.clientRetentionRate}%`,
    subtitle: "Client retention rate",
    icon: menuIcons?.Analytics
  }
];

export const getProspectMetricsData = (prospectMetrics) => [
  {
    title: "Total Prospects",
    value: prospectMetrics.totalProspects,
    subtitle: "Potential new clients",
    icon: menuIcons?.AddUser
  },
  {
    title: "Top Prospects",
    value: prospectMetrics.topProspects,
    subtitle: "Potential AUM > $5M",
    icon: menuIcons?.Report
  },
  {
    title: "High Net Worth Prospects",
    value: prospectMetrics.highNetWorthProspects,
    subtitle: "Net worth > $5M",
    icon: menuIcons?.Analytics
  },
  
  {
    title: "Potential AUM",
    value: `$${(prospectMetrics.potentialAUM / 1000000).toFixed(1)}M`,
    subtitle: "From prospects",
    icon: menuIcons?.Portfolio
  },

];

export const getPortfolioMetricsData = (portfolioMetrics) => [
  {
    title: "Total AUM",
    value: `$${(portfolioMetrics.totalAUM / 1000000).toFixed(1)}M`,
    subtitle: "Assets under management",
    icon: menuIcons?.Portfolio
  },
  {
    title: "Avg. Portfolio",
    value: `$${(portfolioMetrics.averageClientPortfolio / 1000).toFixed(0)}K`,
    subtitle: "Per client average",
    icon: menuIcons?.Analytics
  },
  {
    title: "Top Portfolios",
    value: portfolioMetrics.topPerformingPortfolios,
    subtitle: "Exceeding benchmarks",
    icon: menuIcons?.Report
  },
  {
    title: "Risk-Adjusted Return",
    value: `${portfolioMetrics.riskAdjustedReturn}%`,
    subtitle: "Average across portfolios",
    icon: menuIcons?.RiskManagement
  }
]; 