import { menuIcons } from 'nav/menuIcons'
import React from 'react'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { navLinkDict } from 'nav/navLinks';
import ThreePIcon from '@mui/icons-material/ThreeP';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function MyAdvisoryTeam() {
  const navigate=useNavigate()
  return (
    
    <div className='card-hover' onClick={()=>navigate(navLinkDict?.AskProfessional?.path)} 
    style={{cursor:"pointer",position:"relative",
      background:"#fafafa",
    display:"flex",gap:"0.5rem",alignItems:"center",padding:"1rem",borderRadius:"10px",width:"100%",boxSizing:"border-box"}}>
         {/* <div style={{width:"60px",display:"flex",alignItems:"center",justifyContent:"center"}}>
         <ThreePIcon sx={{color:"gray"}} fontSize='medium'/>
         </div> */}
         <ThreePIcon sx={{color:"gray"}} fontSize='small'/>
        <div style={{flex:1}}>
        <h4 style={{fontWeight:"bold"}}>My Financial Wellness Team
        </h4>
        <h5 style={{color:"gray"}}>Connect with your Advisor</h5>
        </div>
      
        <Button
        onClick={()=>navigate(navLinkDict?.AskProfessional?.path)}
        style={{
        padding:"0.5rem",
        borderRadius:0,
        minWidth:0,
        fontWeight:"bold"}}  
        
       >
          <KeyboardArrowRightIcon sx={{color:"gray"}} fontSize='small'/>
        </Button>
    </div>
  )
}
