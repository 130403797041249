import React from 'react';

import { useAppInfo } from 'AppState';
import LoadingSkeletonPage from 'components/Loading/LoadingSkeletonPage';
import NoFinancialData from 'myFinancials/Template/NoFinancialData';
import OverviewBox from 'myFinancials/Template/OverviewBox';
import { navLinkDict } from 'nav/navLinks';

import GoalAdd from './GoalAdd';
import GoalIndivdiualDetails from './GoalIndivdiualDetails';
import GoalsSuccessRate from './GoalsSuccessRate';

export default function GoalsOverview({displayType="home"}) {
  
  return (
      <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
      {displayType==="home" ?
      <OverviewBox
        header={"Financial Goals"}
        navigateUrl={navLinkDict?.Goals?.path}
      >
        <GoalsOverviewDetials displayType={displayType}/>
      </OverviewBox>:
       <GoalsOverviewDetials displayType={displayType}/>
      }
      
      </div>
      
       
        
     
  );
}

export function GoalsOverviewDetials({displayType="home"}){
  const {stateMyFinancials} = useAppInfo()
  const {financialsLoaded,goalsPeriodic} = stateMyFinancials

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%',display:"flex",flexDirection:"column"}}>
    {financialsLoaded && goalsPeriodic.length === 0 &&  <NoFinancialData financialType="goals"/>}
    {!financialsLoaded &&  <div style={{position:"absolute",width:"100%",height:"100%",zIndex:1}}><LoadingSkeletonPage/></div>}
   
    <GoalsSuccessRate/>
    {(financialsLoaded && goalsPeriodic.length > 0) &&  
    <>
    <GoalIndivdiualDetails edit={displayType==="home"?false:true}/>
    <div style={{display:"flex",gap:"1rem",position:"sticky",bottom:0,padding:"0.25rem 0.5rem"}}>
    <GoalAdd />
    </div></>}
    </div>
    
  )
}