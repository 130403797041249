import { Button } from '@mui/material'
import React from 'react'

export default function LeftSidebarButton({key="button",onClick,selected,name,disabled=false}) {
  return (
    <Button key={key} onClick={()=>onClick(name)} 
        style={{background:selected && "#F8F8F8",
          borderLeft:selected && "4px solid var(--selectBlue)",
          fontSize:"1rem"
        ,justifyContent:"flex-start",textAlign:"left",textTransform:"none",color:"black",borderRadius:0}}
        disabled={disabled}>{name}</Button>
  )
}
