import React from 'react';

import { dummyInsights } from 'b2b/data/dummyData';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Chip,
  Grid,
  Typography,
} from '@mui/material';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

const getDateGroup = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfter = new Date(today);
  dayAfter.setDate(dayAfter.getDate() + 2);

  // Reset time part for comparison
  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0, 0, 0, 0);
  dayAfter.setHours(0, 0, 0, 0);
  const compareDate = new Date(date);
  compareDate.setHours(0, 0, 0, 0);

  if (compareDate.getTime() === today.getTime()) {
    return 'Today';
  } else if (compareDate.getTime() === tomorrow.getTime()) {
    return 'Tomorrow';
  } else if (compareDate.getTime() === dayAfter.getTime()) {
    return 'Day After';
  } else {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }
};

const NomyTodos = () => {
  const todos = dummyInsights?.aiMetrics?.todos
  const [expandedSections, setExpandedSections] = React.useState(['Today']);

  // Group todos by date
  const groupedTodos = todos.reduce((acc, todo) => {
    const dateGroup = getDateGroup(todo.date);
    if (!acc[dateGroup]) {
      acc[dateGroup] = [];
    }
    acc[dateGroup].push(todo);
    return acc;
  }, {});

  const toggleSection = (date) => {
    setExpandedSections(prev => 
      prev.includes(date) 
        ? prev.filter(d => d !== date)
        : [...prev, date]
    );
  };

  return (
    <Box sx={{ mt: 2, pt: 2, borderTop: '1px solid #f0f0f0' }}>
      <Typography variant="subtitle2" sx={{ mb: 2, color: 'text.primary', fontWeight: 500 }}>
        Quinn Recommended Tasks
      </Typography>
      {Object.entries(groupedTodos).map(([date, todos]) => (
        <Box key={date} sx={{ mb: 2 }}>
          <Box 
            onClick={() => toggleSection(date)}
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              cursor: 'pointer',
              p: 1.5,
              borderRadius: '8px',
            //   bgcolor: 'grey.50',
              '&:hover': {
                bgcolor: 'grey.100',
              },
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 500, color: 'text.primary' }}>
              {date}
            </Typography>
            <KeyboardArrowDownIcon 
              sx={{ 
                transform: expandedSections.includes(date) ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.2s',
                color: 'text.secondary'
              }}
            />
          </Box>
          {expandedSections.includes(date) && (
            <Grid container spacing={1} sx={{ mt: 1 }}>
              {todos.map((todo) => (
                <Grid item xs={12} key={todo.id}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start',
                    p: 1.5,
                    borderRadius: '8px',
                    bgcolor: todo.priority === 'High' ? 'error.50' : 'warning.50',
                    '&:hover': {
                bgcolor: 'grey.100',
              }
                    
                  }}>
                    <Box sx={{ flex: 1, minWidth: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                        <Box sx={{ 
                          width: 24, 
                          height: 24, 
                          borderRadius: '50%',
                          bgcolor: 'grey.200',
                          color: 'text.secondary',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '0.65rem',
                          fontWeight: 500
                        }}>
                          {todo.avatar}
                        </Box>
                        <Typography 
                          variant="subtitle2" 
                          sx={{ 
                            fontWeight: 600, 
                            color: 'text.primary',
                            fontSize: '0.8rem'
                          }}
                        >
                          {todo.client}
                        </Typography>
                      </Box>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: 'text.secondary',
                          fontSize: '0.75rem',
                          mb: 0.5
                        }}
                      >
                        {todo.event}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: 'text.secondary',
                          fontSize: '0.75rem',
                          mb: 0.5
                        }}
                      >
                        {todo.description}
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {todo.actions.map((action, index) => (
                          <Chip
                            key={index}
                            label={action}
                            size="small"
                            sx={{
                              height: '18px',
                              fontSize: '0.6rem',
                              bgcolor: 'primary.50',
                              color: 'primary.main',
                              '& .MuiChip-label': {
                                px: 1
                              }
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      gap: 0.5,
                      ml: 2,
                      minWidth: '80px'
                    }}>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'text.secondary',
                          fontSize: '0.65rem',
                          fontWeight: 500
                        }}
                      >
                        {formatDate(todo.date)}
                      </Typography>
                      <Chip 
                        label={todo.priority}
                        size="small"
                        sx={{ 
                          height: '18px',
                          fontSize: '0.6rem',
                          bgcolor: todo.priority === 'High' ? 'error.main' : 'warning.main',
                          color: 'white',
                          '& .MuiChip-label': {
                            px: 1
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default NomyTodos; 