import React, { useEffect, useState } from 'react'
import FieldFormInvestmentPreferences from '../FormFields/FieldFormInvestmentPreference'
import { useAppInfo } from 'AppState'
import SelectMarket from 'components/Inputs/SelectMarket'
import MultiSelect from 'components/Inputs/MultiSelect'
import { Alert, Button, Checkbox, Divider, Slider } from '@mui/material'
import useAddFinancials from 'data/user/financials/useAddFinancials'
import CheckboxCutom from 'components/Inputs/CheckboxCustom'
import Modal from 'components/Modal/Modal'
import RiskTolerance from '../RiskManagement/RiskTolerance'

export default function InvestmentPreferences({info}) {

  const infoContent = {
    "Market Region":<MarketRegion/>,
    "Time Horizon":<TimeHorizon/>,
    "Asset Allocation":<AssetClass/>,
    "Risk Tolerance":<RiskTolerance/>
  }
  
  return (
    <div style={{padding:"0.5rem",height:"100%",width:"100%",boxSizing:"border-box"}}>
     {infoContent[info]} 
    
    </div>
  )
}

function AssetClass(){
  const {stateMyFinancials} = useAppInfo()
  const {investmentPreference,investmentPreferenceRiskTolerance,investmentPreferenceType} = stateMyFinancials
  
  return(
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem"}}>
    <h3>Asset Allocation</h3>
    <Alert severity="info">
      Asset allocation is crucial in achieving your financial goals while managing risk. Allocating assets based on your risk tolerance ensures that you are comfortable with the potential volatility in your portfolio. 
      <strong>Your Portfolio Fit Opportunities will be based on these preferences.</strong>
    </Alert>
    <div>
      <SetInvestmentPreferenceType />
      <FieldFormInvestmentPreferences
        profileCompletion={true}
        storeName={investmentPreferenceType?.type === "Manual" ? "investmentPreference" : "investmentPreferenceRiskTolerance"}
        data={investmentPreferenceType?.type === "Manual" ? investmentPreference : investmentPreferenceRiskTolerance}
      />
    </div>
  </div>
  )
}

export function SetInvestmentPreferenceType({label="Set Investment Preferences based on your Risk Tolerance"}){
  const {dispatchMyFinancials,stateMyFinancials} = useAppInfo()
  const {investmentPreferenceType,riskTolerance} = stateMyFinancials
  const {addFinancials} = useAddFinancials()
  function handleRiskTolerancePreference(v){
    let type = "RiskTolerance"
    if (!v){
      type = "Manual"
    }
    addFinancials({...investmentPreferenceType,type:type},"investmentPreferenceType")
    dispatchMyFinancials({"investmentPreferenceType":{...investmentPreferenceType,type:type}})
  }
  const [riskToleranceTest,setRiskToleranceTest] = useState(false)
  return (
    <>
    <CheckboxCutom label={label} 
    defaultChecked={investmentPreferenceType?.type !=="Manual" ? true:false} 
    onChange={handleRiskTolerancePreference}/>
    {(!riskTolerance.test && investmentPreferenceType?.type !=="Manual") && <Alert severity='warning'>
      You have not yet taken the risk tolerance test. 
      The test will help you identify the right asset allocation and your risk tolerance level.<br/><br/>
      <Button onClick={()=>setRiskToleranceTest(true)} variant='contained'>Take the Risk Tolerance Test</Button>
      </Alert>}
      {riskToleranceTest && <Modal width='90%' height='80%' onClose={()=>setRiskToleranceTest(false)}>
        <RiskTolerance/>
      </Modal>}
    </>
  )

}

function TimeHorizon(){
  const {dispatchMyFinancials,stateMyFinancials,stateApp} = useAppInfo()
  const {investmentHorizon,goalAnalytics,investmentHorizonGoalBased} = stateMyFinancials
  const {addFinancials} = useAddFinancials({stateApp})
  const progressUpdate = stateMyFinancials?.profileCompletion

  function valuetext(value) {
    return `${value} years`;
  }
  const marks = [
    {
      value: 1,
      label: '1y',
    },
    {
      value: 5,
      label: '5y',
    },
    {
      value: 10,
      label: '10y',
    },
    {
      value: 20,
      label: '20y',
    },
    {
      value: 30,
      label: '30y',
    },
    {
      value: 50,
      label: '50y',
    },
    {
      value: 70,
      label: '70y',
    },
    {
      value: 100,
      label: '100y',
    },
  ];

  function handleInvestmentHorizon(v){
    
    dispatchMyFinancials({"investmentHorizon":v})
    addFinancials(v,"investmentHorizon")

    if (!progressUpdate?.["investmentPreference"]){
      const progressUpdated = {...progressUpdate,investmentPreference:true}
      dispatchMyFinancials({"profileCompletion":progressUpdated});
    setTimeout(()=> {
    addFinancials( progressUpdated,"profileCompletion")},1000)
    }
  }

  useEffect(()=>{
    if (investmentHorizonGoalBased){
      const timeHorizon = goalAnalytics?.goalsTimeHorizon || 5
      addFinancials(true,"investmentHorizonGoalBased")
      handleInvestmentHorizon(timeHorizon)
    }
  },[investmentHorizonGoalBased])

  
  return (
    <>
    <div style={{display:"flex",gap:"0.5rem",flexDirection:"column"}}>
    <h3>Investment Time Horizon</h3>
    <Alert severity='info'>It is important to set an optimal time horizon. Your Portfolio Fit will be based on the time horizon.</Alert>
    
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    <CheckboxCutom defaultChecked={investmentHorizonGoalBased} 
    onChange={(v)=>dispatchMyFinancials({"investmentHorizonGoalBased":v})} 
    label={"Match time horizon to meet my goals"}/>
    
    <h4 style={{padding:0,margin:0}}>{`Optimal time horizon to achieve all your goals is ${goalAnalytics?.goalsTimeHorizon || 5} years.`}</h4>
    
    <div style={{width:"50%",minWidth:"200px"}}>
    <Slider
    // disabled={investmentHorizonGoalBased} 
    step={1}
    marks={marks}
    valueLabelDisplay="auto"
    getAriaValueText={valuetext} 
    min={0} max={100} 
    onChange={(e)=>{handleInvestmentHorizon(e.target.value)}}
    value={investmentHorizon}/></div>
    </div>
    </div>
    </>
  )
}

function MarketRegion(){
  const {dispatchMyFinancials,stateMyFinancials,stateApp} = useAppInfo()
  const {marketRegion,primaryMarketRegion} = stateMyFinancials
  const {addFinancials} = useAddFinancials({stateApp})
  function handleMarketRegions(v){
    let marketRegionSel = Array.from (new Set([primaryMarketRegion,...v]))
    dispatchMyFinancials({"marketRegion":marketRegionSel})
    addFinancials( marketRegionSel,"marketRegion")

    if (!stateMyFinancials?.profileCompletion?.["investmentPreference"]){
      const progressUpdated = {...stateMyFinancials?.profileCompletion,investmentPreference:true}
      dispatchMyFinancials({"profileCompletion":progressUpdated});
    setTimeout(()=> {
    addFinancials( progressUpdated,"profileCompletion")},1000)
    }
  }
  function handlePrimaryMarketRegion(v){
    dispatchMyFinancials({"primaryMarketRegion":v})
    let marketRegionSel = Array.from (new Set([v,...marketRegion]))
    dispatchMyFinancials({"marketRegion":marketRegionSel})
    addFinancials( v,"primaryMarketRegion")
    setTimeout(()=>addFinancials(marketRegionSel,"marketRegion"),1000)
  }
  
  return (
    <>
   
    <div style={{display:"flex",gap:"1rem",flexDirection:"column"}}>
    <h3> Select Market Region </h3>
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    <h4 style={{fontWeight:"bold"}}>1. Primary Market</h4>
    <Alert severity='info'>
      This is your home country or the country where you have the most investments in.
      </Alert>
      <SelectMarket 
    defaultMarket={primaryMarketRegion}
    handleMarket={(v)=>handlePrimaryMarketRegion(v)} defaultMarketOptions={defaultMarketOptions}/>
    </div>
    <Divider/>
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    <h4 style={{fontWeight:"bold"}}>2. Market Regions</h4>
    <Alert severity='info'>
    Select all the market regions that you are interested to see opportunities in.
    </Alert>
    <MultiSelect 
    limitTags={5} tags={true} label={"Market Regions"} variant='outlined' 
    options={defaultMarketOptions} values={marketRegion} onChange={(v)=>handleMarketRegions(v)}/>
    </div>
    </div>
    </>
  )
}

const defaultMarketOptions = ["USA","India","Canada","UK"]

const countryCode = {
  "USA":"us",
  "India":"in",
  "Canada":"ca",
  "UK":"gb"
}

