import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import Sankey from 'components/Charts/Sankey';
import TabLine from 'components/Tabs/TabLine';
import {
  dfin,
  financialStatementsInfo,
} from 'content/constants';
import AddPeersBar from 'peerList/AddPeersBar';

import CompanyFinancialsSelf from './Company/CompanyFinancialsSelf';
import FinancialsSettings from './FinancialsSettings';
import CompanyFinancialsPeers from './Peers/CompanyFinancialsPeers';

export default function FinancialsTemplate({ 
  title="Financial Statements",
  period="Multi",
  financialsType="statements",
  metricSelectedDefault=[],
  peers=false}) {

    const { stateStock,dispatchStock } = useAppInfo();
    const {annualInfo,quarterInfo,peerMode,metrics,financialsSettings,metricSelectedCategories} = stateStock
    const metricFinancials = metrics?.metricFinancials
    const companySelected = stateStock.companySelected;
    const {reportMode,periodLen,singlePeriodDate} = financialsSettings
    const [statementType,setStatementType] = useState(dfin.incomeStatement)
    const [statementCategories,setStatementCategories] = useState([])
    const [statementCategory,setStatementCategory] = useState()
    const [metricSelected, setMetricSelected] = useState([dfin.revenue,dfin.grossProfit,dfin.ebitda,dfin.netIncome,]);
    const [statementMetric, setStatementMetric] = useState([]);
    const [statementInfo, setStatementInfo] = useState([]);
    const [commonSizeMetric,setCommonSizeMetric] = useState(dfin.revenue)
    const [metricAll,setMetricAll] = useState([])
    const [dates,setDates] = useState([])


    useEffect(()=>{
      if (metricFinancials.length>0 && financialsType){
      const metricAll = metricFinancials.map(obj=>obj.Metric)
      setMetricAll(metricAll)
    
      if (financialsType === "ratio"){
        const categoryRatio = FinancialRatioCategory(metricFinancials)
        setStatementCategories(categoryRatio)
        setStatementCategory(categoryRatio[0])
        setStatementType(dfin.ratio)
      }
      else {
        const categories = financialStatementsInfo.map((obj) => obj.label)
        setStatementCategories(categories)
        setStatementCategory(categories[0])
        setStatementType(dfin.incomeStatement)
      }
    }
    },[financialsType,metricFinancials])

    useEffect(()=>{
      if (peers){
        dispatchStock({"peerMode":peers})
      }
    },[peers])

      useEffect(() => {
        let dates;
        if (reportMode === false) {
          dates = Array.from(
            new Set(
              annualInfo
                .filter((obj) => obj[dfin.ticker] === companySelected)
                .map((obj) => obj[dfin.period])
            )
          );
        } else {
          dates = Array.from(
            new Set(
              quarterInfo
                .filter((obj) => obj[dfin.ticker] === companySelected)
                .map((obj) => obj[dfin.period])
            )
          );
        }

        dates.sort()
        dates.reverse()
        setDates(dates)
        dispatchStock({financialsSettings:{...financialsSettings,totalPeriods:dates.length,singlePeriodDate:dates[0]}})


        const statementReportSel = reportMode ? quarterInfo : annualInfo;
        let statementInfoCo

        if (!peerMode){
            statementInfoCo = statementReportSel
              .filter((obj) => obj[dfin.ticker] === companySelected)
              .sort((a, b) => b.PERIOD.toString().localeCompare(a.PERIOD.toString()))
            }
        else{
            statementInfoCo = statementReportSel
              .sort((a, b) => b.PERIOD.toString().localeCompare(a.PERIOD.toString()))
            }
     
        const perioSel = dates.slice(0,periodLen)  
        const statementSel = statementInfoCo.filter(obj=>perioSel.includes(obj[dfin.period]))

        if (financialsType === "commonSize"){
          const commonSize = statementSel.length>0 && calculateCommonSize(statementSel, commonSizeMetric,metricAll)
          setStatementInfo(commonSize)
         
        }
        else if (period !=="Multi"){
          setStatementInfo(statementInfoCo)
        }
 
        else {
          setStatementInfo(statementSel)}
        
        let metrics,statementMetric;     

        if (statementType === dfin.ratio){
            metrics = metricFinancials.filter(
              (obj) => obj["Statement Category"] === statementCategory
            );
        
            statementMetric = Array.from(
              new Set(metrics.map((item) => item.Metric))
            );}
        else {
            metrics = metricFinancials.filter(
          (obj) => obj[dfin.statement] === statementType
        );
    
            statementMetric = Array.from(
          new Set(metrics.map((item) => item.Metric))
        );
            }   
        
        setStatementMetric(statementMetric);
      }, [
        peerMode,
        companySelected,
        reportMode,
        annualInfo,
        quarterInfo,
        periodLen,
        metricFinancials,
        statementType,
        statementCategory,
        commonSizeMetric,
        financialsType
      ]);

      useEffect(()=>{
        if (metricSelectedDefault.length>0){
        setMetricSelected(metricSelectedDefault)}
      },[metricSelectedDefault])
      useEffect(()=> {
        let statatement,info;
        let metricSel = metricSelectedCategories?.[`${statementType}`] || metricSelectedCategories?.[`${statementCategory}`]

        if (!metricSel || metricSel.length===0){
        if (statementType!==dfin.ratio){
        statatement= financialStatementsInfo.filter((obj) => obj.value===statementType)[0]?.label;
        info = financialStatementsInfo.filter((obj) => obj.label === statatement);
        setMetricSelected(info[0].default);
        }

        if (statementType===dfin.ratio){
           info = metricFinancials.filter((obj) => obj["Statement Category"] === statementCategory).map(obj=>obj.Metric);
           setMetricSelected(info.slice(0,2))
        }}
        else{
            setMetricSelected(metricSel)
        }
        
      },[statementType,statementCategory,metricSelectedCategories])

    
      function handleMetricSelected(value) {  
        let metricSel;    
        if (Array.isArray(value)) {
          metricSel = value   
        } 
        else {
          if (!metricSelected.includes(value)) {
           metricSel = [...metricSelected,value]
           
          } else {
            metricSel = metricSelected.filter((item) => item !== value)
          }     
        }

        if (statementType===dfin.ratio){
          dispatchStock({"metricSelectedCategories":{...metricSelectedCategories,[`${statementCategory}`]:metricSel}})
        }
        else{
        dispatchStock({"metricSelectedCategories":{...metricSelectedCategories,[`${statementType}`]:metricSel}})}
      }
    
  const [singlePeriodInfo,setSinglePeriodInfo] = useState()
 
 
  useEffect(()=>{
    if (statementInfo.length>0){
    if (!singlePeriodDate){
    const singlePeriodInfo = statementInfo.sort((a,b)=>a[dfin.period]-b[dfin.period])[0]
    setSinglePeriodInfo(singlePeriodInfo)}
    else{
      const singlePeriodInfo = statementInfo.filter(obj=>obj[dfin.period]===singlePeriodDate)[0]
    setSinglePeriodInfo(singlePeriodInfo)
    }}

  },[singlePeriodDate,statementInfo])
 


  function handleCommonSizeMetric(v){
    setCommonSizeMetric(v)
  }

  function handleStatementCategory(index){
    if (financialsType ==="ratio"){
      setStatementCategory(statementCategories[index]);
    }
    else{
    const statatement = statementCategories[index];
    const info = financialStatementsInfo.filter((obj) => obj.label === statatement);
    setStatementType(info[0].value);
    setStatementCategory(info[0].label);}
  }


  return (
    <div className='containerColumnFlexNoGap'>
      
      <FinancialsSettings 
      title={title}
      financialsType={financialsType}
      statementType={statementType}
      statementCategory={statementCategory}
      dates={dates}
      period={period}
      commonSizeMetric={commonSizeMetric}
      metricAll={metricAll}
      metricSelected={metricSelected}
      handleMetricSelected={handleMetricSelected}
      handleCommonSizeMetric={handleCommonSizeMetric}
      />

      {statementCategories.length>0 && 
      <div style={{position:"sticky",left:0}}>
      <TabLine 
      tabList={statementCategories} 
      tabIndex={statementCategories.indexOf(statementCategory)} 
      handleTabChange={(index)=>handleStatementCategory(index)}/></div>}

      
      <div className='containerColumnFlex1'>
      {period === "Multi" && <>
      <>
       {(!peerMode) && 
       <CompanyFinancialsSelf
       title={financialsType === "commonSize" ?`${statementType} common size : ${commonSizeMetric}`:statementType}
       statementInfo={statementInfo}
       metricSelected={metricSelected}
       financialsSettings={financialsSettings}
      statementMetric={statementMetric}
      handleMetricSelected={handleMetricSelected}
       />}
      {peerMode && 
      <CompanyFinancialsPeers
      data={statementInfo}
      financialsSettings={financialsSettings}
      handleMetricSelected={(v)=>handleMetricSelected(v)}
      metricSelected={metricSelected}
      />}
      </>
      </>}
        
      {(period !=="Multi" && singlePeriodInfo) &&
       <Sankey 
       title={`${statementType}-${singlePeriodDate}`}
       data={singlePeriodInfo} metric={statementMetric} statementType={statementType}/>
       }
       </div>
        
      {peerMode && <AddPeersBar/>}
     
    </div>
    
  )
}



const FinancialRatioCategory = (metricFinancials) => {
    const matchingCategory = metricFinancials.filter(
      (obj) =>
        obj[dfin.statement] === dfin.ratio &&
        obj[dfin.statementCategory] !== null
    );
    const categories = [
      ...new Set(matchingCategory.map((obj) => obj[dfin.statementCategory])),
    ];
    return categories
  };

function calculateCommonSize(statements, metricToDivide,metricArray) {
    // Check if the metric to divide exists in the statements
    if (!statements[0].hasOwnProperty(metricToDivide)) {
      return [];
    }
  
    // Calculate common size and create a new array of objects
    return statements.map(statement => {
      const commonSizeStatement = { ...statement };
      const divisor = statement[metricToDivide];
  
      for (const key in commonSizeStatement) {
        if (commonSizeStatement.hasOwnProperty(key) && metricArray.includes(key)) {
          commonSizeStatement[key] /= divisor;
        }
      }
  
      return commonSizeStatement;
    });
  }
  