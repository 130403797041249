import { TextField } from '@mui/material';
import React, { useEffect } from 'react';

export default function NumberInput({
  variant = "standard",
  size = "medium",
  onChange,
  required = true,
  label = "",
  value = 0,
  type = "number",
  sx,
  step = "any",
  min,
  max,
  fontSize,
  placeholder,
  disabled = false,
  labelFontSize,
}) {
  // Format numbers with commas, retaining decimals
//   const [inputValue, setInputValue] = React.useState(value);

//   useEffect(() => {
//     setInputValue(value)
//   },[value])
  const formatWithCommas = (num) => {
    if (num === undefined || num === null || num === "") return ""; 
    const numStr = num.toString();
    const [integer, decimal] = numStr.split(".");  
    const formattedInteger = new Intl.NumberFormat().format(Number(integer.replace(/,/g, "")));
    return decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
  };

  return (
    <TextField
      disabled={disabled}
      sx={{
        ...sx,
        '& .MuiInputBase-input': {
          fontSize: fontSize,
        },
        '& .MuiInputLabel-root': {
          fontSize: labelFontSize,
        },
      }}
      size={size}
      type={type} // Always render as text for proper display
      required={required}
      label={label}
      placeholder={placeholder}
      variant={variant}
      value={
      value
      }
      onChange={(e) => onChange(e.target.value)}
      inputProps={{ step, min, max }}
      fullWidth
    />
  );
}
