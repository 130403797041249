import { useAppInfo } from 'AppState'
import useHandleSignOut from 'auth/useHandleSignout'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import LogoSVG from './LogoSVG'
import LogoLoader from './LogoLoader'

export default function Logo({height="30px",width="25px",type="short",color="white"}) {
  const [mouseEnter,setMouseEnter] = useState(false)
  const {stateApp} = useAppInfo()
  const {handleSignOut} = useHandleSignOut()
  
  function handleLogout(){
    if (stateApp.isLoggedIn && !stateApp.onboarding?.setDisplayName) {
      handleSignOut()
    }
   }
  return (
    <div onClick={()=>handleLogout()} style={{width:width,height:height}}  onMouseEnter={()=>setMouseEnter(true)} 
    onMouseLeave={()=>setMouseEnter(false)} 
    >
    <Link to="/" style={{ display: 'block', width: '100%', height: '100%' }}> 
    {type==="short" && 
    <LogoSVG color={color} height={height} width={width}/>
    // <img src='\LogoShort.png' height={height} width={width} style={{padding:0,borderRadius:"2px"}}
    // />
    
    }
    {type==="loaderShort" && 
    <LogoLoader color={color} height={height} width={width}/>
    }
    {type==="long" && 
      <AutonomyLogo color={color} height={height} width={width}/>      
    }
    </Link> 
    </div>
  )
}


const AutonomyLogo = ({ 
  width = '100%', 
  height = '100%', 
  color = '#000',
  fullWidth = true
}) => {
  const viewBoxWidth = 500;  // Define the SVG's internal width
  const viewBoxHeight = 120; // Define the SVG's internal height
  
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin meet" // Adjust as needed
      style={{ display: 'block', maxWidth: '100%' }}
    >
      <text
        x="0"
        y={viewBoxHeight * 0.65} // Positions text vertically within SVG
        fontSize={viewBoxHeight * 0.72} // Sets font size relative to SVG height
        fontWeight="600"
        textLength={viewBoxWidth} // Stretches text to full SVG width
        lengthAdjust="spacingAndGlyphs" // Adjusts spacing and glyphs to fit text length
        fill={color}
        // dominantBaseline="middle" // Vertically centers text
      >
        Autonomy
      </text>
    </svg>
  );
};



// const AutonomyLogo = ({ 
//   width = "100%", 
//   height = "auto", 
//   color = '#000',
//   fullWidth=true
// }) => {
//   return (
//     <svg
//       width={width}
//       height={height}
//       viewBox="0 0 500 100"
//       xmlns="http://www.w3.org/2000/svg"
//       preserveAspectRatio="xMinYMin meet" // Adjust as needed
//       // preserveAspectRatio="xMinYMin meet"
//       style={{ display: 'block', maxWidth: '100%' }}
//     >
//       <text
//         x="0"
//         y="70"
//         // fontFamily="'Montserrat', sans-serif"
//         fontSize="72"
//         fontWeight="600"
//         textLength={fullWidth ? "100%" : undefined} // Use full width if desired
//         lengthAdjust={fullWidth ? "spacingAndGlyphs" : undefined} // Adjust spacing and glyphs
//         fill={color}
//         // style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.2)' }}
//       >
//         Autonomy
//       </text>
//     </svg>
//   );
// };


