import React, { useEffect, useRef, useState } from "react";
import TargetIcon from "@mui/icons-material/TrackChanges";
import SpeedIcon from "@mui/icons-material/Speed";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import GrowFinanciallyToday from "home/GrowFinanciallyToday";
import FeatureAskAI from "./FeatureAskAI";
import LifeStages from "./LifeStages";
import TextWithBorders from "components/Text/TextWithBorders";
import QuestionsHome from "home/QuestionsHome";
import { HowAutomatingWorks } from "./HowItWorks";



const Features = () => {

  return (
    <div style={{padding:"0",width:"100%",boxSizing:"border-box",position:"relative"}}>
        <FeatureVault/>
        <FeatureAutomating/>
        
        <FeatureAskAI/>
       
        {/* <FeaturScores /> */}
        
        
        <QuestionsHome/>
        {/* <UseCases/> */}
        {/* <GrowFinanciallyToday/>
        */}
      </div>
  );
};

export default Features;

function FeatureOverview(){
  return (
    <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap",justifyContent:"center",paddingTop:"2rem"}}>
    
    <div style={{width:"300px",padding:"1rem"
      ,display:"flex"
      ,flexDirection:"column",gap:"1rem",background:"white",borderRadius:"10px"}}>
      <h3 style={{fontWeight:"normal"}}>Manage your Financial Data</h3>
      <h4>You control and own your data</h4>
      <h4>Get paid for your data</h4>
    </div>  
    <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
    <div style={{width:"350px",padding:"1rem"
      ,display:"flex",flexDirection:"column",gap:"1rem",background:"white",borderRadius:"10px"}}>
      <h3 style={{fontWeight:"normal"}}>Connect with your Advisors</h3>
      <h4>Financial Advisors</h4>
      <h4>Mortgage Advisors</h4>
      <h4>Insurance Advisors</h4>
    </div>
    <div style={{width:"350px"
      ,padding:"1rem",display:"flex",flexDirection:"column",gap:"1rem",background:"white",borderRadius:"10px"}}>
      <h3 style={{fontWeight:"normal"}}>Automate your Financial Workflows</h3>
      <h4 >Budgeting & Savings</h4>
      <h4>Investment & Portfolio Management</h4>
      <h4>Mortgage, Tax & Insurance</h4>
    </div>
    </div>
  </div>
  )
}

function FeatureTrack () {
  return (
    <>
    <div style={{padding:"0rem 4rem",paddingTop:"4rem",textAlign:"center",position:"relative"}}>
    <h1
style={{
color: "white",
fontSize: "4rem",
// fontWeight: "900",
color:"black",
// textShadow: "0px 4px 6px rgba(0, 0, 0, 0.5), 0px 0px 15px rgba(255, 255, 255, 0.7),0px 0px 15px rgba(255, 255, 255, 0.7)"
}}
>
      AI for Your <br/>Financial Journey
    </h1>

    
    </div>
    <div style={{padding:"2rem"}}>
        <LifeStages/>
    </div>
    </>
  )
}




function FeatureVault () {
    const dataOne = [
        { title: "Bank Accounts", description: ["Savings","Loans","Mortgages"] },
        { title: "Investments", description: ["Public Market","Private Market"] },
        { title: "Advisors", description: ["Financial","Mortgage","Insurance"] },
        // { title: "Invest", description: ["Public Market","Private Market"] },
        
        // { title: "Analyze", description: ["Assets","Markets","Economy"] },
        // { title: "Consult", description: ["AI","Professionals"] },
        // { title: "Transact", description: ["Trade","Financial Products"] },
        // { title: "Grow", description: ["Next Best Action","Guidance"] },
      ];
    
    const datatwo = [
       
      ];
    return (
      <div style={{padding:"4rem",textAlign:"center",display:"flex",alignItems:"center"
      ,flexDirection:"column",gap:"1rem",position:"relative",overflow:"hidden"}}>
        
        <h1 
        style={{fontSize:"4rem",zIndex:1,}}>Your Financial World<br/>
        </h1>
        <h1 style={{fontSize:"3rem"}}>
        Fully Connected, Secured and Automated
        </h1>

        {/* <h2 style={{fontSize:"2rem",paddingBottom:"2rem",zIndex:1,fontWeight:"normal"}}>bank, broker, and beyond – all in one place</h2> */}
        <div style={{zIndex:1}}>
        <ZigZagBoxes data={dataOne}/></div>
        
      </div>
    )
  }



  export const FeatureAutomating = () => {
    const automateDetails = [    {
      number: "2",
      title: "AI recommends your next steps",
      description: "Personalized, prirtized tasks guide your next best move...",
      componentBoxes: [
        { icon: "📊", title: "Start Saving" },
        { icon: "🌍", title: "Diversify Portfolio" },
        { icon: "💰", title: "Invest surplus Cash" },
      ],
    },
    {
      number: "3",
      title: "Automate various tasks",
      description: "Automatically execute each recommended actions like rebalancing portfolios, renewing mortgages, and more...",
      componentBoxes: [
        { icon: "🏦", title: "Automate Savings" },
        { icon: "📈", title: "Automate Investing" },
        { icon: "📝", title: "Automate Financial Applications" },
      ],
    },]
    return (
      <div style={{ position: "relative", zIndex: 1,padding: "4rem",alignItems:"center"}}>
      <div style={{textAlign:"center",width:"100%"}}>
      <h1 style={{fontSize:"4rem"}}>Automate Your Financial Workflows</h1>
      <h1 style={{ fontWeight:'normal',fontSize:"2rem"}}>
        budgeting, planning, investing and more... <br/>
        </h1></div>
        <div style={{display:"flex",gap:"2rem",flexDirection:"column",paddingTop:"2rem",alignItems:"center",justifyContent:"center"}}>
        {automateDetails.map((item, index) => (
          <div 
          key={index}
          style={{
            background: "#fafafa"
            ,padding:"2rem",borderRadius:"10px",width:"600px",minHeight:"400px"}}>
              <h2>{item.title}</h2>
              <h3 style={{color: "#555",fontWeight:"normal"}}>{item.description}</h3>


              <div style={{ display: "flex",flexDirection:"column", gap: "1rem", flexWrap: "wrap",paddingTop:"1rem"}}>
          {item.componentBoxes.map((box, i) => (
        <div
key={i}
className="card-hover"
style={{
display: "flex",
alignItems: "center",
// justifyContent: "center",
padding: "1rem",
borderRadius: "12px",
// maxWidth:"150px",
minWidth:"150px",
// boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Soft shadow for a polished look
backgroundColor: "#ffffff",
transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth hover effects
cursor: "pointer",
}}
>
<div style={{ fontSize: "2rem", marginBottom: "0.5rem" }}>{box.icon}</div>
<div style={{ fontSize: "1rem", fontWeight: "bold", color: "#333" }}>
{box.title}
</div>
</div>
    ))}
            </div>
              </div>
        ))}
        
          
        </div>
        <div style={{padding:"2rem",borderRadius:"10px",display:"flex",flexDirection:"column",alignItems:"center",paddingTop:"4rem"}}>
          <h1 style={{fontSize:"4rem",maxWidth:"700px",textAlign:"center"}}>
           
           Financial Plan <br/>
           </h1>
           <h1 style={{fontSize:"3rem",maxWidth:"700px",textAlign:"center"}}>that adapts to your life and market changes<br/></h1>
          {/* <h2 style={{fontWeight:"normal",maxWidth:"700px",textAlign:"center"}}>ensuring your financial plan stays always current, always personal, and always moving you forward</h2> */}
        </div>
        <div style={{padding:"2rem"}}>
        <LifeStages/>
    </div>
    </div>
    )
  }
  

  const FeaturScores = () => {
    const scoreData = [
      {
        icon: <HealthAndSafetyIcon style={{ fontSize: "8rem", color: "green" }} />,
        title: "Financial Wellness Score",
        background: "#E8F5E9",
        points: [
          "100+ financial metrics analyzed",
          "Identify weaknesses like high debt or low savings",
          "To-do's recommended to improve stability and growth",
        ],
      },
      {
        icon: <TargetIcon style={{ fontSize: "8rem", color: "blue" }} />,
        title: "Goal Success Rate",
        background: "#E3F2FD",
        points: [
          "Forecast success based on growth, inflation and life scenarios",
          "Track progress toward your goals",
          "Automate actions to achieve your goals",
        ],
      },
      {
        icon: <SpeedIcon style={{ fontSize: "8rem", color: "orange" }} />,
        title: "Portfolio Fit Score",
        background: "#FFFDE7",
        points: [
          "Explore opportunities that align with your portfolio strategy",
          "Optimize portfolio performance and risk",
          "Automate investment decisions",
        ],
      },
    ];
  
    return (
      <div
        style={{
          textAlign: "center",
          padding: "2rem 4rem",
          paddingBottom: "4rem",
          position: "relative",
        }}
      >
        <div style={{ position: "relative", zIndex: 1,paddingBottom:"2rem"}}>
          
          <h1 style={{ fontSize: "4rem" }}>
           3 scores
            </h1>
          <h2>
            to help you navigate your financial world
          </h2>
        </div>
  
        <div
          style={{
            display: "flex",
            gap: "2rem",
            alignItems: "flex-start",
            justifyContent: "center",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {/* Score Cards */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            {scoreData.map(({ icon, title, points, background }, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: background || "#f5f5f5",
                  borderRadius: "10px",
                  padding: "2rem",
                  boxSizing: "border-box",
                  color: "black",
                  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                  maxWidth:"1000px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    zIndex: 10,
                    position: "relative",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <div style={{ minWidth: "40%",position:"relative"}}>
                    <div>{icon}</div>
                    <h3
                      style={{
                        fontSize: "1.5rem",
                        color: "black",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {title}
                    </h3>
                  </div>
                  <div
                    style={{
                      padding: "0 1rem",
                      flex: 3,
                      textAlign: "left",
                    }}
                  >
                    {points.map((point, idx) => (
                      <h4
                        key={idx}
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {point}
                      </h4>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
  
          
        </div>
      </div>
    );
  };


function FeatureMarketPersonal (){
  return (
    <div style={{}}>
        
    </div>
  )
}


const ZigZagBoxes = ({data}) => {

  const styles = {
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
    boxWrapper: {
      // flex:1,
      position: "relative",
      minWidth: "300px",
      height: "300px",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    box: {
      width: "100%",
      height: "100%",
      borderRadius: "10px",
      
    //   background:"#fafafa",
    //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      // background: "linear-gradient(to top, white, rgba(76, 175, 80, 0.4))",
      background:"rgba(76, 175, 80, 0.3)",
      color: "white",
      // backgroundColor: "#DFF5DC",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      position:"relative",
    },
    title: {
      fontSize: "2rem",
      fontWeight: "600",
      color:"#013220",   
      zIndex:2,
    },
    description: {
      fontSize: "14px",
      marginTop: "8px",
      display: "flex",
      flexDirection:"column",
      // flexWrap:"wrap",
    //   width:"100%",
      gap:"0.25rem",
      zIndex:2,
    },
    upBox: {
      marginTop: "0px"
    },
    downBox: {
     transform: "translateY(50px)"
    },
  };

  return (
    <div style={styles.container}>
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            ...styles.boxWrapper,
            ...(index % 2 === 0 ? styles.upBox : styles.downBox),
          }}
        >
          <div style={styles.box}>
            <div style={styles.title}>{item.title}</div>
            <div style={styles.description}>
                {item.description.map((desc, index) => 
                 <h4 key={index} style={{fontSize:"1rem",fontWeight:"bold"}}>{desc}</h4>)}</div>
              
          </div>
       
        </div>
      ))}
    </div>
  );
};
