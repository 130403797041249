import React from 'react'

export default function Terms() {
  return (
    <div style={{padding:"1rem",borderRadius:"5px"}}>
    <p>Please read the "Autonomy Terms of Use & Autonomy Liability Disclaimer" carefully before use of any of our products and services, including the Resource, Guidance and any videos on the website, mobile app or social media channels.</p>

    <h2>Conditions of Use</h2>
    <p>By visiting the website, downloading the mobile app or following the social media channels you agree with the terms of service and liability disclaimer</p>

    <h2>Copyright</h2>
    <p>Content published on this website (digital downloads, images, texts, graphics, videos, logos and teachings) is the property of Autonomy and/or its content creators and is protected by international copyright laws. The entire compilation of the content found on this website is the exclusive property of Autonomy, with copyright authorship for this compilation by Autonomy.</p>

    <h2>Communications</h2>
    <p>The entire communication with us is electronic. Every time you send us an email or visit our website, you are going to be communicating with us. You hereby consent to receive communications from us. If you subscribe to the newsletter on our website, you are going to receive regular emails from us. We will continue to communicate with you by posting news and notices on our website and by sending you emails. You also agree that all notices, disclosures, agreements and other communications we provide to you electronically meet the legal requirements that such communications be in writing.</p>

    <h2>Applicable Law</h2>
    <p>By visiting this website, you agree that the laws of New York, USA without regard to principles of conflict of laws, will govern these terms of service, or any dispute of any sort that might come between Autonomy and you, or its business partners and associates.</p>

    <h2>Disputes</h2>
    <p>Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court in New York, USA and you consent to the exclusive jurisdiction and venue of such courts.</p>

    <h2>License and Site Access</h2>
    <p>Autonomy permits users to access and make personal use of its content. Users are not permitted to download, copy, modify, or distribute with commercial intent any of Autonomy proprietary products.</p>

    <h2>User Account</h2>
    <p>Users are responsible for maintaining the confidentiality of their user details (username and password). Users are responsible for all activities that occur under their account. Autonomy reserves the right to terminate accounts, edit or remove content or cancel orders.</p>

    <h2>Autonomy Liability Disclaimer</h2>
    <p>By using the website, mobile app, products and/or services of Autonomy, the user releases Autonomy (including its board, officers, employees and other users) jointly and severally from any and all actions, causes of actions, claims and demands for, upon or by reason of any damage, loss or injury, which hereafter may be sustained, perceived, hinted or indicated by participating, subscribing, using or trying out our products and services. Autonomy does not provide liability insurance for protection of users who visit and/or use its website, mobile app, products and/or services.</p>
    <p>Please note that users who have a history of mental illness or brain disorder such as epilepsy should consult their doctor before using Autonomy. Autonomy uses audio frequencies and visual effects which could affect users with mental illness or brain disorders. Autonomy is not liable if they choose to proceed at their own risk.</p>

    <h2>Privacy Policy</h2>
    <p>All user information is kept private.</p>
    <p>Autonomy Users own their own data. At any point, a user can delete their data by going within the app to Settings - Delete My Account.</p>
    <p>Autonomy respects and is committed to maintaining your privacy. The data entered by any user is kept confidential.</p>
    </div>
  )
}
