import React from 'react';

import { dfin } from 'content/constants';
import { formatNumber } from 'utils/utilGeneral';
import { WatchListAddButton } from 'watchlist/WatchListAddButton';

import { Typography } from '@mui/material';

import CompanyESGButton
  from '../analytics/ticker/stocks/Overview/CompanyESGButton';

export default function TickerPriceBar({data,price}) {
    // const { price } = useRealTimePrice({ tickerSel: data?.[dfin.tickerCode] });
  return (
    <div style={{display:"flex",gap:"1rem",flexWrap:"wrap"}}>
    <div style={{display:"flex",gap:"1rem",flex:1,flexWrap:"wrap"}}>
    <div style={{display:"flex",gap:"0.5rem",alignItems:"center",flexWrap:"wrap"}}>
    {data?.[dfin.coLogo] && (
        <a style={{display:"flex",alignItems:"center",justifyContent:"center"}} target="blank" href={data?.[dfin.coWebsite]}>
          <img
            src={data?.[dfin.coLogo]}
            height={"35px"}
            width={"35px"}
          />
        </a>
      )}
    <Typography style={{fontWeight:"bold"
    ,overflow:"hidden",textOverflow:"ellipsis"
    ,whiteSpace:"nowrap",flex:1,maxWidth:"100%",fontSize:"1.2rem"}}>
      {data?.[dfin.coName]} 
    </Typography>
    <Typography style={{fontWeight:"bold"
    ,overflow:"hidden",textOverflow:"ellipsis"
    ,whiteSpace:"nowrap",maxWidth:"100%",fontSize:"1.2rem"}}>
      ({data?.[dfin.ticker]})
    </Typography>
   
    </div>
              <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 5,
                marginLeft:"0.5rem"
              }}
            >
              {/* <div>Ask:Bid</div> */}
  
              <Typography style={{fontSize:"1rem",fontWeight:"bold"}}>{formatNumber(price?.close)}</Typography>
  
              <Typography
                style={{
                  color: price?.change > 0 ? "green" : "red",
                  display: "flex",
                  gap: "0.2rem",
                  fontSize:"0.9rem",fontWeight:"bold",whiteSpace:"nowrap"
                }}
              >
                {price?.change > 0 ? "▲" : "▼"} {formatNumber(price?.change)} (
                {formatNumber(price?.change_p)}%)
              </Typography>
            </div>
             <CompanyESGButton coInfo={data}/>
            </div>
            <div>
            <WatchListAddButton tickerName={data?.[dfin.ticker]}/>
            </div>
            </div>
  )
}
