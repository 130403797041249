import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import { defaultMessageList } from 'appState/initialStateAssistant';
import FinancialVaultModal from 'financialVault/FinancialVaultModal';
import { debounce } from 'lodash';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ExploreIcon from '@mui/icons-material/Explore';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import {
  Button,
  IconButton,
  Paper,
} from '@mui/material';

import useHandleUserEvents from './useHandleUserEvents';

export default function MessageBarT({dropdownPosition = 'top', sx = {} }) {
  const { stateApp, dispatchApp, stateAssistant, dispatchAssistant } = useAppInfo();
  const { showContent, voiceRecording, chatStatus, chatGptMessage,chatHistory} = stateAssistant;
  const userRole = stateApp?.onboarding?.userRole
  
  // Track the text input and filtered messages.
  const [input, setInput] = useState("");
  const [filteredMessages, setFilteredMessages] = useState([]);
  // focusedIndex remains -1 until user explicitly navigates using arrow keys.
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [openFinancialVault,setOpenFinancialVault] = useState(false)

  const {
    handleSend,
    handleVoiceRecording,
    handleShowContent,
    handleStopChat,
    handleExploreQuestions
  } = useHandleUserEvents();

  // Ref for the contenteditable div.
  const contentEditableRef = useRef(null);
  // Ref for storing button references (used for dropdown suggestions)
  const buttonRefs = useRef([]);

  // Update state when chatGptMessage changes
  useEffect(() => {
    setInput(chatGptMessage);
    if (contentEditableRef.current) {
      contentEditableRef.current.textContent = chatGptMessage || "";
    }
  }, [chatGptMessage]);

  // Debounced filtering for dropdown suggestions.
  const debouncedFilterMessages = useCallback(
    debounce((value) => {
      if (value) {
        try {
        // const user_messageList = chatHistory.map((msg)=>msg?.chats?.map((chat)=>chat.role === "user" ? chat.content : null)).flat().filter((msg)=>msg !== null)
        const messageList = [...defaultMessageList]
          const regex = new RegExp(value, 'i');
          const matches = messageList.filter((msg) => regex.test(msg));
          setFilteredMessages(matches);
        } catch (error) {
          console.error("Invalid regular expression:", error);
          setFilteredMessages([]);
        }
      } else {
        setFilteredMessages([]);
      }
      // Do not update focusedIndex here.
    }, 300),
    []
  );

  useEffect(() => {
    debouncedFilterMessages(input);
  }, [input, debouncedFilterMessages]);

  // Keydown handling: update focusedIndex only if arrow keys are used.
  const handleKeyDown = (e) => {
    if (filteredMessages.length > 0) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        // If no suggestion has been selected yet, start at 0.
        if (focusedIndex === -1) {
          setFocusedIndex(0);
        } else {
          setFocusedIndex((prevIndex) => (prevIndex + 1) % filteredMessages.length);
        }
        return;
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        if (focusedIndex === -1) {
          setFocusedIndex(filteredMessages.length - 1);
        } else {
          setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredMessages.length) % filteredMessages.length);
        }
        return;
      }
    }
    if (e.key === "Enter") {
      // Allow new line if Shift key is pressed
      if (e.shiftKey) {
        return; // Let the default behavior happen (new line)
      }
      
      e.preventDefault();
      // If a suggestion is selected via arrow keys, use it.
      if (filteredMessages.length > 0 && focusedIndex !== -1) {
        const selectedMessage = filteredMessages[focusedIndex];
        dispatchAssistant({ chatGptMessage: selectedMessage });
        handleSend(selectedMessage);
        setInput("");
        if (contentEditableRef.current) {
          contentEditableRef.current.textContent = "";
        }
        setFilteredMessages([]);
        setFocusedIndex(-1);
      } else {
        // Otherwise, send the user's typed message.
        handleSendMessage();
        setFilteredMessages([]);
        setFocusedIndex(-1);
      }
    }
  };

  // No auto-focus on suggestion buttons unless arrow navigation is used.
  // (Remove any useEffect that calls .focus() on buttonRefs.)

  function handleSendMessage() {
    if (stateApp.isLoggedIn) {
      if (chatStatus !== "running") {
        setInput("");
        if (contentEditableRef.current) {
          contentEditableRef.current.textContent = "";
        }
      }
      handleSend(input);
      handleShowContent(true);
    } else {
      dispatchApp({ loginModal: true });
    }
  }

  // onInput handler for the contenteditable element.
  const handleInput = (e) => {
    const div = e.currentTarget;
    const textContent = div.textContent.trim();

    if (textContent === "" && div.innerHTML.includes("<br>")) {
      div.innerHTML = "";
    }

    if (textContent === "") {
      div.classList.add("show-placeholder");
    } else {
      div.classList.remove("show-placeholder");
    }
    const text = div.textContent;
    setInput(text);
    setFocusedIndex(-1);
  };

  // Dropdown styling.
  const dropdownStyle = {
    position: "absolute",
    left: 0,
    width: "100%",
    maxHeight: "200px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
    backgroundColor: "white",
    zIndex: 1000,
    // minHeight: "200px",
    ...(dropdownPosition === 'top'
      ? { bottom: "105%" }
      : { top: "105%" }
    )
  };

  return (
    <div 
    id={"assistant"}
    style={{display: 'flex', flexDirection: 
        'column', 
    width: "50%", minWidth: "40vw", ...sx }}>
      {/* <div>
      <RelatedQuestions/>
        </div> */}
      <div
        onKeyDown={handleKeyDown}
        style={{
          position: "relative",
          background: "white",
          borderRadius: "16px",
          padding: "0.5rem",
          minHeight:"50px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
          width: "100%",
          boxSizing: "border-box"
        }}
      >
        {/* Contenteditable Message Input */}
        <div
          ref={contentEditableRef}
          contentEditable={true}
          onInput={handleInput}
          // onPaste={(e) => {
          //   e.preventDefault();
          //   const text = e.clipboardData.getData('text/plain').trim();
          //   const selection = window.getSelection();
          //   const range = selection.getRangeAt(0);
          //   range.deleteContents();
          //   range.insertNode(document.createTextNode(text));
          // }}
          onClick={() => handleShowContent(true)}
          suppressContentEditableWarning={true}
          style={{
            padding: "0.5rem",  
            borderRadius: "8px",
            minHeight: "30px",
            maxHeight: "300px",
            overflowY: "auto",  
            fontSize: "1rem",
            outline: "none",
            width: "100%",
            boxSizing: "border-box",
            wordBreak: "break-word"
          }}
          data-placeholder={"Ask Quinn - your AI Assistant"}
        />

        {/* Options Row Below */}
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", gap: "0.5rem" }}>
            {userRole !== "advisor" && <Button 
              variant='outlined'
              sx={{ 
                minWidth: '0',
                borderColor: "#e0e0e0",
                display: "flex",
                gap: "0.25rem", 
                alignItems: "center",
                padding: "0.25rem 0.75rem",
                color: "gray",
                fontSize: "0.8rem",
                borderRadius: "25px",
                textTransform: "none",
                height: "max-content",
                transition: "all 0.2s ease",
                backgroundColor: "white",
                boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                  borderColor: "black",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  "& .MuiSvgIcon-root": {
                    color: "white"
                  }
                }
              }} 
              onClick={()=>setOpenFinancialVault(true)}
            >
              <DriveFolderUploadIcon sx={{fontSize:"0.8rem", color: "gray", transition: "color 0.2s ease"}}/>
              My Vault
            </Button>}
            <Button 
              variant='outlined'
              sx={{ 
                minWidth: '0',
                borderColor: "#e0e0e0",
                display: "flex",
                gap: "0.25rem", 
                alignItems: "center",
                padding: "0.25rem 0.75rem",
                color: "gray",
                fontSize: "0.8rem",
                borderRadius: "25px",
                textTransform: "none",
                height: "max-content",
                transition: "all 0.2s ease",
                backgroundColor: "white",
                boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                  borderColor: "black",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  "& .MuiSvgIcon-root": {
                    color: "white"
                  }
                }
              }} 
              onClick={handleExploreQuestions}
            >
              <ExploreIcon sx={{fontSize:"0.8rem", color: "gray", transition: "color 0.2s ease"}}/>
              Explore
            </Button>
          </div>

          {chatStatus === "running" ? (
            <IconButton 
              onClick={handleStopChat} 
              sx={{ 
                minWidth: 0,
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#333',
                }
              }}
            >
              <StopIcon fontSize='small'/>
            </IconButton>
          ) : (
            <IconButton
              className={voiceRecording ? "recording" : ""}
              style={{ background: !input ? "#f0f0f0" : "black", color: !input ? "black" : "#f0f0f0" }}
              onClick={!input ? handleVoiceRecording : handleSendMessage}
            >
              {!input ? <MicIcon fontSize='small'/> : <ArrowUpwardIcon fontSize='small'/>}
            </IconButton>
          )}
        </div>

        {/* Filtered Messages Dropdown */}
        {filteredMessages.length > 0 && (
          <Paper style={dropdownStyle}>
            {filteredMessages.map((message, index) => (
              <Button
                disableFocusRipple
                key={index}
                ref={(el) => (buttonRefs.current[index] = el)}
                onClick={(e) => {
                  e.preventDefault();
                  dispatchAssistant({ chatGptMessage: message });
                  handleSend(message);
                  setInput("");
                  if (contentEditableRef.current) {
                    contentEditableRef.current.textContent = "";
                  }
                  setFilteredMessages([]);
                  setFocusedIndex(-1);
                }}
                sx={{
                  textAlign: 'left',
                  width: '100%',
                  color: '#333',
                  justifyContent: "flex-start",
                  padding: "0.25rem 1rem",
                  background: focusedIndex === index ? '#f0f0f0' : 'transparent'
                }}
              >
                {message}
              </Button>
            ))}
          </Paper>
        )}
      </div>
      <FinancialVaultModal open={openFinancialVault} onClose={()=>setOpenFinancialVault(false)}/>
    </div>
  );
}