import { useAppInfo } from 'AppState';
import React, { useEffect, useState } from 'react'

import { calculateRetirementAmountActual } from './calculateRetirmentAmountActual';
import CollapseToggler from 'components/Buttons/CollapseToggler';
import styles from '../RetirmentEarliest/RetirementEarliest.module.css';
import NumberInput from 'components/Inputs/NumberInput';
import { Button } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import AISummaryBox from 'chatGPT/AISummaryBox';

export default function RetirementAmount() {
  const {stateMyFinancials} = useAppInfo()
  
    const { assets, cashflowDistribution, expenseMonthly,goalsPeriodic,financialsLoaded,financialsUpdated} = stateMyFinancials;
    const [ageDetails, setAgeDetails] = useState({"currentAge":40,retirementAge:65,deathAge:100,}) 
    const [results, setResults] = useState([]);
    const [summary, setSummary] = useState({});
    const [submit, setSubmit] = useState(false);
    useEffect(()=>{
        if (!financialsLoaded || !financialsUpdated || !submit) return

        const  { schedule, summary } = calculateRetirementAmountActual({
          currentAge:ageDetails.currentAge,
          retirementAge:ageDetails.retirementAge,
          deathAge:ageDetails.deathAge,assets,expenses:expenseMonthly,goals:goalsPeriodic});
        //   console.log({schedule,summary})
        setResults(schedule);
        setSummary(summary);
    },[submit,ageDetails,assets,expenseMonthly,goalsPeriodic,financialsLoaded,financialsUpdated])
    
    useEffect(()=>{
        if (results.length>0) setSubmit(false);
    },[results])
    function handleSubmit(){
        setSubmit(true);
    }

    const ageInputs = [{key:"currentAge","label":"Current Age","value":ageDetails.currentAge},
        {key:"retirementAge", "label":"Retirement Age","value":ageDetails.retirementAge},
        {key:"deathAge","label":"Death Age","value":ageDetails.deathAge}];
    
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"1rem",borderRadius:"10px",boxSizing:"border-box"}}>
        <form style={{display:"flex",flexDirection:"row",gap:"1rem",flexWrap:"wrap"}} onSubmit={(e)=>{e.preventDefault();handleSubmit()}}>
        
        {ageInputs.map((input, index) => (
            <div key={index}>
                <NumberInput
                size='medium'
                variant='outlined'
                sx={{width:"150px"}}
                label={input.label}
                value={input.value}
                onChange={(value) => {
                    setAgeDetails({ ...ageDetails, [input.key]: value });
                }}
                />
            </div>
        ))}
        <Button sx={{height:"max-content"}} variant='contained' type='submit'>Calculate</Button>
        </form>
        
        {results.length>0 && 
        <>
        <AISummary ageDetails={ageDetails} summary={summary} results={results}/>
        <RetirementTable data={results}/>
        </>}
    </div>
  )
}

function AISummary({ageDetails,summary,results}){
    const currentYear = new Date().getFullYear();
    const message = `Want to know how much assets do I need to retire with? Should I use Asset withdrawal or Asset Return? Are current Asset base and returns sufficient and sustainable? 
    Give me insight in less than 150 words.
    Here are the details: ${JSON.stringify({currentYear,ageDetails,results})}. Make the response easy to understand and engaging.`
    return (<AISummaryBox usePromptStandard={false} message={message}>

    </AISummaryBox>)
}


function RetirementTable({ data }) {
    const [expandedRows, setExpandedRows] = useState([]);
  
    const toggleRow = (startYear) => {
      if (expandedRows.includes(startYear)) {
        setExpandedRows(expandedRows.filter((year) => year !== startYear));
      } else {
        setExpandedRows([...expandedRows, startYear]);
      }
    };
  
    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Year - Retirement Age</th>
            <th>Total Needs (Expenses + Goals)</th>
            <th>Asset Return ($)</th>
            <th>Asset Amount ($)</th>
            <th>Annual Required Return on Asset Amount</th>
            <th>Asset Required to use Returns for Funding</th>
            <th>Asset Required to Withdraw Assets for Funding</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <React.Fragment key={row.currentYear}>
              <tr onClick={() => toggleRow(row.currentYear)} className={styles.tableRow}>
                <td style={{whiteSpace:"nowrap"}}>
                    {/* <CollapseToggler/> 
                 */}
                {row.currentYear} - {row.age}</td>
                <td>${row.totalNeeds.toLocaleString()}</td>
                <td>${parseFloat(row.assetReturn).toLocaleString()}</td>
                <td>${parseFloat(row.assets).toLocaleString()}</td>
                <td>{row.requiredReturn}</td>
                <td>{row.assetsRequiredForReturn}
                </td>
                <td>{row.assetsRequiredForWithdrawal} </td>
              </tr>
              {/* {expandedRows.includes(row.currentYear) && (
                <tr className={styles.expandedRow}>
                  <td colSpan="9">
                    <CashflowAreaChart
                      simulationDetails={row.simulationDetails}
                      startYear={row.currentYear}
                      probReturn={row.probReturn}
                      probWithdrawal={row.probWithdrawal}
                    />
                  </td>
                </tr>
              )} */}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  }
  


  function CashflowAreaChart({ simulationDetails, startYear }) {
    if (!simulationDetails || simulationDetails.length === 0) return null;
  
    const categories = simulationDetails.map((d) => d.year.toString());
  
    const expensesGoals = simulationDetails.map((d) => d.totalNeeds);
    const assetReturn = simulationDetails.map((d) => parseFloat(d.assetReturn));
    const assetWithdrawal = simulationDetails.map((d) => parseFloat(d.assetsRequiredForWithdrawal));
    const assetAmount = simulationDetails.map((d) => parseFloat(d.assets));
  
    const options = {
      chart: {
        type: 'column',
      },
      title: {
        text: `Cashflow Details Starting in ${startYear}`,
      },
      xAxis: {
        categories: categories,
        title: { text: 'Year' },
      },
      yAxis: {
        title: { text: 'Amount ($)' },
      },
      tooltip: {
        shared: true,
        valuePrefix: '$',
      },
      plotOptions: {
        area: {
          stacking: 'normal',
        },
      },
      series: [
        {
          name: 'Expenses + Goals',
          data: expensesGoals,
          color: '#FF6384',
        },
        {
          name: 'Asset Return',
          data: assetReturn,
          color: '#36A2EB',
        },
        {
          name: 'Asset Withdrawal',
          data: assetWithdrawal,
          color: '#FFCE56',
        },
        {
          name: 'Asset Amount',
          data: assetAmount,
          color: '#4BC0C0',
          type: 'line',
          marker: {
            enabled: true,
          },
        },
      ],
      credits: { enabled: false },
    };
  
    return (
      <div className={styles.cashflowChartContainer}>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <div className={styles.chartExplanation}>
          <p>
            <strong>Probability Explanation:</strong> 
            <br />
            {/* <strong>Returns Only:</strong> You have a {probReturn}% probability of sustaining your expenses and goals
            solely through asset returns from the retirement start year.
            <br />
            <strong>Withdrawal:</strong> You have a {probWithdrawal}% probability of sustaining your expenses and goals
            by utilizing both asset returns and withdrawals from the principal. */}
          </p>
          <p>
            This chart shows how your assets are utilized over the years. The <strong>Asset Amount</strong> line indicates the remaining assets each year after accounting for returns and withdrawals.
          </p>
        </div>
      </div>
    );
  }