import React from 'react';

import Correlation from 'analytics/technicals/general/Correlation';
import { useAppInfo } from 'AppState';
import PriceChartMulti from 'components/Charts/PriceChartMulti';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import AddPeersBar from 'peerList/AddPeersBar';

import Beta from './general/Beta';
import PerformanceClose from './general/PerformanceClose';
import Volatility from './general/Volatility';
import IndicatorUI from './indicators/IndicatorUI';

export default function Technicals({tabName,type="all",addTicker=false}) {
    const {stateTicker} = useAppInfo()
    let peerSelected = stateTicker.peerSelectedDisplay
    if (type==="company"){
      peerSelected = stateTicker.peerSelected
    }
    const {close,day,marketInfoLoading} = useMultiTickerInfo(peerSelected)

  const tabs = {
    Trend: (
        <PriceChartMulti
          close={close}
          marketInfoLoading={marketInfoLoading}
          tickerList={peerSelected}
        />
      ),
      Indicators: <IndicatorUI tickerList={peerSelected}/>,
      Performance: (
        <PerformanceClose
          close={close}
          marketInfoLoading={marketInfoLoading}
          tickerList={peerSelected}
        />
      ),
      Volatility: (
        <Volatility
          close={close}
          marketInfoLoading={marketInfoLoading}
          tickerList={peerSelected}
        />
      ),
      Correlation: <Correlation tickerList={peerSelected} />,
      Beta: <Beta tickerList={peerSelected} />,
  }
 
  return (
    <div className='containerColumnFlex'>
    <div className='containerColumnFlex1'>
    {tabs[tabName]}
    </div>
    <AddPeersBar/>
    </div>
  )
}
