import React, { useState } from 'react';

import { firebaseMedia } from 'content/media/firebaseMedia';

import { Add } from '@mui/icons-material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Avatar,
  Button,
  Divider,
} from '@mui/material';

const SocialMediaFeed = () => {
  const [posts, setPosts] = useState([
   
    {
      id: 1,
      author: "Empowering Elise",
      role: "Financial Planner",
      profileImage:firebaseMedia?.demoprofile_gabriel,
      time: "7h",
      text: `💰 "Your Budget Is Your Best Friend." 💰
The 50/30/20 rule:
👉 50% for needs
👉 30% for wants
👉 20% for savings & investments
A simple formula for a balanced financial life. Are you following it?`,
      imageUrl: firebaseMedia?.social_financialPlanning,
      likes: 20,
      comments: 2,
    },
    {
      id: 2,
      author: "Steady Stephan",
      role: "Investor",
      profileImage:firebaseMedia?.demoprofile_stephan,
      time: "7h",
      text: `📊 "Be a Business Owner, Not Just a Trader." 📊
Buying a stock means owning a piece of the business. Understand the company, its market, and its growth potential before investing. Patience beats panic every time! 🧘‍♂️`,
      imageUrl: firebaseMedia?.social_stocks,
      likes: 20,
      comments: 2,
    },
    {
      id: 3,
      author: "Techie Tany",
      followers: "18,034,313",
      profileImage:firebaseMedia?.demoprofile_harper,
      role:`Crypto Enthusiast`,
      time: "1h",
      text: `🚀 "Crypto Is More Than Just Bitcoin." 🚀
From Ethereum powering decentralized apps to Solana enabling lightning-fast transactions, the crypto space is redefining how we see finance.`,
      imageUrl: firebaseMedia?.social_crypto,
      likes: 102,
      comments: 35,
    },
  ]);

  const [showMenu, setShowMenu] = useState(null);

  const toggleMenu = (postId) => {
    setShowMenu(showMenu === postId ? null : postId);
  };

  const styles = {
    container: {
      maxWidth: '600px',
      width:"600px",
      // margin: '0 auto',
      // width:"100%",
      boxSizing:"border-box",
      padding: '20px',
      display:"flex",flexDirection:"column",gap:"1rem"    },
    post: {
      backgroundColor: '#fff',
      padding: '15px',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      position: 'relative',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    author: {
      fontWeight: 'bold',
    },
    role: {
      fontSize: '0.85em',
      color: 'gray',
    },
    text: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    image: {
      width: '100%',
      borderRadius: '8px',
      maxWidth: "100%",
      maxHeight:"400px",
      objectFit: 'contain',
      marginBottom: '10px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '10px',
    },
    actionButton: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: '0.9em',
      color: 'gray',
    },
    menu: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1,
    },
    menuItem: {
      padding: '8px 0',
      cursor: 'pointer',
    },
  };
 
  return (
    <>
   
    <div style={styles.container}>
   
      {posts.map((post) => (
        <div key={post.id} style={styles.post}>
          <div style={{display:"flex",position:"relative",padding:"0"}}>
          <div style={{flex:1}}></div>
          <div onClick={() => toggleMenu(post.id)} style={{ cursor: 'pointer' }}>
             <MoreHorizIcon/>
            </div>
            {showMenu === post.id && (
              <div style={styles.menu}>
                <div style={styles.menuItem}>Save</div>
                <div style={styles.menuItem}>Copy link to post</div>
                <div style={styles.menuItem}>Not interested</div>
                <div style={styles.menuItem}>Unfollow</div>
                <div style={styles.menuItem}>Report post</div>
              </div>
            )}
          </div>
  
          <div style={styles.header}>
            <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
            <Avatar
            src={post?.profileImage}
            style={{
              cursor: "pointer",
              height: "25px",
              width: "25px",
              backgroundColor: "#gray",
              color: "black",
              fontSize: "0.8rem",
            }}
          >
            {post?.author && post?.author[0]}
          </Avatar>
              <span style={styles.author}>{post.author}</span> {post.role && <span style={styles.role}>· {post.role}</span>}
              <span style={styles.role}> · {post.time}</span>
            
            </div>

            <Button sx={{minWidth:"0",}} startIcon={<Add/>}>Follow</Button>
           

          </div>
          <p style={styles.text}>{post.text}</p>
          <div style={{maxHeight:"500px",overflow:"hidden",width:"100%"}}>
          {post.imageUrl && <img src={post.imageUrl} alt="Post" style={styles.image} />}
          </div>
          <Divider/>
          <div style={styles.actions}>
            <div style={styles.actionButton}>
              <span role="img" aria-label="like">👍</span> Like {post.likes}
            </div>
            <div style={styles.actionButton}>
              <span role="img" aria-label="comment">💬</span> Comment {post.comments}
            </div>
            <div style={styles.actionButton}>
              <span role="img" aria-label="repost">🔄</span> Repost
            </div>
            <div style={styles.actionButton}>
              <span role="img" aria-label="send">✉️</span> Send
            </div>
          </div>
        </div>
      ))}
    </div>
    </>
  );
};

export default SocialMediaFeed;
