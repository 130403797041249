import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import {
  assetCode,
  dfin,
} from 'content/constants';

import PriceTable from '../components/Table/PriceTable';

export default function PeerPriceTable() {
  const {stateTicker,dispatchTicker} = useAppInfo();
  let peerInfo = stateTicker.tickerInfo;
  let peerSelected = stateTicker.peerSelectedDisplay;

  const [tickerInfo, setTickerInfo] = useState([]);

  useEffect(() => {
    const uniqueTickers = new Set(); // Create a Set to store unique tickers
    const filteredPeerInfo = peerInfo.filter((peer) => {
      const ticker = peer[dfin.ticker];
      if (peerSelected.includes(ticker) && !uniqueTickers.has(ticker)) {
        uniqueTickers.add(ticker); // Add the ticker to the Set to mark it as seen
        return true; // Include the peer if it's selected and its ticker is unique
      }
      return false; // Exclude the peer if it doesn't meet the criteria
    });

    setTickerInfo(filteredPeerInfo);
  }, [peerSelected, peerInfo]);

  function handleDeletePeers(value) {
    const updatedPeerList = peerSelected.filter((peer) => peer !== value);
    if (stateTicker.tickerType === assetCode?.Stocks) {
        dispatchTicker({"peerSelected":updatedPeerList});
      }

    dispatchTicker({"peerSelectedDisplay":updatedPeerList,"peersIn":"CUSTOM"});
    }

  return (
    <PriceTable 
    data={tickerInfo}
    handleDelete={handleDeletePeers}
    >PeerPriceTable</PriceTable>
  )
}
