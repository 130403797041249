import React, { useState } from 'react';

import { useAppInfo } from 'AppState';
import MyAdvisoryTeam from 'finanaceProfessional/MyAdvisoryTeam';
import FinancialVaultConnect from 'financialVault/FinancialVaultConnect';
import MyFinancialHealthScore
  from 'myFinancials/FinancialHealth/MyFinancialHealthScore';
import GoalsSuccessRate from 'myFinancials/Goals/GoalsSuccessRate';
import MyFinancialCardNew from 'myFinancials/MyFinancialCardNew';
import MyFinancialInsightQuestions
  from 'myFinancials/MyFinancialInsightQuestions';
import ProfileCompletion from 'myFinancials/ProfileCompletion';
import TodoNotificationHome from 'myFinancials/Todos/TodoNotificationHome';
import PortfolioFitNotification from 'opportunities/PortfolioFitNotification';

import AdvisorHome from '../b2b/AdvisorHome';

export default function HomeInsights() {
  const {stateApp,stateMyFinancials} = useAppInfo()
  const {username, onboarding} = stateApp
  const {userRole} = onboarding
  const {financialView,demoProfile} = stateMyFinancials
  const [tabIndex,setTabIndex] = useState(0)

  // Render advisor home page if user is an advisor
  if (userRole === 'advisor') {
    return <AdvisorHome />
  }

  return (
    <div style={{display:"flex"
    ,flexDirection:"column",alignItems:"center"
    ,gap:"1.5rem",padding:"1rem 2rem 2rem 2rem",boxSizing:"border-box",minHeight:"100%",width:"100%"}}>
      {/* <h3>Hi, {username}</h3>
      <div style={{maxWidth:"1000px"}}>
      <MessageBar dropdownPosition='bottom' sx={{background:"#fafafa",boxShadow:"none",maxWidth:"auto",borderRadius:"10px"}}/>
      </div> */}
      {/* <div style={{display:"flex",gap:"1rem",alignItems:"center",background:"#fafafa"
        ,borderRadius:"25px",padding:"0.5rem",minWidth:"200px"}}>
        <ProfileLogo/>
        {username}

      </div>
      <TabLine tabsSx={{borderBottom:"none"}} variant='noline' 
      tabList={["My Financials","Market Place","Investments","Social","Education","Support","About"]}
      tabIndex={tabIndex}
      handleTabChange={(i)=>setTabIndex(i)}
      /> */}
      {/* <div style={{display:"flex",gap:"1rem"}}>
      <FinancialVaultConnect/>
      <MyAdvisoryTeam/>
      </div> */}
     <div style={{maxWidth:"100%",display:"flex",gap:"2rem",justifyContent:"center",flexWrap:"wrap",borderRadius:"10px",padding:"1rem",boxSizing:"border-box"}}>
     <div style={{minWidth:"300px",boxSizing:"border-box",width:"500px",display:"flex",flexDirection:"column",gap:"1rem",borderRadius:"5px"}}>
    
     <MyFinancialCardNew/>
     <FinancialVaultConnect/>
     <MyAdvisoryTeam/>
     
     </div>
     <div style={{padding:"1rem",borderRadius:"10px",display:"flex"
      ,flexDirection:"column",gap:"1rem",height:"max-content",justifyContent:"flex-start"
      ,minWidth:"400px",flex:1,maxWidth:"500px",boxSizing:"border-box"}}>
       
     <TodoNotificationHome/>
     <ProfileCompletion displayType='main'/>
     <MyFinancialHealthScore displayType='home' scoreHeight='60px' scoreWidth='60px'/>
     <GoalsSuccessRate displayType='home' scoreHeight='60px' scoreWidth='60px'/>
     <PortfolioFitNotification/>

     {/* <MyFinancialInsightQuestions/> */}
     {/* <MyAdvisoryTeam/> */}
    
     {/* <AutomateFinancialNotification/> */}
     
     </div>
    
     </div>

     {/* <AISummaryBox 
     id={"home"}
     sx={{width:"950px",background:"#fafafa"}} 
     message={"Give me a key insights about my financials"}/> */}

      {/* <AdditionalStuff/> */}
     </div>
  )
}



export function AdditionalStuff(){
  return (
    <>
        <div style={{maxWidth:"100%",display:"flex",gap:"2rem",justifyContent:"center",flexWrap:"wrap",borderRadius:"10px",padding:"1rem",boxSizing:"border-box"}}>
     <div style={{minWidth:"500px",background:"#fafafa",boxSizing:"border-box"
      ,width:"1000px",padding:"1rem",boxSizing:"border-box"
      ,display:"flex",flexDirection:"column",gap:"1rem",borderRadius:"5px"}}>
     <MyFinancialInsightQuestions/>
     </div>
     </div>

    </>
  )
}