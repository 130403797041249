import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppInfo } from 'AppState';
import useSearchTopic from 'data/resource/useSearchTopic';
import useTopTopics from 'data/resource/useTopTopics';


export default function SearchInfo({searchTerm}) {
    const [topicListF,setTopicListF] = useState()
    const {searchTopic, topicList, error,loadingTopic} = useSearchTopic()
    const {topTopics, topTopicList,loadingTopTopic} = useTopTopics()
    const {dispatchSearch} = useAppInfo()

  useEffect(()=>{
    topTopics()
  },[])
  useEffect(()=>{
    if (searchTerm !== ""){
    searchTopic(searchTerm)}
  },[searchTerm])
  useEffect(()=>{
    if (topicList){
    setTopicListF(topicList)}
  },[topicList])
  useEffect(()=>{
    if (topTopicList){
    setTopicListF(topTopicList)}
  },[topTopicList])


  const navigate = useNavigate()
  const location  = useLocation()
  function handleRC(obj){
    const encodedTopic = encodeURIComponent(obj.Topic)
    const encodedHeading = encodeURIComponent(obj.Heading)
    const encodeSubTopic = encodeURIComponent(obj?.["Sub Topic"])
    navigate({pathname:`/Resource/Learn`,search:`?topic=${encodedTopic}&subTopic=${encodeSubTopic}&heading=${encodedHeading}`})
  
    dispatchSearch({
      searchClick: false,
    });
    

  }
  const [mouseEnter,setMouseEnter] = useState({})


  return (


<>
   <div style={{maxHeight:"100%",overflow:"auto",width:"100%"}}>

  <>
  {topicListF && topicListF.map((obj,index)=>
          <h4 onMouseEnter={()=>setMouseEnter({heading:obj.Heading,enter:true})} 
          onMouseLeave={()=>setMouseEnter({heading:obj.Heading,enter:false})}
          onClick={()=>handleRC(obj)} 
          style={{cursor:"pointer", borderBottom:"1px solid #ddd",color:"black",padding:"0.5rem",
          background:(mouseEnter.heading===obj.Heading && mouseEnter.enter) && "#fafafa",
          fontWeight:(mouseEnter.heading===obj.Heading && mouseEnter.enter) && "bold"}} 
          key={index}>{obj.Heading}</h4>
        )}
  </>
  </div>

  </>
  )
}

    
