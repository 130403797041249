import React, { useState } from 'react';

import { useAppInfo } from 'AppState';
import useHandleSignOut from 'auth/useHandleSignout';
import useUpdateOnboarding from 'data/user/onBoarding/useUpdateOnboarding';
import { useNavigate } from 'react-router-dom';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportIcon from '@mui/icons-material/Support';
import WorkIcon from '@mui/icons-material/Work';
import {
  Avatar,
  Button,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material';

import styles from './ProfileSettings.module.css';

export const RoleSelector = ({setAnchorEl}) => {
  const { stateApp, dispatchApp } = useAppInfo();
  const UpdateOnboarding = useUpdateOnboarding();
  const navigate = useNavigate();
  const { username, onboarding } = stateApp;
  const { userRole } = onboarding;

  const handleRoleChange = async (newRole) => {
    try {
      // Update onboarding state
      const updatedOnboarding = { ...onboarding, userRole: newRole };
      await UpdateOnboarding(username, updatedOnboarding);
      setAnchorEl(null);
      dispatchApp({onboarding: updatedOnboarding});
      navigate("/")
    } catch (error) {
      console.error("Error updating role:", error);
    }
  };

  return (
    <ListItem>
      <ListItemIcon>
        <WorkIcon />
      </ListItemIcon>
      <FormControl fullWidth size="small">
        <Select
          value={userRole}
          onChange={(e) => handleRoleChange(e.target.value)}
          displayEmpty
          MenuProps={{
            sx: { zIndex: 1400 }
          }}
        >
          <MenuItem value="consumer">Individual</MenuItem>
          <MenuItem value="advisor">Advisor</MenuItem>
        </Select>
      </FormControl>
    </ListItem>
  );
};

export default function ProfileSettings({type="short"}) {
    const { stateApp } = useAppInfo();
    const {handleSignOut} = useHandleSignOut();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const { username } = stateApp;

    const handleMenuNav = (v) => {
        navigate(`/${v}`);
        setAnchorEl(null);
    };

    const handleAvatarClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'profile-popover' : undefined;

    const menuOptions = [
        { text: "Home", icon: <HomeIcon /> },
        { text: "Profile", icon: <PersonIcon /> },
        { text: "Support", icon: <SupportIcon /> },
        { text: "Contact", icon: <ContactMailIcon /> }
    ];

    return (
        <>
            <div style={{ position: "relative",width:"100%"}} className="profile" id='userProfile'>
            <Button
            aria-describedby={id}
                    onClick={handleAvatarClick}
                    style={{
                        background: type === "long" && "#3a3a3b",
                        width: "100%",
                        justifyContent: type === "long" ? "flex-start" : "center",
                        color: "white",
                        alignItems: "center",
                        gap: "0.5rem",boxSizing:"border-box",minWidth:"30px"
                    }}
                >
                    <Avatar
                        style={{ height: "30px", width: "30px" }}
                    >
                        {(!stateApp.googlePhoto && !username) && "P"}
                        {(!stateApp.googlePhoto && username) && username?.charAt(0)}
                        {stateApp.googlePhoto && <img src={stateApp.googlePhoto}
                            alt="Profile" style={{ objectFit: "cover" }} width={"100%"} height={"100%"} />}
                    </Avatar>
                    {type === "long" && <span style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{username?.split(" ")[0]}</span>}
                    {type === "long" && <SettingsIcon fontSize='small' style={{ marginLeft: "auto" }} />}
                </Button>
               

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        borderRadius: '15px', 
                        padding: '1rem', 
                        minWidth: '200px',
                        zIndex:1000,
                        '& .MuiPopover-paper': {
                            zIndex: 1000
                        }
                    }}
                >
                    <div className={styles.profileModal}>
                    <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",borderBottom: "1px solid #ddd", }}>
                    <Avatar
                    onClick={handleAvatarClick}
                    style={{ border: open ? "2px solid #00c3ff" : "2px solid black", height: "30px", width: "30px" }}
                >
                    {(!stateApp.googlePhoto && !username) && "P"}
                    {(!stateApp.googlePhoto && username) && username?.charAt(0)}
                    {stateApp.googlePhoto && <img src={stateApp.googlePhoto} alt="Profile" style={{ objectFit: "cover" }} width={"100%"} height={"100%"} />}
                </Avatar>
                        <Typography variant="h6" style={{ paddingBottom: '0.5rem' }}>
                           {username}
                        </Typography>
                    </div>
                    
                        <List>
                            <RoleSelector setAnchorEl={setAnchorEl}/>
                            <Divider />
                            {menuOptions.map((menu, index) => (
                                <ListItem key={index} onClick={() => handleMenuNav(menu.text)}>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.text} />
                                </ListItem>
                            ))}
                            <Divider />
                            <ListItem onClick={handleSignOut}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sign Out" />
                            </ListItem>
                        </List>
                    </div>
                </Popover>
            </div>
        </>
    );
}
