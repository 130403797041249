import React, { useState } from 'react';

import useHandleLogin from 'auth/useHandleLogin';
import GrowFinanciallyToday from 'home/GrowFinanciallyToday';
import HomeFooterBig from 'home/HomeFooterBig';
import QuestionsHome from 'home/QuestionsHome';

const HowItWorks = () => {

  return (
    <div style={{overflow:"hidden",width:"100%",boxSizing:"border-box",position:"relative"
    ,display:"flex",flexDirection:"column"}}>
       <div style={{minHeight:"100vh",padding:"3rem 4rem",width:"100%",boxSizing:"border-box",position:"relative"}}>

       {/* <div style={{top:"0%",zIndex:0,height:"100vh",overflow:"hidden",width:"40%"
        ,position:"absolute",boxSizing:"border-box",right:"0%"}}> 
     
        <img 
        style={{height:"100%",width:"100%",objectFit:"contain",boxSizing:"border-box"}}
        src={firebaseMedia?.landing_goldCoins}>
        
        </img>
       
      </div>
        */}
      
      
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          flex:1,
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: "4rem",fontWeight:"600",textAlign:"center"}}>
        3 Simple Steps <br/>to Financial Autonomy
      </h1>

      <HowAutomatingWorks/>
      </div>
      </div>
    <QuestionsHome/>
    <GrowFinanciallyToday/>
    <HomeFooterBig/>
    </div>
  );
};

export default HowItWorks;


export function HowAutomatingWorks({}){
  const {handleLogin} = useHandleLogin()
   
  const steps = [
    {
      number: "1",
      id:"secureLink",
      title: "Securely link and own your financial data in our digital vault",
      description: "You decide what information you want to share and how you want to share it",
      componentBoxes: [
        { icon: "📄", title: "Manual Input" },
        { icon: "📤", title: "Upload Files" },
        { icon: "🔗", title: "Link Financials" },
      ],
    },
    {
      number: "2",
      id:"airecommends",
      title: "AI recommends your next steps",
      description: "Personalized, prirtized tasks guide your next best move...",
      componentBoxes: [
        { icon: "📊", title: "Start Saving" },
        { icon: "🎯", title: "Diversify Investments" },
        { icon: "💰", title: "Invest Surplus Cash" },
      ],
    },
    {
      number: "3",
      id:"automate",
      title: "Automate financial management workflows",
      description: "Automatically execute each recommended actions like rebalancing portfolios, renewing mortgages, and more...",
      componentBoxes: [
        { icon: "🏦", title: "Automate Savings" },
        { icon: "📈", title: "Automate Investing" },
        { icon: "📝", title: "Automate Financial Applications" },
      ],
    },
  ];
  const [mouseEnter, setMouseEnter] = useState({});
 
  return (
    <div style={{ display: "flex",
      flexDirection: "column",
      gap: "2rem",}}>
        {/* {steps.map((step, index) => (
          <React.Fragment key={index}>
          {index < steps.length - 1 &&
          <Connector from={step.id} to={steps[index + 1]?.id} key={index}/>}
          </React.Fragment>
        ))} */}
          {steps.map((step, index) => (
        <div 
        onPointerEnter={()=>setMouseEnter(step.id)}
        onPointerLeave={()=>setMouseEnter("")}
        id={step.id}
        onClick={handleLogin}
        className="card-hover"
        key={index} style={{background: "#f5f5f5", borderRadius: "12px", 
        overflow: "hidden",cursor:"pointer",position:"relative",width:"750px",maxWidth:"750px",display:"flex",flexWrap:"wrap",padding:"1rem",boxSizing:"border-box"}}>
                 <h1 style={{
  position: "relative",
  top:"0%",
  right: 0,
  zIndex: 1,
  color: mouseEnter === step.id ? "var(--selectBlue)" : "rgba(0, 0, 0, 0.1)",
  fontSize: "6rem",
  lineHeight: "0.8", // Match line-height to font-size
  margin: 0, // Remove default margin
  padding: 0, // Optional, in case of any padding
  alignSelf: "flex-start", // Align to the top
  justifySelf: "flex-start", // Align to the left
  padding:"1rem"
}}>
  {step.number}
</h1>

            <div style={{display:"flex",gap:"1rem",padding:"1rem",flex:1}}>
            
            <div style={{flex:1}}>
            <h2 style={{ fontSize: "1.5rem",
color: mouseEnter === step.id ? "var(--selectBlue)" : "black",
            }}>{step.title}</h2>
            <p style={{ fontSize: "1rem", color: "#555",margin:0}}>{step.description}</p>

          <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap",paddingTop:"2rem"}}>
          {step.componentBoxes.map((box, i) => (
        <div
key={i}
className="card-hover"
style={{
display: "flex",
alignItems: "center",
justifyContent: "center",
padding: "1rem",
borderRadius: "12px",
maxWidth:"150px",
minWidth:"150px",
// boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Soft shadow for a polished look
backgroundColor: "#ffffff",
transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth hover effects
cursor: "pointer",
}}
>
<div style={{ fontSize: "2rem", marginBottom: "0.5rem" }}>{box.icon}</div>
<div style={{ fontSize: "1rem", fontWeight: "bold", color: "#333" }}>
{box.title}
</div>
</div>
    ))}
            </div>
            </div>
        </div>
        </div>
        ))}
   
    </div>
  )
}

