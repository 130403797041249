import React from 'react';

import AutomateEmail from './EmailAutomate/AutomateEmail';
import FormEmail from './FormEmail';

export default function Automate() {
  return (
    <div>Automate
      <FormEmail/>
      <AutomateEmail/>
    </div>
  )
}
