import { getAnalytics } from 'firebase/analytics';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBucUKCjr-1hTClErVAjde1_UmpnxmKITg",
  authDomain: "autonomyfinance.com",
  projectId: "autonomy-finance",
  storageBucket: "autonomy-finance.firebasestorage.app",
  messagingSenderId: "1028224228262",
  appId: "1:1028224228262:web:70e3b7e15469eba4b50d67",
  measurementId: "G-Q7TDQMPQWC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

// setPersistence(auth, inMemoryPersistence)

export default app;
// Create a root reference
export const storage = getStorage();
export const db = getFirestore(app);
// Google Auth Provider
const googleProvider = new GoogleAuthProvider();

export {
  createUserWithEmailAndPassword,
  googleProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
};
