import React, { useRef, useEffect, useState } from 'react';
import styles from './Toggle.module.css';

export default function Toggle({ toggleMode, handleToggle, toggleValues,toggle1info}) {
  // Refs to measure each button
  const leftRef = useRef(null);
  const rightRef = useRef(null);

  // Store measured widths & positions
  const [leftWidth, setLeftWidth] = useState(0);
  const [rightWidth, setRightWidth] = useState(0);
  const [leftX, setLeftX] = useState(0);
  const [rightX, setRightX] = useState(0);

  // Measure button widths & positions after mount
  useEffect(() => {
    if (leftRef.current && rightRef.current) {
      setLeftWidth(leftRef.current.offsetWidth);
      setRightWidth(rightRef.current.offsetWidth);

      // Distance from the parent container's left edge
      setLeftX(leftRef.current.offsetLeft);
      setRightX(rightRef.current.offsetLeft);
    }
  }, [toggleValues]);

  // Style for the knob based on which button is active
  const knobStyle = toggleMode
    ? { left: `${rightX}px`, width: `${rightWidth}px` }
    : { left: `${leftX}px`, width: `${leftWidth}px` };

  return (
    <div className={styles.toggleContainer}>
      {/* Sliding knob highlight */}
      <div className={styles.knob} style={knobStyle} />

      {/* Left button: active if toggleMode === false */}
      <button
        ref={leftRef}
        className={`${styles.button} ${toggleMode ? styles.inactiveButton : styles.activeButton}`
        
        }
        onClick={() => handleToggle(false)}
      >
        {toggleValues[0]}
      </button>

      {/* Right button: active if toggleMode === true */}
      <button
        ref={rightRef}
       className={`${styles.button} ${toggleMode ? styles.activeButton : styles.inactiveButton}`}
        onClick={() => handleToggle(true)}
      >
        {toggleValues[1]}
      </button>
      {toggle1info}
    </div>
  );
}

// export default function Toggle({toggleMode,handleToggle,toggleValues}) {
//   return (
//     <>
//     <div style={{display:"flex",borderRadius:"10px"
//     ,borderColor:"rgb(10, 22, 34)", border:"2px solid black",width:"100%",cursor:"pointer"}}>
//     <button onClick={()=>handleToggle(false)} 
//     style={{background:!toggleMode?"black":"none",color:!toggleMode?"white":"black",borderRadius:"7px",
//     border:"none",fontSize:"0.8rem",
//     textOverflow:"ellipses",overflow:"hidden",whiteSpace:"nowrap",flex:!toggleMode&&1}} 
//     value={false}>{toggleValues[0]}</button>
//     <button onClick={()=>handleToggle(true)} 
//     style={{background:toggleMode?"black":"none",
//       color:toggleMode?"white":"black",borderRadius:"7px",border:"none"
//       ,fontSize:"0.8rem",textOverflow:"ellipses",overflow:"hidden",whiteSpace:"nowrap",flex:toggleMode&&1}}  
//     value={true}>{toggleValues[1]}</button>
//     </div>
//     <ToggleCustom/>
//     </>
    
//   )
// }