import Modal from 'components/Modal/Modal';
import React, { useCallback, useEffect, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, IconButton } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AssetTableForm } from 'myFinancials/Asset/AddAsset';
import { LiabilityTableForm } from 'myFinancials/Liability/AddLiabilities';
import { IncomeTableForm } from 'myFinancials/Cashflow/AddIncome';
import { ExpenseTableForm } from 'myFinancials/Cashflow/AddExpense';
import TextInput from 'components/Inputs/TextInput';
import { debounce } from 'lodash';
import useAddFinancialVault from 'data/user/financialVault/useAddFinancialVault';
import { useAppInfo } from 'AppState';
import DatePicker from 'components/Inputs/DatePicker';

const manualEntry = [
    {label:"Identity & Personal Docs",component:<FinancialVaultManualEntryIdentity/>},
    {label:"Incomes",component:<IncomeTableForm/>},
    {label:"Expenses",component:<ExpenseTableForm/>},
    {label:"Liabilities",component:<LiabilityTableForm/>},
    {label:"Assets",component:<AssetTableForm/>},
    // {label:"Investment Portfolio",component:""}
];
export default function FinancialVaultManualEntryModal({open,handleClose}) {
    const [manualEntryAdd,setManualEntryAdd] = useState(null)

  return (
    <>
    {open &&
    <Modal  onClose={handleClose} height='auto' width='auto'>

    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0.5rem",overflow:"auto",height:"100%"}}>
       {!manualEntryAdd &&
       <div style={{width:"500px",display:"flex",flexDirection:"column",gap:"0.5rem",height:"max-content"}}>
       <h3>Manual Entry</h3>
       {manualEntry.map((item,index)=>(<div 
       className='card-hover'
       onClick={()=>setManualEntryAdd(item)}
       style={{padding:"1rem",borderRadius:"5px",background:"#f7f7f7",
        cursor:"pointer",minWidth:"300px",boxSizing:"border-box", display:"flex",gap:"0.5rem",alignItems:"center"
       }} key={index}>
        <h4 style={{flex:1}}>{item?.label}</h4>
        <Button>
        <KeyboardArrowRightIcon fontSize='small'/>
        </Button>
       </div>))}</div>}

       {manualEntryAdd && 
       <div style={{maxWidth:"80vw",maxHeight:"80vh",minHeight:"60vh",boxSizing:"border-box",display:"flex",flexDirection:"column",gap:"1rem"}}>
       <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
       <IconButton onClick={() => setManualEntryAdd(null)}><ArrowBackIosIcon/></IconButton>
       <h3>{manualEntryAdd?.label}</h3>
         </div>
         {manualEntryAdd?.component}
         </div>}
    </div></Modal>}</>
  )
}

export function FinancialVaultManualEntryTable({category}){
   
    return (
        <>
        {manualEntry?.filter((item,index)=>item?.label===category)[0]?.component || <AssetTableForm/>}
        </>
    )
}

export function FinancialVaultManualEntryIdentity(){
    const {stateMyFinancials,dispatchMyFinancials,stateApp} = useAppInfo();
    const {username,userDetails} = stateApp
    const {vaultIdentity,vaultLoaded,financialView,demoProfile} = stateMyFinancials
    const { addFinancialVault, error } = useAddFinancialVault()
    const [identityDetails,setIdentityDetails] = useState({})
    const [change,setChange] = useState(false)

  
    useEffect(()=>{
        if (financialView === "personal") {
        if (Object.keys(vaultIdentity).length > 0 && vaultLoaded) {
            setIdentityDetails(vaultIdentity)
        }
        if (vaultLoaded && Object.keys(vaultIdentity).length === 0) {
            let username = userDetails?.username
            let email = userDetails?.email
            setIdentityDetails({name:username,email:email})
        }}
        else {
            setIdentityDetails({name:demoProfile,email:`${demoProfile}@demo.com`,mobile:"+1 (123) 456-7890"})
        }
    },[vaultLoaded])
   
    const debouncedAddFinancialVault = useCallback(
        debounce((payload) => {
          addFinancialVault(payload);
        }, 1000), // Adjust debounce delay as needed
        []
      );
    
      useEffect(() => {
        if (Object.keys(identityDetails).length > 0 && change)
        debouncedAddFinancialVault({
          dataName: 'vaultIdentity',
          data: identityDetails,
        });
      }, [identityDetails,change]); // watch for changes in identityDetails
    

      const handleChange = (fieldName, value) => {
        let updateIdentity;
        setIdentityDetails((prevItems) =>{
          updateIdentity = {...prevItems,[fieldName]:value}
          dispatchMyFinancials({vaultIdentity:updateIdentity})
          return updateIdentity
        }
        );
        setChange(true)

      };

    return (
        <div style={{display:"flex",flexDirection:"column",gap:"3rem",padding:"1rem"}}>
        
        <TextInput
        disabled={financialView !== "personal"}
        sx={{maxWidth:"300px"}}  label='Name' placeholder={"Legal Name"} 
        value={identityDetails?.name || ""}
        onChange={(value)=>handleChange("name",value,)}/>
         <TextInput 
          disabled={financialView !== "personal"}
         sx={{maxWidth:"300px"}}  label='Surname' placeholder={"Legal Surname"} 
        value={identityDetails?.surname || ""}
        onChange={(value)=>handleChange("surname",value,)}/>
        
        {identityDetails && <DatePicker 
        disabled={financialView !== "personal"}
         label='Birthdate' required sx={{fontSize:"1.4rem",maxWidth:"300px"}} 
        date={identityDetails?.birthdate || null} 
        placeholder="Birthdate" handleDate={(v) => handleChange("birthdate",v,)}/>}

         <TextInput disabled={financialView !== "personal"}  sx={{maxWidth:"300px"}} label='Email' placeholder={"Professional Email"}
         type='email' 
        value={identityDetails?.email || ""}
        onChange={(value)=>handleChange("email",value,)}/>
      
     
        <TextInput disabled={financialView !== "personal"} required={false} label='Mobile' sx={{maxWidth:"300px"}} placeholder={"Mobile Number"}
         type='phone' 
        value={identityDetails?.mobile || ""}
        onChange={(value)=>handleChange("mobile",value,)}/>

       
        </div>
    )
}
