import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import Logo from 'logo/Logo';
import LogoLoader from 'logo/LogoLoader';

import { Button } from '@mui/material';

import useHandleUserEvents from './useHandleUserEvents';
import useTopQuestionGenerator from './useTopQuestionGenerator';

export default function TopQuestions() {
    const {dispatchAssistant,stateAssistant,stateMyFinancials,stateApp} = useAppInfo()
    const { onboarding}  = stateApp
    const {userRole} = onboarding 
    const {financialsLoaded,financialView,financialsUpdated,demoProfile} = stateMyFinancials
    
    const { handleMessage, handleSend,handleExploreQuestions} = useHandleUserEvents();
    const {topQuestions} = stateAssistant
    let user = financialView === "personal" ? "user" : demoProfile
    user = userRole === "consumer" ? user : "advisor"
    let questionUser = topQuestions && typeof topQuestions === "object" && Object.keys(topQuestions).includes(user) ? topQuestions[user] : undefined
    const [questions, setQuestions] = useState()
    const [loading,setLoading] = useState(false)
    const {fetchTopQuestions} = useTopQuestionGenerator()

    useEffect(()=>{
        if (questionUser) {
            setLoading(false)
            setQuestions(questionUser)
        }
    },[topQuestions,user])

    // console.log(questionUser)
    useEffect(() => {
        if (!questionUser && financialsLoaded && financialsUpdated) {
          setLoading(true);
          async function getQuestions() {
            const generator = fetchTopQuestions();
            // Consume the generator to update UI incrementally.
            for await (const { response, error } of generator) {
              if (!error) {
                setQuestions(response);
                dispatchAssistant({
                  topQuestions: { ...topQuestions, [user]: response },
                });
              } 
            }
            setLoading(false);
          }
          getQuestions();
        }
      }, [financialsLoaded, financialsUpdated,user]);
  
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem",alignItems:"center"}}>

         <div>{loading ? 
            <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}><LogoLoader width={"40px"} height={"40px"}/> 
            <h4>Quinn</h4>  
            </div>
            : 
            <Logo color='black' type='short' width='40px' height='40px'/>}
        </div>
        {loading && <h4>Finding top questions...</h4>}
        {(!loading && userRole === "consumer") && <h4>Top Q's for {financialView === "personal" ? "you" : demoProfile}: </h4>}

        {questionUser && questionUser.map((obj, index) => (
            <div key={index} style={{display:"flex",gap:"1rem",maxWidth:"500px"}}>
            <Button 
            className='card-hover'
                onClick={() =>{dispatchAssistant({ "chatGptMessage": obj.question });handleSend(obj.question)}} 
                style={{
                    background: "#fafafa",
                    width: "500px",
                    borderRadius: "40px",
                    borderRadius: "10px",
                    padding: "1rem",
                    display: "flex",
                    gap:"1rem",
                    alignItems: "center",
                    justifyContent: "flex-start",textAlign:"left"}}>
                        <div>{obj?.emoji}</div>
                        {obj?.question}
                        </Button></div>))}        
    </div>
  )
}

