export const navLinkDict = {
    Home: { path: '/Home', name: 'Home', icon: 'home' },
    Product: { path: '/Product', name: 'Product', icon: 'product' },
    Features: { path: '/Features', name: 'Features', icon: 'features' },
    About: { path: '/About', name: 'About', icon: 'info' },
    Support: { path: '/Support', name: 'Support', icon: 'support' },
    Pricing: { path: '/Pricing', name: 'Pricing', icon: 'pricing' },
    Profile: { path: '/Profile', name: 'Profile', icon: 'profile' },
    Disclaimer: { path: '/Disclaimer', name: 'Disclaimer', icon: 'disclaimer' },
    Terms: { path: '/Terms', name: 'Terms', icon: 'terms' },
    MyFinancials: { path: '/MyFinancials', name: 'My Financials', icon: 'financials' },
    Portfolio: { path: '/Portfolio', name: 'Portfolio', icon: 'financials' },
    FinancialHealth: { path: '/MyFinancials/FinancialHealth', name: 'FinancialHealth', icon: 'financials' },
    Goals: { path: '/Goals', name: 'Goals', icon: 'financials' },
    MyVault: { path: '/MyVault', name: 'My Vault', icon: 'financials' },
    AskProfessional: { path: '/AskProfessional', name: 'Ask Professional', icon: 'financials' },
    Opportunities: { path: '/Opportunities', name: 'Opportunities', icon: 'opportunities' },
    Trade: { path: '/Trade', name: 'Trade', icon: 'trade' },
    Analytics: { path: '/Analytics', name: 'Analytics', icon: 'analytics' },
    Screener: { path: '/Screener', name: 'Screener', icon: 'screener' },
    NewsEvents: { path: '/NewsEvents', name: 'News & Events', icon: 'news' },
    Report: { path: '/Report', name: 'Report', icon: 'report' },
    Resource: { path: '/Resource', name: 'Resource', icon: 'resource' },
  };

export const navLinksPrimary = [
    { key: 'Home', path: '/Home', name: 'Home', icon: 'home' },
    { key: 'Product', path: '/Product', name: 'Product', icon: 'product' },
    { key: 'Features', path: '/Features', name: 'Features', icon: 'features' },
    { key: 'About', path: '/About', name: 'About', icon: 'info' },
    { key: 'Support', path: '/Support', name: 'Support', icon: 'support' },
    { key: 'Pricing', path: '/Pricing', name: 'Pricing', icon: 'pricing' },
    { key: 'Profile', path: '/Profile', name: 'Profile', icon: 'profile' },
    { key: 'Disclaimer', path: '/Disclaimer', name: 'Disclaimer', icon: 'disclaimer' },
    { key: 'Terms', path: '/Terms', name: 'Terms', icon: 'terms' },
    { key: 'MyFinancials', path: '/MyFinancials', name: 'My Financials', icon: 'financials' },
    { key: 'Opportunities', path: '/Opportunities', name: 'Opportunities', icon: 'opportunities' },
    { key: 'Trade', path: '/Trade', name: 'Trade', icon: 'trade' },
    { key: 'Analytics', path: '/Analytics', name: 'Analytics', icon: 'analytics' },
    { key: 'Screener', path: '/Screener', name: 'Screener', icon: 'screener' },
    { key: 'NewsEvents', path: '/NewsEvents', name: 'News & Events', icon: 'news' },
    { key: 'Report', path: '/Report', name: 'Report', icon: 'report' },
    { key: 'Resource', path: '/Resource', name: 'Resource', icon: 'resource' },
  ];
  
  
export const MyFinancialsLinks = [
    { key: 'FinancialOverview', path: '/MyFinancials/Overview', name: 'Financial Overview', icon: 'financial-overview' },
    { key: 'FinancialHealth', path: navLinkDict?.FinancialHealth?.path, name: 'Financial Wellness', icon: 'financial-health' },
    { key: 'Networth', path: '/MyFinancials/Networth', name: 'Net Worth', icon: 'net-worth' },
    { key: 'Assets', path: '/MyFinancials/Assets', name: 'Assets', icon: 'assets' },
    { key: 'Liabilities', path: '/MyFinancials/Liabilities', name: 'Liabilities', icon: 'liabilities' },
    { key: 'Cashflow', path: '/MyFinancials/Cashflow', name: 'Cash Flow', icon: 'cash-flow' },
    { key: 'Goals', path: navLinkDict?.Goals?.path, name: 'Goals', icon: 'goals' },
    { key: 'Portfolio', path: navLinkDict?.Portfolio?.path, name: 'Portfolio', icon: 'portfolio' },
    { key: 'RiskManagement', path: '/MyFinancials/RiskManagement', name: 'Risk Management', icon: 'risk-management' },
    { key: 'InvestmentPreferences', path: '/MyFinancials/InvestmentPreferences', name: 'Investment Preferences', icon: 'investment-preferences' },
    { key: 'Scenarios', path: '/MyFinancials/Scenarios', name: 'Scenarios', icon: 'scenarios' },
];


export const TradeLinks = [
    { key: 'Order', path: '/Trade/Order', name: 'Order', icon: 'order' },
    { key: 'Transactions', path: '/Trade/Transactions', name: 'Transactions', icon: 'transactions' },
    { key: 'Chart', path: '/Trade/Chart', name: 'Trade Chart', icon: 'trade-chart' },
    { key: 'TradeConditions', path: '/Trade/Conditions', name: 'Trade Conditions', icon: 'trade-conditions' },
    { key: 'Technicals', path: '/Trade/Technicals', name: 'Technicals', icon: 'technicals' },
];


export const AnalyticsLinks = [
    { key: 'Ticker', path: '/Analytics/Ticker', name: 'Ticker', icon: 'ticker' },
    { key: 'Market', path: '/Analytics/Market', name: 'Market Analytics', icon: 'market-analytics' },
    { key: 'Industry', path: '/Analytics/Industry', name: 'Industry Analytics', icon: 'industry-analytics' },
    { key: 'Economy', path: '/Analytics/Economy', name: 'Economy', icon: 'economy' },
    { key: 'Investor', path: '/Analytics/Investor', name: 'Investor Sentiment', icon: 'investor-sentiment' },
];


export const OpportunityLinks = [
    { key: 'Investment', path: '/Opportunities/Investment', name: 'Investment Opportunities', icon: 'investment' },
    { key: 'Trading', path: '/Opportunities/Trading', name: 'Trading Opportunities', icon: 'trading' },
    { key: 'PortfolioFit', path: '/Opportunities/PortfolioFit', name: 'Portfolio Fit', icon: 'portfolio-fit' },
];




export const ScreenerLinks = [
        { key: 'ScreenerStocks', path: '/Screener/Stocks', name: 'Stock Screener', icon: 'stocks' },
        { key: 'ScreenerCrypto', path: '/Screener/Crypto', name: 'Crypto Screener', icon: 'crypto' },
        { key: 'ScreenerEtf', path: '/Screener/Etf', name: 'ETF Screener', icon: 'etf' },
        { key: 'ScreenerBonds', path: '/Screener/Bonds', name: 'Bonds Screener', icon: 'bonds' },
        { key: 'ScreenerForex', path: '/Screener/Forex', name: 'Forex Screener', icon: 'forex' },
    ];
    
    
export const NewsEventsLinks = [
        { key: 'News', path: '/NewsEvents/News', name: 'News', icon: 'news' },
        { key: 'Calendar', path: '/NewsEvents/Calendar', name: 'Calendar', icon: 'calendar' },
        { key: 'MandA', path: '/NewsEvents/MandA', name: 'Mergers & Acquisitions', icon: 'mergers' },
        { key: 'Filings', path: '/NewsEvents/Filings', name: 'Filings', icon: 'filings' },
        { key: 'TradingActivities', path: '/NewsEvents/TradingActivities', name: 'Trading Activities', icon: 'trading-activities' },
    ];
    

export const ReportLinks = [
        { key: 'TSReport', path: '/Report/TSReport', name: 'Autonomy Report', icon: 'report' },
        { key: 'ReportBuilder', path: '/Report/Builder', name: 'Report Builder', icon: 'report-builder' },
    ];
    
                    
export const ResourceLinks = [
        { key: 'Learn', path: '/Resource/Learn', name: 'Learn', icon: 'learn' },
        { key: 'Guide', path: '/Resource/Guide', name: 'Guide', icon: 'guide' },
        { key: 'Glossary', path: '/Resource/Glossary', name: 'Glossary', icon: 'glossary' },
        { key: 'MindMap', path: '/Resource/MindMap', name: 'Mind Map', icon: 'mind-map' },
        { key: 'Doodle', path: '/Resource/Doodle', name: 'Doodle', icon: 'doodle' },
    ];
    

const navLinksAll = [
    ...navLinksPrimary,
    ...MyFinancialsLinks,
    ...OpportunityLinks,
    ...TradeLinks,
    ...AnalyticsLinks,
    ...ScreenerLinks,
    ...NewsEventsLinks,
    ...ReportLinks,
...ResourceLinks,
]

const navCategories = {
    All:navLinksAll,
    Primary: navLinksPrimary,  
    MyFinancials: MyFinancialsLinks,  
    Trade: TradeLinks,  
    Analytics: AnalyticsLinks,  
    Opportunities: OpportunityLinks,  
    Screener: ScreenerLinks, 
    NewsEvents: NewsEventsLinks, 
    Report: ReportLinks,
    Resource: ResourceLinks  
  };
  

export const getNavLinkByKey = (key) => {
        const link = navLinksAll.find((linkItem) => linkItem.key === key);
        return link || null; // Returns the link object or null if not found
      };

export const getNavLinksByCategory = ({keys = ['All'],category="All"}) => {
        const linksArray = navCategories[category] || navLinksAll
        if (keys.includes('All')) {
          // Return only the path values for all links
          return linksArray.map(link => link.path);
        }
      
        // Filter the links based on the provided keys and return their path values
        return linksArray
          .filter(link => keys.includes(link.key))
          .map(link => link.path);
      };

