import React, { useReducer } from 'react';

import AppLayout from 'AppLayout';
import AppState from 'AppState';
import useAuthCheck from 'auth/useAuthCheck';
import Automate from 'automate/Automate';
import Clients from 'b2b/Clients/index';
import FinanceProfessional from 'finanaceProfessional/FinanceProfessional';
import FinancialProducts from 'financialProducts/FinancialProducts';
import FinancialVault from 'financialVault/FinancialVault';
import HomeInsights from 'home/HomeInsights';
import HomeLandingPage from 'home/HomeLandingPage';
import Pricing from 'home/pricing/Pricing';
import HowItWorks from 'home/product/HowItWorks';
import WhyUs from 'home/WhyUs';
import HomeMobile from 'mobile/HomeMobile';
import MyFinancialHealthCard
  from 'myFinancials/FinancialHealth/MyFinancialHealthCard';
import GoalPage from 'myFinancials/Goals/GoalPage';
import ScenarioInfo from 'myFinancials/Scenario/ScenarioInfo';
import Analytics from 'pages/dashboard/Analytics';
import AskAI from 'pages/dashboard/AskAI';
import Economy from 'pages/dashboard/Economy';
import IndustrySector from 'pages/dashboard/IndustrySector';
import Market from 'pages/dashboard/Market';
import News from 'pages/dashboard/News';
import { Opportunities } from 'pages/dashboard/Opportunities';
import PortfolioPage from 'pages/dashboard/PortfolioPage';
import Reports from 'pages/dashboard/Reports';
import Resource from 'pages/dashboard/Resource';
import Screener from 'pages/dashboard/Screener';
import Ticker from 'pages/dashboard/Ticker';
import Trade from 'pages/dashboard/Trade';
import Fun from 'pages/Fun';
import About from 'pages/landing/About';
import Contact from 'pages/landing/Contact';
import Disclaimer from 'pages/landing/Disclaimer';
import Product from 'pages/landing/Product';
import Profile from 'pages/landing/Profile';
import SiteMap from 'pages/landing/SiteMap';
import Support from 'pages/landing/Support';
import TermsConditions from 'pages/landing/TermsConditions';
import PageNotFound from 'pages/PageNotFound';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import Guidance from 'resource/guidance/Guidance';
import SocialMedia from 'social/SocialMedia';
import theme from 'style/theme';

import { ThemeProvider } from '@mui/material';

import TopInvestorsList from './analytics/topInvestors/TopInvestorsList';
import { initialStateApp } from './appState/initialStateApp';
import { reducerApp } from './appState/reducer';
import Planning from './b2b/Planning';
import Prospects from './b2b/Prospects';
// Add new imports for advisor pages
import Pulse from './b2b/Pulse';
import Todos from './myFinancials/Todos/Todos';
import MyFinancials from './pages/dashboard/MyFinancials';
import useWindowSize from './utils/useWindowSize';

export default function App() {

  const [stateApp, dispatchApp] = useReducer(reducerApp, initialStateApp);
  const windowSize = useWindowSize();

  useAuthCheck({ dispatchApp, stateApp });

  if (!stateApp.authChecked) {
    return <></>;
  }


  const ProtectedRoute = () => {
    return <>{!stateApp.isLoggedIn ? <Navigate to="/Home" /> : <Outlet />}</>;
  };

  return (
   
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        {windowSize < 0 && (
          <>
            <Route path="/" element={<HomeMobile />} />
            <Route path="*" element={<HomeMobile />}/>
          </>
        )}
        {windowSize > 0 && (
          <Route
            path="/"
            element={<AppState stateApp={stateApp} dispatchApp={dispatchApp} />}
          >
            <Route path="/" element={<AppLayout />}>
              {!stateApp.isLoggedIn && (
                <>
                  <Route path="/" element={<HomeLandingPage/>} />
                  <Route path="/Home" element={<HomeLandingPage/>} />
                </>
              )}
              <Route path="/Contact" element={<Contact />} />
              <Route path="/Sitemap" element={<SiteMap/>} />
              <Route path="/About" element={<About />} />
              <Route path="/HowItWorks" element={<HowItWorks/>} />
              <Route path="/WhyUs" element={<WhyUs/>} />
              <Route path="/Features" element={<Product />} />
              <Route path="/Product" element={<Product />} />
              <Route path="/Pricing" element={<Pricing />} />
              <Route path="/Support" element={<Support />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/Disclaimer" element={<Disclaimer />} />
              <Route path="/Terms" element={<TermsConditions />} />

              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<HomeInsights/>} />
                <Route path="/Home" element={<HomeInsights/>} />
                <Route path="/AskAI" element={<AskAI/>} />
                <Route path="/AskProfessional/:idProfessionalType?/:idProfessional?" element={<FinanceProfessional/>} />
                
                {/* Advisor (B2B) Routes */}
                <Route path="/Clients/:idClient?" element={<Clients/>} />
                <Route path="/Prospects/:idProspect?" element={<Prospects/>} />
                <Route path="/Pulse/:idPulse?" element={<Pulse/>} />
                <Route path="/Planning/:idPlanning?" element={<Planning/>} />
                <Route path="/Products/:idProduct?" element={<FinancialProducts/>} />

                <Route path="/FinancialProducts/:idFinancialProducts?" element={<FinancialProducts/>} />
                <Route
                  path="/MyFinancials/:idMyFinancials?"
                  element={<MyFinancials />}
                />
                <Route
                  path="/Portfolio/:idPortfolio?"
                  element={<PortfolioPage/>}
                />
                <Route
                  path="/FinancialHealth/:idFinancialHealth?"
                  element={<MyFinancialHealthCard/>}
                />
                 <Route
                  path="/Goals/:idGoals?"
                  element={<GoalPage/>}
                />
                <Route
                  path="/Todos/:idTodos?"
                  element={<Todos/>}
                />
                <Route
                  path="Social/:idSocial?"
                  element={<SocialMedia/>}
                />
                <Route
                  path="/Scenarios/:idScenarios?"
                  element={<ScenarioInfo/>}
                />
                 <Route
                  path="/MyVault/:idMyVault?"
                  element={<FinancialVault/>}
                />
                <Route path="/Trade" element={<Trade />} />
                <Route
                  path="Opportunities/:idOpportunities?"
                  element={<Opportunities />}
                />

                <Route
                  path="/:idTradeBase?/Trade/:idTrade?"
                  element={<Trade />}
                />
                 <Route
                  path="/Automate/:idAutomate?"
                  element={<Automate/>}
                />

                <Route path="/Analytics" element={<Analytics />}>
                  <Route path="" element={<Ticker />} />
                  <Route path=":idBase?/Ticker" element={<Ticker />} />
                  <Route path=":idBase?/Market" element={<Market />} />
                  <Route
                    path=":idBase?/Industry"
                    element={<IndustrySector />}
                  />
                  <Route path=":idBase?/Economy" element={<Economy />} />
                  <Route path="Investors" element={<TopInvestorsList />} />
                </Route>

                <Route
                  path="/Screener/:idScreener?/:idTicker?"
                  element={<Screener />}
                />
                <Route
                  path="/:idNewsBase?/NewsEvents/:idNews?"
                  element={<News />}
                />
                <Route path="/Report/:idReportType?/:idReportDetails?" element={<Reports />} />

                <Route
                  path="Resource/:idType?/:idTopic?/:idQuestion?"
                  element={<Resource />}
                />
                <Route path="/Guidance" element={<Guidance />} />

                <Route path="/Fun" element={<Fun />} />
              </Route>
              <Route path="*" element={<PageNotFound />} status={404} />
            </Route>
          </Route>
        )}
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}
