import React from 'react'
import HomeFooterBig from './HomeFooterBig'
import HomeNewLandingPage, { HomeNewLandingPageTyped } from './HomeNewLandingPage'
import { useAppInfo } from 'AppState'

export default function HomeLandingPage() {
  const {stateHome} = useAppInfo()
  return (
    <div style={{width: "100%",display:"flex",
        flexDirection:"column",justifyContent:"center",minHeight:"calc(100vh)"
        ,boxSizing:"border-box"}}>
          
          {!stateHome.pageTyped ? <HomeNewLandingPageTyped/> :
          <HomeNewLandingPage/>}

          {/* <Features/>
            */}
           <HomeFooterBig/>
          </div>

  )
}

