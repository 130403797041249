import React, { useEffect, useState, useMemo } from 'react';
import CompanyVsIndustryScore from './CompanyVsIndustryScore';
import useCompanyVsIndustry from 'data/company/useCompanyVsIndustry';
import { dfin } from 'content/constants';
import ScoreTemplate from '../Overview/ScoreTemplate';
import { useAppInfo } from 'AppState';

export default function CompanyVsIndustryScores({ coInfo, type }) {
  const tickerStock = coInfo?.[dfin.ticker];
  const { statsVsIndustry, loading } = useCompanyVsIndustry({
    tickerList: useMemo(() => [tickerStock], [tickerStock]),
  });

  const { stateStock, dispatchStock } = useAppInfo();
  const { metricScores } = stateStock;

  const [categoryScores, setCategoryScores] = useState({});
  const [overallScore, setOverallScore] = useState(0);
  const [tableDataCategory, setTableDataCategory] = useState({});

  useEffect(() => {
    if (!loading && statsVsIndustry.length > 0 && metricScores) {
      const newCategoryScores = {};
      const tableData = {};
      metricScores.forEach(categoryObj => {
        if (!categoryObj.selected) {
          return;
        }
        const { category, metrics } = categoryObj;
        const stats = type === "Industry" ? statsVsIndustry[0]?.["METRIC STATS INDUSTRY"] : statsVsIndustry[0]?.["METRIC STATS SECTOR"];
        const summary = stats ? stats.filter(obj => metrics.some(metric => metric.metric === obj.metric)) : [];

        if (summary.length > 0) {
          const initializedSummary = summary.map(obj => {
            const metric = metrics.find(metric => metric.metric === obj.metric);
            return { ...obj, Weight: metric.weight, selected: metric.selected };
          });
          const selectedSummary = initializedSummary.filter(obj => obj.selected);
          const totalScore = selectedSummary.reduce((acc, obj) => acc + obj.score * obj.Weight, 0) / selectedSummary.reduce((acc, obj) => acc + obj.Weight, 0);
          newCategoryScores[category] = totalScore || 0;
          tableData[category] = initializedSummary;
        } else {
          newCategoryScores[category] = 0;
          tableData[category] = [];
        }
      });
      setCategoryScores(newCategoryScores);
      setTableDataCategory(tableData);
    }
  }, [loading, statsVsIndustry, type, metricScores]);

  useEffect(() => {
    const totalWeightedScore = Object.values(categoryScores).reduce((acc, score) => acc + score, 0);
    const averageScore = totalWeightedScore / metricScores.filter(category => category.selected).length;
    setOverallScore(averageScore);
  }, [categoryScores, metricScores]);

  const scoreDetails = Object.entries(categoryScores).map(([category, score]) => ({
    Title: category,
    Value: score.toFixed(2),
  }));

  const moreInfoContent = (
    <div style={{display: "flex", flexDirection: "column",height:"60vh"
    ,width:"100%",gap:"0.5rem",boxSizing:"border-box"}}>
      {metricScores.map(categoryObj => (
        <CompanyVsIndustryScore
          key={categoryObj.category}
          title={categoryObj.category}
          tableData={tableDataCategory[categoryObj.category] || []}
          scoreDetails={scoreDetails.find(obj => obj.Title === categoryObj.category)}
        />
      ))}
    </div>
  );

  return (
    <div>
      <ScoreTemplate
        scoreTitle={"Financial"} 
        information={information}
        scoreDetails={scoreDetails}
        scoreOverall={overallScore / 10}
        moreInfoContent={moreInfoContent}
      />
    </div>
  );
}

const information = (
  <h4>
    How are Financial Scores Calculated?<br /><br/>
    Financials Scores are based on Size, Growth, Profitability, Capital Structure, Working Capital Management, 
    Return on Capital and relative to its Industry.<br/><br/>
    Growth score is a weighted average score of
    Revenue, Gross Profit, EBITDA, Net Profit and Free Cash Flow benchmarked with Industry<br /><br/>
    Financial Stability score is a weighted average score of
    Debt/Equity and Current Ratio benchmarked with Industry<br/><br/>
    Size score is a weighted average score of
    Revenue, Gross Profit, EBITDA, Net Profit, Assets, Current Assets, Non-Current Assets, Market Cap benchmarked with Industry.
  </h4>
);


