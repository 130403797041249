import React, {
    useEffect,
    useState,
  } from 'react';
  
  import { useAppInfo } from 'AppState';
  import { dfin } from 'content/constants';
  import {
    DividendToValue,
    LiquidityToVolume,
    RiskToVolatility,
  } from 'content/contentInvestmentPreference';

  import {
    applyFilters,
    createFilterObject,
  } from '../screener/utilsScreener';

  
  export default function usePortfolioFitOpportunities() {
    const {dispatchMyFinancials,stateMyFinancials,stateScreener,stateOpportunities} = useAppInfo()
    const {dataView} = stateOpportunities
    const {investmentPreference,investmentPreferenceRiskTolerance,investmentPreferenceType,marketRegion,primaryMarketRegion,financialsLoaded} = stateMyFinancials 
    const [assetClasses,setAssetClasses] = useState([])
    const [assetClassSel,setAssetClassSel] = useState("")
    const [stocks,setStocks] = useState([])
    const [loaded,setLoaded] = useState(false)
  
   
    useEffect(()=>{
      if (investmentPreference && investmentPreferenceRiskTolerance && marketRegion && financialsLoaded){
      let investmentFilter = investmentPreferenceType?.type === "Manual" ? investmentPreference:investmentPreferenceRiskTolerance
      const assetClassesAvailable = investmentFilter.filter(obj=>obj["selected"]===true).map(obj=>obj.type)
      setAssetClasses(assetClassesAvailable)
      setAssetClassSel(assetClassesAvailable[0])
      const investmentStocks = investmentFilter.filter(obj=>obj["type"]==="Stocks" && obj["selected"]===true)[0]
      const investmentStockCriteria = investmentStocks?.["criterias"]
      const riskLevel =  RiskToVolatility(investmentStocks?.["risk"])
      const liquidityLevel = LiquidityToVolume(investmentStocks?.["liquidity"])
      const dividendLevel = DividendToValue(investmentStockCriteria?.["Dividend"])
      const sector = investmentStockCriteria?.["Sector"]
      const industry = investmentStockCriteria?.["Industry"]
      const mScale = investmentStockCriteria?.["Market Cap"]
        
      const filterArrays = {
        ...createFilterObject(dfin.country, "multiSelect", marketRegion),
        ...createFilterObject(dfin.gicSector, "multiSelect", sector),
        ...createFilterObject(dfin.gicIndustry, "multiSelect", industry),
        ...createFilterObject(dfin.mScale, "multiSelect", mScale),
        ...createFilterObject(dfin.dividendYield, "greaterThan", dividendLevel),
        ...createFilterObject(dfin.dividendYield, "greaterThan", dividendLevel),
        ...createFilterObject(dfin.volume, "greaterThan",1000),
        ...createFilterObject(dfin.pe, "lessThan",80),
        ...createFilterObject(dfin.fiancialRating, "greaterThan",6),
      };

      if (stateScreener.stocks.length>0){
        setLoaded(true)
      }
      if (investmentStocks && Object.keys(investmentStocks) === 0){
        setLoaded(true)
      }

    
      if (stateScreener.stocks.length>0 && investmentStocks && investmentStockCriteria){
        const filteredTable = applyFilters(filterArrays,stateScreener.stocks)
        setStocks(filteredTable.sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap]));
      }
      else{
        setStocks([])
      }  
    } 
    },[investmentPreference,
      investmentPreferenceRiskTolerance,investmentPreferenceType,marketRegion,stateScreener.stocks,financialsLoaded])
  

  
    return {stocks,assetClasses,assetClassSel,loaded}
  }
  