// FinanceProfessional.js
import React from 'react';

import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';

import FinanceProfessionalChat from './Chat/FinanceProfessionalChat';
import FeesDisplay from './Fees/FeesDisplay';
import FinanceProfessionalBanner from './FinanceProfessionalBanner';
import FinanceProfessionalExplore from './FinanceProfessionalExplore';
import FinanceProfessionalForm from './FinanceProfessionalForm';

const FinanceProfessional = () => {
  const location = useLocation()
  const { idProfessional,idProfessionalType} = useParams();
  return (
    <>
    {(!idProfessional && idProfessionalType!=="form") && 
    <FinanceProfessionalExplore/>}
    {idProfessional &&    
    <> 
    <div style={{display:"flex",flexDirection:"column",height:"100%",overflow:"auto",}}>
    <div style={{padding:"0 1rem"}}>
    <Link to={`/AskProfessional`}>Professional</Link> / <Link to={`/AskProfessional/${idProfessionalType}`}>{idProfessionalType}</Link>
    </div>
    <div style={{display:"flex",gap:"1rem",padding:"0 0.5rem",flexWrap:"wrap"
      ,width:"100%",boxSizing:"border-box",position:"relative",flex:1}}>
    <div style={{flex:1,minWidth:"600px"}}>
    <FinanceProfessionalBanner/>
    </div>
    <div style={{minWidth:"400px"}}>
    <FeesDisplay/>
    </div>
    
    </div>
    
    <div style={{position:"sticky",bottom:"0px",padding:"1rem",paddingBottom:"2rem",display:"flex",alignItems:"center"
        ,justifyContent:"center",background:"white",zIndex:"1000",width:"100%",boxSizing:"border-box"}}>
    <FinanceProfessionalChat/>
    </div>
    </div></>}
    
    {(!idProfessional && idProfessionalType && idProfessionalType ==="form") &&
    <FinanceProfessionalForm/>}
   
  </>
  );
};



export default FinanceProfessional;
