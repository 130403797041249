import { useAppInfo } from 'AppState';
import ProfileCompletion from 'myFinancials/ProfileCompletion';
import React, { useEffect } from 'react'
import { generateUniqueId } from 'utils/utilGeneral';


export default function useCheckCreateTodos() {
  const {stateMyFinancials,dispatchMyFinancials} = useAppInfo();    
  const {financialHealthDetails,todos,recommendedTodos} = stateMyFinancials;

  // useEffect(() => {
  //   dispatchMyFinancials({todos:[...todos,...recommendedTodos]})
  // },[recommendedTodos])
  // useEffect(()=>{
   
  // },[])

  return null
}

function TodoList(todo){
    const dateStart = new Date();
    const dateExpiryInOneHour = new Date(dateStart.getTime() + 60 * 60 * 1000); // 1 hour later

    const getStarted = { 
        id: "takestock-get-started",
        owner:"takestock",
        delete:false,
        edit:false,
        category: 'Onboarding',
        name: 'Get Started',
        description:"",
        actions:[{label:"Complete Profile",component:<ProfileCompletion/>}],
        period: 'One Time',
        dateStart: new Date(),
        dateExpiry: dateExpiryInOneHour,
        priority: 1,}

    const info = {"Get Started": getStarted}

    return info?.[todo]
}

export function getStartEndDate(unit, period, anchor = 'monthStart') {
  const currentDate = new Date();

  switch (anchor) {
    case 'monthStart':
      currentDate.setDate(1);
      break;
    case 'monthEnd':
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(0);
      break;

    default:
      break;
  }

  switch (unit) {
    case 'day':
      currentDate.setDate(currentDate.getDate() + period);
      break;
    case 'week':
      currentDate.setDate(currentDate.getDate() + period * 7);
      break;
    case 'month':
      currentDate.setMonth(currentDate.getMonth() + period);
      break;
    case 'quarter':
      currentDate.setMonth(currentDate.getMonth() + period * 3);
      break;
    case 'year':
      currentDate.setFullYear(currentDate.getFullYear() + period);
      break;
    default:
      currentDate.setDate(currentDate.getDate() + period);
  }
  currentDate.setHours(9, 0, 0, 0);
  return currentDate;
}


// export const todoDataInitial = {
//     id: generateUniqueId(),
//     owner:"user",
//     edit:true,
//     category: '',
//     name: '',
//     description:"",
//     action:[],
//     period: 'One Time',
//     dateStart: formatDateTimeLocal(dateStart),
//     dateExpiry: formatDateTimeLocal(dateExpiry),
//     priority: 1,
//   }