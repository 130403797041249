import React from 'react';

import SidebarContentTemplate from './SidebarContentTemplate';

export default function Charts() {
  const content = [
    // {Category:"Personal Finance",Type:"Projection",ImgUrl: 'https://static.thenounproject.com/png/6116241-200.png', style:{height:"30%",width:"50%"}, id: "Projection"},
    {Category:"Price",Type:"Line",
    Ticker:"",ImgUrl: 'https://static.thenounproject.com/png/6116241-200.png', 
    style:{height:"30%",width:"50%"}, id: "Line"},
    {Category:"Price",Type:"Price Multi",Ticker:"",ImgUrl: 'https://static.thenounproject.com/png/6116241-200.png', style:{height:"30%",width:"50%"}, id: "Price Multi"},
    {Category:"Financials", Type: 'Financials',Ticker:"",MetricSelected:[],style:{height:"30%",width:"50%"}, ImgUrl: 'https://static.thenounproject.com/png/6070059-200.png', id: "Financials"},
    {Category:"Technicals", Type: 'Performance',Ticker:"", style:{height:"30%",width:"50%"}, ImgUrl: 'https://static.thenounproject.com/png/363240-200.png', id: "Performance" },
    {Category:"Technicals", Type: 'Volatility',Ticker:"", style:{height:"30%",width:"50%"}, ImgUrl: 'https://static.thenounproject.com/png/363240-200.png', id: "Volatility" },
    {Category:"Technicals", Type: 'Correlation',Ticker:"", style:{height:"30%",width:"50%"}, ImgUrl: 'https://static.thenounproject.com/png/4750906-200.png', id: "Correlation" },
    {Category:"Technicals", Type: 'Beta',Ticker:"", style:{height:"30%",width:"50%"}, ImgUrl:'https://static.thenounproject.com/png/4750906-200.png', id: "Beta" },
    {Category:"Overview", Type: 'Scatter',Ticker:"",MetricSelected:[], style:{height:"30%",width:"50%"}, ImgUrl: 'https://static.thenounproject.com/png/2311987-200.png', id: "Scatter" },
    {Category:"Overview",Type:"Pie",Ticker:"",MetricSelected:undefined, style:{height:"30%",width:"50%"},ImgUrl: 'https://static.thenounproject.com/png/6317718-200.png', id: "Pie"},
    {Category:"Overview",Type:"Column",Ticker:"",MetricSelected:[],ImgUrl: 'https://static.thenounproject.com/png/1591416-200.png', style:{height:"30%",width:"50%"}, id: "Column"},
    {Category:"Overview",Type:"Bar",Ticker:"",MetricSelected:[],ImgUrl: 'https://static.thenounproject.com/png/363240-200.png', style:{height:"30%",width:"50%"}, id: "Bar"},
    {Category:"Overview",Type:"Tree Map",ImgUrl: 'https://static.thenounproject.com/png/3915303-200.png', style:{height:"30%",width:"50%"}, id: "Tree Map"},
  ];
  
  return(
    <SidebarContentTemplate content={content} showType={true}/>
)
}