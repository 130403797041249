import React, { useState } from 'react'
import { useAppInfo } from 'AppState'
import TableGen from 'components/Table/TableGen'
import PriceValueChart from 'components/Charts/PriceValueChart'
import { dfin } from 'content/constants'
import ScoreTemplate from './ScoreTemplate'
import { Alert } from '@mui/material'
import useRealTimePrice from 'data/eod/useRealTimePrice'
import CompanyVsIndustry from '../VsIndustry/CompanyVsIndustry'
import BorderBox from 'components/Layout/BorderBox'


export default function CompanyValueScore({coInfo}) {
  
const {price} = useRealTimePrice({tickerSel:coInfo?.[dfin.tickerCode]})
const close = price?.close
  const companySelected  = coInfo?.[dfin.ticker]
   
  let pe = coInfo?.[dfin.pe]
  let moS;
  if (close){
    moS = (((coInfo?.[dfin.fv30])/(close)-1)*100).toFixed(2)}
  let valueScore = 0
  if(moS>70){
    valueScore = 10
  }
  else if (moS<70 && moS>30){valueScore = 7}
  else if (moS<30 && moS>0){valueScore = 5}
  else if (moS<0 && moS>-40){valueScore = 3}
  else if (moS<-40){valueScore = 1}
  else{valueScore=0}

  const valueTable = [{
    "Close":close && close.toFixed(2),
    "Fair Value":coInfo?.[dfin.fv30],
    "Margin of Safety":moS,
  }]


  const scoreTitle = "Value"
  const scoreOverall=valueScore
  const scoreDetails = [
    {Title:"MoS",Value:`${moS}%`},
    {Title:"PE",Value:coInfo?.[dfin.pe]},
    {Title:"EV/EBITDA",Value:coInfo?.[dfin.evEbitda]},

  ]

  const moreInfoContent = (<>
    <BorderBox sx={{height:"auto"}}>
    <h4 style={{fontWeight:"bold"}}>Relative Value (vs Industry):</h4>
    {[dfin.pe,dfin.ps,dfin.pb,dfin.evEbitda,dfin.roic].map(metric=>(
    <CompanyVsIndustry metric={metric} key={metric} coInfo={coInfo}/>)
    )}
    </BorderBox>
    
    <BorderBox sx={{height:"auto"}}>
    <h4 style={{fontWeight:"bold"}}>Absolute Value (Discounted Cashflow):</h4>
    <TableGen pagination={false} data={valueTable} tableLayout='fixed'/>
    
    <div style={{height:"300px"}}>
    <PriceValueChart companySelected={companySelected}/>
    </div>
    </BorderBox>
    </>)
  return (

    <ScoreTemplate information={information} scoreTitle={scoreTitle} scoreDetails={scoreDetails} scoreOverall={scoreOverall}
    moreInfoContent={moreInfoContent}/>

  )
}

const information = <h4>How are Value Scores Calculated?<br/><br/>
Value scores are a weighted average of PE, EV/EBITDA, and Margin of Safety.<br/><br/>
PE and EV/EBITDA scores are benchmarked against the Industry.<br/>
Margin Of Safety (MoS) = Fair Value based on DCF vs Close<br/><br/>
Scores for MoS:<br/>
MoS greater than 70% = 10<br/>
MoS 30% - 70% = 7<br/>
MoS 0% - 30% = 5<br/>
MoS -40% - 0% = 3<br/>
MoS less than -40% = 0<br/></h4>
