import React from 'react';

import { useAppInfo } from 'AppState';
import PeerIn from 'peerList/PeerIn';

import AddIcon from '@mui/icons-material/Add';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {
  Button,
  Tooltip,
} from '@mui/material';

export default function AddPeersBar() {
  const {dispatchSearch,dispatchSidebar,stateTicker} = useAppInfo()
  function handleAddPeers(){
    dispatchSearch({"addPeers":true})
   
  }
  function handlePeerSidebar(){
    dispatchSidebar({"sidebarOpen":true,"showSidebarRight":true,"sidebarRightContent":"peers"});
  }
  return (
    <div style={{width:"100%",position:"sticky",bottom:"0"
    ,background:"#F7F7F7",display:"flex",alignItems:"center",gap:"0.5rem",padding:"0.2rem",boxSizing:"border-box"}}>
    
    <PeerIn width='250px'/>

    <Tooltip title="Add Peers">
    <Button 
    variant='outlined'
    onClick={handleAddPeers}>
    <AddIcon /></Button></Tooltip>

    
    <div style={{marginLeft:"auto",display:"flex",gap:"1rem",alignItems:"center"}}>
    <>
    Displaying:&nbsp; 
    {stateTicker.peerSelectedDisplay.length}&nbsp;
    Peers
    </>
    <Button variant='outlined' endIcon={<ArrowOutwardIcon/>} onClick={handlePeerSidebar}>
     Show Peers
    </Button>
    </div>
   
    </div>
  )
}
