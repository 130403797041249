import React, { useState } from 'react'
import CashflowChart from './CashflowChart'
import { useAppInfo } from 'AppState'
import TabLine from 'components/Tabs/TabLine'

export default function NetCashFlowProjection() {
    const {stateMyFinancials} = useAppInfo()
    const {cashflowDistribution} = stateMyFinancials
    const {netCashflowDist,totalCashInflowDist,
      totalCashOutflowDist, 
      netCashflowBeforeAssetReturnDist,
      netCashflowAssetWidthdrawalDist,} = cashflowDistribution

    const [tabIndex,setTabIndex] = useState(0)
    
    const tabList = ["Net Cashflow","Net Inflow","Net Outflow"]
    let cashflow = netCashflowDist
    if (tabIndex === 1){
      cashflow = totalCashInflowDist
    }
    else if (tabIndex === 2){
      cashflow = totalCashOutflowDist
    }
    else {
      cashflow = netCashflowDist
    }

    
  return (
    <div style={{overflow:"auto",display:"flex",flexDirection:"column",width:"100%"}}>
      <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(v)=>setTabIndex(v)}/>
 <div style={{height:"500px",width:"100%"}}>
      
      <CashflowChart  data={cashflow} 
          seriesName={"amount"} chartType='column'/>
          <CashflowChart  data={netCashflowBeforeAssetReturnDist} 
          seriesName={"amount"} chartType='column'/>
           <CashflowChart  data={netCashflowAssetWidthdrawalDist} 
          seriesName={"amount"} chartType='column'/>
          </div>
      
    </div>
   
)
}