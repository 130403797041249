import React, { useEffect, useState } from "react";
import { useAppInfo } from "AppState";
import styles from "./RcLibrary.module.css"
import { useNavigate } from 'react-router-dom';
import { Button} from '@mui/material';
import { menuIcons } from "nav/menuIcons";

export default function RcTopics() {
  const {stateResource } = useAppInfo();
  const {learnContentTopics} = stateResource
  const navigate = useNavigate()
  const [categorySel, setCategorySel] = useState("Frequently Asked");
  const [topicContent,setTopicContent] = useState([])

  function handleCategory(value) {
    setCategorySel(value);
  }

  function handleTopic(topic) {
    const encodedTopic = encodeURIComponent(topic);
    navigate({ pathname: `/Resource/Learn`, search: `?topic=${encodedTopic}` });
  }

  useEffect(()=>{
    if (learnContentTopics.length>0){
    const topicFilt = learnContentTopics.filter(
      (obj) => obj.Category === categorySel
    );
    setTopicContent(topicFilt[0]["Topics"])
  }
  },[learnContentTopics,categorySel])
 
  return (
    <>
      <div
        style={{
          width: "20%",
          minWidth: "150px",
          height: "100%",
          borderRight: "1px solid #ddd",
          padding: "0 0.5rem",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <div style={{display:"flex",gap:"1rem",alignItems:"center",padding:"0.5rem"}}>
        {menuIcons?.Learn}
        <h3
          style={{ fontWeight: "bold"}}
        >
          Learn
        </h3>
        </div>
       
        {learnContentTopics.map((obj, index) => (
          <button
            key={obj.Category}
            className={`${styles.button} ${
              obj.Category === categorySel && styles.activeButton
            }`}
            onClick={() => handleCategory(obj.Category)}
          >
            {obj.Category}
          </button>
        ))}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "auto",
          padding:"0.5rem 1rem"
        }}
      >
        <h3
          style={{
            color: "black",
            fontWeight: "bold",
            display: "inline-block",
            padding: "0.5rem",
          }}
        >
          {categorySel}
        </h3>
        {topicContent.map((topic) => (
          <Button
            sx={{
              fontSize: "1.2rem",
              // borderBottom: "1px solid #ddd",
              justifyContent: "flex-start",
              paddingLeft:"1rem"
            }}
            key={topic}
            onClick={() => handleTopic(topic)}
          >
            <>{topic}</>
          </Button>
        ))}
      </div>
    </>
  );
}
