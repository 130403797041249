import React from 'react';

import { useAppInfo } from 'AppState';
import Modal from 'components/Modal/Modal';
import { useNavigate } from 'react-router-dom';

import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';

import Logo from '../../logo/Logo';

export default function ContactModal() {
const {dispatchHome,stateHome} = useAppInfo()
const navigate = useNavigate()
  function handleModal(){
    dispatchHome({"contactUs":false})
    navigate("/")
  }
  function handleHome(){
    dispatchHome({"contactUs":false})
    navigate("/")
  }
  return (
    <>
    {stateHome.contactUs && 
    <Modal onClose={handleModal} closeIcon={false} width="65%" height='auto'>
  <div style={{display:"flex"}}>
<div style={{width:"30%",padding:"1rem",background:"#f7f7f7",borderRadius:"5px"}}>
  
  <Logo color='black' height='30px' width='150px' type='long'></Logo>
  {/* <h4 style={{fontWeight:"bold"}}>Acheive Financial Autonomy with AI</h4><br/> */}
  <div >
  <h4>
  We are a financial empowerment platform that uses AI to automate financial management and deliver personalized financial insights.<br/><br/>

We are not here to grow our assets under management or encourage more trading.<br/><br/>

Our mission is simple - to provide you with high-quality insights and guidance, empowering you to confidently take stock of your finances and make smarter decisions.<br/>
</h4>
   
  </div>
  {/* <button className='btn' style={{marginTop:"1rem"}} onClick={handleHome}>Home Page</button> */}
  {/* <SocialMediaHandles/> */}
  </div>


<div style={{
  padding:"0 2rem",
  width: "70%",
}}>

  <h2>Hey there,</h2>
  <h3 style={{fontWeight:"normal"}}>Thank you for your interest.</h3>
  
  <h3 style={{margin:"1rem 0 0 0",padding:0,fontWeight:"normal"}}>Our platform is currently undergoing active development and is exclusively accessible to our Beta Users at the moment.</h3>
  <h3 style={{margin:"1rem 0 0 0",padding:0,fontWeight:"normal"}}>If you're curious about our offerings or interested in joining our Beta program, we'd love to connect with you!</h3>
  
  <h3 style={{fontWeight:"bold",marginTop:"1rem",fontWeight:"normal"}}>Feel free to contact us via email.</h3>
  
  <div style={{display:"flex",gap:"2rem",marginTop:"1rem",flexDirection:"column"}}>
  <div style={{display:"flex",gap:"2rem"}}>
  <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
  <a
    href="mailto:info@autonomyfinance.ai?subject=Inquiry&body=Hello%20there"
    style={{
      color: "black"
    }}
  >
    <EmailIcon fontSize="large" />
  </a>
  <h3 style={{color:"black",fontWeight:"normal"}}>info@autonomyfinance.ai</h3>
  </div>

  {/* <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
  <a
    href="tel:+17782234263"
    style={{
      fontSize: "2rem",
      color: "black"
    }}
  >
    <PhoneIcon fontSize="large" />
  </a>
  <h3 style={{color:"black"}}>+1 778-223-4263</h3>
  </div> */}
  </div>

  </div>
</div>
</div>
    </Modal>}
    </>
  )
}


function SocialMediaHandles(){
  return (
    <div style={{display:"flex",gap:"2rem",alignItems:"center",padding:"1rem 0"}}>
    <a
      href="https://x.com/AutonomyFinance"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
       <XIcon style={{fontSize:"2rem"}} />
       
    </a>
    <a
      href="https://www.instagram.com/autonomyfinance/"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
      <InstagramIcon style={{fontSize:"2rem"}} />
    </a>
    <a
      href="https://www.instagram.com/autonomyfinance/"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
       <YouTubeIcon style={{color:"red",fontSize:"3rem"}}  />
       
    </a>
    </div>
  )
}