import React, { useLayoutEffect } from 'react';

import AppLayoutLanding from 'AppLayoutLanding';
import { useAppInfo } from 'AppState';
import AuthModal from 'auth/AuthModal';
import TSMainLoading from 'components/Loading/TSMainLoading';
import useTickerInfo from 'data/ticker/useTickerInfo';
import useFindOnboarding from 'data/user/onBoarding/useFindOnboarding';
import DemoAccounts from 'myFinancials/Demo/DemoAccounts';
import OnBoardingNew from 'onBoarding/OnBoardingNew';
import UserTour from 'onBoarding/UserTour';
import { useLocation } from 'react-router-dom';

import AppLayoutDashboard from './AppLayoutDashboard';
import ContactModal from './pages/landing/ContactModal';

export default function AppLayout() {
  const { stateApp } = useAppInfo();
  const location = useLocation();
  const pageName = location.pathname.split("/")[1];
  const {onboarding, initialized} = stateApp
  
  useFindOnboarding();
  useTickerInfo()
  useLayoutEffect(() => {
    const scrollToTop = () => {
      document.body.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
    };
    scrollToTop();
  }, [pageName]);

  if (!initialized && stateApp.isLoggedIn) {
    return <TSMainLoading />;
  }

 
  return (
    <div id="appLayout">
      {!stateApp.isLoggedIn && <AppLayoutLanding />}

      {stateApp.isLoggedIn && (
        <>
          {!onboarding?.setDisplayName && <OnBoardingNew/>}
          {(onboarding?.setDisplayName && onboarding?.terms && !onboarding?.financials)  && <DemoAccounts openModal={!onboarding?.financials}/>}
          {(onboarding?.financials && onboarding?.setDisplayName && !onboarding?.showAround) && <UserTour/>}
          {onboarding?.setDisplayName &&  <AppLayoutDashboard />}
          {/* {setDisplayName && !showAround && <UserTour />} */}
         
        </>
      )}

      <ContactModal />
      <AuthModal />
    </div>
  );
}
