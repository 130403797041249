import React, {
  useMemo,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import SelectBox from 'components/Inputs/SelectBox';
import LoadingSkeletonPage from 'components/Loading/LoadingSkeletonPage';
import TabRound from 'components/Tabs/TabRound';

import Calendar from './Calendar';
import AddTodo from './TodoCRUD';
import TodoTable from './TodoTable';
import { getUpcomingTodos } from './utils';

const TodoDashboard = () => {
  const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
  const {todos,recommendedTodos,financialsLoaded} = stateMyFinancials
  // const [todos, setTodos] = useState();

  // Use useMemo to compute statuses whenever todos change
  // const updatedTodos = useMemo(() => {
  //   return todos.map(t => ({ ...t, computedStatus: getEventStatus(t) }));
  // }, [todos]);

  const allTodos = [...todos,...recommendedTodos]

  const upcomingTodos = useMemo(() => {
    return [...todos,...recommendedTodos].filter(t => getUpcomingTodos(t));
  }, [todos,recommendedTodos]);

  // const recommendedTodos = useMemo(() => {
  //   return todos.filter(t => t.owner === "takestock");
  // }, [todos]);



  const [tabIndex,setTabIndex] = useState(0)
  const [selectedView, setSelectedView] = useState('Category');


  return (
    <div style={{ display: 'flex',height:"100%", flexDirection: 'column',gap:"0rem",padding:"0.5rem",boxSizing:"border-box"}}>
    
    <div style={{display:"flex",gap:"1rem",boxSizing:"border-box",alignItems:"center"}}>    
    <div style={{flex:1}}>
    <TabRound options={["Upcoming","Recommended","All","Calendar"]} 
    value={tabIndex} onChange={(i)=>setTabIndex(i)}></TabRound>  
    </div>
    <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
      <AddTodo variant='contained'/>
        <SelectBox
        variant='outlined'
        sx={{width:"150px"}}
        label={"Group By"}
        value={selectedView}
        options={["All","Category","Priority","Period"]}
        onChange={(e,v) => setSelectedView(v)}
      /></div>
      </div>
    {!financialsLoaded && <div style={{flex:1}}><LoadingSkeletonPage/></div>}
    {financialsLoaded && 
    <>
    
    {(tabIndex === 0 && upcomingTodos.length > 0)  && <TodoTable todos={upcomingTodos} selectedView={selectedView}/>}
    {(tabIndex === 1 && recommendedTodos.length > 0)  && <TodoTable todos={recommendedTodos} selectedView={selectedView}/>}
    {(tabIndex === 2 && allTodos.length > 0)  && <TodoTable todos={allTodos} selectedView={selectedView}/>}
    <div style={{padding:"0.5rem 0"}}>
    {tabIndex === 3 && <Calendar events={allTodos}/>}
    
    {upcomingTodos.length === 0 && tabIndex === 0 && 
    <div style={{padding:"2rem",borderRadius:"10px",background:"#fafafa",display:"flex"
    ,alignItems:"center",justifyContent:"center",gap:"1rem",flexDirection:"column"}}>
      <h2>You currently have no To-dos in the next 30 days</h2>
      <AddTodo/>
    </div>}
    
    {recommendedTodos.length === 0 && tabIndex === 1 && 
    <div style={{padding:"2rem",borderRadius:"10px",background:"#fafafa",display:"flex"
    ,alignItems:"center",justifyContent:"center",gap:"1rem",flexDirection:"column"}}>
      <h2>You currently have no Recommended To-dos</h2>
      <h3>We will suggest to-dos based on your situation and preferences</h3>
    </div>}
    {allTodos.length === 0 && tabIndex === 2 && 
    <div style={{padding:"2rem",borderRadius:"10px",background:"#fafafa",display:"flex"
    ,alignItems:"center",justifyContent:"center",gap:"1rem",flexDirection:"column"}}>
      <h2>You currently have no To-dos</h2>
      <AddTodo/>
    </div>}
    </div>
    </>
    }
    </div>
  );
};

export default TodoDashboard;


const defaultTodos = [
  {
    id: '1',
    category: 'Investment',
    name: 'Tax Loss Harvesting',
    dateStart: '2024-12-10T09:00:00',
    dateExpiry: '2024-12-12T11:00:00',
    priority: 1,
  },
  {
    id: '2',
    category: 'Budgeting',
    name: 'Monthly Budget',
    dateStart: '2024-12-11T07:00:00',
    dateExpiry: '2024-12-11T08:00:00',
    priority: 1,
  },
  {
    id: '3',
    category: "Goal Planning",
    name: 'Project Kickoff',
    dateStart: '2024-12-15T10:00:00',
    dateExpiry: '2024-12-15T12:00:00',
    priority: 1,
  },
  {
    id: '4',
    category: 'Debt Management',
    name: 'Vacation',
    dateStart: '2024-12-20T00:00:00',
    dateExpiry: '2024-12-25T23:59:59',
    priority: 1,
  },
]