
import numeral from 'numeral';
import * as XLSX from 'xlsx';

function getGreeting() {
  const currentHour = new Date().getHours();
  
  if (currentHour < 12) {
      return "Good morning";
  } else if (currentHour < 18) {
      return "Good afternoon";
  } else {
      return "Good evening";
  }
}

export function scrollToSection(id, idParent) {
  // console.log("Scrolling to:", id);
  const parentElement = document.getElementById(idParent);
  const targetSection = document.getElementById(id);

  if (targetSection) {
    const offset = 100; // Adjust this value for margin at the top
    const sectionPosition = targetSection.offsetTop; // Use offsetTop for relative position within a parent

    if (parentElement) {
      parentElement.scrollTo({
        top: sectionPosition - offset, // Scroll within the container
        behavior: "smooth",
      });
    } else {
      const absolutePosition = targetSection.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = absolutePosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }
}

function generateRandomColor() {
  // Generate random values for hue, saturation, and lightness
  const hue = Math.floor(Math.random() * 360); // Hue ranges from 0 to 360 degrees
  const saturation = Math.floor(Math.random() * 60) + 40; // Saturation between 40% and 100%
  const lightness = Math.floor(Math.random() * 40) + 30; // Lightness between 30% and 70%

  // Convert HSL to a CSS color string
  const randomColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  return randomColor;
}



  function numberDecimal(x) {
    return Number(Number.parseFloat(x).toFixed(2));
  }

  function formatNumber(numericVal) {
    if (typeof numericVal !== 'number' || isNaN(numericVal)) {
        return 'N/A'; // Return 'N/A' if numericVal is not a valid number
    }

    // Handle very large or very small values
    if (numericVal >= 1e9) {
        // If numericVal is greater than or equal to 1 billion (1e9), format it in B (billion) format
        return numeral(numericVal / 1e9).format('0.00') + 'B';
    } else if (numericVal >= 1e6) {
        // If numericVal is greater than or equal to 1 million (1e6), format it in M (million) format
        return numeral(numericVal / 1e6).format('0.00') + 'M';
    }

    // Handle decimal numbers
    if (numericVal % 1 !== 0) {
        // If numericVal is a decimal number, format it with two decimals
        return numeral(numericVal).format('0,0.00');
    } else {
        // Format whole numbers without decimal parts
        return numeral(numericVal).format('0,0');
    }
}

function getUniqueValues(data = {}, column = " ", addAll = false) {
  
  let uniqueValues = [];
  
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const item = data[key];
      if (item.hasOwnProperty(column) && !uniqueValues.includes(item[column])) {
        uniqueValues.push(item[column]);
      }
    }
  }
  
  uniqueValues = uniqueValues.filter((value) => {
    return typeof value === 'string' && value.trim().length > 0;
  });
  

  // Sort the unique values alphabetically, ensuring "All" is at the top
  uniqueValues.sort((a, b) => {
    if (a === "All") return -1; // "All" should come first
    if (b === "All") return 1; // "All" should come first
    return a.localeCompare(b); // For other values, use regular alphabetical sorting
  });

  // If addAll is true, add "All" to the top
  if (addAll) {
    uniqueValues.unshift("All");
  }

  return uniqueValues;
}



// Function to extract specific keys from each object
function getSpecificArrayObjKeys(objArray, keysToExtract) {
  return objArray.map(obj => {
    const newObj = {};
    keysToExtract.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  });
}

function excludeSpecificArrayObjKeys(objArray, keysToExclude) {
  return objArray.map(obj => {
    const newObj = { ...obj }; // Create a shallow copy of the object

    keysToExclude.forEach(key => {
      if (newObj.hasOwnProperty(key)) {
        delete newObj[key];
      }
    });

    return newObj;
  });
}

const handleXLDownload = (wbName,data) => {
  // Create a worksheet from the array of objects
  const ws = XLSX.utils.json_to_sheet(data);

  // Create a new XLSX workbook
  const wb = XLSX.utils.book_new();

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Save the workbook as an XLSX file
  XLSX.writeFile(wb, `${wbName}.xlsx`);
};

const transformDataForLineChartMulti = ({data, dateFieldName, valueFieldName}) => {
  return data.map((item) => ({
    date: new Date(item[dateFieldName]).getTime(),
    value: item[valueFieldName],
    chartType: "line"
  }));
};

function generateUniqueId() {
  return 'id-' + Date.now().toString(36) + Math.random().toString(36).substring(2);
}

function getDateString(date){
  const dateObj = new Date(date)
  let day = dateObj.toLocaleDateString('en-GB', { day: 'numeric' });
let month = dateObj.toLocaleDateString('en-GB', { month: 'short' });
let year = dateObj.toLocaleDateString('en-GB', { year: 'numeric' });

const formattedDate = `${day} ${month}, ${year}`;
return formattedDate
}

export {generateRandomColor,generateUniqueId,numberDecimal,excludeSpecificArrayObjKeys,getDateString
  ,getUniqueValues,getSpecificArrayObjKeys,formatNumber,handleXLDownload,
  transformDataForLineChartMulti,getGreeting}