import Animation from 'animation/Animation'
import ComingSoon from 'components/ComingSoon'
import HomeFooterBig from 'home/HomeFooterBig'
import NavLanding from 'nav/NavLanding'
import PageComingSoon from 'pages/PageComingSoon'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export default function AppLayoutLanding() {
  const location = useLocation()
  const pageName = location.pathname.split("/")[1];
  const pages = ["","Home","About","Terms","Disclaimer","Contact"]
  const pageBlock = !pages.includes(pageName)
  return (
    <div style={{width:"100%",boxSizing:"border-box",  background: "linear-gradient(135deg, #ffffff, #f8f9fa, #e9ecef)"}}>
    <NavLanding/>

    <div className='appLandingContentContainer'>
      {pageBlock &&
      <div style={{position:"absolute",zIndex:100,width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
        <Animation type="Coming Soon"/>
      </div>}
      {!pageBlock &&    <Outlet/>}
    </div>
    </div>
  )
}