import React from 'react';

import { useAppInfo } from 'AppState';
import TableGen from 'components/Table/TableGen';
import { dfin } from 'content/constants';
import AddPeersBar from 'peerList/AddPeersBar';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';

export default function PeerProfile() {
  const {stateStock,stateTicker} = useAppInfo()
  const {close,marketInfoLoading,trendTable} = stateTicker
  const {peerInfo} = stateStock
  const cols = [dfin.ticker,dfin.coLogo,
    dfin.coName,dfin.coWebsite, dfin.gicSector,dfin.gicIndustry,dfin.marketCap,dfin.mScale]
  return (
    <div style={{display:"flex",flexDirection:"column",width:"100%",height:"100%"}}>
    <div style={{flex:1,overflow:"auto"}}>
    {(peerInfo.length>0) &&
    <TableGen addToWatchlist={true} tickerPrice={close} 
    data={getSpecificArrayObjKeys(peerInfo,cols)} logo={true} />}
    </div>
    <AddPeersBar/>
    </div>
  )
}
