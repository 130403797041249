import React, {
  useEffect,
  useRef,
} from 'react';

import { useAppInfo } from 'AppState';
import ProfileLogo from 'components/Logo/ProfileLogo';
import Logo from 'logo/Logo';

import ContentRender from './ContentRender';
import TopQuestions from './TopQuestions';

export default function ChatContent() {
  const { stateApp,stateAssistant} = useAppInfo();
  const { username } = stateApp;
  const { chats, loading,loadingMessage,questionChat} = stateAssistant;
  const chatRefx = useRef();

  useEffect(() => {
    chatRefx.current?.scrollIntoView({ behavior: 'smooth',block: 'start'});
  }, [questionChat]);  


  return (
    <>
      {chats.length === 0 && (
        <div  style={{
          display: 'flex',
          flexDirection:"column",
          padding: '2rem 4rem',
          gap: '2rem',
          width: '100%',
          minHeight: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          boxSizing: 'border-box',
        }}>

    
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent:"center",
            boxSizing: 'border-box',
          }}
        >
          <TopQuestions/>
          {/* <DefaultMessageBox explore={false} /> */}
          {/* <DefaultMessageBoxAI explore={false}/> */}
        </div>
        
        </div>
      )}
      {chats.map((obj, index) => (
      <React.Fragment key={obj.id || index}>
        <ChatMessage 
          obj={obj} 
          username={username}
          isLast={index === chats.length - 1}
          loadingMessage={loadingMessage}
          loading={loading}
        />
        <br />
      </React.Fragment>
    ))}


    </>
  );
}


const ChatMessage = React.memo(function ChatMessage({ obj, username, isLast, loadingMessage, loading }) {
  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: obj.role === 'user' ? 'row-reverse' : 'row' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '100%' }}>
        {obj.role === 'user' ? (
          <div style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row-reverse' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
              {/* Render user logo */}
              <ProfileLogo />
              <strong>{username}</strong>
            </div>
          </div>
        ) : (
          <h4 style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
            {/* Render assistant logo */}
            <Logo type={(loadingMessage && isLast) ? "loaderShort" : "short"} color='black' height='20px' width='20px' />
            <strong>Quinn</strong>
          </h4>
        )}
        {obj.role !== 'user' && isLast && loading && loadingMessage && (
          <h4 className="fade-in-simple" style={{ fontWeight: "bold" }}>
            {loadingMessage}
          </h4>
        )}
        {obj.content !== '#?$?#' && (
          <div
            style={{
              background: '#fafafa',
              borderRadius: '5px',
              padding: '1rem',
              width: 'auto',
              maxWidth: obj.role === 'user' ? "400px" : '80%',
              marginLeft: obj.role === 'user' && 'auto',
              fontWeight: "normal"
            }}
          >
            {obj.role === 'user'
              ? obj.content
              : <ContentRender idRun={obj?.idRunTS} content={obj.content} />}
          </div>
        )}
      </div>
    </div>
  );
});


