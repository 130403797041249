import React, { useState } from 'react';

import { useAppInfo } from 'AppState';
import { useNavigate } from 'react-router-dom';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FolderIcon from '@mui/icons-material/Folder';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PersonIcon from '@mui/icons-material/Person';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Typography } from '@mui/material';

export default function FinancialVaultFolders({mediaItems}) {
  const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
  const navigate = useNavigate()
  const {vaultCategories} = stateMyFinancials
  const [mouseEnter,setMouseEnter] = useState(-1)


  return (
    <div 
    style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
      {vaultCategoriesDetails.map((folder, index) => (
        <React.Fragment key={index}>
          <div 
          className='card-hover'
          onPointerEnter={()=>setMouseEnter(index)}
          onPointerLeave={()=>setMouseEnter(-1)}
          onClick={()=>navigate(`/MyVault/${folder?.name}`)} 
          style={{cursor:"pointer",flexWrap:"wrap",minWidth:"300px"
            ,maxWidth:"300px",gap:"1rem",
          padding:"1rem",borderRadius:"25px",border:"1px solid #e0e0e0",display:"flex",alignItems:"center"
          ,gap:"0.5rem",background:mouseEnter === index && "#fafafa"}}>
             {folder?.icon}
             {/* <FolderIcon sx={{color:"#F1C40F"}}/>  */}
            <Typography variant="body1" sx={{flex:1,whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{folder?.name}</Typography>
            {/* {mediaItems.filter(item => item.category === folder).length > 0 && 
            <Typography variant="body1" sx={{color:"green",padding:"0.05rem",borderRadius:"5px"}}>
              
              {mediaItems.filter(item => item.category === folder).length}
              </Typography>} */}
            {/* <MoreVertIcon sx={{color:"gray"}}/> */}
          </div>
          </React.Fragment>
      ))}
      
    </div>
  )
}





const vaultCategoriesDetails = [
  {
    name: "Identity & Personal Docs",
    icon: <PersonIcon sx={{ color: "#42a5f5" }} />, // Bright blue
  },
  {
    name: "Incomes",
    icon: <AttachMoneyIcon sx={{ color: "#66bb6a" }} />, // Vibrant green
  },
  {
    name: "Expenses",
    icon: <MoneyOffIcon sx={{ color: "#ef5350" }} />, // Bold red
  },
  {
    name: "Assets",
    icon: <HomeWorkIcon sx={{ color: "#ab47bc" }} />, // Vivid purple
  },
  {
    name: "Liabilities",
    icon: <AccountBalanceWalletIcon sx={{ color: "#26c6da" }} />, // Fresh teal
  },
  {
    name: "Taxes, Estate & Insurance",
    icon: <GavelIcon sx={{ color: "#ffa726" }} />, // Warm orange
  },
  {
    name: "Charity & Business Interest",
    icon: <VolunteerActivismIcon sx={{ color: "#ff7043" }} />, // Energetic coral
  },
  {
    name: "Others",
    icon: <FolderIcon sx={{ color: "#9e9e9e" }} />, // Neutral gray
  },
];
