import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';

export default function DatePicker({
  date,
  handleDate,
  sx,
  weekends = true,
  label = 'Date',
  required = true,
  disabled = false,
  variant="standard"
}) {
  const [dateSel, setDateSel] = useState('');
  const [isDateValid, setIsDateValid] = useState(true);

  useEffect(() => {
    // Attempt to parse the incoming `date`
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate)) {
      setDateSel(formatDateToString(parsedDate)); // Set dateSel to the valid parsed date
    } else {
      setDateSel(''); // Clear dateSel if the date is invalid
    }
  }, [date]);

  const handleChange = (e) => {
    const value = e.target.value;
    setDateSel(value);
    console.log("Date Selected",value)
    handleDate(value);

    // Validate the date
    const parsedDate = new Date(value);
    if (!isNaN(parsedDate)) {
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }
  };

  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TextField
        disabled={disabled}
        sx={{ ...sx }}
        required={required}
        id="datePicker"
        label={label}
        type="date"
        value={dateSel}
        variant={variant}
        onChange={handleChange}
        error={!isDateValid}
        helperText={!isDateValid && 'Please enter a valid date'}
        InputLabelProps={{ shrink: true }}  
      />
    </LocalizationProvider>
  );
}

const formatDateToString = (date) => {
  return date.toISOString().split('T')[0];
};



const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Get yesterday's date
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);