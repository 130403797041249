import React from 'react';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

const NomyMetrics = ({ taskStats }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={3}>
        <Box sx={{ 
          p: 1.5, 
          bgcolor: 'warning.50',
          borderRadius: '8px',
          textAlign: 'center'
        }}>
          <Typography variant="h6" sx={{ color: 'warning.main', fontWeight: 600 }}>
            {taskStats.pending.count}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
            Pending Tasks
          </Typography>
          <Typography variant="caption2" sx={{ color: 'text.secondary', fontSize: '0.65rem' }}>
            {taskStats.pending.details}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Box sx={{ 
          p: 1.5, 
          bgcolor: 'info.50',
          borderRadius: '8px',
          textAlign: 'center'
        }}>
          <Typography variant="h6" sx={{ color: 'info.main', fontWeight: 600 }}>
            {taskStats.inProcess.count}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
            In Process
          </Typography>
          <Typography variant="caption2" sx={{ color: 'text.secondary', fontSize: '0.65rem' }}>
            {taskStats.inProcess.details}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Box sx={{ 
          p: 1.5, 
          bgcolor: 'success.50',
          borderRadius: '8px',
          textAlign: 'center'
        }}>
          <Typography variant="h6" sx={{ color: 'success.main', fontWeight: 600 }}>
            {taskStats.completed.count}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
            Completed Tasks
          </Typography>
          <Typography variant="caption2" sx={{ color: 'text.secondary', fontSize: '0.65rem' }}>
            {taskStats.completed.details}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Box sx={{ 
          p: 1.5, 
          bgcolor: 'error.50',
          borderRadius: '8px',
          textAlign: 'center'
        }}>
          <Typography variant="h6" sx={{ color: 'error.main', fontWeight: 600 }}>
            {taskStats.errors.count}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
            Errors
          </Typography>
          <Typography variant="caption2" sx={{ color: 'text.secondary', fontSize: '0.65rem' }}>
            {taskStats.errors.details}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NomyMetrics; 