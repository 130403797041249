import React, {
  useMemo,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GroupIcon from '@mui/icons-material/Group';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Box,
  Button,
  Chip,
  Tooltip,
  Typography,
} from '@mui/material';

import TabRound from '../../components/Tabs/TabRound';
import FilterModal from '../components/FilterModal';
import PageHeader from '../components/PageHeader';
import styles from './Planning.module.css';
import { financeCategories } from './planningData';

function TabPanel({ children, value, index }) {
  return (
    <div hidden={value !== index} style={{ padding: '1rem 0' }}>
      {value === index && children}
    </div>
  );
}

export default function Planning() {
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    scenarioType: null,
    status: null,
    priority: null
  });

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // Handle file upload logic here
    // console.log('File uploaded:', file);
  };

  const filteredScenarios = useMemo(() => {
    // If "All" tab is selected (index 0), show all scenarios
    if (tabValue === 0) {
      return financeCategories.flatMap(category => 
        category.scenarios.map(scenario => ({
          ...scenario,
          category: category.category
        }))
      ).filter(scenario => 
        scenario.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Otherwise show scenarios for the selected category
    const currentCategory = financeCategories[tabValue - 1]; // Adjust index since "All" is first
    if (!currentCategory) return [];

    return currentCategory.scenarios.filter(scenario => 
      scenario.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, tabValue]);

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    setFilterModalOpen(false);
  };

  const activeFiltersCount = Object.values(filters).filter(Boolean).length;

  return (
    <div className={styles.container}>
      <PageHeader
        title="Financial Planning"
        subtitle="Manage client plans and explore scenario templates"
        searchPlaceholder="Search scenarios..."
        searchValue={searchTerm}
        onSearchChange={setSearchTerm}
        onFilterClick={() => setFilterModalOpen(true)}
        activeFiltersCount={activeFiltersCount}
      />
      <div style={{ marginBottom: "1rem" }}>
        <TabRound 
          options={["All", ...financeCategories.map(category => category.category)]} 
          value={tabValue} 
          onChange={handleTabChange} 
        />
      </div>

      <div className={styles.uploadSection}>
        <Button
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          component="label"
          className={styles.uploadButton}
        >
          Upload Your Excel Models
          <input
            type="file"
            hidden
            accept=".xlsx,.xls"
            onChange={handleFileUpload}
          />
        </Button>
      </div>

      {["All", ...financeCategories.map(category => category.category)].map((category, index) => (
        <TabPanel key={category} value={tabValue} index={index}>
          <div className={styles.scenariosGrid}>
            {filteredScenarios.map((scenario, idx) => (
              <div 
              sx={{cursor:"pointer"}}
              onClick={() => navigate(`/Planning/${scenario.name}`)}
              key={idx} className={styles.scenarioCard}>
                <div className={styles.scenarioCardContent}>
                  <Box sx={{ mb: 1 }}>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: 'text.secondary',
                        fontSize: '0.65rem',
                        fontWeight: 500,
                        display: 'block',
                        mb: 0.5
                      }}
                    >
                      {scenario.category}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontSize: '1rem',
                        fontWeight: 600,
                        color: 'text.primary'
                      }}
                    >
                      {scenario.name}
                    </Typography>
                  </Box>
                  <div className={styles.scenarioActions}>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<GroupIcon />}
                      className={styles.relatedClientsButton}
                    >
                      Applies to {scenario.relatedClients} Clients
                    </Button>
                    <Tooltip title={scenario.hasCustomModel ? "Using Your Custom Model" : "Using Default Model"}>
                      <div className={`${styles.modelIndicator} ${scenario.hasCustomModel ? styles.custom : styles.default}`}>
                        {scenario.hasCustomModel ? (
                          <SettingsSuggestIcon className={styles.customModelIcon} />
                        ) : (
                          <TuneIcon className={styles.defaultModelIcon} />
                        )}
                        <Typography variant="caption" className={styles.modelText}>
                          {scenario.hasCustomModel ? "Custom Model" : "Default Model"}
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
      ))}

      <FilterModal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        title="Filter Scenarios"
        onSearchChange={setSearchTerm}
        searchTerm={searchTerm}
        searchPlaceholder="Search scenarios..."
        onApply={handleApplyFilters}
        onReset={() => setFilters({
          scenarioType: null,
          status: null,
          priority: null
        })}
      >
        <div className={styles.filterContent}>
          <div className={styles.filterSection}>
            <Typography variant="subtitle1" className={styles.filterTitle}>
              Scenario Type
            </Typography>
            <div className={styles.filterChips}>
              {['Retirement', 'Education', 'Estate', 'Tax', 'Insurance'].map(type => (
                <Chip
                  key={type}
                  label={type}
                  onClick={() => setFilters(prev => ({
                    ...prev,
                    scenarioType: prev.scenarioType === type ? null : type
                  }))}
                  color={filters.scenarioType === type ? "primary" : "default"}
                />
              ))}
            </div>
          </div>

          <div className={styles.filterSection}>
            <Typography variant="subtitle1" className={styles.filterTitle}>
              Status
            </Typography>
            <div className={styles.filterChips}>
              {['Draft', 'In Review', 'Approved', 'Completed'].map(status => (
                <Chip
                  key={status}
                  label={status}
                  onClick={() => setFilters(prev => ({
                    ...prev,
                    status: prev.status === status ? null : status
                  }))}
                  color={filters.status === status ? "primary" : "default"}
                />
              ))}
            </div>
          </div>

          <div className={styles.filterSection}>
            <Typography variant="subtitle1" className={styles.filterTitle}>
              Priority
            </Typography>
            <div className={styles.filterChips}>
              {['High', 'Medium', 'Low'].map(priority => (
                <Chip
                  key={priority}
                  label={priority}
                  onClick={() => setFilters(prev => ({
                    ...prev,
                    priority: prev.priority === priority ? null : priority
                  }))}
                  color={filters.priority === priority ? "primary" : "default"}
                />
              ))}
            </div>
          </div>
        </div>
      </FilterModal>
    </div>
  );
} 