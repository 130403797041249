import React, { useState, useEffect, useCallback } from "react";
import {ClickAwayListener, IconButton,} from "@mui/material";
import { Delete, Close, ArrowBack, ArrowForward } from "@mui/icons-material";
import Modal from "components/Modal/Modal";
import SpreadsheetViewer from "financialVault/SpreadsheetViewer";

export default function MediaDisplayModal({mediaSelectedIndex = 0,mediaItems,handleCloseModal,modalOpen}) {
    const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  

    useEffect(()=>{
        setSelectedMediaIndex(mediaSelectedIndex)
    },[mediaSelectedIndex])


    const closeModal = () => {
      handleCloseModal();
        // setSelectedMediaIndex(null);
      };
    
      const nextMedia = (e) => {
        e.stopPropagation();
        if (selectedMediaIndex !== null && selectedMediaIndex < mediaItems.length - 1) {
          setSelectedMediaIndex((prev) => prev + 1);
        }
        else {
          setSelectedMediaIndex(0);
        }
      };
    
      const previousMedia = (e) => {
        e.stopPropagation();
        if (selectedMediaIndex > 0) {
          setSelectedMediaIndex((prev) => prev - 1);
        }
        else {
          setSelectedMediaIndex(mediaItems.length - 1);
        }
      };
  return (
    <>
    {(selectedMediaIndex !== null && modalOpen) && (
        <Modal
  closeIcon={false}
  onClose={handleCloseModal}
  sx={{
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.8)",
    padding: 0,position:"relative",borderRadius:"0"
  }}
>
  {/* Left Arrow */}
 <IconButton
        sx={{
          position: "absolute",
          left: "1rem",
          top: "50%",
          transform: "translateY(-50%)",
          color: "white",
          background:"black",
          zIndex: 1,
        }}
        onClick={previousMedia}
      >
        <ArrowBack />
      </IconButton>
    {/* Media Content Section */}
     {/* Right Arrow */}
     <IconButton
        sx={{
          position: "absolute",
          right: "1rem",
          top: "50%",
          transform: "translateY(-50%)",
          color: "white",
          background:"black",
          zIndex: 1,
        }}
        onClick={nextMedia}
      >
        <ArrowForward />
      </IconButton>
  <div style={{ display: "flex", flexDirection: "column",width: "100%",alignItems:"center", 
    height: "100%",position:"relative",gap:"2rem",overflow:"auto"}}>
    {/* Header Section */}
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        background: "transparent",
        padding: "1rem",
        alignItems: "center",
        width:"100%",boxSizing:"border-box",minHeight:"max-content",position:"sticky",top:"0",zIndex:"100"
      }}
    >
      <h3 style={{color:"white"}}>{mediaItems[selectedMediaIndex]?.name}</h3>
      <IconButton style={{ color: "white" }} onClick={closeModal}>
        <Close />
      </IconButton>
    </div>
 
    <div style={styles.modalContent}>
      <ClickAwayListener onClickAway={closeModal}>
      <div style={{width:"90%",height:"100%",justifySelf:"center",alignSelf:"center",display:"flex",alignItems:"center",justifyContent:"center"}}>
      {mediaItems[selectedMediaIndex]?.fileType.includes("application/pdf")&&
        <iframe src={mediaItems[selectedMediaIndex]?.url} width="100%" height="100%"></iframe>

      }
            {mediaItems[selectedMediaIndex]?.fileType.includes("application/msword")&&
        <h2 style={{color:"white"}}>Currently MS Word documents are not supported</h2>
      }
        {mediaItems[selectedMediaIndex]?.fileType.includes("image") && (
          <img
            src={mediaItems[selectedMediaIndex]?.url}
            alt={mediaItems[selectedMediaIndex]?.name}
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
          />
        )}
        {mediaItems[selectedMediaIndex]?.fileType.includes("video") && (
          <video
            src={mediaItems[selectedMediaIndex]?.url}
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
            controls
          />
        )}
         {mediaItems[selectedMediaIndex]?.fileType.includes("csv") && (
          <SpreadsheetViewer
          file={mediaItems[selectedMediaIndex]}
          />
        )}
        </div>
        </ClickAwayListener>
    </div>
  </div>
</Modal>

      )}
      </>
  )
}



const styles = {
    modalContent: {
      position: "relative",
      flex: 1,
      background: "transparent",
      color: "white",
      maxWidth: "100%",
      width:"100%",
      height:"100%",
      display:"flex",alignItems:"center",justifyContent:"center",
      padding:"2rem",boxSizing:"border-box",
      minHeight:"400px",
    },
    carouselContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      gap: "1rem",
      padding:"0 2rem",
      boxSizing: "border-box",
      position: "relative",
    },
  };