import React, { useState } from 'react'
import GoalsSuccessRate from './GoalsSuccessRate'
import TabLine from 'components/Tabs/TabLine'
import GoalFunding from './GoalFunding'
import Income from 'myFinancials/Cashflow/Income'
import GoalIndivdiualDetails from './GoalIndivdiualDetails'
import InfoBox from 'components/Layout/InfoBox'
import LiabilityInfo from 'myFinancials/Liability/LiabilityInfo'
import ExpenseInfo from 'myFinancials/Cashflow/ExpenseInfo'
import AssetInfo from 'myFinancials/Asset/AssetInfo'

export default function GoalSuccessFailureScenario({showInfo=false}) {
  const [tabIndex, setTabIndex] = useState(0)

  const tabContent = {
    "Priority": <GoalIndivdiualDetails />,
    "Funding": <GoalFunding />,
    "Expense": <ExpenseInfo info={"Details"}/>,
    "Liability": <LiabilityInfo info={"Overview"}/>,
    "Income": <Income />,
    "Assets": <AssetInfo info={"Projections"}/>,
  }

  const tabData = [
    {
      label: "Setting Realistic Goals & Prioritizing them",
      tabName: "Priority",
      infoHeader: "1. Setting Realistic Goals & Prioritizing them",
      paras: [
        "It is crucial to prioritize your goals based on their importance and urgency.",
        "Proper prioritization ensures that your most critical goals are funded first, which increases the chances of achieving them."
      ]
    },
    {
      label: "How you fund goals?",
      tabName: "Funding",
      infoHeader: "2. How you fund goals?",
      paras: [
        "You can either fund your goals through net cash or withdrawing your savings/investments.",
        "The best scenario is to fund them through net cash, which is the balance cash available after your regular expenses and monthly liabilities.",
        "It is wise not to touch savings and investments, as you should let them build your wealth."
      ]
    },
    {
      label: "Budegting Expenses",
      tabName: "Expense",
      infoHeader: "3. Budegting Expenses",
      paras: [
        "Ensure your expenses are within your budget to avoid impacting your goal funding."
      ]
    },
    {
      label: "Managing Liabilities",
      tabName: "Liability",
      infoHeader: "4. Managing Liabilities",
      paras: [
        "Ensure your liabilities are well-managed to avoid impacting your goal funding."
      ]
    },
    {
      label: "Having a Consistent Source of Income",
      tabName: "Income",
      infoHeader: "5. Having a Consistent Source of Income",
      paras: [
        "A steady income stream is crucial to maintaining financial stability and meeting your goals."
      ]
    },
    {
      label: "Building Long-term Assets",
      tabName: "Assets",
      infoHeader: "6. Building Long-term Assets",
      paras: [
        "Invest in assets that appreciate over time to benefit from compounding."
      ]
    }
  ]

  const handleTabChange = (index) => {
    setTabIndex(index)
  }

  return (
    <div style={{width:"100%",padding:"0.5rem",boxSizing:"border-box",display:"flex",flexDirection:"column",gap:"1rem"}}>
      <GoalsSuccessRate displayType='detailed' />
      <>
      {showInfo && 
        <div style={{maxWidth:"800px",padding:"0.5rem",boxSizing:"border-box"}}>
          <h3 style={{ fontWeight: "bold", marginBottom: "1rem", color: "#343a40" }}>
            Your Goal Success is based on 6 main factors
          </h3>

          {tabData.map((item, index) => (
            <div key={index} style={{ marginBottom: "1.5rem" }}>
              <h4
                style={{ fontWeight: "bold", marginBottom: "0.5rem", color: "#007bff", cursor: 'pointer' }}
                onClick={() => handleTabChange(index)}
              >
                {item.infoHeader}
              </h4>
              {item.paras.map((para, paraIndex) => (
                <p key={paraIndex} style={{ paddingLeft: "1rem", color: "#495057" }}>
                  {para}
                </p>
              ))}
            </div>
          ))}
        </div>}

        <div>
          <h3 style={{ fontWeight: "bold", marginBottom: "1rem", color: "#343a40" }}>
            Tweak the different pieces of your financials below to see the impact on your goals.
          </h3>

          <TabLine tabIndex={tabIndex} tabList={tabData.map(item => item.tabName)} handleTabChange={handleTabChange} />
          {tabContent[tabData[tabIndex].tabName]}
        </div>
      </>
    </div>
  )
}
