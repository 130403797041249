import { Tooltip } from '@mui/material';
import TooltipInfo from 'components/Modal/TooltipInfo';
import React, { useState } from 'react';
import { formatNumber } from 'utils/utilGeneral';

export default function CircleScore({
  score = 0,
  totalScore = 0,
  title = "Score",
  tooltipInfo,
  width = "100px",
  height = "100px",
  valueType = "percentage",
  unit = "%",
  color,
  range = { low: "red", mid: "orange", high: "green" },
  strokeEmpty="#f8f8f8",
  fontSizeScore="1em"
}) {
  const strokeWidth = 10; // Fixed stroke width
  const viewBoxSize = 120; // ViewBox size which SVG uses to calculate relative positions
  const completionPercentage = (score / totalScore) * 100;
  const [hover, setHover] = useState(false);

  const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;

  const determineColor = (percentage) => {
    if (color) return color;
    if (percentage < 30) return range.low;
    if (percentage >= 30 && percentage < 50) return range.mid;
    return range.high;
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ position: "relative", width, height }}
    >
      <div
        style={{
          minWidth: "0",
          marginLeft: "auto",
          padding: 0,
          flexDirection: "column",
          textTransform: "none",
          color: "black",
          width: "100%",
          height: "100%"
        }}
      >
        <Tooltip title={title}>
          <div style={{ width: "100%", height: "100%" }}>
            <svg
              width="100%"
              height="100%"
              viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
              preserveAspectRatio="xMidYMid meet"
            >
              {/* Gradient Definition */}
              {/* <defs>
                <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="lightgreen" />
                  <stop offset="100%" stopColor="green" />
                </linearGradient>
              </defs> */}

              {/* Empty Circle */}
              <circle
                cx={viewBoxSize / 2}
                cy={viewBoxSize / 2}
                r={(viewBoxSize - strokeWidth) / 2}
                fill="transparent"
                stroke={strokeEmpty}
                strokeWidth={strokeWidth}
              />

              {/* Progress Circle */}
              <circle
                cx={viewBoxSize / 2}
                cy={viewBoxSize / 2}
                r={(viewBoxSize - strokeWidth) / 2}
                fill="transparent"
                // stroke={`url(#${gradientId})`}
                strokeWidth={strokeWidth}
                stroke={determineColor(completionPercentage)}
                strokeDasharray={
                  2 * Math.PI * (viewBoxSize - strokeWidth) / 2
                }
                strokeDashoffset={
                  (1 - completionPercentage / 100) *
                  2 * Math.PI * (viewBoxSize - strokeWidth) / 2
                }
                transform={`rotate(-90 ${viewBoxSize / 2} ${viewBoxSize / 2})`}
              />

              {/* Percentage Text */}
              <text
                x="50%"
                y="45%"
                dy=".3em"
                textAnchor="middle"
                fontSize={fontSizeScore}
                fill="#666"
              >
                {valueType === "percentage" && `${completionPercentage.toFixed(2)}%`}
                {valueType === "currency" && `${unit} ${formatNumber(score)}`}
              </text>

              {/* Title Text */}
              <text
                x="50%"
                y="60%"
                dy=".3em"
                textAnchor="middle"
                fontSize="0.7em"
                fill="#666"
                style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
              >
                {`${title}`}
              </text>
            </svg>
          </div>
        </Tooltip>
      </div>
      {hover && (
        <TooltipInfo
          width="300px"
          position="absolute"
          top="100%"
          style={{ zIndex: "10000", padding: "0.5rem" }}
        >
          {tooltipInfo}
        </TooltipInfo>
      )}
    </div>
  );
}


// import { Tooltip } from '@mui/material';
// import TooltipInfo from 'components/Modal/TooltipInfo';
// import React, { useState } from 'react';
// import { formatNumber } from 'utils/utilGeneral';

// export default function CircleScore({
//   score = 0,
//   totalScore = 0,
//   title = "Score",
//   tooltipInfo,
//   width = "100px",
//   height = "100px",
//   valueType = "percentage",
//   unit = "%",
//   color,
//   range = { low: "red", mid: "orange", high: "green" },
//   strokeEmpty="#f8f8f8",fontSizeScore="1em"
// }) {
//   const strokeWidth = 10; // Fixed stroke width
//   const viewBoxSize = 120; // ViewBox size which SVG uses to calculate relative positions
//   const completionPercentage = (score / totalScore) * 100;
//   const [hover, setHover] = useState(false);

//   const determineColor = (percentage) => {
//     if (color) return color;
//     if (percentage < 30) return range.low;
//     if (percentage >= 30 && percentage < 50) return range.mid;
//     return range.high;
//   };

//   return (
//     <div
//       onMouseEnter={() => setHover(true)}
//       onMouseLeave={() => setHover(false)}
//       style={{ position: "relative", width, height }}
//     >
//       <div
//         style={{
//           minWidth: "0",
//           marginLeft: "auto",
//           padding: 0,
//           flexDirection: "column",
//           textTransform: "none",
//           color: "black",
//           width: "100%",
//           height: "100%"
//         }}
//       >
//         <Tooltip title={title}>
//         <div style={{ width: "100%", height: "100%" }}>
//           <svg
//             width="100%"
//             height="100%"
//             viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
//             preserveAspectRatio="xMidYMid meet"
//           >
//             <circle
//               cx={viewBoxSize / 2}
//               cy={viewBoxSize / 2}
//               r={(viewBoxSize - strokeWidth) / 2}
//               fill="transparent"
//               stroke={strokeEmpty}
//               strokeWidth={strokeWidth}
//             />
//             <circle
//               cx={viewBoxSize / 2}
//               cy={viewBoxSize / 2}
//               r={(viewBoxSize - strokeWidth) / 2}
//               fill="transparent"
//               stroke={determineColor(completionPercentage)}
//               strokeWidth={strokeWidth}
//               strokeDasharray={2 * Math.PI * (viewBoxSize - strokeWidth) / 2}
//               strokeDashoffset={(1 - completionPercentage / 100) * 2 * Math.PI * (viewBoxSize - strokeWidth) / 2}
//               transform={`rotate(-90 ${viewBoxSize / 2} ${viewBoxSize / 2})`}
//             />
//             <text
//               x="50%"
//               y="45%"
//               dy=".3em"
//               textAnchor="middle"
//               fontSize={fontSizeScore}
//               fill="#666"
//             >
//              {valueType === "percentage" && `${completionPercentage.toFixed(2)}%`}
//              {valueType === "currency" && `${unit} ${formatNumber(score)}`}
//             </text>
//              <text
//               x="50%"
//               y="60%"
//               dy=".3em"
//               textAnchor="middle"
//               fontSize="0.7em"
//               fill="#666"
//               style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
//             >
//               {`${title}`}
//             </text>
//           </svg>
//         </div>
//         </Tooltip>
//       </div>
//       {hover && (
//         <TooltipInfo
//           width="300px"
//           position="absolute"
//           top="100%"
//           style={{ zIndex: "10000", padding: "0.5rem" }}
//         >
//           {tooltipInfo}
//         </TooltipInfo>
//       )}
//     </div>
//   );
// }
