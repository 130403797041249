import { useAppInfo } from 'AppState';
import { sendEmailForUser } from 'automate/sendEmail';

import useAssistantFinancialAdvisor from './useAssistantFinancialAdvisor';
import useAssistantPortfolioManager from './useAssistantPortfolioManager';

export default function useAssistantNotify() {
    const {stateApp} = useAppInfo()
    const { userDetails } = stateApp;
    const userEmail = userDetails?.email;
    const userPhoto = userDetails?.photoURL;
    const { GetMyGoals,
        GetMyFinancialHealth,
        GetMyFinancials,
        GetMyAssets,
        GetMyLiabilities,
        GetMyCashflow,
        GetMyExpense,
        GetMyIncome,
        GetRiskTolerance,
        GetNewGoals,} = useAssistantFinancialAdvisor()
const { GetPortfolioSummary} = useAssistantPortfolioManager()
    async function SendEmailForUser({recipientEmail, subject, body}){
        try {
            const info = await sendEmailForUser(userEmail, recipientEmail, subject, body,userPhoto)
            return `Email sent successfully to ${recipientEmail}`;
        } catch (error) {
            console.error('Error sending email:', error);
            return "Failed to send email";
        }
    }

    function GetEmailAddress({recipient_names,recipient_type}){
        // console.log(recipient_names,recipient_type)
        const emailAddresses = [
        // {name:"Milind",role:"F",email:"milind@autonomyfinance.ai"},
        {name:"Yash",role:"Financial Advisor",email:"yash1993shah@gmail.com"},
        // {name:"Yash Shah",role:"Financial Advisor",email:"yash@takestock.ai"},
        {name:"Hrishi",role:"Financial Advisor",email:"hrishi@autonomyfinance.ai"},
        {name:"Matt",role:"Financial Advisor",email:"matt@autonomyfinance.ai"},
        {name:"Seb",role:"Financial Advisor",email:"sebastian@autonomyfinance.ai"},
        ]
        const emailAddress = emailAddresses.filter(obj=>recipient_names.includes(obj.name) || obj.role === recipient_type)
        return emailAddress
    }


    function GetMessageDetails({message_type}){
          let info = {}
          if (message_type.includes("FinancialOverview")){
            const financials = GetMyFinancials()
            info["FinancialOverview"] = financials
          }
          if (message_type.includes("Assets")){
            const assets = GetMyAssets()
            info["Assets"] = assets
          }
          if (message_type.includes("Liabilities")){
            const liabilities = GetMyLiabilities()
            info["Liabilities"] = liabilities
          }
          if (message_type.includes("Portfolio")){
            const portfolio = GetPortfolioSummary()
            info["Portfolio"] = portfolio
          }
          if (message_type.includes("Cashflow")){
            const cashflow = GetMyCashflow()
            info["Cashflow"] = cashflow
          }
          if (message_type.includes("Goals")){
            const goals = GetMyGoals()
            info["Goals"] = goals
          }
          if (message_type.includes("Inquiry")){
            info["Inquiry"] = "What are you looking for?"
          } 
          if (message_type.includes("Risk Management")){
            const risk = GetMyFinancialHealth()
            info["Risk Management"] = risk
          }
          if (message_type.includes("Others")){
            info["Others"] = "Please specify your request"
          }
          return info
    }
    
    return {SendEmailForUser,GetEmailAddress,GetMessageDetails}
}


// {
//     "name": "get_recipient_contact",
//     "description": "Retrieve contact information for a specified recipient.",
//     "strict": true,
//     "parameters": {
//       "type": "object",
//       "required": [
//         "recipient_names",
//         "recipient_type"
//       ],
//       "properties": {
//         "recipient_names": {
//           "type": "array",
//           "description": "Array of recipient names whose contact information is being requested.",
//           "items": {
//             "type": "string",
//             "description": "A single recipient name"
//           }
//         },
//         "recipient_type": {
//           "type": "string",
//           "description": "Type of email recipient",
//           "enum": [
//             "Financial Advisor",
//             "Others"
//           ]
//         }
//       },
//       "additionalProperties": false
//     }
//   }