import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import {
  Button,
  Tooltip,
} from '@mui/material';

function ColorPicker({ defaultColor = "black", onChange, title = "Color Picker", sx = {}, icon = "" }) {
    const [color, setColor] = useState('#ffffff'); // Initialize color state
    const inputRef = useRef(null);

    useEffect(() => {
        setColor(defaultColor)
    }, [defaultColor]);

    const handleColorPicker = () => {
        inputRef.current.click(); // Trigger the hidden input when the button is clicked
    };

    const handleColorChange = (v) => {
        setColor(v); // Update color state when the color picker changes
        onChange(v);
    };

    return (
        <Tooltip title={title}>
            <div style={{ position: 'relative' }}>
                {icon === "bookmark" && (
                    <BookmarkIcon
                        style={{
                            transform: "rotate(90deg)", 
                            color: color, // Set the bookmark icon color to the selected color
                            fontSize: '1.5rem' // Adjust the size for better appearance
                        }}
                    />
                )}

                {icon === "standard" && (
                    <Button
                        onClick={handleColorPicker}
                        style={{
                            width: '40px', // Adjusted width for better appearance
                            height: '40px', // Adjusted height for better appearance
                            borderRadius: '5px', // Keep the button's border-radius
                            border: '1px solid #ddd', // Border for better visibility
                            cursor: 'pointer', // Cursor as pointer to indicate clickability
                            backgroundColor: color, // Background color logic based on the icon
                            ...sx,
                            position: 'relative', // Ensure the button is positioned relatively
                            overflow: 'hidden', // Prevents overflow of child elements
                            padding: 0,
                            minWidth: 0
                        }}
                        aria-label="Select color" // Accessibility label for screen readers
                    />
                )}
                
                <input
                    ref={inputRef}
                    type="color"
                    value={color} // Bind input's value to state
                    onChange={(e) => handleColorChange(e.target.value)} // Handle changes
                    style={{
                        position: 'absolute',
                        opacity: 0,
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        cursor: 'pointer',
                        border: 'none',
                        padding: 0
                    }}
                />
            </div>
        </Tooltip>
    );
}

export default ColorPicker;
