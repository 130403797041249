import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import useHandleSignOut from 'auth/useHandleSignout';
import axios from 'axios';
import TextInput from 'components/Inputs/TextInput';
import Modal from 'components/Modal/Modal';
import useUpdateOnboarding from 'data/user/onBoarding/useUpdateOnboarding';
import { updateProfile } from 'firebase/auth';
import {
  doc,
  updateDoc,
} from 'firebase/firestore';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Alert,
  Button,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';

import { BASE_URL } from '../apiConfig';
import {
  auth,
  db,
} from '../firebaseConfig/firebaseConfig';
import Terms from '../home/terms/Terms';
import Logo from '../logo/Logo';

export default function OnBoardingNew() {
  const { stateApp,dispatchMyFinancials,dispatchApp} = useAppInfo();
  const UpdateOnboarding = useUpdateOnboarding()
  const { displayName, onboarding, userId } = stateApp;
  const {objectives} = onboarding
  const [modal, setModal] = useState(true);
  const [steps, setSteps] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [username, setUsername] = useState();
  const [birthdate, setBirthdate] = useState(null);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const {handleSignOut} = useHandleSignOut()

  useEffect(()=>{
    dispatchMyFinancials({financialView:"demo"})
  },[])

  useEffect(()=>{
    if (displayName){
    setUsername(displayName)}
  },[displayName])

  // Handle change event of checkbox
  const handleCheckboxChange = (event) => {
    setIsTermsAccepted(event.target.checked);
  };


  const handleNext = async (e) => {
    e.preventDefault();
    if (steps === 0) {
      await handleRegister();
    } 
    
      else if (steps === 1){
        if (setIsTermsAccepted === false) {
          setSnackbarMessage("Please accept the Terms to continue.");
          setSnackbarOpen(true);
        }

        else {
          const updatedOnboarding = { ...onboarding,birthdate, setDisplayName: true,terms:true};
          UpdateOnboarding(username,updatedOnboarding);
  
          const user = auth.currentUser;
          await updateProfile(user, { displayName: username });
  
          const userDocRef = doc(db, "users", userId);
          await updateDoc(userDocRef, { displayName: username });
          setModal(false)
        }
      }
  };


  const handleRegister = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/register/`, { username });
      const { access_token } = response.data;

      if (access_token === "Success") {
        setSteps((prevSteps) => prevSteps + 1);
      }
    } catch (err) {
      if (err.response?.status === 400 && err.response.data.error === 'Username already exists') {
        setError('Username already exists. Please choose a different username.');
      } else {
        setError('Registration failed');
        console.error(err);
      }
    }
  };

 
  return (
    <>
      {!onboarding?.setDisplayName && (
        <Modal
          onClose={() => setModal(true)}
          backgroundVideo={true}
          sx={{borderRadius:0, 
         
          height: "100%",overflow:"auto",width:"100%",background:"transparent"}}
          closeIcon={false}
        >
          <form onSubmit={handleNext} 
          style={{ display: "flex", flexDirection: "column",
          gap: "2rem",boxSizing:"border-box",alignItems:"center"
          ,height:"100%",minWidth:"60%",width:"100%",padding:"2rem",position:"relative"}}>
        <div style={{alignSelf:"flex-start",display:"flex",flexDirection:"column",gap:"1rem"}}>
        <div className='gradient_text' style={{ display: "flex", gap: "0.5rem" }}>
          <Logo color='white' width="150px"  height='30px' type='long' />
          <Typography style={{alignSelf:"flex-start",fontSize:"0.9rem"}}>Beta</Typography>
        </div>
        {/* <div style={{display: "flex",justifyContent: "center", 
            alignItems: "center", gap: "1rem"}}>
        <Logo height="50px" width="50px" />
        <h3 style={{ color: "white" }}>Autonomy Beta</h3>
        </div> */}
       
        </div>
       
        <div style={{flex:1,display:"flex",flexDirection:"column",gap:"1rem"
            ,width:"100%",height:"100%",alignItems:"center"}}>
            <div style={{flex:1,display:"flex",flexDirection:"column",gap:"1rem",width:"100%",alignItems:"center"}}>
            {steps === 0 && (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", 
      alignItems: "center", gap: "2rem", width: "100%",flex:1}}>
      <h1 style={{ color: "white" }}>Let's get to know you!</h1>
      <div style={{ background: "white", 
        width: "max-content", padding: "1rem", boxSizing: "border-box", 
        borderRadius: "5px", display: "flex", flexDirection: "column", 
        alignItems: "center", gap: "2rem"}}>
        <TextInput
          required
          label="Username"
          fontSize="1.4rem"
          sx={{ fontSize: "1.4rem", width: "250px", background: "white", margin: 0 }}
          value={username}
          onChange={(v)=>setUsername(v)}
        />
        {/* <DatePicker required sx={{fontSize:"1.4rem",width:"250px"}} value={birthdate || ""} 
        label='Birth Date' handleDate={(v) => setBirthdate(v)}/> */}
        {error && <Alert severity="warning">{error}</Alert>}
      </div>
    </div>
            )}
            
        {steps === 1 && (
        <div style={{maxWidth:"700px",display: "flex",
        flexDirection: "column",gap:"1rem",flex:1,overflow:"auto"}}>
          
          <Paper style={{ maxHeight:"50vh", width: "100%",display:"flex",
          flexDirection:"column",gap:"0.5rem",padding:"0.5rem 0",overflow:"auto"}}>
            <h2 style={{textAlign:"center",fontWeight:"bold"}}>Terms & Conditions</h2>
            <Terms />
          </Paper>
          
          <div 
          onClick={()=>setIsTermsAccepted(!isTermsAccepted)}
          style={{ position: "sticky", bottom: "0"
          , zIndex: "1",boxSizing:"border-box"
          ,padding:"1rem",background:"white",borderRadius:"4px",display:"flex",gap:"0.5rem",alignItems:"center"}}>
              <input
                style={{height:"20px",width:"20px"}}
                type="checkbox"
                checked={isTermsAccepted}
                onChange={handleCheckboxChange}
                required
              />
             
            <label style={{fontSize:"1.3rem"}}>
            I accept the terms and conditions
            </label>
            
          </div>

        </div>
      )}

            <div style={{padding:"2rem", display: "flex", justifyContent: "center", gap: "2rem", alignItems: "center" }}>
                <Button onClick={() => {steps === 0 ? handleSignOut() : setSteps(steps - 1)} }>Back</Button>
              <Button endIcon={<ArrowForwardIosIcon />} type='submit' variant="contained">
                Next
              </Button>
            </div>
            </div>

            <Stepper
        activeStep={steps}
        alternativeLabel
        sx={{
          width: "100%",
          backgroundColor: "transparent", // Optional: to remove background color if any
        }}
      >
        {["Personal Info", "Terms & Conditions"].map((label) => (
          <Step key={label}>
            <StepLabel
              sx={{
                "& .MuiStepLabel-label": {
                  color: "white", // Label color
                },
                "& .MuiStepLabel-label.Mui-active": {
                    color: "white",
                  },
                "& .MuiStepLabel-iconContainer": {
                  color: "white", // Step icon color
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      </div>
          </form>
        </Modal>
      )}
    
    </>
  );
}

// export default function OnBoardingNew() {
//   const { stateApp,dispatchMyFinancials,dispatchApp} = useAppInfo();
//   const UpdateOnboarding = useUpdateOnboarding()
//   const { displayName, onboarding, userId } = stateApp;
//   const {objectives} = onboarding
//   const [modal, setModal] = useState(true);
//   const [steps, setSteps] = useState(0);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [error, setError] = useState(null);
//   const [username, setUsername] = useState();
//   const [birthdate, setBirthdate] = useState(null);
//   const [isTermsAccepted, setIsTermsAccepted] = useState(false);
//   const [userRole, setUserRole] = useState('consumer');
//   const {handleSignOut} = useHandleSignOut()

//   useEffect(()=>{
//     dispatchMyFinancials({financialView:"demo"})
//   },[])

//   useEffect(()=>{
//     if (displayName){
//     setUsername(displayName)}
//   },[displayName])

//   // Handle change event of checkbox
//   const handleCheckboxChange = (event) => {
//     setIsTermsAccepted(event.target.checked);
//   };


//   const handleNext = async (e) => {
//     e.preventDefault();
//     if (steps === 0) {
//       await handleRegister();
//     } 
    
//       else if (steps === 1){
//         setSteps((prevSteps) => prevSteps + 1);
//       }
//       else if (steps === 2) {
//         if (setIsTermsAccepted === false) {
//           setSnackbarMessage("Please accept the Terms to continue.");
//           setSnackbarOpen(true);
//         }

//         else {
//           const updatedOnboarding = { ...onboarding,birthdate, setDisplayName: true,terms:true, userRole};
//           UpdateOnboarding(username,updatedOnboarding);
  
//           const user = auth.currentUser;
//           await updateProfile(user, { displayName: username });
  
//           const userDocRef = doc(db, "users", userId);
//           await updateDoc(userDocRef, { displayName: username, role: userRole });
//           setModal(false)
//         }
//       }
//   };


//   const handleRegister = async () => {
//     try {
//       const response = await axios.post(`${BASE_URL}/register/`, { username });
//       const { access_token } = response.data;

//       if (access_token === "Success") {
//         setSteps((prevSteps) => prevSteps + 1);
//       }
//     } catch (err) {
//       if (err.response?.status === 400 && err.response.data.error === 'Username already exists') {
//         setError('Username already exists. Please choose a different username.');
//       } else {
//         setError('Registration failed');
//         console.error(err);
//       }
//     }
//   };

 
//   return (
//     <>
//       {!onboarding?.setDisplayName && (
//         <Modal
//           onClose={() => setModal(true)}
//           backgroundVideo={true}
//           sx={{borderRadius:0, 
         
//           height: "100%",overflow:"auto",width:"100%",background:"transparent"}}
//           closeIcon={false}
//         >
//           <form onSubmit={handleNext} 
//           style={{ display: "flex", flexDirection: "column",
//           gap: "2rem",boxSizing:"border-box",alignItems:"center"
//           ,height:"100%",minWidth:"60%",width:"100%",padding:"2rem",position:"relative"}}>
//         <div style={{alignSelf:"flex-start",display:"flex",flexDirection:"column",gap:"1rem"}}>
//         <div className='gradient_text' style={{ display: "flex", gap: "0.5rem" }}>
//           <Logo color='white' width="150px"  height='30px' type='long' />
//           <Typography style={{alignSelf:"flex-start",fontSize:"0.9rem"}}>Beta</Typography>
//         </div>
//         {/* <div style={{display: "flex",justifyContent: "center", 
//             alignItems: "center", gap: "1rem"}}>
//         <Logo height="50px" width="50px" />
//         <h3 style={{ color: "white" }}>Autonomy Beta</h3>
//         </div> */}
       
//         </div>
       
//         <div style={{flex:1,display:"flex",flexDirection:"column",gap:"1rem"
//             ,width:"100%",height:"100%",alignItems:"center"}}>
//             <div style={{flex:1,display:"flex",flexDirection:"column",gap:"1rem",width:"100%",alignItems:"center"}}>
//             {steps === 0 && (
//       <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", 
//       alignItems: "center", gap: "2rem", width: "100%",flex:1}}>
//       <h1 style={{ color: "white" }}>Let's get to know you!</h1>
//       <div style={{ background: "white", 
//         width: "max-content", padding: "1rem", boxSizing: "border-box", 
//         borderRadius: "5px", display: "flex", flexDirection: "column", 
//         alignItems: "center", gap: "2rem"}}>
//         <TextInput
//           required
//           label="Username"
//           fontSize="1.4rem"
//           sx={{ fontSize: "1.4rem", width: "250px", background: "white", margin: 0 }}
//           value={username}
//           onChange={(v)=>setUsername(v)}
//         />
//         {/* <DatePicker required sx={{fontSize:"1.4rem",width:"250px"}} value={birthdate || ""} 
//         label='Birth Date' handleDate={(v) => setBirthdate(v)}/> */}
//         {error && <Alert severity="warning">{error}</Alert>}
//       </div>
//     </div>
//             )}
            
//         {steps === 1 && (
//         <div style={{display: "flex",
//         flexDirection: "column",gap:"1rem",flex:1,overflow:"auto"}}>
          
//           <Paper style={{ maxHeight:"50vh", width: "100%",display:"flex",
//           flexDirection:"column",gap:"0.5rem",padding:"0.5rem 0",overflow:"auto"}}>
//             <h2 style={{textAlign:"center",fontWeight:"bold"}}>Choose Your Role</h2>
//             <Paper style={{
//               padding: "2rem",
//               display: "flex",
//               flexDirection: "column",
//               gap: "1rem",
//               minWidth: "300px"
//             }}>
//               <FormControl component="fieldset">
//                 <FormLabel component="legend">I am a:</FormLabel>
//                 <RadioGroup
//                   value={userRole}
//                   onChange={(e) => setUserRole(e.target.value)}
//                 >
//                   <FormControlLabel
//                     value="consumer"
//                     control={<Radio />}
//                     label="Individual Investor (B2C)"
//                   />
//                   <FormControlLabel
//                     value="advisor"
//                     control={<Radio />}
//                     label="Financial Advisor (B2B)"
//                   />
//                 </RadioGroup>
//               </FormControl>
//             </Paper>
//           </Paper>
          
//         </div>
//       )}

//             {steps === 2 && (
//         <div style={{maxWidth:"700px",display: "flex",
//         flexDirection: "column",gap:"1rem",flex:1,overflow:"auto"}}>
          
//           <Paper style={{ maxHeight:"50vh", width: "100%",display:"flex",
//           flexDirection:"column",gap:"0.5rem",padding:"0.5rem 0",overflow:"auto"}}>
//             <h2 style={{textAlign:"center",fontWeight:"bold"}}>Terms & Conditions</h2>
//             <Terms />
//           </Paper>
          
//           <div 
//           onClick={()=>setIsTermsAccepted(!isTermsAccepted)}
//           style={{ position: "sticky", bottom: "0"
//           , zIndex: "1",boxSizing:"border-box"
//           ,padding:"1rem",background:"white",borderRadius:"4px",display:"flex",gap:"0.5rem",alignItems:"center"}}>
//               <input
//                 style={{height:"20px",width:"20px"}}
//                 type="checkbox"
//                 checked={isTermsAccepted}
//                 onChange={handleCheckboxChange}
//                 required
//               />
             
//             <label style={{fontSize:"1.3rem"}}>
//             I accept the terms and conditions
//             </label>
            
//           </div>

//         </div>
//       )}

//             <div style={{padding:"2rem", display: "flex", justifyContent: "center", gap: "2rem", alignItems: "center" }}>
//                 <Button onClick={() => {steps === 0 ? handleSignOut() : setSteps(steps - 1)} }>Back</Button>
//               <Button endIcon={<ArrowForwardIosIcon />} type='submit' variant="contained">
//                 Next
//               </Button>
//             </div>
//             </div>

//             <Stepper
//         activeStep={steps}
//         alternativeLabel
//         sx={{
//           width: "100%",
//           backgroundColor: "transparent", // Optional: to remove background color if any
//         }}
//       >
//         {["Personal Info", "Choose Role", "Terms & Conditions"].map((label) => (
//           <Step key={label}>
//             <StepLabel
//               sx={{
//                 "& .MuiStepLabel-label": {
//                   color: "white", // Label color
//                 },
//                 "& .MuiStepLabel-label.Mui-active": {
//                     color: "white",
//                   },
//                 "& .MuiStepLabel-iconContainer": {
//                   color: "white", // Step icon color
//                 },
//               }}
//             >
//               {label}
//             </StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//       </div>
//           </form>
//         </Modal>
//       )}
    
//     </>
//   );
// }
