import React, { useEffect, useState } from 'react'
import InvestmentPreferences from './InvestmentPreferences'
import Modal from 'components/Modal/Modal'
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating'
import LeftSidebarButton from 'sidebar/LeftSidebar/LeftSidebarButton'
import BorderBox from 'components/Layout/BorderBox'

export default function InvestmentPreferenceModal({open,onClose}) {
  const [modal,setModal] = useState(false)
  const [preferenceSel,setPreferenceSel] = useState("Market Region")
  const preferenceList = ["Market Region","Time Horizon","Risk Tolerance","Asset Allocation"]

  useEffect(()=>{
    if (open){
    setModal(true)}
  },[open])

  return (
    <>
    {modal &&
    <Modal width='90%' height='80%' onClose={()=>{setModal(false);onClose && onClose(false)}}>

    <div style={{display:"flex",gap:"0.5rem",padding:"0.5rem 0.25rem",height:"100%",overflow:"auto"}}>
    <div style={{width:"15%",minWidth:"200px"
      ,display:"flex",flexDirection:"column",height:"100%",gap:"0.5rem",background:"#fafafa",borderRadius:"10px"}}>
        <h2 style={{padding:"0.5rem 1rem"}}>Investment Preferences</h2>
      {preferenceList.map((obj,index)=>
      <LeftSidebarButton 
      selected={preferenceSel === obj} 
      key={obj} name={obj} onClick={(v)=>setPreferenceSel(v)}/>)}
    </div>
    <div style={{flex:1,minWidth:"400px",height:"100%"}}>
    <InvestmentPreferences info={preferenceSel}/>
    
    </div>
    </div>
    </Modal>}
    </>
  )
}
