import { Button } from '@mui/material'
import { useAppInfo } from 'AppState'
import { assetTypes, defaultFieldBlank } from 'appState/initialStateMyFinancials'
import AddFinancialsModal from 'myFinancials/Utils/AddFinancialsModal'
import FieldFormGen from 'myFinancials/FormFields/FieldFormGen'
import React, { useState } from 'react'

export default function AddIncome() {

  const [add,setAdd] = useState(false)

  return (
    <>
    <Button sx={{width:"max-content"}} variant='contained' onClick={()=>setAdd(true)}>+ Income</Button>
    {add && <AddFinancialsModal title="Income" onClose={()=>setAdd(false)}>
    <IncomeTableForm/>
    </AddFinancialsModal>}
    </>
  )
}

export function IncomeTableForm(){
  const {stateMyFinancials} = useAppInfo()
const {incomeMonthly} = stateMyFinancials
  return (
    <FieldFormGen 
  defaultField={defaultFieldBlank.Income}
  date={true}
  forecastPeriod={true} growthRate={true} 
  period={true} formName='Income' storeName={"incomeMonthly"} data={incomeMonthly} 
  formHeading='Income Category'/>
  )
}