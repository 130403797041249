import React, { useState } from 'react';

import AssessmentIcon from '@mui/icons-material/Assessment';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import RateReviewIcon from '@mui/icons-material/RateReview';
import {
  Alert,
  Button,
  Portal,
  Snackbar,
} from '@mui/material';

import styles from './Pulse.module.css';
import {
  ACTION_TYPES,
  actionHandlers,
} from './pulseData';

export default function PulseAction({item,filteredPulseData}) {
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [confirmationSnackbar, setConfirmationSnackbar] = useState({ open: false, message: '', action: null });
    const handleBulkExecute = (actionType) => {
        // Get all affected clients from filtered data
        const affectedClients = filteredPulseData.flatMap(item => 
            item.actions && item.actions.some(action => action.type === actionType) ? item.affectedClients : []
        );
        
        if (affectedClients.length > 0) {
            handleAction(actionType, { affectedClients });
        } else {
            setSnackbar({
                open: true,
                message: 'No clients found with selected action type',
                severity: 'warning'
            });
        }
    };
    const handleAction = async (actionType, actionDetails) => {
        try {
            let initialMessage = '';
            let confirmationMessage = '';
            
            switch (actionType) {
                case ACTION_TYPES.REVIEW:
                    initialMessage = `Initiating review for clients...`;
                    confirmationMessage = `Review clients?`;
                    break;
                case ACTION_TYPES.SCHEDULE:
                    initialMessage = 'Opening scheduler for selected clients...';
                    confirmationMessage = 'Schedule meetings for selected clients?';
                    break;
                case ACTION_TYPES.UPDATE:
                    initialMessage = 'Initiating document updates... Will notify when ready for review.';
                    confirmationMessage = 'Update documents for selected clients?';
                    break;
                case ACTION_TYPES.BOOKMARK:
                    initialMessage = 'Adding to bookmarks...';
                    confirmationMessage = 'Add to bookmarks?';
                    break;
                case ACTION_TYPES.NOTIFY:
                    initialMessage = 'Preparing notifications...';
                    confirmationMessage = 'Send notifications to selected clients?';
                    break;
                case ACTION_TYPES.REPORT:
                    initialMessage = 'Generating report...';
                    confirmationMessage = 'Build report for selected clients?';
                    break;
                default:
                    initialMessage = `${actionType} action initiated...`;
                    confirmationMessage = `Execute ${actionType.toLowerCase()} action?`;
            }
            
            // Show initial notification
            setSnackbar({ 
                open: true, 
                message: initialMessage,
                severity: 'info'
            });

            // Show confirmation snackbar after a delay
            setTimeout(() => {
                setConfirmationSnackbar({
                    open: true,
                    message: confirmationMessage,
                    action: async () => {
                        setConfirmationSnackbar({ open: false, message: '', action: null });
                        
                        const handler = actionHandlers[actionType];
                        if (handler) {
                            try {
                                const result = await handler(actionDetails);
                                setSnackbar({ 
                                    open: true, 
                                    message: result.message || `${actionType} completed successfully`,
                                    severity: 'success'
                                });
                            } catch (error) {
                                setSnackbar({ 
                                    open: true, 
                                    message: `Error: ${error.message}`,
                                    severity: 'error'
                                });
                            }
                        } else {
                            setSnackbar({ 
                                open: true, 
                                message: `Action handler not found for ${actionType}`,
                                severity: 'error'
                            });
                        }
                    }
                });
            }, 1500);

        } catch (error) {
            setSnackbar({ 
                open: true, 
                message: `Error: ${error.message}`,
                severity: 'error'
            });
        }
    };
  return (
    <>
    
    <div className={styles.pulseActionsRow}>
    <Button
        className={styles.actionButtonRow}
        onClick={() => handleAction(ACTION_TYPES.BOOKMARK, { itemId: item.id })}
        startIcon={<BookmarkBorderIcon />}
    >
        Bookmark
    </Button>
    <Button
        className={styles.actionButtonRow}
        onClick={() => handleAction(ACTION_TYPES.REVIEW, { 
            itemId: item.id,
            affectedClients: item.affectedClients 
        })}
        startIcon={<RateReviewIcon />}
    >
        Review
    </Button>
    <Button
        className={styles.actionButtonRow}
        onClick={() => handleAction(ACTION_TYPES.NOTIFY, { 
            itemId: item.id,
            affectedClients: item.affectedClients 
        })}
        startIcon={<NotificationsNoneIcon />}
    >
        Notify
    </Button>
    <Button
        className={styles.actionButtonRow}
        onClick={() => handleAction(ACTION_TYPES.REPORT, { 
            itemId: item.id,
            affectedClients: item.affectedClients 
        })}
        startIcon={<AssessmentIcon />}
    >
        Build Report
    </Button>
    <Button
        className={styles.actionButtonRow}
        onClick={() => handleAction(ACTION_TYPES.DISCARD, { 
            itemId: item.id,
            affectedClients: item.affectedClients 
        })}
        startIcon={<DeleteIcon />}
    >
        Discard
    </Button>
</div>
<Portal>
                <Snackbar 
                    open={snackbar.open}
                    autoHideDuration={4000}
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    sx={{ position: 'fixed', top: '24px', zIndex: 9999 }}
                >
                    <Alert 
                        onClose={() => setSnackbar({ ...snackbar, open: false })} 
                        severity={snackbar.severity}
                        sx={{ width: '100%' }}
                        elevation={6}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={confirmationSnackbar.open}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    sx={{ position: 'fixed', bottom: '24px', zIndex: 9999 }}
                >
                    <Alert 
                        severity="info"
                        sx={{ width: '100%' }}
                        elevation={6}
                        action={
                            <>
                                <Button 
                                    color="inherit" 
                                    size="small" 
                                    onClick={() => setConfirmationSnackbar({ open: false, message: '', action: null })}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    color="inherit" 
                                    size="small" 
                                    onClick={confirmationSnackbar.action}
                                    sx={{ ml: 1 }}
                                >
                                    Accept
                                </Button>
                            </>
                        }
                    >
                        {confirmationSnackbar.message}
                    </Alert>
                </Snackbar>
            </Portal>

</>
  )
}
