import React from 'react'
import usePortfolioFitOpportunities from './usePortflioFitOpportunities'
import { dfin } from 'content/constants'
import SpeedIcon from "@mui/icons-material/Speed";
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import LoadingSkeletonPage from 'components/Loading/LoadingSkeletonPage';
import InvestmentPreferenceButton from 'myFinancials/InvestmentPreference/InvestmentPreferenceButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import HomeInsightNotificationBox from 'home/HomeInsightNotificationBox';


export default function PortfolioFitNotification() {
  const {stocks,assetClasses,assetClassSel,loaded} = usePortfolioFitOpportunities()
  const navigate = useNavigate()
  return (
    <HomeInsightNotificationBox navigateUrl="/Opportunities/PortfolioFit">
             
            <div 
             
            style={{minWidth:"60px",width:"60px",display:"flex",alignItems:"center",justifyContent:"center"}}>
            <Badge max={100} overlap="circular" sx={{color:stocks.length > 0 ? "green" : "gray"}} badgeContent={stocks.length}>
            <SpeedIcon style={{width:"60px",height:"60px",color:loaded && stocks.length > 0 ? "green" : "#ddd"}}/></Badge>
            </div>
            {!loaded && <div style={{flex:1}}>
              <h4 className="fade-in-simple"  style={{ fontWeight: "bold" }}>
                Searching Opportunities...</h4>
                
                </div>}
            {loaded &&
            <div style={{flex:1}}>
            {stocks.length === 0 && 
            <div>
            <h4 style={{ fontWeight: "bold" }}>
            {`Set Investment Preferences`}
            </h4>
            {/* <InvestmentPreferenceButton  variant='outlined'/> */}
        </div>
            }
            {stocks.length > 0 &&
            <>
            <h4 
            onClick={(e)=>{e.stopPropagation();navigate("/Opportunities/PortfolioFit")}}
            style={{ fontWeight: "bold" }}>
                {`Portfolio Fit Opportunities: ${stocks.length}`}</h4>
                <Link to={"/Opportunities/PortfolioFit"}/>
                {/* <AvatarGroup stocks={stocks}/> */}
                
                </>}
            </div>}
            {/* <Button
             onClick={(e)=>{e.stopPropagation();navigate("/Opportunities/PortfolioFit")}}
        style={{
        padding:"0.5rem",
        borderRadius:0,
        minWidth:0,
        fontWeight:"bold"}}  
       >
          <KeyboardArrowRightIcon sx={{color:"gray"}} fontSize='small'/>
        </Button> */}
    </HomeInsightNotificationBox>
  )
}


function AvatarGroup({ stocks }) {
    const maxVisible = 5; // Maximum avatars to show
    const extraCount = stocks.length - maxVisible; // Calculate the +x count
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {stocks.slice(0, maxVisible).map((obj, index) => (
          <div
            key={index}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              overflow: "hidden",
              border: "2px solid white", // Border for nice separation
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#f0f0f0",
              zIndex: maxVisible - index, // Ensure proper stacking
            }}
          >
            <img
              src={obj?.[dfin.coLogo]}
              alt="logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        ))}
        {extraCount > 0 && (
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              background: "#ddd",
              color: "#555",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "14px",
              border: "2px solid white", // Match the border for consistency
            }}
          >
            +{extraCount}
          </div>
        )}
      </div>
    );
  }
  