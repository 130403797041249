import { TextField } from '@mui/material';
import React, { useEffect } from 'react';

export default function TextInput({
  variant = "standard",
  size = "medium",
  onChange,
  required = true,
  label = "",
  value = "",
  type = "text",
  sx,
  step = "any",
  min,
  max,
  fontSize,
  placeholder,
  disabled = false,
  labelFontSize,
}) {
  // Format numbers with commas, retaining decimals
  const [inputValue, setInputValue] = React.useState(value);

  useEffect(() => {
    setInputValue(value)
  },[value])
  const formatWithCommas = (num) => {
    if (num === undefined || num === null || num === "") return ""; 
    const numStr = num.toString();
    const [integer, decimal] = numStr.split(".");  
    const formattedInteger = new Intl.NumberFormat().format(Number(integer.replace(/,/g, "")));
    return decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger;
  };

  const handleInputChange = (inputValue) => {
    if (type === "number") {
      let input = inputValue;
      const isValid = /^\d*\.?\d*$/.test(input.replace(/,/g, ""));
      if (!isValid) return;
      const rawValue = input.replace(/,/g, "");
      setInputValue(rawValue); 
      onChange(Number(rawValue)); 
    } else {
      onChange(inputValue);
    }
  };

  return (
    <TextField
      disabled={disabled}
      sx={{
        ...sx,
        '& .MuiInputBase-input': {
          fontSize: fontSize,
        },
        '& .MuiInputLabel-root': {
          fontSize: labelFontSize,
        },
      }}
      size={size}
      type="text" // Always render as text for proper display
      required={required}
      label={label}
      placeholder={placeholder}
      variant={variant}
      value={
        type === "number"
          ? formatWithCommas(inputValue) // Format numbers with commas
          : value // Display raw value for text
      }
      onChange={(e) => handleInputChange(e.target.value)}
      inputProps={{ step, min, max }}
      fullWidth
    />
  );
}



// import { TextField } from '@mui/material';
// import React from 'react';


// export default function TextInput({
//   variant = "standard",
//   size = "medium",
//   onChange,
//   required = true,
//   label = "",
//   value,
//   type = "text",
//   sx,
//   step = "any",
//   min,
//   max,
//   fontSize,
//   placeholder,
//   disabled = false,
//   labelFontSize // Add a prop for label font size
// }) {
//   let stringValue = value === undefined ? '' : value.toString();
//   return (
//     <TextField 
//       disabled={disabled}
//       sx={{
//         ...sx,
//         '& .MuiInputBase-input': {
//           fontSize: fontSize,
//         },
//         '& .MuiInputLabel-root': {
//           fontSize: labelFontSize, // Customize label font size
//         },
//       }}
//       size={size}
//       type={type}
//       required={required}
//       label={label}
//       placeholder={placeholder}
//       variant={variant}
//       value={stringValue} // Ensure value is never undefined
//       onChange={(e) => onChange(e.target.value)}
//       inputProps={{ step, min, max }}
//       fullWidth // This ensures the component covers the full width of the parent container
//     />
//   );
// }
