import React, { useState } from 'react';

import { useAppInfo } from 'AppState';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';

import { sendEmailForUser } from './sendEmail';

export default function FormEmail() {
  const { stateApp } = useAppInfo();
  const { userDetails } = stateApp;
  const userEmail = userDetails?.email;

  const [formData, setFormData] = useState({
    recipientEmail: '',
    subject: '',
    body: '',
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await sendEmailForUser(
        userEmail,
        formData.recipientEmail,
        formData.subject,
        formData.body
      );

      setSnackbar({
        open: true,
        message: 'Email sent successfully!',
        severity: 'success',
      });

      // Reset form
      setFormData({
        recipientEmail: '',
        subject: '',
        body: '',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to send email. Please try again.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Paper 
        elevation={0}
        sx={{ 
          p: 4,
          borderRadius: 2,
          border: '1px solid #e0e0e0',
          backgroundColor: '#ffffff',
        }}
      >
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 500, color: '#1a1a1a' }}>
          Send Email
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="From"
              value={userEmail || ''}
              disabled
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            />

            <TextField
              required
              label="To"
              name="recipientEmail"
              value={formData.recipientEmail}
              onChange={handleChange}
              fullWidth
              placeholder="recipient@example.com"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            />

            <TextField
              required
              label="Subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              fullWidth
              placeholder="Enter email subject"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            />

            <TextField
              required
              label="Message"
              name="body"
              value={formData.body}
              onChange={handleChange}
              fullWidth
              multiline
              rows={6}
              placeholder="Enter your message"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{
                py: 1.5,
                px: 4,
                borderRadius: 2,
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: '0 4px 12px rgba(25,118,210,0.2)',
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Send Email'
              )}
            </Button>
          </Box>
        </form>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
} 