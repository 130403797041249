import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';

const FilterModal = ({ 
  open, 
  onClose, 
  title, 
  children, 
  onApply, 
  onReset,
  onSearchChange,
  searchTerm,
  searchPlaceholder
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }
      }}
    >
      <DialogTitle sx={{ 
        padding: '20px 24px',
        borderBottom: '1px solid #E5E7EB',
        backgroundColor: '#F9FAFB'
      }}>
        {title}
      </DialogTitle>
      
      <DialogContent sx={{ padding: '24px' }}>
        {/* Search Bar */}
        <TextField
          fullWidth
          size="small"
          placeholder={searchPlaceholder || 'Search Clients...'}
          value={searchTerm || ''}
          onChange={(e) => onSearchChange?.(e.target.value)}
          sx={{
            marginTop: '16px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: '#F9FAFB',
              '&:hover': {
                backgroundColor: '#F3F4F6'
              },
              '&.Mui-focused': {
                backgroundColor: '#FFFFFF'
              }
            },
            '& .MuiOutlinedInput-input': {
              padding: '10px 12px',
              fontSize: '0.875rem'
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: '#6B7280', fontSize: '1.25rem' }} />
              </InputAdornment>
            ),
          }}
        />

        {children}
      </DialogContent>

      <DialogActions sx={{ 
        padding: '16px 24px',
        gap: '8px',
        borderTop: '1px solid #E5E7EB',
        backgroundColor: '#F9FAFB'
      }}>
        <Button 
          onClick={onReset}
          variant="outlined"
          sx={{
            padding: '6px 16px',
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '0.875rem',
            borderColor: '#E5E7EB',
            color: '#4B5563',
            '&:hover': {
              borderColor: '#D1D5DB',
              backgroundColor: '#F3F4F6'
            }
          }}
        >
          Reset
        </Button>
        <Button 
          onClick={onApply}
          variant="contained"
          sx={{
            padding: '6px 16px',
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '0.875rem',
            backgroundColor: '#1F2937',
            '&:hover': {
              backgroundColor: '#111827'
            }
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal; 