import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { useAppInfo } from "AppState";
import { demoAccounts } from "./DemoAccounts";
import useUpdateOnboarding from "data/user/onBoarding/useUpdateOnboarding";
import Toggle from "components/Inputs/Toggle";
import { fi } from "date-fns/locale";

const DemoToggle = ({ toggleValues=["Personal","Demo"]}) => {
    const {stateMyFinancials,stateApp,dispatchMyFinancials,dispatchPortfolio} = useAppInfo()
    const {onboarding,username} = stateApp
    const {financialView,demoProfile} = stateMyFinancials
    const UpdateOnboarding = useUpdateOnboarding()

  const handleToggle = (v) => {
    if (v === (financialView !== "personal")) return

    dispatchMyFinancials({"financialView":!v ? "personal":"demo",financialsLoaded:false,
      financialsUpdated:false,assetUpdatedWithPortfolio:false})
    dispatchPortfolio({portfolioLoaded:false,portfolioResults:undefined})
    const updatedOnboarding = {...onboarding,financialView:!v ? "personal":"demo"}
    UpdateOnboarding(username,updatedOnboarding)
  };

  function onAvatarClick() {
    dispatchMyFinancials({demoProfileModal:true})
  }

  const [toggleMode, setToggleMode] = useState(false);

  useEffect(() => {
    setToggleMode(financialView !== "personal");
  }, [financialView]);

  const demoProfileSelected = demoAccounts.filter(account => account.name === demoProfile)[0]
  
 
  return (
    <div style={{padding:"0.5rem"}}>
    <Toggle handleToggle={handleToggle} 
    toggleValues={toggleValues}
    toggleMode={toggleMode}
    toggle1info={toggleMode && (
      <Tooltip title="Change Demo Profile">
        <Avatar
          src={demoProfileSelected?.img}
          onClick={onAvatarClick && onAvatarClick}
          style={{
            position: "absolute",
            top: "-15px",
            right: "-15px",
            cursor: "pointer",
            height: "30px",
            width: "30px",
            backgroundColor: "#gray",
            color: "black",
            fontSize: "0.8rem",
          }}
        >
          {demoProfileSelected && demoProfileSelected?.avatar}
        </Avatar>
      </Tooltip>
    )}
    />
          
   
 
    </div>
  );
};

export default DemoToggle;

   // <div
    //   style={{
    //     display: "flex",
    //     borderRadius: "10px",
    //     borderColor: "rgb(10, 22, 34)",
    //     border: "2px solid black",
    //     width: "max-content",
    //     cursor: "pointer",
    //     position: "relative", // To position the avatar
    //     alignItems: "center",
    //     marginRight:"15px",height:"max-content"
    //   }}
    // >
    //   <Tooltip title={"Your Profile"}>
    //   <button
    //     onClick={() => handleToggle(false)}
    //     style={{
    //       background: !toggleMode ? "black" : "none",
    //       color: !toggleMode ? "white" : "black",
    //       borderRadius: "7px",
    //       border: "none",
    //       fontSize: "0.8rem",
    //       textOverflow: "ellipsis",
    //       overflow: "hidden",
    //       whiteSpace: "nowrap",
    //       flex: !toggleMode && 1,cursor:"pointer"
    //     }}
    //     value={false}
    //   >
    //     {toggleValues[0]}
    //   </button>
    //   </Tooltip>
    //   <Tooltip title={`Demo Profile: ${demoProfileSelected?.name}`}>
    //   <button
    //     onClick={() => handleToggle(true)}
    //     style={{
    //       background: toggleMode ? "black" : "none",
    //       color: toggleMode ? "white" : "black",
    //       borderRadius: "7px",
    //       border: "none",
    //       fontSize: "0.8rem",
    //       textOverflow: "ellipsis",
    //       overflow: "hidden",
    //       whiteSpace: "nowrap",
    //       flex: toggleMode && 1,cursor:"pointer"
    //     }}
    //     value={true}
    //   >
    //     {toggleValues[1]}
    //   </button>
    //   </Tooltip>
    //   {/* {!toggleMode && (
    //     <Tooltip title="Your Profile">
    //       <Avatar
    //         src={stateApp?.googlePhoto}
    //         onClick={onAvatarClick && onAvatarClick}
            
    //         style={{
    //           position: "absolute",
    //           top: "-15px",
    //           left: "-20px",
    //           cursor: "pointer",
    //           height: "30px",
    //           width: "30px",
    //           backgroundColor: "#gray",
    //           color: "black",
    //           fontSize: "0.8rem",
    //         }}
    //       >
    //         {stateApp?.username.split("")[0]}
    //       </Avatar>
    //     </Tooltip>
    //   )} */}
    //   {toggleMode && (
    //     <Tooltip title="Change Demo Profile">
    //       <Avatar
    //         src={demoProfileSelected?.img}
    //         onClick={onAvatarClick && onAvatarClick}
    //         style={{
    //           position: "absolute",
    //           top: "-15px",
    //           right: "-15px",
    //           cursor: "pointer",
    //           height: "30px",
    //           width: "30px",
    //           backgroundColor: "#gray",
    //           color: "black",
    //           fontSize: "0.8rem",
    //         }}
    //       >
    //         {demoProfileSelected && demoProfileSelected?.avatar}
    //       </Avatar>
    //     </Tooltip>
    //   )}
    // </div>