import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { menuMyPortfolio } from "nav/menu";
import LeftSidebarPageMenu from "sidebar/LeftSidebar/LeftSidebarPageMenu";
import PortfolioInfoList from "myFinancials/Portfolio/PortfolioInfoList";
import PortfolioHeader from "myFinancials/Portfolio/PortfolioHeader";

export default function PortfolioPage() {
  const [menuSel, setMenuSel] = useState("Overview");
  const [subMenuSel, setSubMenuSel] = useState("");
  function handleContentSel(n, v) {
    setMenuSel(n);  
    setSubMenuSel(v);
  }

  return (
    <>
      <div className="pageContentWrapper">
        <PortfolioHeader />
        <div className="containerLeftMenuAndContent">
          <LeftSidebarPageMenu
            name={`Information`}
            bigMenu={false}
            contentSel={subMenuSel}
            menuOptions={menuMyPortfolio}
            menuSel={menuSel}
            handleMenuSel={(v) => setMenuSel(v)}
            handleContentSel={handleContentSel}
          />

          <Paper
            elevation={1}
            className="containerMainContent"
            sx={{ position: "relative" }}
          >
            <PortfolioInfoList subMenu={subMenuSel} menu={menuSel} />
          </Paper>
        </div>
      </div>
    </>
  );
}
