/**
 * LocationUserDropdown.js
 */
import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

/**
 * 1) Get the user's current city & country via GeoNames + Geolocation
 *    - We use `findNearbyPlaceNameJSON` or `findNearbyPostalCodesJSON` for a city-level result.
 *    - This version uses `findNearbyPlaceName` with featureClass=P to improve city detection.
 */
async function detectUserCityCountry(geoNamesUsername) {
  // Get user's latitude/longitude from the browser
  const position = await new Promise((resolve, reject) => {
    if (!("geolocation" in navigator)) {
      reject("Geolocation not supported in this browser.");
    }
    navigator.geolocation.getCurrentPosition(
      (pos) => resolve(pos),
      (err) => reject(err.message)
    );
  });

  const { latitude, longitude } = position.coords;

  // Query GeoNames for the nearby city
  const url = `http://api.geonames.org/findNearbyJSON?lat=${latitude}&lng=${longitude}&featureCode=PPL&featureCode=PPLA&featureCode=PPLC&username=${geoNamesUsername}`;
  const response = await fetch(url);
  const data = await response.json();

  if (data.geonames && data.geonames.length > 0) {
    const { name: city, countryName: country } = data.geonames[0];
    return {
      latitude,
      longitude,
      city: city || "",
      country: country || "",
      error: null,
    };
  }

  // Fallback if we get no results
  return {
    latitude,
    longitude,
    city: "",
    country: "",
    error: "Unable to find nearby city using GeoNames.",
  };
}

/**
 * 2) Fetch city suggestions from GeoNames as the user types.
 *    - We use `searchJSON` with `name_startsWith` to implement an autocomplete style search.
 *    - Restrict to featureClass=P (populated places).
 *    - Order by population to get major cities first.
 */
async function fetchCitySuggestions(query, geoNamesUsername) {
  if (!query || query.length < 2) {
    // Return empty if query too short or missing
    return [];
  }
  const url = `http://api.geonames.org/searchJSON?name_startsWith=${encodeURIComponent(
    query
  )}&featureClass=P&orderby=population&maxRows=10&username=${geoNamesUsername}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.geonames) {
      // Return array of objects: { city, country }
      return data.geonames.map((item) => ({
        city: item.name,
        country: item.countryName || "",
      }));
    }
    return [];
  } catch (error) {
    console.error("Error fetching city suggestions:", error);
    return [];
  }
}

/**
 * LocationUserDropdown Component
 * 
 * Props:
 *   - cityName (string, optional): If provided, use this as initial city instead of detecting.
 *   - handleChange (function): Callback to parent. Receives an object { city, country }.
 */
export default function LocationUserDropdown({
  cityName = "",    // External city name override
  handleChange,     // Callback to parent
}) {
  const GEO_NAMES_USERNAME = "xxxx"; // Replace with your username

  // Local state for user location detection
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
    city: "",
    country: "",
    error: null,
  });

  // The currently selected city/country pair
  // We'll store it as an object { city, country } for clarity
  const [selectedCity, setSelectedCity] = useState({ city: "", country: "" });

  // The list of suggestions from GeoNames
  const [suggestions, setSuggestions] = useState([]);

  // The input display value in Autocomplete (which might be typed or from selection).
  // We’ll show "City, Country" if we have that info.
  const [inputValue, setInputValue] = useState("");

  /**
   * On mount, decide if we should detect city via geolocation
   * or use the `cityName` prop. If `cityName` is provided
   * (and not empty), skip detection.
   */
  useEffect(() => {
    (async () => {
      // If the parent provided a cityName, skip geolocation
      if (cityName) {
        setSelectedCity({ city: cityName, country: "" });
        setInputValue(cityName); // Display the city in the Autocomplete
        return;
      }

      // Otherwise, detect user location
      try {
        const loc = await detectUserCityCountry(GEO_NAMES_USERNAME);
        setUserLocation(loc);

        if (!loc.error && loc.city) {
          // Default to the found city
          handleChange?.({ city: loc.city, country: loc.country })
          setSelectedCity({ city: loc.city, country: loc.country });
          setInputValue(`${loc.city}${loc.country ? `, ${loc.country}` : ""}`);
        }
      } catch (error) {
        setUserLocation((prev) => ({
          ...prev,
          error:
            typeof error === "string" ? error : "Error detecting user location",
        }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Whenever the user types in the Autocomplete,
   * fetch suggestions from GeoNames. If fewer than 2 characters,
   * we'll just clear suggestions.
   */
  useEffect(() => {
    let active = true;

    const fetchSuggestions = async () => {
      // If the user is typing something like "City, Country", we might want
      // just the city part. A simple approach is to split by comma and take the first part.
      // But let's try the entire string for partial matches.
      const plainQuery = inputValue.split(",")[0].trim();

      if (plainQuery.length < 2) {
        setSuggestions([]);
        return;
      }

      const results = await fetchCitySuggestions(plainQuery, GEO_NAMES_USERNAME);
      if (active) {
        setSuggestions(results);
      }
    };

    if (inputValue) {
    fetchSuggestions().catch((err) => {
      console.error("Autocomplete fetch error:", err);
    });
    }
    return () => {
      active = false;
    };
  }, [inputValue, GEO_NAMES_USERNAME]);

  /**
   * Called when a new city is selected from the dropdown
   * or typed in manually (freeSolo).
   */
  const onCitySelected = (event, newValue) => {
    console.log("OnChange", newValue);
    if (typeof newValue === "string") {
      const cityFromString = newValue.trim();
      const [cityPart, countryPart] = cityFromString.split(",");
      const cityObj = {
        city: cityPart || "",
        country: (countryPart || "").trim(),
      };
      setSelectedCity(cityObj);
      setInputValue(getOptionLabel(cityObj)); // Update input to show city, country
      handleChange?.(cityObj);
    } else if (newValue && newValue.city) {
      setSelectedCity((prev)=>{return {...prev, ...newValue}});
      setInputValue(getOptionLabel(newValue)); // Update input to show city, country
      handleChange?.(newValue);
    } else {
      setSelectedCity({ city: "", country: "" });
      setInputValue(""); // Clear input if selection is cleared
      handleChange?.({ city: "", country: "" });
    }
  };

  /**
   * Called on every keystroke in the input to update `inputValue`.
   * We keep the label as "City, Country" if we have both.
   */
  const onInputChanged = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  /**
   * Convert a suggestion object into a display label: "City, Country"
   */
  const getOptionLabel = (option) => {
    if (!option) return "";
    if (typeof option === "string") return option;
    let { city, country } = option;
    if (typeof city === "object" && city !== null) {
      city = typeof city.city === "string" ? city.city : "";
    }
    return country ? `${city}, ${country}` : city;
  };
  /**
   * Ensure we match the correct suggestion object
   */


  const isOptionEqualToValue = (option, value) => {
    if (!value) return false;  // Defensive check
    if (typeof value === "string") {
      return option.city === value;
    }
    return (
      option.city === value.city &&
      (option.country || "") === (value.country || "")
    );
  };
  console.log(selectedCity);
  return (
    <div style={{ width: "100%" }}>
      {/* <h2>Location User Dropdown (MUI)</h2> */}
      {/* {userLocation.error && (
        <p style={{ color: "red" }}>Location Error: {userLocation.error}</p>
      )} */}

      {/* Show coordinates if you want */}
      {/* {userLocation.latitude && userLocation.longitude && (
        <p>
          <strong>Detected Coordinates:</strong><br />
          Lat: {userLocation.latitude}, Lng: {userLocation.longitude}
        </p>
      )} */}

      {/* Autocomplete from Material UI */}
      <Autocomplete
  sx={{ width: "100%" }}
  freeSolo
  options={suggestions}
  filterOptions={(options) => options}  
  getOptionLabel={getOptionLabel}
  isOptionEqualToValue={isOptionEqualToValue}
  value={selectedCity?.city ?  selectedCity : null} 
  onChange={onCitySelected}
  // inputValue={inputValue ? inputValue : ""}
  onInputChange={onInputChanged}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Search or Select a City"
      variant="outlined"
      placeholder="Type at least 2 letters..."
    />
  )}
/>

      {/* Show chosen city/country */}
      {/* {selectedCity.city && (
        <p style={{ marginTop: 10 }}>
          <strong>Selected City:</strong> {selectedCity.city}
          {selectedCity.country ? `, ${selectedCity.country}` : ""}
        </p>
      )} */}
    </div>
  );
}
