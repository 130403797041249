import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import useDeleteWatchlistTicker
  from 'data/user/watchlist/useDeleteWatchlistTicker';

import PriceTable from '../components/Table/PriceTable';

export default function WatchlistPriceTable() {
 
  const {stateTicker,dispatchWatchlist,stateWatchlist} = useAppInfo();
  const {watchlistData,watchlistSelected} = stateWatchlist
  const { deleteWatchlistTicker, error } = useDeleteWatchlistTicker(watchlistSelected);
 
  const [tickerInfo, setTickerInfo] = useState([]);
  
  let peerSelected = watchlistData ? watchlistData?.[watchlistSelected] : [];
  let peerInfo = stateTicker.tickerInfo;
 
  useEffect(() => {
    const uniqueTickers = new Set(); // Create a Set to store unique tickers
    const filteredPeerInfo = peerInfo.filter((peer) => {
      const ticker = peer[dfin.ticker];
      if (peerSelected.includes(ticker) && !uniqueTickers.has(ticker)) {
        uniqueTickers.add(ticker); // Add the ticker to the Set to mark it as seen
        return true; // Include the peer if it's selected and its ticker is unique
      }
      return false; // Exclude the peer if it doesn't meet the criteria
    });

    setTickerInfo(filteredPeerInfo);
  }, [peerSelected, peerInfo]);

 

  function handleDelete(value) {
    const updatedPeerList = peerSelected.filter((peer) => peer !== value);
    const wlName = watchlistSelected;
    const watchlistData = stateWatchlist?.watchlistData;
    watchlistData[wlName] = updatedPeerList;
    deleteWatchlistTicker(value);
    dispatchWatchlist({
      watchlistData: watchlistData})
  }


  return (
      <PriceTable data={tickerInfo} handleDelete={handleDelete}/>
  );
}
