import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import { assetCode, dfin } from 'content/constants'
import CircularScore from 'components/Score/CircularScore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button } from '@mui/material'
import { menuIcons } from 'nav/menuIcons'
import InvestmentPreferenceModal from 'myFinancials/InvestmentPreference/InvestmentPreferenceModal'
import usePortfolioFitStocks from './usePortfolioFitStocks'
import usePortfolioFitCrypto from './usePortfolioFitCrypto'
import usePortfolioFitETF from './usePortfolioFitETF'
import CircleScore from 'components/Charts/CircleScore';


export default function PortfolioFitTicker() {
    const {stateTicker,stateStock} = useAppInfo()
    const {tickerType} = stateTicker
    const {coInfo} = stateStock
   
  return (
    <>
    {tickerType === assetCode?.Stocks && <PortfolioFitStock coInfo={coInfo}/>}
    {tickerType === assetCode?.Crypto && <PortfolioFitCrypto/>}
    {tickerType === assetCode?.ETF && <PortfolioFitETF/>}
    </>
  )
}

export function PortfolioFitStock({coInfo,displayType="small"}) {
  const {portfolioFitScore,totalScore,completionPercentage,portfolioFitCriteria} = usePortfolioFitStocks(coInfo)
return (
  <PortfolioFitTemplate displayType={displayType} portfolioFitCriteria={portfolioFitCriteria} portfolioFitScore={portfolioFitScore}
  totalScore={totalScore} completionPercentage={completionPercentage}></PortfolioFitTemplate>
)
}

function PortfolioFitETF({displayType="small"}){
  const {portfolioFitScore,totalScore,completionPercentage,portfolioFitCriteria} = usePortfolioFitETF()
  return (
    <PortfolioFitTemplate displayType={displayType} portfolioFitCriteria={portfolioFitCriteria} portfolioFitScore={portfolioFitScore}
    totalScore={totalScore} completionPercentage={completionPercentage}></PortfolioFitTemplate>
  )
}

function PortfolioFitCrypto({displayType="small"}) {
  const {portfolioFitScore,totalScore,completionPercentage,portfolioFitCriteria} = usePortfolioFitCrypto()
return (
  <PortfolioFitTemplate displayType={displayType} portfolioFitCriteria={portfolioFitCriteria} portfolioFitScore={portfolioFitScore}
  totalScore={totalScore} completionPercentage={completionPercentage}></PortfolioFitTemplate>
)
}

function PortfolioFitTemplate({portfolioFitScore,totalScore,completionPercentage,portfolioFitCriteria,displayType="small"}){
  
  const tooltipInfo = portfolioFitCriteria && <PortfolioFitCriteria criterias={portfolioFitCriteria} completionPercentage={completionPercentage}/>

  return (
    <>
    {displayType==="small" && <CircularScore score={portfolioFitScore} 
    tooltipInfo={tooltipInfo}
    totalScore={totalScore} title='Portfolio Fit'/>}
    {displayType==="large" && 
    <CircleScore score={portfolioFitScore} 
    tooltipInfo={tooltipInfo}
    totalScore={totalScore} title='Portfolio Fit' width="100px" height='100px'/>}
    </>
  )
}

function PortfolioFitTemplateBig({portfolioFitScore,totalScore,completionPercentage,portfolioFitCriteria}){
  
  const tooltipInfo = portfolioFitCriteria && <PortfolioFitCriteria criterias={portfolioFitCriteria} completionPercentage={completionPercentage}/>

  return (
    <><CircleScore score={portfolioFitScore} 
    tooltipInfo={tooltipInfo}
    totalScore={totalScore} title='Portfolio Fit'/>
    </>
  )
}



function PortfolioFitCriteria({criterias,completionPercentage}){
  const [investmentPreference,setInvestmentPreference] = useState(false)
  return (
    <>
    <div style={{ padding: '10px',display:"flex",flexDirection:"column",gap:"0.5rem"}}>
      <strong>Portfolio Fit:  {`${Math.round(completionPercentage)}%`}</strong>
     
      {Object.entries(criterias).map(([key, value], index) => (
        <div style={{ display: "flex", gap: "0.5rem" }} key={index}>
          {value === 0 && <CheckCircleOutlineIcon fontSize="small" sx={{color:"gray"}}/>}
          {value === 1 && <CheckCircleIcon fontSize="small" sx={{ color: 'green' }} />}
          {key}
        </div>
      ))}
      <Button onClick={()=>setInvestmentPreference(true)} startIcon={menuIcons?.Filter} variant='outlined'>Investment Preference</Button>
    
    </div>
      {investmentPreference && <InvestmentPreferenceModal onClose={()=>setInvestmentPreference(false)}/>}
        </>
  )
}