import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import { Button, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SearchButton from 'components/Inputs/SearchButton'

export default function RcRecentlySearched() {
    const {stateResource,dispatchSearch} = useAppInfo()
    const {searchLearnHistory} = stateResource
    const navigate = useNavigate()
    const [recentSearchedSubTopics,setRecentSearchedTopics] = useState([])

    useEffect(()=>{
        if (searchLearnHistory.length>0){
            const extractedParams = searchLearnHistory.map(url => extractParams(url)).filter(params=>params!==null);
            setRecentSearchedTopics(extractedParams)
        }
    },[])

    function handleHeading(obj){
        const encodedTopic = encodeURIComponent(obj.Topic)
        const encodedHeading = encodeURIComponent(obj.Heading)
        const encodedSubTopic = encodeURIComponent(obj?.["Sub Topic"])
        navigate({pathname:`/Resource/Learn`,search:`?topic=${encodedTopic}&subTopic=${encodedSubTopic}&heading=${encodedHeading}`})
    }
    
  return (
    <div style={{display:"flex",width:"100%",height:"100%",flexDirection:"column"}}>
        <h3 style={{color:"black",fontWeight:"bold",display:"inline-block",padding:"0.5rem"}}>
            Recently Searched</h3>
    
        <div style={{padding:"0 0.25rem"}}>
        <SearchButton placeholder='More Information' onClick={()=>{dispatchSearch({"searchClick":true})}}/>
        </div>
        {recentSearchedSubTopics.length>0 && recentSearchedSubTopics.slice(0,20).map((obj=>
             <Button style={{justifyContent:"flex-start",textTransform:"none"}} key={obj.Heading} onClick={()=>handleHeading(obj)}>
             <Typography sx={{whiteSpace:"noWrap",
             textOverflow:"ellipsis",overflow:"hidden"}}>{obj.Topic}/{obj.Heading}</Typography>
         </Button>))}
    </div>
  )
}

const extractParams = (url) => {
    const baseUrl = 'https://example.com';
    const urlObj = new URL(url,baseUrl);
    const searchParams = new URLSearchParams(urlObj.search);
    const topic = searchParams.get('topic');
    const subTopic = searchParams.get('subTopic');
    const heading = searchParams.get('heading');
    if (heading){
    return {"Topic":topic,"Sub Topic":subTopic,"Heading":heading};}
    return null;
  };