import React from 'react';

import { useAppInfo } from 'AppState';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import NomyHighlightsCard from './components/NomyHighlightsCard';
import {
  dummyInsights,
  getClientMetricsData,
  getPortfolioMetricsData,
  getProspectMetricsData,
} from './data/dummyData';

const MetricGroup = ({ title, metrics }) => (
  <Paper 
    elevation={0} 
    sx={{ 
      p: 3,
      bgcolor: '#ffffff',
      borderRadius: '16px',
      height: '100%',
      border: '1px solid #f0f0f0',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        boxShadow: '0 8px 24px rgba(0,0,0,0.05)',
      }
    }}
  >
    <Typography 
      variant="h6" 
      sx={{ 
        fontWeight: 500,
        mb: 2,
        color: 'text.primary',
        letterSpacing: '0.3px',
        fontSize: '1rem'
      }}
    >
      {title}
    </Typography>
    <Grid container spacing={3}>
      {metrics.map((metric, index) => (
        <Grid item xs={12} sm={6} key={metric.title}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5 }}>
            <Box sx={{ color: 'primary.light', opacity: 0.9, mt: 0.5 }}>
              {metric.icon}
            </Box>
            <Box>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5, fontSize: '0.75rem' }}>
                {metric.title}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600, letterSpacing: '-0.5px', fontSize: '1.1rem' }}>
                {metric.value}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '0.7rem', mt: 0.5 }}>
                {metric.subtitle}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Paper>
);


export default function AdvisorHome() {
  const { stateApp } = useAppInfo();
  const navigate = useNavigate();
  const { clientMetrics, portfolioMetrics, aiMetrics, prospectMetrics } = dummyInsights;

  const clientMetricsData = getClientMetricsData(clientMetrics);
  const portfolioMetricsData = getPortfolioMetricsData(portfolioMetrics);
  const prospectMetricsData = getProspectMetricsData(prospectMetrics);

  return (
    <Box sx={{ 
        padding: "2rem",
        bgcolor: '#ffffff',
        minHeight: '100vh'
      }}>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 3,
          alignItems: 'stretch',
        }}>
          {/* Metrics Section */}
          <Box sx={{ 
            flex: '1 1 500px',
            minWidth: "300px",
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
            alignItems: 'stretch',
            
          }}>
            {/* Client Overview */}
            <Box 
            onClick={() => navigate('/Clients')}
            sx={{ 
              flex: '1 1 290px',
              minWidth: '290px',
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
            }}>
              <MetricGroup title="Client Overview" metrics={clientMetricsData} />
            </Box>
            
            {/* Portfolio Management */}
            <Box 
            onClick={() => navigate('/Clients')}
            sx={{ 
              flex: '1 1 290px',
              minWidth: '290px',
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
            }}>
              <MetricGroup title="Portfolio Management" metrics={portfolioMetricsData} />
            </Box>

            {/* Prospects Overview */}
            <Box 
            onClick={() => navigate('/Prospects')}
            sx={{ 
              flex: '1 1 290px',
              minWidth: '290px',
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
            }}>
              <MetricGroup title="Prospects Overview" metrics={prospectMetricsData} />
            </Box>
            
            {/* Quinn Highlights - Takes Full Width */}
            <Box sx={{ 
              flex: '1 1 100%', 
              display: 'flex',
              flexDirection: 'column',
            }}>
              <NomyHighlightsCard metrics={aiMetrics} />
            </Box>
          </Box>
        </Box>
      </Box>
  );
} 