import React, { useState, useEffect } from 'react';
import Logo from 'logo/Logo';
import useHandleSignOut from 'auth/useHandleSignout';


export default function TSMainLoading() {
  const [text, setText] = useState('Getting Things Ready...');
  const [counter, setCounter] = useState(0);
  const {handleSignOut} = useHandleSignOut()

  useEffect(() => {
    // Setting an interval that updates every half second (500 milliseconds)
    const intervalId = setInterval(() => {
      setCounter((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Check the counter to update text
    if (counter > 14) {  
      // Since counter updates every 0.5 seconds, 14 counts will be 7 seconds
      setText('This is taking too long...');
    } 
    if (counter > 30) {  // Since counter updates every 0.5 seconds, 14 counts will be 7 seconds
      setText('Please refresh the page');
      handleSignOut()
    } 
  }, [counter]);

  return (
    <div style={{
      background: "var(--bgWoodSmoke)", width: "100vw", height: "100vh",
      display: "flex", justifyContent: "center", alignItems: "center"
    }}>
      <div 
      className='fade-in'
      style={{
        display: "flex", flexDirection: "column", gap: "0.5rem", justifyContent: "center", alignItems: "center"
      }}>
                  <Logo color='white' height='72px' width='300px' type='long'/>
        {/* <div className='logoHover' style={{ display: "flex", flexDirection: "column"}}>
          <Logo color='white' height='100px' width='500px' type='long'/>
        </div> */}
        
        <h1 className="fade-in-simple" style={{ color: "white" }}>{text}</h1>
      </div>
    </div>
  );
}