import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function ContactIcon() {
  return (
    <div style={{display:"flex",gap:"2rem",marginTop:"1rem",alignItems:"center",flexWrap:"wrap"}}>
    
   
    <a
      href="mailto:info@autonomyfinance.ai?subject=Inquiry&body=Hello%20there"
      style={{
        fontSize: "2rem",
        color: "black"
      }}
    >
      <EmailIcon style={{fontSize:"2rem"}}/>
    </a>
   
    <a
      href="tel:+17782234263"
      style={{
        fontSize: "2rem",
        color: "black"
      }}
    >
      <PhoneIcon style={{fontSize:"2rem"}} />
    </a>
   
    <a
      href="https://twitter.com/autonomyfinance"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
       <XIcon style={{fontSize:"2rem"}} />
       
    </a>
    <a
      href="https://www.instagram.com/autonomyfinance/"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
      <InstagramIcon style={{fontSize:"2rem"}} />
    </a>
    <a
      href="https://www.instagram.com/autonomyfinance/"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
       <YouTubeIcon style={{color:"red",fontSize:"2.5rem"}}  />
       
    </a>
    </div>

  
  )
}
