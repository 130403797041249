import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Alert,
  IconButton
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import SelectBox from "components/Inputs/SelectBox";
import { useAppInfo } from "AppState";
import NumberInput from "components/Inputs/NumberInput";
import AISummaryBox from "chatGPT/AISummaryBox";
import CollapseToggler from "components/Buttons/CollapseToggler";
import LocationUser from "components/LocationUser";
import LocationUserDropdown from "components/LocationUserDropDown";
import { getWebSearchTavily } from "data/searchWeb/SearchTavily";

export default function HomeOwnership() {
  const { stateMyFinancials } = useAppInfo();
  const { cashflowDistribution } = stateMyFinancials;
  // Suppose these arrays are monthly net-cashflow distributions:
  // Each entry: { date, amount, [maybe other meta], ... }
  const {
    netCashflowDist,
    netCashflowBeforeAssetReturnDist,
    netCashflowAssetWidthdrawalDist,
  } = cashflowDistribution;

  const [formData, setFormData] = useState({
    fundingSource: "Net Cashflow Before Asset Return",
    buyYear: new Date().getFullYear(),
    location:undefined,
    housePrice: 1000000,
    downPaymentType: "percent", // or 'absolute'
    downPaymentPercent: 20,
    downPaymentValue: 1000000 * 0.2,

    loanTermYears: 30,
    baseInterestRate: 4.0,
    adjustedInterestRate: 6.0,
    interestRateChangeAfterYears: 5,

    propertyTaxRate: 1.0, // % year
    annualInsurance: 1500,
    pmiRate: 0.5,
    closingCosts: 5000,
  });
  

  // Mortgage results
  const [chartOptions, setChartOptions] = useState({});
  const [summary, setSummary] = useState({});

  // Full amortization schedule
  const [schedule, setSchedule] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [cityDetails, setCityDetails] = useState();

  useEffect(()=>{
    async function getMortgageDetails(){
        const details =  await getWebSearchTavily(`Morgage rates in ${formData.location?.city}`)
        console.log(details)    
        setCityDetails(details)
    }
    
    if (formData.location) {
        getMortgageDetails()
    }
  },[formData])

  // -----------------------------
  // Handle input changes
  // -----------------------------
  const handleInputChange = (field, value) => {
    setFormSubmitted(false);
    setFormData((prev) => {
      let newVal = value;
      let next = { ...prev };

      if (typeof value === "number") {
        newVal = parseFloat(value) || 0;
      }
      
     
      if (field === "housePrice") {
        next.housePrice = newVal;
        // recalc DP
        if (prev.downPaymentType === "percent") {
          next.downPaymentValue = (prev.downPaymentPercent / 100) * newVal;
        } else {
          next.downPaymentPercent = (prev.downPaymentValue / newVal) * 100;
        }
        return next;
      }

      if (field === "downPaymentType") {
        next.downPaymentType = value;
        if (value === "percent") {
          // Convert absolute -> percent
          next.downPaymentPercent =
            (prev.downPaymentValue / prev.housePrice) * 100;
        } else {
          // Convert percent -> absolute
          next.downPaymentValue =
            (prev.downPaymentPercent / 100) * prev.housePrice;
        }
        return next;
      }

      if (field === "downPaymentValue") {
        next.downPaymentValue = newVal;
        next.downPaymentPercent = (newVal / prev.housePrice) * 100 || 0;
        return next;
      }
      if (field === "downPaymentPercent") {
        next.downPaymentPercent = newVal;
        next.downPaymentValue = (newVal / 100) * prev.housePrice || 0;
        return next;
      }

      // Normal numeric
      next[field] = newVal;
      return next;
    });
  };

  // -----------------------------
  // Mortgage Calculation
  // -----------------------------
  const calculateMortgage = () => {
    const {
      housePrice,
      downPaymentType,
      downPaymentPercent,
      downPaymentValue,
      loanTermYears,
      baseInterestRate,
      adjustedInterestRate,
      interestRateChangeAfterYears,
      propertyTaxRate,
      annualInsurance,
      pmiRate,
      closingCosts,
      buyYear
    } = formData;

    // 1) Down Payment
    const actualDownPayment =
      downPaymentType === "percent"
        ? (downPaymentPercent / 100) * housePrice
        : downPaymentValue;

    // 2) Mortgage Principal
    const mortgagePrincipal = Math.max(housePrice - actualDownPayment, 0);

    // 3) Monthly taxes/insurance
    const monthlyPropertyTax = ((propertyTaxRate / 100) * housePrice) / 12;
    const monthlyInsurance = annualInsurance / 12;

    // 4) PMI
    const downPaymentRatio = actualDownPayment / housePrice;
    let monthlyPmi = 0;
    if (downPaymentRatio < 0.2) {
      monthlyPmi = ((pmiRate / 100) * mortgagePrincipal) / 12;
    }

    // 5) Amortization schedule
    const totalMonths = loanTermYears * 12;
    const changeMonth = interestRateChangeAfterYears * 12;

    // monthly mortgage payment in phase1 (for entire term), but only valid until rate changes
    const monthlyPaymentPhase1 = getMonthlyPayment(
      mortgagePrincipal,
      baseInterestRate,
      totalMonths
    );

    let localSchedule = [];
    let balance = mortgagePrincipal;

    // We'll assume the mortgage starts in January of `buyYear` (or the 1st day of buyYear's current month).
    // Or you can adapt if you want the exact month. Here we just do a rough approach:
    // let's start from the 1st of Jan of buyYear:
    const startDate = new Date(buyYear, 0, 1);

    const getDateForIndex = (mIndex) => {
      return new Date(startDate.getFullYear(), startDate.getMonth() + mIndex, 1);
    };

    // Phase 1
    for (let m = 0; m < changeMonth; m++) {
      if (balance <= 0) break;
      const monthlyRate = baseInterestRate / 100 / 12;
      const interestPaid = balance * monthlyRate;
      const principalPaid = monthlyPaymentPhase1 - interestPaid;
      balance = Math.max(balance - principalPaid, 0);

      const totalMonthlyCost =
        monthlyPaymentPhase1 + monthlyPropertyTax + monthlyInsurance + monthlyPmi;

      localSchedule.push({
        monthIndex: m,
        date: getDateForIndex(m),
        payment: monthlyPaymentPhase1,
        interestPaid,
        principalPaid,
        totalMonthlyCost,
      });
    }

    // Phase 2 (if any balance left after the interest rate changes)
    if (balance > 0 && changeMonth < totalMonths) {
      const remainingMonths = totalMonths - changeMonth;
      const monthlyPaymentPhase2 = getMonthlyPayment(
        balance,
        adjustedInterestRate,
        remainingMonths
      );
      for (let m = changeMonth; m < totalMonths; m++) {
        if (balance <= 0) break;
        const monthlyRate = adjustedInterestRate / 100 / 12;
        const interestPaid = balance * monthlyRate;
        const principalPaid = monthlyPaymentPhase2 - interestPaid;
        balance = Math.max(balance - principalPaid, 0);

        const totalMonthlyCost =
          monthlyPaymentPhase2 + monthlyPropertyTax + monthlyInsurance + monthlyPmi;

        localSchedule.push({
          monthIndex: m,
          date: getDateForIndex(m),
          payment: monthlyPaymentPhase2,
          interestPaid,
          principalPaid,
          totalMonthlyCost,
        });
      }
    }

    // Summaries
    const totalInterestSoFar = localSchedule.reduce(
      (acc, e) => acc + e.interestPaid,
      0
    );
    const totalPaidMortgage = localSchedule.reduce(
      (acc, e) => acc + e.payment,
      0
    );
    const totalPaidExtras =
      localSchedule.length * (monthlyPropertyTax + monthlyInsurance + monthlyPmi);
    const totalPaidOverLife = totalPaidMortgage + totalPaidExtras + closingCosts;

    // Insert the "down payment" expense in the first month
    // so that the monthly cost for the FIRST month includes downPayment + closingCosts
    // (assuming all at once). This helps with affordability checks in that month.
    // if (localSchedule.length > 0) {
    //   localSchedule[0].totalMonthlyCost += actualDownPayment + closingCosts;
    // }

    // Build mortgage chart
    const mortgageChartConfig = createMortgageChartOptions(localSchedule);

    setChartOptions(mortgageChartConfig);
    setSummary({
      mortgageAmount: mortgagePrincipal,
      monthlyPaymentFirstPhase: localSchedule[0]?.payment || 0,
      monthlyPaymentSecondPhase: localSchedule[changeMonth]?.payment || 0,
      totalInterestOverLife: totalInterestSoFar,
      totalPaidOverLife,
      totalClosingCosts: closingCosts,
    });
    setSchedule(localSchedule);
    setFormSubmitted(true);
  };

  // Recompute whenever formData changes
//   useEffect(() => {
//     calculateMortgage();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [formData]);

  // -----------------------------
  // Choose which distribution array to use
  // -----------------------------
  const getSelectedDistribution = () => {
    const f = formData.fundingSource;
    if (f === "Net Cashflow Before Asset Return") {
      return netCashflowBeforeAssetReturnDist;
    } else if (f === "Net Cashflow After Asset Return") {
      return netCashflowDist;
    } else {
      return netCashflowAssetWidthdrawalDist;
    }
  };

  // -----------------------------
  // Build affordability data
  // -----------------------------
  const selectedDist = getSelectedDistribution();

  // For each month in schedule, see how many scenarios can cover that month's cost
  const [affordChartOptions, setAffordChartOptions] = useState({});
  const [yearlyAffordRows, setYearlyAffordRows] = useState([]); // for the table

  useEffect(() => {
    if (!schedule || schedule.length === 0 || !selectedDist) {
      setAffordChartOptions({});
      setYearlyAffordRows([]);
      return;
    }

    // 1) Make monthly-level array with:
    //    date, totalExpense, coverageProbability, medianFunding
    //    "funding" = median of distribution amounts for that month
    //    coverageProbability = fraction of scenarios >= totalExpense
    const monthlyData = buildMonthlyAffordability(schedule, selectedDist);

    // 2) Build chart with 3 series: Funding, Expenses, Coverage Probability (secondary y-axis)
    const affChartConfig = createAffordabilityChartOptions(monthlyData);
    setAffordChartOptions(affChartConfig);

    // 3) Build a year-level rollup for the table
    const yearly = rollupYearlyAffordability(monthlyData);
    setYearlyAffordRows(yearly);
  }, [schedule, selectedDist]);

  // -----------------------------
  // UI for the new affordability table (Year-level + expand months)
  // -----------------------------
  const [expandedYear, setExpandedYear] = useState(null);

  const toggleExpandYear = (year) => {
    setExpandedYear((prev) => (prev === year ? null : year));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem 2rem" }}>
      
      {/* ----------------- */}
      {/* 1) Form + Mortgage Chart */}
      {/* ----------------- */}
      <div style={{ display: "flex", gap: "2rem" }}>
        {/* Left Column: Inputs */}
        
        <div style={{ width: "350px", display: "flex", flexDirection: "column", gap: "1rem" }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              calculateMortgage();
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              background: "#fafafa",
              padding: "1rem",
              borderRadius: "10px",
            }}
          >
            <TextField
              label="House Price ($)"
              type="number"
              variant="outlined"
              value={formData.housePrice}
              onChange={(e) => handleInputChange("housePrice", e.target.value)}
            />
            <LocationUserDropdown cityName={formData.location} handleChange={(v)=>handleInputChange("location",v)}/>
            <NumberInput
              label="Buy Year"
              type="number"
              variant="outlined"
              value={formData.buyYear}
              onChange={(v) => handleInputChange("buyYear", parseInt(v, 10))}
            />

            <SelectBox
              size="medium"
              variant="outlined"
              label={"Funding Source"}
              options={[
                "Net Cashflow Before Asset Return",
                "Net Cashflow After Asset Return",
                "Net Cashflow Asset Withdrawal",
              ]}
              value={formData.fundingSource}
              onChange={(e, v) => handleInputChange("fundingSource", v)}
            />

            <Accordion title="Down Payment">
              <TextField
                select
                label="Down Payment Type"
                variant="standard"
                SelectProps={{ native: true }}
                value={formData.downPaymentType}
                onChange={(e) => handleInputChange("downPaymentType", e.target.value)}
                fullWidth
              >
                <option value="percent">percent</option>
                <option value="absolute">absolute</option>
              </TextField>

              {formData.downPaymentType === "percent" ? (
                <TextField
                  label="Down Payment (%)"
                  type="number"
                  variant="standard"
                  value={formData.downPaymentPercent}
                  onChange={(e) =>
                    handleInputChange("downPaymentPercent", e.target.value)
                  }
                  fullWidth
                />
              ) : (
                <TextField
                  label="Down Payment ($)"
                  type="number"
                  variant="standard"
                  value={formData.downPaymentValue}
                  onChange={(e) =>
                    handleInputChange("downPaymentValue", e.target.value)
                  }
                  fullWidth
                />
              )}
            </Accordion>

            <Accordion title="Mortgage Details">
              <TextField
                label="Loan Term (Years)"
                type="number"
                variant="standard"
                value={formData.loanTermYears}
                onChange={(e) => handleInputChange("loanTermYears", e.target.value)}
                fullWidth
              />
              <TextField
                label="Base Interest Rate (%)"
                type="number"
                variant="standard"
                value={formData.baseInterestRate}
                onChange={(e) =>
                  handleInputChange("baseInterestRate", e.target.value)
                }
                fullWidth
              />
              <TextField
                label="Adjusted Interest Rate (%)"
                type="number"
                variant="standard"
                value={formData.adjustedInterestRate}
                onChange={(e) =>
                  handleInputChange("adjustedInterestRate", e.target.value)
                }
                fullWidth
              />
              <TextField
                label="Rate Changes After (Years)"
                type="number"
                variant="standard"
                value={formData.interestRateChangeAfterYears}
                onChange={(e) =>
                  handleInputChange("interestRateChangeAfterYears", e.target.value)
                }
                fullWidth
              />
            </Accordion>

            <Accordion title="Additional Costs (Estimated)">
              <TextField
                label="Property Tax Rate (%/year)"
                type="number"
                variant="standard"
                value={formData.propertyTaxRate}
                onChange={(e) => handleInputChange("propertyTaxRate", e.target.value)}
                fullWidth
              />
              <TextField
                label="Annual Insurance ($)"
                type="number"
                variant="standard"
                value={formData.annualInsurance}
                onChange={(e) => handleInputChange("annualInsurance", e.target.value)}
                fullWidth
              />
              <TextField
                label="PMI Rate (%/year)"
                type="number"
                variant="standard"
                value={formData.pmiRate}
                onChange={(e) => handleInputChange("pmiRate", e.target.value)}
                fullWidth
              />
              <TextField
                label="Closing Costs ($)"
                type="number"
                variant="standard"
                value={formData.closingCosts}
                onChange={(e) => handleInputChange("closingCosts", e.target.value)}
                fullWidth
              />
            </Accordion>

            <Button variant="contained" type="submit">
              Calculate
            </Button>
          </form>
        </div>
        <AISummary details={(formSubmitted) && {formData,"Market News":cityDetails}}/>
        </div>
    
        {/* Right Column: Mortgage Chart + Summary */}
        {formSubmitted && 
        <div style={{display: "flex", flexDirection: "column", gap: "1rem" }}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />

          <Alert severity="info">
            <div>
              <strong>Mortgage Amount:</strong> ${formatNumber(summary.mortgageAmount)}
            </div>
            <div>
              <strong>Monthly Payment (Phase 1):</strong> $
              {formatNumber(summary.monthlyPaymentFirstPhase)}
            </div>
            <div>
              <strong>Monthly Payment (Phase 2):</strong> $
              {formatNumber(summary.monthlyPaymentSecondPhase)}
            </div>
            <div>
              <strong>Total Paid Over Life:</strong> $
              {formatNumber(summary.totalPaidOverLife)}
            </div>
            <div>
              <strong>Total Interest Over Life:</strong> $
              {formatNumber(summary.totalInterestOverLife)}
            </div>
            <div>
              <strong>Closing Costs:</strong> $
              {formatNumber(summary.totalClosingCosts)}
            </div>
          </Alert>

          <Alert severity="warning">
            <strong>Note:</strong> This is a simplified scenario. Real mortgages
            can have additional complexities (rate caps, re-amortizations, etc.).
          </Alert>
       
      {/* ----------------- */}
      {/* 2) Affordability Section */}
      {/* ----------------- */}
      <div style={{ marginTop: "2rem" }}>
        <h3>Affordability Analysis</h3>

        {/* Chart: Funding, Expenses, Probability */}
        {Object.keys(affordChartOptions).length > 0 ? (
          <HighchartsReact highcharts={Highcharts} options={affordChartOptions} />
        ) : (
          <Alert severity="info">No affordability data yet.</Alert>
        )}

        {/* Yearly Table with expand to months */}
        {yearlyAffordRows.length > 0 && (
          <div style={{ marginTop: "1rem" }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "left",
              }}
            >
              <thead>
                <tr style={{ borderBottom: "1px solid #ccc" }}>
                  <th style={{ padding: "8px" }}>Year</th>
                  <th style={{ padding: "8px" }}>Funding (Sum of Medians)</th>
                  <th style={{ padding: "8px" }}>Expenses</th>
                  <th style={{ padding: "8px" }}>Prob (Avg)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {yearlyAffordRows.map((yRow) => {
                  const isExpanded = expandedYear === yRow.year;
                  return (
                    <React.Fragment key={yRow.year}>
                      {/* Year Row */}
                      <tr style={{ borderBottom: "1px solid #eee" }}>
                        <td style={{ padding: "8px" }}>{yRow.year}</td>
                        <td style={{ padding: "8px" }}>
                          ${formatNumber(yRow.sumFunding)}
                        </td>
                        <td style={{ padding: "8px" }}>
                          ${formatNumber(yRow.sumExpenses)}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {formatNumber(yRow.avgCoverage * 100)}%
                        </td>
                        <td style={{ padding: "8px" }}>
                            <CollapseToggler
                             isExpanded={isExpanded}
                              onClick={() => toggleExpandYear(yRow.year)}/>
                         
                        </td>
                      </tr>

                      {/* If expanded, show monthly details */}
                      {isExpanded && (
                        <>
                          {yRow.months.map((m) => (
                            <tr key={m.monthIndex} style={{ background: "#fafafa" }}>
                              <td style={{ padding: "8px" }}>
                                {m.date.toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                })}
                              </td>
                              <td style={{ padding: "8px" }}>
                                ${formatNumber(m.fundingMedian)}
                              </td>
                              <td style={{ padding: "8px" }}>
                                ${formatNumber(m.expenses)}
                              </td>
                              <td style={{ padding: "8px" }}>
                                {formatNumber(m.coverageProbability * 100)}%
                              </td>
                              <td></td>
                            </tr>
                          ))}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      </div>}
    </div>
  );
}

function AISummary({details}){
    const [message, setMessage] = useState()
    useEffect(()=>{
        if (details) { 
            setMessage(`Planning to buy new home. Here are some details: ${JSON.stringify(details)}`)
        } 
    },[details])
    return (
        <AISummaryBox sx={{flex:1}} message={message}>

        </AISummaryBox>
    )
}

/** Simple accordion for grouping inputs. */
function Accordion({ children, title }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div style={{ width: "100%" }}>
      <div
        onClick={() => setExpanded(!expanded)}
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
        }}
      >
        <h4 style={{ margin: 0 }}>{title}</h4>
        <KeyboardArrowDownIcon
          sx={{ marginLeft: "auto", transition: "0.3s", transform: expanded ? "rotate(180deg)" : "none" }}
        />
      </div>
      {expanded && (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop: "0.5rem" }}>
          {children}
        </div>
      )}
    </div>
  );
}

/** Mortgage Chart: principal vs interest vs total cost each month. */
function createMortgageChartOptions(schedule) {
  const principalData = [];
  const interestData = [];
  const totalData = [];

  schedule.forEach((entry) => {
    const xVal = entry.date.getTime();
    principalData.push([xVal, round2(entry.principalPaid)]);
    interestData.push([xVal, round2(entry.interestPaid)]);
    totalData.push([xVal, round2(entry.totalMonthlyCost)]);
  });

  return {
    chart: {
      type: "line",
    },
    title: {
      text: "Mortgage Payment Over Time",
    },
    xAxis: {
      type: "datetime",
      tickInterval: 365 * 24 * 3600 * 1000,
      dateTimeLabelFormats: {
        year: "%Y",
      },
      title: {
        text: "Date",
      },
    },
    yAxis: {
      title: {
        text: "Monthly Amount (USD)",
      },
    },
    series: [
      {
        name: "Principal Portion",
        data: principalData,
        color: "#5cb85c",
      },
      {
        name: "Interest Portion",
        data: interestData,
        color: "#f0ad4e",
      },
      {
        name: "Total Monthly Cost",
        data: totalData,
        color: "#337ab7",
      },
    ],
    credits: { enabled: false },
  };
}

/** Build monthly affordability data:
 * For each month in `schedule`, find all scenario amounts from `distribution` that match the year/month.
 * Then:
 *   fundingMedian = median of those amounts
 *   coverageProbability = fraction of amounts >= that month's total expense
 */
function buildMonthlyAffordability(schedule, distribution) {
  // 1) group the distribution by year-month
  const distMap = {}; // key = "YYYY-MM", value = array of amounts
  distribution.forEach((d) => {
    const dt = new Date(d.date);
    const y = dt.getFullYear();
    const m = dt.getMonth(); // 0-based
    const key = `${y}-${m}`;
    if (!distMap[key]) distMap[key] = [];
    distMap[key].push(d.amount);
  });

  // 2) For each schedule item, compute coverage
  const monthlyData = schedule.map((s) => {
    const y = s.date.getFullYear();
    const m = s.date.getMonth();
    const key = `${y}-${m}`;
    const amounts = distMap[key] || [];
    if (amounts.length === 0) {
      return {
        date: s.date,
        monthIndex: s.monthIndex,
        expenses: s.totalMonthlyCost,
        fundingMedian: 0,
        coverageProbability: 0,
      };
    }
    const med = median(amounts);
    // coverage probability = fraction of amounts >= expenses
    const coverCount = amounts.filter((amt) => amt >= s.totalMonthlyCost).length;
    const coverageProb = coverCount / amounts.length;

    return {
      date: s.date,
      monthIndex: s.monthIndex,
      expenses: s.totalMonthlyCost,
      fundingMedian: med,
      coverageProbability: coverageProb,
    };
  });

  return monthlyData;
}

/** Affordability chart: 3 series:
 *  Funding (median) vs Expenses vs Coverage Probability (secondary axis from 0..1).
 */
function createAffordabilityChartOptions(monthlyData) {
  if (!monthlyData || monthlyData.length === 0) return {};

  const fundingSeries = [];
  const expenseSeries = [];
  const coverageSeries = [];

  monthlyData.forEach((row) => {
    const xVal = row.date.getTime();
    fundingSeries.push([xVal, round2(row.fundingMedian)]);
    expenseSeries.push([xVal, round2(row.expenses)]);
    coverageSeries.push([xVal, round2(row.coverageProbability)]);
  });

  return {
    chart: {
      zoomType: "x",
      type: "line",
    },
    title: {
      text: "Affordability: Funding vs. Expenses vs. Coverage",
    },
    xAxis: {
      type: "datetime",
      tickInterval: 365 * 24 * 3600 * 1000,
      dateTimeLabelFormats: {
        year: "%Y",
      },
      title: {
        text: "Date",
      },
    },
    yAxis: [
      {
        title: { text: "Amount (USD)" },
        opposite: false,
      },
      {
        title: { text: "Coverage Probability" },
        max: 1,
        min: 0,
        opposite: true,
        labels: {
          formatter: function () {
            return this.value.toFixed(1);
          },
        },
      },
    ],
    series: [
      {
        name: "Funding (Median)",
        data: fundingSeries,
        color: "#2c974b",
        yAxis: 0,
      },
      {
        name: "Expenses",
        data: expenseSeries,
        color: "#ff7f0e",
        yAxis: 0,
      },
      {
        name: "Coverage Probability",
        data: coverageSeries,
        color: "#1f77b4",
        yAxis: 1,
      },
    ],
    credits: { enabled: false },
  };
}

/** Roll up monthly data into year-level rows for the table.
 * Summaries:
 *   sumFunding = sum of monthly fundingMedian
 *   sumExpenses = sum of monthly expenses
 *   avgCoverage = average coverageProbability
 */
function rollupYearlyAffordability(monthlyData) {
  // group by year
  const yearMap = {}; // { year: [ { ...monthlyRow }, ... ] }
  monthlyData.forEach((m) => {
    const y = m.date.getFullYear();
    if (!yearMap[y]) yearMap[y] = [];
    yearMap[y].push(m);
  });

  const results = Object.keys(yearMap)
    .map((yearStr) => {
      const year = parseInt(yearStr, 10);
      const months = yearMap[year] || [];
      const sumFunding = months.reduce((acc, r) => acc + r.fundingMedian, 0);
      const sumExpenses = months.reduce((acc, r) => acc + r.expenses, 0);
      const avgCoverage =
        months.reduce((acc, r) => acc + r.coverageProbability, 0) /
        (months.length || 1);
      return {
        year,
        sumFunding,
        sumExpenses,
        avgCoverage,
        months, // keep monthly detail
      };
    })
    .sort((a, b) => a.year - b.year);

  return results;
}

// --------------------------------
// Helper Functions
// --------------------------------
function formatNumber(num) {
  if (isNaN(num)) return 0;
  return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
}

function round2(n) {
  return parseFloat(n.toFixed(2));
}

/** Standard Mortgage formula for monthly payment */
function getMonthlyPayment(principal, annualInterestRate, totalMonths) {
  if (annualInterestRate <= 0) {
    return principal / totalMonths;
  }
  const monthlyRate = annualInterestRate / 100 / 12;
  return (
    principal *
    (monthlyRate * Math.pow(1 + monthlyRate, totalMonths)) /
    (Math.pow(1 + monthlyRate, totalMonths) - 1)
  );
}

/** Median of array */
function median(arr) {
  if (!arr || arr.length === 0) return 0;
  const sorted = [...arr].sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  if (sorted.length % 2 === 0) {
    return (sorted[mid - 1] + sorted[mid]) / 2;
  }
  return sorted[mid];
}
