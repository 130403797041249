import { useAppInfo } from 'AppState';
import { sendEmailForUser } from 'automate/sendEmail';
import { clientsData } from 'b2b/Clients/clientsData';
import { dummyInsights } from 'b2b/data/dummyData';
import { prospectData } from 'b2b/Prospects/prospectData';
import { pulseData } from 'b2b/Pulse/pulseData';

export default function useAssistantFinancialAdvisorAssistant() {
    const { stateApp } = useAppInfo();
    const { userDetails } = stateApp;
    const userPhoto = userDetails?.photoURL;
    const userEmail = userDetails?.email;

    function GetClientDetails({clientNames}){
        console.log(clientNames)
        const clientDetails = clientNames.length > 0 || (clientNames.length === 0 && clientNames[0] ==="")? 
        clientsData.filter(obj=>clientNames.includes(obj.name)) : clientsData
        const marketnewsData = pulseData.filter(obj=>obj.affectedClients.includes(clientNames))
        return {clientDetails,marketnewsData}
    }

    function GetClientFiltered(){
        return clientsData
    }

    function GetAdvisorBookOverview(){
        const advisorBookOverview = dummyInsights
        return {advisorBookOverview,clientsData}
    }

    function GetProspectClientDetails(){
        const prospectOverview = dummyInsights?.prospectMetrics
        const prospectDetails = prospectData
        return {prospectDetails}
    }

    function GetClientTodos(){
        const clientTodos = dummyInsights?.aiMetrics?.todos
        return clientTodos
    }

    async function SendEmailForUser({recipientEmail, subject, body}){
        try {
            const info = await sendEmailForUser(userEmail, recipientEmail, subject, body,userPhoto);
            // console.log("Email sent successfully to",info);
            return `Email sent successfully to ${recipientEmail}`;
        } catch (error) {
            console.error('Error sending email:', error);
            return "Failed to send email";
        }
    }

    async function GetEmailAddress({recipientName}){
        const emailAddresses = [
        {name:"Milind",email:"milind@autonomyfinance.ai"},
        {name:"Yash",email:"yash1993shah@gmail.com"},
        {name:"Hrishi",email:"hrishi@autonomyfinance.ai"},
        {name:"Matt",email:"matt@autonomyfinance.ai"},
        {name:"Seb",email:"sebastian@autonomyfinance.ai"},
        ]
        // const emailAddress = emailAddresses.find(obj=>obj.name === recipientName)?.email
        return emailAddresses
    }

    

    return {GetAdvisorBookOverview,
        GetClientDetails,GetProspectClientDetails,GetClientTodos,GetClientFiltered,SendEmailForUser}
}
