import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import LogoLoader from 'logo/LogoLoader';
import ReactMarkdown from 'react-markdown';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import useAssistantSummarize from './useAssistantSummarize';

export default function AISummaryBox({children,sx={},message,id,loadingMessage="Analyzing...",usePromptStandard=true} ) {
  const [aiMessage,setAIMessage] = useState()
  const {stateMyFinancials,statePortfolio,stateApp,stateAssistant,dispatchAssistant} = useAppInfo()
  const {username} = stateApp
  const {aiSummaryStore} = stateAssistant
  const {portfolioLoaded,portfolio,portfolioResults} = statePortfolio
  const {financialsUpdated,currency,summaryStats,assets,liabilities,incomeMonthly,expenseMonthly,financialHealthIssues,
    financialWellnessScore,goalsPeriodic,financialView,financialsLoaded,demoProfile,financials} = stateMyFinancials
  
  const aiSummaryId = financialView === "personal" ? `${id}_${username}` : `${id}_demo_${demoProfile}`
  const aiSummary = useAssistantSummarize({message:aiMessage});
  const {loading,answer } = aiSummary;
  const [answerSaved,setAnswerSaved] = useState()

  useEffect(()=>{
    if (id){
      let aiSummaryStoreSave = aiSummaryStore?.filter(obj=>obj.aiSummaryId === aiSummaryId)
      let answerSave =  aiSummaryStoreSave[0]?.answer
      setAnswerSaved(answerSave)
    }
    else {
      setAnswerSaved(false)
    }
  },[id])
 
  useEffect(()=>{
    let portfolioCheck = false
    if (portfolioLoaded && portfolio && typeof portfolio === "object" && Object.keys(portfolio).length > 0 && portfolioResults){
      portfolioCheck = true
    }
    if (
      portfolioLoaded && portfolio && typeof portfolio === "object" && Object.keys(portfolio).length === 0
    ){
      portfolioCheck = true}
    
      let user = financialView === "personal" ? username : demoProfile
      let userMessage = user ? `You are addressing to ${user}` : `You are addressing to a demo profile ${demoProfile} for ${username} account on Autonomy Finance.`

    if (!usePromptStandard && message){
      setAIMessage(`${userMessage}. ${message}`)
    }
    
    
    if (usePromptStandard &&financialsLoaded && message && !answerSaved && financialsUpdated && portfolioCheck){
      const portfolioResultAll = portfolioResults?.portfolioOverviewAll
      let financialSituation = {currency,summaryStats,assets,liabilities,incomeMonthly,expenseMonthly,financialHealthIssues,
            financialWellnessScore,goalsPeriodic,}
      if (portfolioResultAll){
        financialSituation = {...financialSituation,...portfolioResultAll}
      }

      setAIMessage(`${userMessage}. Adress the user personally and not as a 3rd person. Give me insights from this in 100 words max in an engaging user-friendly way and highlight important points: ${message}. 
        Consider the financial situation: ${JSON.stringify(financialSituation)}`)
    }
  },[financialsLoaded,financialsUpdated,answerSaved,message
    ,financialView,portfolioLoaded,portfolio,portfolioResults,demoProfile])

  useEffect(()=>{
    if (id && aiSummary && !loading){
      dispatchAssistant({aiSummaryStore:{aiSummaryId,answer}})
    }
   
  },[aiSummary,id])

  let answerRender = answerSaved || answer

  return (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem"
    ,background:"#e1f6df",padding:"1rem",borderRadius:"5px",width:"100%"
    ,boxSizing:"border-box",height:"max-content",...sx}}>
<div style={{display:"flex",alignItems:"center",gap:"1rem"}}>
        <AutoAwesomeIcon/>
        AI Insight
        </div>
        {!message && <h4>Insights available after you enter details</h4>}
    <div>
    {children}
    {message && <>
    {(!answerRender && loading) && <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
      <div style={{display:"flex",gap:"0.25rem",alignItems:"center"}}>
        <LogoLoader width={"20px"} height={"20px"}/> <h4 style={{fontWeight:"bold"}}>Quinn 
        </h4></div>
        <h4 className="fade-in-simple">{loadingMessage}</h4>
        </div>}
    {answerRender && <ReactMarkdown>{answerRender}</ReactMarkdown>}
    </>}
    </div>
      

    </div>
  )
}
