import React from 'react';

import TimeSeriesChart from 'components/Charts/TimeSeriesChart';
import LoadingSkeletonLine from 'components/Loading/LoadingSkeletonLine';
import TableGen from 'components/Table/TableGen';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import usePriceChartEod from 'data/eod/usePriceChartEod';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import {
  cols,
  colsPrimary,
} from './cryptoKeys';

export default function CryptoProfile() {
  const { stateScreener, dispatchScreener, stateTicker } = useAppInfo();
  const { tickerCrypto, tickerCryptoCode } = stateTicker;
  const { priceInfo, loadingPriceInfo } = usePriceChartEod({
    tickerSel: tickerCryptoCode,
  });
  const crypto = stateScreener.crypto.filter(
    (obj) => obj[dfin.ticker] === tickerCrypto
  );

  const primaryInfo =
  crypto &&
  getSpecificArrayObjKeys(crypto, colsPrimary).flatMap((obj) => {
    return Object.entries(obj).map(([key, value]) => {
      const table = {};
      table["Metric"] = key;
      table["Value"] = value;
      return table;
    });
  });

  const tableData =
    crypto &&
    getSpecificArrayObjKeys(crypto, cols).flatMap((obj) => {
      return Object.entries(obj).map(([key, value]) => {
        const table = {};
        table["Metric"] = key;
        table["Value"] = value;
        return table;
      });
    });
  
  
  return (
    <div style={{width:"100%",height:"100%",overflow:"auto"}}>
    <div style={{ width: "100%", height: "100%"
    ,display:"flex",gap:"0.25rem"}}>
   
    <div
          style={{
            border: "2px solid #F8F8F8",
            borderRadius: "5px",
            padding: "0.25rem",
            width: "50%",
            minWidth:"300px",
            boxSizing: "border-box",
          }}
        >
          <div style={{width:"100%",flex:1,height:"100%"}}>
          <div style={{display:"flex",flexDirection:"column",width:"100%",minHeight:"350px"
          ,height:"40%",border:"1px solid #F8F8F8",padding:"0.2rem",boxSizing:"border-box",background:"#F8F8F8",borderRadius:"5px"}}>
          <div style={{flex:1,width:"100%"}}>
          {!priceInfo.length > 0 && loadingPriceInfo && <LoadingSkeletonLine length={1} width="100%" height="100%"/>}
          {priceInfo.length > 0 && !loadingPriceInfo && (
              <TimeSeriesChart
              gradient={true}
              grid={false}
              chartType="area"
                datePicker={false}
                seriesName="adjusted_close"
                data={getSpecificArrayObjKeys(priceInfo, [
                  "date",
                  "adjusted_close",
                ])}
              />
            )}
          </div>
          {/* <div style={{float:"right",marginLeft:"auto"}}><Button onClick={handleChart}><FullscreenIcon/></Button></div> */}
          </div>
    
    <div style={{flex:1}}>
   
    <TableGen tableLayout='auto' data={tableData}/>
    </div>
          </div>
          
          </div>
          <div style={{width:"50%"}}>
    <div style={{flex:1}}>
   
   <TableGen tableLayout='auto' data={primaryInfo} tHead={false} pagination={false}/>
   </div>
    
    <div style={{border:"3px solid #F8F8F8"
     ,borderRadius:"5px",width:"100%",boxSizing:"border-box",flexWrap:"wrap",padding:"0.3rem"}}>
     <h3 style={{color:"gray",fontWeight:"bold"}}>Description</h3>
     
     <p style={{width:"100%"}}>{crypto[0]?.["DESCRIPTION"]}</p>
     </div>
     </div>
   
  </div>
  </div>
  );
}
