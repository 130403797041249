import { useEffect} from 'react';
import { db} from "../firebaseConfig/firebaseConfig";
import { doc, getDoc, setDoc, updateDoc, serverTimestamp, arrayUnion, Timestamp, increment} from 'firebase/firestore';
import { v4 as uuid } from 'uuid';

export default function useOnboardingMessage({stateApp}) {
    const { userDetails } = stateApp;

    const user = {
        displayName: "YashTS",
        email: "yash@takestock.ai",
        photoURL: 'https://lh3.googleusercontent.com/a-/ALV-UjXZ2qcljg0M1xV5Hr4N5G77pCpPWjr1XQo-f2wdLbAnlLCMuT2aCoc_SWgVjXd79QYUSVWlxg4k3FVONNkloobuyse8DLyMx5RGVDqwLwIA1vSuIBWSdChQdP7q80R4-wPpe9xVOEbKNOEAMU0NTQ-iiuQ2fhtqXscuRqswOUI5xjpjIECWeESo45jtnMcXEwm4kGYhgQMWlMGAwXM8kLiTFooWErEeMwZhFghm8SveWh69LsYB0fFggDelk_g8vc40rTUeje6GKwiDZgZv3Md4usMB544WNpmFwvl4ydAFdselIjEsHNH2QPvQhwpuiuGL8tAE8zluL401nkhtXMi2yH8mK6sG_7Ch3sLP0yWSbiO7-abP1oCcYsnJWbQc32RNFQg7zQZ7iIfYP5ssMp49nCklgnuH5j-VCInVRvcki-D1j6mhdxa4dHINuAwMjd8N9FRlF4NQzHalVWEnyUj3cYNmz60CnY6V0minw_cVhsVBlnjBy9MlQP1RnpUWFNi2_3TpghwxbtAcZGVeYfei4BUbRwVw_185wKgFT7vu-8gtjfnwhx-AzLSeYNdpKkdYXbYldMQkX8XvwirtRXIb9lWdyDUrI2wJzfgyEVHBBZ2ydOsiwCaXhS1QTvdJcxy--yk7Ms4dq8DrfXxxn9vW0F-uhA515fB_3Pn1FZFwd2n0kdo9zkf4q4e3RKPw1clWSZVLWr0gtszMxJsWDBxDa1UbFYH6YZCA9uIZMxB5Os02EOeAMXpZadAR0AiVcoL2s1z3zExVJAQE5LsWGgsFX8NZFmHYV8ZsIf-Q3_-UR8Lo9AwUmZ5OmGMLgrxmOmHBtIHj98E1_SuYxs47zfx_UjNWJrZ_Gv31LATVhHt2qa4rX7gneYOhEyRI8BIYwvc5kJNxwk04A2iJ9OtzNas9X8lDrkb8rojl6IZhsh8-D_517di25Ccxh_1y7uE1gVTE27VHA1TztzbK_d7bn2_F=s96-c',
        uid: "t04Xg0Tf9TfL7Ajg7FeaaEjGH5l2"
    };

    const handleSend = async (combinedId) => {
        try{
        const res = await getDoc(doc(db, "chats", combinedId));

        if (!res.exists()) {
        const messageId = uuid();
        const messageObject = {
            id: messageId,
            text: `Hey ${userDetails.displayName}, welcome to Autonomy! I’m Yash, the founder. Got questions or need help? Just shoot a message our way!`,
            senderId: user.uid,
            date: Timestamp.now(),
            seen: false
        };
        
        await setDoc(doc(db, "chats", combinedId), { messages: arrayUnion(messageObject) });
        
               // Increment unread messages count for recipient
      const recipientUnreadPath = `${combinedId}.unreadCount`;
      const senderLastMessagePath = `${combinedId}.lastMessage`;
      // await updateDoc(doc(db, "userChats", user.uid), {
      //   [recipientUnreadPath]: increment(1),
      // });

      // Update last message for both sender and recipient
      const userChatUpdates = { 
        [combinedId + ".userInfo"]: {
            uid: userDetails.uid,
            displayName: userDetails.displayName,
            photoURL: userDetails.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
          [senderLastMessagePath]: messageObject,
        // [combinedId + ".date"]: serverTimestamp(),
      };
      const chatUpdateReceiver = {
        [combinedId + ".userInfo"]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
          },
        [combinedId + ".date"]: serverTimestamp(),
        [senderLastMessagePath]: messageObject,
        [recipientUnreadPath]: increment(1)
      }
      await Promise.all([
        updateDoc(doc(db, "userChats", userDetails.uid), chatUpdateReceiver),
        updateDoc(doc(db, "userChats", user.uid), userChatUpdates)
      ]);

        }
    }
        catch (error) {
            console.error("Failed to setup chat or send message:", error);
        }
    };


    useEffect(() => {
       
        if (userDetails?.uid &&
            userDetails?.uid !== user.uid) {
                const combinedId = userDetails.uid > user.uid ? userDetails.uid + user.uid : user.uid + userDetails.uid;
               
            setTimeout(()=>handleSend(combinedId),1000*5);
        }
    }, [userDetails]);

}









