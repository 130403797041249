import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindDefaultFinancials = () => {
  const { dispatchMyFinancials, stateApp,stateMyFinancials} = useAppInfo()
  const {demoProfile,financialView} = stateMyFinancials
  const userId = stateApp.userId
  const [data,setData] = useState()

  const findDefaultFinancials = async (demoName) => {
    dispatchMyFinancials({'financialsLoaded':false });

    try {
      if (userId) {
        const response = await axios.get(`${BASE_URL}/findDefaultFinancials/`, 
            {params: {
            name:demoName
          }});
        const data = response.data;
        setData(data)
        dispatchMyFinancials({'financialsLoaded':true});
        // Object.entries(data).map(([name,value])=>{
        //     {!["_id","userId","username"].includes(name) 
        //         && dispatchMyFinancials({[name]:value });
        //     }
        // })
        // dispatchMyFinancials({'financials':data,'financialsLoaded':true});
      }
    } catch (err) {
      console.log(err);
      dispatchMyFinancials({'financialsLoaded':true});
    }
  };

  useEffect(() => {
    if (userId && demoProfile && financialView !== "personal") {
      const demoName = demoProfile ? demoProfile : "Ambitious Alex"
      
      findDefaultFinancials(demoName);
    }
  }, [userId,demoProfile,financialView]);

  return {data};
};

export default useFindDefaultFinancials;