import React from 'react';

import { useAppInfo } from 'AppState';
import ProfileLogo from 'components/Logo/ProfileLogo';

import { DemoProfile } from '../Demo/DemoAccounts';
import PortfolioAccountSelection from '../Portfolio/PortfolioAccountSelection';

export default function ReportMoreHeaderDetails({reportType="Portfolio"}) {
    const {stateApp,stateMyFinancials} = useAppInfo()
    const {financialView,demoProfile} = stateMyFinancials
    const profileLogo = financialView === "personal" ? <ProfileLogo/> : <DemoProfile demoProfile={demoProfile}/>
    const headerDetails = {
        "Portfolio":<div style={{display:"flex",gap:"0.5rem",alignItems:"center",flexWrap:"wrap"}}>{profileLogo} 
        Portfolio <PortfolioAccountSelection/></div>,
       "My Financials":<>{profileLogo} Financials Overview</>
    }
  return (
    <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
        {headerDetails[reportType]}
    </div>
  )
}

