import { useAppInfo } from 'AppState';
import { formatNumber } from 'utils/utilGeneral';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SavingsIcon from '@mui/icons-material/Savings';
import { Typography } from '@mui/material';

import TemplateAction from './TemplateAction';
import { calculateTotal } from './utils';

export function LifeInsuranceCoverage() {
    const { stateMyFinancials } = useAppInfo();
    const { summaryStats, assets, currency } = stateMyFinancials;
    const { totalIncome, totalNetCashflow } = summaryStats;

    const yearIncome = totalIncome?.y || totalNetCashflow?.m > 0 && totalNetCashflow?.m * 12;
    const lifeInsuranceAmount = yearIncome ? yearIncome * 10 : 0;
    const lifeInsuranceCurrent = calculateTotal(assets, ["Life Insurance"]);
    const remainingCoverage = lifeInsuranceAmount - lifeInsuranceCurrent;

    // Average premiums for life insurance (using 0.2% for term and 2% for whole life annually)
    const avgTermPremium = remainingCoverage > 0 ? (remainingCoverage * 0.002) / 12 : 0; // Term: 0.2% annually
    const avgWholePremium = remainingCoverage > 0 ? (remainingCoverage * 0.02) / 12 : 0;  // Whole: 2% annually

    const hasEnoughCashForTerm = totalNetCashflow?.m >= avgTermPremium;
    const hasEnoughCashForWhole = totalNetCashflow?.m >= avgWholePremium;

    const noIncomeOrCashflow = !yearIncome || !totalNetCashflow?.m;

    return (
        <TemplateAction
            professional={yearIncome ? "InsuranceAdvisor" :"FinancialAdvisor"}
            actions={!yearIncome && [{label:"Ways to Build Periodic Income"}]}
            actionHeading={yearIncome ? "Here are some ways to secure your life insurance coverage:" : "Here are some ways to help you start building periodic Income:"}
            financialProduct={yearIncome && "LifeInsurance"}
        >
            <Typography variant="body1" gutterBottom>
                {noIncomeOrCashflow ? (
                    <>
                         You currently don’t have a steady income or sufficient cash flow to afford life insurance premiums. It’s important to take steps now to protect your family’s financial future, even if you can’t secure a full policy immediately.
                        <br />
                    </>
                ) : (
                    <>
                        {lifeInsuranceCurrent === 0 && (
                            <>
                                You currently don’t have any life insurance coverage. To secure your family’s financial future, you should have coverage of at least <strong>{`${currency?.symbol}${formatNumber(lifeInsuranceAmount)}`}</strong>, based on 10 times your annual income of <strong>{`${currency?.symbol}${formatNumber(yearIncome)}`}</strong>.
                                <br />
                                <br />
                                {hasEnoughCashForTerm ? (
                                    <>
                                        You can afford the monthly premium for term life insurance, which is approximately <strong>{`${currency?.symbol}${formatNumber(avgTermPremium)}`}</strong>. Consider prioritizing term life insurance as it is more cost-effective.
                                    </>
                                ) : (
                                    <>
                                        Your current net cash flow of <strong>{`${currency?.symbol}${formatNumber(totalNetCashflow?.m)}`}</strong> may not be sufficient to cover the term life insurance premium of <strong>{`${currency?.symbol}${formatNumber(avgTermPremium)}`}</strong>. Budgeting may help you allocate funds for this essential coverage.
                                    </>
                                )}
                                <br />
                                <br />
                                {hasEnoughCashForWhole ? (
                                    <>
                                        You can afford the monthly premium for whole life insurance, which is approximately <strong>{`${currency?.symbol}${formatNumber(avgWholePremium)}`}</strong>. Consider this if you’re looking for lifetime coverage and a savings component.
                                    </>
                                ) : (
                                    <>
                                        Whole life insurance has a higher monthly premium of <strong>{`${currency?.symbol}${formatNumber(avgWholePremium)}`}</strong>. With your current net cash flow of <strong>{`${currency?.symbol}${formatNumber(totalNetCashflow?.m)}`}</strong>, it may be challenging to afford. Consider term life insurance as a more budget-friendly alternative.
                                    </>
                                )}
                            </>
                        )}
                        {lifeInsuranceCurrent > 0 && lifeInsuranceCurrent < lifeInsuranceAmount && (
                            <>
                                Your current life insurance coverage is insufficient. To meet the recommended level, you need an additional <strong>{`${currency?.symbol}${formatNumber(remainingCoverage)}`}</strong> in coverage.
                                <br />
                                <br />
                                {hasEnoughCashForTerm ? (
                                    <>
                                        You can afford the additional term life insurance premium of <strong>{`${currency?.symbol}${formatNumber(avgTermPremium)}`}</strong>. Upgrading your coverage will help secure your family’s financial future.
                                    </>
                                ) : (
                                    <>
                                        Your current net cash flow of <strong>{`${currency?.symbol}${formatNumber(totalNetCashflow?.m)}`}</strong> may not be sufficient to cover the additional term life insurance premium of <strong>{`${currency?.symbol}${formatNumber(avgTermPremium)}`}</strong>. Adjust your budget to prioritize this crucial protection.
                                    </>
                                )}
                                <br />
                                <br />
                                {hasEnoughCashForWhole ? (
                                    <>
                                        You can afford the additional whole life insurance premium of <strong>{`${currency?.symbol}${formatNumber(avgWholePremium)}`}</strong>. This option provides lifetime coverage with a savings component.
                                    </>
                                ) : (
                                    <>
                                        Whole life insurance has a higher additional premium of <strong>{`${currency?.symbol}${formatNumber(avgWholePremium)}`}</strong>. Your current net cash flow of <strong>{`${currency?.symbol}${formatNumber(totalNetCashflow?.m)}`}</strong> may not be sufficient. Consider term life insurance for a more affordable solution.
                                    </>
                                )}
                            </>
                        )}
                        {lifeInsuranceCurrent >= lifeInsuranceAmount && (
                            <>
                                You have adequate life insurance coverage of <strong>{`${currency?.symbol}${formatNumber(lifeInsuranceCurrent)}`}</strong>. Well done on securing your family’s financial future!
                            </>
                        )}
                    </>
                )}
            </Typography>
        </TemplateAction>
    );
}




// 2. DIME Method
// The DIME method is a more detailed approach that evaluates your financial obligations and goals:

// D: Debt
// Cover all outstanding debts (e.g., mortgage, car loans, credit cards).
// Example: Mortgage = $200,000, Other Debt = $20,000.
// I: Income Replacement
// Multiply your annual income by the number of years your family would need support.
// Example: Income = $50,000, Support = 10 years → $500,000.
// M: Mortgage
// Ensure the policy covers the remaining mortgage balance.
// Example: Remaining Mortgage = $200,000.
// E: Education
// Include costs for your children's education.
// Example: 2 kids, college cost = $50,000 each → $100,000.
// Total Coverage = Debt + Income Replacement + Mortgage + Education

// Example:

// Debt: $20,000
// Income Replacement: $500,000
// Mortgage: $200,000
// Education: $100,000




export function BuildEmergencyFund() {
    const { stateMyFinancials } = useAppInfo();
    const { summaryStats, assets, currency } = stateMyFinancials;
    const { totalExpense, totalIncome } = summaryStats;

    const annualExpense = totalExpense?.m * 12; // Monthly expense converted to annual
    const emergencyFundTarget = (annualExpense * 9) / 12; // 9 months of expenses
    const liquidAssets = calculateTotal(assets, ["Cash", "Savings", "Investment - Liquid"]);
    const shortfall = emergencyFundTarget - liquidAssets;

    const monthlyIncome = totalIncome?.m;
    const annualIncome = totalIncome?.y;

    const hasSufficientIncome = monthlyIncome && monthlyIncome > 0;

    return (
        <TemplateAction 
        actionHeading={"Here are some ways to build your emergency fund:"}
        actions={[{label:"Open emergency savings account",icon:<SavingsIcon/>},
        {label:" Automate monthly contributions",icon:<AccountBalanceWalletIcon/>},
        ]}
        >
            <Typography variant="body1" gutterBottom>
                Your emergency fund should cover 6-9 months of expenses. Based on your projected annual expenses of <strong>{`${currency?.symbol}${formatNumber(annualExpense)}`}</strong>, you should aim for an emergency fund of <strong>{`${currency?.symbol}${formatNumber(emergencyFundTarget)}`}</strong>.
            </Typography>

            {liquidAssets >= emergencyFundTarget ? (
                <Typography variant="body1" gutterBottom>
                    You have sufficient liquid assets of <strong>{`${currency?.symbol}${formatNumber(liquidAssets)}`}</strong>. Well done on building a robust emergency fund!
                </Typography>
            ) : (
                <>
                    <Typography variant="body1" gutterBottom>
                        Your current liquid assets (Cash, Savings, Liquid Investments) are <strong>{`${currency?.symbol}${formatNumber(liquidAssets)}`}</strong>, which is <strong>{`${currency?.symbol}${formatNumber(shortfall)}`}</strong> short of your target emergency fund.
                    </Typography>

                    {hasSufficientIncome ? (
                        <Typography variant="body1" gutterBottom>
                            With your current monthly income of <strong>{`${currency?.symbol}${formatNumber(monthlyIncome)}`}</strong>, you can set aside a portion each month to close this gap.
                        </Typography>
                    ) : (
                        <Typography variant="body1" gutterBottom>
                            Without sufficient income, you may need to adjust your budget or explore additional income opportunities to meet your emergency fund target. 
                        </Typography>
                    )}
                </>
            )}
        </TemplateAction>
    );
}
