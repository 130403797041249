import React, { useState, useEffect } from "react";
import { useAppInfo } from "AppState";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import TextInput from "components/Inputs/TextInput";
import SelectBox from "components/Inputs/SelectBox";
import { formatNumber } from "utils/utilGeneral";
import AISummaryBox from "chatGPT/AISummaryBox";
import { Button } from "@mui/material";


export default function RetireWithXAmount() {
  const { stateMyFinancials } = useAppInfo();

  const { cashflowDistribution } = stateMyFinancials;
  const {
    netCashflowDist,
    netCashflowBeforeAssetReturnDist,
    netCashflowAssetWidthdrawalDist,
  } = cashflowDistribution;

//   console.log(netCashflowDist);
  const [formData, setFormData] = useState({
    retireAmount: 1000000,
    retirePeriod: 20,
    funding: "Net Cashflow Before Asset Return",
  });

  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [finalProbability, setFinalProbability] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const calculateRetirementData = () => {
    let accumulatedAmount = 0;
  
    // Determine the funding distribution based on the selected option
    const funding =
      {
        "Net Cashflow Before Asset Return": netCashflowBeforeAssetReturnDist,
        "Net Cashflow After Asset Return": netCashflowDist,
        "Net Cashflow Asset Withdrawal": netCashflowAssetWidthdrawalDist,
      }[formData.funding] || [];
  
    // Group funding entries by year and calculate summed amounts
    const grouped = funding.reduce((acc, { date, amount }) => {
      const year = new Date(date).getFullYear();
      acc[year] = (acc[year] || 0) + amount;
      return acc;
    }, {});
  
    // Extract and sort years
    const years = Object.keys(grouped)
      .map(Number)
      .sort((a, b) => a - b);
  
    // Create result and table data
    const { result, table } = years.reduce(
      ({ result, table }, year) => {
        accumulatedAmount += grouped[year];
        const probability = Math.max(
          Math.min((accumulatedAmount / formData.retireAmount) * 100, 100),
          0
        );
  
        result.push({ year, amount: accumulatedAmount, probability });
        table.push({
          year,
          accumulatedAmount,
          probability: `${probability.toFixed(2)}%`,
        });
  
        return { result, table };
      },
      { result: [], table: [] }
    );
  
    // Set state updates
    setChartData(result);
    setTableData(table);
    setFinalProbability(result.length ? result[result.length - 1].probability : 0);
    setFormSubmitted(true);
  };
  

  

//   useEffect(() => {
//     calculateRetirementData();
//   }, [formData, cashflowDistribution]);

  const retireYear = new Date().getFullYear() + formData.retirePeriod;

  function handleInputChange(field,value){
    setFormSubmitted(false)
    setFormData((prev)=>{return {...prev,[field]:value}})
  }

  return (
    <div style={{ display: "flex",flexDirection:"column",alignItems:"center"
    ,gap: "2rem", padding: "1rem 2rem",boxSizing:"border-box",width:"100%"}}>
      {/* Input Fields */}
      <div style={{ display: "flex", gap: "1rem" ,flexWrap:"wrap",width:"100%"}}>
      <form 
      onSubmit={(e)=>{e.preventDefault();calculateRetirementData()}}
      style={{ display: "flex", 
      flexDirection: "column",
        gap: "1rem",padding:"1rem",height:"max-content"
        ,boxSizing:"border-box",backgroundColor: "#fafafa",borderRadius:"10px",width:"350px"}}>
      <TextInput
        //  sx={{maxWidth:"250px",width:"250px"}}
          label="Retire Amount"
          type="number"
          value={formData.retireAmount}
          onChange={(v) => handleInputChange("retireAmount",parseFloat(v))}
        />
        <SelectBox
        // width="250px"
          label={"Retirement Funding Source"}
          options={[
            "Net Cashflow Before Asset Return",
            "Net Cashflow After Asset Return",
            "Net Cashflow Asset Withdrawal",
          ]}
          value={formData.funding}
          onChange={(e,v) => handleInputChange("funding",v)}
        />
       
        <TextInput
        //  sx={{maxWidth:"250px",width:"250px"}}
          label="Retire in (Years)"
          type="number"
          value={formData.retirePeriod}
          onChange={(v) => handleInputChange("retirePeriod",parseInt(v, 10))}
        />
        <Button type="submit" variant="contained">Submit</Button>
      </form>
      <RetirementSummary
      formSubmitted={formSubmitted}
      retireTable={tableData}
      retireAmount={formData.retireAmount}
      retirePeriod={formData.retirePeriod}
      funding={formData.funding}
      probability={finalProbability}
    />
    </div>
      {formSubmitted && 
      <div style={{boxSizing:"border-box",display:"flex",flexDirection:"column",gap:"1rem",width:"100%"}}>
     
      {/* Highcharts Visualization */}
      {chartData.length > 0 && (
        <RetirementChart chartData={chartData} retireYear={retireYear} />
      )}
       {/* Summary */}
      


      {/* Data Table */}
      {tableData.length > 0 && (
        <RetirementTable tableData={tableData} retireYear={retireYear} />
      )}
      </div>}
    </div>
  );
}


function RetirementSummary({formSubmitted,retireTable, retireAmount, retirePeriod, funding, probability }) {
    let summaryComment;
  
    if (probability >= 80) {
      summaryComment = `Great news! Based on your current plan, you have a high probability (${probability.toFixed(
        2
      )}%) of retiring with your desired amount of $${retireAmount.toLocaleString()} in ${retirePeriod} years using ${funding}.`;
    } else if (probability >= 50) {
      summaryComment = `There is a moderate chance (${probability.toFixed(
        2
      )}%) that you will retire with your target amount of $${retireAmount.toLocaleString()} in ${retirePeriod} years using ${funding}. Consider revisiting your plan for a higher probability.`;
    } else {
      summaryComment = `Based on current projections, your probability (${probability.toFixed(
        2
      )}%) of retiring with $${retireAmount.toLocaleString()} in ${retirePeriod} years using ${funding} is low. It might be wise to adjust your strategy.`;
    }

    let message = `Want to retire by ${retirePeriod} years with $${retireAmount.toLocaleString()} using ${funding}. 
      Here is the datail of accumulated table: ${JSON.stringify({retireTable})}. Give the earliest time of retirement and also whether can retire by the specified by the retire period. And if can't give when if possible.`
  
    return (
      <AISummaryBox message={formSubmitted && message } sx={{flex:1}}>
        {/* <h3>Retirement Summary</h3> */}
        {/* <p>
          You plan to retire in <strong>{retirePeriod} years</strong> with a target amount of{" "}
          <strong>${retireAmount.toLocaleString()}</strong>, funded by <strong>{funding}</strong>.
        </p>
        <p>{summaryComment}</p> */}
      </AISummaryBox>
    );
  }
  
  function RetirementChart({ chartData, retireYear }) {
    // Get the default color from Highcharts theme
    const defaultColor = Highcharts.getOptions().colors[0];
    const highlightColor = "#d9534f"; // Highlight color for the retirement year
  
    // Determine the index for the retirement year in the categories
    const retireIndex = chartData.findIndex(data => data.year === retireYear);
  
    const chartOptions = {
      title: { text: "Retirement Probability Over Time" },
      xAxis: {
        categories: chartData.map((data) => data.year),
        // Add plotBand to highlight the retirement year
        plotBands: retireIndex !== -1 ? [{
          color: "#d4eed1", // a semi-transparent highlight color
          from: retireIndex - 0.5,
          to: retireIndex + 0.5,
          label: {
            text: "Retirement Year",
            verticalAlign: "top",
            y: 20,
            style: {
              color: "green",
              fontWeight: "bold"
            }
          }
        }] : []
      },
      yAxis: [
        { title: { text: "Amount" } },
        { title: { text: "Probability (%)" }, opposite: true },
      ],
      credits: { enabled: false },
      series: [
        {
          name: "Accumulated Amount",
          type: "column",
          yAxis: 0,
          dataLabels: { enabled: false }, // Disable labels for column series
          colorByPoint: true,
          data: chartData.map((data) => ({
            y: data.amount,
            color: defaultColor,
          })),
        },
        {
          name: "Probability (%)",
          type: "line",
          yAxis: 1,
          color: "gray",
          dataLabels: { enabled: false }, // Enable labels for probability line series
          data: chartData.map((data) => data.probability),
        },
      ],
      plotOptions: {
      //   series: {
      //     marker: { enabled: true },
      //   },
      },
    };
  
    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  }
  
  function RetirementTable({ tableData, retireYear }) {
    return (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Year</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Accumulated Amount ($)</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Probability</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr
              key={index}
              style={
                row.year === retireYear
                  ? { backgroundColor: "#d4eed1" }
                  : { backgroundColor: "#fff" }
              }
            >
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.year} {row.year === retireYear ? "(Retirement Year)" : ""}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{formatNumber(row.accumulatedAmount)}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{row.probability}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  