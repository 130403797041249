import { useAppInfo } from 'AppState';
import {
  assistantDetails,
  assistants,
  openaiAPI,
} from 'appState/initialStateAssistant';

import useFetchResponse from './useFetchResponse';

export default function useFetchChiefSupervisor() {
  const {stateApp} = useAppInfo()
  const userRole = stateApp?.onboarding?.userRole
  const systemInstructions = userRole === "advisor" ? systemInstructionsAdvisor : systemInstructionsClient
  const responseFormat = userRole === "advisor" ? responseFormatAdvisor : responseFormatClient
  const {fetchResponse} = useFetchResponse()
  const { dispatchAssistant, stateAssistant } = useAppInfo();
  const {openai} = stateAssistant
    async function findAssistant({message,chats}) {
      dispatchAssistant({ loading: true, chatStatus: "running",loadingMessage:"Connecting..."});

      try {
        const completion = await openaiAPI.chat.completions.create({
          messages: [{ role: "system", content: systemInstructions},...chats,{role:"user",content:message}],
          model: "gpt-4o",
          response_format: responseFormat,
          store:true
        });
  
        const assistantToUse = JSON.parse(completion?.choices[0]?.message?.content)?.assistant_to_use
              let assistantName;
              if (assistants?.[assistantToUse]){
                assistantName = assistantDetails.filter(obj=>obj.assistant === assistantToUse)[0]?.name
                dispatchAssistant({"loadingMessage":`Connecting to AI-${assistantName}`})
              }
             
        let assistantIdUpdate = assistants?.[assistantToUse] || assistants?.GeneralAssistant
        // console.log(assistantIdUpdate,assistantToUse)
        await fetchResponse({message,assistantId:assistantIdUpdate,assistantName,chats})  
      }
    
      catch (error){
        // console.log(error)
        const updatedChats = [...chats];
        updatedChats[updatedChats.length - 1] = {
            role: "assistant",
            content: 'Sorry, something went wrong.',
        };
        // await cancelRun({threadId, runId})
        dispatchAssistant({ chats: updatedChats,loading:false,chatStatus:"stop",runId:null,threadId:null});
    }
    finally {
      dispatchAssistant({loading:false,loadingMessage:""})
    }
    }

    return {findAssistant}
    
}


const systemInstructionsClient = `You are a supervising assistant. You decide which assistant will be helpful to answer users question. 
Go through all the different Assistants below and decide which Assistant will be helpful to answer users question: 
1. FinancialPlanning assistant - if the queries relate to perosnal finances, financial health, all assets like savings, insurance, investment portfolio, cash, private equity, etc, and all asset allocation, liabilities, goals, income, expense, and cashflow. 
2. PortfolioManager assistant - if the queries relate to users Portfolio, Portfolio Assets, Portfolio Asset Allocation. It does not answer questions of all the Assets.
3. EconomyAnalyst assistant - if the queries relate to the Economy, Inflation, GDP, Monetary Policy etc. 
4. TickerAnalyst assistant - if the queries relate to tickers in Stocks, Crypto, Bond, Forex, ETF, including earning calls.  
5. OpportunityFinder assistant - finds opportunties that fit portfolio, Trending Tickers, long term and trading opportunities. 
6. NewsAssistant assistant -  if the queries relate to News. 
7. ReportBuilder assistant - if the queries relate to getting reports on personal finance, portfolio, market, economy. 
8. FinancialProductAnalyst assistant - if the queries relate to looking for financial products like Savings Account, Credit Card, Mortgages, Car Insurance, Life Insurance, Personal Loans, Student Loans, etc.
9. GeneralAssistant assistant if the queries are general.
10. NotifyAssistant assistant if the queries relate to sending emails, sms, calendar, meeting, etc.
`

const responseFormatClient = { "type": "json_schema","json_schema":{
    "name": "supervisor_assistant",
    "strict": false,
    "schema": {
      "type": "object",
      "required": [
        "assistant_to_use"
      ],
      "properties": {
        "assistant_to_use": {
          "type": "string",
          "items": {
            "enum": [
              "FinancialPlanning",
              "PortfolioManager",
              "OpportunityFinder",
              "EconomyAnalyst",
              "TickerAnalyst",
              "NewsAssistant",
              "ReportBuilder",
              "GeneralAssistant",
              "FinancialProductAnalyst",
              "NotifyAssistant"
            ],
            "type": "string",
            "required": [],
            "properties": {},
            "description": systemInstructionsClient,
            "additionalProperties": false
          }
        }
      },
      "additionalProperties": false
    }
  }}



const systemInstructionsAdvisor = `You are a supervising assistant. You decide which assistant will be helpful to answer users question. 
  Go through all the different Assistants below and decide which Assistant will be helpful to answer users question: 
  1. AdvisorAssistant assistant - if the queries relate to advisors business or clients. And if the queries relate to sending emails, sms, calendar, meeting, etc.
  2. EconomyAnalyst assistant - if the queries relate to the Economy, Inflation, GDP, Monetary Policy etc. 
  3. TickerAnalyst assistant - if the queries relate to tickers in Stocks, Crypto, Bond, Forex, ETF, including earning calls.  
  4. OpportunityFinder assistant - finds opportunties that fit portfolio, Trending Tickers, long term and trading opportunities. 
  5. NewsAssistant assistant -  if the queries relate to News. 
  6. ReportBuilder assistant - if the queries relate to getting reports on personal finance, portfolio, market, economy. 
  7. FinancialProductAnalyst assistant - if the queries relate to looking for financial products like Savings Account, Credit Card, Mortgages, Car Insurance, Life Insurance, Personal Loans, Student Loans, etc.
  8. GeneralAssistant assistant if the queries are general.
  `
  
  const responseFormatAdvisor = { "type": "json_schema","json_schema":{
      "name": "supervisor_assistant",
      "strict": false,
      "schema": {
        "type": "object",
        "required": [
          "assistant_to_use"
        ],
        "properties": {
          "assistant_to_use": {
            "type": "string",
            "items": {
              "enum": [
                "AdvisorAssistant",
                "OpportunityFinder",
                "EconomyAnalyst",
                "TickerAnalyst",
                "NewsAssistant",
                "ReportBuilder",
                "GeneralAssistant",
                "FinancialProductAnalyst",
              ],
              "type": "string",
              "required": [],
              "properties": {},
              "description": systemInstructionsAdvisor,
              "additionalProperties": false
            }
          }
        },
        "additionalProperties": false
      }
    }}
  