import Modal from 'components/Modal/Modal'
import React, { useEffect } from 'react'
import RiskTolerance from './RiskTolerance'

export default function RiskToleranceModal({open,onClose}) {
  const [modal, setModal] = React.useState(true);

  useEffect(()=>{
    if (open){
    setModal(true)}
  },[open])

  return (
    <>
    {modal && 
    <Modal width='90%' height='80%' onClose={() =>{setModal(false);onClose && onClose(false)}}>
        <div style={{width:"100%",height:"100%",overflow:"auto"}}>
        <RiskTolerance/>
        </div>
        
    </Modal>}</>
  )
}
