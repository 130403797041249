import React from 'react'
import PortfolioOverview from './PortfolioOverview'
import OverviewBox from 'myFinancials/Template/OverviewBox'
import PortfolioAccountSelection from './PortfolioAccountSelection'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { navLinkDict } from 'nav/navLinks'

export default function PortfolioOverviewHome() {
  const navigate = useNavigate()
  const navigateUrl = navLinkDict?.Portfolio?.path
  return (
    <OverviewBox navigateUrl={navigateUrl} 
    header={ 
    <div style={{display:"flex",gap:"1rem",alignItems:"center"}}><h3 style={{fontWeight:"bold"}}>
      Investment Portfolio:
      </h3><PortfolioAccountSelection/></div>}>
    
    <div style={{display:"flex",flexDirection:"column",gap:"1rem",height:"100%",width:"100%"}}>
      <div style={{flex:1,width:"100%"}}>
    <PortfolioOverview displayType='home'/>
    </div>
    {/* <div style={{display:"flex",gap:"1rem"}}>
      <Button variant='highlight' onClick={()=>navigate(navigateUrl)}>Manage Portfolio</Button>
      <Button variant='highlight'onClick={()=>navigate("/MyFinancials/InvestmentPreferences")}>Investment Preference</Button>
      <Button variant='highlight'onClick={()=>navigate("/MyFinancials/RiskManagement")}>Risk Management</Button>

    </div> */}
    </div>
    </OverviewBox>
  )
}
