export const clientsData = [
  {
    id: 1,
    name: "Emma Thompson",
    location: "New York, NY",
    accountType: "Individual",
    occupation: "Executive",
    age: 45,
    aum: 2500000,
    portfolioType: "Conservative",
    riskScore: 3,
    lastContact: "2024-03-15",
    nextReview: "2024-04-15",
    status: "Active",
    growth: 8.5,
    activeScenarios: 3,
    todos: ["Annual Review", "Portfolio Rebalancing", "Tax Planning Discussion", "Estate Planning Update"],
    investmentGoals: ["Retirement", "Estate Planning"],
    financialWellnessScore: 85,
    goalSuccessRate: 78,
    contactInfo: {
      email: "emma.thompson@email.com",
      phone: "+1 (212) 555-0123",
      address: "123 Park Avenue, New York, NY 10001"
    },
    familyMembers: [
      { name: "John Thompson", relation: "Spouse", age: 47 },
      { name: "Sarah Thompson", relation: "Daughter", age: 15 }
    ],
    accounts: [
      { type: "401(k)", balance: 1500000, institution: "Fidelity" },
      { type: "IRA", balance: 800000, institution: "Vanguard" },
      { type: "Brokerage", balance: 200000, institution: "Charles Schwab" }
    ],
    recentTransactions: [
      { date: "2024-03-10", type: "Portfolio Rebalancing", amount: 50000 },
      { date: "2024-02-28", type: "Dividend Reinvestment", amount: 5000 }
    ],
    documents: [
      { name: "2023 Tax Return", date: "2024-02-15", type: "Tax" },
      { name: "Investment Policy Statement", date: "2024-01-10", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-15", content: "Discussed retirement planning strategy" },
      { date: "2024-02-20", content: "Reviewed portfolio performance" }
    ]
  },
  {
    id: 2,
    name: "James Wilson",
    location: "San Francisco, CA",
    accountType: "Joint",
    occupation: "Tech Entrepreneur",
    age: 38,
    aum: 1800000,
    portfolioType: "Moderate",
    riskScore: 5,
    lastContact: "2024-03-12",
    nextReview: "2024-04-10",
    status: "Active",
    growth: 12.3,
    activeScenarios: 2,
    todos: ["Stock Option Review", "Estate Planning Update", "IPO Planning", "Tax Strategy Review"],
    investmentGoals: ["Growth", "Tax Optimization", "Wealth Building"],
    financialWellnessScore: 92,
    goalSuccessRate: 85,
    contactInfo: {
      email: "james.wilson@email.com",
      phone: "+1 (415) 555-0123",
      address: "456 Market Street, San Francisco, CA 94105"
    },
    familyMembers: [
      { name: "Lisa Wilson", relation: "Spouse", age: 36 }
    ],
    accounts: [
      { type: "Brokerage", balance: 1200000, institution: "Morgan Stanley" },
      { type: "RSU Account", balance: 600000, institution: "E*TRADE" }
    ],
    recentTransactions: [
      { date: "2024-03-08", type: "Stock Option Exercise", amount: 100000 }
    ],
    documents: [
      { name: "Stock Option Agreement", date: "2024-01-15", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-12", content: "Discussed IPO planning" }
    ]
  },
  {
    id: 3,
    name: "Sarah Chen",
    location: "Boston, MA",
    accountType: "Individual",
    occupation: "Physician",
    age: 42,
    aum: 3200000,
    portfolioType: "Aggressive",
    riskScore: 8,
    lastContact: "2024-03-10",
    nextReview: "2024-04-05",
    status: "Active",
    growth: 15.7,
    activeScenarios: 4,
    todos: ["Portfolio Review", "Tax Planning", "Estate Planning Update", "Education Funding Review"],
    investmentGoals: ["Wealth Building", "Children's Education", "Early Retirement"],
    financialWellnessScore: 88,
    goalSuccessRate: 92,
    contactInfo: {
      email: "sarah.chen@email.com",
      phone: "+1 (617) 555-0123",
      address: "789 Commonwealth Ave, Boston, MA 02215"
    },
    familyMembers: [
      { name: "Michael Chen", relation: "Spouse", age: 44 },
      { name: "Emily Chen", relation: "Daughter", age: 8 },
      { name: "David Chen", relation: "Son", age: 6 }
    ],
    accounts: [
      { type: "Brokerage", balance: 2000000, institution: "Fidelity" },
      { type: "529 Plan", balance: 800000, institution: "Vanguard" },
      { type: "IRA", balance: 400000, institution: "Charles Schwab" }
    ],
    recentTransactions: [
      { date: "2024-03-05", type: "College Fund Contribution", amount: 25000 },
      { date: "2024-02-15", type: "Portfolio Rebalancing", amount: 75000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-01", type: "Legal" },
      { name: "Investment Policy Statement", date: "2024-01-20", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-10", content: "Discussed education funding strategy" },
      { date: "2024-02-15", content: "Reviewed portfolio performance" }
    ]
  },
  {
    id: 4,
    name: "Michael Anderson",
    location: "Chicago, IL",
    accountType: "Joint",
    occupation: "Corporate Executive",
    age: 55,
    aum: 4500000,
    portfolioType: "Conservative",
    riskScore: 4,
    lastContact: "2024-03-08",
    nextReview: "2024-04-20",
    status: "Active",
    growth: 6.8,
    activeScenarios: 2,
    todos: ["Retirement Planning Review", "Estate Planning Update", "Tax Strategy Meeting", "Charitable Giving Review"],
    investmentGoals: ["Retirement", "Legacy Planning", "Income Generation"],
    financialWellnessScore: 95,
    goalSuccessRate: 88,
    contactInfo: {
      email: "michael.anderson@email.com",
      phone: "+1 (312) 555-0123",
      address: "123 Michigan Avenue, Chicago, IL 60601"
    },
    familyMembers: [
      { name: "Jennifer Anderson", relation: "Spouse", age: 53 },
      { name: "Robert Anderson", relation: "Son", age: 25 },
      { name: "Elizabeth Anderson", relation: "Daughter", age: 22 }
    ],
    accounts: [
      { type: "401(k)", balance: 2500000, institution: "Fidelity" },
      { type: "IRA", balance: 1500000, institution: "Vanguard" },
      { type: "Brokerage", balance: 500000, institution: "Charles Schwab" }
    ],
    recentTransactions: [
      { date: "2024-03-01", type: "Required Minimum Distribution", amount: 150000 },
      { date: "2024-02-15", type: "Charitable Contribution", amount: 50000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-10", type: "Legal" },
      { name: "Retirement Planning Review", date: "2024-01-15", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-08", content: "Discussed legacy planning strategy" },
      { date: "2024-02-20", content: "Reviewed retirement income projections" }
    ]
  },
  {
    id: 5,
    name: "Lisa Rodriguez",
    location: "Miami, FL",
    accountType: "Individual",
    occupation: "Attorney",
    age: 36,
    aum: 1500000,
    portfolioType: "Moderate",
    riskScore: 6,
    lastContact: "2024-03-14",
    nextReview: "2024-04-12",
    status: "Active",
    growth: 10.2,
    activeScenarios: 3,
    todos: ["Home Purchase Planning", "Investment Review", "Tax Planning", "Retirement Planning"],
    investmentGoals: ["Home Purchase", "Investment Growth", "Retirement Planning"],
    financialWellnessScore: 82,
    goalSuccessRate: 75,
    contactInfo: {
      email: "lisa.rodriguez@email.com",
      phone: "+1 (305) 555-0123",
      address: "456 Ocean Drive, Miami, FL 33139"
    },
    familyMembers: [
      { name: "Carlos Rodriguez", relation: "Spouse", age: 38 }
    ],
    accounts: [
      { type: "Brokerage", balance: 1000000, institution: "Fidelity" },
      { type: "IRA", balance: 500000, institution: "Vanguard" }
    ],
    recentTransactions: [
      { date: "2024-03-10", type: "Investment Contribution", amount: 25000 },
      { date: "2024-02-28", type: "Portfolio Rebalancing", amount: 15000 }
    ],
    documents: [
      { name: "Investment Policy Statement", date: "2024-02-01", type: "Planning" },
      { name: "Home Purchase Analysis", date: "2024-01-15", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-14", content: "Discussed home purchase strategy" },
      { date: "2024-02-28", content: "Reviewed investment portfolio" }
    ]
  },
  {
    id: 6,
    name: "David Kim",
    location: "Seattle, WA",
    accountType: "Individual",
    occupation: "Software Engineer",
    age: 41,
    aum: 2800000,
    portfolioType: "Aggressive",
    riskScore: 7,
    lastContact: "2024-03-11",
    nextReview: "2024-04-18",
    status: "Active",
    growth: 14.5,
    activeScenarios: 2,
    todos: ["Stock Option Review", "Portfolio Rebalancing", "Tax Planning", "Retirement Planning"],
    investmentGoals: ["Early Retirement", "Stock Options", "Wealth Building"],
    financialWellnessScore: 86,
    goalSuccessRate: 82,
    contactInfo: {
      email: "david.kim@email.com",
      phone: "+1 (206) 555-0123",
      address: "456 Pine Street, Seattle, WA 98101"
    },
    familyMembers: [
      { name: "Karen Kim", relation: "Spouse", age: 39 }
    ],
    accounts: [
      { type: "Brokerage", balance: 1800000, institution: "Fidelity" },
      { type: "RSU Account", balance: 1000000, institution: "E*TRADE" }
    ],
    recentTransactions: [
      { date: "2024-03-05", type: "Stock Option Exercise", amount: 50000 },
      { date: "2024-02-15", type: "Portfolio Rebalancing", amount: 100000 }
    ],
    documents: [
      { name: "Stock Option Agreement", date: "2024-01-15", type: "Legal" },
      { name: "Retirement Planning Review", date: "2024-01-20", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-11", content: "Discussed stock option exercise" },
      { date: "2024-02-15", content: "Reviewed portfolio performance" }
    ]
  },
  {
    id: 7,
    name: "Rachel Greene",
    location: "Denver, CO",
    accountType: "Joint",
    occupation: "Business Owner",
    age: 48,
    aum: 1900000,
    portfolioType: "Conservative",
    riskScore: 3,
    lastContact: "2024-03-13",
    nextReview: "2024-04-25",
    status: "Active",
    growth: 7.4,
    activeScenarios: 3,
    todos: ["Business Succession Planning", "Portfolio Review", "Tax Planning", "Estate Planning"],
    investmentGoals: ["Business Expansion", "Retirement", "Legacy Planning"],
    financialWellnessScore: 84,
    goalSuccessRate: 80,
    contactInfo: {
      email: "rachel.greene@email.com",
      phone: "+1 (303) 555-0123",
      address: "456 Cherry Street, Denver, CO 80202"
    },
    familyMembers: [
      { name: "David Greene", relation: "Spouse", age: 46 }
    ],
    accounts: [
      { type: "Brokerage", balance: 1500000, institution: "Morgan Stanley" },
      { type: "RSU Account", balance: 400000, institution: "E*TRADE" }
    ],
    recentTransactions: [
      { date: "2024-03-05", type: "Business Succession Planning", amount: 50000 },
      { date: "2024-02-15", type: "Portfolio Rebalancing", amount: 75000 }
    ],
    documents: [
      { name: "Business Succession Plan", date: "2024-02-01", type: "Legal" },
      { name: "Investment Policy Statement", date: "2024-01-20", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-13", content: "Discussed business succession planning" },
      { date: "2024-02-15", content: "Reviewed portfolio performance" }
    ]
  },
  {
    id: 8,
    name: "Thomas Martinez",
    location: "Austin, TX",
    accountType: "Joint",
    occupation: "Real Estate Developer",
    age: 44,
    aum: 3500000,
    portfolioType: "Moderate",
    riskScore: 5,
    lastContact: "2024-03-09",
    nextReview: "2024-04-15",
    status: "Active",
    growth: 11.8,
    activeScenarios: 4,
    todos: ["Property Investment Review", "Portfolio Rebalancing", "Tax Planning", "Estate Planning"],
    investmentGoals: ["Property Investment", "Wealth Preservation", "Income Generation"],
    financialWellnessScore: 89,
    goalSuccessRate: 85,
    contactInfo: {
      email: "thomas.martinez@email.com",
      phone: "+1 (512) 555-0123",
      address: "456 Congress Avenue, Austin, TX 78701"
    },
    familyMembers: [
      { name: "Maria Martinez", relation: "Spouse", age: 42 }
    ],
    accounts: [
      { type: "Brokerage", balance: 2000000, institution: "Fidelity" },
      { type: "RSU Account", balance: 1500000, institution: "E*TRADE" }
    ],
    recentTransactions: [
      { date: "2024-03-05", type: "Property Investment", amount: 1000000 },
      { date: "2024-02-15", type: "Portfolio Rebalancing", amount: 100000 }
    ],
    documents: [
      { name: "Property Investment Agreement", date: "2024-02-01", type: "Legal" },
      { name: "Investment Policy Statement", date: "2024-01-20", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-09", content: "Discussed property investment strategy" },
      { date: "2024-02-15", content: "Reviewed portfolio performance" }
    ]
  },
  {
    id: 9,
    name: "Jennifer Wu",
    location: "Los Angeles, CA",
    accountType: "Individual",
    occupation: "Marketing Executive",
    age: 39,
    aum: 2200000,
    portfolioType: "Aggressive",
    riskScore: 8,
    lastContact: "2024-03-07",
    nextReview: "2024-04-08",
    status: "Active",
    growth: 16.2,
    activeScenarios: 2,
    todos: ["Investment Review", "Tax Planning", "Retirement Planning", "Estate Planning"],
    investmentGoals: ["Growth", "Innovation Investments", "Early Retirement"],
    financialWellnessScore: 87,
    goalSuccessRate: 83,
    contactInfo: {
      email: "jennifer.wu@email.com",
      phone: "+1 (213) 555-0123",
      address: "456 Sunset Boulevard, Los Angeles, CA 90028"
    },
    familyMembers: [
      { name: "Kevin Wu", relation: "Spouse", age: 37 }
    ],
    accounts: [
      { type: "Brokerage", balance: 1800000, institution: "Morgan Stanley" },
      { type: "RSU Account", balance: 400000, institution: "E*TRADE" }
    ],
    recentTransactions: [
      { date: "2024-03-05", type: "Investment Contribution", amount: 50000 },
      { date: "2024-02-15", type: "Portfolio Rebalancing", amount: 100000 }
    ],
    documents: [
      { name: "Investment Policy Statement", date: "2024-02-01", type: "Planning" },
      { name: "Retirement Planning Review", date: "2024-01-20", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-07", content: "Discussed investment strategy" },
      { date: "2024-02-15", content: "Reviewed portfolio performance" }
    ]
  },
  {
    id: 10,
    name: "Robert Taylor",
    location: "Washington, DC",
    accountType: "Joint",
    occupation: "Government Official",
    age: 52,
    aum: 4100000,
    portfolioType: "Conservative",
    riskScore: 4,
    lastContact: "2024-03-06",
    nextReview: "2024-04-22",
    status: "Active",
    growth: 7.9,
    activeScenarios: 3,
    todos: ["Portfolio Review", "Tax Planning", "Estate Planning", "Retirement Planning"],
    investmentGoals: ["Retirement", "Conservative Growth", "Legacy Planning"],
    financialWellnessScore: 93,
    goalSuccessRate: 87,
    contactInfo: {
      email: "robert.taylor@email.com",
      phone: "+1 (202) 555-0123",
      address: "456 Pennsylvania Avenue, Washington, DC 20001"
    },
    familyMembers: [
      { name: "Mary Taylor", relation: "Spouse", age: 50 }
    ],
    accounts: [
      { type: "Brokerage", balance: 2000000, institution: "Morgan Stanley" },
      { type: "RSU Account", balance: 2100000, institution: "E*TRADE" }
    ],
    recentTransactions: [
      { date: "2024-03-05", type: "Portfolio Rebalancing", amount: 100000 },
      { date: "2024-02-15", type: "Retirement Contribution", amount: 100000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-01", type: "Legal" },
      { name: "Investment Policy Statement", date: "2024-01-20", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-06", content: "Discussed retirement planning strategy" },
      { date: "2024-02-15", content: "Reviewed portfolio performance" }
    ]
  },
  {
    id: 11,
    name: "Alexandra Foster",
    location: "San Diego, CA",
    accountType: "Individual",
    occupation: "Biotech Executive",
    age: 41,
    aum: 2800000,
    portfolioType: "Aggressive",
    riskScore: 7,
    lastContact: "2024-03-16",
    nextReview: "2024-04-20",
    status: "Active",
    growth: 15.2,
    activeScenarios: 3,
    todos: ["Stock Option Review", "IPO Planning"],
    investmentGoals: ["Growth", "Innovation Investment"],
    financialWellnessScore: 89,
    goalSuccessRate: 86,
    contactInfo: {
      email: "alexandra.foster@email.com",
      phone: "+1 (619) 555-0123",
      address: "123 Innovation Drive, San Diego, CA 92121"
    },
    familyMembers: [
      { name: "Michael Foster", relation: "Spouse", age: 43 }
    ],
    accounts: [
      { type: "Brokerage", balance: 1800000, institution: "Morgan Stanley" },
      { type: "RSU Account", balance: 1000000, institution: "E*TRADE" }
    ],
    recentTransactions: [
      { date: "2024-03-10", type: "Stock Option Exercise", amount: 75000 }
    ],
    documents: [
      { name: "Stock Option Agreement", date: "2024-02-15", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-16", content: "Discussed IPO planning strategy" }
    ]
  },
  {
    id: 12,
    name: "Marcus Johnson",
    location: "Houston, TX",
    accountType: "Joint",
    occupation: "Energy Executive",
    age: 47,
    aum: 3200000,
    portfolioType: "Moderate",
    riskScore: 5,
    lastContact: "2024-03-15",
    nextReview: "2024-04-18",
    status: "Active",
    growth: 11.5,
    activeScenarios: 2,
    todos: ["Portfolio Rebalancing", "Estate Planning Update"],
    investmentGoals: ["Wealth Preservation", "Income Generation"],
    financialWellnessScore: 87,
    goalSuccessRate: 84,
    contactInfo: {
      email: "marcus.johnson@email.com",
      phone: "+1 (713) 555-0123",
      address: "456 Energy Plaza, Houston, TX 77002"
    },
    familyMembers: [
      { name: "Sarah Johnson", relation: "Spouse", age: 45 },
      { name: "Emily Johnson", relation: "Daughter", age: 16 }
    ],
    accounts: [
      { type: "401(k)", balance: 2000000, institution: "Fidelity" },
      { type: "Brokerage", balance: 1200000, institution: "Charles Schwab" }
    ],
    recentTransactions: [
      { date: "2024-03-08", type: "Portfolio Rebalancing", amount: 100000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-20", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-15", content: "Reviewed portfolio performance" }
    ]
  },
  {
    id: 13,
    name: "Sophie Chen",
    location: "Vancouver, BC",
    accountType: "Individual",
    occupation: "Real Estate Developer",
    age: 39,
    aum: 2500000,
    portfolioType: "Aggressive",
    riskScore: 8,
    lastContact: "2024-03-14",
    nextReview: "2024-04-15",
    status: "Active",
    growth: 16.8,
    activeScenarios: 4,
    todos: ["Property Investment Review", "Tax Planning"],
    investmentGoals: ["Real Estate Portfolio", "Wealth Building"],
    financialWellnessScore: 85,
    goalSuccessRate: 82,
    contactInfo: {
      email: "sophie.chen@email.com",
      phone: "+1 (604) 555-0123",
      address: "789 Real Estate Ave, Vancouver, BC V6B 1Y6"
    },
    familyMembers: [
      { name: "David Chen", relation: "Spouse", age: 41 }
    ],
    accounts: [
      { type: "Brokerage", balance: 1500000, institution: "RBC" },
      { type: "Real Estate Holdings", balance: 1000000, institution: "TD Bank" }
    ],
    recentTransactions: [
      { date: "2024-03-05", type: "Property Acquisition", amount: 500000 }
    ],
    documents: [
      { name: "Property Investment Agreement", date: "2024-02-25", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-14", content: "Discussed new property investment" }
    ]
  },
  {
    id: 14,
    name: "Daniel Rodriguez",
    location: "Miami, FL",
    accountType: "Joint",
    occupation: "Hospitality Executive",
    age: 44,
    aum: 1900000,
    portfolioType: "Moderate",
    riskScore: 6,
    lastContact: "2024-03-13",
    nextReview: "2024-04-12",
    status: "Active",
    growth: 10.5,
    activeScenarios: 2,
    todos: ["Business Expansion Review", "Retirement Planning"],
    investmentGoals: ["Business Growth", "Retirement"],
    financialWellnessScore: 86,
    goalSuccessRate: 83,
    contactInfo: {
      email: "daniel.rodriguez@email.com",
      phone: "+1 (305) 555-0123",
      address: "123 Ocean Drive, Miami, FL 33139"
    },
    familyMembers: [
      { name: "Maria Rodriguez", relation: "Spouse", age: 42 }
    ],
    accounts: [
      { type: "Business Account", balance: 1200000, institution: "Bank of America" },
      { type: "IRA", balance: 700000, institution: "Vanguard" }
    ],
    recentTransactions: [
      { date: "2024-03-10", type: "Business Investment", amount: 250000 }
    ],
    documents: [
      { name: "Business Plan", date: "2024-02-28", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-13", content: "Discussed business expansion strategy" }
    ]
  },
  {
    id: 15,
    name: "Emma Thompson",
    location: "Seattle, WA",
    accountType: "Individual",
    occupation: "Tech Entrepreneur",
    age: 35,
    aum: 4200000,
    portfolioType: "Aggressive",
    riskScore: 8,
    lastContact: "2024-03-12",
    nextReview: "2024-04-10",
    status: "Active",
    growth: 17.2,
    activeScenarios: 3,
    todos: ["IPO Planning", "Stock Option Review"],
    investmentGoals: ["Startup Growth", "Wealth Building"],
    financialWellnessScore: 88,
    goalSuccessRate: 85,
    contactInfo: {
      email: "emma.thompson@email.com",
      phone: "+1 (206) 555-0123",
      address: "456 Tech Street, Seattle, WA 98101"
    },
    familyMembers: [
      { name: "James Thompson", relation: "Spouse", age: 37 }
    ],
    accounts: [
      { type: "Brokerage", balance: 3000000, institution: "Morgan Stanley" },
      { type: "RSU Account", balance: 1200000, institution: "E*TRADE" }
    ],
    recentTransactions: [
      { date: "2024-03-08", type: "Stock Option Exercise", amount: 150000 }
    ],
    documents: [
      { name: "IPO Planning Document", date: "2024-02-22", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-12", content: "Discussed IPO preparation" }
    ]
  },
  {
    id: 16,
    name: "William Chang",
    location: "Toronto, ON",
    accountType: "Joint",
    occupation: "Investment Banker",
    age: 45,
    aum: 3800000,
    portfolioType: "Moderate",
    riskScore: 6,
    lastContact: "2024-03-11",
    nextReview: "2024-04-08",
    status: "Active",
    growth: 12.8,
    activeScenarios: 3,
    todos: ["Portfolio Review", "Tax Strategy"],
    investmentGoals: ["Wealth Preservation", "Income Generation"],
    financialWellnessScore: 90,
    goalSuccessRate: 87,
    contactInfo: {
      email: "william.chang@email.com",
      phone: "+1 (416) 555-0123",
      address: "789 Bay Street, Toronto, ON M5H 2N2"
    },
    familyMembers: [
      { name: "Lisa Chang", relation: "Spouse", age: 43 },
      { name: "Michael Chang", relation: "Son", age: 12 }
    ],
    accounts: [
      { type: "Brokerage", balance: 2500000, institution: "RBC" },
      { type: "RRSP", balance: 1300000, institution: "TD Bank" }
    ],
    recentTransactions: [
      { date: "2024-03-05", type: "Portfolio Rebalancing", amount: 200000 }
    ],
    documents: [
      { name: "Investment Policy Statement", date: "2024-02-18", type: "Planning" }
    ],
    notes: [
      { date: "2024-03-11", content: "Reviewed investment strategy" }
    ]
  },
  {
    id: 17,
    name: "Olivia Martinez",
    location: "Austin, TX",
    accountType: "Individual",
    occupation: "Healthcare Executive",
    age: 38,
    aum: 2200000,
    portfolioType: "Conservative",
    riskScore: 4,
    lastContact: "2024-03-10",
    nextReview: "2024-04-05",
    status: "Active",
    growth: 8.5,
    activeScenarios: 2,
    todos: ["Retirement Planning", "Estate Planning"],
    investmentGoals: ["Retirement", "Wealth Preservation"],
    financialWellnessScore: 92,
    goalSuccessRate: 89,
    contactInfo: {
      email: "olivia.martinez@email.com",
      phone: "+1 (512) 555-0123",
      address: "123 Healthcare Blvd, Austin, TX 78701"
    },
    familyMembers: [
      { name: "Carlos Martinez", relation: "Spouse", age: 40 }
    ],
    accounts: [
      { type: "401(k)", balance: 1500000, institution: "Fidelity" },
      { type: "IRA", balance: 700000, institution: "Vanguard" }
    ],
    recentTransactions: [
      { date: "2024-03-08", type: "Retirement Contribution", amount: 50000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-15", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-10", content: "Discussed retirement planning" }
    ]
  },
  {
    id: 18,
    name: "Benjamin Kim",
    location: "San Francisco, CA",
    accountType: "Individual",
    occupation: "Fintech Founder",
    age: 36,
    aum: 3500000,
    portfolioType: "Aggressive",
    riskScore: 8,
    lastContact: "2024-03-09",
    nextReview: "2024-04-03",
    status: "Active",
    growth: 18.5,
    activeScenarios: 4,
    todos: ["Series B Planning", "Stock Option Review"],
    investmentGoals: ["Startup Growth", "Innovation Investment"],
    financialWellnessScore: 87,
    goalSuccessRate: 84,
    contactInfo: {
      email: "benjamin.kim@email.com",
      phone: "+1 (415) 555-0123",
      address: "456 Fintech Street, San Francisco, CA 94105"
    },
    familyMembers: [
      { name: "Sarah Kim", relation: "Spouse", age: 34 }
    ],
    accounts: [
      { type: "Brokerage", balance: 2500000, institution: "Morgan Stanley" },
      { type: "RSU Account", balance: 1000000, institution: "E*TRADE" }
    ],
    recentTransactions: [
      { date: "2024-03-05", type: "Investment Round", amount: 500000 }
    ],
    documents: [
      { name: "Series B Planning", date: "2024-02-12", type: "Business" }
    ],
    notes: [
      { date: "2024-03-09", content: "Discussed Series B funding" }
    ]
  },
  {
    id: 19,
    name: "Victoria Patel",
    location: "Chicago, IL",
    accountType: "Joint",
    occupation: "Manufacturing Executive",
    age: 42,
    aum: 2800000,
    portfolioType: "Moderate",
    riskScore: 5,
    lastContact: "2024-03-08",
    nextReview: "2024-04-01",
    status: "Active",
    growth: 11.2,
    activeScenarios: 2,
    todos: ["Business Succession", "Portfolio Review"],
    investmentGoals: ["Business Growth", "Wealth Preservation"],
    financialWellnessScore: 86,
    goalSuccessRate: 83,
    contactInfo: {
      email: "victoria.patel@email.com",
      phone: "+1 (312) 555-0123",
      address: "789 Manufacturing Ave, Chicago, IL 60601"
    },
    familyMembers: [
      { name: "Raj Patel", relation: "Spouse", age: 44 }
    ],
    accounts: [
      { type: "Business Account", balance: 1800000, institution: "JP Morgan" },
      { type: "IRA", balance: 1000000, institution: "Vanguard" }
    ],
    recentTransactions: [
      { date: "2024-03-03", type: "Business Investment", amount: 150000 }
    ],
    documents: [
      { name: "Succession Plan", date: "2024-02-10", type: "Business" }
    ],
    notes: [
      { date: "2024-03-08", content: "Discussed business succession planning" }
    ]
  },
  {
    id: 20,
    name: "Nathan Wright",
    location: "Boston, MA",
    accountType: "Individual",
    occupation: "Pharmaceutical Executive",
    age: 47,
    aum: 3200000,
    portfolioType: "Conservative",
    riskScore: 4,
    lastContact: "2024-03-07",
    nextReview: "2024-03-30",
    status: "Active",
    growth: 9.8,
    activeScenarios: 2,
    todos: ["Retirement Planning", "Estate Planning"],
    investmentGoals: ["Retirement", "Legacy Planning"],
    financialWellnessScore: 93,
    goalSuccessRate: 90,
    contactInfo: {
      email: "nathan.wright@email.com",
      phone: "+1 (617) 555-0123",
      address: "123 Pharma Street, Boston, MA 02215"
    },
    familyMembers: [
      { name: "Emily Wright", relation: "Spouse", age: 45 },
      { name: "David Wright", relation: "Son", age: 15 }
    ],
    accounts: [
      { type: "401(k)", balance: 2000000, institution: "Fidelity" },
      { type: "IRA", balance: 1200000, institution: "Vanguard" }
    ],
    recentTransactions: [
      { date: "2024-03-01", type: "Retirement Contribution", amount: 75000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-08", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-07", content: "Discussed estate planning strategy" }
    ]
  },
  {
    id: 21,
    name: "Robert Chen",
    location: "Boston, MA",
    accountType: "Individual",
    occupation: "Pharmaceutical Executive",
    age: 47,
    aum: 3200000,
    portfolioType: "Conservative",
    riskScore: 4,
    lastContact: "2024-03-07",
    nextReview: "2024-03-30",
    status: "Active",
    growth: 9.8,
    activeScenarios: 2,
    todos: ["Retirement Planning", "Estate Planning"],
    investmentGoals: ["Retirement", "Legacy Planning"],
    financialWellnessScore: 93,
    goalSuccessRate: 90,
    contactInfo: {
      email: "nathan.wright@email.com",
      phone: "+1 (617) 555-0123",
      address: "123 Pharma Street, Boston, MA 02215"
    },
    familyMembers: [
      { name: "Emily Wright", relation: "Spouse", age: 45 },
      { name: "David Wright", relation: "Son", age: 15 }
    ],
    accounts: [
      { type: "401(k)", balance: 2000000, institution: "Fidelity" },
      { type: "IRA", balance: 1200000, institution: "Vanguard" }
    ],
    recentTransactions: [
      { date: "2024-03-01", type: "Retirement Contribution", amount: 75000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-08", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-07", content: "Discussed estate planning strategy" }
    ]
  },
  {
    id: 22,
    name: "Lisa Park",
    location: "Boston, MA",
    accountType: "Individual",
    occupation: "Pharmaceutical Executive",
    age: 47,
    aum: 3200000,
    portfolioType: "Conservative",
    riskScore: 4,
    lastContact: "2024-03-07",
    nextReview: "2024-03-30",
    status: "Active",
    growth: 9.8,
    activeScenarios: 2,
    todos: ["Retirement Planning", "Estate Planning"],
    investmentGoals: ["Retirement", "Legacy Planning"],
    financialWellnessScore: 93,
    goalSuccessRate: 90,
    contactInfo: {
      email: "nathan.wright@email.com",
      phone: "+1 (617) 555-0123",
      address: "123 Pharma Street, Boston, MA 02215"
    },
    familyMembers: [
      { name: "Emily Wright", relation: "Spouse", age: 45 },
      { name: "David Wright", relation: "Son", age: 15 }
    ],
    accounts: [
      { type: "401(k)", balance: 2000000, institution: "Fidelity" },
      { type: "IRA", balance: 1200000, institution: "Vanguard" }
    ],
    recentTransactions: [
      { date: "2024-03-01", type: "Retirement Contribution", amount: 75000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-08", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-07", content: "Discussed estate planning strategy" }
    ]
  },
  {
    id: 23,
    name: "Michael Wilson",
    location: "Boston, MA",
    accountType: "Individual",
    occupation: "Pharmaceutical Executive",
    age: 47,
    aum: 3200000,
    portfolioType: "Conservative",
    riskScore: 4,
    lastContact: "2024-03-07",
    nextReview: "2024-03-30",
    status: "Active",
    growth: 9.8,
    activeScenarios: 2,
    todos: ["Retirement Planning", "Estate Planning"],
    investmentGoals: ["Retirement", "Legacy Planning"],
    financialWellnessScore: 93,
    goalSuccessRate: 90,
    contactInfo: {
      email: "nathan.wright@email.com",
      phone: "+1 (617) 555-0123",
      address: "123 Pharma Street, Boston, MA 02215"
    },
    familyMembers: [
      { name: "Emily Wright", relation: "Spouse", age: 45 },
      { name: "David Wright", relation: "Son", age: 15 }
    ],
    accounts: [
      { type: "401(k)", balance: 2000000, institution: "Fidelity" },
      { type: "IRA", balance: 1200000, institution: "Vanguard" }
    ],
    recentTransactions: [
      { date: "2024-03-01", type: "Retirement Contribution", amount: 75000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-08", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-07", content: "Discussed estate planning strategy" }
    ]
  },
  {
    id: 24,
    name: "Sarah Thompson",
    location: "Boston, MA",
    accountType: "Individual",
    occupation: "Pharmaceutical Executive",
    age: 47,
    aum: 3200000,
    portfolioType: "Conservative",
    riskScore: 4,
    lastContact: "2024-03-07",
    nextReview: "2024-03-30",
    status: "Active",
    growth: 9.8,
    activeScenarios: 2,
    todos: ["Retirement Planning", "Estate Planning"],
    investmentGoals: ["Retirement", "Legacy Planning"],
    financialWellnessScore: 93,
    goalSuccessRate: 90,
    contactInfo: {
      email: "nathan.wright@email.com",
      phone: "+1 (617) 555-0123",
      address: "123 Pharma Street, Boston, MA 02215"
    },
    familyMembers: [
      { name: "Emily Wright", relation: "Spouse", age: 45 },
      { name: "David Wright", relation: "Son", age: 15 }
    ],
    accounts: [
      { type: "401(k)", balance: 2000000, institution: "Fidelity" },
      { type: "IRA", balance: 1200000, institution: "Vanguard" }
    ],
    recentTransactions: [
      { date: "2024-03-01", type: "Retirement Contribution", amount: 75000 }
    ],
    documents: [
      { name: "Estate Plan", date: "2024-02-08", type: "Legal" }
    ],
    notes: [
      { date: "2024-03-07", content: "Discussed estate planning strategy" }
    ]
  }
];

// Helper functions that can be used across components
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimization: 2,
    notation: 'compact',
  }).format(amount);
};

export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
};

// Analytics functions
export const calculateTotalAUM = (clients) => {
  return clients.reduce((sum, client) => sum + client.aum, 0);
};

export const calculateAverageGrowth = (clients) => {
  return clients.reduce((sum, client) => sum + client.growth, 0) / clients.length;
};

export const calculateActiveScenarios = (clients) => {
  return clients.reduce((sum, client) => sum + client.activeScenarios, 0);
};

export const getTopClients = (clients, count = 10) => {
  return [...clients].sort((a, b) => b.aum - a.aum).slice(0, count);
}; 