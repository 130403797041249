import React from 'react';

import { promptSuggestions } from 'b2b/data/dummyData';
import useHandleUserEvents from 'chatGPT/useHandleUserEvents';

import {
  Box,
  Chip,
  Grid,
  Typography,
} from '@mui/material';

const NomyPrompts = () => {
    const todos = promptSuggestions
  const [showAllPrompts, setShowAllPrompts] = React.useState(false);
  const {handleSend} = useHandleUserEvents()
  const toggleShowAllPrompts = () => {
    setShowAllPrompts(!showAllPrompts);
  };

  if (!promptSuggestions || promptSuggestions.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mt: 3, pt: 2, borderTop: '1px solid #f0f0f0' }}>
      <Typography variant="subtitle2" sx={{ mb: 2, color: 'text.primary', fontWeight: 500 }}>
        Ask Quinn
      </Typography>
      <Grid container spacing={1}>
        {promptSuggestions
          .slice(0, showAllPrompts ? undefined : 5)
          .map((suggestion, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Box 
            onClick={() => handleSend(suggestion.prompt)}
              sx={{ 
                p: 1.5,
                borderRadius: '8px',
                bgcolor: 'primary.50',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  bgcolor: 'primary.100',
                  transform: 'translateY(-1px)',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
                }
              }}
            >
              <Box 
              sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                <Typography sx={{ fontSize: '1rem' }}>{suggestion.icon}</Typography>
                <Chip
                  label={suggestion.category}
                  size="small"
                  sx={{
                    height: '18px',
                    fontSize: '0.6rem',
                    bgcolor: 'primary.main',
                    color: 'white',
                    '& .MuiChip-label': {
                      px: 1
                    }
                  }}
                />
              </Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'text.primary',
                  fontSize: '0.75rem',
                  fontWeight: 500
                }}
              >
                {suggestion.prompt}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {promptSuggestions.length > 5 && (
        <Box 
          onClick={toggleShowAllPrompts}
          sx={{ 
            mt: 2, 
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
              color: 'primary.main'
            }
          }}
        >
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'text.secondary',
              fontSize: '0.75rem',
              fontWeight: 500
            }}
          >
            {showAllPrompts ? 'Show Less ▲' : 'See More Suggestions ▼'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NomyPrompts; 