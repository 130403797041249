import React, { useState } from 'react';
import styles from './QuestionBox.module.css';  // Import the CSS module


export default function QuestionsHome() {
  return (
    <div style={{padding:"2rem 4rem"}}>
          <h1 style={{fontSize:"4rem",fontWeight:"600"}}>Questions?</h1>
          <QuestionBox/>    
          </div>
          
  )
}



const faqData = [
  {
    question: "What is Autonomy?",
    answer: `Autonomy is an autonomous wealth-tech platform powered by agentic AI. Autonomy’s proprietary algorithms deliver a Financial Wellness Score and take autonomous actions to optimize your financial life. From swapping high-interest credit cards to reducing debt, initiating mortgage renewals, or fine-tuning your portfolio for optimal fit, Autonomy doesn’t just provide insights—it acts on them for you.

Autonomy's financial vault secures your financial data and documents while powering your self-driving wallet, enabling individuals and advisors to make smarter decisions, build confidence, and create lasting prosperity. Experience the future of financial planning in the AI world.`,
  },
  {
    question: "How can we help you?",
    answer: "Wherever you are in your financial journey, we offer personalized guidance and recommend the best next steps to help you grow financially. You can connect with a financial advisor directly on our platform, ask our AI for personalized recommendations, or follow our interactive guides to take control and manage your finances on your own terms.",
  },
  {
    question: "How does AI improve my financial decisions?",
    answer: "It's nearly impossible for anyone, including financial advisors, to track every financial detail in real-time. AI, however, never rests. It constantly works in the background, identifying opportunities aligned with your portfolio strategy and offering insights to help you secure and grow your financial future. While the final decisions are still yours to make, AI acts as a guide, empowering you to make smarter, more informed choices. And when you consult with your financial advisor, you’ll be equipped to have more productive and insightful conversations.",
  },
  {
    question: "How much does it cost?",
    answer: "Monthly or Annual subscription for as little as $20!",
  },
  {
    question: "Is it safe to use?",
    answer: "Autonomy takes great care in security, integrated with industry-leading technology partners like Plaid, Apex, and Okta to ensure your valuable financial data remains your own. Whether you’re buying and selling investments, transferring funds to and from your bank account, or just logging into the app, your experience is protected.",
  },
  {
    question: "How are we different?",
    answer: "We do not provide generic answers.  We don’t offer one-size-fits-all solutions. We’re the only platform that analyzes how every market and economic change impacts your unique financial situation—because what truly matters is you.",
  },
  {
    question: "How do I connect my financials?",
    answer: "To receive personalized insights, simply connect your financial accounts, such as your bank and brokerage accounts. Autonomy uses Plaid, a secure service, to link your accounts safely with your financial institutions. Once connected, you’ll start receiving tailored insights, recommended next steps, and the option to ask AI for advice or consult with a professional on various financial topics.",
  },
];

const QuestionBox = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.faqContainer}>
      {faqData.map((item, index) => (
        <div key={index} className={styles.faqItem}>
          <div className={styles.faqQuestion} onClick={() => handleToggle(index)}>
            <h1 style={{fontWeight:"500"}}>{item.question}</h1>
            <h1 style={{fontWeight:"500"}}>{activeIndex === index ? '-' : '+'}</h1>
          </div>
          {activeIndex === index && <div className={styles.faqAnswer}>
            <h2 style={{fontWeight:"400"}}>{item.answer}</h2></div>}
        </div>
      ))}
    </div>
  );
};
