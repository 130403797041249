import { useAppInfo } from 'AppState'

export default function useHandleLogin() {
 const {dispatchApp} = useAppInfo()
  
  function handleLogin(login=true){
      dispatchApp({"loginModal":login})
    }
  return {handleLogin}
}
