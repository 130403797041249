import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import LabelIcon from '@mui/icons-material/Label';
import WorkIcon from '@mui/icons-material/Work';
import SavingsIcon from '@mui/icons-material/Savings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SecurityIcon from '@mui/icons-material/Security';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BarChartIcon from '@mui/icons-material/BarChart';

export const categoryTodos = [
  "Investment",
  "Budgeting",
  "Goal Planning",
  "Asset",
  "Cashflow",
  "Debt",
  "Risk & Crisis Management",
  "Retirement Planning",
  "Tax",
  "Savings",
  "Insurance",
  "Other"
];

export const categoriesConfig = [
  {
    name: "Investment",
    icon: <WorkIcon fontSize="small" />, // Investment icon
    color: '#BBDEFB', // Light Blue
    colorDark: '#2196F3', // Rich Blue
  },
  {
    name: "Budgeting",
    icon: <DonutSmallIcon fontSize="small" />, // Budgeting icon
    color: '#C8E6C9', // Light Green
    colorDark: '#43A047', // Vibrant Green
  },
  {
    name: "Goal Planning",
    icon: <CrisisAlertIcon fontSize="small" />, // Goal Planning icon
    color: '#FFE0B2', // Light Orange
    colorDark: '#FB8C00', // Bold Orange
  },
  {
    name: "Asset",
    icon: <BarChartIcon fontSize="small" />, // Asset Management icon
    color: '#D1C4E9', // Lavender
    colorDark: '#512DA8', // Deep Purple
  },
  {
    name: "Cashflow",
    icon: <AttachMoneyIcon fontSize="small" />, // Cashflow icon
    color: '#FFECB3', // Light Yellow
    colorDark: '#FFC107', // Gold
  },
  {
    name: "Debt",
    icon: <AccountBalanceIcon fontSize="small" />, // Debt Management icon
    color: '#FFCDD2', // Light Red
    colorDark: '#F44336', // Strong Red
  },
  {
    name: "Risk & Crisis Management",
    icon: <SecurityIcon fontSize="small" />, // Risk Management icon
    color: '#B2EBF2', // Light Cyan
    colorDark: '#00ACC1', // Teal
  },
  {
    name: "Retirement Planning",
    icon: <EventNoteIcon fontSize="small" />, // Retirement Planning icon
    color: '#F0F4C3', // Pale Lime
    colorDark: '#AFB42B', // Olive Green
  },
  {
    name: "Tax",
    icon: <LabelIcon fontSize="small" />, // Tax icon
    color: '#E0E0E0', // Light Gray
    colorDark: '#9E9E9E', // Medium Gray
  },
  {
    name: "Savings",
    icon: <SavingsIcon fontSize="small" />, // Savings icon
    color: '#E1F5FE', // Light Sky Blue
    colorDark: '#0288D1', // Rich Blue
  },
  {
    name: "Insurance",
    icon: <SecurityIcon fontSize="small" />, // Insurance icon
    color: '#FFCCBC', // Light Peach
    colorDark: '#E64A19', // Deep Orange
  },
  {
    name: "Other",
    icon: <LabelIcon fontSize="small" />, // Default icon
    color: '#E1BEE7', // Light Purple
    colorDark: '#9C27B0', // Rich Purple
  }
];

// Category to icon mapping
export const categoryIcons = categoriesConfig.reduce((icons, category) => {
  icons[category.name] = category.icon;
  return icons;
}, { default: <LabelIcon fontSize="small" /> });

// Category to light color mapping
export const categoryColors = categoriesConfig.reduce((colors, category) => {
  colors[category.name] = category.color;
  return colors;
}, { default: '#E0E0E0' });

// Category to dark color mapping
export const categoryColorDark = categoriesConfig.reduce((colorsDark, category) => {
  colorsDark[category.name] = category.colorDark;
  return colorsDark;
}, { default: '#9E9E9E' });


// Helper functions
function formatDate(date) {
    return new Intl.DateTimeFormat('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    }).format(date);
  }
  
  export function getUpcomingTodos(evt) {
    const now = new Date();
    const start = new Date(evt.dateStart);
    const end = new Date(evt.dateExpiry);
  
    // Function to strip time from a date
    const toDateOnly = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
  
    const nowDate = toDateOnly(now); // Current date without time
    const startDate = toDateOnly(start); // Start date without time
  
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 30 * oneDay;
  
    // Check if startDate is within the upcoming week
    return startDate >= nowDate && startDate < new Date(nowDate.getTime() + oneWeek);
  }

 export function getEventStatus(evt) {
    const now = new Date();
    const start = new Date(evt.dateStart);
    const end = new Date(evt.dateExpiry);
  
    // If event is entirely in the past
    if (end < now) return 'Completed in the Past';
  
    // If event starts today
    if (start.toDateString() === now.toDateString()) {
      return 'Ongoing Today';
    }
  
    // If event start is in the past, but not ended yet (ongoing multi-day)
    if (start < now && end > now) {
      return 'Currently Ongoing';
    }
  
    // Now, let's determine how far in the future the event is.
    const diffTime = start.getTime() - now.getTime();
    if (diffTime < 0) {
      // If we reached here, it means start < now and not ended, handled above.
      return 'Currently Ongoing';
    }
  
    // Define time thresholds
    const oneDay = 24 * 60 * 60 * 1000;
    const oneMonth = 30 * oneDay;   // Approximate a month as 30 days
    const oneQuarter = 3 * oneMonth; 
    const oneYear = 365 * oneDay;
  
    if (diffTime <= oneMonth) {
      return 'Coming Soon (Within a Month)';
    } else if (diffTime <= oneQuarter) {
      return 'Just a Bit Away (Within a Quarter)';
    } else if (diffTime <= oneYear) {
      return 'Down the Road (Within a Year)';
    } else {
      return 'Way into the Future';
    }
  }
  
  function getUpcomingEvents(events, daysAhead = 7) {
    const now = new Date();
    const futureThreshold = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysAhead);
    return events.filter(evt => {
      const start = new Date(evt.dateStart);
      return start > now && start <= futureThreshold;
    });
  }
  

export function formatDateToDisplay(dateString) {
    const date = new Date(dateString); // Convert the string to a Date object
  
    // Options for a readable format
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
  
    return date.toLocaleDateString('en-US', options); // Format using locale and options
  }