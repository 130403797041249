import React from 'react';

import SocialMedia from 'home/SocialMedia';

import EmailIcon from '@mui/icons-material/Email';

import HomePageTemplate from '../../home/HomePageTemplate';

export default function Contact() {
  return (
    <HomePageTemplate pageName='Contact'>
    <div style={{display:"flex",gap:"2rem",marginTop:"1rem"
    ,flexDirection:"column",alignItems:"center",padding:"1rem",borderRadius:"5px"}}>
  <div style={{display:"flex",gap:"2rem"}}>
  <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
  <a
    href="mailto:info@autonomyfinance.ai?subject=Inquiry&body=Hello%20there"
    style={{
      fontSize: "2rem",
      color: "black"
    }}
  >
    <EmailIcon fontSize="large" />
  </a>
  <h3 style={{color:"black"}}>info@autonomyfinance.ai</h3>
  </div>
  
  </div>
  <SocialMedia color='black'/>
  </div>
  </HomePageTemplate>
  
  )
}
