import React, {
  useEffect,
  useState,
} from 'react';

import Modal from 'components/Modal/Modal';
import { useParams } from 'react-router-dom';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import MessageBarProfessional from './Chat/MessgaeBarProfessional';
import FinanceProfessionalCard from './FinanceProfessionalCard';
import {
  professionalDetailsArray,
  professionalType,
} from './info';

export default function FinanceProfessionalExplore() {
  const {idProfessionalType} = useParams()
  const professionalSelected = professionalType.filter(obj=>obj.url === idProfessionalType)[0]

  return (
    <>
    <ExploreTemplate professionalSelected={professionalSelected} professionalCategory={idProfessionalType}/>
    </>
    
  )
}

export function FinanceProfessionalExploreModal({label,professional="Financial Advisor"}) {
  const professionalSelected = professionalType.filter(obj=>obj.name === professional)[0]
  const [modal,setModal] = useState(false)
  return (
    <>
    <Button 
    sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        background: "#ffffff",
        boxShadow: "0 2px 8px rgba(0,0,0,0.04)",
        borderRadius: "12px",
        padding: "1.25rem",
        minWidth: "240px",
        border: "1px solid #f0f0f0",
        transition: "all 0.2s ease-in-out",
        textAlign: "left",
        "&:hover": {
          background: "#ffffff",
          boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
          transform: "translateY(-2px)",
          borderColor: "#e0e0e0"
        }
    }}
    onClick={()=>setModal(true)}>
      <Typography 
        sx={{ 
          fontSize: "1rem",
          fontWeight: 500,
          color: "text.primary",
          width: "100%"
        }}
      >
        {label ? label : "Explore Professionals"}
      </Typography>
      <Box sx={{ 
        display: "flex", 
        gap: "0.5rem",
        alignItems: "center",
        width: "100%"
      }}>
        <ProfessionalsAvailable/>
        <OnlineStatus/>
      </Box>
    </Button>
    {modal &&
    <Modal onClose={()=>setModal(false)} width='90%' height='80%' sx={{borderRadius:"5px"}}>
    <div style={{height:"100%",width:"100%",overflow:"hidden",position:"relative"}}>
    <ExploreTemplate professionalSelected={professionalSelected} professionalCategory={professionalSelected?.url}/>
    </div>
    </Modal>}
    </>
  )
}

function ExploreTemplate({professionalSelected,professionalCategory}) {
  const [tabIndex, setTabIndex] = useState(0);

  const professionalName = professionalType.filter(obj=>obj.url === professionalCategory || obj.name === professionalCategory)[0]?.name
  const matchProfessionals = professionalDetailsArray.filter(obj=>obj?.professionalCategory.includes(professionalName))
  
const financeProfessionals = matchProfessionals.length > 0 ? matchProfessionals : professionalDetailsArray 
  // const financeProfessionals =new Array(20).fill(null);

  useEffect(()=>{setTabIndex(0)},[professionalCategory])
  return (
    <>
    <div style={{display:"flex",gap:"1rem",flexDirection:"column",height:"100%",
      alignItems:"center",boxSizing:"border-box",overflow:"auto"}}>
      <h1 style={{textAlign:"center",padding:"1rem"}}>{professionalCategory ? professionalSelected?.name : "My Financial Wellness Team"}</h1>
         {/* <FinanceProfessionalFilters/> */}
        {/* {professionalCategory &&
         <TabLine centered={true} tabList={professionalSelected?.coreExpertise} handleTabChange={(i)=>setTabIndex(i)} tabIndex={tabIndex}></TabLine>
        } */}
      <div style={{height:"auto",display:"flex",gap:"2rem",maxWidth:"1200px",flex:1,padding:"1rem",flexWrap:"wrap",justifyContent:"center"}}>
      {financeProfessionals.map((financeProfessional, index) => (
        <FinanceProfessionalCard cardData={financeProfessional} key={index} />
      ))}
      </div>
      <div style={{position:"sticky",bottom:"0px",padding:"1rem",paddingBottom:"2rem",display:"flex",alignItems:"center"
        ,justifyContent:"center",background:"white",zIndex:"1000",width:"100%",boxSizing:"border-box"}}>
      <MessageBarProfessional/>
      </div>
      
      </div></>
  )
}


const ProfessionalsAvailable = ({ endLabel="online now"}) => {
  const maxImages = 3;
  const data = [{name:"Mike",ImageURL:"https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cHJvZmVzc2lvbmFsfGVufDB8fDB8fHww"},
    {name:"Sara",ImageURL:'https://plus.unsplash.com/premium_photo-1661766386981-1140b7b37193?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8cHJvZmVzc2lvbmFsfGVufDB8fDB8fHww'},
    {name:"Maria",ImageURL:'https://plus.unsplash.com/premium_photo-1670884442927-e647436e12ff?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHByb2Zlc3Npb25hbHxlbnwwfHwwfHx8MA%3D%3D'},
    {name:"Sara",ImageURL:'https://plus.unsplash.com/premium_photo-1661766386981-1140b7b37193?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8cHJvZmVzc2lvbmFsfGVufDB8fDB8fHww'},
    {name:"Maria",ImageURL:'https://plus.unsplash.com/premium_photo-1670884442927-e647436e12ff?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHByb2Zlc3Npb25hbHxlbnwwfHwwfHx8MA%3D%3D'},
    {name:"Sara",ImageURL:'https://plus.unsplash.com/premium_photo-1661766386981-1140b7b37193?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8cHJvZmVzc2lvbmFsfGVufDB8fDB8fHww'},
    {name:"Maria",ImageURL:'https://plus.unsplash.com/premium_photo-1670884442927-e647436e12ff?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHByb2Zlc3Npb25hbHxlbnwwfHwwfHx8MA%3D%3D'},
  ]
  return (
    <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
      {data.slice(0, maxImages).map((obj, index) => (
        <div
          key={index}
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            overflow: "hidden",
            position: "relative",
            zIndex: maxImages - index,
            marginLeft: index > 0 ? "-10px" : "0",
            border: "2px solid white"
          }}
        >
          <img
            src={obj?.["ImageURL"]}
            alt="image"
            width="30px"
            height="30px"
            style={{ borderRadius: "50%" }}
          />
        </div>
      ))}
      {data.length > maxImages && (
        <div
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#ddd",
            color: "#333",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "14px",
            marginLeft: "-10px",
            zIndex: 10,
            border: "2px solid white" // Adds separation from previous image
          }}
        >
          +{data.length - maxImages}
        </div>
      )}

    </div>
  );
};




const OnlineStatus = ({ status="online"}) => {
  const isOnline = status === 'online';

  return (
    <Box display="flex" alignItems="center">
      <FiberManualRecordIcon
        style={{
          color: isOnline ? 'green' : 'gray',
          fontSize: '1rem',
          marginRight: '0.25rem',
        }}
      />
      <Typography
        variant="body2"
        style={{ color: isOnline ? 'green' : 'gray', fontWeight: 'bold' }}
      >
        7 {isOnline ? 'Online' : 'Offline'}
      </Typography>
    </Box>
  );
};
