import React, { useEffect } from 'react'
import ContactList from './ContactList'
import Chat from './Chat'
import { useAppInfo } from 'AppState'
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig/firebaseConfig";
import Chats from './Chats';

const ChatHome = () => {
  const {dispatchChats,stateApp,stateChats} = useAppInfo()
  const {chatting} = stateChats
  const {userDetails} = stateApp 

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", userDetails.uid), (doc) => {
        // console.log("Chat data: ", doc.data()); 
        dispatchChats({"chats":doc.data()});
      });

      return () => {
        unsub();
      };
    };

    if (userDetails?.uid){
      getChats()
    } 
  }, [userDetails]);



  return (
    <>
      <div style={{display:"flex",flexDirection:"column",height:"100%",width:"100%"
        ,maxWidth:"100%",padding:"0.25rem",boxSizing:"border-box"}}>
        <ContactList/>
        {!chatting && <Chats/>}
        {chatting && <Chat/>}
      </div>
    </>
  )
}

export default ChatHome