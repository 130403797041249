import { useRef } from 'react';

import { useAppInfo } from 'AppState';
import { defaultFieldBlank } from 'appState/initialStateMyFinancials';
import useCheckNewGoalSuccessFailure
  from 'myFinancials/Goals/useCheckNewGoalSuccessFailure';
import {
  getNavLinksByCategory,
  navLinkDict,
} from 'nav/navLinks';
import { generateUniqueId } from 'utils/utilGeneral';

export default function useAssistantFinancialAdvisor() {
        const {stateMyFinancials,statePortfolio,dispatchMyFinancials,dispatchAssistant} = useAppInfo()
        const {processGoalDetails} = useCheckNewGoalSuccessFailure()
        const {cashflowDistribution,goalsPeriodic} = stateMyFinancials  
        const {netGoalCashflowDist,netGoalAferWithdrawal} = cashflowDistribution

        function GetMyGoals() {
            const {goalsPeriodic,goalAnalytics} = stateMyFinancials
            const {
              goalSuccessRateBeforeWithdrawal,
              goalSuccessRateAfterWithdrawal,
          } = goalAnalytics

            const info = {goalsPeriodic,
              "goal success rate before withdrawing investment/savings"
              :goalSuccessRateBeforeWithdrawal,
              goalSuccessRateAfterWithdrawal,
              "learn_more":navLinkDict?.Goals?.path
              ,"react_component":"GoalSuccess"}
            return info
          }

        function GetRiskTolerance() {
            const {riskTolerance,investmentPreference} = stateMyFinancials


            const info = [riskTolerance,investmentPreference,{"react_component":"RiskTolerance"}]
            return info
          }
    
        function GetMyFinancialHealth() {
            const {financialHealthIssues,
                financialWellnessScore,financialHealthDetails} = stateMyFinancials
            
            const links = getNavLinksByCategory({category:"MyFinancials"})
           
            const info = [financialWellnessScore,financialHealthIssues,
              financialHealthDetails,
              {"react_component":"FinancialHealth","learn_more":links}]
            return info
          }
    
        function GetMyFinancials() {
            const {currency,summaryStats,assets,liabilities,incomeMonthly,expenseMonthly,financialHealthIssues,
                financialWellnessScore,goalsPeriodic} = stateMyFinancials
            const info = {currency,summaryStats,goalsPeriodic,assets,liabilities,
              incomeMonthly,expenseMonthly,financialHealthIssues,
              financialWellnessScore,
              "react_component":"FinancialOverview",}
            return info
          }
        
        function GetMyAssets() {
            const {currency,assets} = stateMyFinancials
            const info = {currency,assets,
              "react_component":"AssetDetails",}
            return info
          }
    
          function GetMyLiabilities() {
            const {currency,liabilities} = stateMyFinancials
            const info = {currency,liabilities,
              "react_component":"LiabilityDetails",}
            return info
          }
    
          function GetMyCashflow() {
            const {currency,  incomeMonthly,
              expenseMonthly,summaryStats} = stateMyFinancials
              
            const info = [
              currency,
              summaryStats,
              expenseMonthly,
              incomeMonthly,
              {"learn_more":"/MyFinancials/Cashflow"},
              {"react_components":["NetCashflow","NetCashAllocate"]}
            ]

            return info
          }

          function GetMyIncome() {
            const {currency,  incomeMonthly} = stateMyFinancials
              
            const info = [
              {details:incomeMonthly},
              {"learn_more":"/MyFinancials/Cashflow"},
              {"react_components":"Income"}
            ]
            return info
          }
          
          function GetMyExpense() {
            const {currency,  expenseMonthly} = stateMyFinancials
              
            const info = [
              currency,
              expenseMonthly,
              {"learn_more":"/MyFinancials/Cashflow"},
              {"react_components":"Expenses"}
            ]
            return info
          }
          const newGoalDeatils = useRef()
          newGoalDeatils.current = []
          // console.log("NewGoal Details")
          async function GetNewGoals({message,react_component,goal_type,
            goal_name,amount,start_date,period_frequency,forecast_period,inflation}) {
            const {currency,summaryStats,goalsPeriodic,assets,liabilities,
              incomeMonthly,expenseMonthly,financialHealthIssues,
              financialWellnessScore,} = stateMyFinancials
            // const {currency,  expenseMonthly} = stateMyFinancials
            // console.log(goal_type,
            //   goal_name,amount,start_date,period_frequency,forecast_period)
            const uniqueId = generateUniqueId()
            const newGoal = {...defaultFieldBlank?.Goals}
            newGoal.id = uniqueId
            newGoal.name = goal_name
            newGoal.type = goal_type
            newGoal.amount = amount
            newGoal.forecastPeriod = forecast_period
            newGoal.growthRate = inflation
            newGoal.period = period_frequency
            newGoalDeatils.current = [...newGoalDeatils.current,newGoal]
            // console.log("newGoal",newGoal)
            dispatchAssistant({
              type: 'UPDATE_GOAL',
              payload: {
                componentProps: newGoal,
                message: {
                  type: 'goal_update',
                  content: `Processing new goal: ${goal_name}`
                }
              }
            })
            // const webSearch = await getWebSearchTavily(message)
           
            const {perGoalDetails} = processGoalDetails([newGoal],netGoalCashflowDist,netGoalAferWithdrawal)
            
            const info = {
              react_id: uniqueId,
              introduction: "",
              react_component: "NewGoal",
              currency,
              summaryStats,
              goalsPeriodic,
              assets,
              liabilities,
              incomeMonthly,
              expenseMonthly,
              financialHealthIssues,
              financialWellnessScore,
              goal_details: {
                name: goal_name,
                type: goal_type,
                amount: amount,
                period: period_frequency,
                forecast_period: forecast_period,
                inflation: inflation
              }
            };
            
            if (perGoalDetails && perGoalDetails.length > 0) {
              const { successRateBeforeWithdrawal, successRateAfterWithdrawal } = perGoalDetails[0];
              
              info.success_rates = {
                before_withdrawal: successRateBeforeWithdrawal,
                after_withdrawal: successRateAfterWithdrawal
              }
              
              if (successRateBeforeWithdrawal > 80) {
                info.introduction = `Based on your current financial situation, you have a ${successRateBeforeWithdrawal}% chance of achieving your goal "${goal_name}" through monthly savings alone.`;
                info.recommendation = "Continue with your current savings plan to meet this goal.";
              } else {
                if (successRateAfterWithdrawal === 0) {
                  info.introduction = `This goal "${goal_name}" appears to be challenging with your current financial situation (0% success rate).`;
                  info.recommendation = "Consider adjusting the goal amount or timeline, or explore ways to increase your savings rate.";
                } else if (successRateAfterWithdrawal > 80) {
                  info.introduction = `You have a ${successRateAfterWithdrawal}% chance of achieving "${goal_name}" by utilizing both savings and existing investments.`;
                  info.recommendation = "Consider optimizing your investment strategy to minimize withdrawals while meeting this goal.";
                } else {
                  info.introduction = `The success rate for "${goal_name}" is ${successRateAfterWithdrawal}% even with investment withdrawals.`;
                  info.recommendation = "This goal may need to be revised or broken down into smaller, more achievable milestones.";
                }
              }
            }
          
    
            return info
          }


        return {
          GetMyGoals,
          GetMyFinancialHealth,
          GetMyFinancials,
          GetMyAssets,
          GetMyLiabilities,
          GetMyCashflow,
          GetMyExpense,
          GetMyIncome,
          GetRiskTolerance,
          GetNewGoals,
          }
        
}
