import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal/Modal';
import { Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useAppInfo } from 'AppState';
import { Add } from '@mui/icons-material';
import { groupedNavLinks } from 'nav/menu';
import useUpdateSettings from 'data/user/settings/useUpdateSettings';


export default function LeftSidebarMainSelectMenu({ open }) {
  
  const {stateSidebar,dispatchSidebar} = useAppInfo()
  const {navLinksSelected} = stateSidebar
  const [openModal, setOpenModal] = useState(false);
  const {UpdateSettings} = useUpdateSettings()
  
  const handleSelect = (item) => {
    const navLinksSelectedUpdate = navLinksSelected.some((navItem) => navItem === item.link)
      ? navLinksSelected.filter((navItem) => navItem !== item?.link) // Remove item if selected
      : [...navLinksSelected, item?.link]; // Add item if not selected
  
    dispatchSidebar({ navLinksSelected: navLinksSelectedUpdate });
    UpdateSettings({data:navLinksSelectedUpdate,dataName:"navLinkSelected"})
    // setSelectedNavItems(navLinksSelectedUpdate);
  };

  useEffect(()=>{
    if(open) {setOpenModal(true)}
  },[open])

  const categories = Object.keys(groupedNavLinks).filter((category) => !['Autonomy'].includes(category));
  return (
    <>
     <Button onClick={()=>setOpenModal(true)} style={{width:"100%",padding:"0.5rem",boxSizing:"border-box",minWidth:0}}><Add/></Button>

    {openModal && 
    <Modal onClose={() => setOpenModal(false)} sx={{minWidth:"500px",width: 'max-content', height: '80%',padding:"0"}}>
      <div style={{ display: 'flex', 
        flexDirection: 'column',padding:"1rem 3rem",gap: '1rem'
        ,height:"100%",overflow:"auto",boxSizing:"border-box",borderRadius:"10px"}}>
        <h1 style={{fontSize: '1.5rem' }}>Select Menu</h1>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', flex: 1 }}>
          {categories.map((category, catIndex) => (
            <div key={catIndex} style={{ padding: !['Home', 'AI'].includes(category) && '1rem 0' }}>
              {/* Category Header */}
              {!['Home', 'AI'].includes(category) && (
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: 'gray',
                    marginBottom: '0.5rem',
                    fontSize: '0.7rem',
                  }}
                >
                  {category}
                </Typography>
              )}

              {/* Links */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                {groupedNavLinks[category].map((option, index) => (
                  <div
                    key={index}
                    style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                  >
                    {!['Home'].includes(option.name) && (
                      <div style={{ display: 'flex', alignItems: 'center',gap:"1rem"}}>
                         <Checkbox
                            // sx={{ color: 'white' }}
                            checked={navLinksSelected.some(
                              (item) => item === option?.link
                            )}
                            onChange={() => handleSelect(option)}
                          />
                        <div style={{ display: 'flex', alignItems: 'center',gap:"0.25rem"}}>
                        {option?.icon}
                        <Typography
                            style={{
                              fontSize: '0.85rem',
                            }}
                          >
                            {option.name}
                          </Typography>
                          </div>
                    </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
        }
            </>
  );
}

