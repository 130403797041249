import React, { useEffect } from 'react';

import useIndSecDetails from 'analytics/industry/useIndSecDetails';
import AssetModal from 'analytics/ticker/AssetModal';
import useSetStockInfo from 'analytics/ticker/hooks/useSetStockInfo';
import useSetTickerInfo from 'analytics/ticker/hooks/useSetTickerInfo';
import AppNotification from 'AppNotification';
import { useAppInfo } from 'AppState';
import ExploreQuestionsModal from 'chatGPT/ExploreQuestionsModal';
import TSAssistant from 'chatGPT/TSAssistant';
import useHandleUserEvents from 'chatGPT/useHandleUserEvents';
import NoTickerInfoModal from 'components/NoTickerInfoModal';
import useSecIndList from 'data/industry/useSecIndList';
import useFindLearnTopics from 'data/resource/useFindLearnTopics';
import useScreenerCrypto from 'data/screener/useScreenerCrypto';
import useFindChatAI from 'data/user/chatAI/useFindChatAI';
import useFindReport from 'data/user/reports/useFindReport';
import useAddDefaultSettings from 'data/user/settings/useAddDefaultSettings';
import useFindSettings from 'data/user/settings/useFindSettings';
import useAddDefaultWatchlist from 'data/user/watchlist/useAddDefaultWatchlist';
import DemoAccounts from 'myFinancials/Demo/DemoAccounts';
import useCheckAlertsMyFinancials
  from 'myFinancials/hooks/useCheckAlertsMyFinancials';
import useSetMyFinancials from 'myFinancials/hooks/useSetMyFinancials';
import useSetPortfolio from 'myFinancials/hooks/useSetPortfolio';
import useCheckCreateTodos from 'myFinancials/Todos/useCheckCreateTodos';
import NavNew from 'nav/NavNew';
import AddPeersModal from 'peerList/AddPeersModal';
import {
  Outlet,
  useLocation,
} from 'react-router-dom';
import LeftSidebarMain from 'sidebar/LeftSidebar/LeftSidebarMain';

import useSetMarketToday from './analytics/market/useSetMarketToday';
import ChatNotfication from './chat/ChatNotification';
import useScreenerStocks from './data/screener/useScreenerStocks';
import useFindWatchlist from './data/user/watchlist/useFindWatchlist';
import usePeerFilter from './peerList/usePeerFilter';
import SearchModal from './search/SearchModal';
import RightSidebar from './sidebar/RightSidebar/RightSidebar';
import SidebarRightContent from './sidebar/RightSidebar/SidebarRightContent';
import useKeyboardShortcuts from './utils/useKeyboardShortcuts';
import WatchlistCreateModal from './watchlist/WatchlistCreateModal';

export default function AppLayoutDashboard() {

  const {stateSidebar,stateAssistant,dispatchSidebar,stateApp} = useAppInfo()
  const location = useLocation();
  const { pathname } = useLocation();
  const pageName = location.pathname.split("/")[1]
  const subPage = location.pathname.split("/")[2]
  const pageHome = ["Home",""].includes(pageName);
  const pageAbout = /(\/|^)(About|Sitemap|Pricing|Features|Contact|Product|Terms|Disclaimer|Support|Profile)(\/|$)/i.test(pageName);
  const { sidebarLeftExpanded } = stateSidebar;
  const {chats} = stateAssistant
  const userRole = stateApp?.onboarding?.userRole
  const {handleNewChat} = useHandleUserEvents()
  useSetMyFinancials()
  useSetPortfolio()  
  useFindChatAI()
  useFindSettings()
  useAddDefaultSettings()
  useCheckCreateTodos()
  // useTickerInfo()
  useSetTickerInfo()
  useSetStockInfo()
  useSetMarketToday()
  
  useScreenerCrypto()
  
  //industrySector
  useSecIndList();
  useIndSecDetails()
  useFindWatchlist();
  useAddDefaultWatchlist()
  usePeerFilter();
  useScreenerStocks();
  useFindReport()

  useCheckAlertsMyFinancials()
  useFindLearnTopics()
  useKeyboardShortcuts();

  useEffect(()=>{
    handleNewChat()
  },[userRole])

  useEffect(() => {
    const contentWrapper = document.getElementById('contentWrapper');
    if (contentWrapper) {
      contentWrapper.scrollTo(0, 0);
    }
    window.scrollTo(0,0)
  }, [pathname]);

  useEffect(()=>{
    if (chats.length > 0 && pageName === "AskAI"){
      dispatchSidebar({ sidebarLeftExpanded: false})
    }

    // else if (["Home",""].includes(pageName)){
    //   dispatchSidebar({ sidebarLeftExpanded: true })
    // }
  },[pageName,chats])


  const excludedAIPages = ["Social", "ReportBuilder", "AskAI", "AskProfessional"];

  const conditionAI = excludedAIPages.every(
  (item) => pageName !== item && subPage !== item
);

  
  return (
        <>
          

          <div style={{display:"flex",width:"100%",height:"100%"}}>

          <LeftSidebarMain/>

            <div className="appContentContainer" 
              style={{
              marginRight:!stateSidebar.showSidebarRight && 0,  
              marginLeft:(stateSidebar.sidebarLeftExpanded) && 0,
              width:(stateSidebar.sidebarLeftExpanded) && "auto",minWidth:(stateSidebar.sidebarLeftExpanded) && 0}}>
              
              <div id="appContent" className="appContent">
              {!pageAbout &&
              <NavNew/>}
                <div id="contentWrapper" className="contentWrapper">           
                  <Outlet/>
                 </div>
                 
                 {(!pageAbout && conditionAI) && <TSAssistant/>}
              </div>
              
              {(!pageAbout && stateSidebar.sidebarOpen && stateSidebar.showSidebarRight) && 
              <SidebarRightContent />}
            </div>
            {<RightSidebar />}

            <AssetModal />
            <NoTickerInfoModal/>
            <SearchModal/>
            <AddPeersModal/>
            <WatchlistCreateModal/>
            <AppNotification/>
            <ChatNotfication/>
            <ExploreQuestionsModal/>
            <DemoAccounts/>
            </div>
      </>
  );
}

