import { useAppInfo } from 'AppState';
import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function useAutomateEmail() {
    const { stateApp} = useAppInfo();
    const {userId} = stateApp;
    const [isConnected, setIsConnected] = useState(false);
     const [provider, setProvider] = useState('');
    
    
    
    const checkConnection = async () => {
        try {
          const response = await axios.post(`http://localhost:8000/check-email-connection/`,{
            userId
          });
          if (response.data.connected) {
            setIsConnected(true);
            setProvider(response.data.provider);
          } else {
            setIsConnected(false);
          }
        } catch (error) {
          setIsConnected(false);
        }
      };
    
      useEffect(() => {
        if (userId) {
          checkConnection();
        }
      
      }, [userId]);
  return {isEmailConnected:isConnected,emailProvider:provider}
}


export const emailAutomateForm = {
    recipient: '',
    subject: '',
    message: '',
    isHtml: false, // Flag to indicate if the message is HTML
    consent: false,
  }

  export const handleSendAutoEmail = async (userId, formData) => {
    try {
      const response = await axios.post(`http://localhost:8000/send-email/`, {
        userId, ...formData,
      });
  
      if (response.status === 200) {
        return 'Email sent successfully!';
      } else {
        return 'Failed to send email.';
      }
    } catch (error) {
      console.error('Error sending email:', error);
      return 'Failed to send email.';
    }
  };