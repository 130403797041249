import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import Modal from 'components/Modal/Modal';
import { deleteMediaFile } from 'components/Upload/processMedia';
import { DragAndDropMedia } from 'components/Upload/UploadMedia';
import useAddFinancialVault
  from 'data/user/financialVault/useAddFinancialVault';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import FinancialVaultTableUpload from './FinancialVaultTable';

const today = new Date()
export default function FinancialVaultUpload(){
    const location = useLocation()
    const subPage = location.pathname.split('/')[2];
    const subPageName = decodeURIComponent(subPage)
    const {dispatchMyFinancials,stateMyFinancials}  = useAppInfo()
    const {vaultData,vaultLoaded} = stateMyFinancials
    const [mediaItems, setMediaItems] = useState([]);
    const [uploadedMediaItems, setUploadedMediaItems] = useState([]);
    const [uploadModal,setUploadModal] = useState(false)
    const { addFinancialVault, error } = useAddFinancialVault()
    const [mouseEnter,setMouseEnter] = useState(false);
    const [change,setChange] = useState(false)

    useEffect(() => {
        if (vaultData.length>0 && vaultLoaded) {
          setMediaItems(vaultData)
        }
      },[vaultLoaded])

    
    
    const handleFileData = (file) => {
        // console.log("File Upload",file)
        // Add new media item for each selected file with initial preview URL and type
        setUploadModal(true)
        // if (!subPage){
        // setUploadModal(true)}
        // if (!subPage) {
        // setTabIndex(1)}
        
        setMediaItems(prevItems => {
          let updatedMediaItems = [...prevItems,
          { 
            id: file?.id || file.name, // Unique identifier
            category: subPage ? subPageName : "Others",
            subCategory: "Others",
            date:today,
            name: file.name,
            url: file?.url, 
            fileType: file.type,
            progress:0
          }
        ]; 
        dispatchMyFinancials({'vaultData':updatedMediaItems})
        return updatedMediaItems;});
        setUploadedMediaItems(prevItems => [
          ...prevItems,
          { 
            id: file?.id || file.name, // Unique identifier
            category: subPage ? subPageName : "Others",
            subCategory: "Others",
            date:today,
            name: file.name,
            url: file?.url, 
            fileType: file.type,
            progress:0
          }
        ])
      };
    
      const handleProgress = (file) => {
        const progress = file.percent;
        // console.log("progress",progress,file)
        // setMediaItems(prevItems => {
        //   let updatedMediaItems = prevItems.map(item =>
        //     item.id === file.id ? { ...item, progress } : item
        //   ); 
        //   dispatchMyFinancials({'vaultData':updatedMediaItems})
        //   return updatedMediaItems;}
        // );
    
        // setUploadedMediaItems(prevItems =>
        //   prevItems.map(item =>
        //     item.id === file.id ? { ...item, progress } : item
        //   )
        // );
      };
    
      const debouncedAddFinancialVault = useCallback(
        debounce((payload) => {
          addFinancialVault(payload);
        }, 1000), // Adjust debounce delay as needed
        []
      );
    
    
      // Clean up the debounce function on unmount
      useEffect(() => {
        return () => {
          debouncedAddFinancialVault.cancel();
        };
      }, [debouncedAddFinancialVault]);
    
      const handleUploadSuccess = (file) => {
        const url = file?.url;
        // console.log("File",file)
        // Use a temporary variable to store updated items
        let updatedMediaItems;
        
        setMediaItems(prevItems => {
          updatedMediaItems = prevItems.map(item =>
            item.id === file?.id ? { ...item, url: url, progress: 100 } : item
          );
          // console.log("Updated",updatedMediaItems)
          dispatchMyFinancials({'vaultData':updatedMediaItems})
          if (updatedMediaItems && updatedMediaItems.length > 0) {
            addFinancialVault({data:updatedMediaItems});}
          return updatedMediaItems; // Return updated items to set the state
        })

        setUploadedMediaItems(prevItems =>
          prevItems.map(item =>
            item.id === file?.id ? { ...item, url: url, progress: 100 } : item
          )
        );
        // if (updatedMediaItems && updatedMediaItems.length > 0) {
        // console.log("Add Vault",updatedMediaItems);
        // addFinancialVault({data:updatedMediaItems});}
        // Safely use updatedMediaItems here
      };
      const handleDeleteSuccess = () => {
        // onUploadSuccess && onUploadSuccess({url,fileType:file.type});
      }

      function handleDelete(item) {
        const mediaItemUpdate = mediaItems.filter(mediaItem => mediaItem.id !== item.id);
        setMediaItems(mediaItemUpdate);
        
        deleteMediaFile({fileUrl:item.uploadedUrl,previewUrl:item.previewUrl,onSuccess:handleDeleteSuccess})
        addFinancialVault({data:mediaItemUpdate});
        // handleDeleteFile && handleDeleteFile(item.uploadedUrl)
      }

      
  const handleChange = (fieldName, value,id) => {
    let updatedMediaItems;
    setMediaItems((prevItems) =>{
      updatedMediaItems = prevItems.map((item,i) =>
        item.id === id ? { ...item, [fieldName]: value } : item
      )
      dispatchMyFinancials({vaultData:updatedMediaItems})
      return updatedMediaItems
    }
    );
    
    // console.log("Updated Media Items",updatedMediaItems)
    setUploadedMediaItems((prevItems) =>prevItems.map((item,i) =>
          item.id === id ? { ...item, [fieldName]: value } : item
        )
      );

    debouncedAddFinancialVault({
      dataId: id,
      dataKey: fieldName,
      updateType: "update_by_key",
      data: value,
    });
  };

    return ( 
    <div 
    // className='card-hover'
    onPointerEnter={()=>setMouseEnter(true)}
    onPointerLeave={()=>setMouseEnter(false)}
    style={{flex:1,display:"flex",background:"#f0f8ff"
    ,borderRadius:"5px",height:"100%",padding: '1rem'
    ,boxSizing:"border-box",justifyContent:"center",cursor:"pointer"}}>
      
      {uploadModal  && 
      <Modal onClose={()=>setUploadModal(false)} height="70%" width="80%">
      <div style={{width:"100%",height:"100%",overflow:"auto",display:"flex",flexDirection:"column",gap:"1rem"}}>
      <div>
      <h2>Financial Vault Upload</h2>
      {!subPage && <h4>Select folder where you want to upload</h4>}
      </div>
      <FinancialVaultTableUpload 
      showFolder={true} mediaItems={uploadedMediaItems} handleChange={handleChange} 
      handleDelete={handleDelete}/></div>
      </Modal>}

      <DragAndDropMedia
      mediaBaseUrl = "user"
      mediaFolder = "vault"
    handleFileData={handleFileData}
    handleProgress={handleProgress}
    handleUrl={handleUploadSuccess}
    >
    
      <div style={{display:"flex"
        ,gap:"1rem",alignItems:"center",height:"100%"}}>
         <CloudUploadIcon sx={{color:mouseEnter? "var(--selectBlue)":"gray"}}/>   
         <h4 style={{maxWidth:"500px",color:mouseEnter? "var(--selectBlue)":"gray"}}>Upload Documents</h4>
        {/* <Button variant="text" role={undefined}>
          Upload
        </Button> */}
    
    </div>
    </DragAndDropMedia>
    </div>)
  }

