import React, { useEffect } from 'react';

import { useAppInfo } from 'AppState';
import NoFinancialData from 'myFinancials/Template/NoFinancialData';
import PageComingSoon from 'pages/PageComingSoon';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import AssetAllocation from './AssetAllocation';
import PortfolioAssetAnalytics from './PortfolioAssetAnalytics';
import PortfolioHoldingSenate from './PortfolioHoldingSenate';
import PortfolioOverview from './PortfolioOverview';
import PortfolioOverviewHome from './PortfolioOverviewHome';
import PortfolioVsBenchmark from './PortfolioVsBenchmark';
import PortfolioCurrentPositions
  from './PositionsAndTransactions/PortfolioCurrentPositions';
import PortfolioTransactions, {
  PortfolioTransactionsAll,
  PortfolioTransactionsTicker,
} from './PositionsAndTransactions/PortfolioTransactions';

export default function PortfolioInfoList({menu,subMenu}) {
  const {statePortfolio,dispatchPortfolio} = useAppInfo()
  const {portfolioSel,portfolioLoaded,portfolioDefaultLoaded,addTransaction,portfolio} = statePortfolio
  const {navigate} = useNavigate()
  const location = useLocation()
  const page = location.pathname.split("/")[1]


  const infoList = {
    "Overview":<PortfolioOverview/>,
    "Overview Home":<PortfolioOverviewHome/>,
    "Current Positions":<PortfolioCurrentPositions/>,
    "Transactions":<PortfolioTransactions/>,
    "Transactions All":<PortfolioTransactionsAll uiType='trade'/>,
    "Transaction Ticker":<PortfolioTransactionsTicker type='ticker'/>,
    "Current Position Ticker":<PortfolioCurrentPositions positionType='Ticker'/>,
    "Asset Allocation":<AssetAllocation/>,
    "Portfolio vs Benchmark":<PortfolioVsBenchmark/>,
    "Asset Analytics":<PortfolioAssetAnalytics info={subMenu}/>,
    "Report":<PageComingSoon name={"Insights"}/>,
    "Insights":<PageComingSoon name={"Insights"}/>,
    "Holdings - Senate":<PortfolioHoldingSenate/>,
  }

  useEffect(()=>{
    if ( statePortfolio.portfolio){
    const tDetailAccount = statePortfolio.portfolio[statePortfolio.accountNameSel]
  
    if (tDetailAccount){
    dispatchPortfolio({"portfolioSel":tDetailAccount})
  }
  
  }
  },[statePortfolio.portfolio,statePortfolio.accountNameSel])

  
  return (
    <div style={{position:"relative",height:page==="Portfolio"?"100%":"auto",width:"100%",minHeight:"50vh"}}>
    <NoFinancialData financialType="portfolio"/>
    {(portfolioLoaded && portfolioDefaultLoaded && portfolioSel.length > 0) && 
    infoList[menu]
    }
    </div>
  )
}
