import React from 'react';

import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable';
import TableGen from 'components/Table/TableGen';
import AddPeersBar from 'peerList/AddPeersBar';

export default function TrendTable({data,marketInfoLoading,tickerDict,tickerPrice,addTicker=false}) {

  return (
    <div style={{display:"flex",flexDirection:"column",width:"100%",height:"100%"}}>
    {marketInfoLoading && <LoadingSkeletonTable length={10}/>}
    {!marketInfoLoading && 
        <TableGen tickerPrice={tickerPrice} height='auto' data={data} tickerDict={tickerDict} />}
    {addTicker && <AddPeersBar/>}
    </div>
  )
}
