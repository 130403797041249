import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import SelectBox from 'components/Inputs/SelectBox';
import useAddFinancials from 'data/user/financials/useAddFinancials';
import { generateUniqueId } from 'utils/utilGeneral';

import {
  Delete,
  Edit,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { categoryTodos } from './utils';

export default function AddTodo({editOperation="add",variant='text'}) {

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        variant={variant}
        onClick={()=>setOpenModal(true)}
        style={{ textTransform: 'none', fontSize:"0.9rem",minWidth:"max-content"}}
        startIcon={<AddIcon/>}
      >
         To-do
      </Button>
      <TodoCRUDModal editOperation={editOperation} open={openModal} onClose={()=>setOpenModal(false)}/>
    </>
  );
}

export function EditTodo({data,editable}) {

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        disabled={!editable}
        variant='text'
        onClick={()=>setOpenModal(true)}
        style={{ textTransform: 'none', width: "max-content" }}
      >
        <Edit fontSize='small' style={{ marginRight: '5px' }} />
      </Button>
      <TodoCRUDModal editOperation={"edit"} data={data} open={openModal} onClose={()=>setOpenModal(false)}/>
    </>
  );
}


export function DeleteTodoButton({data,allowDelete=true}) {
  const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
  const {addFinancials} = useAddFinancials()
  const {todos} = stateMyFinancials
  function handleDelete(){
    const updatedTodo = DeleteTodo({dataAll:todos,data})
    dispatchMyFinancials({todos:updatedTodo})
    addFinancials(updatedTodo,"todos")
  }

  return (
    <>
      <Button
        disabled={!allowDelete}
        variant='text'
        onClick={handleDelete}
        style={{ textTransform: 'none',minWidth:0, width: "max-content" }}
      >
        <Delete fontSize='small' style={{ marginRight: '5px'}} />
      </Button>
    </>
  );
}



export function TodoCRUDModal({editOperation="add",onClose,open,data,editData=true}){
  const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
  const {addFinancials} = useAddFinancials();
  const { todos } = stateMyFinancials;
  const [newTodo, setNewTodo] = useState({...todoDataInitial,id:generateUniqueId()});

  useEffect(() => {
    if (data) {
      setNewTodo(data);
    }
  }, [data]);

  const handleChange = (field, v) => {
    if (field ==="period" && v === "One Time") {
      setNewTodo({ ...newTodo, dateExpiry: dateExpiry,[field]: v });
    }
    if (field ==="period" && v != "One Time") {
      setNewTodo({ ...newTodo, dateExpiry: undefined,[field]: v });
    }
    else{
      setNewTodo({ ...newTodo, [field]: v });
    }
    
  };

  const handleSaveTodo = (e) => {
    e.preventDefault(); 

    // Build or clear the recurrence rule based on period
    const { period, dateStart, dateExpiry } = newTodo;

    const updatedTodo = {
      ...newTodo,
    };

    if (editOperation === "add") {
      const updatedData = AddNewTodo({ dataAll: todos, data: updatedTodo });
      dispatchMyFinancials({ todos: updatedData });
      addFinancials(updatedData,"todos")
    }
    if (editOperation === "edit") {
      const updatedData = UpdateTodo({ dataAll: todos, data: updatedTodo });
      
      dispatchMyFinancials({ todos: updatedData });
      addFinancials(updatedData,"todos")
    }

    setNewTodo({...todoDataInitial,id:generateUniqueId()});
    onClose();
  };


    return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <form onSubmit={handleSaveTodo} style={{ padding: '20px', fontFamily: 'Arial',display:"flex",flexDirection:"column",gap:"1rem"}}>
          <Typography variant="h6" style={{ marginBottom: '15px' }}>
            {editOperation==="add" ? "Add Todo" : "Edit Todo"}
          </Typography>
          
          <SelectBox
              disabled={!editData}
              required
              variant='outlined'
              value={newTodo.category}
              onChange={(e,v)=>handleChange('category',v)}
              label="Category"
              style={{ fontSize: '14px' }}
              options={categoryTodos}
                />
    
          <TextField
            disabled={!editData}
            label="Name"
            variant="outlined"
            value={newTodo.name}
            onChange={(e)=>handleChange('name',e.target.value)}
            placeholder="Enter the task name"
            required
            style={{ fontSize: '14px', width: '100%' }}
          />

          <SelectBox
              required
              variant='outlined'
              value={newTodo?.period}
              onChange={(e,v)=>handleChange('period',v)}
              label="Period Frequency"
              style={{ fontSize: '14px' }}
              options={["One Time","Daily","Weekly","Monthly","Quarterly","Semi-Annually","Annually"]}
                />
    
          <TextField
            label="Start"
            variant="outlined"
            type="datetime-local"
            value={formatDateTimeLocal(newTodo.dateStart)}
            onChange={(e)=>handleChange('dateStart',e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
            style={{ fontSize: '14px', width: '100%' }}
          />
       

        {/* Only show End field for One Time or if you want to allow a recurrence end */}
        {(newTodo.period === "One Time" || newTodo.period !== "One Time") && (
          <TextField
            label="End"
            variant="outlined"
            type="datetime-local"
            value={newTodo?.dateExpiry ? formatDateTimeLocal(newTodo?.dateExpiry) : ""}
            onChange={(e) => {
              const newEndDate = e.target.value;
              if (new Date(newEndDate) < new Date(newTodo.dateStart)) {
                 alert("End date cannot be before start date.");
              }
              else{
                handleChange('dateExpiry', e.target.value)
              }
            }
              
              }
            InputLabelProps={{ shrink: true }}
            style={{ fontSize: '14px', width: '100%' }}
            // end date can be optional if period != One Time, depending on your preference
            required={newTodo.period === "One Time"}
          />
        )}
         
          <FormControl variant="outlined" style={{ minWidth: '100%' }} required>
            <InputLabel>Priority</InputLabel>
            <Select
              value={newTodo.priority}
              onChange={(e)=>handleChange('priority',e.target.value)}
              label="Priority"
              style={{ fontSize: '14px' }}
            >
              <MenuItem value={1}>
                <WhatshotIcon style={{ color: '#ff5722' }} />
              </MenuItem>
              <MenuItem value={2}>
                <WhatshotIcon style={{ color: '#ff5722' }} />
                <WhatshotIcon style={{ color: '#ff5722' }} />
              </MenuItem>
              <MenuItem value={3}>
                <WhatshotIcon style={{ color: '#ff5722' }} />
                <WhatshotIcon style={{ color: '#ff5722' }} />
                <WhatshotIcon style={{ color: '#ff5722' }} />
              </MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button
              onClick={onClose}
              style={{ textTransform: 'none', background: '#eee', padding: '5px 10px' }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              style={{ textTransform: 'none', background: '#4caf50', color: 'white', padding: '5px 10px' }}
            >
              Save
            </Button>
          </div>
        </form>
      </Dialog>)
}


const pad = (num) => String(num).padStart(2, '0');
const formatDateTimeLocal = (dateObj) => {
  const date = new Date(dateObj);
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // getMonth() is zero-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};


export function AddNewTodo({dataAll,data}){
  const dataUpdate = [...dataAll,data]
  return dataUpdate
}

export function UpdateTodo({ dataAll, data }) {
    const dataUpdate = dataAll.map((obj) =>
      obj.id === data?.id ? { ...obj, ...data } : obj
    );
    return dataUpdate;
  }
export function DeleteTodo({dataAll,data}){
    const dataUpdate = dataAll && dataAll.filter(obj=>obj.id !== data?.id)
    return dataUpdate
}

export function DeleteAllTodo(){
 return []
}



const buildRRule = (period, dateStart, dateExpiry) => {
  if (!period || period === "One Time") return null;

  const startDate = new Date(dateStart);
  let freq;
  switch (period) {
    case "Daily":
      freq = "DAILY";
      break;
    case "Weekly":
      freq = "WEEKLY";
      break;
    case "Monthly":
      freq = "MONTHLY";
      break;
    case "Quarterly":
      // iCal doesn't have a direct QUARTERLY frequency; you can use MONTHLY with INTERVAL=3
      freq = "MONTHLY;INTERVAL=3";
      break;
    case "Annually":
      freq = "YEARLY";
      break;
    default:
      freq = null;
  }

  // If no frequency is set, just return null
  if (!freq) return null;

  // Construct the RRULE. If there's an expiration, use UNTIL.
  // UNTIL must be in UTC and in YYYYMMDDThhmmssZ format for strict RFC compliance
  let rule = `RRULE:FREQ=${freq}`;
  
  if (dateExpiry && period !== "One Time") {
    const endDate = new Date(dateExpiry);
    // Format end date for RRULE
    const year = endDate.getUTCFullYear();
    const month = String(endDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(endDate.getUTCDate()).padStart(2, '0');
    const hours = String(endDate.getUTCHours()).padStart(2, '0');
    const minutes = String(endDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(endDate.getUTCSeconds()).padStart(2, '0');
    const untilStr = `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
    rule += `;UNTIL=${untilStr}`;
  }

  return rule;
};

const dateStart = new Date();
const dateExpiry = new Date(dateStart.getTime() + 60 * 60 * 1000); // 1 hour later


export const todoDataInitial = {
  id: generateUniqueId(),
  owner:"user",
  edit:true,
  delete:true,
  category: '',
  name: '',
  description:"",
  actions:[],
  period: 'One Time',
  dateStart: dateStart,
  dateExpiry: dateExpiry,
  priority: 1,
  recurrenceRule: null, // new field for storing RRULE
}