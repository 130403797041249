import React from 'react';

import Animation from 'animation/Animation';
import { useAppInfo } from 'AppState';
import CollapseToggler from 'components/Buttons/CollapseToggler';
import LiquidChart from 'components/Charts/SVGLineChart';
import LoadingSkeletonPage from 'components/Loading/LoadingSkeletonPage';
import ProfileLogo from 'components/Logo/ProfileLogo';
import { menuIcons } from 'nav/menuIcons';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from 'utils/utilGeneral';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Button,
  Card,
} from '@mui/material';

import { demoAccounts } from './Demo/DemoAccounts';

export default function MyFinancialCardNew({expandItems=false}) {
  const { stateApp, dispatchSidebar, stateMyFinancials,statePortfolio} = useAppInfo();
  const {portfolioValue,portfolioLoaded} = statePortfolio
  const { financialView, demoProfile } = stateMyFinancials;
  const { username } = stateApp;
  const navigate = useNavigate();

  const demoProfileSelected = demoAccounts.filter(
    (account) => account.name === demoProfile
  )[0];

  return (
    <Card
      elevation={0}
      style={{
        width:"100%",
        padding: "1rem",
        boxSizing: "border-box",
        background: "#fafafa",
        // boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: "10px",
        minWidth: "400px",
       display:"flex",flexDirection:"column",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "0",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            padding: "0",
          }}
        >
          {financialView === "personal" && (
            <>
              <ProfileLogo />
              <h3 style={{ fontWeight: "bold" }}>{username}</h3>
            </>
          )}

          {financialView !== "personal" && (
            <>
              <Avatar src={demoProfileSelected?.img} />
              <div>
                <h5>Demo Profile</h5>
                <h3
                  style={{ fontWeight: "bold" }}
                >{`${demoProfileSelected?.name}`}</h3>
              </div>
            </>
          )}
        </div>
        <Button
          onClick={() => navigate("/Report/TSReport/FinancialsOverview")}
          style={{ minWidth: "0", display: "flex", flexDirection: "column" }}
        >
          {menuIcons?.Report}
          <span style={{ fontSize: "0.7rem" }}>Report</span>
        </Button>
      </div>
     
      <div
        style={{
          marginTop: "1.2rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "flex-start",
          width: "100%",
          flexWrap: "wrap",
          padding: "0",
          boxSizing: "border-box",
          paddingBottom:"0.5rem"
        }}
      >
        <MyFinancialsOverviewHomeNew initiallyAllExpanded={expandItems}/>
        
      </div>
      
     
    </Card>
  );
}





// A reusable component to format values.
function formatValue(value, { isCurrency, currency, isPercentage }) {
  if (!value) return "-";
  if (isCurrency) return `${currency} ${formatNumber(value)}`;
  if (isPercentage) return `${formatNumber(value)}%`;
  return value;
}

// A reusable component to display an info line.
const InfoLine = ({
  link,
  label,
  value,
  percentageValue,
  currency = "USD",
  isCurrency = false,
  isSuccess = null,
  isPercentage = false,
  showNav = false,
}) => {
  const navigate = useNavigate();
  const formattedValue = formatValue(value, { isCurrency, currency, isPercentage });
  const percentageValueFormatted = percentageValue && formatNumber(percentageValue);
  const color = isSuccess !== null ? (isSuccess ? 'green' : 'red') : 'black';

  return (
    <div
    onClick={() => navigate(`${link}`)}
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        width: "100%", 
        textAlign: "left", 
        padding: "0.05rem 0", 
        color: "black",
        gap:"0.2rem",cursor:"pointer",
        
      }}
    >
      
       <Button
          sx={{ minWidth: "0", padding: 0,color:"gray",opacity: 0}}
          role={undefined}
        >
          <ExpandMoreIcon />
        </Button>
      <span style={{flex:1,paddingLeft:"0.4rem"}}>{label}</span>
      <span style={{ color}}>{formattedValue}{percentageValueFormatted && ` (${percentageValueFormatted}%)`}</span>
      <ArrowOutwardIcon
          onClick={() => navigate(`${link}`)}
          style={{ 
            color: "var(--selectBlue)", 
            fontSize: "0.9rem", 
            paddingLeft: "0.5rem", 
            cursor: "pointer",opacity: showNav ? 1 : 0
          }}
        />
    </div>
  );
};

// A reusable category component for main categories with expandable sub-items.
const Category = ({
  label,
  value,
  currency,
  isCurrency = false,
  isSuccess = null,
  isPercentage = false,
  link = "",
  children,
  showNav = true,
  initiallyExpanded = false,
}) => {
  const [expanded, setExpanded] = React.useState(initiallyExpanded);
  const navigate = useNavigate();

  const formattedValue = formatValue(value, { isCurrency, currency, isPercentage });
  const color = isSuccess !== null ? (isSuccess ? 'green' : 'red') : 'black';

  return (
    <div style={{ borderRadius: "10px", display: "flex", flexDirection: "column", gap: "0.25rem"}}>
      <div style={{ display: "flex",gap: "0.2rem", alignItems: "center", cursor: "pointer", width: "100%" }}>
        {/* Expand/Collapse button */}
        {/* <Button
          sx={{ minWidth: "0", padding: 0,color:"gray"}}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button> */}
        <CollapseToggler onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }} isExpanded={expanded}/>
        {/* Main category line: label and value */}
        <div 
          style={{ 
            display: "flex", 
            flex: 1, 
            alignItems: "center" 
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <span style={{ flex: 1,fontWeight: 'bold'}}>{label}</span>
          <span style={{ color, fontWeight: 'bold'}}>{formattedValue}</span>
        </div>

        {/* Navigation icon */}
        {showNav && (
          <ArrowOutwardIcon
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${link}`);
            }}
            style={{ 
              color: "var(--selectBlue)", 
              fontSize: "0.9rem", 
              paddingLeft: "0.5rem", 
              cursor: "pointer" 
            }}
          />
        )}
      </div>

      {/* Children (sub-items) */}
      {expanded && children}
    </div>
  );
};
export const calculateTotal = (items, types) => 
  items.reduce((total, item) => types.includes(item.type) ? total + item.amount : total, 0);

export function MyFinancialsOverviewHomeNew({ initiallyAllExpanded = false }) {
  const { statePortfolio, stateMyFinancials } = useAppInfo();
  const { portfolioTotal,portfolioValue,portfolioLoaded,portfolio} = statePortfolio;
  const { summaryStats, currency, assets,cashflowDistribution} = stateMyFinancials;
  const {netCashflowDist,totalCashInflowDist,
    totalCashOutflowDist,networthDist} = cashflowDistribution
  const navigate = useNavigate()
  const beginningCashBalanace = calculateTotal(assets, ["Cash"])
  

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      {/* Net Worth Category */}
      <Category
        link="/MyFinancials/Networth"
        label="Net Worth"
        value={summaryStats?.netWorth}
        currency={currency?.symbol}
        isCurrency
        isSuccess={summaryStats?.netWorth > 0}
        initiallyExpanded={initiallyAllExpanded}
      >
        <InfoLine 
          link="/MyFinancials/Assets" 
          currency={currency?.symbol} 
          label="Assets" 
          value={summaryStats?.totalAssets} 
          isCurrency 
          isSuccess 
        />
        <InfoLine 
          link="/MyFinancials/Liabilities" 
          currency={currency?.symbol} 
          label="Liabilities" 
          value={summaryStats?.totalLiabilities} 
          isCurrency 
          isSuccess={false} 
        />
      </Category>

      {/* Cashflow Category */}
      <Category
        link="/MyFinancials/Cashflow"
        label="Net Cash (exp. month)"
        value={summaryStats?.totalNetCashflow?.m}
        currency={currency?.symbol}
        isCurrency
        isSuccess={summaryStats?.totalNetCashflow?.m >= 0}
        initiallyExpanded={initiallyAllExpanded}
      >
        <InfoLine 
          link="/MyFinancials/Cashflow"
          currency={currency?.symbol} 
          label="Cash balance" 
          value={beginningCashBalanace} 
          isCurrency 
          isSuccess 
        />
        <InfoLine 
          link="/MyFinancials/Cashflow"
          currency={currency?.symbol} 
          label="Income" 
          value={summaryStats?.totalIncome?.m} 
          isCurrency 
          isSuccess 
        />
        <InfoLine 
          link="/MyFinancials/Cashflow"
          currency={currency?.symbol} 
          label="Investment Return" 
          value={summaryStats?.totalAssetReturnDist?.m} 
          isCurrency 
          isSuccess 
        />
        <InfoLine 
          link="/MyFinancials/Cashflow" 
          currency={currency?.symbol} 
          label="Expense" 
          value={summaryStats?.totalExpense?.m} 
          isCurrency 
          isSuccess={false} 
        />
        <InfoLine 
          link="/MyFinancials/Cashflow"
          currency={currency?.symbol} 
          label="Liability Payments" 
          value={summaryStats?.totalLiabilityDist?.m} 
          isCurrency 
          isSuccess={false} 
        />
        
      </Category>

      {/* Portfolio Category */}
      <Category
        link="/Portfolio"
        label="Portfolio"
        value={portfolioTotal?.NAV}
        currency={currency?.symbol}
        isCurrency
        isSuccess={portfolioTotal?.NAV > portfolioTotal?.["Investment Cost"]}
        initiallyExpanded={true}
      >
        {/* <InfoLine 
          link="/Portfolio" 
          currency={currency?.symbol} 
          label="Investment Cost" 
          value={portfolioTotal?.["Investment Cost"]} 
          isCurrency 
          isSuccess={false} 
        /> */}
        <InfoLine 
          link="/Portfolio" 
          currency={currency?.symbol} 
          label="Unrealized P/L" 
          value={portfolioTotal?.["Profit/Loss"] || ""} 
          percentageValue={portfolioTotal?.["Profit/Loss"] ? (portfolioTotal?.["Profit/Loss"]/portfolioTotal?.["Investment Cost"]) * 100 : ""}
          isCurrency 
          isSuccess={portfolioTotal?.["Profit/Loss"] >= 0} 
        />
        {/* <InfoLine 
          link="/Portfolio" 
          currency={currency?.symbol} 
          label="Realized P/L" 
          value={portfolioTotal?.["Realized P/L"]} 
          isCurrency 
          isSuccess={portfolioTotal?.["Realized P/L"] >= 0} 
        /> */}
        <ChartBox>
        {!portfolioLoaded && <LoadingSkeletonPage/>}
    {portfolioLoaded && Object.keys(portfolio).length === 0 && 
    <div onClick={()=>navigate("/Portfolio")} style={{cursor:"pointer",width:"100%",height:"100%"
    ,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",background:"#f7f7f7",borderRadius:"10px",padding:"0.5rem",boxSizing:"border-box"}}>
<div style={{width:"80px",height:"80px"}}>
<Animation type="Portfolio" loop={false}/>
</div>
<Button sx={{color:"gray"}} onClick={()=>navigate("/Portfolio")} 
// startIcon={<PieChartIcon/>}

>

Add Portfolio</Button>
    </div>
   }
    {portfolioLoaded && portfolioValue.length>0 &&
    <LiquidChart tooltipEnabled animationType="line" color="green" data={portfolioValue.map(obj=>obj.Portfolio).filter(obj=>!isNaN(obj))}/>}
      </ChartBox>
      </Category>
    </div>
  );
}

function ChartBox({children}){
 return (
  <div style={{padding:"0rem",borderRadius:"10px"
    ,textAlign:"center",width:"100%",display:"flex"
    ,gap:"1rem",height:"120px",width:"100%"
    ,flexDirection:"column",gap:"1rem",justifyContent:"center",boxSizing:"border-box"}}>
    {children}
    </div>
 )
}
