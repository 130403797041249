import React from 'react';

import { useAppInfo } from 'AppState';
import TimeSeriesChart from 'components/Charts/TimeSeriesChart';
import LoadingSkeletonChart from 'components/Loading/LoadingSkeletonChart';

export default function PortfolioValue() {
  const {statePortfolio} = useAppInfo()
  const tOverview = statePortfolio.portfolioOverview
  const {portfolioValue,portfolioLoaded,portfolioDefaultLoaded} = statePortfolio

  return (
    <>
    {(!portfolioLoaded || !portfolioDefaultLoaded || portfolioValue.length === 0) && <LoadingSkeletonChart chartType='area'/>}
    {(portfolioLoaded && portfolioValue.length>0) && 
    <TimeSeriesChart seriesName='Portfolio'
    color={'green'}
    gradient={true}
    grid={false} 
    zoneRGseries={true}
    rgValueMin={tOverview && tOverview?.["Investment Cost"]}
     data={portfolioValue} marketInfoLoading={false}/>}

    </>
  );
}

