// Helper function to get a date relative to today in UTC format
const getRelativeDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date.toISOString();
};

// Action Types
export const ACTION_TYPES = {
    REVIEW: 'Review',
    SCHEDULE: 'Schedule Meeting',
    UPDATE: 'Update Docs',
    BOOKMARK: 'Bookmark',
    NOTIFY: 'Notify',
    REPORT: 'Build Report',
    DISCARD: 'Discard'
};

// Helper function to create action details
const createActionDetails = (type, description, steps) => ({
    type,
    description,
    steps: steps || []
});

// Helper function to create action sequence
const createActionSequence = (actions) => actions.map((action, index) => ({
    ...action,
    order: index + 1,
    status: action.status || 'Pending'
}));

// Helper function to get a random past date for lastCommunication
const getRandomPastDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - Math.floor(Math.random() * 365));
    return date.toISOString();
};

// Update the pulse data to match actual clients and their situations
export const pulseData = [
    {
        id: 1,
        type: 'Economy',
        timestamp: getRelativeDate(0),
        title: 'Fed Maintains Interest Rates Amid Tariff Uncertainty',
        details: 'The Federal Reserve has kept its benchmark interest rate unchanged at 5.25-5.50%, citing increased economic uncertainty due to recent tariff implementations. This decision impacts fixed-income portfolios and mortgage rates, with market analysts predicting potential rate cuts in Q3 2025.',
        impact: 'Low',
        priority: 'Normal',
        affectedClients: [
            { id: 1, name: 'Emma Thompson', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 4, name: 'Michael Anderson', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 10, name: 'Robert Taylor', portfolio: 'Conservative', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review fixed income portfolio allocations', status: 'Pending' },
            { type: 'Build Report', description: 'Generate interest rate sensitivity analysis', status: 'Pending' },
            { type: 'Schedule Meeting', description: 'Schedule portfolio strategy discussion', status: 'Pending' }
        ]
    },
    {
        id: 2,
        type: 'Economy',
        timestamp: getRelativeDate(1),
        title: 'Inflation Expected to Rise Due to Tariffs',
        details: 'The Federal Reserve anticipates that tariffs will drive inflation up to 2.7% by the end of 2025, surpassing the 2% target. Core inflation remains elevated at 2.4%, with shelter costs contributing 60% of the increase. This impacts purchasing power and investment strategies.',
        impact: 'High',
        priority: 'Urgent',
        affectedClients: [
            { id: 3, name: 'Sarah Chen', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() },
            { id: 4, name: 'Michael Anderson', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 10, name: 'Robert Taylor', portfolio: 'Conservative', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review inflation protection strategies', status: 'Pending' },
            { type: 'Update Docs', description: 'Update inflation risk documentation', status: 'Pending' },
            { type: 'Schedule Meeting', description: 'Schedule inflation strategy session', status: 'Pending' }
        ]
    },
    {
        id: 3,
        type: 'Regulatory',
        timestamp: getRelativeDate(1),
        title: 'New Tariff Regulations Impact Trade',
        details: 'Recent 25% tariff on imported autos and 10% on consumer goods are projected to raise costs across multiple sectors. Goldman Sachs estimates this could add 0.5% to inflation and reduce GDP growth by 0.3% in 2025.',
        impact: 'High',
        priority: 'Urgent',
        affectedClients: [
            { id: 4, name: 'Michael Anderson', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 10, name: 'Robert Taylor', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 20, name: 'Nathan Wright', portfolio: 'Conservative', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review trade exposure', status: 'Pending' },
            { type: 'Schedule Meeting', description: 'Discuss regulatory impact', status: 'Pending' }
        ]
    },
    {
        id: 4,
        type: 'Mortgage Update',
        timestamp: getRelativeDate(1),
        title: 'Bank of America Reports 80% Surge in Mortgage Applications',
        details: 'Bank of America experienced an 80% increase in mortgage applications from January to March 2025, attributed to increased housing inventory and lower long-term bond yields. The average 30-year fixed mortgage rate has decreased to 6.2%, creating refinancing opportunities.',
        impact: 'High',
        priority: 'Urgent',
        affectedClients: [
            { id: 5, name: 'David Park', portfolio: 'Mortgage', lastCommunication: getRandomPastDate() },
            { id: 8, name: 'Thomas Martinez', portfolio: 'Moderate', lastCommunication: getRandomPastDate() },
            { id: 12, name: 'Jennifer Lee', portfolio: 'Income', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review mortgage portfolio opportunities', status: 'Pending' },
            { type: 'Schedule Meeting', description: 'Discuss refinancing options', status: 'Pending' },
            { type: 'Build Report', description: 'Generate mortgage market analysis', status: 'Pending' }
        ]
    },
    {
        id: 5,
        type: 'Insurance Alert',
        timestamp: getRelativeDate(1),
        title: 'Homeowners Face Rising Insurance Premiums',
        details: 'Homeowners insurance prices have surged by 74% since the Great Recession, outpacing the 40% rise in home prices after adjusting for inflation. Climate change impacts and increased construction costs are driving premiums higher, with some regions seeing 20% year-over-year increases.',
        impact: 'High',
        priority: 'Normal',
        affectedClients: [
            { id: 6, name: 'Linda Chen', portfolio: 'Real Estate', lastCommunication: getRandomPastDate() },
            { id: 9, name: 'James Wilson', portfolio: 'Property', lastCommunication: getRandomPastDate() },
            { id: 14, name: 'Sophie Chen', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review insurance coverage adequacy', status: 'Pending' },
            { type: 'Schedule Meeting', description: 'Discuss insurance cost impact', status: 'Pending' },
            { type: 'Update Docs', description: 'Update insurance documentation', status: 'Pending' }
        ]
    },
    {
        id: 6,
        type: 'Mortgage Update',
        timestamp: getRelativeDate(1),
        title: 'Potential Privatization of Fannie Mae and Freddie Mac',
        details: 'Investors are optimistic about the mortgage giants exiting government conservatorship, but experts caution that privatization could lead to higher mortgage rates due to the loss of government guarantees. Moody\'s estimates this could increase mortgage rates by 0.5-1% and reduce home sales by 5-10%.',
        impact: 'Medium',
        priority: 'Normal',
        affectedClients: [
            { id: 7, name: 'Robert Chen', portfolio: 'Growth', lastCommunication: getRandomPastDate() },
            { id: 11, name: 'Maria Lopez', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 16, name: 'Michael Zhang', portfolio: 'Retirement', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review mortgage exposure', status: 'Pending' },
            { type: 'Build Report', description: 'Generate privatization impact analysis', status: 'Pending' },
            { type: 'Notify Client', description: 'Send mortgage market update', status: 'Pending' }
        ]
    },
    {
        id: 7,
        type: 'Market Change',
        timestamp: getRelativeDate(1),
        title: 'Tech Sector Volatility',
        details: 'Major tech companies announce significant workforce reductions and market volatility in tech sector',
        impact: 'High',
        priority: 'Urgent',
        affectedClients: [
            { id: 2, name: 'James Wilson', portfolio: 'Moderate', lastCommunication: getRandomPastDate() },
            { id: 6, name: 'David Kim', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() },
            { id: 15, name: 'Emma Thompson', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() },
            { id: 18, name: 'Benjamin Kim', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Assess tech sector exposure', status: 'Pending' },
            { type: 'Build Report', description: 'Generate sector analysis report', status: 'Pending' },
            { type: 'Notify Client', description: 'Send market update notification', status: 'Pending' }
        ]
    },
    {
        id: 8,
        type: 'Client Life Event',
        timestamp: getRelativeDate(4),
        title: 'Retirement Planning Milestone',
        details: 'Multiple high-net-worth clients approaching retirement age, requiring portfolio adjustments',
        impact: 'High',
        priority: 'Normal',
        affectedClients: [
            { id: 4, name: 'Michael Anderson', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 10, name: 'Robert Taylor', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 20, name: 'Nathan Wright', portfolio: 'Conservative', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review retirement portfolio allocation', status: 'Pending' },
            { type: 'Schedule Meeting', description: 'Discuss retirement income strategy', status: 'Pending' }
        ]
    },
    {
        id: 9,
        type: 'Regulatory',
        timestamp: getRelativeDate(5),
        title: 'Estate Tax Law Changes',
        details: 'New estate tax legislation affecting high-net-worth clients and their legacy planning',
        impact: 'High',
        priority: 'Urgent',
        affectedClients: [
            { id: 3, name: 'Sarah Chen', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() },
            { id: 4, name: 'Michael Anderson', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 10, name: 'Robert Taylor', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 20, name: 'Nathan Wright', portfolio: 'Conservative', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review estate planning strategies', status: 'Pending' },
            { type: 'Update Docs', description: 'Update estate planning documentation', status: 'Pending' },
            { type: 'Schedule Meeting', description: 'Schedule estate planning session', status: 'Pending' }
        ]
    },
    {
        id: 10,
        type: 'Student Loan Payment Resumption',
        timestamp: getRelativeDate(7),
        title: 'Student Loan Payment Resumption',
        details: 'Federal student loan payments set to resume, impacting disposable income and investment capacity',
        impact: 'Medium',
        priority: 'Normal',
        affectedClients: [
            { id: 11, name: 'Alexandra Foster', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() },
            { id: 18, name: 'Benjamin Kim', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review budget and investment strategy', status: 'Pending' },
            { type: 'Schedule Meeting', description: 'Discuss payment impact planning', status: 'Pending' }
        ]
    },
    {
        id: 11,
        type: 'Market Change',
        timestamp: getRelativeDate(8),
        title: 'Real Estate Market Shift',
        details: 'Significant changes in real estate market affecting property investment strategies',
        impact: 'High',
        priority: 'Urgent',
        affectedClients: [
            { id: 8, name: 'Thomas Martinez', portfolio: 'Moderate', lastCommunication: getRandomPastDate() },
            { id: 13, name: 'Sophie Chen', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review real estate portfolio allocation', status: 'Pending' },
            { type: 'Build Report', description: 'Generate property market analysis', status: 'Pending' },
            { type: 'Notify Client', description: 'Send portfolio update', status: 'Pending' }
        ]
    },
    {
        id: 12,
        type: 'Market Change',
        timestamp: getRelativeDate(9),
        title: 'Healthcare Sector Growth',
        details: 'Emerging opportunities in healthcare sector following regulatory changes',
        impact: 'Medium',
        priority: 'Normal',
        affectedClients: [
            { id: 3, name: 'Sarah Chen', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() },
            { id: 17, name: 'Olivia Martinez', portfolio: 'Conservative', lastCommunication: getRandomPastDate() },
            { id: 20, name: 'Nathan Wright', portfolio: 'Conservative', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Assess healthcare sector exposure', status: 'Pending' },
            { type: 'Build Report', description: 'Generate sector analysis', status: 'Pending' },
            { type: 'Schedule Meeting', description: 'Discuss portfolio positioning', status: 'Pending' }
        ]
    },
    {
        id: 13,
        type: 'Regulatory',
        timestamp: getRelativeDate(10),
        title: 'ESG Reporting Requirements',
        details: 'New ESG disclosure requirements affecting investment strategies and reporting',
        impact: 'Medium',
        priority: 'Normal',
        affectedClients: [
            { id: 13, name: 'Sophie Chen', portfolio: 'Aggressive', lastCommunication: getRandomPastDate() },
            { id: 19, name: 'Victoria Patel', portfolio: 'Moderate', lastCommunication: getRandomPastDate() }
        ],
        actions: [
            { type: 'Review', description: 'Review ESG compliance', status: 'Pending' },
            { type: 'Update Docs', description: 'Update ESG documentation', status: 'Pending' },
            { type: 'Build Report', description: 'Generate ESG impact report', status: 'Pending' }
        ]
    },
    // Add some past dated items
    
    {
        id: 14,
        type: 'Insurance Alert',
        title: 'Health Insurance Changes',
        impact: 'Medium',
        affectedClients: [
            { id: 28, name: 'Joseph Clark', portfolio: 'Health', lastCommunication: getRandomPastDate() },
            { id: 29, name: 'Margaret Lee', portfolio: 'Benefits', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.UPDATE,
        actionDetails: createActionDetails('insurance', 'Review health insurance options'),
        priority: 'Medium',
        timestamp: getRelativeDate(15),
        details: 'New health insurance plans available'
    },
    {
        id: 15,
        type: 'Mortgage Update',
        title: 'FHA Loan Changes',
        impact: 'High',
        affectedClients: [
            { id: 30, name: 'Robert Martin', portfolio: 'FHA', lastCommunication: getRandomPastDate() },
            { id: 31, name: 'Susan Wilson', portfolio: 'First-Time Buyer', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.REVIEW,
        actionDetails: createActionDetails('mortgage', 'Review FHA loan terms'),
        priority: 'High',
        timestamp: getRelativeDate(16),
        details: 'Changes to FHA loan requirements'
    },
    {
        id: 16,
        type: 'Client Life Event',
        title: 'Business Succession',
        impact: 'High',
        affectedClients: [
            { id: 32, name: 'Charles Thompson', portfolio: 'Business', lastCommunication: getRandomPastDate() },
            { id: 33, name: 'Diana Rodriguez', portfolio: 'Corporate', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.SCHEDULE,
        actionDetails: createActionDetails('business', 'Schedule succession planning'),
        priority: 'High',
        timestamp: getRelativeDate(17),
        details: 'Business succession planning needed'
    },
    {
        id: 17,
        type: 'Economic Indicator',
        title: 'Employment Report',
        impact: 'Medium',
        affectedClients: [
            { id: 34, name: 'Thomas Moore', portfolio: 'Growth', lastCommunication: getRandomPastDate() },
            { id: 35, name: 'Sarah Davis', portfolio: 'Income', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.REVIEW,
        actionDetails: createActionDetails('economic', 'Review employment impact'),
        priority: 'Medium',
        timestamp: getRelativeDate(17),
        details: 'Strong employment figures released'
    },
    {
        id: 18,
        type: 'Regulatory',
        title: 'Investment Disclosure',
        impact: 'High',
        affectedClients: [
            { id: 36, name: 'William White', portfolio: 'All', lastCommunication: getRandomPastDate() },
            { id: 37, name: 'Barbara Johnson', portfolio: 'All', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.UPDATE,
        actionDetails: createActionDetails('compliance', 'Update disclosure documents'),
        priority: 'Urgent',
        timestamp: getRelativeDate(18),
        details: 'New investment disclosure requirements'
    },
    {
        id: 19,
        type: 'Market Change',
        title: 'International Markets',
        impact: 'Medium',
        affectedClients: [
            { id: 38, name: 'Edward Lee', portfolio: 'International', lastCommunication: getRandomPastDate() },
            { id: 39, name: 'Carol Martinez', portfolio: 'Global', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.REVIEW,
        actionDetails: createActionDetails('international', 'Review international exposure'),
        priority: 'Medium',
        timestamp:  getRelativeDate(19),
        details: 'Significant movements in international markets'
    },
    {
        id: 20,
        type: 'Insurance Alert',
        title: 'Disability Insurance',
        impact: 'Medium',
        affectedClients: [
            { id: 40, name: 'Donald Harris', portfolio: 'Protection', lastCommunication: getRandomPastDate() },
            { id: 41, name: 'Ruth Thompson', portfolio: 'Disability', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.UPDATE,
        actionDetails: createActionDetails('insurance', 'Review disability coverage'),
        priority: 'Medium',
        timestamp: getRelativeDate(19),
        details: 'New disability insurance options available'
    },
    {
        id: 21,
        type: 'Market Change',
        title: 'Historical Market Analysis',
        impact: 'Medium',
        affectedClients: [
            { id: 42, name: 'Robert Chen', portfolio: 'Growth', lastCommunication: getRandomPastDate() },
            { id: 43, name: 'Lisa Park', portfolio: 'Balanced', lastCommunication: getRandomPastDate() }
        ],
        actions: createActionSequence([
            {
                type: ACTION_TYPES.REVIEW,
                description: 'Review historical market performance',
                status: 'Completed',
                completedAt: '2023-09-15T14:00:00Z'
            },
            {
                type: ACTION_TYPES.UPDATE,
                description: 'Update investment strategy',
                status: 'Completed',
                completedAt: '2023-09-20T11:00:00Z'
            }
        ]),
        priority: 'Medium',
        timestamp:  getRelativeDate(20),
        details: 'Six-month historical market analysis completed'
    },
    // Add some future dated items
    {
        id: 22,
        type: 'Client Life Event',
        title: 'Upcoming Retirement Planning',
        impact: 'High',
        affectedClients: [
            { id: 44, name: 'Michael Wilson', portfolio: 'Retirement', lastCommunication: getRandomPastDate() },
            { id: 45, name: 'Sarah Thompson', portfolio: '401k', lastCommunication: getRandomPastDate() }
        ],
        actions: createActionSequence([
            {
                type: ACTION_TYPES.REVIEW,
                description: 'Review retirement portfolio allocation',
                status: 'Pending'
            },
            {
                type: ACTION_TYPES.SCHEDULE,
                description: 'Schedule retirement planning session',
                status: 'Pending'
            },
            {
                type: ACTION_TYPES.AUTOMATE,
                description: 'Set up retirement milestone tracking',
                status: 'Pending'
            }
        ]),
        priority: 'High',
        timestamp: getRelativeDate(20),
        details: 'Upcoming retirement planning session needed'
    },
    {
        id: 23,
        type: 'Client Life Event',
        title: 'Birthday Milestone Automation',
        impact: 'Medium',
        affectedClients: [
            { id: 42, name: 'Henry Wilson', portfolio: 'Retirement', lastCommunication: getRandomPastDate() },
            { id: 43, name: 'Julia Chang', portfolio: 'Planning', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.AUTOMATE,
        actionDetails: createActionDetails('automation', 'Set up birthday milestone notifications', [
            'Configure age-based triggers',
            'Set up automated email templates',
            'Enable notification workflow'
        ]),
        priority: 'Medium',
        timestamp: getRelativeDate(20),
        details: 'Automate birthday-based financial planning milestone notifications'
    },
    {
        id: 24,
        type: 'Client Life Event',
        title: 'Rebalancing Automation',
        impact: 'High',
        affectedClients: [
            { id: 44, name: 'Mark Stevens', portfolio: 'Growth', lastCommunication: getRandomPastDate() },
            { id: 45, name: 'Angela Martinez', portfolio: 'Balanced', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.AUTOMATE,
        actionDetails: createActionDetails('automation', 'Configure automated portfolio rebalancing', [
            'Set up rebalancing thresholds',
            'Configure trade automation',
            'Enable notification system'
        ]),
        priority: 'High',
        timestamp: getRelativeDate(20),
        details: 'Implement automated portfolio rebalancing system'
    },
    {
        id: 25,
        type: 'Client Life Event',
        title: 'Document Processing Automation',
        impact: 'Medium',
        affectedClients: [
            { id: 46, name: 'Paul Roberts', portfolio: 'Estate Planning', lastCommunication: getRandomPastDate() },
            { id: 47, name: 'Linda Kim', portfolio: 'Trust', lastCommunication: getRandomPastDate() }
        ],
        actionType: ACTION_TYPES.AUTOMATE,
        actionDetails: createActionDetails('automation', 'Set up document processing workflow', [
            'Configure OCR processing',
            'Set up document categorization',
            'Enable automated filing system'
        ]),
        priority: 'Medium',
        timestamp: getRelativeDate(20),
        details: 'Automate document processing and filing system'
    }
];

// Update action handlers to handle action sequences
export const actionHandlers = {
    [ACTION_TYPES.REVIEW]: async (details) => {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 1500));
        return { message: 'Review completed successfully. Updates ready for your approval.' };
    },
    [ACTION_TYPES.SCHEDULE]: async (details) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return { message: 'Meeting requests sent to selected clients.' };
    },
    [ACTION_TYPES.UPDATE]: async (details) => {
        await new Promise(resolve => setTimeout(resolve, 1800));
        return { message: 'Documents updated successfully. Ready for review.' };
    },
    [ACTION_TYPES.NOTIFY]: async (details) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return { message: 'Client notification sent successfully' };
    },
    [ACTION_TYPES.BOOKMARK]: async (details) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return { message: 'Client bookmarked successfully' };
    },
    [ACTION_TYPES.DISCARD]: async (details) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return { message: 'Item discarded successfully' };
    },
    [ACTION_TYPES.REPORT]: async (details) => {
        await new Promise(resolve => setTimeout(resolve, 2000));
        return { message: 'Report generated successfully' };
    }

};

// Helper function to add more clients to an existing array
const expandClientList = (baseClients, additionalClients) => [
    ...baseClients,
    ...additionalClients.map((client, index) => ({
        id: baseClients[baseClients.length - 1].id + index + 1,
        ...client
    }))
];

// Update first item with more clients
const item1 = pulseData.find(item => item.id === 1);
if (item1) {
    item1.affectedClients = expandClientList(item1.affectedClients, [
        { name: 'Robert Chen', portfolio: 'Fixed Income' },
        { name: 'Maria Lopez', portfolio: 'Conservative' },
        { name: 'David Park', portfolio: 'Bonds' },
        { name: 'Jennifer Lee', portfolio: 'Income' },
        { name: 'Michael Zhang', portfolio: 'Retirement' },
        { name: 'Susan Kim', portfolio: 'Fixed Income' }
    ]);
}

// Update second item with more clients
const item2 = pulseData.find(item => item.id === 2);
if (item2) {
    item2.affectedClients = expandClientList(item2.affectedClients, [
        { name: 'Thomas Wu', portfolio: 'Property' },
        { name: 'Linda Chen', portfolio: 'Real Estate' },
        { name: 'James Lee', portfolio: 'Commercial' },
        { name: 'Sarah Johnson', portfolio: 'Property' }
    ]);
}

// Add more clients to other items...
// Continue updating other items similarly

// Update the filter function in the component to handle the simplified time periods
const getTimeFilteredData = (data, period) => {
    const now = new Date();
    const itemDate = new Date(data.timestamp);
    
    switch(period) {
        case 'Past':
            return itemDate < now;
        case 'Future':
            return itemDate > now;
        case 'Current':
        default:
            const diffDays = Math.abs(now - itemDate) / (1000 * 60 * 60 * 24);
            return diffDays <= 7; // Consider "current" as within the last week
    }
};

// Update MARKET_EVENT_TYPES to match actual data
export const MARKET_EVENT_TYPES = [
    'Market Change',
    'Regulatory',
    'Economy',
    'Mortgage Update',
    'Insurance Alert'
];

// Update QUICK_DATE_RANGES to fix Beyond 6M logic
export const QUICK_DATE_RANGES = {
    'Next 1M': [0, 1],
    'Next 3M': [0, 3],
    'Next 6M': [0, 6],
    'Past 1M': [-1, 0],
    'Past 3M': [-3, 0],
    'Past 6M': [-6, 0],
    'Beyond 6M': [6, 24]  // From 6 months to 2 years in future
}; 