import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";

function SpreadsheetViewer({ file }) {
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!file?.url || !file?.fileType) return;

    // Convert fileType to lowercase to do simpler checks
    const fileTypeLower = file.fileType.toLowerCase();

    // If the file is labeled as a CSV
    if (fileTypeLower.includes("csv")) {
      parseCsvWithPapa(file.url);
    }
    // If the file is labeled as Excel (e.g. "excel", "xls", or "xlsx")
    else if (
      fileTypeLower.includes("excel") ||
      fileTypeLower.includes("xls") ||
      fileTypeLower.includes("xlsx")
    ) {
      parseExcelWithSheetJS(file.url);
    }
    // Otherwise, not supported
    else {
      alert("Unsupported file type. Only CSV or Excel files are supported here.");
    }
  }, [file]);

  // -------------------------------------------
  // 1. CSV Parsing (Papa Parse)
  // -------------------------------------------
  const parseCsvWithPapa = (url) => {
    console.log(url);
    Papa.parse(url, {
      download: true,        // Tells Papa Parse to fetch the file from the URL
    //   skipEmptyLines: true,  // Optional: skip empty lines
      complete: (results) => {
        // results.data is an array of arrays or objects (depending on config)
        // By default, Papa Parse returns an array of arrays if there's no header option set
        const parsedData = results?.data;
        console.log("Results:", results?.data);
        // If you want the first row to be your headers:
        // if (parsedData.length > 0) {
        //   setHeaders(parsedData[0]);
        //   setRows(parsedData.slice(1));
        // } else {
        //   setHeaders([]);
        //   setRows([]);
        // }
      },
      error: (err) => {
        console.error("Papa Parse error:", err);
        alert("Error parsing CSV file.");
      },
    });
  };

  // -------------------------------------------
  // 2. Excel Parsing (SheetJS)
  // -------------------------------------------
  const parseExcelWithSheetJS = (url) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        // Convert array buffer into a Uint8Array for SheetJS
        const data = new Uint8Array(arrayBuffer);

        // Parse the Excel workbook
        const workbook = XLSX.read(data, { type: "array" });

        // Take the first sheet
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Convert sheet to a 2D array (array of arrays)
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        if (sheetData.length > 0) {
          setHeaders(sheetData[0]);
          setRows(sheetData.slice(1));
        } else {
          setHeaders([]);
          setRows([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching/parsing Excel file:", error);
        alert("Error parsing Excel file.");
      });
  };

  return (
    <div style={{ margin: "20px" }}>
      <h2>Spreadsheet Viewer</h2>

      {file?.url ? (
        <p>
          Loaded from: <code>{file.url}</code>
        </p>
      ) : (
        <p>No file URL provided.</p>
      )}

      {headers.length > 0 && (
        <table border="1" style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              {headers.map((header, idx) => (
                <th key={idx}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>
                    {cell !== null && cell !== undefined ? cell : ""}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default SpreadsheetViewer;

