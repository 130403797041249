import { useState } from 'react';

import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';
import axios from 'axios';

const useAddChatAI = () => {
  const {stateScreener,dispatchScreener,stateApp} = useAppInfo()
  const userId = stateApp.userId
  const userRole = stateApp?.onboarding?.userRole
const username = stateApp.username
  const [error, setError] = useState(null);
  
  const addChatAI = async ({chatDetails}) => {
    try {
      const response = await axios.post(`${BASE_URL}/addChatAI/`, {
        userId,
        username,
        chatDetails,
        table:userRole === "advisor" ? "ChatAIAdvisor" : "ChatAI"
      });
      
     
      setError("Success")
    } catch (err) {
      setError('Error adding Screener data');
    }
  };

  return { addChatAI, error };
};

export default useAddChatAI;
