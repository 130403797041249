import React, { useEffect, useState } from 'react'
import useAutomateEmail, { handleSendAutoEmail } from './useAutomateEmail';
import ConnectEmail from './ConnectEmail';
import { Alert, Button } from '@mui/material';
import TextInput from 'components/Inputs/TextInput';
import TextArea from 'components/Inputs/TextArea';
import EmailTemplate from './EmailTemplate';
import { useAppInfo } from 'AppState';
import ReactMarkdown from 'react-markdown';
import useAISummarizeFinancials from 'chatGPT/useAISummarizeFinancials';
import { marked } from "marked";

export default function AutomateEmail() {
    const {stateApp} = useAppInfo()
    const {userId} = stateApp
    const [formData, setFormData] = useState({
      recipient: '',
      subject: '',
      message: '',
      isHtml: false, // Flag to indicate if the message is HTML
      consent: true,
    });
    const {isEmailConnected,emailProvider} = useAutomateEmail()
    
    
  const handleChange = (n,v) => {
    setFormData((prev) => ({
      ...prev,
      [n]: v,
    }));
  };

  const [emailTemplate,setEmailTemplate] = useState("")


  useEffect(()=>{
    const template = EmailTemplate({message:formData.message, link: 'https://AutonomyFinance.com', companyName: 'Autonomy Finance' });
    setEmailTemplate(template)
  },[formData])

  const [status,setStatus] = useState('')
  const [submit,setSubmit] = useState(false)
  async function handleSubmit(e){
    e.preventDefault()
    const formUpdate = formData
    formUpdate.message = emailTemplate
    formUpdate.isHtml = true
    const statusUpdate = await handleSendAutoEmail(userId,formUpdate)
    setStatus(statusUpdate)
    
  }
  const {aiSummary} = useAISummarizeFinancials({message:"Give key insignts and questions to ask advisor",submitAnswer:true})
 
  useEffect(()=>{
    async function handleSubmit(){
        const htmlMessage = marked(aiSummary.answer);
    const template = EmailTemplate({message:htmlMessage, link: 'https://AutonomyFinance.com', companyName: 'Autonomy Finance' });
    const formUpdate = formData
    formUpdate.message = template
    formUpdate.isHtml = true
    const statusUpdate = await handleSendAutoEmail(userId,formUpdate)
    setStatus(statusUpdate)
}
      if (submit && aiSummary?.answer) {
        handleSubmit()
      }
  },[submit,aiSummary])

  useEffect(()=>{
    if (status) {
      setSubmit(false)
    }
  },[status])

  const [messageSelected,setMessageSelected] = useState(-1)

  
  if (!isEmailConnected) {
    return (
        <ConnectEmail/>
    )
  }

  return (
    <div style={{padding:"1rem"}}>
        <h3>Automate Email</h3>
        {status && <Alert severity="success">{status}</Alert>}

    
        <form 
        onSubmit={(e)=>{e.preventDefault();setSubmit(true)}}
        style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
            
            <label>
                <input
                  required
                  type="checkbox"
                  name="consent"
                  checked={formData.consent}
                  onChange={(e)=>handleChange("consent",e.target.checked)}
                />
                I consent to send emails on my behalf.
              </label>

            {/* <TextInput 
            required
            value={formData.recipient} 
            onChange={(v)=>handleChange("recipient",v)}
            type='email' label='Recipient Email'/> */}
            
            {/* <TextInput value={formData.subject} 
            onChange={(v)=>handleChange("subject",v)}
            type='text' label='Subject'/>
                
        <TextArea 
        sx={{width:"100%",minHeight:"400px",height:"auto"}}
        value={formData.message} 
            onChange={(v)=>handleChange("message",v)}
           label='Message'/> */}
        <div style={{display:"flex",gap:"1rem"}}>
        {autoTopicMessage.map((topic,index)=>(
            <div 
            
            style={{background:"#fafafa",border:index===messageSelected?"2px solid var(--selectBlue)":"none",padding:"1rem",cursor:"pointer",borderRadius:"10px"}}
            onClick={(e)=>{setMessageSelected(index);
                setFormData((prev)=>
                    ({...prev,recipient:topic.recipient,subject:topic.subject
                        ,message:topic.message,
                    }))}}
            key={topic.subject}>
                <div>
                <h4 style={{fontWeight:"bold",color:"gray"}}>Recipients: </h4>
                {topic.recipient.map((email)=>(
                    <p style={{margin:"0",paddingLeft:"1rem"}} key={email}>{email}</p>
                ))}
                </div><br/>
                
                <h4 style={{fontWeight:"bold",color:"gray"}}>Subject:</h4>
                <p style={{margin:"0",paddingLeft:"1rem"}} >{topic.subject}</p> <br/>
                <h4 style={{fontWeight:"bold",color:"gray"}}>Message:</h4>
                <p style={{margin:"0",paddingLeft:"1rem"}} >{topic.message}</p>
            </div>
        ))}
        </div>
        
        <div>
        <Button variant='contained' type='submit'>Send</Button>
        
        </div>
       
        </form>
    </div>
  )
}

const autoTopicMessage = [
{recipient:["yash@takestock.ai","theLouisGagnon@gmail.com"],subject:"How are my financials",message:"Ask your advisor whats up with your finances"},
{recipient:["yash@takestock.ai"],subject:"My Portfolio",message:"How is my portfolio doing?"},
{recipient:["yash@takestock.ai"],subject:"My Goals",message:"Hey, I am thinking about..."},
{recipient:["yash@takestock.ai"],subject:"Market Update",message:"Hey, what is the market outlook with Trump now in office?"},
]


const emailContactList = [
    {role:"Financial Advisor",name:"Yash",email:"yash@takestock.ai",},
    {role:"Portfolio Manager",name:"Bianca",email:"bianca.vanderstoel@gmail.com",},
    {role:"Financial Advisor",name:"Milind",email:"dylan.vanderstoel@gmail.com",},
    {role:"Family",name:"Dylan",email:"dylan.vanderstoel@gmail.com",},
    {role:"Family",name:"Dylan",email:"dylan.vanderstoel@gmail.com",}]
