import { firebaseMedia } from 'content/media/firebaseMedia';
import MyFinancialsOverview from 'myFinancials/MyFinancialsOverview';
import MyPortfolioReport from 'myFinancials/Report/MyPortfolioReport';
import ReportMoreHeaderDetails
  from 'myFinancials/Report/ReportMoreHeaderDetails';

import EconomyReport from './EconomyReport';
import IntermarketReport from './IntermarketReport';

export const takestockReports = {
  "PersonalFinancials":<MyFinancialsOverview showCard={false} showHealth/>,
  "Portfolio":<MyPortfolioReport/>,
  "Economy":<EconomyReport/>,
  "Market":<IntermarketReport/>
}

const takestockReportContent = [
    {
      category: "My Financials",
      title: <ReportMoreHeaderDetails reportType="My Financials"/>,
      component:<MyFinancialsOverview showCard={false} showHealth/>,
      link: "FinancialsOverview",
      imgUrl: "https://images.unsplash.com/photo-1445116572660-236099ec97a0?q=80&w=2371&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "A detailed overview of your financials.",
      
    },
    {
      category: "My Financials",
      title: <ReportMoreHeaderDetails/>,
      component:<MyPortfolioReport/>,
      link: "Portfolio",
      imgUrl: "https://images.unsplash.com/photo-1466781783364-36c955e42a7f?q=80&w=2371&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "A detailed overview of your Portfolio.",
      moreHeaderDetails:<></>,
    },
    {
      category: "Market & Economy",
      title: "USA Economy",
      component:<EconomyReport/>,
      link: "EconomyUS",
      imgUrl: "https://images.unsplash.com/photo-1620263827308-682251c1be2b?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "Insights into the latest economy.",
      source:"https://fred.stlouisfed.org/"
    },
    {
      category: "Market & Economy",
      title: "Intermarket",
      component:<IntermarketReport/>,
      link: "Intermarket",
      imgUrl: firebaseMedia?.report_market,
      description: "Insights into how different Asset Classes are performing",
    },
    // Add more report objects as needed
  ]

export { takestockReportContent };