import { firebaseMedia } from 'content/media/firebaseMedia';

import {
  Settings,
  TrendingUp,
} from '@mui/icons-material';
import AbcIcon from '@mui/icons-material/Abc';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AdjustIcon from '@mui/icons-material/Adjust';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ArticleIcon from '@mui/icons-material/Article';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CompressIcon from '@mui/icons-material/Compress';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ExploreIcon from '@mui/icons-material/Explore';
import ExtensionIcon from '@mui/icons-material/Extension';
import FeedIcon from '@mui/icons-material/Feed';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import GestureIcon from '@mui/icons-material/Gesture';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HomeIcon from '@mui/icons-material/Home';
import InsightsIcon from '@mui/icons-material/Insights';
import LanguageIcon from '@mui/icons-material/Language';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LockIcon from '@mui/icons-material/Lock';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import PagesIcon from '@mui/icons-material/Pages';
import PeopleIcon from '@mui/icons-material/People';
import PieChartIcon from '@mui/icons-material/PieChart';
import PixIcon from '@mui/icons-material/Pix';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PrecisionManufacturingIcon
  from '@mui/icons-material/PrecisionManufacturing';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SavingsIcon from '@mui/icons-material/Savings';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SpaIcon from '@mui/icons-material/Spa';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TableChartIcon from '@mui/icons-material/TableChart';
import TagIcon from '@mui/icons-material/Tag';
import ThreePIcon from '@mui/icons-material/ThreeP';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TokenIcon from '@mui/icons-material/Token';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import TuneIcon from '@mui/icons-material/Tune';
import WarningIcon from '@mui/icons-material/Warning';

function Icon({imgSrc,height="15px",width="15px"}){
    return(<div style={{width:width,height:height}}>
     <img src={imgSrc} style={{objectFit:"cover"}} width={"100%"} height={"100%"}>
       </img></div>)
   }
  
const fontSize = "small";
export const menuIcons = {
  "News": <FeedIcon fontSize={fontSize} />,
  "Calendar": <CalendarMonthIcon fontSize={fontSize} />,
  "MandA": <CompressIcon fontSize={fontSize} />,
  "Merger & Acquisitions": <CompressIcon fontSize={fontSize} />,
  "Filings": <FilePresentIcon fontSize={fontSize} />,
  "TradingActivities": <CompareArrowsIcon fontSize={fontSize} />,
  "Trading Activities": <CompareArrowsIcon fontSize={fontSize} />,
  "Profile": <AccountBoxIcon fontSize={fontSize} />,
  "Investment Portfolio": <PieChartIcon fontSize={fontSize} />,
  "Portfolio": <PieChartIcon fontSize={fontSize} />,
  "Scenarios": <QueryStatsIcon fontSize={fontSize} />,
  "RiskManagement": <GppMaybeIcon fontSize={fontSize} />,
  "Risk Management": <GppMaybeIcon fontSize={fontSize} />,
  "Asset": <SpaIcon fontSize={fontSize} />,
  "Liability": <AccountBalanceWalletIcon fontSize={fontSize} />,
  "Goals": <CrisisAlertIcon fontSize={fontSize} />,
  "Guide": <TipsAndUpdatesIcon fontSize={fontSize} />,
  "Learn": <AutoStoriesIcon fontSize={fontSize} />,
  "Education": <LocalLibraryIcon fontSize={fontSize} />,
  "Mind Map": <PsychologyAltIcon fontSize={fontSize} />,
  "Glossary": <AbcIcon fontSize={fontSize} />,
  "Networth": <PixIcon fontSize={fontSize} />,
  "Cashflow": <AttachMoneyIcon fontSize={fontSize} />,
  "Alerts": <WarningIcon fontSize={fontSize} />,
  "Ticker": <TokenIcon fontSize={fontSize} />,
  "Industry": <PrecisionManufacturingIcon fontSize={fontSize} />,
  "Industry/Sector": <PrecisionManufacturingIcon fontSize={fontSize} />,
  "Sector & Industry": <PrecisionManufacturingIcon fontSize={fontSize} />,
  "Market": <LanguageIcon fontSize={fontSize} />,
  "Economy": <AccountBalanceIcon fontSize={fontSize} />,
  "Stocks": <Icon imgSrc={firebaseMedia?.icon_stocks} fontSize={fontSize} />, // Note: Ensure Icon supports fontSize
  "Crypto": <Icon imgSrc={firebaseMedia?.icon_bond} fontSize={fontSize} />, // Note: Ensure Icon supports fontSize
  "Etf": <Icon imgSrc={firebaseMedia?.icon_etf} fontSize={fontSize} />, // Note: Ensure Icon supports fontSize
  "Bonds": <Icon imgSrc={firebaseMedia?.icon_forex} fontSize={fontSize} />, // Note: Ensure Icon supports fontSize
  "Forex": <Icon imgSrc={firebaseMedia?.icon_crypto} fontSize={fontSize} />, // Note: Ensure Icon supports fontSize
  "Order": <LibraryBooksIcon fontSize={fontSize} />,
  "Trade Conditions": <ChecklistIcon fontSize={fontSize} />,
  "Technicals": <TroubleshootIcon fontSize={fontSize} />,
  "Visuals": <ScatterPlotIcon fontSize={fontSize} />,
  "Checklist": <ChecklistIcon fontSize={fontSize} />,
  "Transactions": <TableChartIcon fontSize={fontSize} />,
  "Chart": <SsidChartIcon fontSize={fontSize} />,
  "Explore": <ExploreIcon fontSize={fontSize} />,
  "Flow": <AccountTreeIcon fontSize={fontSize} />,
  "Trade": <TrendingUp fontSize={fontSize} />,
  "Top Investors": <SupervisedUserCircleIcon fontSize={fontSize} />,
  "Fit": <ExtensionIcon fontSize={fontSize} />,
  "Portfolio Fit": <ExtensionIcon fontSize={fontSize} />,
  "Investment Preferences": <TuneIcon fontSize={fontSize} />,
  "InvestmentPreferences": <TuneIcon fontSize={fontSize} />,
  "Report Builder": <PagesIcon fontSize={fontSize} />,
  "Report": <ArticleIcon fontSize={fontSize} />,
  "Investment": <SavingsIcon fontSize={fontSize} />,
  "Trading": <MultipleStopIcon fontSize={fontSize} />,
  "Settings": <Settings fontSize={fontSize} />,
  "Filter": <TuneIcon fontSize={fontSize} />,
  "Doodle": <GestureIcon fontSize={fontSize} />,
  "My Finanicals": <></>,
  "Home": <HomeIcon fontSize={fontSize} />,
  "Opportunity": <AdjustIcon fontSize={fontSize} />,
  "Health": <HealthAndSafetyIcon fontSize={fontSize} />,
  "ChatAI": <AutoAwesomeIcon fontSize={fontSize} />,
  "AskProfessional": <ThreePIcon fontSize={fontSize} />,
  "Analytics": <AnalyticsIcon fontSize={fontSize} />,
  "Product": <ShoppingCartIcon fontSize={fontSize} />,
  "Vault": <LockIcon fontSize={fontSize} />,
  "Social":<TagIcon fontSize={fontSize}/>,
  "Todo":<PlaylistAddCheckIcon fontSize={fontSize}/>,
  "Automate":<AutoModeIcon fontSize={fontSize}/>,
  "Clients": <PeopleIcon fontSize={fontSize}/>,
  "Pulse": <InsightsIcon fontSize={fontSize}/>,
  "Planning": <AssessmentIcon fontSize={fontSize}/>,
  "Products": <ShoppingCartIcon fontSize={fontSize}/>
};

  