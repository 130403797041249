import React from 'react';

import { useAppInfo } from 'AppState';
import NoFinancialData from 'myFinancials/Template/NoFinancialData';

import GoalRealizeSteps from './GoalRealizeSteps';
import GoalsOverview from './GoalsOverview';
import GoalsProjectionEdit from './GoalsProjectionEdit';
import GoalSuccessFailureScenario from './GoalSuccessFailureScenario';

export default function GoalInfo({info,displayType="main"}) {
  const {stateMyFinancials} = useAppInfo()
  const {goalsPeriodic,financialsLoaded} = stateMyFinancials
  const content = {
    "Overview":<GoalsOverview displayType={displayType}/>,
    "Steps to realize Goals":<GoalRealizeSteps/>,
    "Projections":<GoalsProjectionEdit/>,
    "SuccessFailure":<GoalSuccessFailureScenario/>
  }
  return (
    <div style={{width:"100%",position:"relative",minHeight:"50vh"}}>
    <NoFinancialData financialType="goals"/>
    {financialsLoaded && goalsPeriodic.length > 0 &&  content[info]}
    </div>
  )
}
