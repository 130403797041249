import React from 'react';

import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

const RoundedTab = styled(Tab)(({ theme }) => ({
  borderRadius: '9999px', // fully rounded
  backgroundColor: 'white', // light fill color
  margin: theme.spacing(0.5),
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  minHeight: 'auto',
  padding: '6px 16px',
  border: "1px solid #ddd",
  '&.Mui-selected': {
    // backgroundColor: 'var(--bgWoodSmoke)',
    border: "1px solid var(--selectBlue)",
    fontWeight: "bold",
    color: "var(--selectBlue)",
  },
}));

const TabRound = ({ options, value, onChange,tabSx={},tabsSx={},...props}) => {
  return (
    <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
      <Tabs
        value={value}
        onChange={(e, newValue) => onChange(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="custom tabs"
        TabIndicatorProps={{ sx: { bgcolor: "var(--selectBlue)", display: "none" } }}
        className="overflow-x-auto" // Tailwind overflow for container
        sx={{
          flex: 1,
          position: "relative",
          paddingRight: "80px", // Adds space at the end for buttons
          '& .MuiTabs-scrollButtons': {
            position: 'absolute',
            right: 0, // Position at the right edge
            top: "50%", // Center vertically
            transform: "translateY(-50%)", // Adjust for proper centering
            zIndex: 10,
            background: 'white',
            borderRadius: "50%",
            width: "32px",
            // height: "32px",
          },
          '& .MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0.3,
          },
          '& .MuiTabs-scrollButtons:first-of-type': {
            right: 30, // Space between left and right buttons
          },
          ...tabsSx
        }}
        {...props}
      >
        {options.map((option, index) => (
          <RoundedTab key={index} label={option} />
        ))}
      </Tabs>
    </div>
  );
};

export default TabRound;
