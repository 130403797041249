import React from 'react';
import { Box, Typography } from '@mui/material';

const TextWithBorders = ({ text,textAlign="left",showBorder=true, icon,variant="body1",sx={}}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%">

      <Box sx={{flex:textAlign==="center" && "1",width:textAlign==="left" && "10px"}} 
      borderBottom={showBorder && "1px solid #ddd"}></Box>
      <Box display="flex" alignItems="center" mx={2}>
        {icon && <Box mr={1} sx={{...sx}}>{icon}</Box>}
        <Typography variant={variant} sx={{ fontWeight: "bold",...sx}}>
          {text}
        </Typography>
      </Box>
      <Box flex="1"  borderBottom={showBorder && "1px solid #ddd"}></Box>
    </Box>
  );
};

export default TextWithBorders;