import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Typography } from '@mui/material';
import { getTodoHealthCheck } from './TodoHealthActionSteps';
import { FinancialProductInfo, getFinancialProductInfo } from 'financialProducts/info';
import { menuIcons } from 'nav/menuIcons';
import { formatStartAndEndDates } from './TodoTable';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useAppInfo } from 'AppState';
import DemoToggle from 'myFinancials/Demo/DemoToggle';
import { DemoProfile } from 'myFinancials/Demo/DemoAccounts';

export default function TodoHealthCheckActionTemplate({actionId}) {
  const {stateMyFinancials} = useAppInfo()
  const {financialView,demoProfile} = stateMyFinancials
  const todo = getTodoHealthCheck(actionId)
  const action = todo?.actions[0] || {}

  const navigate = useNavigate()
  const financialProduct = action?.financialProduct && getFinancialProductInfo(action?.financialProduct)

  return (
    <div style={{maxWidth:"90vw",maxHeight:"80vh",display:"flex",flexDirection:"column",gap:"1rem",overflow:"auto"}}>
    {/* <Typography>{action?.description}</Typography> */}
    <h3 style={{color:"var(--selectBlue)"}}>{action?.label}</h3>
    
    <div>

    {todo?.priority &&
          <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
          <Typography style={{fontWeight:"bold",color:"gray"}}>Priority:</Typography>
          <div>
          {Array.from({ length: todo?.priority }).map((_, index) => (
            <span key={index}>
              <WhatshotIcon style={{ color: '#ff5722' }} />
            </span>
          ))}</div></div>} 
    <Typography style={{fontWeight:"bold",color:"gray"}}>Timeline: {todo?.dateStart && formatStartAndEndDates(todo?.dateStart, todo?.dateExpiry)}</Typography>
    </div>
    <div>
    {action?.component}
    {(!action?.component && action?.todo) && action?.todo.map((obj,index)=>
    <div key={index} style={{display:"flex",gap:"0.5rem"}}>
    <CheckCircleIcon style={{color:"green"}}/>
    <Typography key={index}>
                  {obj}
                </Typography></div>)}</div>
    {!action?.component && <>
     <Button startIcon={menuIcons?.AskProfessional} sx={{width:"max-content"}} onClick={()=>navigate("/AskProfessional")} variant='highlight'>
        {`Connect with a ${action?.professional || "Financial Advisor"}`}
    </Button>
    {financialProduct &&
    <Button sx={{width:"max-content"}} onClick={()=>navigate(`/FinancialProducts/${financialProduct?.productName}`)} variant='highlight'>
       Get {financialProduct?.heading}
    </Button>}
    </>}

    {financialView !== "personal" &&  
    <div style={{maxWidth:"500px",boxSizing:"border-box",display:"flex",padding:"1rem",borderRadius:"5px",background:"var(--colorWarning)"}}>
    <Typography style={{color:"gray"}}>Your are viewing todo for demo profile: {demoProfile}</Typography></div>}
   
    {/* {financialView === "personal" &&  
    <div style={{fontSize:"0.8rem",maxWidth:"500px",boxSizing:"border-box",display:"flex",padding:"1rem",borderRadius:"5px",background:"var(--colorWarning)"}}>
    <Typography style={{fontSize:"0.8rem",color:"gray",display:"flex",gap:"0.5rem",alignItems:"center"}}>Disclaimer: To-dos are for education purposes only and should not be taken as financial advice.</Typography></div>}
    */}
    
 {/* <Link to={"/FinancialProducts"} style={{color:"var(--selectBlue)",textDecoration:"underline"}}>View Financial Products</Link> */}
    {/* <FinancialProductsModal/> */}
    

    {/* <Link to={"/AskAI"} style={{color:"var(--selectBlue)",textDecoration:"underline"}}>Ask AI for help</Link> */}

    
    </div>
  )
}


