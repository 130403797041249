import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import Video from 'components/Media/Video';
import TabLine from 'components/Tabs/TabLine';
import { firebaseMedia } from 'content/media/firebaseMedia';

// Typewriter component for animated text display
const Typewriter = ({ message = '', onComplete, speed = 30, fontSize = "2rem" }) => {
  const [displayedText, setDisplayedText] = useState('');
  const indexRef = useRef(0);
  const rafIdRef = useRef(null);
  const startTimeRef = useRef(null);

  useEffect(() => {
    if (!message) return;

    setDisplayedText('');
    indexRef.current = 0;
    startTimeRef.current = null;

    const typeCharacter = (timestamp) => {
      if (!startTimeRef.current) {
        startTimeRef.current = timestamp;
      }

      const elapsed = timestamp - startTimeRef.current;

      if (elapsed >= speed * indexRef.current) {
        setDisplayedText(message.slice(0, indexRef.current + 1));
        indexRef.current += 1;
      }

      if (indexRef.current < message.length) {
        rafIdRef.current = requestAnimationFrame(typeCharacter);
      } else {
        if (onComplete) onComplete();
        cancelAnimationFrame(rafIdRef.current);
        rafIdRef.current = null;
      }
    };

    rafIdRef.current = requestAnimationFrame(typeCharacter);

    return () => {
      if (rafIdRef.current) {
        cancelAnimationFrame(rafIdRef.current);
      }
    };
  }, [message, speed, onComplete]);

  return <h1 style={{ color: '#000', fontSize }}>{displayedText}</h1>;
};

// Carousel section component
const CarouselSection = ({ section, active }) => {
  const [typingCompleted, setTypingCompleted] = useState(false);
  const [messageInView, setMessageInView] = useState(false);

  useEffect(() => {
    if (active) {
      setMessageInView(true);
    } else {
      setMessageInView(false);
      setTypingCompleted(false);
    }
  }, [active]);

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        top: 0,
        left: active ? '0%' : '100%',
        padding: "3rem",
        boxSizing: "border-box",
        zIndex: active ? 1 : 0,
      }}
    >
      {messageInView && (
        <div
          style={{
            backdropFilter: "blur(30px)",
            width: '100%',
            zIndex: 200,
            height: "100%",
            position: 'relative',
            padding: '20px',
            gap: '1rem',
            opacity: messageInView ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              background: '#f7f7f7',
              borderRadius: '5px',
              padding: '1rem',
              width: 'max-content',
              maxWidth: '600px',
              opacity: messageInView ? 1 : 0,
              transition: 'opacity 1s ease-in-out',
            }}
          >
            <Typewriter
              message={section.message}
              onComplete={() => setTypingCompleted(true)}
            />
          </div>

          {typingCompleted && (
            <div
              className='fade-in'
              style={{
                borderRadius: '5px',
                height: "auto",
                width: '80%',
                opacity: typingCompleted ? 1 : 0,
                transition: 'opacity 4s ease-in-out',
              }}
            >
              <Video 
                src={section.videoUrl}
                sx={{ height: "auto", boxShadow: "none" }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Main carousel component
const FullWidthCarousel = ({ sections, delay = 14000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const questions = sections.map((section) => section.message);

  return (
    <div style={{ 
      width: '100%', 
      minHeight: '100vh', 
      position: 'relative', 
      overflow: 'hidden',
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: "#fafafa",
      borderRadius: "25px",
      padding: "1rem 0"
    }}>
      <TabLine 
        variant='noLine'
        tabList={questions} 
        tabIndex={activeIndex} 
        handleTabChange={(i) => setActiveIndex(i)}
      />

      {sections.map((section, index) => (
        <React.Fragment key={index}>
          {index === activeIndex && (
            <CarouselSection
              section={section}
              active={index === activeIndex}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

// Main FeatureAskAI component
export default function FeatureAskAI() {
  const sections = [
    {
      message: "How am I doing financially?",
      videoUrl: firebaseMedia?.video_howfinancially,
      imageUrl: "https://images.unsplash.com/photo-1518130242561-edb760734bee?q=80&w=2535&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      messageAlign: "left",
    },
    {
      message: "Can I afford a million-dollar house?",
      videoUrl: firebaseMedia?.video_canibuyhouse,
      imageUrl: "https://images.unsplash.com/photo-1501004318641-b39e6451bec6?q=80&w=2273&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      messageAlign: "left",
    },
    {
      message: "Am I beating the market?",
      videoUrl: firebaseMedia?.video_amibeatingmarket,
      imageUrl: "https://images.unsplash.com/photo-1466781783364-36c955e42a7f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cGxhbnR8ZW58MHx8MHx8fDA%3D",
      messageAlign: "left",
    },
    {
      message: "Find me opportunities aligned with my portfolio strategy?",
      videoUrl: firebaseMedia?.video_portfoliofit,
      messageAlign: "left",
    },
  ];

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      padding: "2rem 4rem",
      paddingTop: "4rem",
      alignItems: "center",
      width: "100%",
      boxSizing: "border-box",
      position: "relative"
    }}>
      <div style={{ paddingBottom: "4rem", textAlign: "center" }}>
        <h1 style={{ color: "black", fontSize: "4rem" }}>Ask AI Quinn</h1>
        <h1 style={{ fontSize: "3rem" }}>personal questions</h1>
      </div>
      <FullWidthCarousel sections={sections} />
    </div>
  );
}
    