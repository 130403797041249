import React, { useState } from 'react'
import { Info } from '@mui/icons-material'
import { Alert, Button, Icon, Popover } from '@mui/material'
import Modal from './Modal';

export default function PopoverInfo({title,children,color="gray",sxBtn={}}){
    
        
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'metric-settings' : undefined;
  
    return ( 
      <>
      <Button sx={{color:color?color:"var(--selectBlue)",minWidth:0,padding:"0.25rem",...sxBtn}} aria-describedby={id} onClick={handleClick}>
      <Info fontSize='small'/>
    </Button>
    {open && <Modal 
     sx={{width:"auto",height:"auto"}}
    onClose={handleClose}>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem", 
    overflow:"auto",maxWidth:"45vw",maxHeight:"85vh",boxSizing:"border-box"}}>

<div style={{display:"flex",gap:"1rem",alignItems:"center"
        ,position:"sticky",top:0,zIndex:5,background:"white",padding:"0.25rem"}}> 
        <h3 style={{flex:1}}>{title}</h3>
        {/* <IconButton onClick={handleClose}><Close/></IconButton> */}
        </div>

         <div style={{padding:"0.25rem",flex:1,display:"flex",flexDirection:"column",gap:"0.5rem",borderRadius:"10px",padding:"1rem",background:"var(--colorInformation)"}}>
                {children}
                </div>   
    </div>
      </Modal>}
    {/* <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      onMouseLeave={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
    <div style=
    {{ display: "flex", flexDirection: "column", gap: "1rem", 
    padding: "0.5rem",background:"var(--colorInformation)",maxWidth:"300px",overflow:"auto"}}>
        <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
          <Icon/> <h3>{title}</h3></div>
        
        {children}
        
      </div>
    </Popover> */}
    </>)
  }

// import React, { useState } from 'react'
// import { Info } from '@mui/icons-material'
// import { Alert, Button, Icon, Popover } from '@mui/material'

// export default function PopoverInfo({title,children,color="gray",sxBtn={}}){
    
        
//     const [anchorEl, setAnchorEl] = useState(null);
    
//     const handleClick = (event) => {
//       setAnchorEl(event.currentTarget);
//     };
  
//     const handleClose = () => {
//       setAnchorEl(null);
//     };
  
//     const open = Boolean(anchorEl);
//     const id = open ? 'metric-settings' : undefined;
  
//     return ( 
//       <>
//       <Button sx={{color:color?color:"var(--selectBlue)",minWidth:0,padding:"0.25rem",...sxBtn}} aria-describedby={id} onClick={handleClick}>
//       <Info fontSize='small'/>
//     </Button>
//     <Popover
//       id={id}
//       open={open}
//       anchorEl={anchorEl}
//       onClose={handleClose}
//       onMouseLeave={handleClose}
//       anchorOrigin={{
//         vertical: 'bottom',
//         horizontal: 'left',
//       }}
//     >
//     <div style=
//     {{ display: "flex", flexDirection: "column", gap: "1rem", 
//     padding: "0.5rem",background:"var(--colorInformation)",maxWidth:"300px",overflow:"auto"}}>
//         <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
//           <Icon/> <h3>{title}</h3></div>
        
//         {children}
        
//       </div>
//     </Popover>
//     </>)
//   }