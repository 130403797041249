import React from 'react';

import PortfolioFitNotification from 'opportunities/PortfolioFitNotification';

import CashflowInfo from './Cashflow/CashflowInfo';
import { MyFinancialHealthCard } from './FinancialHealth/MyFinancialHealthCard';
import MyFinancialHealthScore from './FinancialHealth/MyFinancialHealthScore';
import GoalInfo from './Goals/GoalInfo';
import GoalsSuccessRate from './Goals/GoalsSuccessRate';
import MyFinancialCardNew from './MyFinancialCardNew';
import NetWorthOverview from './NetWorth/NetWorthOverview';
import PortfolioInfoList from './Portfolio/PortfolioInfoList';
import TodoNotificationHome from './Todos/TodoNotificationHome';

export default function MyFinancialsOverview({showCard=true,showHealth=false}){
  
  return(
    <div style={{display:"flex",flexDirection:"column"
    ,gap:"1rem",padding:"1rem",boxSizing:"border-box",width:"100%"}}>

      {showCard && 
          <div style={{maxWidth:"100%",display:"flex",gap:"1.5rem",flexWrap:"wrap",alignSelf:"flex-start"}}>
          <div style={{minWidth:"300px"}}>
          <MyFinancialCardNew expandItems={true}/>
          </div>
          <div style={{display:"flex",flexDirection:"column",gap:"1rem",justifyContent:"flex-start",minWidth:"400px",maxWidth:"500px"}}>
          <TodoNotificationHome/>
          <MyFinancialHealthScore displayType='home' scoreHeight='60px' scoreWidth='60px'/>
          <GoalsSuccessRate displayType='home' scoreHeight='60px' scoreWidth='60px'/>
          <PortfolioFitNotification/>
          
          </div>
          
          </div>}
      
      <div style={{zIndex:1,width:"100%"
        ,background:"white",padding:"0.25rem",boxSizing:"border-box",minWidth:"700px"
        ,display:"flex",flexDirection:"column",alignItems:"center",gap:"1rem",boxSizing:"border-box"}}>
      
      {showHealth && <MyFinancialHealthCard/>}
      <NetWorthOverview/>
      <PortfolioInfoList menu={"Overview Home"} />
      <CashflowInfo displayType='home' menu={"Net Cashflow"}/>
      <GoalInfo displayType='home' info={"Overview"}/>
      </div>
    </div>
  )
}
