import React, { useEffect, useState } from 'react';
import { useAppInfo } from 'AppState';
import { formatNumber, generateUniqueId } from "utils/utilGeneral";
import TextInput from 'components/Inputs/TextInput';
import { Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAddFinancials from 'data/user/financials/useAddFinancials';

export default function NetCashAllocation() {
  const {stateMyFinancials, dispatchMyFinancials} = useAppInfo();
  const {summaryStats, netCashAllocate,currency} = stateMyFinancials;
  const {addFinancials} = useAddFinancials()
  
  const {totalNetCashflow,totalNetCashflowBeforeReturns} = summaryStats;
  const [fields, setFields] = useState([]);
  const [savedIds, setSavedIds] = useState([]);
  const [unsavedId, setUnsavedId] = useState([]);
  const [reinvest,setReinvest] = useState(true)
  const netCashflowType = reinvest ? totalNetCashflowBeforeReturns : totalNetCashflow
  
  useEffect(() => {
    if (netCashAllocate.length > 0) {
      setFields(netCashAllocate);
      setSavedIds(netCashAllocate.map(obj => obj.id));
    } 
  }, [netCashAllocate]);

  const handleChange = (name, value, id) => {
    let updatedFields = fields.map((fieldObj) => {
      if (fieldObj.id === id) {
        return { ...fieldObj, [name]: value };
      }
      return fieldObj;
    });
    if (!unsavedId.includes(id)) {
      setUnsavedId([...unsavedId, id]);
      setSavedIds(savedIds.filter(obj => obj !== id));
    }
    setFields(updatedFields);
  };

  const getTotalAllocation = () => fields.reduce((acc, curr) => acc + Number(curr.allocation), 0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const totalAllocation = getTotalAllocation();
    if (totalAllocation === 100) {
      dispatchMyFinancials({'netCashAllocate':fields });
      addFinancials(fields,'netCashAllocate')
      const progressUpdate = stateMyFinancials?.profileCompletion
      progressUpdate["netCashAllocate"] = true
      dispatchMyFinancials({"profileCompletion":progressUpdate});
      addFinancials( progressUpdate,"profileCompletion")
      setUnsavedId([]);
    } else {
      alert('The total allocation must equal 100%.');
    }
  };

  return (
        <form onSubmit={handleSubmit} className='containerColumnFlex'>
          <div style={{padding:"0.5rem"}}>
          <h3 style={{fontWeight:"bold",color:"gray"}}>Net Cash Allocation</h3>
          <div style={{padding:"1rem 0",display:"flex",alignItems:"center"}} 
          onClick={()=>setReinvest(!reinvest)}>{reinvest ? <CheckCircleIcon sx={{color:'green'}}/> : <AddCircleOutlineIcon/>} Reinvest the returns on Saving/Investment? </div>
          </div>
          <div className='containerColumnFlex1'>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>Type</th>
                {/* <th rowSpan={2}>Description</th> */}
                <th rowSpan={2}>Allocation (%)</th>
                <th colSpan={3}>Projected Allocation Amount {currency?.symbol}</th>
              </tr>
              <tr>
                <th>Monthly</th>
                <th>Quarterly</th>
                <th>Yearly</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((obj, index) =>
                <React.Fragment key={index}>
                  <tr>
                    <td>{obj.type}</td>
                    {/* <td>{obj.description}</td> */}
                    <td>
                      <TextInput type='number' min={0} max={100} value={obj.allocation} onChange={(v) => handleChange('allocation', Number(v), obj.id)} />
                    </td>
                    <td>{netCashflowType?.m > 0 ? formatNumber(netCashflowType?.m * (obj.allocation / 100)) : <span style={{color:"red"}}><WarningIcon/> No Cash </span>}</td>
                    <td>{netCashflowType?.q > 0 ? formatNumber(netCashflowType?.q * (obj.allocation / 100)) : <span style={{color:"red"}}><WarningIcon/> No Cash</span>}</td>
                    <td>{netCashflowType?.y > 0 ? formatNumber(netCashflowType?.y * (obj.allocation / 100)) : <span style={{color:"red"}}><WarningIcon/> No Cash</span>}</td>
                  </tr>
                </React.Fragment>
              )}
              <tr>
                <td>Total</td>
                <td>{getTotalAllocation()}%</td>
                <td>{formatNumber(netCashflowType?.m)}</td>
                <td>{formatNumber(netCashflowType?.q)}</td>
                <td>{formatNumber(netCashflowType?.y)}</td>
              </tr>
            </tbody>
          </table>
          {(fields.length > 0 && unsavedId.length > 0) && 
            <div>
              <Button variant='contained' type='submit'>Save</Button>
            </div>}
            </div>
        </form>
  );
}

