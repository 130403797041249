import React from 'react';

export default function LogoSVG({ height = 150, width = 120, color = 'black' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 375 375"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <clipPath id="clip1">
          <path d="M 18.7 277 L 262 277 L 262 339 L 18.7 339 Z" />
        </clipPath>
        <clipPath id="clip2">
          <path d="M 202 120 L 356.2 120 L 356.2 363.8 L 202 363.8 Z" />
        </clipPath>
        <clipPath id="clip3">
          <path d="M 80 11.3 L 246 11.3 L 246 268 L 80 268 Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip1)">
        <path
          fill={color}
          d="M 49.24 277.5 L 232.08 277.5 L 261.03 338.2 L 18.77 338.2 Z"
        />
      </g>
      <g clipPath="url(#clip2)">
        <path
          fill={color}
          d="M 332.64 312 L 356.26 363.72 L 284.65 363.72 L 202.37 190.6 L 238.18 120.25 Z"
        />
      </g>
      <g clipPath="url(#clip3)">
        <path
          fill={color}
          d="M 165.04 241.64 L 152.85 267.16 L 80.48 267.16 L 210.75 11.28 L 245.8 83 Z"
        />
      </g>
    </svg>
  );
}
