import React, {
  useMemo,
  useState,
} from 'react';

import { generateUniqueId } from 'utils/utilGeneral';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import {
  Button,
  Dialog,
  IconButton,
} from '@mui/material';

import {
  DeleteTodoButton,
  TodoCRUDModal,
  todoDataInitial,
} from './TodoCRUD';
import {
  categoryColorDark,
  categoryColors,
} from './utils';

function startOfDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0,0,0);
}

function endOfDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23,59,59);
}

function eventSpansDay(evt, dayDate) {
  const evtStart = new Date(evt.dateStart);
  const evtEnd = new Date(evt.dateExpiry);
  const dayStart = startOfDay(dayDate);
  const dayEnd = endOfDay(dayDate);
  return evtStart <= dayEnd && evtEnd >= dayStart; 
}

function getEventsForDay(events, dayDate) {
  const expandedEvents = [];

  events.forEach(evt => {
    if (evt.period && evt.period !== "One Time") {
      // Generate all occurrences once
      const occurrences = generateRecurrences(evt);

      // Filter occurrences for the current day
      const dayOccurrences = occurrences.filter(occ => eventSpansDay(occ, dayDate));
      expandedEvents.push(...dayOccurrences);
    } else {
      // One-time event: just check if it spans this day
      if (eventSpansDay(evt, dayDate)) {
        expandedEvents.push(evt);
      }
    }
  });

  return expandedEvents;
}

function generateRecurrences(evt) {
  const { dateStart, dateExpiry, period } = evt;
  const start = new Date(dateStart);
  
  // Determine increment based on period
  let incrementDays = 0;
  if (period === "Daily") incrementDays = 1;
  if (period === "Weekly") incrementDays = 7;
  if (period === "Monthly") incrementDays = 30;
  if (period === "Quarterly") incrementDays = 90;
  if (period === "Semi-Annually") incrementDays = 180;
  if (period === "Annually") incrementDays = 360;
  if (period === "Yearly") incrementDays = 360;
  // Add Monthly, Yearly, etc. as needed

  // If no increment or unsupported period, return just the original event
  if (!incrementDays) return [evt];

  // Determine the recurrence cutoff
  let recurUntil;
  if (dateExpiry && dateExpiry !== "") {
    recurUntil = new Date(dateExpiry);
  } else {
    recurUntil = new Date(start);
    recurUntil.setFullYear(recurUntil.getFullYear() + 1); // default cutoff: 1 year from start
  }

  const occurrences = [];

  // Normalize the occurrence start to midnight of the start date
  let occurrenceStart = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0);

  while (occurrenceStart <= recurUntil) {
    // End of the same day
    const occurrenceEnd = new Date(
      occurrenceStart.getFullYear(),
      occurrenceStart.getMonth(),
      occurrenceStart.getDate(),
      23, 59, 59, 999
    );

    // Add the occurrence if it starts before or on the cutoff date
    if (occurrenceStart <= recurUntil) {
      occurrences.push({
        ...evt,
        dateStart: occurrenceStart.toISOString(),
        dateExpiry: occurrenceEnd.toISOString(),
      });
    }

    // Move to the next occurrence
    occurrenceStart.setDate(occurrenceStart.getDate() + incrementDays);
    occurrenceStart.setHours(0,0,0,0); // ensure it stays aligned to midnight
  }

  return occurrences;
}



function isFullDayEventForDate(evt, dayDate) {
  const evtStart = new Date(evt.dateStart);
  const evtEnd = new Date(evt.dateExpiry);
  const dayStart = startOfDay(dayDate);
  const dayEnd = endOfDay(dayDate);
  return evtStart <= dayStart && evtEnd >= dayEnd;
}

function splitDayEvents(events, dayDate) {
  const fullDay = [];
  const timed = [];
  events.forEach(evt => {
    if (isFullDayEventForDate(evt, dayDate)) {
      fullDay.push(evt);
    } else {
      timed.push(evt);
    }
  });
  return { fullDay, timed };
}

function formatTime(date) {
  return date.getHours().toString().padStart(2,'0') + ':' + date.getMinutes().toString().padStart(2,'0');
}

// Build the calendar matrix for a given month
function buildCalendarMatrix(year, month) {
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month+1, 0);
  const startDay = firstDayOfMonth.getDay();
  const daysInMonth = lastDayOfMonth.getDate();
  
  const dayCells = [];
  for (let i = 0; i < startDay; i++) {
    dayCells.push(null);
  }
  for (let d = 1; d <= daysInMonth; d++) {
    dayCells.push(d);
  }
  
  const weeks = [];
  for (let i = 0; i < dayCells.length; i += 7) {
    weeks.push(dayCells.slice(i, i+7));
  }
  return weeks;
}

function PriorityIcons({ priority, size='medium' }) {
  const iconStyle = { color: '#ff5722', fontSize: size === 'small' ? '14px' : '18px' };
  const icons = [];
  for (let i = 0; i < priority; i++) {
    icons.push(<WhatshotIcon key={i} style={iconStyle} />);
  }
  return <span>{icons}</span>;
}

export default function Calendar({ 
  events = [],
  view = 'big', // 'big' or 'small'
  onAddTodo,    // onAddTodo({name, category, priority, dateStart, dateExpiry})
  onExpand      // called when the expand icon is clicked in small view
}) {
  const today = new Date();
  const [displayDate, setDisplayDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const year = displayDate.getFullYear();
  const month = displayDate.getMonth();
  const weeks = useMemo(() => buildCalendarMatrix(year, month), [year, month]);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDayEvents, setSelectedDayEvents] = useState([]);

  const [addEventModalOpen, setAddEventModalOpen] = useState(false);

  const [newEventData, setNewEventData] = useState({...todoDataInitial,id:generateUniqueId()});

  const openDayView = (day) => {
    if (!day) return;
    const date = new Date(year, month, day);
    const evs = getEventsForDay(events, date);
    setSelectedDay(date);
    setSelectedDayEvents(evs);
  };

  const closeDayView = () => {
    setSelectedDay(null);
    setSelectedDayEvents([]);
  };

  const handleHourClick = (hour) => {
    const start = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate(), hour, 0, 0);
    const end = new Date(start.getTime() + 60*60*1000); // 1 hour event
    setNewEventData({...todoDataInitial,
      dateStart: formatDateTimeLocal(start),
      dateExpiry: formatDateTimeLocal(end),id:generateUniqueId()});
    setAddEventModalOpen(true);
  };

  const handleAddEventModalClose = () => {
    setAddEventModalOpen(false);
    setNewEventData({...todoDataInitial,id:generateUniqueId()});
    closeDayView();
  };

  const goPrevMonth = () => {
    setDisplayDate(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
  };

  const goNextMonth = () => {
    setDisplayDate(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
  };

  const goToday = () => {
    const today = new Date();
    setDisplayDate(new Date(today.getFullYear(), today.getMonth(), 1));
  };

  function renderEvent(evt) {
    const evtColor = categoryColors[evt.category] || categoryColors.default;
    // const [update,setUpdate] = useState(false)
    return (
      <>
      <div
        key={evt.id}
        // onClick={()=>setUpdate(true)}
        style={{
          background: evtColor,
          borderRadius: '3px',
          fontSize: view === 'big' ? '10px' : '9px',
          padding: '2px',
          marginBottom: '2px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'flex',
          alignItems: 'center',
          gap: '2px'
        }}
        title={`${evt.name}\nStart: ${new Date(evt?.dateStart).toLocaleString()}\nEnd: ${new Date(evt?.dateExpiry).toLocaleString()}`}
      >
        <div style={{flex:1,display:"flex",gap:"2px",alignItems:"center"}}>
        <PriorityIcons priority={evt.priority} size={view === 'big' ? 'medium' : 'small'} />
        {evt.name}</div>
        <DeleteTodoButton allowDelete={evt?.delete} data={evt}/>
      </div>
      {/* <TodoCRUDModal editOperation='edit' onClose={()=>setUpdate(false)} open={update} data={evt}/> */}
      </>
    );
  }

  return (
    <div style={{
      fontFamily: 'Arial', 
      padding: '10px', 
      borderRadius:"5px",
      // boxShadow:'0px 4px 8px rgba(0, 0, 0, 0.1)',
      position: 'relative', 
      maxWidth: view === 'big' ? '100%' : '300px'
    }}>
       
      {/* Top Controls */}
      <div style={{
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        marginBottom: '10px'
      }}>
       
        <div style={{ fontWeight: 'bold', fontSize: view==='big'?'18px':'14px',flex:1}}>
          {displayDate.toLocaleString('default', { month: 'long' })} {year}
        </div>
        <div style={{display:'flex', justifyContent:"flex-end", alignItems:'center', gap:'5px' }}>
          
          <Button variant="highlight" sx={{fontSize:"0.8rem"}} onClick={goToday}>Today</Button>
          {/* {view === 'small' && (
            <IconButton onClick={onExpand} style={{ height: '30px', width: '30px' }}>
              <FullscreenIcon fontSize="small" />
            </IconButton>
          )} */}
        </div>
        <div style={{ display:'flex', gap:'5px' }}>
          
          <IconButton onClick={goPrevMonth} style={{ height: '30px', width: '30px' }}>
            <ArrowLeftIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={goNextMonth} style={{ height: '30px', width: '30px' }}>
            <ArrowRightIcon fontSize="small" />
          </IconButton>
        </div>
      </div>

      {/* Day Labels */}
      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(7, 1fr)', 
        textAlign: 'center', 
        marginBottom: '5px', 
        fontWeight: 'bold', 
        fontSize: view === 'big' ? '14px' : '12px'
      }}>
        <div>Sun</div><div>Mon</div><div>Tue</div><div>Wed</div><div>Thu</div><div>Fri</div><div>Sat</div>
      </div>

      {/* Weeks with events */}
      {weeks.map((week, wIndex) => (
        <div key={wIndex} style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)'}}>
          {week.map((day, dIndex) => {
            const cellDate = day ? new Date(year, month, day) : null;
            const isToday = cellDate && cellDate.toDateString() === new Date().toDateString();
            if (!day) {
              return (
                <div 
                  key={dIndex} 
                  style={{ 
                    minHeight: view==='big'?'80px':'40px', 
                    border: '1px solid #eee', 
                    background: '#f9f9f9' 
                  }}
                ></div>
              );
            }

            const dayEvents = getEventsForDay(events, cellDate);

            return (
              <div 
                key={dIndex} 
                style={{ 
                  minHeight: view==='big'?'80px':'40px',
                  border: '1px solid #eee', 
                  padding: '5px', 
                  cursor: 'pointer', 
                  // background: isToday ? '#e6f7ff' : 'white',
                  position: 'relative',textAlign:'center' 
                }}
                onClick={() => openDayView(day)}
              >
                <div style={{ 
                  fontWeight: 'bold', 
                  marginBottom: view==='big'?'5px':'2px', 
                  fontSize: view==='big'?'12px':'10px', 
                  background:isToday && "var(--selectBlue)",
                  borderRadius:"5px",
                  padding:"0.5rem 0",
                  color:isToday && "#fff",
                }}>
                  {day}
                </div>
                {/* If small view, just show darkened dots for events */}
                {view === 'small' ? (
                  <div style={{display:'flex',textAlign:"left",gap:'3px', flexWrap:'wrap', justifyContent:'center', marginTop:'2px'}}>
                    {dayEvents.slice(0,3).map(evt => (
                      <div key={evt.id} style={{
                        width:'6px', 
                        height:'6px', 
                        borderRadius:'50%', 
                        backgroundColor: categoryColorDark[evt.category] || categoryColors.default,
                      }}></div>
                    ))}
                    
                    {dayEvents.length > 3 && (
                      <div style={{fontSize:'10px', color:'#555'}}>+{dayEvents.length-3}</div>
                    )}
                  </div>
                ) : (
                  dayEvents.map(evt => renderEvent(evt))
                )}
              </div>
            );
          })}
        </div>
      ))}

      {/* Day View Modal */}
      <Dialog open={!!selectedDay} onClose={closeDayView} maxWidth="sm" fullWidth>
        {selectedDay && (() => {
          const { fullDay, timed } = splitDayEvents(selectedDayEvents, selectedDay);
          return (
            <div style={{ padding: '20px', fontFamily: 'Arial' }}>
              <h3 style={{ marginTop: 0 }}>{selectedDay.toDateString()}</h3>
              {fullDay.length > 0 && (
                <div style={{ marginBottom: '10px' }}>
                  <h4 style={{ margin: '5px 0' }}>Full-Day Events:</h4>
                  {fullDay.map(e => {
                    const evtColor = categoryColors[e.category] || categoryColors.default;
                    return (
                      <div 
                        key={e.id} 
                        style={{ 
                          background: evtColor, 
                          padding: '5px', 
                          marginBottom: '5px', 
                          borderRadius: '3px',
                          color: '#000',
                          display:'flex', 
                          gap:'5px',
                          alignItems:'center'
                        }}
                      >
                         <PriorityIcons priority={e.priority} size={view === 'big' ? 'medium' : 'small'} />
                        {e.name} 
                       
                      </div>
                    );
                  })}
                </div>
              )}
              <h4 style={{ margin: '5px 0' }}>Hourly Schedule:</h4>
              <div style={{ maxHeight: '400px', overflowY: 'auto', border: '1px solid #ccc', padding: '5px' }}>
                {[...Array(24).keys()].map(hour => {
                  const hourStart = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate(), hour, 0, 0);
                  const hourEnd = new Date(hourStart.getTime() + 60*60*1000);
                  const hourEvents = timed.filter(e => {
                    const eStart = new Date(e.dateStart);
                    const eEnd = new Date(e.dateExpiry);
                    return (eStart < hourEnd && eEnd > hourStart);
                  });
                  return (
                    <div 
                      key={hour} 
                      style={{ 
                        borderBottom: '1px solid #eee', 
                        padding: '5px', 
                        position: 'relative', 
                        cursor: 'pointer' 
                      }}
                      onClick={(e) => { e.stopPropagation(); handleHourClick(hour); }}
                    >
                      <strong>{hour.toString().padStart(2, '0')}:00</strong>
                      {hourEvents.map(ev => {
                        const evtColor = categoryColors[ev.category] || categoryColors.default;
                        return (
                          <div 
                            key={ev.id} 
                            style={{ 
                              background: evtColor, 
                              padding: '2px 5px', 
                              marginTop: '2px', 
                              borderRadius: '3px', 
                              display:'flex',
                              alignItems:'center',
                              gap:'2px'
                            }}
                          >
                            <PriorityIcons priority={ev.priority} size={view === 'big' ? 'medium' : 'small'} />
                            
                            {ev.name} 
                            
                            ({formatTime(new Date(ev.dateStart))} - {formatTime(new Date(ev.dateExpiry))})
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <div style={{ textAlign: 'right', marginTop: '10px' }}>
                <Button variant="contained" onClick={closeDayView}>Close</Button>
              </div>
            </div>
          );
        })()}
      </Dialog>

      <TodoCRUDModal open={addEventModalOpen} onClose={handleAddEventModalClose}
      data={newEventData}
      />
    </div>
  );
}


const pad = (num) => String(num).padStart(2, '0');
const formatDateTimeLocal = (date) => {
  
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // getMonth() is zero-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
