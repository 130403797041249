import React, { useState } from 'react'
import { useAppInfo } from 'AppState'
import { Button, Skeleton } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { formatNumber } from 'utils/utilGeneral'
import { useNavigate } from 'react-router-dom'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { navLinkDict } from 'nav/navLinks'

export default function PortfolioMetricOverview({ displayType = "card",size="large"}) {
  const { statePortfolio,stateMyFinancials} = useAppInfo()
  const { portfolioResults,portfolioOverview,portfolioTotal} = statePortfolio
  const [expandAccountDetails, setExpandAccountDetails] = useState(true)
  const portfolioSelected = portfolioOverview
  const {currency} = stateMyFinancials
  return (
    <>
      {displayType === "bar" && 
         <div style={{ width: '100%', padding: '0.5rem', boxSizing: 'border-box', background: 'white', borderRadius: '5px' }}>
         {!portfolioSelected && <Skeleton variant='rect' sx={{height:"50px",width:"100%"}}/>}
         {portfolioSelected && (
           <div style={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap', alignItems: 'center' }}>
             <RenderMetricBar 
             size={size}
             currency={currency?.symbol}
             label="Portfolio Value"  value={portfolioSelected["NAV"]} />
             <RenderMetricBar 
             size={size}
             currency={currency?.symbol}
             label="Investment Cost" value={portfolioSelected["Investment Cost"]} />
             <RenderMetricBar
               size={size}
               currency={currency?.symbol}
               label="Overall Unrealized Gain/Loss"
               value={portfolioSelected["Profit/Loss"]}
               profitLoss={portfolioSelected["Profit/Loss"]}
               percentage={portfolioSelected["PLpercent"]}
             />
             <RenderMetricBar
               size={size}
               currency={currency?.symbol}
               label="Overall Realized Gain/Loss"
               value={portfolioSelected["Realized P/L"]}
               profitLoss={portfolioSelected["Realized P/L"] || 0}
             />
           </div>
         )}
       </div>
      }
      {displayType === "table" && portfolioResults  && (
        <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
        {(Object.keys(portfolioResults).length > 1 && portfolioTotal && portfolioResults) && 
        (<PortfolioTotalDisplay portfolioTotal={portfolioTotal}/>)}
        {Object.keys(portfolioResults).length > 1 && 
        <Button variant='highlight' endIcon={expandAccountDetails?<ExpandLess/>:<ExpandMore/>} 
          sx={{textTransform:"none",fontSize:"1rem",width:"100%",justifyContent:"flex-start"}} 
          onClick={() => setExpandAccountDetails(!expandAccountDetails)}>Account Details</Button>}
          
          {(expandAccountDetails || Object.keys(portfolioResults).length === 1) && 
          Object.entries(portfolioResults).map(([accountNameSel, portfolioDetails]) => (
            <div key={accountNameSel}>
              <table>
                <thead>
                  <tr>
                    <th>Account: {accountNameSel}</th>
                    <th>Value {currency?.symbol}</th>
                  </tr>
                </thead>
                <tbody>
                  <RenderTableCell currency={currency?.symbol} label="Latest Value" value={portfolioDetails?.portfolioOverviewAll?.NAV}/>
                  <RenderTableCell currency={currency?.symbol} label="Investment Cost" value={portfolioDetails?.portfolioOverviewAll?.["Investment Cost"]}/>
                  <RenderTableCell currency={currency?.symbol} label="Investment Cost" value={portfolioDetails?.portfolioOverviewAll?.["Investment Cost"]}/>
                  <RenderTableCell currency={currency?.symbol} label="Overall Unrealized P/L" value={portfolioDetails?.portfolioOverviewAll?.["Profit/Loss"]} isProfitLoss/>
                  <RenderTableCell currency={currency?.symbol} label="Overall Realized P/L" value={portfolioDetails?.portfolioOverviewAll?.["Realized P/L"]} isProfitLoss/>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}
      {displayType === "card" && 
      <PortfolioTotalDisplay portfolioTotal={portfolioTotal}/>}
    </>
  )
}

function RenderTableCell({label,currency,value,isProfitLoss}){
  const formatValue = formatNumber(Number(value)) || 0
  return (
    <tr>
      <td>{label}</td>
      <td>
      {formatValue}
      {isProfitLoss && (
            <span style={{ color: value >= 0 ? "green" : value < 0 ? "red" : "black", fontSize: "1rem", marginLeft: "0.2rem" }}>
              {value >= 0 ? '▲' : value < 0 ? '▼' : ""}
            </span>
          )}
      </td>
      </tr>
  )
}

function RenderMetricBar({ label,currency,value, profitLoss, percentage,size="large"}) {
  const formatValue = formatNumber(Number(value)) || 0
  const formatPL = formatNumber(profitLoss) || 0
  const fontSizeMetric = size === "large" ? "1.6rem":"1rem"
  return (
    <div>
      <h4 style={{ display: 'block', color: 'black',fontWeight:"bold"}}>{label}</h4>
      <div style={{ display: 'flex', gap: '0.3rem', alignItems: 'center' }}>
        {profitLoss === undefined && <h2 style={{fontSize:fontSizeMetric}}>{currency} {formatValue}</h2>}
        {profitLoss !== undefined && (
          <>
          <span style={{ color: profitLoss >= 0 ? 'green' : 'red', fontSize: '1rem', marginRight: '0.2rem' }}>
            {profitLoss >= 0 ? '▲' : '▼'}
          </span>
          <h2 style={{ color: profitLoss >= 0 ? 'green' : 'red',fontSize:fontSizeMetric}}>{currency} {formatValue}</h2>
          </>
        )}
        
        
        {percentage !== undefined && (
          <h2 style={{ color: profitLoss >= 0 ? 'green' : 'red',fontSize:fontSizeMetric }}>({percentage}%)</h2>
        )}
      </div>
    </div>
  );
}


function RenderPortfolioCard({label,currency,value,isProfitLoss,colorGain}){
  const formatValue = value ? formatNumber(Number(value)) : "-"
  return (
  <Button style={{ display: 'flex', justifyContent: 'space-between',width:"100%"
    ,padding:"0.05rem",color:"black",textAlign:"left",wordBreak:"normal"}}>
    <span>{label}</span>
    <span style={{marginLeft:"auto",whiteSpace:"nowrap",color: (value >= 0 && colorGain) ? "green" : (value < 0  && colorGain) ? "red" : "black"}}>
      {formatValue === "-" && "-"}  
      {formatValue !== "-" &&
      <>
      {currency} {formatValue}
      {isProfitLoss && (
        <span style={{ color: value >= 0 ? "green" : value < 0 ? "red" : "black", fontSize: "1rem", marginLeft: "0.2rem" }}>
          {value >= 0 ? '▲' : value < 0 ? '▼' : ""}
        </span>
      )}</>}
      <ArrowOutwardIcon sx={{color:"var(--selectBlue)",fontSize:"0.9rem",paddingLeft:"0.5rem",opacity:"0"}}/>
    </span>
  </Button>
  )
}

function PortfolioTotalDisplay({portfolioTotal}){
 const {stateMyFinancials} = useAppInfo()
 const navigate = useNavigate()
 const {currency} = stateMyFinancials
 const formatValue = portfolioTotal?.NAV ? formatNumber(Number(portfolioTotal?.NAV)) : "-"
 const profit = Number(portfolioTotal?.NAV) - Number(portfolioTotal?.["Investment Cost"])
 return (<div style={{display:"flex",flexDirection:"column",gap:"0.25rem"}}>
  <Button onClick={()=>navigate(navLinkDict?.Portfolio?.path)} 
  style={{display:'flex',fontWeight:"bold",color:"black",padding:"0.25rem 0",justifyContent:"flex-start",textAlign:"left"}}>
  <span style={{flex:1,paddingRight:"3rem"}}>Portfolio Value (all accounts)</span>
  
  <span style={{color:profit>=0?"green":"red"}}>
    {portfolioTotal?.NAV && currency?.symbol} {formatValue}
  </span>
  <ArrowOutwardIcon sx={{color:"var(--selectBlue)",fontSize:"0.9rem",paddingLeft:"0.5rem",opacity:"1"}}/>
  </Button>
  <div style={{paddingLeft:"1rem"}}>
  {/* <RenderPortfolioCard currency={currency?.symbol} label="Latest Value" value={portfolioTotal?.NAV}/> */}
  <RenderPortfolioCard colorGain={false} currency={currency?.symbol} label="Investment Cost" value={portfolioTotal?.["Investment Cost"]}/>
  <RenderPortfolioCard colorGain={true} currency={currency?.symbol} label="Unrealized P/L" value={portfolioTotal?.["Profit/Loss"]} isProfitLoss/>
  <RenderPortfolioCard colorGain={true} currency={currency?.symbol} label="Realized P/L" value={portfolioTotal?.["Realized P/L"]} isProfitLoss/>
  </div>
</div>)
}

function PortfolioTotalDisplayNew({portfolioTotal}){
  const {stateMyFinancials} = useAppInfo()
  const {currency} = stateMyFinancials
  return (<div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
   <h4 style={{ fontWeight: "bold",borderBottom:"1px solid #ddd"}}>Portfolio Total Summary</h4>
   <div style={{paddingLeft:"1rem"}}>
   <RenderPortfolioCard currency={currency?.symbol} label="Latest Value" value={portfolioTotal?.NAV}/>
   <RenderPortfolioCard currency={currency?.symbol} label="Investment Cost" value={portfolioTotal?.["Investment Cost"]}/>
   <RenderPortfolioCard currency={currency?.symbol} label="Unrealized P/L" value={portfolioTotal?.["Profit/Loss"]} isProfitLoss/>
   <RenderPortfolioCard currency={currency?.symbol} label="Realized P/L" value={portfolioTotal?.["Realized P/L"]} isProfitLoss/>
   </div>
 </div>)
 }

{/* <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>

  </div> */}