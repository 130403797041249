import React, { useState } from 'react';

import {
  Alert,
  Box,
  Typography,
} from '@mui/material';

import FinancialProductModal from './FinancialProductModal';
import FinancialTips from './FinancialTips';

const FinancialProductInfoListTemplate = ({ data, productGeneralInfo }) => {
    const [modal, setModal] = useState({ data: null, modal: false });

    if (!data || data.length === 0) return <p>Loading...</p>;

    const { heading, disclaimerFor, financialTipPositive, financialTipNegative, columns } = productGeneralInfo;
    const factKeys = Array.from(
        new Set(data.flatMap(card => card.Facts.map(fact => Object.keys(fact)[0])))
    );
    const date = new Date();
    const monthShort = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const date_word = monthShort + "," + year;

    return (
        <Box sx={{ py: 3, px: { xs: 1, sm: 2 } }}>
            <Box sx={{ mb: 3, maxWidth: '1400px', mx: 'auto' }}>
                <Typography 
                    variant="h4" 
                    component="h1" 
                    sx={{ 
                        fontWeight: 400,
                        color: '#2C3E50',
                        mb: 2,
                        fontSize: '1.75rem',
                        letterSpacing: '0.3px'
                    }}
                >
                    {heading}
                </Typography>
                
                <Box sx={{ mb: 3 }}>
                    <Alert 
                        severity="warning" 
                        sx={{ 
                            backgroundColor: '#FFF9F5',
                            '& .MuiAlert-icon': {
                                color: '#F57C00'
                            }
                        }}
                    >
                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                            <strong>Disclaimer:</strong> {`This list is for demo purposes only, showcasing top ${disclaimerFor} as of ${date_word}. We do not personally endorse any specific ${disclaimerFor} and do not receive any referral fees.`}
                        </Typography>
                    </Alert>
                </Box>

                <FinancialTips 
                    financialTipPositive={financialTipPositive} 
                    financialTipNegative={financialTipNegative}
                />
            </Box>

            <Box sx={{ 
                overflowX: 'auto',
                maxWidth: '1400px',
                mx: 'auto',
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
            }}>
                <table style={{ 
                    width: '100%', 
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed'
                }}>
                    <thead>
                        <tr style={{ backgroundColor: '#F8F9FA' }}>
                            {columns.map((column, index) => (
                                <th key={index} style={{ 
                                    padding: '12px 16px',
                                    textAlign: 'left',
                                    fontWeight: 500,
                                    color: '#2C3E50',
                                    fontSize: '0.813rem',
                                    letterSpacing: '0.3px'
                                }}>
                                    {column}
                                </th>
                            ))}
                            {factKeys.map((key, index) => (
                                <th key={index} style={{ 
                                    padding: '12px 16px',
                                    textAlign: 'left',
                                    fontWeight: 500,
                                    color: '#2C3E50',
                                    fontSize: '0.813rem',
                                    letterSpacing: '0.3px'
                                }}>
                                    {key}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((card, cardIndex) => (
                            <tr key={cardIndex} style={{ 
                                backgroundColor: '#FFFFFF',
                                transition: 'background-color 0.2s ease',
                                '&:hover': {
                                    backgroundColor: '#F5F7FA'
                                }
                            }}>
                                <td style={{ padding: '12px 16px' }}>
                                    <a
                                        href={card["Card Link"]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2C3E50',
                                            display: "flex",
                                            gap: "1rem",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            padding: '0.25rem',
                                            width: '100%',
                                            transition: 'all 0.2s ease',
                                            '&:hover': {
                                                backgroundColor: '#F5F7FA'
                                            }
                                        }}
                                    >
                                        <img
                                            src={card["Image URL"]}
                                            alt={`${card["Card Title"]} Image`}
                                            style={{
                                                width: '100px',
                                                height: '60px',
                                                maxHeight: "60px",
                                                maxWidth: '100px',
                                                objectFit: "contain",
                                                flexShrink: 0
                                            }}
                                        />
                                        <span
                                            style={{
                                                flex: 1,
                                                fontSize: '0.875rem',
                                                lineHeight: '1.4',
                                                color: '#2C3E50'
                                            }}
                                        >
                                            {card["Card Title"]}
                                        </span>
                                    </a>
                                </td>
                                <td style={{ padding: '12px 16px', color: '#2C3E50', fontSize: '0.875rem' }}>{card["Rating Value"]} ⭐</td>
                                <td style={{ padding: '12px 16px', color: '#2C3E50', fontSize: '0.875rem' }}>{card["Card Best For"]}</td>
                                {columns.includes("Reward Rate") && <td style={{ padding: '12px 16px', color: '#2C3E50', fontSize: '0.875rem' }}>{card["Reward Rate"]}</td>}
                                {columns.includes("Reward Description") && <td style={{ padding: '12px 16px', color: '#2C3E50', fontSize: '0.875rem', minWidth: "200px" }}>{card["Reward Description"]}</td>}
                                {factKeys.map((key, index) => {
                                    const fact = card.Facts.find(fact => fact[key]);
                                    return (
                                        <td key={index} style={{ padding: '12px 16px', color: '#666', fontSize: '0.875rem' }}>
                                            {fact ? fact[key] : 'N/A'}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>

            {modal.modal && (
                <FinancialProductModal 
                    closeModal={() => setModal({ data: null, modal: false })} 
                    card={modal.data}
                />
            )}
        </Box>
    );
};

export default FinancialProductInfoListTemplate;
