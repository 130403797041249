import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import "./style/appLayout.css";
import "./style/PageLayout.css";
import "./style/animation.css";
import "./style/Nav.css";
import "./style/media.css";
import "./style/font.css";
import "./style/formElements.css";
import "./style/display.css";
import "./style/reactFlow.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { registerLicense } from '@syncfusion/ej2-base';
import "/node_modules/flag-icons/css/flag-icons.min.css";


// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjW39fcX1WRGJeUEB3Vg==');

// Sentry.init({
//   dsn: "https://46e42b5c3dbe24e7819bbd990b5ecb45@o4506645131034624.ingest.sentry.io/4506645133197312",
//   integrations: [
//     new Sentry.Replay(),
//   ],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
