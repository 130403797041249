import React, { useState } from 'react';

import { useAppInfo } from 'AppState';
import CrudModal from 'components/Modal/CrudModal';
import Modal from 'components/Modal/Modal';
import useAddWatchlist from 'data/user/watchlist/useAddWatchlist';

import { Button } from '@mui/material';

import SearchTickerAddNewWatchlist from '../search/SearchTickerAddNewWatchlist';

export default function WatchlistCreateModal() {
  const { stateWatchlist, dispatchWatchlist, dispatchSidebar } = useAppInfo();
  const {watchlistData} = stateWatchlist
  const [watchlistSelected, setWatchlistSelected] = useState();
  const [watchlistTickerSelected,setWatchlistTickerSelected] = useState([])
  const { addWatchlist, error } = useAddWatchlist(watchlistSelected);
  const [addTicker, setAddTicker] = useState(false);

  function handleAddTicker(v) {
    setWatchlistSelected(v)
    setAddTicker(true);
  }

  function handleCloseModal(){
    dispatchWatchlist({"createWatchlist":false})
  }
  function handleCloseTickerList() {
    setAddTicker(false);
    handleCloseModal();
  }
  function handleSubmit() {
    setAddTicker(false);
    watchlistData[watchlistSelected] = watchlistTickerSelected;
    dispatchWatchlist({
      watchlistSelected,
      watchlistData,
      watchlistMenu: false
  });
    dispatchSidebar({"sidebarOpen":true,"sidebarRightContent":"watchlist" });
    handleCloseModal();
    addWatchlist(watchlistTickerSelected);
  }

  const handleAddToWatchlist = (ticker) => {
    let selected;
    if (watchlistTickerSelected.includes(ticker)) {
      selected = watchlistTickerSelected.filter((item) => item !== ticker);
    } else {
      selected = [...watchlistTickerSelected,ticker]
    }
    setWatchlistTickerSelected(selected);
  };


  return (
    <>
      {stateWatchlist.createWatchlist && (
        <CrudModal
        placeholder={"enter a watchlist name"}
        title={"Watchlist"}
        onClose={handleCloseModal}
        handleCreate={handleAddTicker}
        createButton="Next: Add Watchlist"
        />
      )}

      {addTicker && (
        <Modal onClose={handleCloseTickerList}>
          <form onSubmit={(e)=>{e.preventDefault();handleSubmit()}} style={{display:"flex",flexDirection:"column",height:"100%",width:"100%",gap:"1rem"}}>
          <div style={{display:"flex",flex:1,overflow:"auto"}}>
          <SearchTickerAddNewWatchlist 
          handleAddToWatchlist={handleAddToWatchlist} 
          selected={watchlistTickerSelected}/>
          </div>
          <Button variant="contained" type="submit">Create Watchlist</Button>
          </form>
        </Modal>
      )}
    </>
  );
}
