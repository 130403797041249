import { BASE_URL } from 'apiConfig';
import axios from 'axios';

export const sendEmailForUser = async (userEmail, recipientEmail, subject, body,userPhoto) => {
  try {
    const response = await axios.post(`${BASE_URL}/send-email-for-user/`, {
      user_email: userEmail,
      recipient_email: recipientEmail,
      subject,
      body,
      user_photo:userPhoto
    });
    // console.log('Email sent successfully:', response.data);
    return `Email sent successfully to ${recipientEmail}`;
  } catch (error) {
    console.error('Error sending email:', error.response ? error.response.data : error);
    return `Error sending email to ${recipientEmail}`
  }
};