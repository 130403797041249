import { useState } from 'react';

import Animation from 'animation/Animation';
import Modal from 'components/Modal/Modal';
import { menuIcons } from 'nav/menuIcons';
import { useNavigate } from 'react-router-dom';

import NearMeIcon from '@mui/icons-material/NearMe';
import {
  Button,
  Typography,
} from '@mui/material';

function TemplateAction({
    sx = {},                  // Additional styles
    children,                 // Custom content to render
    actionHeading,            // Heading for the action
    actions = [],             // Array of action buttons with labels and handlers
    professional = "Financial Advisor",  // Default professional reference
    financialProduct = ""     // Financial product reference
}) {

    const navigate = useNavigate()
    const [actionModal, setActionModal] = useState(false);
    return (
        <div style={{ maxWidth: "500px", ...sx }}>
            {children && <Typography sx={{ fontWeight: "bold" }} variant="body1"  gutterBottom>Key insights from your financial data:</Typography>}
            {children && 
            
            <div style={{ marginBottom: "16px",background:"var(--colorSuccess)",padding:"1rem",borderRadius:"5px"}}>{children}</div>}

            {actionHeading && (
                <Typography sx={{ fontWeight: "bold" }} variant="body1" gutterBottom>
                    {actionHeading}
                </Typography>
            )}
            <div style={{ display: "flex", flexDirection: "column", gap: "12px",background:"var(--colorInformation)",padding:"1rem",borderRadius:"5px"}}>
            {actions.length > 0 && (
                <>
                    {actions.map((action, index) => (
                        action?.label && 
                        <Button
                        sx={{width:"max-content",justifyContent:"flex-start",textAlign:"left"}}
                            key={index}
                            variant={"text"}
                            color={action.color || "primary"}
                            size="small"
                            startIcon={action?.icon || <NearMeIcon />}
                            onClick={()=>setActionModal(true)}  
                        >
                            {action.label}
                        </Button>
                    ))}
               </>
            )}

            {professional && (
                <Button
                sx={{width:"max-content",justifyContent:"flex-start",textAlign:"left"}}
                onClick={() => navigate(`/AskProfessional/`)}
                startIcon={menuIcons?.AskProfessional} variant="text">
                    Connect with a {professional}
                </Button>
            )}
            {financialProduct && (
                <Button
                sx={{width:"max-content",justifyContent:"flex-start",textAlign:"left"}}
                onClick={() => navigate(`/FinancialProducts/${financialProduct}`)}
                startIcon={menuIcons?.Product} variant="text">
                    Get {financialProduct}
                </Button>
            )
            }
             </div>
             {actionModal && <Modal onClose={()=>setActionModal(false)}>
                <div style={{width:"100%",height:"100%",overflow:"hidden",display:"flex"
                    ,alignItems:"center",justifyContent:"center"}}>
                    <Animation type="Coming Soon"/>
                </div>
                </Modal>}
        </div>
    );
}

export default TemplateAction;
