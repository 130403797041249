import TemplateAction from "./TemplateAction";
import { Typography, Button } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SavingsIcon from "@mui/icons-material/Savings";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { useAppInfo } from "AppState";
import { calculateTotal } from "./utils";
import { formatNumber } from "utils/utilGeneral";

export function CreditCardDebt() {
    const { stateMyFinancials } = useAppInfo();
    const { summaryStats, liabilities, currency } = stateMyFinancials;
    const { totalIncome, totalExpense, totalNetCashflow } = summaryStats;

    const monthlyIncome = totalIncome?.m || 0;
    const monthlyExpense = totalExpense?.m || 0;
    const netCashFlow = totalNetCashflow?.m || 0;

    const hasSurplus = netCashFlow > 0;
    const canCutExpenses = monthlyExpense > 0.7 * monthlyIncome; // Arbitrary threshold for "high" expenses
    const debt = calculateTotal(liabilities, ["Credit Card"]); // Custom function to calculate debt total

    return (
        <TemplateAction
            actionHeading={"Here are some ways to help you tackle your Credit Card Debt:"}
            actions={[{
                label: "Review and reduce discretionary expenses",},
            {label:"Allocate surplus cash to debt repayment"},
        {label:"Build an emergency fund to avoid future debt"}]}
        >
            <Typography variant="body1" gutterBottom>
                You currently have <strong>{`${currency?.symbol}${formatNumber(debt)}`}</strong> in credit card debt. Credit card debt typically comes with high interest rates, so paying it off should be a priority.
            </Typography>

            {hasSurplus ? (
                <Typography variant="body1" gutterBottom>
                    With a monthly net cash flow of <strong>{`${currency?.symbol}${formatNumber(netCashFlow)}`}</strong>, you can allocate a portion of your surplus toward reducing your credit card debt.
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Your expenses currently exceed your income. To start tackling your credit card debt, you’ll need to reduce expenses or explore additional sources of income. 
                </Typography>
            )}

       
        </TemplateAction>
    );
}


export function HighDebtToIncome() {
    const { stateMyFinancials } = useAppInfo();
    const { summaryStats, liabilities, liabilityStats, currency } = stateMyFinancials;
    const { totalIncome, totalNetCashflow } = summaryStats;

    const totalMonthlyPayments = liabilityStats?.totalMonthlyPayments || 0; // Total monthly debt payments
    const monthlyIncome = totalIncome?.m || 0; // Monthly income
    const debtToIncomeRatio = monthlyIncome > 0 ? totalMonthlyPayments / monthlyIncome : undefined;

    const isHighDTI = debtToIncomeRatio > 0.3; // Above best practice threshold of 30%

    return (
        <TemplateAction
            actionHeading={"Here are some ways to help you tackle your debt:"}
            actions={[
                { label: "Allocate surplus cash to debt repayment" },
                { label: "Review and consolidate high-interest debt" },
            ]}
        >
            {debtToIncomeRatio !== undefined ? (
                <Typography variant="body1" gutterBottom>
                    Your current debt-to-income ratio is <strong>{`${formatNumber(debtToIncomeRatio * 100)}%`}</strong>. 
                    {isHighDTI
                        ? " This is above the recommended limit of 30%, which could make it harder to manage your debt and qualify for new credit."
                        : " Congratulations! Your debt-to-income ratio is within the healthy range of under 30%."}
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Debt-to-income ratio could not be calculated because your income details are missing. Focus on tracking your income and expenses to better assess your financial health.
                </Typography>
            )}

            {/* {isHighDTI && (
                <>
                    <Typography variant="body1" gutterBottom>
                        Here’s how you can bring your debt-to-income ratio back under control:
                    </Typography>
                    <ul>
                        <li><strong>Allocate surplus cash:</strong> Use any extra cash flow to pay down high-interest debt faster.</li>
                        <li><strong>Review and consolidate debt:</strong> Consider consolidating loans or transferring balances to a lower-interest option.</li>
                        <li><strong>Limit new debt:</strong> Avoid taking on new loans or credit card balances until your DTI ratio improves.</li>
                        <li><strong>Increase income:</strong> Explore side hustles or additional work opportunities to raise your monthly income.</li>
                    </ul>
                </>
            )} */}

            {!isHighDTI && (
                <Typography variant="body1" gutterBottom>
                    Keep up the good work! Continue managing your finances carefully to maintain a healthy debt-to-income ratio.
                </Typography>
            )}
        </TemplateAction>
    );
}


export function LiabilityCoverage() {
    const { stateMyFinancials } = useAppInfo();
    const { summaryStats, assets, currency } = stateMyFinancials;

    const totalAssets = summaryStats?.totalAssets || 0; // Total assets
    const totalLiabilities = summaryStats?.totalLiabilities || 0; // Total liabilities
    const monthlyNetCashflow = summaryStats?.totalNetCashflow?.m || 0; // Monthly net cashflow
    const totalCash = calculateTotal(assets, ["Cash"]); // Total cash
    const emergencyFundTarget = summaryStats?.totalExpense?.m * 6 || 0; // Emergency fund target (6 months of expenses)
    const liabilityCoverageRatio = totalLiabilities > 0 ? totalAssets / totalLiabilities : undefined;
    const cashProportion = totalAssets > 0 ? (totalCash / totalAssets) * 100 : 0; // Cash as % of total assets
    const hasExcessCash = totalCash > emergencyFundTarget; // If cash exceeds the emergency fund target

    const isCoverageAdequate = liabilityCoverageRatio >= 1.5; // Recommended threshold

    return (
        <TemplateAction
            actionHeading={"Here are some steps to improve your Liability Coverage:"}
            actions={[
                { label: "Manage Liabilities", link: "/MyFinancials/Liabilities" },
                { label: "Invest Excess Cash to Grow Assets" },
            ]}
        >
            {liabilityCoverageRatio !== undefined ? (
                <Typography variant="body1" gutterBottom>
                    Your current liability coverage ratio is{" "}
                    <strong>{`${formatNumber(liabilityCoverageRatio, 2)}`}</strong>.
                    {isCoverageAdequate ? (
                        " Great job! This is above the recommended threshold of 1.5, indicating you have sufficient assets to cover your liabilities."
                    ) : (
                        " This is below the recommended threshold of 1.5. Improving your coverage can enhance your financial stability."
                    )}
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Liability coverage ratio couldn’t be calculated because your financial details are incomplete. Please update your asset and liability information to better assess your financial position.
                </Typography>
            )}

            <Typography variant="body1" gutterBottom>
                Here’s a breakdown of your financial position:
            </Typography>
            <ul>
                <li>
                    <strong>Cash Proportion:</strong> {`${formatNumber(cashProportion, 2)}%`} of your total assets.
                </li>
                <li>
                    <strong>Total Cash:</strong> {`${currency?.symbol}${formatNumber(totalCash)}`} (Emergency fund target:{" "}
                    {`${currency?.symbol}${formatNumber(emergencyFundTarget)}`}).
                </li>
                <li>
                    <strong>Monthly Net Cash Flow:</strong> {`${currency?.symbol}${formatNumber(monthlyNetCashflow)}`}.
                </li>
            </ul>

            {hasExcessCash ? (
                <Typography variant="body1" gutterBottom>
                    You have excess cash above your emergency fund target. Consider investing this excess cash to grow your assets and improve your liability coverage ratio.
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Your cash reserves are below the recommended 6 months of expenses. Focus on building your emergency fund before allocating cash to investments.
                </Typography>
            )}

            {monthlyNetCashflow > 0 ? (
                <Typography variant="body1" gutterBottom>
                    With a positive monthly net cash flow of{" "}
                    <strong>{`${currency?.symbol}${formatNumber(monthlyNetCashflow)}`}</strong>, consider automating contributions to your emergency fund or investment accounts.
                </Typography>
            ) : (
                <Typography variant="body1" gutterBottom>
                    Your monthly net cash flow is negative. Prioritize reducing discretionary expenses or increasing income to free up funds for improving your financial position.
                </Typography>
            )}
        </TemplateAction>
    );
}
