import { Alert } from '@mui/material'
import TabLine from 'components/Tabs/TabLine'
import { FinancialVaultManualEntryTable } from 'financialVault/ManualEntry/FinancialVaultManualEntry'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FinancialVaultUploadTab from '../Upload/FinancialVaultUploadTab'
import { useAppInfo } from 'AppState'
import { UploadMediaButton } from 'components/Upload/UploadMedia'
import useAddFinancialVault from 'data/user/financialVault/useAddFinancialVault'
import { debounce } from 'lodash'
import { useCallback } from 'react'
import FinancialVaultUpload from 'financialVault/Upload/FinancialVaultUpload'
import FinancialVaultTableUpload from 'financialVault/Upload/FinancialVaultTable'
import useHandleVaultUser from 'financialVault/useHandleVaultUser'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ActionFinancialProducts, ActionProfessionalAdvice } from './CategoryActions'

export default function FinancialVaultIdentity() {
    const [tabIndex,setTabIndex] = useState(0)
    const navigate = useNavigate()


    
  return (
         <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"0.5rem"}}>
         
          <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
          <ActionProfessionalAdvice/>
          <ActionFinancialProducts/>
         
          </div>
          <div
         style={{borderRadius:"5px",background:"#fafafa",padding:"1rem",boxSizing:"border-box",display:"flex",alignItems:"center"}}>
            Your identity and personal information is required to get advice from experts and automate financial applications like loans, insurance, credit cards, etc. </div>
         
            <div style={{minHeight:"500px",width:"100%",borderRadius:"25px",background:"#fafafa"
        ,padding:"1rem",boxSizing:"border-box",display:"flex",flexDirection:"column",gap:"0.5rem",height:"auto"}}>
         <TabLine tabsSx={{borderBottom:"none"}} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)} 
        tabList={["Manual Entry","Uploaded Documents"]}/>
         <>
        {tabIndex === 0 && 
        <div style={{width:"100%",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
        <FinancialVaultManualEntryTable category={"Identity & Personal Docs"}/>
        
        </div>}
        {tabIndex === 1 && 
        <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
        <FinancialVaultUpload/>
        <UploadIdentity/>
        </div>
        }
        </>
        </div>
         </div>        
  )
}

export const identityCategories = [
    "Passports",
    "Driver’s License",
    "Social Security Cards",
    "Birth/Marriage Certificates",
    "Insurance Cards (Health, Auto, Home, etc.)",
    "Power of Attorney for Spouse",
    "Young Adult Power of Attorney",
    "Safe Deposit Box Information",
    "SSN Account Details",
    "Details of Dependents (Names, Birthdates, SSNs)",
    "Guardianship Documents",
    "Others"
  ];


const identityData = [{icon:"",category:"Passport",}]


function UploadIdentity() {
  const today = new Date()
  const {dispatchMyFinancials,stateMyFinancials}  = useAppInfo()
  const {vaultData,vaultLoaded} = stateMyFinancials
  const [mediaItems, setMediaItems] = useState([]);
  const [uploadedMediaItems, setUploadedMediaItems] = useState([]);
  const [uploadModal,setUploadModal] = useState(false)
  const { addFinancialVault, error } = useAddFinancialVault()
  const [mouseEnter,setMouseEnter] = useState(false);
  const [change,setChange] = useState(false)
  const [identityData,setIdentityData] = useState([])
  const {handleChange,handleDelete} = useHandleVaultUser()

  useEffect(() => {
      if (vaultData.length>0 && vaultLoaded) {
        setMediaItems(vaultData)
      }
    },[vaultLoaded])

    useEffect(()=>{
      const identity = vaultData.filter(item => item.category === "Identity & Personal Docs")
      const identityUploaded = identity.map((item)=>item?.subCategory)
      const identityToBeUploaded = identityCategories.filter((item)=>!identityUploaded.includes(item)).map((item)=>{return {category:"Identity & Personal Docs",subCategory:item}})
      const updatedIdentity = [...identity,...identityToBeUploaded]
      setIdentityData(updatedIdentity)
            },[vaultData])
  
  const handleFileData = (file,subCategory) => {
    console.log(file,subCategory)
      setMediaItems(prevItems => {
        let updatedMediaItems = [...prevItems,
        { 
          id: file?.id || file.name, // Unique identifier
          category: "Identity & Personal Docs",
          subCategory: subCategory,
          date:today,
          name: file.name,
          url: file?.url, 
          fileType: file.type,
        }
      ]; 
      dispatchMyFinancials({'vaultData':updatedMediaItems})
      return updatedMediaItems;});
      setUploadedMediaItems(prevItems => [
        ...prevItems,
        { 
          id: file?.id || file.name, // Unique identifier
          category: "Identity & Personal Docs",
          subCategory: subCategory, 
          date:today,
          name: file.name,
          url: file?.url, 
          fileType: file.type,
        }
      ])
    };
  
    const handleProgress = (file) => {
      const progress = file.percent;
      setMediaItems(prevItems => {
        let updatedMediaItems = prevItems.map(item =>
          item.id === file.id ? { ...item, progress } : item
        ); 
        dispatchMyFinancials({'vaultData':updatedMediaItems})
        return updatedMediaItems;}
      );
  
      setUploadedMediaItems(prevItems =>
        prevItems.map(item =>
          item.id === file.name ? { ...item, progress } : item
        )
      );
    };
  
    const debouncedAddFinancialVault = useCallback(
      debounce((payload) => {
        addFinancialVault(payload);
      }, 1000), // Adjust debounce delay as needed
      []
    );
  
  
    // Clean up the debounce function on unmount
    useEffect(() => {
      return () => {
        debouncedAddFinancialVault.cancel();
      };
    }, [debouncedAddFinancialVault]);
  
    const handleUploadSuccess = (file) => {
      const url = file?.url;
      // Use a temporary variable to store updated items
      let updatedMediaItems;
      
      setMediaItems(prevItems => {
        updatedMediaItems = prevItems.map(item =>
          item.id === file?.id ? { ...item, url: url, progress: 100 } : item
        );
     
        dispatchMyFinancials({'vaultData':updatedMediaItems})
        if (updatedMediaItems && updatedMediaItems.length > 0) {
          addFinancialVault({data:updatedMediaItems});}
        return updatedMediaItems; // Return updated items to set the state
      })
    };

    const [showTable,setShowTable] = useState({})

    return (
      <>
      <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    {identityCategories.map((item,i)=>
    <div key={i} style={{display:"flex",flexDirection:"column",gap:"0.5rem",borderBottom:"1px solid #e0e0e0",padding:"0.5rem",}}>
    <div style={{display:"flex",gap:"0.5rem",alignItems:"center",padding:"0.5rem",}}>
          <input type='checkbox'  checked={identityData.filter((data)=>data?.subCategory === item && data?.url).length > 0 ? true : false}/>
            
            <div onClick={()=>setShowTable({...showTable,[item]:!showTable[item]})} 
            style={{flex:1,display:"flex",gap:"0.5rem",alignItems:"center",cursor:"pointer"}}>
              {item}
              {identityData.filter((data)=>data?.subCategory === item && data?.url).length > 0 &&
              <>
              {!showTable?.[item] && 
              <KeyboardArrowDownIcon sx={{color:"gray",cursor:"pointer",marginLeft:"auto"}} onClick={()=>setShowTable({...showTable,[item]:!showTable[item]})}/>
              }
              {showTable?.[item] &&
               <KeyboardArrowUpIcon sx={{color:"gray",cursor:"pointer",marginLeft:"auto"}} onClick={()=>setShowTable({...showTable,[item]:!showTable[item]})}/>
              }
              </>
              }
              </div>
            
            
            {
            <UploadMediaButton handleFileData={(v)=>handleFileData(v,item)} handleProgress={handleProgress} 
            handleUrl={handleUploadSuccess} variant='text'>
            Upload
            </UploadMediaButton>}
            </div>
            <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
            {showTable?.[item] && identityData.filter((data)=>data?.subCategory === item && data?.url).length > 0 && 
              <FinancialVaultTableUpload
              showFolder={false}
              mediaItems={identityData.filter((data)=>data?.subCategory === item)}
                handleChange={handleChange}
                handleDelete={handleDelete}
                />}
              {/* {identityData.filter((data)=>data?.subCategory === item).map((data,i)=> 
              <div style={{display:"flex",gap:"0.5rem",flexDirection:"column",}} key={i}>
                
              <img onClick={()=>setUploadModal(true)} src={data?.url} style={{width:"2rem",height:"2rem"}}/>
              </div>
              )} */}
            
            </div>
            </div>)}
            
        </div>
        {}
        </>
    )
}
