import { extrapolateCashflow } from "myFinancials/Utils/utils";

/**
 * Calculates retirement data for each potential retirement start year.
 * Returns an array of results containing probabilities and immediate year data.
 */
export function calculateRetirementData({
    currentAge,
    retirementAge,
    deathAge,
    assets,
    expenses,
    goals,
  }) {
    const expenseDist = extrapolateCashflow({
          fields: expenses,
          useYears: true,
          years: deathAge - currentAge + 1,
        });
    const goalDist = extrapolateCashflow({
            fields: goals,
          });
    const currentYear = new Date().getFullYear();
    const baseYear = currentYear - currentAge;
    let earliestRetirementReturn = "Not possible";
    let earliestRetirementWithdrawal = "Not possible"
    
    const results = [];
  
    for (let startAge = retirementAge; startAge <= deathAge; startAge++) {
      const startYear = ageToCalendarYear(baseYear, startAge);
    
      const totalHorizon = deathAge - startAge + 1;
      const { totalAssets, averageReturn } = aggregateAssets(assets,startYear-currentYear);
  
      // Simulation Yearly
      const {probReturn,probWithdrawal, 
          coveredYears,
          coveredYearsWithdrawal, 
          simulationDetails } = returnsOnlySimulation(totalHorizon,totalAssets,
              averageReturn,baseYear,startAge,
              deathAge,expenseDist,goalDist
      );
      
      if (earliestRetirementReturn === "Not possible" && probReturn === 100){
          earliestRetirementReturn = startYear;}
          
      if (earliestRetirementWithdrawal === "Not possible" && probWithdrawal === 100){
      earliestRetirementWithdrawal = startYear;}
  
      // Immediate year's data for display
      const immediateExpenses = sumForAge(expenseDist, baseYear, startAge);
      const immediateGoals = sumForAge(goalDist, baseYear, startAge);
      const immediateAssetReturn = (totalAssets * averageReturn).toFixed(2);
      const immediateAssetWithdrawal = simulationDetails.length > 0 ? simulationDetails[0].assetWithdrawal : '0.00';
      const immediateAssetAmount = (totalAssets).toFixed(2);
  
      results.push({
        startAge,
        startYear,
        expenses: immediateExpenses,
        goals: immediateGoals,
        expensesAndGoals: immediateExpenses + immediateGoals,
        assetReturn: immediateAssetReturn,
        assetWithdrawal: immediateAssetWithdrawal,
        assetAmount: immediateAssetAmount,
        probReturn: probReturn.toFixed(2),
        probWithdrawal: probWithdrawal.toFixed(2),
        coveredReturn:coveredYears,
        coveredWithdrawal:coveredYearsWithdrawal,
        simulationDetails: simulationDetails, // For CashflowAreaChart
      });
    }
  
    return {results, earliestRetirementReturn, earliestRetirementWithdrawal};
  }
  
  /**
   * Simulates retirement using asset returns and withdrawals.
   * Returns the number of years assets can sustain expenses/goals and the simulation details.
   */
  function returnsOnlySimulation(
    totalHorizon,
    principal,
    averageReturn,
    baseYear,
    startAge,
    deathAge,
    expenseDist,
    goalDist
  ) {
    let coveredYears = 0;
    let coveredYearsWithdrawal = 0;
    const simulationDetails = [];
    let currentPrincipal = principal;
    let currentPrincipalWithdrawal = principal
  
    for (let age = startAge; age <= deathAge; age++) {
      const currentYear = ageToCalendarYear(baseYear, age);
      const expenses = sumForAge(expenseDist, baseYear, age);
      const goals = sumForAge(goalDist, baseYear, age);
      const needed = expenses + goals;
  
      const assetReturn = currentPrincipal * averageReturn;
      const assetReturnWithdrawal = currentPrincipalWithdrawal * averageReturn;
      let assetWithdrawal = 0; // No withdrawal in returns-only scenario
      currentPrincipal += assetReturn;
      currentPrincipalWithdrawal += assetReturnWithdrawal;
      if (assetReturnWithdrawal < needed) {
          assetWithdrawal = needed - assetReturnWithdrawal;
          currentPrincipalWithdrawal -= assetWithdrawal;
        }
  
      simulationDetails.push({
        year: currentYear,
        age,
        expenses,
        goals,
        assetReturn: assetReturnWithdrawal.toFixed(2),
        assetWithdrawal: assetWithdrawal.toFixed(2),
        assetAmount: currentPrincipalWithdrawal.toFixed(2),
      });
      
      if (assetReturn >= needed) {
        coveredYears++;
      } 
  
      if (assetReturnWithdrawal + assetWithdrawal >= needed - 1 && currentPrincipalWithdrawal >= 0) {
          coveredYearsWithdrawal ++;
        }
      else {
        break; // Cannot cover this year
      }
    }
    const probReturn = (coveredYears / totalHorizon) * 100; 
    const probWithdrawal = (coveredYearsWithdrawal / totalHorizon) * 100;
  
    return {probReturn,probWithdrawal, coveredYears,coveredYearsWithdrawal, simulationDetails };
  }


  function ageToCalendarYear(baseYear, age) {
    return baseYear + age;
  }
  
  /**
   * Sums all distribution amounts that fall into the 
   * calendar year matching the person's age.
   */
  function sumForAge(distribution, baseYear, age) {
    const targetYear = ageToCalendarYear(baseYear, age);
    return distribution
      .filter((item) => new Date(item.date).getFullYear() === targetYear)
      .reduce((acc, curr) => acc + curr.amount, 0);
  }
  
  /**
   * Aggregates all assets into a single principal and calculates the weighted average return.
   * Returns the total assets and the average return as a decimal.
   */
  function aggregateAssets(assets,yearPassed) {
    let totalAmount = 0;
    let totalAmountNoChange = 0
    let weightedReturnSum = 0;
    assets.forEach((asset) => {
      totalAmountNoChange += asset.amount
      totalAmount += asset.amount * (1 + asset.return / 100)**yearPassed;
      weightedReturnSum += asset.amount * (asset.return/100);
    });
    const averageReturn = totalAmount === 0 ? 0 : weightedReturnSum / totalAmountNoChange;
    return { totalAssets: totalAmount, averageReturn };
  }