import React, { useState } from 'react';

import Animation from 'animation/Animation';
import Modal from 'components/Modal/Modal';

import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import QueueIcon from '@mui/icons-material/Queue';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

export default function FinancialProductsModal({action="Schedule Call"}) {
    const [modal,setModal] = useState(false)
 
    const data = [{
        action:"Schedule Call",
        description:"Schedule a call with the client to discuss their financial goals and needs.",
        icon:<ScheduleIcon/>
    },{
        action:"Add to my Queue",
        description:"Add the client to your queue to follow up on their financial goals and needs.",
        icon:<QueueIcon/>
    },{
        action:"Summarize & Email",
        description:"Summarize the client's financial goals and needs and email it to the client.",
        icon:<AttachEmailIcon/>
    }]
    const actionSel = data.filter(obj=>obj.action===action)[0]
    if (!actionSel) return null

  return (
    <>
     <Button
      onClick={()=>setModal(true)}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        background: "#ffffff",
        boxShadow: "0 2px 8px rgba(0,0,0,0.04)",
        borderRadius: "12px",
        padding: "1.25rem",
        minWidth: "240px",
        border: "1px solid #f0f0f0",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          background: "#ffffff",
          boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
          transform: "translateY(-2px)",
          borderColor: "#e0e0e0"
        }
      }}
    >   
      <Box sx={{ 
        display: "flex", 
        gap: "0.75rem", 
        alignItems: "center",
        width: "100%"
      }}>
        <Box sx={{ 
          color: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
        {actionSel?.icon}
        </Box>
        <Typography 
          sx={{ 
            fontSize: "1rem",
            fontWeight: 500,
            color: "text.primary"
          }}
        >
          {actionSel?.action}
        </Typography>
      </Box>
      
    </Button>
    {modal && 
    <Modal onClose={()=>setModal(false)}>
       <div style={{width:"100%",height:"100%",overflow:"hidden",display:"flex"
                    ,alignItems:"center",justifyContent:"center",background:"#f7f7f7"}}>
                    <Animation type="Coming Soon"/>
                </div>
    </Modal>}
    </>
  )
}
